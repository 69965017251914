import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdvertiserDetailContext } from 'js/contexts';
import { reloadPage, setDocumentTitle } from 'js/utils';
import { withAdvertiserDetail } from 'js/hocs';
import AdvertiserForm from 'js/components/advertiser/form';
import ActionBar from 'js/components/action-bar/action-bar';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Navigation from 'js/components/navigation/navigation';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import TopBar from 'js/components/top-bar/top-bar';

const LoadingErrorContent = () => (
    <Section>
        <Container>
            <Box padding={['large', 0]}>
                <Alert
                    theme="danger"
                    title="There was an error loading the advertiser"
                    actionItems={<Button onClick={reloadPage}>Retry</Button>}
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try again
                        in a few moments.
                    </p>
                </Alert>
            </Box>
        </Container>
    </Section>
);

function AdvertiserDetailPage() {
    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {
        advertiser: {
            current: advertiser,
            saved: savedAdvertiser,
            save: saveAdvertiser,
            delete: deleteAdvertiser,
            loading,
            saving,
            setName,
            setLogo,
        },
        errors: { load: loadErrors, save: saveErrors, delete: deleteErrors },
    } = useContext(AdvertiserDetailContext);

    const getBreadcrumbs = () => {
        const breadcrumbs = [<Crumb key="home" to="/" label="4D" />];

        if (Object.keys(loadErrors).length > 0) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (savedAdvertiser.name) {
            breadcrumbs.push(
                <Crumb
                    key="advertisers"
                    to="/context/"
                    label="Advertisers"
                    isCollapsible
                />,
                <Crumb
                    key="advertiser"
                    to={`/context/${savedAdvertiser.id}/`}
                    label={savedAdvertiser.name}
                    isCollapsible
                />,
                <Crumb key="edit" label="Edit" />,
            );
        } else {
            breadcrumbs.push(
                <Crumb
                    key="advertisers"
                    label="Advertisers"
                    isInactive
                    isCollapsible
                />,
                <Crumb key="create" label="Create New" />,
            );
        }

        return breadcrumbs;
    };

    const handleSave = async () => {
        try {
            const newAdvertiser = await saveAdvertiser();
            history.push(`/context/${newAdvertiser.id}/`);
        } catch {} // eslint-disable-line no-empty
    };

    const handleDelete = async () => {
        try {
            await deleteAdvertiser();
            history.push('/context/');
        } catch {} // eslint-disable-line no-empty
    };

    useEffect(() => {
        // TODO: make coherent with breadcrumb
        setDocumentTitle([savedAdvertiser.name || 'New', 'Advertisers']);
    }, [savedAdvertiser.name]);

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
        >
            {advertiser.id && !loading && (
                <ActionBar>
                    <Button
                        theme="outline"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        Delete
                    </Button>
                </ActionBar>
            )}

            {loading && (
                <Box padding={['large', 0]}>
                    <Spinner
                        size="large"
                        message="Loading Advertiser"
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            )}

            {Object.keys(loadErrors).length > 0 && <LoadingErrorContent />}

            {saving ? (
                <LoadingLayer
                    message={`${
                        advertiser.id ? 'Saving' : 'Creating'
                    } Advertiser`}
                />
            ) : null}

            {!loading && !Object.keys(loadErrors).length ? (
                <AdvertiserForm
                    advertiser={advertiser}
                    saving={saving}
                    saveErrors={saveErrors}
                    onNameChange={setName}
                    onLogoChange={setLogo}
                    onSave={handleSave}
                />
            ) : null}

            {showDeleteModal ? (
                <ConfirmModal
                    confirm="Yes, Delete Advertiser"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Advertiser"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage="
                        You were unable to delete the advertiser because there
                        was an unexpected error. Please try again in a few
                        moments."
                    onCancel={() => setShowDeleteModal(false)}
                    onConfirm={handleDelete}
                >
                    <p>
                        Deleting an advertiser is permanent and cannot be
                        undone. Any campaigns and contexts in this advertiser
                        will also be deleted.
                    </p>
                </ConfirmModal>
            ) : null}
        </Layout>
    );
}

export default withAdvertiserDetail(AdvertiserDetailPage);
