import React, { useState } from 'react';
import moment from 'moment';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import { eventTagSources } from 'js/constants';
import { deepCopy, getEventFilterForReportType } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Stepper from 'js/components/stepper/stepper';
import ContextStep from './context-step';
import DataSourceStep from './data-source-step/data-source-step';
import DatesStep from './dates-step';
import NameStep from './name-step';
import ReportTypeStep from './report-type-step/report-type-step';
import SummaryStep from './summary-step/summary-step';
import steps from '../enums/steps.enum';
import videoReportSubtypeOptions from '../enums/video-report-subtype-options.enum';

const CreationForm = ({
    template,
    errors,
    advertisers,
    contexts,
    eventHierarchy,
    onChange,
    onValidate,
    onSave,
    onFileUploadError,
}) => {
    const [nextSourceId, setNextSourceId] = useState();
    const [currentStep, setCurrentStep] = useState(steps.REPORT_TYPE);

    const filteredContexts = contexts.filter(
        ({ is_video: isVideo }) =>
            template.type === entityTypes.EVENT_LOG_REPORTS ||
            isVideo === (template.type === entityTypes.VIDEO_REPORTS),
    );

    const filteredAdvertisers = advertisers.filter(({ id }) =>
        filteredContexts.some(({ advertiser }) => advertiser === id),
    );

    const filteredEventHierarchy = [];
    if (template.type === entityTypes.VIDEO_REPORTS) {
        const { subtypes } = videoReportSubtypeOptions.find((option) =>
            option.subtypes.includes(template.subtype),
        );
        subtypes.forEach((subtype) =>
            filteredEventHierarchy.push(
                ...eventHierarchy.filter(
                    getEventFilterForReportType(template.type, subtype),
                ),
            ),
        );
    } else {
        filteredEventHierarchy.push(
            ...eventHierarchy.filter(
                getEventFilterForReportType(template.type, template.subtype),
            ),
        );
    }

    const hasNoData =
        (currentStep === steps.CONTEXT && !filteredContexts.length) ||
        (currentStep === steps.DATA_SOURCE &&
            (template.subtype !== videoReportSubtypes.YOUTUBE_GADS_FILE
                ? !filteredEventHierarchy.length
                : !template.file));

    const activeSteps = [
        steps.REPORT_TYPE,
        steps.CONTEXT,
        steps.DATA_SOURCE,
        template.subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
            ? null
            : steps.DATES,
        steps.NAME,
        steps.SUMMARY,
    ].filter((step) => step);

    const currentStepIndex = activeSteps.indexOf(currentStep);
    const nextStep = activeSteps[currentStepIndex + 1];
    const previousStep = activeSteps[currentStepIndex - 1];

    const { campaignLabel = 'Campaign', lineItemLabel = 'Line Item' } =
        eventTagSources.find(({ id }) => id === template.source) || {};

    const currentFields =
        {
            [steps.NAME]: ['name'],
            [steps.CONTEXT]: ['context'],
            [steps.DATES]: ['schedule'],
            [steps.SUMMARY]: ['name', 'context', 'schedule'],
            [steps.DATA_SOURCE]: ['source', 'file'],
        }[currentStep] || [];
    const hasFieldError = (errs) =>
        Object.keys(errs).some((field) => currentFields.includes(field));
    const hasOtherError = (errs) =>
        Object.keys(errs).length > 0 && !hasFieldError(errs);

    const nextCampaignId = filteredEventHierarchy.find(
        ({ source, campaign: campaignId }) =>
            source === template.source &&
            !template.campaigns.some(
                ({ campaign_id: id }) => id === campaignId,
            ),
    )?.campaign;

    const changeStep = (destination) => {
        let canProceed = true;

        if (destination === nextStep) {
            try {
                if (currentStep === steps.CONTEXT) {
                    onValidate(['context']);
                }
                if (currentStep === steps.NAME) {
                    onValidate(['name']);
                }
                if (currentStep === steps.DATES) {
                    onValidate(['schedule']);
                }
                if (
                    currentStep === steps.DATA_SOURCE &&
                    template.subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
                ) {
                    onValidate(['file']);
                }
            } catch (errs) {
                canProceed = !hasFieldError(errs);
            }
        }

        if (canProceed) {
            setCurrentStep(destination);
        }
    };

    const changeName = (name) =>
        onChange((prevState) => ({ ...prevState, name }));

    const changeType = (type, subtype) => {
        const candidateContexts = contexts.filter(
            ({ is_video: isVideo }) =>
                type === entityTypes.EVENT_LOG_REPORTS ||
                isVideo === (type === entityTypes.VIDEO_REPORTS),
        );
        const firstContext =
            candidateContexts.find(({ id }) => id === template.context) ||
            candidateContexts.find(
                ({ advertiser }) => advertiser === template.advertiser,
            ) ||
            candidateContexts[0];

        const { source: firstSourceId, campaign: firstCampaignId } =
            eventHierarchy.find(getEventFilterForReportType(type, subtype)) ||
            {};

        onChange((prevState) => ({
            ...prevState,
            advertiser: firstContext?.advertiser,
            context: firstContext?.id,
            source:
                subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
                    ? ''
                    : firstSourceId,
            campaigns:
                subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
                    ? []
                    : [{ campaign_id: firstCampaignId, line_items: [] }],
            type,
            subtype,
        }));
    };

    const changeFile = (data) => {
        onChange((prevState) => ({ ...prevState, file: data }));
    };

    const changeAdvertiser = (newAdvertiser) => {
        const firstContextId = filteredContexts.find(
            ({ advertiser }) => advertiser === newAdvertiser,
        )?.id;

        onChange((prevState) => ({
            ...prevState,
            advertiser: newAdvertiser,
            context: firstContextId,
        }));
    };

    const changeContext = (newContext) => {
        onChange((prevState) => ({ ...prevState, context: newContext }));
    };

    const changeSource = (newSourceId) => {
        const { campaign: firstCampaignId } = filteredEventHierarchy.find(
            ({ source }) => source === newSourceId,
        );

        const { type, subtype } = template;
        let newSubtype = subtype;
        if (type === entityTypes.VIDEO_REPORTS) {
            if (newSourceId === 'youtube') {
                newSubtype = videoReportSubtypes.YOUTUBE_DV360;
            }
            if (newSourceId === 'youtube_gads') {
                newSubtype = videoReportSubtypes.YOUTUBE_GADS;
            }
        }

        onChange((prevState) => ({
            ...prevState,
            subtype: newSubtype,
            source: newSourceId,
            campaigns: [{ campaign_id: firstCampaignId, line_items: [] }],
        }));
    };

    const changeSchedule = (newSchedule) => {
        onChange((prevState) => ({ ...prevState, schedule: newSchedule }));
    };

    const changeStartDate = (newDate) => {
        onChange((prevState) => ({
            ...prevState,
            start_date: newDate ? moment(newDate).format('YYYY-MM-DD') : null,
        }));
    };

    const changeEndDate = (newDate) => {
        onChange((prevState) => ({
            ...prevState,
            end_date: newDate ? moment(newDate).format('YYYY-MM-DD') : null,
        }));
    };

    const addCampaign = () => {
        const campaigns = deepCopy(template.campaigns);
        campaigns.push({ campaign_id: nextCampaignId, line_items: [] });
        onChange((prevState) => ({ ...prevState, campaigns }));
    };

    const removeCampaign = (campaignId) => {
        const campaigns = deepCopy(
            template.campaigns.filter(
                ({ campaign_id: id }) => id !== campaignId,
            ),
        );
        onChange((prevState) => ({ ...prevState, campaigns }));
    };

    const changeCampaign = (newCampaignId, newLineItemIds, oldCampaignId) => {
        const campaigns = deepCopy(template.campaigns);
        const campaign = campaigns.find(
            ({ campaign_id: id }) => id === oldCampaignId,
        );
        campaign.campaign_id = newCampaignId;
        campaign.line_items = newLineItemIds.map((id) => ({
            line_item_id: id,
        }));
        onChange((prevState) => ({ ...prevState, campaigns }));
    };

    return (
        <Panel bordered>
            <Box padding="base">
                <Form>
                    <Box margin={[0, 0, 'large']}>
                        <Stepper
                            steps={activeSteps
                                .filter((step) => step !== steps.SUMMARY)
                                .map((step) => ({ id: step, label: step }))}
                            currentStep={currentStepIndex}
                            onStepChange={(stepIndex) =>
                                changeStep(activeSteps[stepIndex])
                            }
                        />
                    </Box>

                    {currentStep === steps.REPORT_TYPE && (
                        <ReportTypeStep
                            template={template}
                            eventHierarchy={eventHierarchy}
                            onChangeType={changeType}
                        />
                    )}

                    {currentStep === steps.CONTEXT && (
                        <ContextStep
                            template={template}
                            advertisers={filteredAdvertisers}
                            contexts={filteredContexts.filter(
                                ({ advertiser }) =>
                                    advertiser === template.advertiser,
                            )}
                            onChangeAdvertiser={changeAdvertiser}
                            onChangeContext={changeContext}
                        />
                    )}

                    {currentStep === steps.DATA_SOURCE && (
                        <DataSourceStep
                            template={template}
                            errors={errors}
                            campaignLabel={campaignLabel}
                            lineItemLabel={lineItemLabel}
                            eventHierarchy={filteredEventHierarchy}
                            nextCampaignId={nextCampaignId}
                            onAddCampaign={addCampaign}
                            onChangeCampaign={changeCampaign}
                            onRemoveCampaign={removeCampaign}
                            onSetNextSourceId={setNextSourceId}
                            onChangeSource={changeSource}
                            onChangeType={changeType}
                            onChangeFile={changeFile}
                            onFileUploadError={onFileUploadError}
                        />
                    )}

                    {currentStep === steps.DATES && (
                        <DatesStep
                            template={template}
                            errors={errors}
                            onChangeSchedule={changeSchedule}
                            onChangeStartDate={changeStartDate}
                            onChangeEndDate={changeEndDate}
                        />
                    )}

                    {currentStep === steps.NAME && (
                        <NameStep
                            template={template}
                            errors={errors}
                            onChangeName={changeName}
                        />
                    )}

                    {currentStep === steps.SUMMARY && (
                        <SummaryStep
                            template={template}
                            context={contexts.find(
                                ({ id }) => id === template.context,
                            )}
                            errors={errors}
                            campaignLabel={campaignLabel}
                            lineItemLabel={lineItemLabel}
                            eventHierarchy={filteredEventHierarchy}
                            onChangeStep={setCurrentStep}
                        />
                    )}

                    {hasFieldError(errors) && (
                        <Box margin={['large', 0]}>
                            <Alert theme="danger">
                                <p>
                                    You can’t proceed to the next step because
                                    there are issues that have been highlighted
                                    in the form above.
                                </p>
                            </Alert>
                        </Box>
                    )}

                    {hasOtherError(errors) && currentStep === steps.SUMMARY && (
                        <Box margin={['large', 0]}>
                            <Alert theme="danger">
                                <p>
                                    You were unable to create the insights
                                    report because there was an unexpected
                                    error. Please try again in a few moments.
                                </p>
                            </Alert>
                        </Box>
                    )}

                    <Box margin={['large', 0, 0]}>
                        <Row justifyContent="space-between">
                            <Col span="auto">
                                {!!previousStep && (
                                    <Button
                                        theme="outline"
                                        onClick={() => changeStep(previousStep)}
                                    >
                                        Previous
                                    </Button>
                                )}
                            </Col>

                            <Col span="auto">
                                {nextStep ? (
                                    <Button
                                        onClick={() => changeStep(nextStep)}
                                        disabled={
                                            hasFieldError(errors) || hasNoData
                                        }
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={onSave}
                                        disabled={hasFieldError(errors)}
                                    >
                                        Create
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </Box>

            {nextSourceId && (
                <ConfirmModal
                    confirm="Yes, Change Platform"
                    cancel="No, Keep Platform"
                    onCancel={() => setNextSourceId(null)}
                    onConfirm={() => {
                        changeSource(nextSourceId);
                        setNextSourceId(null);
                    }}
                >
                    <p>
                        Changing the platform will clear all selected campaigns.
                    </p>
                    <p>Are you sure you want to continue?</p>
                </ConfirmModal>
            )}
        </Panel>
    );
};

export default CreationForm;
