import React from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import CardAdvertiser from 'js/components/card/card-advertiser';
import CardCreateNew from 'js/components/card/card-create-new';
import CardLoading from 'js/components/card/card-loading';
import Container from 'js/components/container/container';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';

const Wrapper = ({ children }) => (
    <Section>
        <Container>
            <Box padding={['base', 0, 0]}>
                <Row>{children}</Row>
            </Box>
        </Container>
    </Section>
);

function AdvertiserList({
    advertisers,
    readOnly,
    loading,
    hasAdvertisersError,
    reloadPage,
    onToggleFavorite,
    onDelete,
}) {
    const createNewContent = (
        <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
            <Box padding={['base', 0, 0]}>
                <CardCreateNew
                    title="Create New Advertiser"
                    to="/advertisers/new/"
                />
            </Box>
        </Col>
    );

    if (loading) {
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <CardLoading loadingText="Loading Advertisers" />
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    if (hasAdvertisersError) {
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <Alert
                            theme="danger"
                            actionItems={
                                <Button onClick={reloadPage}>Retry</Button>
                            }
                            title="There was an error loading the advertisers"
                        >
                            <p>
                                Hopefully it is only a temporary issue. Please
                                try again in a few moments.
                            </p>
                        </Alert>
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    if (!advertisers.length) {
        if (readOnly) {
            return (
                <Wrapper>
                    <Box padding={['base', 0, 0]}>
                        <Alert theme="empty">
                            <p>You have no advertisers.</p>
                        </Alert>
                    </Box>
                </Wrapper>
            );
        }

        return <Wrapper>{createNewContent}</Wrapper>;
    }

    return (
        <Wrapper>
            {advertisers.map((advertiser) => (
                <Col
                    key={advertiser.id}
                    span={12}
                    spanSm={12}
                    spanMd={6}
                    spanLg={4}
                >
                    <Box padding={['base', 0, 0]}>
                        <CardAdvertiser
                            advertiser={advertiser}
                            onToggleFavorite={() =>
                                onToggleFavorite(advertiser.id)
                            }
                            onDeleteAdvertiser={() => onDelete(advertiser.id)}
                        />
                    </Box>
                </Col>
            ))}

            {createNewContent}
        </Wrapper>
    );
}

export default AdvertiserList;
