import { fontSize, fontWeight } from '../tokens.styles';

const styles = {
    emblem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
    },

    labelPortrait: {
        fontWeight: fontWeight.semibold,
        fontSize: fontSize.smaller,
        textTransform: 'capitalize',
        textAlign: 'center',
    },

    labelLandscape: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.small,
        textTransform: 'capitalize',
        textAlign: 'center',
    },
};

export default styles;
