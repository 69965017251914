import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import svgToJsx from 'svg-to-jsx';

const SVG_CACHE = {};

const fetchSafeSvg = async (src) => {
    if (!src) return null;

    const response = await fetch(src);
    const text = await response.text();
    const safeText = await svgToJsx(text);
    const safeSvg = new DOMParser().parseFromString(
        safeText,
        'image/svg+xml',
    ).firstElementChild;

    // 'attrName' -> 'attr-name':
    const walker = document.createTreeWalker(safeSvg, NodeFilter.SHOW_ELEMENT);
    for (let node = walker.currentNode; node; node = walker.nextNode()) {
        [...node.attributes].forEach(({ name, value }) => {
            const isCamelCase = name !== name.toLowerCase();
            if (isCamelCase) {
                const plainName = name.replace(
                    /[A-Z]/g,
                    (match) => `-${match.toLowerCase()}`,
                );
                node.setAttributeNS(null, plainName, value);
            }
        });
    }

    return safeSvg;
};

const SVGImage = ({ src, nocache, ...props }) => {
    const [safeSvg, setSafeSvg] = useState();

    useEffect(
        () =>
            (async () => {
                if (!src) return;

                if (SVG_CACHE[src] && !nocache) {
                    setSafeSvg(SVG_CACHE[src]);
                } else {
                    try {
                        const svg = await fetchSafeSvg(src);
                        SVG_CACHE[src] = svg;
                        setSafeSvg(svg);
                    } catch {
                        // pass
                    }
                }
            })(),
        [src, nocache],
    );

    if (!safeSvg) return null;

    const attrs = Object.fromEntries(
        [...safeSvg.attributes]
            .filter(({ name }) => !name.includes('-'))
            .map(({ name, value }) => [name, value]),
    );
    return (
        <svg
            {...attrs}
            {...props}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: safeSvg.innerHTML,
            }}
        />
    );
};

SVGImage.defaultProps = {
    nocache: false,
};

SVGImage.propTypes = {
    src: PropTypes.string.isRequired,
    nocache: PropTypes.bool,
};

export default SVGImage;
