import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './scrollable.module.scss';

const cx = classNames.bind(styles);

// Threshold fixes the problem of incorrect measurement of content sizes when using browser zoom.
const SCROLL_THRESHOLD = 3;

const Scrollable = forwardRef(
    (
        { hideScrollbar, focusOnShow, focusOnScroll, onBottom, children },
        ref,
    ) => {
        const localRef = useRef();
        const scrollableRef = ref || localRef;
        const [isBottom, setIsBottom] = useState(false);

        const classes = cx({
            container: true,
            'no-scrollbar': hideScrollbar,
        });

        const checkIsBottom = (scrollable) =>
            Math.abs(
                scrollable.scrollHeight -
                    scrollable.scrollTop -
                    scrollable.clientHeight,
            ) < SCROLL_THRESHOLD;

        const handleScroll = ({ target: scrollable }) => {
            setIsBottom(checkIsBottom(scrollable));
            if (focusOnScroll) scrollable.focus();
        };

        useEffect(() => {
            const scrollable = scrollableRef.current;
            if (focusOnShow) scrollable.focus();
        }, [focusOnShow, scrollableRef]);

        useEffect(() => {
            const scrollable = scrollableRef.current;
            setIsBottom(checkIsBottom(scrollable));
        }, [children, scrollableRef]);

        useEffect(() => {
            if (onBottom) onBottom(isBottom);
        }, [isBottom, onBottom]);

        return (
            <div
                ref={scrollableRef}
                className={classes}
                onScroll={handleScroll}
            >
                {children}
            </div>
        );
    },
);

Scrollable.defaultProps = {
    hideScrollbar: false,
    focusOnShow: false,
    focusOnScroll: false,
    onBottom: undefined,
};

Scrollable.propTypes = {
    hideScrollbar: PropTypes.bool,
    focusOnShow: PropTypes.bool,
    focusOnScroll: PropTypes.bool,
    onBottom: PropTypes.func,
    children: PropTypes.node.isRequired,
};

Scrollable.displayName = 'Scrollable';

export default Scrollable;
