const searchTypes = {
    ADVERTISERS: 'advertisers',
    CAMPAIGNS: 'campaigns',
    DEALS: 'deals',
    DISPLAY_CONTEXTS: 'displayContexts',
    VIDEO_CONTEXTS: 'videoContexts',
    DISPLAY_REPORTS: 'insightsReports',
    VIDEO_REPORTS: 'videoReports',
};

export default searchTypes;
