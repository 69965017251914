import React, { useContext } from 'react';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import TopicButton from 'js/components/button/topic-button';
import styles from './rules-section.module.scss';
import ContextDetailContext from '../../../contexts/context-detail.context';

export default function RuleTopics({ rule }) {
    const {
        context: {
            current: { rules },
            setRules,
        },
        page: { isReadOnly },
        topics: { list: topics },
    } = useContext(ContextDetailContext);
    const ruleSet = new RuleSet(rules);

    const topicList = topics.filter((topic) => rule.topics.includes(topic.id));

    return (
        <Box margin={['base', 0]}>
            <Text size="large">Topics</Text>

            <div className={styles.items}>
                {topicList.map((topic) => (
                    <Box
                        key={topic.id}
                        margin={['small', 'small', 0, 0]}
                        display="inline-block"
                    >
                        <TopicButton
                            theme={
                                rule.aggregation === ruleTypes.INCLUDED
                                    ? 'targeted'
                                    : 'blocked'
                            }
                            action="remove"
                            logo={topic.group_logo}
                            isReadOnly={isReadOnly}
                            onClick={() => {
                                ruleSet.changeRule(rule.key, {
                                    removeTopics: [topic.id],
                                });
                                setRules(ruleSet.rules);
                            }}
                        >
                            {topic.name}
                        </TopicButton>
                    </Box>
                ))}
            </div>
        </Box>
    );
}
