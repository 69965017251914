const columnLabels = {
    IMPRESSIONS: 'Impressions',
    CLICKS: 'Clicks',
    CTR: 'CTR',
    VIEWABLE_IMPRESSIONS: 'Viewable Imp.',
    VIEW_RATE: 'View Rate',
    STARTS: 'Started Views',
    FIRST_QUARTILES: 'First Quartile Views',
    MIDPOINTS: 'Midpoint Views',
    THIRD_QUARTILES: 'Third Quartile Views',
    COMPLETES: {
        DEFAULT: 'Completed Views',
        DV360: 'True Views',
    },
    COMPLETION_RATE: {
        DEFAULT: 'Completion Rate',
        DV360: 'True View Rate',
    },
};

export default columnLabels;
