import SectionTitle from 'js/components/section-title/section-title';
import React from 'react';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import { useLimitList } from 'js/hooks';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Button from 'js/components/button/button';
import RecognizedItemsPanel from './recognized-item-panel';

const RecognizedItemsList = ({ items, title, info, type, onNameUpdate }) => {
    const nameKey = type === 'campaign' ? 'campaignName' : 'lineItemName';
    const itemsSorted = items.sort((a, b) =>
        a[nameKey].localeCompare(b[nameKey]),
    );

    const [
        hasMoreItems,
        isItemsExpanded,
        visibleItems,
        showMoreItems,
        showLessItems,
    ] = useLimitList(itemsSorted, 3);

    return (
        <Box margin={[0, 0, 'large', 0]}>
            <Box margin={[0, 0, 'small']}>
                <SectionTitle info={info}>
                    <Text size="large">{title}</Text>
                </SectionTitle>
            </Box>
            {visibleItems
                .sort((a, b) => a[nameKey].localeCompare(b[nameKey]))
                .map((item) => (
                    <RecognizedItemsPanel
                        key={
                            type === 'campaign'
                                ? item.campaignId
                                : item.lineItemId
                        }
                        type={type}
                        item={item}
                        onNameUpdate={onNameUpdate}
                    />
                ))}
            {hasMoreItems && (
                <Box margin={[0, 'small', 0, 0]}>
                    <Row>
                        <Col span="auto">
                            <Button
                                theme="outline"
                                size="small"
                                onClick={showMoreItems}
                            >
                                Show All {items.length}{' '}
                                {type === 'campaign'
                                    ? 'Campaigns'
                                    : 'Line Items'}
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}
            {isItemsExpanded && (
                <Box margin={[0, 'small', 0, 0]}>
                    <Row>
                        <Col span="auto">
                            <Button
                                theme="outline"
                                size="small"
                                onClick={showLessItems}
                            >
                                Show Fewer{' '}
                                {type === 'campaign'
                                    ? 'Campaigns'
                                    : 'Line Items'}
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}
        </Box>
    );
};

export default RecognizedItemsList;
