import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import styles from './panel.styles';

function Panel({ children, theme }) {
    const classes = [styles.container];

    if (theme) {
        classes.push(styles[`theme--${theme}`]);
    }

    return <View style={classes}>{children}</View>;
}

Panel.defaultProps = {
    theme: 'primary',
};

Panel.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.oneOf([
        'primary',
        'secondary',
        'impressions',
        'ctr',
        'view-rate',
    ]),
};

export default Panel;
