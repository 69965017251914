import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Badge from 'js/components/badge/badge';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import IndicatorBar from 'js/components/indicator-bar/indicator-bar';
import Number from 'js/components/number/number';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import ContextChangesDrawer from './context-changes-drawer/context-changes-drawer';
import styles from './summary-footer.module.scss';

function UnsavedChanges({
    contextChanges,
    isNewContext,
    onViewContextSettings,
    isVideo,
}) {
    const changedTopicsCount = contextChanges.rules.reduce(
        (total, rule) =>
            total + rule.addedTopics.length + rule.removedTopics.length,
        0,
    );
    const changedLogosCount = isVideo
        ? contextChanges.rules.reduce(
              (total, rule) =>
                  total + rule.addedLogos.length + rule.removedLogos.length,
              0,
          )
        : 0;
    const changedKeywordsCount = !isVideo
        ? contextChanges.rules.reduce(
              (total, rule) =>
                  total +
                  rule.addedKeywords.length +
                  rule.removedKeywords.length,
              0,
          )
        : 0;

    return (
        changedTopicsCount + changedLogosCount + changedKeywordsCount > 0 && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
                className={styles['unsaved-changes']}
                onClick={onViewContextSettings}
            >
                <Row justifyContent="space-between" alignItems="center">
                    <Col span="auto">
                        <Row>
                            {changedTopicsCount > 0 && (
                                <Col span="auto">
                                    <Row gutter="small" alignItems="center">
                                        <Col span="auto">
                                            <Badge>{changedTopicsCount}</Badge>
                                        </Col>

                                        <Col span="auto">
                                            <Text
                                                weight="bold"
                                                color={['gray', 'darkest']}
                                            >
                                                {changedTopicsCount > 1
                                                    ? 'Topics'
                                                    : 'Topic'}{' '}
                                                {isNewContext
                                                    ? 'Added'
                                                    : 'Changed'}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            )}

                            {changedLogosCount > 0 && (
                                <Col span="auto">
                                    <Row gutter="small" alignItems="center">
                                        <Col span="auto">
                                            <Badge>{changedLogosCount}</Badge>
                                        </Col>

                                        <Col span="auto">
                                            <Text
                                                weight="bold"
                                                color={['gray', 'darkest']}
                                            >
                                                {changedLogosCount > 1
                                                    ? 'Brands'
                                                    : 'Brand'}{' '}
                                                {isNewContext
                                                    ? 'Added'
                                                    : 'Changed'}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            )}

                            {changedKeywordsCount > 0 && (
                                <Col span="auto">
                                    <Row gutter="small" alignItems="center">
                                        <Col span="auto">
                                            <Badge>
                                                {changedKeywordsCount}
                                            </Badge>
                                        </Col>

                                        <Col span="auto">
                                            <Text
                                                weight="bold"
                                                color={['gray', 'darkest']}
                                            >
                                                {changedKeywordsCount > 1
                                                    ? 'Keywords'
                                                    : 'Keyword'}{' '}
                                                {isNewContext
                                                    ? 'Added'
                                                    : 'Changed'}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col span="auto">
                        <span className={styles['view-settings']}>
                            View Context Settings
                        </span>
                    </Col>
                </Row>
            </div>
        )
    );
}

function DimensionCostLabel({ name, value, index }) {
    return (
        <span>
            <Text inline weight="bolder">
                {index > 0 && <> + </>}

                {name}
            </Text>

            <Text inline color={['gray', 'dark']}>
                {' '}
                <Number currencyCode="USD" locale="en-US" value={value} /> CPM
            </Text>
        </span>
    );
}

function SummaryFooter({
    context,
    contextChanges,
    allChannels,
    topics,
    logos,
    priceChanging,
    scaleScore,
    scaleChanging,
    scaleErrors,
    scaleReload,
    scaleOnClick,
    scaleOnHelp,
    actionItems,
    isShowChangeDetails,
    onChangeContextRules,
}) {
    const [isContextChangesDrawerVisible, setContextChangesDrawerVisible] =
        useState(false);

    const {
        id: contextId,
        cpm_usd: contextCpm,
        rules,
        is_video: isVideo,
        channels: contextChannels,
    } = context;
    const isNew = !contextId;
    const prices = {};
    const allYoutubeChannels = allChannels.filter((channel) => {
        const isYoutubeChannel = channel.name.includes('YouTube');
        return isYoutubeChannel;
    });
    const isYoutubeContext = allYoutubeChannels.some((channel) =>
        contextChannels.every((id) => id.includes(channel.id)),
    );
    const isMixedContext = allYoutubeChannels.some(
        (channel) =>
            contextChannels.some((id) => id.includes(channel.id)) &&
            contextChannels.some((id) => !id.includes(channel.id)),
    );
    if (
        rules.some((item) => [...item.keywords, ...item.topics].length) &&
        !isVideo
    ) {
        prices['Topics & Keywords'] =
            contextCpm - Object.values(prices).reduce((a, b) => a + b, 0);
    } else if (
        rules.some((item) => [...item.topics, ...item.logos].length) &&
        isVideo &&
        (contextChannels.length === 0 || isMixedContext)
    ) {
        prices['Video/YouTube Topics'] =
            contextCpm - Object.values(prices).reduce((a, b) => a + b, 0);
    } else if (
        rules.some((item) => [...item.topics, ...item.logos].length) &&
        isVideo &&
        !isYoutubeContext
    ) {
        prices['Video Topics'] =
            contextCpm - Object.values(prices).reduce((a, b) => a + b, 0);
    } else if (
        rules.some((item) => [...item.topics, ...item.logos].length) &&
        isVideo &&
        isYoutubeContext
    ) {
        prices['YouTube Topics'] =
            contextCpm - Object.values(prices).reduce((a, b) => a + b, 0);
    }

    const toggleContextChangesDrawer = () => {
        setContextChangesDrawerVisible((state) => !state);
    };

    const dimensionsContent = (
        <>
            <Text size="small" color={['gray', 'dark']}>
                <h3>Active Dimensions</h3>
            </Text>

            {Object.keys(prices).length ? (
                Object.entries(prices).map(([name, price], idx) => (
                    <DimensionCostLabel
                        key={name}
                        name={name}
                        index={idx}
                        value={price}
                    />
                ))
            ) : (
                <Text weight="bolder" color={['gray', 'dark']}>
                    <h2>There Are No Active Dimensions</h2>
                </Text>
            )}
        </>
    );

    const scaleContent = (
        <IndicatorBar
            value={scaleScore}
            lowMessage="Warning: Low Scale"
            mediumMessage="Medium Scale"
            highMessage="High Scale"
            loading={scaleChanging}
            loadingMessage="Calculating Scale"
            unknownMessage="Unknown Scale"
            hasError={Object.keys(scaleErrors || {}).length > 0}
            errorMessage="Unable To Calculate Scale"
            onClick={scaleOnClick}
            onHelp={scaleOnHelp}
            onReload={scaleReload}
        />
    );

    const totalContent = (
        <>
            <Text color={['gray', 'dark']}>
                <h3>Total Cost</h3>
            </Text>

            <Text size="large" weight="bold">
                <h2>
                    {priceChanging ? (
                        <Spinner />
                    ) : (
                        <Number
                            currencyCode="USD"
                            locale="en-US"
                            value={contextCpm}
                        />
                    )}{' '}
                    CPM
                </h2>
            </Text>
        </>
    );

    return (
        <>
            <div className={styles['summary-footer']}>
                <Container>
                    <Row alignItems="center" gutter="large">
                        <Col>
                            {isShowChangeDetails ? (
                                <UnsavedChanges
                                    contextChanges={contextChanges}
                                    isNewContext={isNew}
                                    onViewContextSettings={
                                        toggleContextChangesDrawer
                                    }
                                    isVideo={isVideo}
                                />
                            ) : (
                                dimensionsContent
                            )}
                        </Col>

                        {!!scaleScore && <Col span="auto">{scaleContent}</Col>}

                        <Col span="auto">{totalContent}</Col>

                        <Col span="auto">{actionItems}</Col>
                    </Row>
                </Container>
            </div>

            {isContextChangesDrawerVisible && (
                <ContextChangesDrawer
                    context={context}
                    contextChanges={contextChanges}
                    topics={topics}
                    logos={logos}
                    onChangeContextRules={onChangeContextRules}
                    onClose={toggleContextChangesDrawer}
                />
            )}
        </>
    );
}

SummaryFooter.defaultProps = {
    contextChanges: {},
    topics: [],
    allChannels: [],
    priceChanging: false,
    scaleScore: null,
    scaleChanging: false,
    scaleErrors: {},
    scaleReload: undefined,
    scaleOnClick: undefined,
    scaleOnHelp: undefined,
    actionItems: null,
    isShowChangeDetails: false,
};

SummaryFooter.propTypes = {
    context: PropTypes.object.isRequired,
    contextChanges: PropTypes.object,
    topics: PropTypes.array,
    allChannels: PropTypes.array,
    priceChanging: PropTypes.bool,
    scaleScore: PropTypes.number,
    scaleChanging: PropTypes.bool,
    scaleErrors: PropTypes.object,
    scaleReload: PropTypes.func,
    scaleOnClick: PropTypes.func,
    scaleOnHelp: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    actionItems: PropTypes.node,
    isShowChangeDetails: PropTypes.bool,
};

export default SummaryFooter;
