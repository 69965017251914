import React from 'react';
import ruleTypes from 'js/enums/rule-types.enum';
import ruleItemTypes from 'js/enums/rule-item-types.enum';
import { capitalize } from 'js/utils';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import TopicButton from 'js/components/button/topic-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';

const RuleItems = ({
    itemDisplayName,
    itemType,
    isBlocked,
    isReadOnly,
    rule,
    items,
    onRemoveRuleItems,
}) =>
    rule[itemType].length > 0 ? (
        <Box margin={['base', 0, 0]}>
            <Row gutter="small" alignItems="center">
                <Col span="auto">
                    <Text size="large">
                        {isBlocked ? 'Blocked ' : 'Targeted '}
                        {capitalize(itemDisplayName)}
                    </Text>
                </Col>

                <Col span="auto">
                    <InfoButton>
                        {isBlocked ? 'Do not show' : 'Show'} advertising when
                        the video{' '}
                        {itemType === ruleItemTypes.TOPIC
                            ? 'matches'
                            : 'contains'}{' '}
                        any of the following {itemDisplayName}.
                    </InfoButton>
                </Col>
            </Row>

            {rule[itemType].map((itemId) => {
                const item = items.find(({ id }) => id === itemId);

                if (!item) return null;

                return (
                    <Box
                        key={itemId}
                        margin={['small', 'small', 0, 0]}
                        display="inline-block"
                    >
                        <TopicButton
                            theme={
                                rule.aggregation === ruleTypes.INCLUDED
                                    ? 'targeted'
                                    : 'blocked'
                            }
                            action="remove"
                            logo={item.group_logo}
                            isReadOnly={isReadOnly}
                            onClick={() =>
                                onRemoveRuleItems(rule.key, itemType, [itemId])
                            }
                        >
                            {item.name}
                        </TopicButton>
                    </Box>
                );
            })}
        </Box>
    ) : null;

export default RuleItems;
