import React from 'react';
// eslint-disable-next-line import/no-nodejs-modules
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import Box from 'js/components/box/box';
import FileInput from 'js/components/input/file-input';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Text from 'js/components/text/text';
import Button from 'js/components/button/button';
import Panel from 'js/components/panel/panel';
import {
    validateFileSize,
    validateMimeTypes,
} from 'js/components/button/file-button';
import { knowledgeBaseYoutubeFileLink } from 'js/constants';
import { csvQuoteSplit, enumerate } from 'js/utils';
import Lightbulb from '../images/lightbulb.svg';
import { CSV_MAX_SIZE_MB } from '../../constants';
import templateFile from './yt_upload_template.csv';

const VALID_DATE_FORMATS = /^(20\d\d-\d\d-\d\d)|(\d\d\/\d\d\/20\d\d)$/;

const requiredHeaders = {
    date: 'Date',
    url: 'Placement URL',
    impressions: 'Impressions',
    starts: 'Views',
    first_quartiles: 'Video played to 25%',
    midpoints: 'Video played to 50%',
    third_quartiles: 'Video played to 75%',
    completes: 'Video played to 100%',
};

const optionalHeaders = {
    clicks: 'Clicks',
};

const outputHeaders = Object.values({ ...requiredHeaders, ...optionalHeaders });

const isNumber = (n) => Number.isFinite(Number(n));

const validateGoogleAdsDate = (row, header, rowNumber) => {
    if (!VALID_DATE_FORMATS.test(row[header])) {
        throw Error(
            `The file contains dates with incorrect formatting, starting on row ${
                rowNumber + 2
            }. Accepted formats include YYYY-MM-DD or DD/MM/YYYY. Please correct the errors and try again.`,
        );
    } else {
        return row[header].replace(/(\d\d)\/(\d\d)\/(20\d\d)/, '$3-$2-$1');
    }
};

const validateGoogleAdsUrl = (row, header, rowNumber) => {
    const url = row[header]
        ?.replace(
            /^https?:\/\/(www\.)?youtube.com\/video\/([^/]+).*$/,
            'https://www.youtube.com/watch?v=$2',
        )
        .trim();
    if (!url || !/^https?:\/\/(www\.)?youtube\.com\//.test(url)) {
        throw Error(
            `invalid url in file (${row[header]}) on row ${
                rowNumber + 2
            }. Please correct the errors and try again.`,
        );
    }
    return url;
};

const validateGoogleAdsInteger = (row, header, rowNumber) => {
    if (!row[header] && !Object.values(optionalHeaders).includes(header)) {
        throw Error(
            `${header} on row ${
                rowNumber + 2
            } is required. Please correct the errors and try again.`,
        );
    }
    const value = !row[header]
        ? '0'
        : row[header].replace(/,/g, '').replace(/^ *-- *$/, '0');
    if (!isNumber(value)) {
        throw Error(
            `Value of ${header} on row ${
                rowNumber + 2
            } is not a number. Please correct the errors and try again.`,
        );
    }
    return value;
};

const validateGoogleAdsPercentage = (row, header, rowNumber) => {
    const value = row[header]
        .replace(/,/g, '') // make sure there are no comma separators
        .replace(/^ *-- *$/, '0') // convert blank values (i.e. "--" to 0)
        .replace(
            /^\d+\.\d\d%$/,
            Math.round(
                (row[requiredHeaders.impressions] * parseFloat(row[header])) /
                    100,
            ),
        );
    if (!isNumber(value)) {
        throw Error(
            `Value of ${header} on row ${
                rowNumber + 2
            } is not a valid percentage or number. Please correct the errors and try again.`,
        );
    }
    return value;
};

const validateGoogleAdsFile = (file, data) => {
    let lines;
    let separator;
    let headers;
    try {
        lines = Buffer.from(data.split(',')[1], 'base64')
            .toString('utf-8')
            .split(/\r?\n/);
        separator = lines[0]?.includes('\t') ? '\t' : ',';
        headers = csvQuoteSplit(lines[0], separator);
    } catch (err) {
        throw Error('Could not parse file - is this a valid CSV?');
    }

    const missingHeaders = Object.values(requiredHeaders).filter(
        (header) => !headers?.includes(header),
    );
    if (missingHeaders.length) {
        const missingHeadersList = enumerate(missingHeaders, 3).join('');
        throw Error(
            `The file doesn’t include ${missingHeadersList}${
                missingHeaders.length > 3
                    ? ` and ${missingHeaders.length - 3} more`
                    : ''
            }. Please update the file with ${
                missingHeaders.length > 1 ? 'these headers' : 'this header'
            } and try again.`,
        );
    }

    const rows = lines
        .slice(1)
        .map((l, rowNumber) => {
            const cells = csvQuoteSplit(l, separator);
            const row = Object.fromEntries(
                headers.map((key, index) => [key, cells[index]]),
            );

            // if the row is empty
            if (!Object.values(row).some(Boolean)) {
                return '';
            }

            // row contains some unpopulated fields/empty strings
            if (!Object.values(row).every(Boolean)) {
                throw Error(
                    `The file contains rows with incomplete data, starting at row ${
                        rowNumber + 2
                    }. Please correct the errors and try again.`,
                );
            }

            return `${outputHeaders
                .map((header) => {
                    switch (header) {
                        case requiredHeaders.date:
                            return validateGoogleAdsDate(
                                row,
                                header,
                                rowNumber,
                            );
                        case requiredHeaders.url:
                            return validateGoogleAdsUrl(row, header, rowNumber);
                        case requiredHeaders.impressions:
                        case requiredHeaders.starts:
                        case optionalHeaders.clicks:
                            return validateGoogleAdsInteger(
                                row,
                                header,
                                rowNumber,
                            );
                        case requiredHeaders.first_quartiles:
                        case requiredHeaders.midpoints:
                        case requiredHeaders.third_quartiles:
                        case requiredHeaders.completes:
                            return validateGoogleAdsPercentage(
                                row,
                                header,
                                rowNumber,
                            );
                        default:
                            throw Error(
                                `Unexpected header value (${header}) found in file on row ${rowNumber}. Please correct the errors and try again.`,
                            );
                    }
                })
                .join('\t')}\n`;
        })
        .join('');

    if (!rows) {
        throw Error('The file contains no data');
    }

    const cleanedData = Buffer.from(
        `${outputHeaders.join('\t')}\n${rows}`,
    ).toString('base64');

    return [file, `${data.split(',')[0]},${cleanedData}`];
};

const FileUploadHelp = () => (
    <Panel theme="tertiary">
        <Box padding={['base']}>
            <Row alignItems="center" justifyContent="center">
                <Col span="auto">
                    <img src={Lightbulb} alt="lightbulb" />
                </Col>
                <Col>
                    <Text color={['purple', 'darker']}>
                        Download the template and add your formatted data to it.
                        Curious how to format your data? Learn more on the{' '}
                        <a
                            href={knowledgeBaseYoutubeFileLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Knowledge Base
                        </a>
                        .
                    </Text>
                </Col>
                <Col span="auto">
                    <Link
                        to={templateFile}
                        target="_blank"
                        download="4D_YT_UPLOAD_TEMPLATE.csv"
                    >
                        <Button theme="outline">Download Template</Button>
                    </Link>
                </Col>
            </Row>
        </Box>
    </Panel>
);

const FileSourceTab = ({
    errors,
    template,
    onChangeFile,
    onFileUploadError,
}) => (
    <>
        <Box margin={[0, 0, 'large', 0]}>
            <FileUploadHelp />
        </Box>
        <FileInput
            label="YouTube Data File To Optimize"
            src={template.file}
            hint={
                <p>
                    Please upload a csv file with a max size of{' '}
                    {CSV_MAX_SIZE_MB}MB.
                </p>
            }
            info=""
            sanitizers={[
                validateMimeTypes([
                    'text/csv',
                    'text/tsv',
                    'text/tab-separated-values',
                    'application/vnd.ms-excel',
                ]),
                validateFileSize(12, CSV_MAX_SIZE_MB * 1024 ** 3),
                validateGoogleAdsFile,
            ]}
            onLoadEnd={onChangeFile}
            onError={onFileUploadError}
            required
            hasError={!!errors.file}
            errorMessage={<p>{errors.file}</p>}
        />
    </>
);

export default FileSourceTab;
