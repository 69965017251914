import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardAccount from 'js/components/card/card-account';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import DrawerFooter from 'js/components/drawer/footer';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Modal from 'js/components/modal/modal';
import Panel from 'js/components/panel/panel';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import { showToast } from 'js/components/toast/toast';
import { NotificationsContext } from 'js/contexts';
import { api, cleanString, getJwtPayload, getUpdatedList } from 'js/utils';
import PropTypes from 'prop-types';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import styles from './navigation.module.scss';

const FavoriteAccounts = ({
    accounts,
    selectedAccount,
    onToggleFavorite,
    onSelectAccount,
}) => (
    <Box margin={['large', 0, 'small']}>
        <Panel theme="secondary">
            <Box padding="base">
                <Text weight="bold" size="large">
                    Favorites
                </Text>

                <Box margin={['small', 0, 0]}>
                    <Panel>
                        <Box padding="base">
                            {accounts.map((account) => (
                                <Box
                                    padding={['small', 'none', 'none']}
                                    key={account.id}
                                >
                                    <CardAccount
                                        key={account.id}
                                        account={account}
                                        isSelected={
                                            account.id === selectedAccount
                                        }
                                        onSelect={() =>
                                            onSelectAccount(account.id)
                                        }
                                        onToggleFavorite={() =>
                                            onToggleFavorite(account.id)
                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Panel>
                </Box>
            </Box>
        </Panel>
    </Box>
);

const DrawerContent = ({
    accounts,
    selectedAccount,
    isLoading,
    hasLoadingError,
    onToggleFavorite,
    onSelectAccount,
}) => {
    const formRef = useRef();

    const [searchTerm, setSearchTerm] = useState('');

    const filteredAccounts =
        cleanString(searchTerm).length > 0
            ? accounts.filter(
                  ({ name, advertisers }) =>
                      name.toLowerCase().includes(cleanString(searchTerm)) ||
                      advertisers.some((advertiser) =>
                          advertiser.name
                              .toLowerCase()
                              .includes(cleanString(searchTerm)),
                      ),
              )
            : accounts;

    const favoriteAccounts = accounts.filter(
        ({ is_favourite: isFavorite }) => isFavorite,
    );

    if (isLoading) {
        return (
            <Spinner
                size="large"
                message="Loading"
                color={['gray', 'dark']}
                isCentered
            />
        );
    }

    if (hasLoadingError) {
        return (
            <Alert
                theme="danger"
                title="There Was An Error While Loading Accounts"
            >
                <p>
                    Hopefully it is only a temporary issue. Please try again in
                    a few moments.
                </p>
            </Alert>
        );
    }

    return (
        <>
            <Form ref={formRef}>
                <Input
                    placeholder="Search accounts or advertisers by name"
                    value={searchTerm}
                    prefix={<FontAwesomeIcon icon={faSearch} />}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClear={() => setSearchTerm('')}
                    focusOnShow
                />
            </Form>

            {favoriteAccounts.length > 0 && !searchTerm && (
                <FavoriteAccounts
                    accounts={favoriteAccounts}
                    selectedAccount={selectedAccount}
                    onToggleFavorite={onToggleFavorite}
                    onSelectAccount={onSelectAccount}
                />
            )}

            <Box margin={['large', 0]}>
                {searchTerm.length > 0 && !filteredAccounts.length ? (
                    <Alert
                        theme="empty"
                        title="There are no accounts that match your search"
                    />
                ) : (
                    filteredAccounts.map((account) => (
                        <Box margin={['small', 0, 0]} key={account.id}>
                            <CardAccount
                                account={account}
                                isSelected={account.id === selectedAccount}
                                onSelect={() => onSelectAccount(account.id)}
                                onToggleFavorite={() =>
                                    onToggleFavorite(account.id)
                                }
                            />
                        </Box>
                    ))
                )}
            </Box>
        </>
    );
};

function SwitchAccountDrawer({ toggleDrawer }) {
    const { refreshNotifications } = useContext(NotificationsContext);

    const [isLoading, setLoading] = useState(true);
    const [isSwitching, setSwitching] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccountId, setSelectedAccountId] = useState(
        getJwtPayload(localStorage.getItem('AuthToken')).account || '',
    );
    const [hasLoadingError, setLoadingError] = useState(false);
    const [hasSwitchingError, setSwitchingError] = useState(false);

    const selectedAccount = accounts.find(
        (item) => item.id === selectedAccountId,
    );

    const loadAccountsData = async () => {
        setLoading(true);
        setLoadingError(false);

        try {
            const [{ results: accountsData }, { results: advertisersData }] =
                await Promise.all([
                    api().accounts.list({
                        complete: true,
                        field: ['id', 'name', 'is_favourite', 'logo'],
                        ordering: 'name',
                    }),
                    api().advertisers.list({
                        complete: true,
                        field: ['name', 'account'],
                    }),
                ]);

            setAccounts(
                accountsData.map((account) => ({
                    ...account,
                    advertisers: advertisersData.filter(
                        (advertiser) => advertiser.account === account.id,
                    ),
                })),
            );
        } catch (e) {
            setLoadingError(true);
        } finally {
            setLoading(false);
        }
    };

    const toggleFavorite = async (accountId) => {
        const acc = accounts.find((item) => item.id === accountId);
        const isFavorite = !acc.is_favourite;

        setAccounts((prevState) =>
            getUpdatedList(prevState, accountId, {
                isFavoriteLoading: true,
            }),
        );

        let success = false;

        try {
            await api().accounts.favorite(accountId, isFavorite, {
                complete: true,
            });
            success = true;
        } catch (err) {
            showToast(
                'There Was An Unexpected Error',
                <p>
                    It was not possible to{' '}
                    {isFavorite ? 'unfavorite' : 'favorite'} account. Please
                    again in a few moments.
                </p>,
                5,
                'danger',
            );
        } finally {
            setAccounts((prevState) =>
                getUpdatedList(prevState, accountId, {
                    is_favourite: success ? isFavorite : !isFavorite,
                    isFavoriteLoading: false,
                }),
            );
        }
    };

    const switchAccount = async () => {
        setSwitching(true);

        try {
            const { token } = await api().masquerade(selectedAccountId);
            localStorage.setItem('AuthToken', token);
            await refreshNotifications();
            window.location.href = '/';
        } catch (err) {
            setSwitchingError(true);
            setSwitching(false);
        }
    };

    useLayoutEffect(() => {
        loadAccountsData();

        return () => {
            api().abortAll();
        };
    }, []);

    return (
        <>
            <Drawer onClose={toggleDrawer}>
                <Layout
                    header={
                        <Container size="medium">
                            <Box padding={['large', 0]}>
                                <Text size="huge">Switch Accounts</Text>
                            </Box>
                        </Container>
                    }
                    isFooterSticky
                    footer={
                        <DrawerFooter>
                            <Container size="medium">
                                <Box padding={['small', 0]}>
                                    <Row>
                                        {selectedAccount && (
                                            <Col>
                                                <Row
                                                    gutter="small"
                                                    alignItems="center"
                                                >
                                                    <Col span="auto">
                                                        {selectedAccount.logo ? (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        'selected-account-logo'
                                                                    ]
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        selectedAccount.logo
                                                                    }
                                                                    alt={
                                                                        selectedAccount.name
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        'selected-account-no-logo'
                                                                    ]
                                                                }
                                                            />
                                                        )}
                                                    </Col>

                                                    <Col span="auto">
                                                        <Text
                                                            weight="bold"
                                                            size="smaller"
                                                            color={[
                                                                'gray',
                                                                'dark',
                                                            ]}
                                                        >
                                                            SELECTED ACCOUNT
                                                        </Text>
                                                        <Text weight="bold">
                                                            {
                                                                selectedAccount.name
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}

                                        {!isLoading && (
                                            <Col span="auto">
                                                <Button onClick={switchAccount}>
                                                    Switch Account
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                </Box>
                            </Container>
                        </DrawerFooter>
                    }
                >
                    <Container size="medium">
                        <DrawerContent
                            accounts={accounts}
                            selectedAccount={selectedAccountId}
                            isLoading={isLoading}
                            hasLoadingError={hasLoadingError}
                            onToggleFavorite={toggleFavorite}
                            onSelectAccount={setSelectedAccountId}
                        />
                    </Container>
                </Layout>
            </Drawer>

            {isSwitching && <LoadingLayer message="Switching Accounts" />}

            {hasSwitchingError && (
                <Modal preventClosing>
                    <Alert
                        theme="danger"
                        title="There Was An Error While Switching Accounts"
                        actionItems={
                            <Row gutter="small">
                                <Col span="auto">
                                    <Button
                                        theme="outline"
                                        onClick={() => setSwitchingError(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>

                                <Col span="auto">
                                    <Button onClick={switchAccount}>
                                        Retry
                                    </Button>
                                </Col>
                            </Row>
                        }
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            again in a few moments.
                        </p>
                    </Alert>
                </Modal>
            )}
        </>
    );
}

SwitchAccountDrawer.defaultProps = {};

SwitchAccountDrawer.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
};

export default SwitchAccountDrawer;
