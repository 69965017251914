import React from 'react';
import { enumerate } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import Sparkline from 'js/components/sparkline/sparkline';
import Text from 'js/components/text/text';
import pageTabs from '../../enums/page-tabs.enum';
import styles from './recommendation-panel.module.scss';
import recommendationActions from '../recommendations-tab/enums/actions.enum';
import recommendationAttributes from '../recommendations-tab/enums/attributes.enum';
import getRecommendationAppliedCount from '../recommendations-tab/utils';

const Recommendation = ({ recommendation, report, context }) => {
    const {
        attribute,
        action,
        entities,
        target_metric: targetMetric,
        target_metric_value: targetMetricValue,
        target_metric_baseline: targetMetricBaseline,
    } = recommendation;
    const recommendedEntities = entities.map((i) => i.name);
    const isAdding = action === recommendationActions.ADD;
    const isKeywords = attribute === recommendationAttributes.KEYWORDS;

    const appliedCount = getRecommendationAppliedCount(recommendation, context);

    const totalCount = recommendedEntities.length;

    const namedEntities = isKeywords
        ? recommendedEntities
        : report.topics
              .filter((i) => recommendedEntities.includes(i.topic))
              .map((i) => i.topic_name);

    const enumeratedNames = `${
        namedEntities.length > 3 ? ', including' : ':'
    } ${enumerate(
        namedEntities.slice(0, 3),
        3,
        (item, separator) => `’${item}’${separator}`,
    ).join(' ')}`;

    const title = `${
        isAdding ? 'Target' : 'Stop Targeting'
    } ${totalCount} new ${
        totalCount > 1 ? attribute : attribute.slice(0, -1)
    }${enumeratedNames}`;

    const improvement = (
        <>
            Content featuring these {attribute} had a{' '}
            {targetMetric.toUpperCase()} of{' '}
            <Number
                value={targetMetricValue / 100}
                isPercentage
                maximumFractionDigits={2}
            />
            {', '}
            <Number
                value={targetMetricValue / targetMetricBaseline - 1}
                isPercentage
                maximumFractionDigits={2}
                signDisplay="never"
            />{' '}
            {targetMetricValue / targetMetricBaseline > 1 ? 'greater' : 'less'}{' '}
            than the report average
        </>
    );

    return (
        <div className={styles.recommendation}>
            <Text weight="bold">{title}</Text>

            <Box margin={['smaller', 0, 0]}>
                <Text size="small" color={['gray', 'dark']}>
                    {improvement}
                </Text>
            </Box>

            <Box margin={['base', 0, 0]}>
                <Box margin={[0, 0, 'smaller']}>
                    <Text size="small">
                        {appliedCount}/{totalCount}
                        {` ${attribute} ${
                            isAdding ? 'targeted' : 'untargeted'
                        }`}
                    </Text>
                </Box>

                <Sparkline
                    value={appliedCount}
                    maxValue={totalCount}
                    noLabel
                    color={['purple', 'base']}
                />
            </Box>
        </div>
    );
};

function RecommendationPanel({ report, context, onChangeTab }) {
    return (
        <Container>
            <Box margin={['large', 0]}>
                <Panel theme="secondary">
                    <Box padding="base">
                        <Text weight="bold" size="larger">
                            Optimizations
                        </Text>

                        <div className={styles.column}>
                            {report.recommendations.length ? (
                                <>
                                    {report.recommendations
                                        .slice(0, 3)
                                        .map((recommendation) => (
                                            <Recommendation
                                                recommendation={recommendation}
                                                report={report}
                                                context={context}
                                            />
                                        ))}
                                </>
                            ) : (
                                <div className={styles.empty}>
                                    <p>No optimizations could be generated.</p>
                                </div>
                            )}
                        </div>

                        <Row>
                            <Col span="auto">
                                <Button
                                    onClick={() =>
                                        onChangeTab(pageTabs.RECOMMENDATIONS)
                                    }
                                >
                                    View Optimizations
                                </Button>
                            </Col>
                        </Row>
                    </Box>
                </Panel>
            </Box>
        </Container>
    );
}

export default RecommendationPanel;
