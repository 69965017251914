import React from 'react';
import { reloadPage } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Navigation from 'js/components/navigation/navigation';
import Panel from 'js/components/panel/panel';
import Spinner from 'js/components/spinner/spinner';
import TopBar from 'js/components/top-bar/top-bar';

const Wrapper = ({ saving, children }) => (
    <Layout
        sidebar={<Navigation />}
        header={
            <TopBar
                breadcrumbs={[
                    <Crumb key="home" to="/" label="4D" />,
                    <Crumb
                        key="settings"
                        to="/settings/"
                        label="Settings"
                        isCollapsible
                    />,
                    <Crumb
                        key="profile"
                        to="/settings/your-profile/"
                        label="Your Profile"
                        isCollapsible
                    />,
                    <Crumb key="details" label="Details" />,
                ]}
            />
        }
    >
        {saving && <LoadingLayer message="Saving Details" />}

        <Container size="small">
            <Box padding={['large', 0]}>{children}</Box>
        </Container>
    </Layout>
);

const FormErrors = ({ userErrors }) => {
    let content;

    if (userErrors.email || userErrors.first_name || userErrors.last_name) {
        content = (
            <p>
                You can&apos;t save your details at the moment because there are
                problems that have been highlighted in the form above.
            </p>
        );
    } else {
        content = (
            <p>
                You were unable to save your details because there was an
                unexpected error. Please try again in a few moments.
            </p>
        );
    }

    return (
        <Box margin={[0, 0, 'large']}>
            <Alert theme="danger">{content}</Alert>
        </Box>
    );
};

export default function UserSettingsDetail({
    isLoading,
    isSaving,
    hasLoadingError,
    userErrors,
    user,
    onChangeUserAttr,
    onSaveUser,
}) {
    const hasFormErrors = Object.keys(userErrors).length > 0;

    if (isLoading) {
        return (
            <Wrapper saving={isSaving}>
                <Spinner
                    size="large"
                    message="Loading Your Details"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Wrapper>
        );
    }

    if (hasLoadingError) {
        return (
            <Wrapper saving={isSaving}>
                <Alert
                    theme="danger"
                    title="There was an error while loading your details"
                    actionItems={
                        <Button onClick={reloadPage}>
                            Retry Loading Details
                        </Button>
                    }
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try
                        loading the page again in a few moments.
                    </p>
                </Alert>
            </Wrapper>
        );
    }

    return (
        <Wrapper saving={isSaving}>
            <Form>
                <Panel bordered>
                    <Box padding="base">
                        <Box margin={[0, 0, 'base']}>
                            <Input
                                focusOnShow
                                maxLength={150}
                                required
                                label="Your Email"
                                value={user.email}
                                hasError={!!userErrors.email}
                                errorMessage={<p>{userErrors.email}</p>}
                                onChange={(evt) =>
                                    onChangeUserAttr('email', evt.target.value)
                                }
                            />
                        </Box>

                        <Box margin={[0, 0, 'base']}>
                            <Input
                                label="Your First Name"
                                maxLength={30}
                                required
                                value={user.first_name}
                                hasError={!!userErrors.first_name}
                                errorMessage={<p>{userErrors.first_name}</p>}
                                onChange={(evt) =>
                                    onChangeUserAttr(
                                        'first_name',
                                        evt.target.value,
                                    )
                                }
                            />
                        </Box>

                        <Box margin={[0, 0, 'base']}>
                            <Input
                                label="Your Surname"
                                maxLength={150}
                                required
                                value={user.last_name}
                                hasError={!!userErrors.last_name}
                                errorMessage={<p>{userErrors.last_name}</p>}
                                onChange={(evt) =>
                                    onChangeUserAttr(
                                        'last_name',
                                        evt.target.value,
                                    )
                                }
                            />
                        </Box>

                        {hasFormErrors && (
                            <FormErrors userErrors={userErrors} />
                        )}

                        <Row justifyContent="flex-end">
                            <Col span="auto">
                                <Button
                                    type="submit"
                                    disabled={hasFormErrors}
                                    onClick={onSaveUser}
                                >
                                    Save Details
                                </Button>
                            </Col>
                        </Row>
                    </Box>
                </Panel>
            </Form>
        </Wrapper>
    );
}
