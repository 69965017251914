import React from 'react';
import Box from 'js/components/box/box';
import CardContext from 'js/components/card/card-context';
import Container from 'js/components/container/container';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';

const FavoriteContexts = ({ contexts, onDelete, onToggleFavorite }) => (
    <Container>
        <Box padding={['large', 0, 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    <Text weight="bold" size="large">
                        Favorites
                    </Text>

                    {contexts.map((context) => (
                        <Box padding={['small', 0, 0]} key={context.id}>
                            <CardContext
                                isSlim
                                context={context}
                                topline={context.group_name}
                                hasDealID
                                onDeleteContext={() => onDelete(context.id)}
                                onToggleFavorite={() =>
                                    onToggleFavorite(context.id)
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Panel>
        </Box>
    </Container>
);

export default FavoriteContexts;
