import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import Crumb from 'js/components/breadcrumbs/crumb';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownLink from 'js/components/dropdown-menu/link';

function CrumbCollapsed({ children }) {
    const [showExtraCrumbs, setShowExtraCrumbs] = useState(false);
    const toggleShowMenu = () => {
        setShowExtraCrumbs((prevState) => !prevState);
    };

    return (
        <DropdownMenu
            content={
                <ul>
                    {Children.map(children, (crumb) => {
                        const { label, to } = crumb.props;

                        return (
                            <DropdownLink key={label} to={to}>
                                {label}
                            </DropdownLink>
                        );
                    })}
                </ul>
            }
            onHide={() => setShowExtraCrumbs(false)}
            showMenu={showExtraCrumbs}
        >
            <Crumb label="&hellip;" to="#" onClick={toggleShowMenu} />
        </DropdownMenu>
    );
}

CrumbCollapsed.defaultProps = {};

CrumbCollapsed.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CrumbCollapsed;
