import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api, getDisplayName } from 'js/utils';
import { AdvertiserDetailContext } from 'js/contexts';

const advertiserTemplate = {
    id: null,
    name: '',
    logo: '',
    context_groups: 0,
};

const ContextProvider = ({ children }) => {
    const { advertiserId } = useParams();
    const isNew = !advertiserId || advertiserId === 'new';
    const [advertiser, setAdvertiser] = useState({
        ...advertiserTemplate,
        id: isNew ? null : advertiserId,
    });
    const [savedAdvertiser, setSavedAdvertiser] = useState({});
    const [amending, setAmending] = useState(false);
    const [loading, setLoading] = useState(!isNew);
    const [loadErrors, setLoadErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [saveErrors, setSaveErrors] = useState({});
    const [deleting, setDeleting] = useState(false);
    const [deleteErrors, setDeleteErrors] = useState({});

    const validateAdvertiser = useCallback(
        ({ advertiserData, throwErrors = false } = {}) => {
            setAmending(true);

            const errors = {};
            if (!advertiserData.name) {
                errors.name = 'A name is required.';
            }
            if (!advertiserData.logo) {
                errors.logo = 'A logo is required.';
            }

            setSaveErrors((prevState) => ({ ...prevState, ...errors }));

            if (throwErrors && (errors.name || errors.logo)) {
                throw errors;
            }
        },
        [],
    );

    const loadAdvertiser = useCallback(
        async (id) => {
            setLoading(true);
            setLoadErrors({});
            try {
                const advertiserData = await api().advertisers.retrieve(id, {
                    verbose: true,
                });
                setAdvertiser(advertiserData);
                setSavedAdvertiser(advertiserData);
                validateAdvertiser({ advertiserData });
            } catch (errors) {
                setLoadErrors(errors);
            }
            setLoading(false);
        },
        [validateAdvertiser],
    );

    const saveAdvertiser = async () => {
        setSaving(true);
        setSaveErrors({});
        try {
            validateAdvertiser({
                advertiserData: advertiser,
                throwErrors: true,
            });

            const isLogoUnchanged = advertiser.logo.startsWith('http');
            const advertiserData = { ...advertiser };
            if (isLogoUnchanged) delete advertiserData.logo;

            let newAdvertiser;
            if (advertiser.id) {
                newAdvertiser = await api().advertisers.update(
                    advertiser.id,
                    advertiserData,
                );
            } else {
                newAdvertiser = await api().advertisers.create(advertiser);
            }
            setAdvertiser(newAdvertiser);
            setSavedAdvertiser(newAdvertiser);
            setSaving(false);

            return newAdvertiser;
        } catch (errors) {
            if (errors.non_field_errors) {
                delete errors.non_field_errors;
                errors.name = 'This Name is already in use';
            }
            setSaveErrors(errors);
            setSaving(false);
            throw errors;
        }
    };

    const deleteAdvertiser = async () => {
        setDeleting(true);
        setDeleteErrors({});
        try {
            await api().advertisers.destroy(advertiser.id);
            setAdvertiser({ ...advertiserTemplate });
            setDeleting(false);
        } catch (errors) {
            setDeleteErrors(errors);
            setDeleting(false);
            throw errors;
        }
    };

    const setName = (name) => {
        delete saveErrors.name;
        setAdvertiser({ ...advertiser, name });
    };

    const setLogo = (logo) => {
        delete saveErrors.logo;
        setAdvertiser({ ...advertiser, logo });
    };

    const providerValue = useMemo(
        () => ({
            advertiser: {
                current: advertiser,
                saved: savedAdvertiser,
                loading,
                saving,
                deleting,
                save: saveAdvertiser,
                delete: deleteAdvertiser,
                setName,
                setLogo,
            },
            errors: {
                load: loadErrors,
                save: saveErrors,
                delete: deleteErrors,
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            advertiser,
            deleteErrors,
            deleting,
            loadErrors,
            loading,
            saveErrors,
            savedAdvertiser,
            saving,
        ],
    );

    useEffect(() => {
        setSaveErrors({});

        if (isNew) {
            setAmending(false);
            setAdvertiser({
                ...advertiserTemplate,
                id: null,
            });
            setSavedAdvertiser({});
        } else {
            loadAdvertiser(advertiserId);
        }
    }, [advertiserId, isNew, loadAdvertiser]);

    useEffect(() => {
        if (amending && !loading) {
            validateAdvertiser({ advertiserData: advertiser });
        }
    }, [advertiser, amending, loading, validateAdvertiser]);

    return (
        <AdvertiserDetailContext.Provider value={providerValue}>
            {children}
        </AdvertiserDetailContext.Provider>
    );
};

export default function withAdvertiserDetail(Wrapped) {
    function WithAdvertiserDetail(props) {
        return (
            <ContextProvider>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Wrapped {...props} />
            </ContextProvider>
        );
    }

    WithAdvertiserDetail.displayName = `withAdvertiserDetail(${getDisplayName(
        Wrapped,
    )})`;

    return WithAdvertiserDetail;
}
