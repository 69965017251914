import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Alert from 'js/components/alert/alert';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import HighlightsSection from '../../../components/highlights-section/highlights-section';
import PanelWrapper from '../../../components/panel-wrapper';
import SectionWrapper from '../../../components/section-wrapper/section-wrapper';
import KeywordBubblesSection from './keyword-bubbles-section';
import TopKeywordsSection from './top-keywords-section';
import panelTabs from '../../enums/panel-tabs.enum';

const tabs = [
    { id: panelTabs.CHART, label: 'Impression Share' },
    { id: panelTabs.HIGHLIGHTS, label: 'Highlights' },
    { id: panelTabs.TABLE, label: 'Table' },
];

const KeywordsPanel = ({
    report,
    context,
    kpis,
    tableColumns,
    onChangeContextRules,
}) => {
    const [currentTab, setCurrentTab] = useState(panelTabs.CHART);

    const checkDisplayKeywordsPanel = () => {
        const keywordsWithImpressions = report.keywords.filter(
            (keyword) => keyword.impressions > 0,
        );

        return keywordsWithImpressions.length > 0;
    };

    const panelTitle = 'Keyword Themes';
    const panelDescription = `This panel displays keyword level recommendations 
        that can help improve performance and scale. Keywords are the most granular 
        building block of a context. Use these insights to understand how keywords 
        overlap with your media, and can improve your targeting.`;

    if (checkDisplayKeywordsPanel() === false) {
        return (
            <SectionWrapper title={panelTitle} description={panelDescription}>
                <Alert size="base" theme="empty">
                    <p>Not enough data available</p>
                </Alert>
            </SectionWrapper>
        );
    }

    return (
        <SectionWrapper title={panelTitle} description={panelDescription}>
            <PanelWrapper>
                <Box margin={['base', 0, 0]}>
                    <Tabs
                        tabs={tabs}
                        currentTab={currentTab}
                        onChangeTab={setCurrentTab}
                    >
                        <TabContent tabId={panelTabs.CHART}>
                            <KeywordBubblesSection report={report} />
                        </TabContent>

                        <TabContent tabId={panelTabs.HIGHLIGHTS}>
                            <Box margin={['base', 0]}>
                                <HighlightsSection
                                    data={report.keywords}
                                    report={report}
                                    kpis={kpis}
                                    impressionsHint={`If you do not want to show 
                                        your advertising with these keywords, remove 
                                        any that you are targeting from your targeted 
                                        keywords.`}
                                    ctrHint={`If CTR is one of your key metrics, 
                                        you might want to add any top performing 
                                        keywords to your targeted keywords.`}
                                    viewRateHint={`If View Rate is one of your key 
                                        metrics, you might want to add any top 
                                        performing keywords to your targeted keywords.`}
                                />
                            </Box>
                        </TabContent>

                        <TabContent tabId={panelTabs.TABLE}>
                            <TopKeywordsSection
                                report={report}
                                context={context}
                                tableColumns={tableColumns}
                                onChangeContextRules={onChangeContextRules}
                            />
                        </TabContent>
                    </Tabs>
                </Box>
            </PanelWrapper>
        </SectionWrapper>
    );
};

export default KeywordsPanel;
