import React, { useState } from 'react';
import StatusHero from './components/status-hero';
import StepsSection from './components/steps-section';
import uploadGuideSteps from './upload-guide-copy';
import styles from './task-detail.module.scss';
import HelpDrawer from './components/help-drawer';

const YoutubeDownload = ({ isLoading, hasError, statusMsg, redirectUrl }) => {
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [selectedStep, setSelectedStep] = useState(uploadGuideSteps[0]);

    const handleTileClick = (tileContent) => {
        setDrawerIsVisible(true);
        setSelectedStep(tileContent);
    };

    return (
        <div>
            <div className={styles['tasks-flexbox']}>
                <div className={styles['status-section']}>
                    <StatusHero
                        isLoading={isLoading}
                        hasError={hasError}
                        statusMsg={statusMsg}
                        redirectUrl={redirectUrl}
                    />
                </div>
                <div className={styles['steps-section']}>
                    <StepsSection onClickTile={handleTileClick} />
                </div>
            </div>
            {drawerIsVisible && (
                <HelpDrawer
                    selectedStep={selectedStep}
                    onSetSelectedStep={setSelectedStep}
                    onSetDrawerIsVisible={setDrawerIsVisible}
                />
            )}
        </div>
    );
};

export default YoutubeDownload;
