import React, { useContext, useState } from 'react';
import classNames from 'classnames/bind';
import { languageOptions } from 'js/constants';
import DisplayActive from 'media/images/context-types/display-active.svg';
import DisplayInactive from 'media/images/context-types/display-inactive.svg';
import VideoActive from 'media/images/context-types/video-active.svg';
import VideoInactive from 'media/images/context-types/video-inactive.svg';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import InfoButton from 'js/components/button/info-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Panel from 'js/components/panel/panel';
import Select from 'js/components/select/select';
import SVGImage from 'js/components/svg-image/svg-image';
import Text from 'js/components/text/text';
import PageSection from '../page-section/page-section';
import styles from './details-section.module.scss';
import ContextDetailContext from '../../contexts/context-detail.context';

const contentTypeOptions = [
    {
        id: 'content-type-display',
        label: 'Display',
        icons: [DisplayActive, DisplayInactive],
        value: false,
        hint: <p>This context will be used with display advertising.</p>,
    },
    {
        id: 'content-type-video',
        label: 'Video',
        icons: [VideoActive, VideoInactive],
        value: true,
        hint: <p>This context will be used with video advertising.</p>,
    },
];

const cx = classNames.bind(styles);
const readOnlySectionClasses = cx('section', 'section--read-only');

function RadioSelector({ options, selectedValue, onChange }) {
    return (
        <div className={styles.options}>
            {options.map(
                ({
                    hint,
                    icons: [activeIcon, inactiveIcon],
                    id,
                    label,
                    value,
                }) => {
                    const isChecked = selectedValue === value;

                    return (
                        <div className={styles.option} key={id}>
                            <input
                                type="radio"
                                checked={isChecked}
                                value={value}
                                id={id}
                                onChange={() => onChange(value)}
                            />

                            <label
                                htmlFor={id}
                                className={styles['option-handler']}
                            >
                                <div className={styles['icon-container']}>
                                    <SVGImage
                                        className={styles.icon}
                                        src={
                                            isChecked
                                                ? activeIcon
                                                : inactiveIcon
                                        }
                                    />
                                </div>

                                <div className={styles['label-content']}>
                                    <p>{label}</p>
                                </div>

                                <div className={styles['hint-container']}>
                                    <InfoButton placement="bottom-end">
                                        {hint}
                                    </InfoButton>
                                </div>
                            </label>
                        </div>
                    );
                },
            )}
        </div>
    );
}

function ContentTypeSelector({ isVideo, isReadOnly, onChange }) {
    if (isReadOnly) {
        return (
            <section className={readOnlySectionClasses}>
                <Box margin={['smaller', 0]}>
                    <Text size="large" weight="bold">
                        Targeting Type
                    </Text>
                </Box>
                <Box margin={['smaller', 0]}>
                    <Text size="larger" color={['gray', 'dark']}>
                        {isVideo ? 'Video' : 'Display'}
                    </Text>
                </Box>
            </section>
        );
    }

    return (
        <section className={styles.section}>
            <Box margin={['small', 0]}>
                <Box margin={[0, 0, 'small']}>
                    <Text weight="bold" size="large">
                        Targeting Type
                    </Text>
                </Box>

                <RadioSelector
                    options={contentTypeOptions}
                    selectedValue={isVideo}
                    onChange={onChange}
                />
            </Box>
        </section>
    );
}

function Name({ name, isReadOnly, error, onChange }) {
    if (isReadOnly) {
        return (
            <section className={readOnlySectionClasses}>
                <Box margin={['smaller', 0]}>
                    <Text size="large" weight="bold">
                        Name
                    </Text>
                </Box>

                <Box margin={['smaller', 0]}>
                    <Text size="larger" color={['gray', 'dark']}>
                        {name || 'None Set'}
                    </Text>
                </Box>
            </section>
        );
    }

    return (
        <section className={styles.section}>
            <Box margin={['small', 0]}>
                <Input
                    focusOnShow
                    maxLength={50}
                    hasError={!!error}
                    value={name}
                    onChange={onChange}
                    label="Name"
                    errorMessage={<p>{error}</p>}
                    required
                />
            </Box>
        </section>
    );
}

function Advertiser({
    advertiserId,
    advertisers,
    isReadOnly,
    error,
    onCreate,
    onChange,
}) {
    const [searchTerm, setSearchTerm] = useState('');

    const advertiser = advertisers.find((item) => item.id === advertiserId);
    const advertiserChoices = advertisers.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    const visibleAdvertiserChoices = advertiserChoices.filter(({ label }) =>
        label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    if (isReadOnly) {
        return (
            <section className={readOnlySectionClasses}>
                <Box margin={['smaller', 0]}>
                    <Text size="large" weight="bold">
                        Advertiser
                    </Text>
                </Box>

                <Box margin={['smaller', 0]}>
                    <Text size="larger" color={['gray', 'dark']}>
                        {(advertiser || {}).name || 'None Set'}
                    </Text>
                </Box>
            </section>
        );
    }

    return (
        <section className={styles.section}>
            <Box margin={['small', 0]}>
                <Text
                    weight="bold"
                    size="large"
                    color={error ? ['red', 'dark'] : ['gray', 'darkest']}
                >
                    Advertiser
                </Text>
            </Box>

            {advertiserChoices.length > 0 ? (
                <Box margin={['small', 0]}>
                    <Row alignItems="center" gutter="smaller">
                        <Col>
                            <Select
                                required
                                width="same"
                                hasError={!!error}
                                errorMessage={<p>{error}</p>}
                                options={visibleAdvertiserChoices}
                                selectedValues={advertiserId}
                                onChange={onChange}
                                onSearch={
                                    advertiserChoices.length > 5
                                        ? setSearchTerm
                                        : null
                                }
                            />
                        </Col>

                        <Col span={12} spanSm="auto">
                            <Box padding={['smaller', 0]} paddingSm="none">
                                <Row justifyContent="center">
                                    <Col span="auto">
                                        <Text
                                            size="small"
                                            color={['gray', 'dark']}
                                        >
                                            or
                                        </Text>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>

                        <Col span={12} spanSm="auto">
                            <Button theme="outline" onClick={onCreate}>
                                Create Advertiser
                            </Button>
                        </Col>
                    </Row>
                </Box>
            ) : (
                <>
                    <Box margin={['small', 0]}>
                        <Row>
                            <Col span="auto">
                                <Button theme="outline" onClick={onCreate}>
                                    Create Advertiser
                                </Button>
                            </Col>
                        </Row>
                    </Box>

                    {error ? (
                        <Box margin={['small', 0]}>
                            <Row>
                                <Col>
                                    <Text color={['red', 'dark']}>{error}</Text>
                                </Col>
                            </Row>
                        </Box>
                    ) : null}
                </>
            )}
        </section>
    );
}

const CampaignWrapper = ({ error, children }) => (
    <section className={styles.section}>
        <Box margin={['small', 0]}>
            <Text
                weight="bold"
                size="large"
                color={error ? ['red', 'dark'] : ['gray', 'darkest']}
            >
                Campaign
            </Text>
        </Box>

        {children}
    </section>
);

function Campaign({
    advertiserId,
    campaignId,
    campaigns,
    isReadOnly,
    error,
    onCreate,
    onChange,
}) {
    const [searchTerm, setSearchTerm] = useState('');

    const campaign = campaigns.find((item) => item.id === campaignId);
    const campaignChoices = campaigns
        .filter((item) => item.advertiser === advertiserId)
        .map((item) => ({
            label: item.name,
            value: item.id,
        }));

    const visibleCampaignChoices = campaignChoices.filter(({ label }) =>
        label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    if (isReadOnly) {
        return (
            <section className={readOnlySectionClasses}>
                <Box margin={['smaller', 0]}>
                    <Text size="large" weight="bold">
                        Campaign
                    </Text>
                </Box>

                <Box margin={['smaller', 0]}>
                    <Text size="larger" color={['gray', 'dark']}>
                        {(campaign || {}).name || 'None Set'}
                    </Text>
                </Box>
            </section>
        );
    }

    if (!advertiserId) {
        return (
            <CampaignWrapper error={error}>
                <Box margin={['small', 0]}>
                    <Row>
                        <Col>
                            <Alert theme="empty">
                                <p>
                                    An advertiser must be selected in order to
                                    select a campaign.
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                </Box>
            </CampaignWrapper>
        );
    }

    if (!campaignChoices.length) {
        return (
            <CampaignWrapper error={error}>
                <Box margin={['small', 0]}>
                    <Row>
                        <Col span="auto">
                            <Button theme="outline" onClick={onCreate}>
                                Create Campaign
                            </Button>
                        </Col>
                    </Row>
                </Box>

                {error ? (
                    <Box margin={['small', 0]}>
                        <Row>
                            <Col>
                                <Text color={['red', 'dark']}>{error}</Text>
                            </Col>
                        </Row>
                    </Box>
                ) : null}
            </CampaignWrapper>
        );
    }

    return (
        <CampaignWrapper error={error}>
            <Box margin={['small', 0]}>
                <Row alignItems="center" gutter="smaller">
                    <Col>
                        <Select
                            required
                            width="same"
                            hasError={!!error}
                            errorMessage={<p>{error}</p>}
                            options={visibleCampaignChoices}
                            selectedValues={campaignId}
                            onChange={onChange}
                            onSearch={
                                campaignChoices.length > 5
                                    ? setSearchTerm
                                    : null
                            }
                        />
                    </Col>

                    <Col span={12} spanSm="auto">
                        <Box padding={['smaller', 0]} paddingSm="none">
                            <Row justifyContent="center">
                                <Col span="auto">
                                    <Text size="small" color={['gray', 'dark']}>
                                        or
                                    </Text>
                                </Col>
                            </Row>
                        </Box>
                    </Col>

                    <Col span={12} spanSm="auto">
                        <Button theme="outline" onClick={onCreate}>
                            Create Campaign
                        </Button>
                    </Col>
                </Row>
            </Box>
        </CampaignWrapper>
    );
}

function Language({ language, isReadOnly, onChange }) {
    const selectedLanguage = languageOptions.find(
        (item) => item.value === language,
    ).label;

    if (isReadOnly) {
        return (
            <section className={readOnlySectionClasses}>
                <Box margin={['smaller', 0]}>
                    <Text size="large" weight="bold">
                        Language
                    </Text>
                </Box>

                <Box margin={['smaller', 0]}>
                    <Text size="larger" color={['gray', 'dark']}>
                        {selectedLanguage}
                    </Text>
                </Box>
            </section>
        );
    }

    return (
        <section className={styles.section}>
            <Box margin={['small', 0]}>
                <Select
                    label="Language"
                    width="same"
                    options={languageOptions.filter((item) => item.value)}
                    selectedValues={language}
                    onChange={onChange}
                />
            </Box>
        </section>
    );
}

export default function DetailsSection({
    onChangeContentType,
    onChangeName,
    onCreateAdvertiser,
    onChangeAdvertiser,
    onCreateCampaign,
    onChangeCampaign,
    onChangeLanguage,
}) {
    const {
        context: {
            current: {
                id,
                name,
                advertiser,
                group,
                language,
                is_video: isVideo,
            },
        },
        advertisers: { list: advertisers },
        campaigns: { list: campaigns },
        errors: { context: contextErrors },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);
    const {
        name: nameError,
        advertiser: advertiserError,
        group: groupError,
    } = contextErrors;

    return (
        <PageSection title="Details">
            <Box margin={['large', 0, 0, 0]}>
                <Panel bordered>
                    <Box padding={['small', 'base']}>
                        <ContentTypeSelector
                            isVideo={isVideo}
                            isReadOnly={!!id}
                            onChange={onChangeContentType}
                        />

                        <Name
                            name={name}
                            isReadOnly={isReadOnly}
                            error={nameError}
                            onChange={onChangeName}
                        />

                        <Advertiser
                            advertiserId={advertiser}
                            advertisers={advertisers}
                            isReadOnly={isReadOnly}
                            error={advertiserError}
                            onCreate={onCreateAdvertiser}
                            onChange={onChangeAdvertiser}
                        />
                        <Campaign
                            advertiserId={advertiser}
                            campaignId={group}
                            campaigns={campaigns}
                            isReadOnly={isReadOnly}
                            error={groupError}
                            onCreate={onCreateCampaign}
                            onChange={onChangeCampaign}
                        />

                        <Language
                            language={language}
                            isReadOnly={isReadOnly}
                            onChange={onChangeLanguage}
                        />
                    </Box>
                </Panel>
            </Box>
        </PageSection>
    );
}
