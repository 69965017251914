import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { breakpointKeys } from 'js/constants';
import { getBreakpointCssPrefix } from 'js/utils';
import styles from './box.module.scss';

const backgroundClasses = (background) => {
    const classes = [];
    if (background) {
        classes.push(styles[`background--${background[0]}-${background[1]}`]);
    }
    return classes;
};

const borderClasses = ({
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
}) => {
    const classes = [];
    if (border) {
        classes.push(
            styles[
                `border--${border[0]}-${border[1]}-${border[2]}-${border[3]}`
            ],
        );
    }
    if (borderTop) {
        if (borderTop === 'none') {
            classes.push(styles['border-top--none']);
        } else {
            classes.push(
                styles[
                    `border-top--${borderTop[0]}-${borderTop[1]}-${borderTop[2]}-${borderTop[3]}`
                ],
            );
        }
    }
    if (borderBottom) {
        if (borderBottom === 'none') {
            classes.push(styles['border-bottom--none']);
        } else {
            classes.push(
                styles[
                    `border-bottom--${borderBottom[0]}-${borderBottom[1]}-${borderBottom[2]}-${borderBottom[3]}`
                ],
            );
        }
    }
    if (borderLeft) {
        if (borderLeft === 'none') {
            classes.push(styles['border-left--none']);
        } else {
            classes.push(
                styles[
                    `border-left--${borderLeft[0]}-${borderLeft[1]}-${borderLeft[2]}-${borderLeft[3]}`
                ],
            );
        }
    }
    if (borderRight) {
        if (borderRight === 'none') {
            classes.push(styles['border-right--none']);
        } else {
            classes.push(
                styles[
                    `border-right--${borderRight[0]}-${borderRight[1]}-${borderRight[2]}-${borderRight[3]}`
                ],
            );
        }
    }
    if (borderRadius) {
        if (Array.isArray(borderRadius)) {
            const [topLeft, topRight, bottomRight, bottomLeft] = borderRadius;

            classes.push(
                styles[`border-radius-top-left--${topLeft || 'none'}`],
                styles[`border-radius-top-right--${topRight || 'none'}`],
                styles[
                    `border-radius-bottom-right--${
                        bottomRight || topLeft || 'none'
                    }`
                ],
                styles[
                    `border-radius-bottom-left--${
                        bottomLeft || topRight || 'none'
                    }`
                ],
            );
        } else {
            classes.push(styles[`border-radius--${borderRadius}`]);
        }
    }
    return classes;
};

const shadowClasses = (shadow) => {
    const classes = [];
    if (shadow) {
        classes.push(styles[`shadow--${shadow}`]);
    }
    return classes;
};

const displayClasses = (props) => {
    const classes = [];
    breakpointKeys.forEach((breakpointKey) => {
        const breakpointCssPrefix = getBreakpointCssPrefix(breakpointKey);
        const { [`display${breakpointKey}`]: display } = props;
        if (display) {
            classes.push(styles[`${breakpointCssPrefix}display--${display}`]);
        }
    });
    return classes;
};

const marginClasses = (props) => {
    const classes = [];
    breakpointKeys.forEach((breakpointKey) => {
        const breakpointCssPrefix = getBreakpointCssPrefix(breakpointKey);
        const { [`margin${breakpointKey}`]: margin } = props;
        if (margin) {
            if (Array.isArray(margin)) {
                if (margin.length === 1) {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}margin--${
                                margin[0] || 'none'
                            }`
                        ],
                    );
                } else if (margin.length === 2) {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}margin-vertical--${
                                margin[0] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}margin-horizontal--${
                                margin[1] || 'none'
                            }`
                        ],
                    );
                } else if (margin.length === 3) {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}margin-top--${
                                margin[0] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}margin-horizontal--${
                                margin[1] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}margin-bottom--${
                                margin[2] || 'none'
                            }`
                        ],
                    );
                } else {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}margin-top--${
                                margin[0] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}margin-right--${
                                margin[1] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}margin-bottom--${
                                margin[2] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}margin-left--${
                                margin[3] || 'none'
                            }`
                        ],
                    );
                }
            } else {
                classes.push(styles[`${breakpointCssPrefix}margin--${margin}`]);
            }
        }
    });
    return classes;
};

const paddingClasses = (props) => {
    const classes = [];
    breakpointKeys.forEach((breakpointKey) => {
        const breakpointCssPrefix = getBreakpointCssPrefix(breakpointKey);
        const { [`padding${breakpointKey}`]: padding } = props;
        if (padding) {
            /*
                TODO:
                We need to factor in how we calculate "bottomRemainder".

                classes.push(styles[`${breakpointCssPrefix}padding-bottom--remainder-${paddingBottom}-${paddingBottomRemainder}`);

                Do we look for props.contentMarginBottom?
            */
            if (Array.isArray(padding)) {
                if (padding.length === 1) {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}padding--${
                                padding[0] || 'none'
                            }`
                        ],
                    );
                } else if (padding.length === 2) {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}padding-vertical--${
                                padding[0] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}padding-horizontal--${
                                padding[1] || 'none'
                            }`
                        ],
                    );
                } else if (padding.length === 3) {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}padding-top--${
                                padding[0] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}padding-horizontal--${
                                padding[1] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}padding-bottom--${
                                padding[2] || 'none'
                            }`
                        ],
                    );
                } else {
                    classes.push(
                        styles[
                            `${breakpointCssPrefix}padding-top--${
                                padding[0] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}padding-right--${
                                padding[1] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}padding-bottom--${
                                padding[2] || 'none'
                            }`
                        ],
                        styles[
                            `${breakpointCssPrefix}padding-left--${
                                padding[3] || 'none'
                            }`
                        ],
                    );
                }
            } else {
                classes.push(
                    styles[`${breakpointCssPrefix}padding--${padding}`],
                );
            }
        }
    });
    return classes;
};

function Box({
    display,
    displaySm,
    displayMd,
    displayLg,
    displayXl,
    margin,
    marginSm,
    marginMd,
    marginLg,
    marginXl,
    padding,
    paddingSm,
    paddingMd,
    paddingLg,
    paddingXl,
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
    background,
    shadow,
    position,
    children,
}) {
    const classes = [
        styles.box,
        ...displayClasses({
            display,
            displaySm,
            displayMd,
            displayLg,
            displayXl,
        }),
        ...marginClasses({ margin, marginSm, marginMd, marginLg, marginXl }),
        ...paddingClasses({
            padding,
            paddingSm,
            paddingMd,
            paddingLg,
            paddingXl,
        }),
        ...borderClasses({
            border,
            borderTop,
            borderBottom,
            borderLeft,
            borderRight,
            borderRadius,
        }),
        ...backgroundClasses(background),
        ...shadowClasses(shadow),
    ];

    if (position) {
        classes.push(styles[`position--${position}`]);
    }

    return <div className={classNames(classes)}>{children}</div>;
}

const displays = PropTypes.oneOf([
    'block',
    'inline-block',
    'none',
    'flex',
    'grid',
]);

const spacings = PropTypes.oneOfType([
    PropTypes.oneOf([
        '-larger',
        '-large',
        '-base',
        '-small',
        '-smaller',
        '-smallest',
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
        'auto',
    ]),
    PropTypes.arrayOf(
        PropTypes.oneOf([
            '-larger',
            '-large',
            '-base',
            '-small',
            '-smaller',
            '-smallest',
            0,
            'none',
            'smallest',
            'smaller',
            'small',
            'base',
            'large',
            'larger',
            'auto',
        ]),
    ),
]);

const colors = [
    'purple',
    'red',
    'yellow',
    'aqua',
    'gray',
    'blue',
    'black',
    'darkest',
    'darker',
    'dark',
    'base',
    'light',
    'lighter',
    'lightest',
    'background',
    'white',
];

const borders = PropTypes.arrayOf(
    PropTypes.oneOf(['base', 'thick', 'thicker', 'solid', 'dashed', ...colors]),
);

Box.defaultProps = {
    display: undefined,
    displaySm: undefined,
    displayMd: undefined,
    displayLg: undefined,
    displayXl: undefined,
    margin: undefined,
    marginSm: undefined,
    marginMd: undefined,
    marginLg: undefined,
    marginXl: undefined,
    padding: undefined,
    paddingSm: undefined,
    paddingMd: undefined,
    paddingLg: undefined,
    paddingXl: undefined,
    border: undefined,
    borderTop: undefined,
    borderBottom: undefined,
    borderLeft: undefined,
    borderRight: undefined,
    borderRadius: undefined,
    background: undefined,
    shadow: undefined,
    position: undefined,
};

Box.propTypes = {
    display: displays,
    displaySm: displays,
    displayMd: displays,
    displayLg: displays,
    displayXl: displays,
    margin: spacings,
    marginSm: spacings,
    marginMd: spacings,
    marginLg: spacings,
    marginXl: spacings,
    padding: spacings,
    paddingSm: spacings,
    paddingMd: spacings,
    paddingLg: spacings,
    paddingXl: spacings,
    border: borders,
    borderTop: borders,
    borderBottom: borders,
    borderLeft: borders,
    borderRight: borders,
    borderRadius: PropTypes.oneOfType([
        PropTypes.oneOf([
            'none',
            'small',
            'base',
            'round',
            'rounder',
            'rounded',
        ]),
        PropTypes.arrayOf(
            PropTypes.oneOf([
                'none',
                'small',
                'base',
                'round',
                'rounder',
                'rounded',
            ]),
        ),
    ]),
    background: PropTypes.arrayOf(PropTypes.oneOf(colors)),
    shadow: PropTypes.oneOf(['shallow', 'base', 'deep']),
    position: PropTypes.oneOf(['relative', 'absolute']),
    children: PropTypes.node.isRequired,
};

export default Box;
