import React from 'react';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Promo from './components/promo';
import LoginForm from './components/login-form';
import styles from './login.module.scss';

function Login({
    isLoggingIn,
    loginErrors,
    randomPromo,
    onUpdateEmail,
    onUpdatePassword,
    onLoginUser,
}) {
    return (
        <Layout alignContent="center">
            <div className={styles.container}>
                {isLoggingIn && <LoadingLayer message="Logging In" />}

                <Container>
                    <Row
                        alignItems="center"
                        gutter="none"
                        gutterMd="larger"
                        reverse
                    >
                        <Col span={12} spanMd="equal">
                            {randomPromo && <Promo promo={randomPromo} />}
                        </Col>

                        <Col span={12} spanMd="auto">
                            <LoginForm
                                errors={loginErrors}
                                onUpdateEmail={onUpdateEmail}
                                onUpdatePassword={onUpdatePassword}
                                onLoginUser={onLoginUser}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
}

export default Login;
