import React, { useContext } from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import NoMatchedVideosNotice from './no-matched-videos-notice';
import MatchedVideoItem from './matched-video-item';
import ContextDetailContext from '../../../contexts/context-detail.context';

export default function MatchedVideos() {
    const {
        context: {
            current: { rules },
        },
        matchedVideos: {
            list: matchedVideos,
            load: getMatchedVideos,
            loadingVideoMatches: loading,
            videoMatchError: loadingError,
            needsReload,
        },
    } = useContext(ContextDetailContext);

    const hasActiveDimensions = rules.filter(
        (item) => item.topics.length,
    ).length;

    if (loading) {
        return (
            <Spinner
                size="large"
                message="Loading"
                color={['gray', 'dark']}
                isCentered
            />
        );
    }

    if (loadingError) {
        return (
            <Alert
                theme="danger"
                title="There Was An Unexpected Error Loading Matched Videos"
                actionItems={
                    <Button onClick={getMatchedVideos}>
                        Retry Loading Videos
                    </Button>
                }
            >
                <p>
                    Hopefully it is only a temporary issue. Please try loading
                    the matching pages again in a few moments.
                </p>
            </Alert>
        );
    }

    // No Active Dimensions
    if (!hasActiveDimensions && !needsReload)
        return (
            <Alert theme="empty" title="No Topics Selected">
                <Text color={['gray', 'dark']}>
                    <Box margin={[0, 0, 'small']}>
                        <p>
                            When a topic has been selected you will be able to
                            view matched videos here.
                        </p>
                    </Box>
                </Text>
            </Alert>
        );

    if (!matchedVideos.length) return <NoMatchedVideosNotice />;

    return (
        <Box margin={[0, 0, '-base', 0]}>
            {matchedVideos.map((item) => (
                <Box margin={[0, 0, 'base']} key={item.url}>
                    <MatchedVideoItem matchedVideo={item} />
                </Box>
            ))}
        </Box>
    );
}
