import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import styles from './alert.module.scss';

const cx = classNames.bind(styles);

function Alert({ actionItems, children, theme, title, size }) {
    const classes = cx({
        container: true,
        [theme]: theme,
        [size]: size,
    });

    return (
        <div className={classNames(classes)}>
            {title && (
                <Box margin={['small', 0]}>
                    <Text weight="bolder" size="larger">
                        {title}
                    </Text>
                </Box>
            )}

            {children}

            {actionItems && (
                <div className={styles['action-items']}>
                    <div>{actionItems}</div>
                </div>
            )}
        </div>
    );
}

Alert.defaultProps = {
    actionItems: null,
    children: null,
    theme: 'primary',
    title: '',
    size: 'base',
};

Alert.propTypes = {
    actionItems: PropTypes.node,
    children: PropTypes.node,
    theme: PropTypes.oneOf(['primary', 'danger', 'empty']),
    title: PropTypes.string,
    size: PropTypes.oneOf(['small', 'base']),
};

export default Alert;
