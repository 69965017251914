import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from 'js/components/box/box';
import FavoritesButton from 'js/components/button/favorites-button';
import Text from 'js/components/text/text';
import styles from './tile.module.scss';
import ClickArea from '../click-area/click-area';

const Wrapper = ({ to, onClick, children }) => {
    if (to) {
        return <Link to={to}> {children}</Link>;
    }

    if (onClick) {
        return <ClickArea onClick={onClick}>{children}</ClickArea>;
    }

    return children;
};

const Tile = ({
    topline,
    title,
    subtitle,
    description,
    background,
    backgroundImage,
    hasOverlay,
    hasShadow,
    logo,
    alignLogo,
    to,
    onClick,
    isFavorite,
    isFavoriteLoading,
    onToggleFavorite,
}) => {
    const containerClasses = [styles.container];
    if (background) {
        containerClasses.push(
            styles[`background--${background[0]}-${background[1]}`],
        );
    }
    if (hasShadow) {
        containerClasses.push(styles.shadow);
    }

    const logoClasses = [styles.logo];
    if (alignLogo) {
        logoClasses.push(styles[`align--${alignLogo}`]);
    }

    const contentClasses = [styles.content];
    if (hasOverlay) {
        contentClasses.push(styles.overlay);
    }

    return (
        <Wrapper to={to} onClick={onClick}>
            <div className={classNames(containerClasses)}>
                {backgroundImage && (
                    <img
                        src={backgroundImage}
                        alt=""
                        className={styles.background}
                    />
                )}
                {logo && (
                    <img
                        src={logo}
                        alt=""
                        className={classNames(logoClasses)}
                    />
                )}
                {onToggleFavorite && (
                    <div className={styles.favorite}>
                        <FavoritesButton
                            size="small"
                            isFavorite={isFavorite}
                            isLoading={isFavoriteLoading}
                            onToggleFavorite={onToggleFavorite}
                        />
                    </div>
                )}
                <div className={classNames(contentClasses)}>
                    {topline && (
                        <Text weight="bolder" color={['purple', 'base']}>
                            {topline}
                        </Text>
                    )}
                    <Text size="huge">{title}</Text>
                    {subtitle && <Text>{subtitle}</Text>}
                    {description && (
                        <Box padding={['small', 0, 0]}>
                            <Text size="large">{description}</Text>
                        </Box>
                    )}
                </div>
            </div>
        </Wrapper>
    );
};

Tile.defaultProps = {
    topline: undefined,
    title: undefined,
    subtitle: undefined,
    description: undefined,
    background: undefined,
    backgroundImage: undefined,
    hasOverlay: false,
    hasShadow: false,
    logo: undefined,
    alignLogo: undefined,
    to: undefined,
    onClick: undefined,
    isFavorite: false,
    isFavoriteLoading: false,
    onToggleFavorite: undefined,
};

Tile.propTypes = {
    topline: PropTypes.node,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    description: PropTypes.node,
    background: PropTypes.arrayOf(
        PropTypes.oneOf([
            'purple',
            'red',
            'yellow',
            'aqua',
            'gray',
            'blue',
            'black',
            'darkest',
            'darker',
            'dark',
            'base',
            'light',
            'lighter',
            'lightest',
            'background',
            'white',
        ]),
    ),
    backgroundImage: PropTypes.string,
    hasOverlay: PropTypes.bool,
    hasShadow: PropTypes.bool,
    logo: PropTypes.string,
    alignLogo: PropTypes.oneOf(['mid-left', 'mid-right']),
    to: PropTypes.string,
    onClick: PropTypes.func,
    isFavorite: PropTypes.bool,
    isFavoriteLoading: PropTypes.bool,
    onToggleFavorite: PropTypes.func,
};

export default Tile;
