import React from 'react';
import { getScheduleType } from 'js/utils';
import Checkbox from 'js/components/checkbox/checkbox';
import MonthCalendar from './month-calendar';
import WeekCalendar from './week-calendar';
import scheduleTypes from '../../../enums/schedule-types.enum';

const DatesEditingStep = ({ schedule, errors, onChangeSchedule }) => {
    const changeScheduleType = (newScheduleType) => {
        if (getScheduleType(schedule) === newScheduleType) return;

        let newSchedule;
        switch (newScheduleType) {
            case scheduleTypes.WEEKLY:
                newSchedule = 'mon';
                break;
            case scheduleTypes.MONTHLY:
                newSchedule = 1;
                break;
            default:
                newSchedule = null;
                break;
        }
        onChangeSchedule(newSchedule);
    };

    const options = [
        {
            label: 'No Scheduling',
            value: null,
        },
        {
            label: 'Weekly',
            value: scheduleTypes.WEEKLY,
            content: (
                <WeekCalendar
                    selectedDay={schedule}
                    onChange={onChangeSchedule}
                />
            ),
        },
        {
            label: 'Monthly',
            value: scheduleTypes.MONTHLY,
            content: (
                <MonthCalendar
                    selectedDay={Number.parseInt(schedule, 10)}
                    onChange={onChangeSchedule}
                />
            ),
        },
    ];

    return (
        <Checkbox
            radio
            label="Scheduled 30 Day Snapshots"
            hint={
                <p>
                    Scheduled reports will contain snapshots with 30 days of
                    data, from the day the snapshot was generated to 30 days in
                    the past. Scheduled reports will continue to generate
                    snapshots until 4D stops recording data for your data
                    sources.
                </p>
            }
            options={options}
            selectedValues={getScheduleType(schedule)}
            hasError={!!errors.schedule}
            errorMessage={<p>{errors.schedule}</p>}
            onChange={changeScheduleType}
        />
    );
};

export default DatesEditingStep;
