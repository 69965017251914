import React from 'react';
import { Document, Font } from '@react-pdf/renderer';
import fontBold from 'fonts/SF Pro Display/SFProDisplay-Bold.ttf';
import fontHeavy from 'fonts/SF Pro Display/SFProDisplay-Heavy.ttf';
import fontLight from 'fonts/SF Pro Display/SFProDisplay-Light.ttf';
import fontMedium from 'fonts/SF Pro Display/SFProDisplay-Medium.ttf';
import fontRegular from 'fonts/SF Pro Display/SFProDisplay-Regular.ttf';
import fontSemibold from 'fonts/SF Pro Display/SFProDisplay-Semibold.ttf';
import fontThin from 'fonts/SF Pro Display/SFProDisplay-Thin.ttf';
import DisplayTopicThemesPage from './topic-themes-page/display-topic-themes-page';
import KeywordThemesPage from './keyword-themes-page/keyword-themes-page';
import DisplaySummaryPage from './summary-page/display-summary-page';
import TitlePage from './title-page/title-page';
import { displayPdfPages } from '../pdf-download-drawer/enums/pdf-pages.enum';

Font.registerHyphenationCallback((word) => [word]);

Font.register({
    family: 'SF Pro Display',
    fonts: [
        { src: fontThin, fontWeight: 200 },
        { src: fontLight, fontWeight: 300 },
        { src: fontRegular, fontWeight: 400 },
        { src: fontMedium, fontWeight: 500 },
        { src: fontSemibold, fontWeight: 600 },
        { src: fontBold, fontWeight: 700 },
        { src: fontHeavy, fontWeight: 800 },
    ],
});

const DisplayReport = ({
    pages,
    report,
    reportName,
    advertiserName,
    campaignName,
    orientation,
    logo4D,
    brandLogo,
    charts,
}) => {
    const getPage = (pageLabel) => {
        switch (pageLabel) {
            case displayPdfPages.SUMMARY:
                return (
                    <DisplaySummaryPage
                        orientation={orientation}
                        logo4D={logo4D}
                        brandLogo={brandLogo}
                        charts={charts}
                        report={report}
                    />
                );
            case displayPdfPages.TOPICS:
                return (
                    <DisplayTopicThemesPage
                        orientation={orientation}
                        logo4D={logo4D}
                        brandLogo={brandLogo}
                        charts={charts}
                        report={report}
                    />
                );
            case displayPdfPages.KEYWORDS:
                return (
                    <KeywordThemesPage
                        orientation={orientation}
                        logo4D={logo4D}
                        brandLogo={brandLogo}
                        charts={charts}
                        report={report}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <Document>
            <TitlePage
                pages={pages}
                reportName={reportName}
                advertiserName={advertiserName}
                campaignName={campaignName}
                orientation={orientation}
                logo4D={logo4D}
                brandLogo={brandLogo}
                report={report}
            />
            {pages.map((p) => getPage(p.label))}
        </Document>
    );
};

export default DisplayReport;
