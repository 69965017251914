const filterTypes = {
    ORDERING: 'ordering',
    LANGUAGE: 'language',
    REGION: 'region',

    SORT: 'NOT_USING_1',
    DATE: 'last',
    VIEW: 'view',
};

export default filterTypes;
