import React, { useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { reloadPage } from 'js/utils';
import ActionBar from 'js/components/action-bar/action-bar';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Navigation from 'js/components/navigation/navigation';
import Panel from 'js/components/panel/panel';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import TopBar from 'js/components/top-bar/top-bar';

const loadingErrorContent = (
    <Section>
        <Container>
            <Box padding={['large', 0]}>
                <Alert
                    theme="danger"
                    title="There was an error while loading the seat"
                    actionItems={
                        <Button onClick={reloadPage}>Retry Loading Seat</Button>
                    }
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try
                        loading the page again in a few moments.
                    </p>
                </Alert>
            </Box>
        </Container>
    </Section>
);

const FormErrorContent = ({ channelErrors }) => {
    if (channelErrors.description || channelErrors.integration_key) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You can&apos;t update the seat at the moment because
                        there are problems that have been highlighted in the
                        form above.
                    </p>
                </Alert>
            </Box>
        );
    }
    if (Object.keys(channelErrors).length) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You were unable to update the seat because there was an
                        unexpected error. Please try again in a few moments.
                    </p>
                </Alert>
            </Box>
        );
    }
    return null;
};

const FormContent = ({
    channel,
    onSetDescription,
    onSetIntegrationKey,
    channelErrors,
    isReadOnly,
}) => (
    <>
        <Box margin={[0, 0, 'base']}>
            <Input
                disabled={isReadOnly}
                focusOnShow
                maxLength={50}
                required
                label="Name"
                value={channel.description || ''}
                onChange={onSetDescription}
                hasError={!!channelErrors.description}
                errorMessage={<p>{channelErrors.description}</p>}
            />
        </Box>
        <Box margin={[0, 0, 'large']}>
            <Input
                disabled={isReadOnly}
                label="Seat ID"
                maxLength={150}
                required
                value={channel.integration_key || ''}
                onChange={onSetIntegrationKey}
                hasError={!!channelErrors.integration_key}
                errorMessage={<p>{channelErrors.integration_key}</p>}
            />
        </Box>
    </>
);

const Content = ({
    loading,
    channelErrors,
    channel,
    error,
    onSetDescription,
    onSetIntegrationKey,
    onSaveChannel,
    isReadOnly,
}) => {
    const isSaveDisabled = loading || Object.keys(channelErrors).length > 0;
    const saveButtonText = channel.id ? 'Save' : 'Add New Seat';
    if (error) {
        return loadingErrorContent;
    }
    if (loading) {
        return (
            <Box padding={['large', 0]}>
                <Spinner
                    size="large"
                    message="Loading Seat"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Box>
        );
    }
    return (
        <Box padding={['large', 0]}>
            <Container size="small">
                <Form>
                    <Panel bordered>
                        <Box padding="base">
                            <FormContent
                                channel={channel}
                                channelErrors={channelErrors}
                                onSetDescription={onSetDescription}
                                onSetIntegrationKey={onSetIntegrationKey}
                                isReadOnly={isReadOnly}
                            />
                            <FormErrorContent channelErrors={channelErrors} />
                            {!isReadOnly && (
                                <Row justifyContent="flex-end">
                                    <Col span="auto">
                                        <Button
                                            type="submit"
                                            disabled={isSaveDisabled}
                                            onClick={onSaveChannel}
                                        >
                                            {saveButtonText}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Box>
                    </Panel>
                </Form>
            </Container>
        </Box>
    );
};

function ChannelDetail({
    channel,
    loading,
    saving,
    error,
    integration,
    channelErrors,
    deleteErrors,
    onSetDescription,
    onSetIntegrationKey,
    onSaveChannel,
    onDeleteChannel,
    isReadOnly,
}) {
    const [showModal, setShowModal] = useState(false);
    const { channelId } = useParams();

    const getBreadcrumbs = () => {
        const isNew = channelId === 'new';
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb
                key="settings"
                to="/settings/"
                label="Settings"
                isCollapsible
            />,
            <Crumb
                key="channels"
                to="/settings/channels/"
                label="Channels"
                isCollapsible
            />,
        ];

        if (error) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (isNew) {
            breadcrumbs.push(
                <Crumb
                    key="integration"
                    to={`/settings/channels/${integration.id}/`}
                    label={integration.name}
                    isCollapsible
                />,
                <Crumb key="create" label="Create New" />,
            );
        } else if (channel.description) {
            breadcrumbs.push(
                <Crumb
                    key="integration"
                    to={`/settings/channels/${integration.id}/`}
                    label={integration.name}
                    isCollapsible
                />,
                <Crumb key="channel" label={channel.description} />,
            );
        }

        return breadcrumbs;
    };

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
        >
            {channel.id && !loading && !isReadOnly && (
                <ActionBar>
                    <Button theme="outline" onClick={() => setShowModal(true)}>
                        Delete
                    </Button>
                </ActionBar>
            )}

            {saving ? (
                <LoadingLayer
                    message={channel.id ? 'Saving Seat' : 'Adding New Seat'}
                />
            ) : null}
            <Content
                channel={channel}
                channelErrors={channelErrors}
                loading={loading}
                error={error}
                onSaveChannel={onSaveChannel}
                onSetDescription={onSetDescription}
                onSetIntegrationKey={onSetIntegrationKey}
                isReadOnly={isReadOnly}
            />
            {showModal ? (
                <ConfirmModal
                    confirm="Yes, Delete Seat"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Seat"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage="
                        You were unable to delete the seat because there was an
                        unexpected error. Please try again in a few moments."
                    onCancel={() => setShowModal(false)}
                    onConfirm={onDeleteChannel}
                >
                    <p>Deleting a seat is permanent and cannot be undone.</p>
                </ConfirmModal>
            ) : null}
        </Layout>
    );
}

export default withRouter(ChannelDetail);
