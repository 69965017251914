import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';

const NoticePanel = ({ text }) => (
    <Box margin={['auto', 0, 0]}>
        <Panel theme="tertiary">
            <Box padding="base">
                <Row alignItems="center">
                    <Col span="auto">
                        <Text size="huge" color={['purple', 'base']}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Text>
                    </Col>

                    <Col>
                        <Text color={['purple', 'darker']}>{text}</Text>
                    </Col>
                </Row>
            </Box>
        </Panel>
    </Box>
);

export default NoticePanel;
