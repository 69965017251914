import React, { useState } from 'react';
import moment from 'moment';
import { getEventTagSourceOrDefault, getScheduleDescription } from 'js/utils';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import ClickArea from 'js/components/click-area/click-area';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import videoReportSubtypeOptions from '../../enums/video-report-subtype-options.enum';
import steps from '../../enums/steps.enum';
import styles from './summary-step.module.scss';

const StepInfo = ({
    title,
    hasError,
    errorMessage,
    children,
    onChangeStep,
}) => (
    <Box margin={['base', 0]}>
        <Box margin={[0, 0, 'smaller']}>
            <Text
                size="large"
                color={[hasError ? 'red' : 'gray', 'dark']}
                weight={hasError ? 'bold' : 'base'}
            >
                {title}{' '}
                <ClickArea onClick={onChangeStep}>
                    <span className={styles.edit}>Edit</span>
                </ClickArea>
            </Text>
        </Box>

        <Box margin={[0, 0, 'smaller']}>{children}</Box>

        {hasError && (
            <Box margin={[0, 0, 'smaller']}>
                <Text color={['red', 'dark']}>{errorMessage}</Text>
            </Box>
        )}
    </Box>
);

const StepValue = ({ value }) => (
    <Box margin={['smaller', 0]}>
        <Text weight="bolder" size="large">
            <div className={styles['long-name-wrapper']}>{value}</div>
        </Text>
    </Box>
);

const LineItemsTooltip = ({ lineItems, lineItemLabel }) => {
    const [isActive, setActive] = useState(false);

    return (
        <Box display="inline-block">
            <DropdownMenu
                content={
                    <Box padding={[0, 'base']}>
                        <Box margin={['small', 0]}>
                            <Text weight="bold" size="large">
                                {lineItemLabel}s
                            </Text>
                        </Box>

                        {lineItems.map(({ id, name }) => (
                            <Box margin={['small', 0]} key={id}>
                                {name && <strong>{name}</strong>} {id}
                            </Box>
                        ))}
                    </Box>
                }
                onHide={() => setActive(false)}
                showMenu={isActive}
                width="fixed"
            >
                <ClickArea onClick={() => setActive(!isActive)}>
                    <Text
                        size="small"
                        weight="bold"
                        color={['purple', isActive ? 'darker' : 'dark']}
                    >
                        View
                    </Text>
                </ClickArea>
            </DropdownMenu>
        </Box>
    );
};

export default function SummaryStep({
    template,
    context,
    errors,
    campaignLabel,
    lineItemLabel,
    eventHierarchy,
    onChangeStep,
}) {
    const sourceLabel = getEventTagSourceOrDefault(template.source);

    const visibleCampaigns = template.campaigns.map((templateCampaign) => {
        const campaign = eventHierarchy.find(
            (event) => event.campaign === templateCampaign.campaign_id,
        );

        return {
            id: campaign.campaign,
            name: campaign.campaign_name,
            lineItems: templateCampaign.line_items.map(
                ({ line_item_id: itemId }) => {
                    const lineItem = eventHierarchy.find(
                        (event) => event.line_item === itemId,
                    );

                    return {
                        id: lineItem.line_item,
                        name: lineItem.line_item_name,
                    };
                },
            ),
        };
    });

    const scheduleDescription = getScheduleDescription(template.schedule) || (
        <>
            <>
                <Text inline weight="base">
                    From
                </Text>{' '}
                <Text inline>
                    {moment(template.start_date).format('Do MMMM YYYY')}
                </Text>
            </>

            <Box margin={['smaller', 0, 0]}>
                <Text inline weight="base">
                    To
                </Text>{' '}
                <Text inline>
                    {moment(template.end_date).format('Do MMMM YYYY')}
                </Text>
            </Box>
        </>
    );

    const getReportTypeLabel = () => {
        switch (template.type) {
            case entityTypes.DISPLAY_REPORTS:
                return 'Display';
            case entityTypes.VIDEO_REPORTS:
                return `Video ${
                    videoReportSubtypeOptions.find(({ subtypes }) =>
                        subtypes.includes(template.subtype),
                    ).label
                }`;
            case entityTypes.EVENT_LOG_REPORTS:
                return 'Event Log Report';
            default:
                return '';
        }
    };

    const getReportTypeHint = () => {
        if (template.type === entityTypes.DISPLAY_REPORTS) {
            return (
                <p>
                    Analyze a campaign that is using 4D targeting via a deal to
                    discover how to boost performance and scale your context.
                </p>
            );
        }
        if (
            template.type === entityTypes.VIDEO_REPORTS &&
            !template.subtype !== videoReportSubtypes.YOUTUBE_GADS_FILE
        ) {
            return videoReportSubtypeOptions.find(({ subtypes }) =>
                subtypes.includes(template.subtype),
            ).hint;
        }
        return '';
    };

    return (
        <>
            <StepInfo
                title="Report Type"
                onChangeStep={() => onChangeStep(steps.REPORT_TYPE)}
            >
                {template.type === entityTypes.EVENT_LOG_REPORTS ? (
                    <Box margin={['small', 0, 0]}>
                        <Panel theme="secondary">
                            <Box padding="base">
                                <Text size="large" color={['gray', 'dark']}>
                                    Report Type
                                </Text>

                                <Box margin={['smaller', 0, 0]}>
                                    <Text weight="bolder" size="large">
                                        {getReportTypeLabel()}
                                    </Text>
                                </Box>
                            </Box>
                        </Panel>
                    </Box>
                ) : (
                    <>
                        <StepValue value={getReportTypeLabel()} />

                        <Text size="small">{getReportTypeHint()}</Text>
                    </>
                )}
            </StepInfo>

            <StepInfo
                title="Context"
                hasError={!!errors.context}
                errorMessage={<p>{errors.context}</p>}
                onChangeStep={() => onChangeStep(steps.CONTEXT)}
            >
                <StepValue value={context.name} />

                <Box margin={['smaller', 0, 0]}>
                    <Text size="small">{context.group_name}</Text>
                </Box>

                <Box margin={['smaller', 0, 0]}>
                    <Text size="small">{context.advertiser_name}</Text>
                </Box>
            </StepInfo>

            {template.subtype === videoReportSubtypes.YOUTUBE_GADS_FILE ? (
                <StepInfo
                    title="Data Source"
                    hasError={!!errors.file}
                    errorMessage={<p>{errors.file}</p>}
                    onChangeStep={() => onChangeStep(steps.DATA_SOURCE)}
                >
                    <StepValue value="Uploaded File" />
                </StepInfo>
            ) : (
                <>
                    <StepInfo
                        title="Data Source"
                        hasError={!!errors.source || !!errors.campaigns}
                        errorMessage={
                            <p>{errors.source || errors.campaigns}</p>
                        }
                        onChangeStep={() => onChangeStep(steps.DATA_SOURCE)}
                    >
                        <Box margin={['small', 0, 0]}>
                            <Panel theme="secondary">
                                <Box padding="base">
                                    <Text size="large" color={['gray', 'dark']}>
                                        Platform
                                    </Text>

                                    <Box margin={['smaller', 0, 0]}>
                                        <Text weight="bolder" size="large">
                                            {sourceLabel}
                                        </Text>
                                    </Box>

                                    <Box margin={['base', 0, 0]}>
                                        <Text
                                            size="large"
                                            color={['gray', 'dark']}
                                        >
                                            {campaignLabel}s
                                        </Text>

                                        {visibleCampaigns.map(
                                            ({ id, name, lineItems }) => (
                                                <Box
                                                    margin={['smaller', 0, 0]}
                                                    key={id}
                                                >
                                                    <Row gutter="small">
                                                        <Col span="auto">
                                                            <Text
                                                                size="large"
                                                                weight="bolder"
                                                            >
                                                                {name || id}
                                                            </Text>
                                                        </Col>

                                                        {name && (
                                                            <Col span="auto">
                                                                <InfoButton>
                                                                    ID - {id}
                                                                </InfoButton>
                                                            </Col>
                                                        )}
                                                    </Row>

                                                    <Text size="small">
                                                        {lineItems.length ===
                                                        0 ? (
                                                            `All ${campaignLabel} ${lineItemLabel}s`
                                                        ) : (
                                                            <>
                                                                {`${lineItems.length} ${lineItemLabel}s`}{' '}
                                                                <LineItemsTooltip
                                                                    lineItems={
                                                                        lineItems
                                                                    }
                                                                    lineItemLabel={
                                                                        lineItemLabel
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Text>
                                                </Box>
                                            ),
                                        )}
                                    </Box>
                                </Box>
                            </Panel>
                        </Box>
                    </StepInfo>
                    <StepInfo
                        title="Dates"
                        hasError={!!errors.schedule}
                        errorMessage={<p>{errors.schedule}</p>}
                        onChangeStep={() => onChangeStep(steps.DATES)}
                    >
                        <StepValue value={scheduleDescription} />
                    </StepInfo>
                </>
            )}

            <StepInfo
                title="Name"
                hasError={!!errors.name}
                errorMessage={<p>{errors.name}</p>}
                onChangeStep={() => onChangeStep(steps.NAME)}
            >
                <StepValue value={template.name} />
            </StepInfo>
        </>
    );
}
