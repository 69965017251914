import React from 'react';
import PropTypes from 'prop-types';
import styles from './radial-chart.module.scss';

/**
 * returns a SVG path representing an arc of ellipse
 * @see http://xahlee.info/js/svg_circle_arc.html
 *
 * @param {number} cx Center X
 * @param {number} cy Center Y
 * @param {number} rx Major radius
 * @param {number} ry Minor radius
 * @param {number} s0 Sweep start angle (rad > 0)
 * @param {number} sΔ Sweep delta angle (rad > 0)
 * @param {number} φ Rotation angle (rad)
 * @returns SVG arc path string
 *
 */
const arcPath = (cx, cy, rx, ry, s0, sΔ, φ) => {
    const { sin, cos, PI: π } = Math;

    const matrixMul = ([[a, b], [c, d]], [x, y]) => [
        a * x + b * y,
        c * x + d * y,
    ];
    const matrixRot = (x) => [
        [cos(x), -sin(x)],
        [sin(x), cos(x)],
    ];
    const vectorAdd = ([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2];

    const ε = 0.0001; // prevents a 360° arc to look like 0°
    const Δ = (sΔ - ε) % (2 * π);
    const rotMatrix = matrixRot(φ);
    const [sX, sY] = vectorAdd(
        matrixMul(rotMatrix, [rx * cos(s0), ry * sin(s0)]),
        [cx, cy],
    );
    const [x, y] = vectorAdd(
        matrixMul(rotMatrix, [rx * cos(s0 + Δ), ry * sin(s0 + Δ)]),
        [cx, cy],
    );
    const φdeg = φ * (180 / π);
    const largeArcFlag = Δ > π ? 1 : 0;
    const sweepFlag = Δ > 0 ? 1 : 0;

    return `M ${sX} ${sY} A ${rx} ${ry} ${φdeg} ${largeArcFlag} ${sweepFlag} ${x} ${y}`;
};

const Arc = ({ centerX, centerY, radius, angle, color }) => (
    <g className={styles.arc}>
        <circle cx={centerX} cy={centerY} r={radius} />
        <path
            d={arcPath(
                centerX,
                centerY,
                radius,
                radius,
                Math.PI,
                angle * (Math.PI / 180),
                Math.PI / 2,
            )}
            stroke={color}
        />
    </g>
);

const RadialChart = ({
    metrics,
    measures,
    maxMeasures,
    chartSize,
    children,
    onMouseEnter,
    onMouseLeave,
}) => {
    const centerX = chartSize / 2;
    const centerY = chartSize / 2;
    const padding = chartSize / 10;
    const radii = metrics.map(
        (_, index) => (chartSize - 4) / 2 - index * padding,
    );

    return (
        <div
            className={styles.container}
            style={{
                width: chartSize,
                height: chartSize,
            }}
            onMouseOver={onMouseEnter}
            onFocus={() => {}}
            onMouseLeave={onMouseLeave}
        >
            <svg
                className={styles.content}
                width={chartSize}
                height={chartSize}
                viewBox={`0 0 ${chartSize} ${chartSize}`}
            >
                <g
                    className={styles['radial-chart-icon-text']}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <foreignObject width="100%" height="100%">
                        <div>
                            <div>{children}</div>
                        </div>
                    </foreignObject>
                </g>

                {metrics.map(({ color }, index) => (
                    <Arc
                        key={radii[index]}
                        centerX={centerX}
                        centerY={centerY}
                        radius={radii[index]}
                        angle={360 * (measures[index] / maxMeasures[index])}
                        color={color}
                    />
                ))}
            </svg>
        </div>
    );
};

RadialChart.defaultProps = {
    chartSize: 235,
    children: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
};

RadialChart.propTypes = {
    metrics: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        }),
    ).isRequired,
    measures: PropTypes.array.isRequired,
    maxMeasures: PropTypes.array.isRequired,
    chartSize: PropTypes.number,
    children: PropTypes.node,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

export default RadialChart;
