import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'; // safari sucks.

import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AdvertiserDetailPage from 'js/pages/advertiser-detail';
import AdvertiserListPage from 'js/pages/advertiser-list';
import ContextListPage from 'js/pages/context-list';
import CampaignListPage from 'js/pages/campaign-list';
import CampaignDetailPage from 'js/pages/campaign-detail';
import ChannelDetailPage from 'js/pages/channel-detail';
import ChannelListPage from 'js/pages/channel-list';
import ContextDetailPage from 'js/pages/context-detail';
import ErrorBoundary from 'js/components/error-boundary/error-boundary';
import ErrorPage from 'js/pages/error/error';
import DiscoverPage from 'js/pages/discover';
import RenameDataSourcesPage from 'js/pages/rename-data-sources';
import HomePage from 'js/pages/home';
import IntegrationListPage from 'js/pages/integration-list';
import LoginPage from 'js/pages/login';
import NotificationListPage from 'js/pages/notification-list';
import PasswordResetPage from 'js/pages/password-reset';
import ReportTemplateListPage from 'js/pages/report-template-list';
import ReportTemplateDetailPage from 'js/pages/report-template-detail';
import ReportDisplayDetailPage from 'js/pages/report-detail/report-display-detail';
import ReportVideoDetailPage from 'js/pages/report-detail/report-video-detail';
import ReportEventLogDetailPage from 'js/pages/report-detail/report-event-log-detail';
import ReportTagPage from 'js/pages/report-tag';
import SetPasswordPage from 'js/pages/set-password';
import SettingsMenuPage from 'js/pages/settings-menu';
import StatusListPage from 'js/pages/status-list';
import TermsAndConditionsPage from 'js/pages/terms-and-conditions';
import UserDetailPage from 'js/pages/user-detail';
import UserListPage from 'js/pages/user-list';
import UserSettingsDetailPage from 'js/pages/user-settings-detail';
import UserSettingsMenuPage from 'js/pages/user-settings-menu';
import UserSettingsNotificationsPage from 'js/pages/user-settings-notifications';
import UserSettingsPasswordChangePage from 'js/pages/user-settings-password-change';
import {
    clearPresets,
    getLoginPath,
    get404Path,
    getJwtPayload,
    validJwtAge,
} from 'js/utils';
import { withNotifications } from 'js/hocs';
import serviceTypes from 'js/enums/service-types.enum';
import YoutubeSdfDownloadPage from 'js/pages/youtube-sdf-download';

function redirect(to) {
    return () => <Redirect to={to} />;
}

function handlePostMessage(event) {
    if (event.origin === window.location.origin) return null; // fuck you webpack
    const token = localStorage.getItem('AuthToken');
    const payload =
        event.data === getJwtPayload(token).username ? token : undefined; // todo: remove jwt on bad payload
    event.source.postMessage(payload, event.origin);
    return null;
}

function preventDefault(event) {
    event.preventDefault();
}

const WithNotificationsSwitch = withNotifications(Switch);

const AppSwitch = ({ hasNotifications, children }) => (
    <ErrorBoundary to="/errors/">
        {hasNotifications ? (
            <WithNotificationsSwitch>{children}</WithNotificationsSwitch>
        ) : (
            <Switch>{children}</Switch>
        )}
    </ErrorBoundary>
);

class App extends Component {
    componentDidMount() {
        if (window.location !== window.parent.location) {
            window.addEventListener('message', handlePostMessage, false);
        }
        window.addEventListener('drop', preventDefault, false);
        window.addEventListener('dragover', preventDefault, false);
    }

    componentWillUnmount() {
        if (window.location !== window.parent.location) {
            window.removeEventListener('message', handlePostMessage, false);
        }
        window.removeEventListener('drop', preventDefault, false);
        window.removeEventListener('dragover', preventDefault, false);
    }

    render() {
        const token = localStorage.getItem('AuthToken');
        const {
            is_internal: isInternalUser,
            is_terms_accepted: isTermsAccepted,
            services = [],
        } = getJwtPayload(token);
        const idRegex =
            '[A-Za-f][A-Za-z0-9_-]{7}[Q-T][A-Za-z0-9_-][CGKOSWaeimquy26-][A-Za-z0-9_-]{10}[AQgw]';

        if (token && validJwtAge(token)) {
            if (!isTermsAccepted) {
                return (
                    <AppSwitch>
                        <Route
                            exact
                            path="/terms-and-conditions/"
                            component={TermsAndConditionsPage}
                        />
                        <Route exact path="/errors/" component={ErrorPage} />
                        <Route exact path="/errors/403/">
                            <ErrorPage code={403} />
                        </Route>
                        <Route exact path="/errors/404/">
                            <ErrorPage code={404} />
                        </Route>
                        <Route
                            path="/"
                            component={redirect('/terms-and-conditions/')}
                        />
                    </AppSwitch>
                );
            }
            const insightsService = services.find(
                (item) => item.name === serviceTypes.INSIGHTS,
            );
            const discoverService = services.find(
                (item) => item.name === serviceTypes.DISCOVER,
            );
            return (
                <AppSwitch hasNotifications>
                    <Route exact path="/" component={HomePage} />
                    <Route
                        exact
                        path={`/contexts/:contextId(${idRegex}|new)/`}
                        component={ContextDetailPage}
                    />
                    <Route
                        exact
                        path={`/advertisers/:advertiserId(${idRegex}|new)/`}
                        component={AdvertiserDetailPage}
                    />
                    <Route
                        exact
                        path={`/campaigns/:campaignId(${idRegex}|new)/`}
                        component={CampaignDetailPage}
                    />
                    <Route
                        exact
                        path="/context/"
                        component={AdvertiserListPage}
                    />
                    <Route
                        exact
                        path={`/context/:advertiserId(${idRegex})/`}
                        component={CampaignListPage}
                    />
                    <Route
                        exact
                        path={`/context/:advertiserId(${idRegex})/:campaignId(${idRegex})/`}
                        component={ContextListPage}
                    />
                    {insightsService && [
                        <Route
                            exact
                            path="/insights/"
                            key="/insights/"
                            component={ReportTemplateListPage}
                        />,
                        <Route
                            exact
                            path={`/insights/:advertiserId(${idRegex})/`}
                            key={`/insights/:advertiserId(${idRegex})/`}
                            component={ReportTemplateListPage}
                        />,
                        <Route
                            exact
                            path="/insights/new/"
                            key="/insights/new/"
                            component={ReportTemplateDetailPage}
                        />,
                        <Route
                            exact
                            path={`/insights/:advertiserId(${idRegex})/:templateId(${idRegex})/`}
                            key={`/insights/:advertiserId(${idRegex})/:templateId(${idRegex})/`}
                            component={ReportTemplateDetailPage}
                        />,
                        <Route
                            exact
                            path={`/insights/display/:templateId(${idRegex})/:reportId(${idRegex}|)/`}
                            key={`/insights/display/:templateId(${idRegex})/:reportId(${idRegex}|)/`}
                            component={ReportDisplayDetailPage}
                        />,
                        <Route
                            exact
                            path={`/insights/video/:templateId(${idRegex})/:reportId(${idRegex}|)/`}
                            key={`/insights/video/:templateId(${idRegex})/:reportId(${idRegex}|)/`}
                            component={ReportVideoDetailPage}
                        />,
                        <Route
                            exact
                            path={`/insights/event-log/:templateId(${idRegex})/:reportId(${idRegex}|)/`}
                            key={`/insights/event-log/:templateId(${idRegex})/:reportId(${idRegex}|)/`}
                            component={ReportEventLogDetailPage}
                        />,
                    ]}
                    <Route
                        exact
                        path="/(insights|settings)/rename-data-sources/"
                        component={RenameDataSourcesPage}
                    />
                    <Route
                        exact
                        path="/insights/tag/"
                        component={ReportTagPage}
                    />
                    <Route
                        exact
                        path="/notifications/"
                        component={NotificationListPage}
                    />
                    <Route
                        exact
                        path={`/integrations/dv360/sdf-download/:contextId(${idRegex})/`}
                    >
                        <YoutubeSdfDownloadPage />
                    </Route>
                    <Route exact path="/errors/">
                        <ErrorPage isLoggedIn />
                    </Route>
                    <Route exact path="/errors/403/">
                        <ErrorPage code={403} isLoggedIn />
                    </Route>
                    <Route exact path="/errors/404/">
                        <ErrorPage code={404} isLoggedIn />
                    </Route>
                    <Route exact path={`/errors/401/:accountId(${idRegex})/`}>
                        <ErrorPage code="account" isLoggedIn />
                    </Route>
                    <Route
                        exact
                        path="/settings/"
                        component={SettingsMenuPage}
                    />
                    {discoverService && (
                        <Route
                            exact
                            path={[
                                '/discover/:tabId(feature|video|page)/',
                                '/discover/:tabId(feature|video|page)/:resource/',
                            ]}
                            key="/discover/:tabId(feature|video|page)/"
                            component={DiscoverPage}
                        />
                    )}
                    <Route
                        exact
                        path="/settings/your-profile/"
                        component={UserSettingsMenuPage}
                    />
                    <Route
                        exact
                        path="/settings/your-profile/details/"
                        component={UserSettingsDetailPage}
                    />
                    <Route
                        exact
                        path="/settings/your-profile/notifications/"
                        component={UserSettingsNotificationsPage}
                    />
                    <Route
                        exact
                        path="/settings/your-profile/password/"
                        component={UserSettingsPasswordChangePage}
                    />
                    <Route
                        exact
                        path={`/settings/channels/:integrationId(${idRegex})/`}
                        component={ChannelListPage}
                    />
                    <Route
                        exact
                        path={`/settings/channels/:integrationId(${idRegex})/:channelId(${idRegex}|new)/`}
                        component={ChannelDetailPage}
                    />
                    <Route
                        exact
                        path="/settings/channels/"
                        component={IntegrationListPage}
                    />
                    <Route
                        exact
                        path="/settings/users/"
                        component={UserListPage}
                    />
                    <Route
                        exact
                        path={`/settings/users/:userId(${idRegex}|new)`}
                        component={UserDetailPage}
                    />
                    {isInternalUser && (
                        <Route
                            exact
                            path="/settings/status/"
                            component={StatusListPage}
                        />
                    )}
                    <Route
                        exact
                        path="/terms-and-conditions/"
                        component={TermsAndConditionsPage}
                    />
                    <Route
                        exact
                        path="/logout/"
                        component={() => {
                            localStorage.removeItem('AuthToken');
                            clearPresets();
                            return redirect('/login/')();
                        }}
                    />
                    <Route path="/" component={redirect(get404Path())} />
                </AppSwitch>
            );
        }
        // remove auth because the user's token has expired.
        localStorage.removeItem('AuthToken');
        return (
            <AppSwitch>
                <Route exact path="/" component={redirect('/login/')} />
                <Route exact path="/login/" component={LoginPage} />
                <Route
                    exact
                    path="/password-reset/"
                    component={PasswordResetPage}
                />
                <Route
                    exact
                    path="/password-reset/:token/"
                    component={SetPasswordPage}
                />
                <Route exact path="/welcome/:token/">
                    <SetPasswordPage isNewUser />
                </Route>
                <Route exact path="/errors/" component={ErrorPage} />
                <Route exact path="/errors/401/">
                    <ErrorPage code={401} />
                </Route>
                <Route exact path="/errors/403/">
                    <ErrorPage code={403} />
                </Route>
                <Route exact path="/errors/429/">
                    <ErrorPage code={429} />
                </Route>
                <Route path="/" component={redirect(getLoginPath())} />
            </AppSwitch>
        );
    }
}

export default withRouter(App);
