import React from 'react';
import Box from 'js/components/box/box';
import CardCampaign from 'js/components/card/card-campaign';
import Container from 'js/components/container/container';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';

const FavoriteCampaigns = ({ campaigns, onDelete, onToggleFavorite }) => (
    <Container>
        <Box padding={['large', 0, 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    <Text weight="bold" size="large">
                        Favorites
                    </Text>

                    {campaigns.map((campaign) => (
                        <Box padding={['small', 0, 0]} key={campaign.id}>
                            <CardCampaign
                                isSlim
                                campaign={campaign}
                                onDeleteCampaign={() => onDelete(campaign.id)}
                                onToggleFavorite={() =>
                                    onToggleFavorite(campaign.id)
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Panel>
        </Box>
    </Container>
);

export default FavoriteCampaigns;
