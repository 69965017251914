import React, { useRef } from 'react';
import { getNumber } from 'js/components/number/number';
import Legend from 'js/components/treemap-chart/legend';
import TreeMapChart from 'js/components/treemap-chart/treemap-chart';
import TrendChart from 'js/components/trend-chart/trend-chart';
import BubbleChart from 'js/components/bubble-chart/bubble-chart';

const trendChartMetrics = [
    {
        id: 'impressions',
        name: 'Impressions',
        format: 'k',
        getValue: (i) => i.impressions,
    },
    {
        id: 'clicks',
        name: 'Clicks',
        format: 'k',
        getValue: (i) => i.clicks,
    },
    {
        id: 'ctr',
        name: 'CTR',
        format: '%',
        getValue: (i) =>
            i.clicks && i.impressions ? i.clicks / i.impressions : 0,
    },
    {
        id: 'inViews',
        name: 'Viewable Imp.',
        format: 'k',
        getValue: (i) => i.in_views,
    },
    {
        id: 'viewRate',
        name: 'View Rate',
        format: '%',
        getValue: (i) =>
            i.in_views && i.impressions ? i.in_views / i.impressions : 0,
    },
];

export const chartDimensionMap = {
    portrait: {
        trend: [450, 100],
        treemap: [535, 250],
        bubble: [535, 220],
    },

    landscape: {
        trend: [594, 154],
        treemap: [586, 250],
        bubble: [586, 250],
    },
};

const getTrendChartData = (report) =>
    trendChartMetrics.map((i) => ({
        ...i,
        data: report.overviews.map((record) => ({
            date: record.activity_date,
            value: i.getValue(record),
        })),
    }));

const ImpressionsChart = ({ report, orientation }) => (
    <div
        style={{
            width: chartDimensionMap[orientation].trend[0] * 2,
            height: chartDimensionMap[orientation].trend[1] * 2,
        }}
    >
        {report.overviews.some((i) => i.impressions > 0) && (
            <TrendChart
                series={getTrendChartData(report).map((i) => ({
                    ...i,
                    color: { impressions: '#6673FF', clicks: '#137F86' }[i.id],
                    axis: { impressions: 'left', clicks: 'right' }[i.id],
                }))}
            />
        )}
    </div>
);

const CTRChart = ({ report, orientation }) => (
    <div
        style={{
            width: chartDimensionMap[orientation].trend[0] * 2,
            height: chartDimensionMap[orientation].trend[1] * 2,
        }}
    >
        {report.overviews.some((i) => i.clicks > 0) && (
            <TrendChart
                series={getTrendChartData(report).map((i) => ({
                    ...i,
                    color: { ctr: '#1CBEC9' }[i.id],
                    axis: { ctr: 'left' }[i.id],
                }))}
            />
        )}
    </div>
);

const ViewabilityChart = ({ report, orientation }) => (
    <div
        style={{
            width: chartDimensionMap[orientation].trend[0] * 2,
            height: chartDimensionMap[orientation].trend[1] * 2,
        }}
    >
        {report.overviews.some((i) => i.in_views > 0) && (
            <TrendChart
                series={getTrendChartData(report).map((i) => ({
                    ...i,
                    color: { viewRate: '#79E5EC', inViews: '#2684FF' }[i.id],
                    axis: { viewRate: 'left', inViews: 'right' }[i.id],
                }))}
            />
        )}
    </div>
);

const TopicGroupsChart = ({ report, orientation }) => {
    const topGroupsByImpressions = [...report.topic_groups]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 4)
        .map((group) => ({
            label: group.topic_group_name,
            sublabel: `${getNumber({
                value: group.impressions,
                maximumFractionDigits: 0,
            })} (${getNumber({
                value: group.impressions / report.impressions,
                maximumFractionDigits: 2,
                isPercentage: true,
            })})`,
            value: group.impressions,
        }));

    const treeMapChartData = {
        children: topGroupsByImpressions.concat({
            label: 'Other',
            value:
                topGroupsByImpressions[topGroupsByImpressions.length - 1]
                    ?.value ?? 0,
        }),
    };

    return (
        <div
            style={{
                width: chartDimensionMap[orientation].treemap[0] * 2,
                height: chartDimensionMap[orientation].treemap[1] * 2,
            }}
        >
            {topGroupsByImpressions.some((i) => i.value > 0) && (
                <TreeMapChart
                    data={treeMapChartData}
                    rightLegend={
                        <Legend
                            lowLabel="Fewer Impressions"
                            highLabel="More Impressions"
                        />
                    }
                />
            )}
        </div>
    );
};

const KeywordsChart = ({ report, orientation }) => {
    const bubbleChartData = [...(report.keywords || [])]
        .sort((a, b) => b.impressions - a.impressions)
        .map((keyword) => ({
            label: keyword.name,
            value: keyword.impressions,
        }))
        .slice(0, 50);

    return (
        <div
            style={{
                width: chartDimensionMap[orientation].bubble[0] * 2,
                height: chartDimensionMap[orientation].bubble[1] * 2,
            }}
        >
            {bubbleChartData.some((i) => i.value > 0) ? (
                <BubbleChart
                    data={bubbleChartData}
                    legend={{
                        lowLabel: 'Fewer Impressions',
                        highLabel: 'More Impressions',
                        position: 'bottom',
                    }}
                    viewport={[0.2, 0.2, 0.8, 0.8]}
                />
            ) : null}
        </div>
    );
};

const DisplayCharts = ({ report, orientation, chartRefs }) => {
    const refs = {
        IMPRESSIONS: useRef(),
        CTR: useRef(),
        VIEWABILITY: useRef(),
        TOPIC_GROUPS: useRef(),
        KEYWORDS: useRef(),
    };
    Object.assign(chartRefs.current, refs);

    return (
        <>
            <div ref={refs.IMPRESSIONS}>
                <ImpressionsChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.CTR}>
                <CTRChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.VIEWABILITY}>
                <ViewabilityChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.TOPIC_GROUPS}>
                <TopicGroupsChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.KEYWORDS}>
                <KeywordsChart report={report} orientation={orientation} />
            </div>
        </>
    );
};

export default DisplayCharts;
