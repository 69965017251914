import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';

const Legend = ({ lowLabel, highLabel, colorScale, sizeScale }) => {
    const getColor = colorScale.domain([0, 1]);
    const getSize = sizeScale.domain([0, 1]);
    return (
        <Row alignItems="center" gutter="small">
            <Col span="auto">
                <Text color={['gray', 'dark']}>{lowLabel}</Text>
            </Col>
            <Col span="auto">
                <Row alignItems="center" gutter="smaller">
                    {[
                        { width: getSize(0.0) * 17, color: getColor(0.0) },
                        { width: getSize(0.5) * 17, color: getColor(0.5) },
                        { width: getSize(1.0) * 17, color: getColor(1.0) },
                    ].map(({ width, color }) => (
                        <Col span="auto" key={`${width}_${color}`}>
                            <svg width={width} viewBox="0 0 100 100">
                                <rect
                                    fill={color}
                                    width="100"
                                    height="100"
                                    cx="50"
                                    cy="50"
                                />
                            </svg>
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col span="auto">
                <Text color={['gray', 'dark']}>{highLabel}</Text>
            </Col>
        </Row>
    );
};

Legend.defaultProps = {
    lowLabel: 'Low',
    highLabel: 'High',
    colorScale: d3.scaleSequential(() => '#6673FF'),
    sizeScale: d3
        .scaleSequential()
        .interpolator(d3.interpolateNumber(0.75, 1.25)),
};

Legend.propTypes = {
    lowLabel: PropTypes.string,
    highLabel: PropTypes.string,
    colorScale: PropTypes.func,
    sizeScale: PropTypes.func,
};

export default Legend;
