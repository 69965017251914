import React, { useState, useLayoutEffect } from 'react';
import { api, getJwtPayload } from 'js/utils';
import permissionGroups from 'js/enums/permission-groups.enum';
import UserList from './user-list';

const UserListPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const { roles } = getJwtPayload(localStorage.getItem('AuthToken'));
    const isReadOnly =
        roles.includes(permissionGroups.MANAGER) ||
        roles.includes(permissionGroups.VIEW_ONLY);

    const hasLoadingError = Object.keys(errors).some(
        (key) => key === 'loadingError',
    );

    const loadUserData = async () => {
        const data = await api().users.list({
            verbose: true,
            ordering: 'email',
        });
        setUsers(data.results);
        setLoading(false);
    };

    const loadData = async () => {
        setLoading(true);
        setErrors({});

        try {
            await loadUserData();
        } catch (err) {
            setErrors({
                loadingError: 'Failed to load users',
            });
        } finally {
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        loadData();

        return () => {
            api().abortAll();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <UserList
            loading={loading}
            users={users}
            hasErrors={hasLoadingError}
            isReadOnly={isReadOnly}
        />
    );
};

export default UserListPage;
