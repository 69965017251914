import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api, getJwtPayload, setDocumentTitle, validEmail } from 'js/utils';
import { permissions } from 'js/constants';
import ActionBar from 'js/components/action-bar/action-bar';
import Button from 'js/components/button/button';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import permissionGroups from 'js/enums/permission-groups.enum';
import UserDetail from './components/user-detail';

const omit = (obj, ...props) => {
    const copy = { ...obj };
    props.forEach((prop) => delete copy[prop]);

    return copy;
};

const UserDetailPage = () => {
    const { userId } = useParams();
    const history = useHistory();
    const isNew = userId === 'new';

    const [loadErrors, setLoadErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
        is_admin: false,
        roles: [permissions[0].value],
    });
    const [amending, setAmending] = useState(false);
    const [saveErrors, setSaveErrors] = useState({});
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteErrors, setDeleteErrors] = useState({});

    const hasLoadingError = Object.keys(loadErrors || {}).length > 0;
    const hasSavingError = Object.keys(saveErrors || {}).length > 0;

    const { roles: jwtRoles } = getJwtPayload(
        localStorage.getItem('AuthToken'),
    );
    const isReadOnly =
        jwtRoles.includes(permissionGroups.MANAGER) ||
        jwtRoles.includes(permissionGroups.VIEW_ONLY);

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb
                key="settings"
                to="/settings/"
                label="Settings"
                isCollapsible
            />,
            <Crumb
                key="users"
                to="/settings/users/"
                label="Users"
                isCollapsible
            />,
        ];

        if (isNew) {
            breadcrumbs.push(<Crumb key="create" label="Create New" />);

            return breadcrumbs;
        }

        if (hasLoadingError) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (user.email) {
            breadcrumbs.push(<Crumb key="email" label={user.email} />);
        }

        return breadcrumbs;
    };

    const validateUser = ({ userData = user, throwErrors = false } = {}) => {
        setAmending(true);

        const errors = { ...saveErrors };
        if (!userData.email) {
            errors.email = 'An email address is required.';
        } else if (!validEmail(userData.email)) {
            errors.email = 'Enter a valid email address.';
        }
        if (!userData.first_name) {
            errors.first_name = "The user's first name is required.";
        }
        if (!userData.last_name) {
            errors.last_name = "The user's surname is required.";
        }
        if (errors.email || errors.first_name || errors.last_name) {
            setSaveErrors(errors);
            if (throwErrors) throw errors;
        }
    };

    const loadUser = async () => {
        setLoading(true);

        try {
            const params = { verbose: true };
            const currentUser = await api().users.retrieve(userId, params);
            setDocumentTitle([currentUser.email, 'Users', 'Settings']);
            setUser(currentUser);
            validateUser({ userData: currentUser });
        } catch (err) {
            setLoadErrors({
                loadingError: 'There was an error loading the user',
            });
        } finally {
            setLoading(false);
        }
    };

    const onSaveUser = async () => {
        setSaveErrors({});
        setSaving(true);
        try {
            validateUser({ throwErrors: true });

            if (isNew) {
                await api().users.create(user);
            } else {
                await api().users.update(user.id, user);
            }
            history.push('/settings/users/');
        } catch (errors) {
            setSaveErrors(errors);
        } finally {
            setSaving(false);
        }
    };

    const onDeleteUser = async () => {
        setDeleteErrors({});
        try {
            await api().users.destroy(user.id);
            history.push('/settings/users/');
        } catch (err) {
            setDeleteErrors(err);
        }
    };

    const onSetEmail = (e) => {
        setSaveErrors(omit(saveErrors, 'email'));
        setUser({
            ...user,
            email: e.target.value,
        });
    };

    const onSetFirstName = (e) => {
        setSaveErrors(omit(saveErrors, 'first_name'));
        setUser({
            ...user,
            first_name: e.target.value,
        });
    };

    const onSetLastName = (e) => {
        setSaveErrors(omit(saveErrors, 'last_name'));
        setUser({
            ...user,
            last_name: e.target.value,
        });
    };

    const setPermissions = (roles) => {
        setSaveErrors(omit(saveErrors, 'roles'));
        setUser({
            ...user,
            roles: [...roles],
        });
    };

    useLayoutEffect(() => {
        if (!isNew) {
            loadUser();
        }

        return () => api().abortAll();
    }, [isNew]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (amending && !loading) {
            validateUser();
        }
    }, [user.email, user.first_name, user.last_name]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
        >
            <>
                {saving && (
                    <LoadingLayer
                        message={isNew ? 'Creating User' : 'Saving User'}
                    />
                )}
                {!isNew && loading && <LoadingLayer message="Loading User" />}
                {isDeleteModalVisible && (
                    <ConfirmModal
                        title={`Are You Sure You Want To Delete ${user.email}?`}
                        confirm="Yes, Delete User"
                        cancel="No, Cancel"
                        loadingMessage="Deleting User"
                        hasError={Object.keys(deleteErrors).length > 0}
                        errorMessage="
                            You were unable to delete the user because there
                            was an unexpected error. Please try again in a few
                            moments."
                        onCancel={() => {
                            setIsDeleteModalVisible(false);
                        }}
                        onConfirm={onDeleteUser}
                    >
                        <p>
                            They will no longer be able to access the account.
                        </p>
                    </ConfirmModal>
                )}

                {!isNew && !isReadOnly && (
                    <ActionBar>
                        <Button
                            theme="outline"
                            onClick={() => {
                                setIsDeleteModalVisible(true);
                            }}
                        >
                            Delete
                        </Button>
                    </ActionBar>
                )}

                <UserDetail
                    loading={loading}
                    user={user}
                    isNew={isNew}
                    hasLoadingError={hasLoadingError}
                    hasSavingError={hasSavingError}
                    userErrors={saveErrors}
                    onSetEmail={onSetEmail}
                    onSetFirstName={onSetFirstName}
                    onSetLastName={onSetLastName}
                    setPermissions={setPermissions}
                    onSaveUser={onSaveUser}
                    isReadOnly={isReadOnly}
                />
            </>
        </Layout>
    );
};

export default UserDetailPage;
