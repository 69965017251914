import * as d3 from 'd3';
import { useResizeObserver } from 'js/hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import { getColorDomainFn, getTopParent, resolvePath } from './helpers';
import Node from './node';
import styles from './treemap-chart.module.scss';

const useTreeMapChart = ({ width, height, data }) => {
    if (!width || !height) return null;

    const d3TreeMap = d3
        .treemap()
        .tile(d3.treemapSquarify.ratio(1))
        .size([width, height])
        .round(true)
        .padding(1)(
        d3
            .hierarchy(data)
            .sum((s) => s.value)
            .sort((a, b) => b.value - a.value),
    );

    let numberItemId = 0;

    return d3TreeMap.each((item) => {
        item.customId = numberItemId.toString(); // eslint-disable-line no-param-reassign
        numberItemId += 1;
    });
};

function TreeMapChart({
    data,
    customD3ColorScale,
    colorableValue,
    hoverColor,
    activeColor,
    leftLegend,
    rightLegend,
    dropdownTooltip,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) {
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    const svgRef = useRef();
    const legendRef = useRef();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [legendHeight, setLegendHeight] = useState(0);
    const treemapHeight = height - legendHeight;
    const treeMapChart = useTreeMapChart({
        width,
        height: treemapHeight,
        data,
    });

    const getBgColorFromNode = (node) => {
        const colorDomainFn = getColorDomainFn(
            getTopParent(node),
            colorableValue,
            customD3ColorScale,
        );

        let backgroundColor = colorDomainFn(resolvePath(node, colorableValue));

        if (node.parent === null) {
            backgroundColor = colorDomainFn(1);
        }

        return {
            bgColor: backgroundColor,
        };
    };

    const getNode = (node) => {
        const { customId, data: nodeData, x0, x1, y0, y1 } = node;
        const hasChildren = node.children?.length > 0;
        const nodeTotalNodes = node.descendants().length - 1;
        const { bgColor } = getBgColorFromNode(node);

        return (
            <Node
                isDropdown={!!dropdownTooltip}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                hoverColor={hoverColor}
                activeColor={activeColor}
                svgRef={svgRef}
                nodeData={nodeData}
                bgColor={bgColor}
                style={{
                    fontVariant: 'normal',
                    fontWeight: 'bold',
                    fontFamily: 'Arial',
                }}
                hasChildren={hasChildren}
                id={customId}
                key={customId}
                nodeTotalNodes={nodeTotalNodes}
                x0={x0}
                x1={x1}
                y0={y0}
                y1={y1}
            />
        );
    };

    const reactNodes = [];
    const iterateAllChildren = (mainNode) => {
        if ('children' in mainNode && mainNode.children.length > 0) {
            mainNode.children.forEach((element) => {
                reactNodes.push(getNode(element));
                iterateAllChildren(element);
            });
        }
    };

    if (treeMapChart) {
        iterateAllChildren(treeMapChart);
    }

    useEffect(() => {
        if (!dimensions) return;
        // getting dimensions width height dynamically
        setWidth(dimensions.width);
        setHeight(dimensions.height);
        if (legendRef.current) {
            setLegendHeight(legendRef.current.getBoundingClientRect().height);
        }
    }, [dimensions, data]);

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.container}>
                <svg ref={svgRef} width={width} height={treemapHeight}>
                    {reactNodes}
                </svg>
                {dropdownTooltip}
                {(leftLegend || rightLegend) && (
                    <div ref={legendRef} className={styles.legend}>
                        <Box padding="base">
                            <Row
                                alignItems="center"
                                justifyContent="space-between"
                                gap="base"
                            >
                                <Col span="auto">{leftLegend}</Col>
                                <Col span="auto" />
                                <Col span="auto">{rightLegend}</Col>
                            </Row>
                        </Box>
                    </div>
                )}
            </div>
        </div>
    );
}

TreeMapChart.defaultProps = {
    customD3ColorScale: d3.scaleSequential(() => '#6673FF'),
    colorableValue: 'value',
    hoverColor: undefined,
    activeColor: undefined,
    leftLegend: undefined,
    rightLegend: undefined,
    dropdownTooltip: undefined,
    onClick: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
};

TreeMapChart.propTypes = {
    data: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.number,
                link: PropTypes.string,
            }),
        ),
    }).isRequired,
    customD3ColorScale: PropTypes.func,
    colorableValue: PropTypes.string,
    hoverColor: PropTypes.string,
    activeColor: PropTypes.string,
    leftLegend: PropTypes.node,
    rightLegend: PropTypes.node,
    dropdownTooltip: PropTypes.node,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

export default TreeMapChart;
