import React, { useState } from 'react';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import KeywordInput from 'js/components/input/keyword-input';
import Label from 'js/components/label/label';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import TopicSelector from 'js/components/topic-selector/topic-selector';
import ruleTypes from 'js/enums/rule-types.enum';
import ruleItemTypes from 'js/enums/rule-item-types.enum';
import TopicButton from 'js/components/button/topic-button';
import languageTypes from 'js/enums/language-types.enum';

const GuardrailsSelector = ({
    isVideo,
    isReadOnly,
    controls,
    topicGroups,
    logoGroups,
    language,
    onChangeControls,
}) => {
    const initialKeywords =
        controls
            .find(
                ({ action, attribute }) =>
                    action === 'add' && attribute === 'keywords',
            )
            ?.blacklisted_entities.map(({ name }) => name) || [];
    const initialTopics =
        controls
            .find(
                ({ action, attribute }) =>
                    action === 'add' && attribute === 'topics',
            )
            ?.blacklisted_entities.map(({ name }) => name) || [];
    const initialLogos =
        controls
            .find(
                ({ action, attribute }) =>
                    action === 'add' && attribute === 'logos',
            )
            ?.blacklisted_entities.map(({ name }) => name) || [];

    const [keywords, setKeywords] = useState(initialKeywords);
    const [topics, setTopics] = useState(initialTopics);
    const [logos, setLogos] = useState(initialLogos);

    const sortedKeywords = [...keywords].sort((a, b) => a.localeCompare(b));
    const detailedTopicList = topicGroups
        .map((g) => g.topics)
        .flat()
        .filter((t) => topics.includes(t.id));
    const nonBSTopicGroups = topicGroups
        .map((group) => ({
            ...group,
            topics: group.topics.filter((topic) => !topic.is_brand_safety),
        }))
        .filter((group) => group.topics.length);
    const detailedLogoList = logoGroups
        .map((g) => g.logos)
        .flat()
        .filter((l) => logos.includes(l.id));

    const mockRules = new RuleSet([
        {
            key: 0,
            aggregation: ruleTypes.EXCLUDED,
            keywords: [...keywords],
            topics: [...topics],
            logos: [...logos],
        },
    ]);

    const onToggleTopic = (topic) => {
        const updatedTopics = topics.includes(topic)
            ? topics.filter((t) => t !== topic)
            : [...topics, topic];
        const updatedControls = controls.map((c) => {
            if (c.action === 'add' && c.attribute === 'topics') {
                return {
                    ...c,
                    blacklisted_entities: updatedTopics.map((t) => ({
                        name: t,
                    })),
                };
            }
            return c;
        });

        setTopics(updatedTopics);
        onChangeControls(updatedControls);
    };

    const onToggleTopicGroup = (group) => {
        const groupTopicIds = topicGroups
            .find((g) => g.id === group)
            .topics.map((t) => t.id);

        const isGroupFullyBlocked = groupTopicIds.every((t) =>
            topics.includes(t),
        );
        const topicsWithoutGroup = topics.filter(
            (t) => !groupTopicIds.includes(t),
        );
        const updatedTopics = isGroupFullyBlocked
            ? topicsWithoutGroup
            : [...topicsWithoutGroup, ...groupTopicIds];

        const updatedControls = controls.map((c) => {
            if (c.action === 'add' && c.attribute === 'topics') {
                return {
                    ...c,
                    blacklisted_entities: updatedTopics.map((t) => ({
                        name: t,
                    })),
                };
            }
            return c;
        });

        setTopics(updatedTopics);
        onChangeControls(updatedControls);
    };

    const onToggleLogo = (logo) => {
        const updatedLogos = logos.includes(logo)
            ? logos.filter((l) => l !== logo)
            : [...logos, logo];
        const updatedControls = controls.map((c) => {
            if (c.action === 'add' && c.attribute === 'logos') {
                return {
                    ...c,
                    blacklisted_entities: updatedLogos.map((t) => ({
                        name: t,
                    })),
                };
            }
            return c;
        });

        setLogos(updatedLogos);
        onChangeControls(updatedControls);
    };

    const onToggleLogoGroup = (group) => {
        const groupLogoIds = logoGroups
            .find((g) => g.id === group)
            .logos.map((l) => l.id);

        const isGroupFullyBlocked = groupLogoIds.every((l) =>
            logos.includes(l),
        );
        const logosWithoutGroup = logos.filter(
            (l) => !groupLogoIds.includes(l),
        );
        const updatedLogos = isGroupFullyBlocked
            ? logosWithoutGroup
            : [...logosWithoutGroup, ...groupLogoIds];

        const updatedControls = controls.map((c) => {
            if (c.action === 'add' && c.attribute === 'logos') {
                return {
                    ...c,
                    blacklisted_entities: updatedLogos.map((l) => ({
                        name: l,
                    })),
                };
            }
            return c;
        });

        setLogos(updatedLogos);
        onChangeControls(updatedControls);
    };
    const onAddKeyword = (keyword) => {
        const updatedKeywords = keywords.includes(keyword)
            ? keywords.filter((k) => k !== keyword)
            : [...keywords, keyword];

        const updatedControls = controls.map((c) => {
            if (c.action === 'add' && c.attribute === 'keywords') {
                return {
                    ...c,
                    blacklisted_entities: updatedKeywords.map((k) => ({
                        name: k,
                    })),
                };
            }
            return c;
        });

        setKeywords(updatedKeywords);
        onChangeControls(updatedControls);
    };

    const onEditKeywords = (updatedKeywords) => {
        const updatedControls = controls.map((c) => {
            if (c.action === 'add' && c.attribute === 'keywords') {
                return {
                    ...c,
                    blacklisted_entities: updatedKeywords.map((k) => ({
                        name: k,
                    })),
                };
            }
            return c;
        });

        setKeywords(updatedKeywords);
        onChangeControls(updatedControls);
    };

    return (
        <Panel>
            <Box padding="base">
                <Label label="Guardrails">
                    {!isVideo ? (
                        <>
                            <Box margin={[0, 0, 'small']}>
                                <Label
                                    label={
                                        <Text size="large" weight="base">
                                            Avoid adding these topics and
                                            keywords
                                        </Text>
                                    }
                                    info={
                                        <p>
                                            This list of topics and keywords
                                            will never be added to your context.
                                        </p>
                                    }
                                />
                            </Box>
                            <TopicSelector
                                itemDisplayName="topics"
                                itemType={ruleItemTypes.TOPIC}
                                groups={nonBSTopicGroups}
                                rules={mockRules.rules}
                                ruleKey={0}
                                language={language}
                                onBlock={onToggleTopic}
                                onBlockAll={onToggleTopicGroup}
                                onAddKeyword={isVideo ? null : onAddKeyword}
                            />
                            {topics.length > 0 && (
                                <Box margin={['base', 0, 0]}>
                                    <Text>Topics</Text>

                                    {detailedTopicList.map((topic) => (
                                        <Box
                                            key={topic.id}
                                            margin={['small', 'small', 0, 0]}
                                            display="inline-block"
                                        >
                                            <TopicButton
                                                action="remove"
                                                logo={topic.group_logo}
                                                isReadOnly={isReadOnly}
                                                onClick={() =>
                                                    onToggleTopic(topic.id)
                                                }
                                            >
                                                {topic.name}
                                            </TopicButton>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            {keywords.length > 0 && (
                                <Box margin={['base', 0, 0]}>
                                    <KeywordInput
                                        keywords={sortedKeywords}
                                        label={
                                            <Text weight="base" size="base">
                                                Keywords
                                            </Text>
                                        }
                                        theme="muted"
                                        onChange={onEditKeywords}
                                        hideInput
                                    />
                                </Box>
                            )}
                        </>
                    ) : (
                        <>
                            <Box margin={[0, 0, 'small']}>
                                <Label
                                    label={
                                        <Text size="large" weight="base">
                                            Avoid adding these topics
                                        </Text>
                                    }
                                    info={
                                        <p>
                                            This list of topics will never be
                                            added to your context
                                        </p>
                                    }
                                />
                            </Box>
                            <TopicSelector
                                itemDisplayName="topics"
                                itemType={ruleItemTypes.TOPIC}
                                groups={nonBSTopicGroups}
                                rules={mockRules.rules}
                                ruleKey={0}
                                language={languageTypes.EN}
                                onBlock={onToggleTopic}
                                onBlockAll={onToggleTopicGroup}
                                placeholder="Search Topics"
                            />
                            {topics.length > 0 && (
                                <Box margin={['small', 0, 0]}>
                                    {detailedTopicList.map((topic) => (
                                        <Box
                                            key={topic.id}
                                            margin={['small', 'small', 0, 0]}
                                            display="inline-block"
                                        >
                                            <TopicButton
                                                action="remove"
                                                logo={topic.group_logo}
                                                isReadOnly={isReadOnly}
                                                onClick={() =>
                                                    onToggleTopic(topic.id)
                                                }
                                            >
                                                {topic.name}
                                            </TopicButton>
                                        </Box>
                                    ))}
                                </Box>
                            )}

                            <Box margin={['base', 0, 'small']}>
                                <Label
                                    label={
                                        <Text size="large" weight="base">
                                            Avoid adding these brands
                                        </Text>
                                    }
                                    info={
                                        <p>
                                            This list of brands will never be
                                            added to your context
                                        </p>
                                    }
                                />
                            </Box>
                            <TopicSelector
                                itemDisplayName="brands"
                                itemType={ruleItemTypes.LOGO}
                                groups={logoGroups}
                                rules={mockRules.rules}
                                ruleKey={0}
                                language={languageTypes.EN}
                                onBlock={onToggleLogo}
                                onBlockAll={onToggleLogoGroup}
                                placeholder="Search Brands"
                            />
                            {logos.length > 0 && (
                                <Box margin={['small', 0, 0]}>
                                    {detailedLogoList.map((logo) => (
                                        <Box
                                            key={logo.id}
                                            margin={['small', 'small', 0, 0]}
                                            display="inline-block"
                                        >
                                            <TopicButton
                                                action="remove"
                                                isReadOnly={isReadOnly}
                                                onClick={() =>
                                                    onToggleLogo(logo.id)
                                                }
                                            >
                                                {logo.name}
                                            </TopicButton>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </>
                    )}
                </Label>
            </Box>
        </Panel>
    );
};

export default GuardrailsSelector;
