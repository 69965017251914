import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { faExternalLink, faHistory } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eventTagSources } from 'js/constants';
import { getScheduleDescription } from 'js/utils';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import ClickArea from 'js/components/click-area/click-area';
import Container from 'js/components/container/container';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Spinner from 'js/components/spinner/spinner';
import Statistic from 'js/components/statistic/statistic';
import Text from 'js/components/text/text';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import YoutubeLogo from 'media/images/logos/youtube.png';
import { ReactComponent as DisplayIcon } from './images/display.svg';
import { ReactComponent as VideoIcon } from './images/video.svg';
import styles from '../report-detail.module.scss';

const Tooltip = ({ label, children }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <Box display="inline-block">
            <DropdownMenu
                content={<Box padding={['small', 'base']}>{children}</Box>}
                onHide={() => setIsActive(false)}
                showMenu={isActive}
                width="fixed"
            >
                <ClickArea onClick={() => setIsActive(!isActive)}>
                    <Text
                        size="small"
                        weight="bold"
                        color={['purple', isActive ? 'darker' : 'dark']}
                    >
                        {label}
                    </Text>
                </ClickArea>
            </DropdownMenu>
        </Box>
    );
};

const CampaignInfo = ({
    campaign,
    hasPadding,
    lineItemLabel,
    campaignLabel,
}) => {
    const {
        campaign_id: id,
        campaign_name: campaignName,
        line_items: lineItems,
    } = campaign;
    const lineItemsLabel = `${lineItemLabel}${lineItems.length > 1 ? 's' : ''}`;

    return (
        <Box key={id} padding={[0, 0, hasPadding ? 'small' : 0]}>
            <Row gutter="small">
                <Col span="auto">
                    <Text size="large" weight="bolder">
                        {campaignName || id}
                    </Text>
                </Col>
                {campaignName && (
                    <Col span="auto">
                        <Box margin={['smallest', 'none', 'none']}>
                            <InfoButton>
                                <Text color={['gray', 'dark']}>ID - {id}</Text>
                            </InfoButton>
                        </Box>
                    </Col>
                )}
            </Row>
            <Text size="small" weight="base">
                {lineItems.length ? (
                    <>
                        {`${lineItems.length} ${lineItemsLabel} `}
                        <Tooltip label="View">
                            <Text size="large" weight="bold">
                                {lineItemLabel}s
                            </Text>
                            {lineItems.map(
                                ({
                                    line_item_id: lineItemId,
                                    line_item_name: lineItemName,
                                }) => (
                                    <Box
                                        key={lineItemId}
                                        margin={['small', 0, 0]}
                                    >
                                        {lineItemName && (
                                            <strong>{lineItemName}</strong>
                                        )}{' '}
                                        {lineItemId}
                                    </Box>
                                ),
                            )}
                        </Tooltip>
                    </>
                ) : (
                    `All ${campaignLabel} ${lineItemLabel}s`
                )}
            </Text>
        </Box>
    );
};

const ScheduleInfo = ({
    template: { id, advertiser: advertiserId, schedule },
}) => {
    const scheduleDescription =
        getScheduleDescription(schedule) ||
        'This report is not scheduled to generate new Snapshots';

    return (
        <Box
            margin={['smaller', 0, 0]}
            padding="small"
            display="flex"
            background={['gray', 'background']}
            borderRadius="round"
        >
            <Row alignItems="center" gutter="small">
                <Col span="auto">
                    <FontAwesomeIcon
                        icon={faHistory}
                        flip="horizontal"
                        size="lg"
                    />
                </Col>
                <Col>
                    <Text size="small" weight="base">
                        {scheduleDescription}{' '}
                        <Text weight="bold" inline>
                            <Link to={`/insights/${advertiserId}/${id}/`}>
                                Edit
                            </Link>
                        </Text>
                    </Text>
                </Col>
            </Row>
        </Box>
    );
};

const InfoPanel = ({ report, template, context, reportType }) => {
    const {
        id: reportId,
        start_date: startDate,
        end_date: endDate,
        created: creationDate,
    } = report;
    const { source: sourceId, campaigns, subtype = '' } = template;
    const {
        id: contextId,
        name: contextName,
        group_name: campaignName,
        advertiser_name: advertiserName,
    } = context;

    const { campaignLabel = 'Campaign', lineItemLabel = 'Line Item' } =
        eventTagSources.find(({ id }) => id === sourceId) || {};

    const displayPlatform = () => {
        let logo;

        if (subtype.toLowerCase().includes('youtube')) {
            logo = YoutubeLogo;
        } else {
            const isLogoAvailable =
                sourceId &&
                eventTagSources.map((src) => src.id).includes(sourceId);
            if (!isLogoAvailable) return sourceId;

            logo = eventTagSources.find((src) => src.id === sourceId).logoImg;
        }

        return (
            <img
                src={logo}
                alt={sourceId}
                className={styles['platform-logo']}
            />
        );
    };

    return (
        <Box margin={['large', 0]}>
            <Container>
                <Panel theme="secondary">
                    <Box padding="base">
                        <Box margin={[0, 0, 'base', 0]}>
                            <Text weight="bold" size="larger">
                                Details
                            </Text>
                        </Box>

                        <Panel>
                            <Box padding="base">
                                <Row gap="base">
                                    {contextId && (
                                        <Statistic name="Context">
                                            <Link
                                                to={`/contexts/${contextId}/`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Text
                                                    inline
                                                    color={['gray', 'darkest']}
                                                >
                                                    {contextName}{' '}
                                                </Text>

                                                <Text
                                                    inline
                                                    color={['purple', 'dark']}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faExternalLink}
                                                    />
                                                </Text>
                                            </Link>

                                            <Box margin={['smaller', 0]}>
                                                <Row
                                                    gutter="small"
                                                    alignItems="center"
                                                >
                                                    <Col span="auto">
                                                        <div
                                                            className={
                                                                styles[
                                                                    'type-icon'
                                                                ]
                                                            }
                                                        >
                                                            {reportType ===
                                                            'Display' ? (
                                                                <DisplayIcon />
                                                            ) : (
                                                                <VideoIcon />
                                                            )}
                                                        </div>
                                                    </Col>

                                                    <Col span="auto">
                                                        <Text
                                                            weight="base"
                                                            size="small"
                                                        >
                                                            {reportType}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </Box>

                                            {campaignName && (
                                                <Text
                                                    size="small"
                                                    weight="base"
                                                >
                                                    {campaignName}
                                                </Text>
                                            )}

                                            {advertiserName && (
                                                <Box margin={['smaller', 0, 0]}>
                                                    <Text
                                                        size="small"
                                                        weight="base"
                                                    >
                                                        {advertiserName}
                                                    </Text>
                                                </Box>
                                            )}
                                        </Statistic>
                                    )}

                                    <Statistic name="Platform">
                                        {displayPlatform()}
                                    </Statistic>

                                    {subtype !==
                                        videoReportSubtypes.YOUTUBE_GADS_FILE && (
                                        <Statistic
                                            name={
                                                campaigns.length > 1
                                                    ? `${campaignLabel}s`
                                                    : campaignLabel
                                            }
                                            spanSm={12}
                                        >
                                            {campaigns.map(
                                                (campaign, index) => (
                                                    <CampaignInfo
                                                        key={
                                                            campaign.campaign_id
                                                        }
                                                        campaign={campaign}
                                                        hasPadding={
                                                            index <
                                                            campaigns.length - 1
                                                        }
                                                        lineItemLabel={
                                                            lineItemLabel
                                                        }
                                                        campaignLabel={
                                                            campaignLabel
                                                        }
                                                    />
                                                ),
                                            )}
                                        </Statistic>
                                    )}

                                    <Statistic name="Dates">
                                        {!reportId ? (
                                            <Spinner />
                                        ) : (
                                            <>
                                                <>
                                                    <Text inline weight="base">
                                                        From
                                                    </Text>{' '}
                                                    {moment(startDate).format(
                                                        'Do MMMM YYYY',
                                                    )}
                                                </>

                                                <Box margin={['smaller', 0, 0]}>
                                                    <Text inline weight="base">
                                                        To
                                                    </Text>{' '}
                                                    {moment(endDate).format(
                                                        'Do MMMM YYYY',
                                                    )}
                                                </Box>

                                                <Box margin={['smaller', 0, 0]}>
                                                    <Text
                                                        size="small"
                                                        weight="base"
                                                    >
                                                        Created On{' '}
                                                        {moment(
                                                            creationDate,
                                                        ).format(
                                                            'Do MMMM, YYYY',
                                                        )}
                                                    </Text>
                                                </Box>
                                            </>
                                        )}

                                        {reportType !== 'Event Log' &&
                                            subtype !==
                                                videoReportSubtypes.YOUTUBE_GADS_FILE && (
                                                <ScheduleInfo
                                                    template={template}
                                                />
                                            )}
                                    </Statistic>
                                </Row>
                            </Box>
                        </Panel>
                    </Box>
                </Panel>
            </Container>
        </Box>
    );
};

export default InfoPanel;
