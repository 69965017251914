import React from 'react';
import { exportToTXT } from 'js/utils';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import Button from 'js/components/button/button';
import Form from 'js/components/form/form';
import Input from 'js/components/input/input';
import CopyButton from 'js/components/button/copy-button';
import Panel from 'js/components/panel/panel';
import VastPreview from './vast-preview';

const ExternalLink = ({ text, to }) => (
    <a href={to} target="_blank" rel="noopener noreferrer">
        {text} <FontAwesomeIcon size="sm" icon={faExternalLink} />
    </a>
);

const BaseLinkTemplate = ({ text, to }) => (
    <Row gutter="smaller" alignItems="center">
        <Col span="auto">
            <Text>For any help in implementing this tag, please refer to </Text>
        </Col>
        <Col span="auto">
            <ExternalLink text={text} to={to} />
        </Col>
    </Row>
);

const documentationLinksMapper = {
    dv360: (
        <BaseLinkTemplate
            text="this Google Display & Video 360 documentation"
            to="https://support.google.com/displayvideo/answer/3129957?hl=en&ref_topic=3470650"
        />
    ),
    xandr: (
        <BaseLinkTemplate
            text="this Xandr documentation"
            to="https://docs.xandr.com/bundle/invest_invest-standard/page/topics/video-creative-guidelines-and-specifications.html"
        />
    ),
    ttd: (
        <>
            <Box padding={[0, 0, 'smaller']}>
                <Text>
                    For any help in implementing this tag, please refer to the
                    following The Trade Desk documentation:{' '}
                </Text>
            </Box>
            <Box padding={['smaller', 0]}>
                <ExternalLink
                    text="Third-Party Video Creative Specifications"
                    to="https://tradedesk.getguru.com/card/Ta6e4xMc/ThirdParty-Video-Creative-Specifications-Solimar"
                />
            </Box>
            <Box padding={['smaller', 0]}>
                <ExternalLink
                    text="Video Creative Specifications by Channel"
                    to="https://tradedesk.getguru.com/card/ir67k9KT/Video-Creative-Specifications-by-Channel-Solimar"
                />
            </Box>
            <Box padding={['smaller', 0]}>
                <ExternalLink
                    text="Video Creative Specifications by Resolution"
                    to="https://tradedesk.getguru.com/card/cp67kRpi/Video-Creative-Specifications-by-Resolution-Solimar"
                />
            </Box>
        </>
    ),
};

const Loading = () => (
    <Box
        margin={['small', 0, 0]}
        border={['base', 'solid', 'gray', 'light']}
        borderRadius="round"
        padding={['small', 'small', 'small', 'base']}
        background={['gray', 'white']}
    >
        <Spinner
            color={['gray', 'dark']}
            message={<Text weight="base">Generating 4D VAST wrapper</Text>}
            placement="right"
        />
    </Box>
);

const WrappedVast = ({ value, onExport }) => (
    <Form>
        <Row gutter="small">
            <Col>
                <Input value={value} readOnly />
            </Col>

            <Col spanSm="auto">
                <CopyButton value={value} message={<Text>Copied</Text>}>
                    <Button theme="outline">Copy</Button>
                </CopyButton>
            </Col>

            <Col spanSm="auto">
                <Button theme="outline" onClick={onExport}>
                    Download
                </Button>
            </Col>
        </Row>
    </Form>
);

const VastOutput = ({
    source,
    loading,
    wrappedTag,
    videoUrl,
    videoDuration,
}) => (
    <Box margin={['large', 0, 0]}>
        <Panel theme="secondary">
            <Box padding="base">
                <Text color={['gray', 'black']} size="large" weight="bold">
                    Your 4D VAST Wrapper
                </Text>

                {loading && <Loading />}

                {!loading && videoUrl && (
                    <Box padding={['smallest', 0]}>
                        <Box padding={['small', 0]}>
                            <WrappedVast
                                value={wrappedTag}
                                onExport={() =>
                                    exportToTXT(
                                        wrappedTag,
                                        `4D-${source.id}-vast-wrapper.txt`,
                                    )
                                }
                            />
                        </Box>

                        <Box padding={['smaller', 0, 0]}>
                            {documentationLinksMapper[source.id]}
                        </Box>

                        <VastPreview
                            videoDuration={videoDuration}
                            videoUrl={videoUrl}
                        />
                    </Box>
                )}

                {!loading && !videoUrl && (
                    <Box
                        margin={['small', 0, 0]}
                        border={['base', 'dashed', 'gray', 'lighter']}
                        borderRadius="round"
                        padding="small"
                    >
                        <Text color={['gray', 'dark']}>
                            Your 4D VAST wrapper will be displayed here after it
                            has been generated.
                        </Text>
                    </Box>
                )}
            </Box>
        </Panel>
    </Box>
);

export default VastOutput;
