import React from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import styles from './section-title.module.scss';

const SectionTitle = ({ children, hasBar, info, extension }) => (
    <Row gutter="small" gap="small" alignItems="center">
        <Col span="auto">
            <Row gutter="small" alignItems="center">
                <Col>
                    <Text weight="bolder" size="largest">
                        {children}
                    </Text>
                </Col>

                {info && (
                    <Col span="auto">
                        <Box display="flex">
                            <InfoButton>{info}</InfoButton>
                        </Box>
                    </Col>
                )}
            </Row>
        </Col>

        <Col>{hasBar && <hr className={styles['line-through']} />}</Col>

        {extension?.length && (
            <Col span={12} spanSm="auto">
                <Row gutter="small" alignItems="center">
                    {React.Children.map(extension, (child) => (
                        <Col span={12} spanSm="auto">
                            <Box margin={['small', 0, 0]} marginSm="none">
                                {child}
                            </Box>
                        </Col>
                    ))}
                </Row>
            </Col>
        )}
    </Row>
);
SectionTitle.defaultProps = {
    hasBar: false,
    info: undefined,
    extension: undefined,
};

SectionTitle.propTypes = {
    children: PropTypes.node.isRequired,
    hasBar: PropTypes.bool,
    info: PropTypes.node,
    extension: PropTypes.node,
};

export default SectionTitle;
