import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Logo from 'media/images/logos/logo.png';
import { eventTagSources } from 'js/constants';
import ruleTypes from 'js/enums/rule-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import {
    RuleSet,
    api,
    copyContext,
    deepCopy,
    setDocumentTitle,
    exportToXLSX,
    AbortError,
} from 'js/utils';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Navigation from 'js/components/navigation/navigation';
import SummaryFooter from 'js/components/summary-footer/summary-footer';
import { showToast } from 'js/components/toast/toast';
import TopBar from 'js/components/top-bar/top-bar';
import regionTypes from 'js/enums/region-types.enum';
import SaveButton from '../components/save-button';
import ReportVideoDetail from './report-video-detail';

const contextChangesTemplate = {
    rules: [],
};

const ruleSortFn = (a, b) => {
    const ruleOrders = [ruleTypes.INCLUDED, ruleTypes.EXCLUDED];
    if (ruleOrders.indexOf(a.aggregation) < ruleOrders.indexOf(b.aggregation)) {
        return -1;
    }
    if (a.key < b.key) {
        return -1;
    }
    return 1;
};

function useContextChanges(initialContext, context) {
    const [contextChanges, setContextChanges] = useState(
        contextChangesTemplate,
    );

    useEffect(() => {
        const { rules } = context;
        const { rules: initialRules } = initialContext;

        if (!rules || !initialRules) {
            return;
        }

        const diffRules = [];

        initialRules.forEach((initialRule) => {
            const updatedRule = rules.find(
                (rule) => rule.key === initialRule.key,
            );

            if (updatedRule) {
                const addedTopics = updatedRule.topics.filter(
                    (x) => !initialRule.topics.includes(x),
                );
                const removedTopics = initialRule.topics.filter(
                    (x) => !updatedRule.topics.includes(x),
                );
                const addedLogos = updatedRule.logos.filter(
                    (x) => !initialRule.logos.includes(x),
                );
                const removedLogos = initialRule.logos.filter(
                    (x) => !updatedRule.logos.includes(x),
                );

                if (
                    addedTopics.length ||
                    removedTopics.length ||
                    addedLogos.length ||
                    removedLogos.length
                ) {
                    diffRules.push({
                        ...updatedRule,
                        addedTopics,
                        removedTopics,
                        addedLogos,
                        removedLogos,
                        isNew: false,
                    });
                }
            } else {
                diffRules.push({
                    ...initialRule,
                    addedTopics: [],
                    removedTopics: initialRule.topics,
                    addedLogos: [],
                    removedLogos: initialRule.logos,
                    isNew: false,
                });
            }
        });

        rules.forEach((rule) => {
            const isAddedRule = !initialRules.some(
                (initialRule) => rule.key === initialRule.key,
            );

            if (isAddedRule) {
                diffRules.push({
                    ...rule,
                    addedTopics: rule.topics,
                    removedTopics: [],
                    addedLogos: rule.logos,
                    removedLogos: [],
                    isNew: true,
                });
            }
        });

        setContextChanges({
            rules: diffRules.sort(ruleSortFn),
        });
    }, [initialContext, context]);

    return contextChanges;
}

export default function ReportVideoDetailPage() {
    const { templateId, reportId } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [scale, setScale] = useState(null);
    const [scaleLoading, setScaleLoading] = useState(true);
    const [scaleErrors, setScaleErrors] = useState({});
    const [hasDeletingError, setHasDeletingError] = useState(false);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [hasSnapshotError, setHasSnapshotError] = useState(false);
    const [reports, setReports] = useState([]);
    const [report, setReport] = useState(null);
    const [template, setTemplate] = useState({});
    const [initialContext, setInitialContext] = useState({});
    const [context, setContext] = useState({});
    const [topicGroups, setTopicGroups] = useState([]);
    const [logoGroups, setLogoGroups] = useState([]);
    const [matchedVideos, setMatchedVideos] = useState([]);
    const [isMatchedVidsLoading, setMatchedVidsLoading] = useState(false);
    const [hasMatchedVidsError, setMatchedVidsError] = useState(false);

    const contextChanges = useContextChanges(initialContext, context); // TODO add logos
    const { rules: ruleChanges } = contextChanges;
    const topicChangesCount = ruleChanges.reduce(
        (count, rule) =>
            count + rule.addedTopics.length + rule.removedTopics.length,
        0,
    );
    const logoChangesCount = ruleChanges.reduce(
        (count, rule) =>
            count + rule.addedLogos.length + rule.removedLogos.length,
        0,
    );
    const hasUnsavedChanges = topicChangesCount > 0 || logoChangesCount > 0;

    const getScaleScore = async (c) => {
        const { rules = [] } = c;
        const nonEmptyRules = rules.filter((rule) => rule.topics.length);

        if (!nonEmptyRules.length) {
            setScaleErrors({});
            setScale(null);
            setScaleLoading(false);
            return;
        }

        setScaleLoading(true);
        setScaleErrors({});

        try {
            const { score } = await api()
                .contexts.action('scale')
                .create({
                    ...c,
                    rules: nonEmptyRules,
                    name: undefined,
                    group: undefined,
                });

            if (score === undefined || score < 0) {
                const errors = { score: 'Failed to calculate scale.' };

                throw errors;
            }
            setScaleLoading(false);
            setScale(score);
        } catch (err) {
            if (err instanceof AbortError) return;
            setScaleLoading(false);
            setScale(null);
            setScaleErrors(err);
        }
    };

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb
                key="insights"
                to="/insights/"
                label="Insights"
                isCollapsible
            />,
        ];
        if (hasLoadingError) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else {
            breadcrumbs.push(
                <Crumb
                    key="advertiser"
                    label={template.advertiser_name}
                    to={`/insights/${template.advertiser}/`}
                    isCollapsible
                />,
                <Crumb key="report" label={template.name} />,
            );
        }

        return breadcrumbs;
    };

    const getMatchedPages = async (inspectedContext) => {
        setMatchedVideos([]);
        setMatchedVidsLoading(true);
        setMatchedVidsError(false);

        try {
            const { results: list } = await api().matchedVideosAnalytics.create(
                inspectedContext,
                {
                    limit: 40,
                    region: regionTypes.ANY,
                },
            );

            setMatchedVideos(list);
            setMatchedVidsLoading(false);
            setMatchedVidsError(false);
        } catch (err) {
            if (err instanceof AbortError) return;

            setMatchedVideos([]);
            setMatchedVidsLoading(false);
            setMatchedVidsError(true);
        }
    };

    const switchReport = async (id) => {
        setLoading(true);
        setHasLoadingError(false);

        try {
            const reportData = await api().videoReports.retrieve(id, {
                verbose: true,
            });

            setReport(reportData);
        } catch (errors) {
            setHasLoadingError(true);
        } finally {
            setLoading(false);
        }
    };

    const loadData = async () => {
        setLoading(true);
        setHasLoadingError(false);

        try {
            const [{ results: reportsData }, templateData] = await Promise.all([
                api().videoReports.list({
                    template: templateId,
                    ordering: '-end_date',
                }),
                api().videoReportTemplates.retrieve(templateId, {
                    verbose: true,
                }),
            ]);

            const { context: contextId } = templateData;
            const [contextData, reportData] = await Promise.all([
                api().contexts.retrieve(contextId, { verbose: true }),
                reportsData.length > 0
                    ? api().videoReports.retrieve(
                          reportId || reportsData[0].id,
                          { verbose: true },
                      )
                    : null,
            ]);
            const { language, rules } = contextData;
            const ruleSet = new RuleSet(rules);
            contextData.rules = ruleSet.rules;

            const { results: topicsData } = await api().topics.list({
                ordering: 'group__name,name',
                language,
                verbose: true,
            });

            const { results: logosData } = await api().logos.list({
                ordering: 'group__name,name',
                language,
                verbose: true,
            });

            setReports(reportsData);
            setTemplate(templateData);
            setInitialContext(contextData);
            setContext(contextData);
            setReport(reportData);
            setTopicGroups(
                topicsData
                    .filter(
                        (topic, i) =>
                            !i || topic.group !== topicsData[i - 1].group,
                    )
                    .map((item) => ({
                        id: item.group,
                        name: item.group_name,
                        logo: item.group_logo,
                        topics: topicsData.filter(
                            (topic) => topic.group === item.group,
                        ),
                    })),
            );
            setLogoGroups(
                logosData
                    .filter(
                        (logo, i) =>
                            !i || logo.group !== logosData[i - 1].group,
                    )
                    .map((item) => ({
                        id: item.group,
                        name: item.group_name,
                        logos: logosData.filter(
                            (logo) => logo.group === item.group,
                        ),
                    })),
            );
            getScaleScore(contextData);
        } catch (errors) {
            setHasLoadingError(true);
        } finally {
            setLoading(false);
        }
    };

    const createSnapshot = async (dateRange) => {
        setHasSnapshotError(false);
        try {
            const snapshotTemplate = await api()
                .videoReportTemplates.action('refresh')
                .create({ id: templateId, ...dateRange });

            setTemplate(snapshotTemplate);
        } catch (errors) {
            setHasSnapshotError(true);
            throw errors;
        }
    };

    const deleteTemplate = async () => {
        setHasDeletingError(false);
        try {
            await api().videoReportTemplates.destroy(template.id);
            await history.push(`/insights/${template.advertiser}/`);
        } catch (errors) {
            setHasDeletingError(true);
            throw errors;
        }
    };

    const toggleFavorite = async () => {
        const isFavorite = !template.is_favourite;
        try {
            await api().videoReportTemplates.favorite(template.id, isFavorite);
            setTemplate({ ...template, is_favourite: isFavorite });
        } catch (err) {
            showToast(
                'There Was An Unexpected Error',
                <p>
                    It was not possible to{' '}
                    {isFavorite ? 'favorite' : 'unfavorite'} {template.name}.
                    Please try again in a few moments.
                </p>,
                10,
                'danger',
            );
        }
    };

    const changeContextRules = (rules) => {
        const updatedContext = {
            ...deepCopy(context),
            rules,
        };
        setContext(updatedContext);
        getScaleScore(updatedContext);
    };

    const createContext = () => {
        copyContext(context, { namePrefix: '' });
        setContext(deepCopy(initialContext)); // avoids triggering the unsaved changes modal
    };

    const saveContext = async () => {
        setSaving(true);

        const nonEmptyRules = context.rules.filter(
            (rule) =>
                rule.keywords.length || rule.topics.length || rule.logos.length,
        );

        const method = api().contexts.update;
        const params = [context.id];

        params.push({
            ...context,
            rules: nonEmptyRules,
        });

        try {
            await method(...params, { verbose: true });
            setContext(deepCopy(initialContext));
            history.push(`/insights/${context.advertiser}/`);
        } catch (err) {
            setSaving(false);
            showToast(
                'There Was An Unexpected Error',
                <p>
                    The context has not been saved. Please try again in a few
                    moments.
                </p>,
                null,
                'danger',
            );
        }
    };

    const createExcelExport = async () => {
        const hasClicks = report?.clicks;
        const isDV360Report =
            template.subtype === videoReportSubtypes.YOUTUBE_DV360;

        const campaigns = [];
        const lineItems = [];
        template.campaigns.forEach((campaign) => {
            let name = campaign.campaign_name;
            let id = campaign.campaign_id;
            campaigns.push(name ? `${name} (${id})` : id);
            campaign.line_items.forEach((lineItem) => {
                name = lineItem.line_item_name;
                id = lineItem.line_item_id;
                lineItems.push(name ? `${name} (${id})` : id);
            });
        });

        const { campaignLabel = 'Campaign', lineItemLabel = 'Line Item' } =
            eventTagSources.find(({ id }) => id === template.source) || {};

        const completedViewsLabel = `${
            isDV360Report ? 'True' : 'Completed'
        } Views`;

        const completeRateLabel = `${
            isDV360Report ? 'True View' : 'Completion'
        } Rate`;

        const completeRate = report.impressions
            ? report.completes / report.impressions
            : 0;

        const reportSummary = [
            [],
            ['Start Date', new Date(report.start_date)],
            ['End Date', new Date(report.end_date)],
            ['Client', context.advertiser_name],
            ['Context ID', context.id],
            ['Context Group Name', context.group_name],
            ['Context Name', context.name],
            ['Avocado ID', report.account],
        ];
        if (template.campaigns.length > 0) {
            reportSummary.push(
                ...[
                    [`${campaignLabel} ID(s)`, campaigns.join(', ')],
                    [
                        `${lineItemLabel} ID(s)`,
                        lineItems.join(', ') ||
                            `All ${campaignLabel} ${lineItemLabel}s`,
                    ],
                ],
            );
        }
        reportSummary.push(
            ...[
                [],
                ['Impressions', report.impressions],
                ['Started Views', report.starts],
                [completedViewsLabel, report.completes],
                [completeRateLabel, `${completeRate * 100}%`],
                hasClicks ? ['Clicks', report.clicks] : [],
            ],
        );

        const dailyBreakdown = report.overviews.map((data) => ({
            Date: new Date(data.activity_date),
            Impressions: data.impressions,
            'Started Views': data.starts,
            [completedViewsLabel]: data.completes,
            Clicks: data.clicks,
        }));
        if (!hasClicks) {
            // eslint-disable-next-line no-param-reassign
            dailyBreakdown.forEach((item) => delete item.Clicks);
        }

        const groupBreakdown = report.topic_groups.map((data) => ({
            Group: data.topic_group_name,
            Impressions: data.impressions,
            'Started Views': data.starts,
            [completedViewsLabel]: data.completes,
            Clicks: data.clicks,
        }));
        if (!hasClicks) {
            // eslint-disable-next-line no-param-reassign
            groupBreakdown.forEach((item) => delete item.Clicks);
        }

        const topicBreakdown = report.topics.map((data) => ({
            Group: topicGroups.find((group) =>
                group.topics.some(({ id }) => id === data.topic),
            ).name,
            Topic: data.topic_name,
            Impressions: data.impressions,
            'Started Views': data.starts,
            [completedViewsLabel]: data.completes,
            Clicks: data.clicks,
        }));
        const logoBreakdown = report.logos.map((data) => ({
            Group: logoGroups.find((group) =>
                group.logos.some(({ id }) => id === data.logo),
            ).name,
            Logo: data.logo_name,
            Impressions: data.impressions,
            'Started Views': data.starts,
            [completedViewsLabel]: data.completes,
            Clicks: data.clicks,
        }));
        if (!hasClicks) {
            // eslint-disable-next-line no-param-reassign
            topicBreakdown.forEach((t) => delete t.Clicks);
            // eslint-disable-next-line no-param-reassign
            logoBreakdown.forEach((l) => delete l.Clicks);
        }

        const sheets = [
            {
                name: 'Report Summary',
                data: reportSummary,
                title: template.name,
                logo: Logo,
            },
            { name: 'Daily Breakdown', data: dailyBreakdown },
            { name: 'Group Breakdown', data: groupBreakdown },
            { name: 'Topic Breakdown', data: topicBreakdown },
            { name: 'Logo Breakdown', data: logoBreakdown },
        ].filter(({ data }) => data.length);

        await exportToXLSX(sheets, `4D report - ${template.name}.xlsx`);
    };

    useLayoutEffect(() => {
        loadData();
        return () => api().abortAll();
    }, [templateId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (template.name) {
            setDocumentTitle([template.name, 'Insights']);
        } else {
            setDocumentTitle(['Insights']);
        }
    }, [template.name]);

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
            footer={
                !loading &&
                !hasLoadingError &&
                reports.length > 0 && (
                    <SummaryFooter
                        context={context}
                        contextChanges={contextChanges}
                        onChangeContextRules={changeContextRules}
                        topics={report.topics}
                        logos={report.logos}
                        scaleScore={scale}
                        scaleChanging={scaleLoading}
                        scaleErrors={scaleErrors}
                        scaleReload={() => getScaleScore(context)}
                        isShowChangeDetails
                        actionItems={
                            <SaveButton
                                hasUnsavedChanges={hasUnsavedChanges}
                                onCreateContext={createContext}
                                onSaveContext={saveContext}
                            />
                        }
                    />
                )
            }
            isFooterSticky
        >
            {saving && <LoadingLayer message="Saving Optimizations" />}

            <ReportVideoDetail
                report={report}
                reports={reports}
                template={template}
                context={context}
                topicGroups={topicGroups}
                logoGroups={logoGroups}
                loading={loading}
                hasLoadingError={hasLoadingError}
                hasDeletingError={hasDeletingError}
                hasSnapshotError={hasSnapshotError}
                hasUnsavedChanges={hasUnsavedChanges}
                onChangeContextRules={changeContextRules}
                onDelete={deleteTemplate}
                onDiscardChanges={() => {
                    setContext(deepCopy(initialContext));
                }}
                onExportExcel={createExcelExport}
                onSnapshot={createSnapshot}
                onToggleFavorite={toggleFavorite}
                onReload={loadData}
                onSwitchReport={switchReport}
                matchedVideos={matchedVideos}
                hasMatchedVideosError={hasMatchedVidsError}
                isMatchedVideosLoading={isMatchedVidsLoading}
                onLoadMatchedVideos={getMatchedPages}
            />
        </Layout>
    );
}
