import FilterDropdown from 'js/components/filter-dropdown/filter-dropdown';
import filterTypes from 'js/enums/filter-types.enum';
import regionTypes from 'js/enums/region-types.enum';
import React from 'react';

const RegionFilter = ({ value, onChange }) => {
    const regionOptions = [
        { label: 'All', value: regionTypes.ANY, group: 0 },
        // { label: 'APAC', value: regionTypes.APAC, group: 1 },
        { label: 'EMEA', value: regionTypes.EMEA, group: 1 },
        { label: 'North America', value: regionTypes.NORTH_AMERICA, group: 1 },
    ];
    return (
        <FilterDropdown
            name="region"
            prefix="Region"
            param={filterTypes.REGION}
            options={regionOptions}
            selectedValue={value}
            title={
                <p>
                    This setting does not control any targeting behavior, but
                    merely provides insight into the type of inventory available
                    by region. Please continue to use geo-targeting within your
                    DSP as needed.
                </p>
            }
            onFilterChange={(param, newValue) => onChange(newValue)}
        />
    );
};

export default RegionFilter;
