const columnTypes = {
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CTR: 'ctr',
    VIEWABLE_IMPRESSIONS: 'viewableImpressions',
    VIEW_RATE: 'viewRate',
    STARTS: 'starts',
    FIRST_QUARTILES: 'firstQuartiles',
    MIDPOINTS: 'midpoints',
    THIRD_QUARTILES: 'thirdQuartiles',
    COMPLETES: 'completes',
    COMPLETION_RATE: 'completionRate',
};

export default columnTypes;
