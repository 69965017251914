import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import serviceTypes from 'js/enums/service-types.enum';
import { getJwtPayload, createPresetUrl } from 'js/utils';
import Button from 'js/components/button/button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import styles from './create-new-button.module.scss';

const cx = classNames.bind(styles);

function CreateNewButton({ match }) {
    const [showDropdown, setShowDropdown] = useState(false);

    const history = useHistory();

    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );

    const classes = cx({
        content: true,
        active: showDropdown,
    });

    const { advertiserId, campaignId, contextId } = match.params;
    const newCampaign = {};
    const newContext = {};
    const newReport = {};
    if (advertiserId && advertiserId !== 'new') {
        newCampaign.advertiser = advertiserId;
        newContext.advertiser = advertiserId;
        newReport.advertiser = advertiserId;
    }
    if (campaignId && campaignId !== 'new') {
        newContext.group = campaignId;
        newReport.advertiser = advertiserId;
    }
    if (contextId && contextId !== 'new') {
        newReport.context = contextId;
    }

    const redirect = (to) => {
        setShowDropdown(false);
        history.push(to);
    };

    return (
        <DropdownMenu
            onHide={() => setShowDropdown(false)}
            showMenu={showDropdown}
            content={
                <ul>
                    <DropdownButton
                        onClick={() => redirect('/advertisers/new/')}
                    >
                        Advertiser
                    </DropdownButton>

                    <DropdownButton
                        onClick={() =>
                            redirect(
                                createPresetUrl('/campaigns/new/', newCampaign),
                            )
                        }
                    >
                        Campaign
                    </DropdownButton>

                    <DropdownButton
                        onClick={() =>
                            redirect(
                                createPresetUrl('/contexts/new/', newContext),
                            )
                        }
                    >
                        Context
                    </DropdownButton>

                    {insightsService && (
                        <DropdownButton
                            onClick={() =>
                                redirect(
                                    createPresetUrl(
                                        '/insights/new/',
                                        newReport,
                                    ),
                                )
                            }
                        >
                            Report
                        </DropdownButton>
                    )}
                </ul>
            }
            offset={[0, 4]}
        >
            <Button
                theme="outline"
                className={classes}
                square
                onClick={() => setShowDropdown((prevState) => !prevState)}
                active={showDropdown}
            >
                <div className={styles.text}>Create</div>
                <div className={styles.icon}>
                    <p>
                        <FontAwesomeIcon icon={faPlus} />
                    </p>
                </div>
            </Button>
        </DropdownMenu>
    );
}

CreateNewButton.defaultProps = {
    match: {},
};

CreateNewButton.propTypes = {
    match: PropTypes.object,
};

export default withRouter(CreateNewButton);
