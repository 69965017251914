import React, { useReducer, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { api, getJwtPayload, setDocumentTitle } from 'js/utils';
import Text from 'js/components/text/text';
import { showToast } from 'js/components/toast/toast';
import permissionGroups from 'js/enums/permission-groups.enum';
import ChannelDetail from './channel-detail';

const FETCH_DATA_INIT = 'FETCH_DATA_INIT';
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
const SAVE_CHANNEL_INIT = 'SAVE_CHANNEL_INIT';
const SAVE_CHANNEL_SUCCESS = 'SAVE_CHANNEL_SUCCESS';
const SAVE_CHANNEL_ERROR = 'SAVE_CHANNEL_ERROR';
const CHANNEL_ERRORS = 'CHANNEL_ERRORS';
const CHANNEL = 'CHANNEL';
const AMENDING = 'AMENDING';
const DELETED_ERRORS = 'DELETED_ERRORS';

const initialState = {
    loading: true,
    saving: false,
    amending: false,
    error: false,
    deleteErrors: {},
    channelErrors: {},
    channel: {},
    integration: {},
};

const reducer = (state, action) => {
    switch (action.type) {
        case FETCH_DATA_INIT:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case FETCH_DATA_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case SAVE_CHANNEL_INIT:
            return {
                ...state,
                saving: true,
            };
        case SAVE_CHANNEL_SUCCESS:
            return {
                ...state,
                channel: action.payload,
                saving: false,
            };
        case SAVE_CHANNEL_ERROR:
            return {
                ...state,
                channelErrors: action.payload,
                saving: false,
            };
        case CHANNEL_ERRORS:
            return {
                ...state,
                channelErrors: action.payload,
            };
        case CHANNEL:
            return {
                ...state,
                channel: action.payload,
            };
        case AMENDING:
            return {
                ...state,
                amending: action.payload,
            };
        case DELETED_ERRORS:
            return {
                ...state,
                deleteErrors: action.payload,
            };
        default:
            throw new Error();
    }
};

const ChannelDetailPage = () => {
    const { integrationId, channelId } = useParams();
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { roles } = getJwtPayload(localStorage.getItem('AuthToken'));
    const isReadOnly =
        roles.includes(permissionGroups.MANAGER) ||
        roles.includes(permissionGroups.VIEW_ONLY);

    const {
        amending,
        saving,
        loading,
        integration,
        channel,
        error,
        deleteErrors,
        channelErrors,
    } = state;

    const setChannelErrors = (payload) =>
        dispatch({ type: CHANNEL_ERRORS, payload });

    const setChannel = (payload) => dispatch({ type: CHANNEL, payload });

    const validateChannel = useCallback(
        ({ channelData, throwErrors = false } = {}) => {
            dispatch({ type: AMENDING, payload: true });
            const errors = {};
            if (!channelData.integration_key)
                errors.integration_key = 'A Seat ID is required.';

            if (!channelData.description)
                errors.description = 'A Name is required.';
            if (errors.integration_key || errors.description) {
                setChannelErrors(errors);
                if (throwErrors) throw errors;
            }
        },
        [],
    );

    const cleanChannelErrors = (e) => {
        const errors = { ...e };
        if (errors.non_field_errors) {
            const err = errors.non_field_errors[0];
            if (err.includes('description'))
                errors.description = 'A Seat already exists with this name.';
            else {
                errors.integration_key = 'This Seat ID is already in use.';
            }
            delete errors.non_field_errors;
        }
        return errors;
    };

    const onSaveChannel = async () => {
        dispatch({ type: SAVE_CHANNEL_INIT });

        try {
            validateChannel({ channelData: channel, throwErrors: true });

            const args = [];
            let method;

            if (channel.id) {
                args.push(channel.id);
                method = api().channels.update;
            } else {
                method = api().channels.create;
            }
            args.push({ ...channel, integration: integration.id });
            const newChannel = await method(...args);

            setDocumentTitle([
                channel.description,
                integration.name,
                'Channels',
                'Settings',
            ]);
            dispatch({ type: SAVE_CHANNEL_SUCCESS, payload: newChannel });

            history.push({
                ...history.location,
                pathname: `/settings/channels/${integration.id}/`,
            });

            if (!channel.id) {
                showToast(
                    'Success',
                    <Text color={['gray', 'white']}>
                        The seat has successfully been added to the channel.
                    </Text>,
                    10,
                );
            }
        } catch (err) {
            dispatch({
                type: SAVE_CHANNEL_ERROR,
                payload: cleanChannelErrors(err),
            });
        }
    };

    const onDeleteChannel = async () => {
        try {
            await api().channels.destroy(channel.id);
            history.push({
                ...history.location,
                pathname: `/settings/channels/${integration.id}/`,
            });
        } catch (err) {
            dispatch({ type: DELETED_ERRORS, payload: err });
        }
    };

    const handleInput = (key, payload) => {
        delete channelErrors[key];
        setChannel({ ...channel, ...payload });
    };

    const setIntegrationKey = (integrationKey) =>
        handleInput('integration_key', { integration_key: integrationKey });

    const setDescription = (description) =>
        handleInput('description', { description });

    useEffect(() => {
        const getChannelId = () =>
            channelId === 'new' ? undefined : channelId;

        const loadData = async () => {
            dispatch({ type: FETCH_DATA_INIT });
            const id = getChannelId();
            const callbacks = [api().integrations.retrieve(integrationId)];

            if (id) callbacks.push(api().channels.retrieve(id));

            try {
                const data = await Promise.all(callbacks);
                const [integrationData, channelData] = data;
                const breadCrumbContent = (channelData || {}).description;

                setDocumentTitle([
                    id ? breadCrumbContent : 'New',
                    integrationData.name,
                    'Channels',
                    'Settings',
                ]);
                dispatch({
                    type: FETCH_DATA_SUCCESS,
                    payload: {
                        channel: channelData || {},
                        integration: integrationData,
                    },
                });
                if (id) validateChannel({ channelData });
            } catch (err) {
                dispatch({ type: FETCH_DATA_ERROR });
            }
        };
        loadData();
    }, [channelId, integrationId, validateChannel]);

    useEffect(() => {
        if (amending) validateChannel({ channelData: channel });
    }, [amending, channel, loading, validateChannel]);

    return (
        <ChannelDetail
            loading={loading}
            saving={saving}
            error={error}
            deleteErrors={deleteErrors}
            channelErrors={channelErrors}
            channel={channel}
            integration={integration}
            onSaveChannel={onSaveChannel}
            onDeleteChannel={onDeleteChannel}
            onSetIntegrationKey={(e) => setIntegrationKey(e.target.value)}
            onSetDescription={(e) => setDescription(e.target.value)}
            isReadOnly={isReadOnly}
        />
    );
};

export default ChannelDetailPage;
