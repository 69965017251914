import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from '@react-pdf/renderer';
import Box from '../box/box';
import Col from '../grid/col';
import Row from '../grid/row';
import SeparatedSection from '../separated-section/separated-section';
import styles from './annotated-chart.styles';

const Annotation = ({ annotation, orientation }) => {
    const isPortrait = orientation === 'portrait';

    return (
        <Box padding={['small', 0]}>
            <Text
                style={
                    isPortrait ? styles.titlePortrait : styles.titleLandscape
                }
            >
                <Text
                    style={{
                        color: annotation.color ? annotation.color : null,
                    }}
                >
                    {annotation.title}
                </Text>

                {annotation.value ? (
                    <Text style={styles.value}> {annotation.value}</Text>
                ) : null}
            </Text>

            {annotation.description ? (
                <Box margin={['smallest', 0, 0]}>
                    <Text
                        style={
                            isPortrait
                                ? styles.descriptionPortrait
                                : styles.descriptionLandscape
                        }
                    >
                        {annotation.description}
                    </Text>
                </Box>
            ) : null}
        </Box>
    );
};

const AnnotatedChart = ({ orientation, annotations, chart }) => {
    const isPortrait = orientation === 'portrait';

    return (
        <Box padding={isPortrait ? ['small', 0] : ['base', 0]}>
            <Row>
                <Col span={isPortrait ? 12 : 4}>
                    <SeparatedSection
                        orientation={orientation}
                        items={annotations.map((annotation) => (
                            <Annotation
                                annotation={annotation}
                                orientation={orientation}
                            />
                        ))}
                        alignItems="flex-start"
                    />
                </Col>

                <Col span={isPortrait ? 12 : 8}>
                    <View style={[styles.chart]}>
                        {chart && <Image src={chart} />}
                    </View>
                </Col>
            </Row>
        </Box>
    );
};

AnnotatedChart.defaultProps = {
    orientation: 'portrait',
    chart: undefined,
};

AnnotatedChart.propTypes = {
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    annotations: PropTypes.array.isRequired,
    chart: PropTypes.node,
};

export default AnnotatedChart;
