import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './table.module.scss';

const cx = classNames.bind(styles);

function Table({
    children,
    firstColFluidWidth,
    firstColFixedWidth,
    firstColSticky,
    lastColFluidWidth,
    noWrap,
    verticallyCentered,
}) {
    const classes = cx({
        'table-container': true,
        'table--columns-first-fluid-width': firstColFluidWidth,
        'table--columns-first-fixed-width': firstColFixedWidth,
        'table--columns-first-sticky': firstColSticky,
        'table--columns-last-fluid-width': lastColFluidWidth,
        'table--columns-no-wrap': noWrap,
        'table--vertically-centered': verticallyCentered,
    });

    return <div className={classNames(classes)}>{children}</div>;
}

Table.defaultProps = {
    firstColFluidWidth: false,
    firstColFixedWidth: false,
    firstColSticky: false,
    lastColFluidWidth: false,
    noWrap: false,
    verticallyCentered: false,
};

Table.propTypes = {
    children: PropTypes.node.isRequired,
    firstColFluidWidth: PropTypes.bool,
    firstColFixedWidth: PropTypes.bool,
    firstColSticky: PropTypes.bool,
    lastColFluidWidth: PropTypes.bool,
    noWrap: PropTypes.bool,
    verticallyCentered: PropTypes.bool,
};

export default Table;
