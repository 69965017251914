import React, { useState } from 'react';
import entityTypes from 'js/enums/entity-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import ErrorBar from 'js/components/error-bar/error-bar';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Panel from 'js/components/panel/panel';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import { showToast } from 'js/components/toast/toast';
import InfoPanel from '../components/info-panel';
import ProcessingPanel from '../components/processing-panel';
import SnapshotModal from '../components/snapshot-modal';
import ActionButtons from './components/action-buttons';
import DownloadBanner from './images/download.svg';
import styles from '../report-detail.module.scss';

const ReportContent = ({ report, template, context }) => {
    if (!report) {
        return <ProcessingPanel />;
    }

    return (
        <>
            <Box margin={['large', 0]}>
                <InfoPanel
                    report={report}
                    template={template}
                    context={context}
                    reportType="Event Log"
                />
            </Box>

            <Container>
                <Box margin={['large', 0]}>
                    <Panel bordered>
                        <Box padding="base">
                            <Panel theme="secondary">
                                <Box padding="base">
                                    <Text
                                        weight="bolder"
                                        size="larger"
                                        align="center"
                                    >
                                        Woohoo! Your Event Log Report is Ready
                                        to Download
                                    </Text>

                                    <Box margin={['base', 0, 0]}>
                                        <Row justifyContent="center">
                                            <Col span="auto">
                                                <Button to={report.file}>
                                                    Download
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>
                            </Panel>

                            <Box margin={['base', 0, 0]}>
                                <Row justifyContent="center">
                                    <Col span="auto">
                                        <img
                                            className={
                                                styles['download-banner']
                                            }
                                            src={DownloadBanner}
                                            alt="Woohoo banner"
                                        />
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Panel>
                </Box>
            </Container>
        </>
    );
};

const ReportEventLogDetail = ({
    report,
    reports,
    template,
    context,
    loading,
    hasLoadingError,
    hasDeletingError,
    hasSnapshotError,
    onDelete,
    onReload,
    onSnapshot,
    onSwitchReport,
    onToggleFavorite,
}) => {
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isSnapshotModalVisible, setSnapshotModalVisible] = useState(false);

    const { is_ready: isReady } = template;

    const handleSnapshot = async (dateRange) => {
        await onSnapshot(dateRange);
        setSnapshotModalVisible(false);
        showToast(
            'Success',
            <p>
                The snapshot has been created and will be ready to view within
                the next 24 hours.
            </p>,
            10,
        );
    };

    if (loading) {
        return (
            <Box margin={['large', 0]}>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Box>
        );
    }

    if (hasLoadingError) {
        return (
            <Box margin={['large', 0]}>
                <ErrorBar
                    size="small"
                    title="There Was An Error Loading The Report"
                    onAction={onReload}
                />
            </Box>
        );
    }

    return (
        <>
            <ActionButtons
                template={template}
                report={report}
                reports={reports}
                onDelete={() => setDeleteModalVisible(true)}
                onSnapshot={() => setSnapshotModalVisible(true)}
                onToggleFavorite={onToggleFavorite}
                onSwitchReport={onSwitchReport}
            />

            <ReportContent
                report={report}
                template={template}
                context={context}
            />

            {isDeleteModalVisible && (
                <ConfirmModal
                    title={`Are You Sure You Want To Delete ${template.name}?`}
                    confirm="Yes, Delete Report"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Report"
                    hasError={hasDeletingError}
                    errorMessage="
                        You were unable to delete the report because there
                        was an unexpected error. Please try again in a few moments."
                    onConfirm={onDelete}
                    onCancel={() => setDeleteModalVisible(false)}
                />
            )}

            {isSnapshotModalVisible && (
                <SnapshotModal
                    reportType={entityTypes.EVENT_LOG_REPORTS}
                    reports={reports}
                    isReady={isReady}
                    hasError={hasSnapshotError}
                    onConfirm={handleSnapshot}
                    onCancel={() => setSnapshotModalVisible(false)}
                />
            )}
        </>
    );
};

export default ReportEventLogDetail;
