import React from 'react';
import { permissions } from 'js/constants';
import Box from 'js/components/box/box';
import Input from 'js/components/input/input';
import Checkbox from 'js/components/checkbox/checkbox';

export default function UserForm({
    user: { email, first_name: firstName, last_name: lastName, roles = [] },
    onSetEmail,
    onSetFirstName,
    onSetLastName,
    setPermissions,
    userErrors,
    isReadOnly,
}) {
    const hiddenRoles = roles.filter(
        (role) => !permissions.some(({ value }) => value === role),
    );
    const selectedRole = roles.find((role) => !hiddenRoles.includes(role));

    return (
        <>
            <Box margin={[0, 0, 'base']}>
                <Input
                    disabled={isReadOnly}
                    required
                    label="Email"
                    maxLength={150}
                    value={email}
                    onChange={onSetEmail}
                    hasError={!!userErrors.email}
                    errorMessage={<p>{userErrors.email}</p>}
                    focusOnShow
                />
            </Box>
            <Box margin={[0, 0, 'base']}>
                <Input
                    disabled={isReadOnly}
                    label="First Name"
                    maxLength={30}
                    required
                    value={firstName}
                    onChange={onSetFirstName}
                    hasError={!!userErrors.first_name}
                    errorMessage={<p>{userErrors.first_name}</p>}
                />
            </Box>
            <Box margin={[0, 0, 'base']}>
                <Input
                    disabled={isReadOnly}
                    label="Surname"
                    maxLength={150}
                    required
                    value={lastName}
                    onChange={onSetLastName}
                    hasError={!!userErrors.last_name}
                    errorMessage={<p>{userErrors.last_name}</p>}
                />
            </Box>
            <Box margin={[0, 0, 'large']}>
                <Checkbox
                    label="Role"
                    radio
                    options={permissions}
                    selectedValues={selectedRole}
                    hasError={!!userErrors.roles}
                    errorMessage={<p>{userErrors.roles}</p>}
                    onChange={(role) => {
                        setPermissions([...hiddenRoles, role]);
                    }}
                />
            </Box>
        </>
    );
}
