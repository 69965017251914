import React, {
    useCallback,
    useContext,
    useLayoutEffect,
    useState,
} from 'react';
import {
    AbortError,
    api,
    getUpdatedList,
    setDocumentTitle,
    getJwtPayload,
} from 'js/utils';
import { NotificationsContext } from 'js/contexts';
import { showToast } from 'js/components/toast/toast';
import entityTypes from 'js/enums/entity-types.enum';
import serviceTypes from 'js/enums/service-types.enum';
import templateMethodMap from 'js/enums/templateMethodMap.enum';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import sections from './enums/page-sections.enum';
import Home from './home';

const modules = [
    {
        id: sections.SPOTLIGHT,
        label: 'What’s New in 4D',
    },
    {
        id: sections.NOTIFICATIONS,
        label: 'Notifications',
    },
    {
        id: sections.FAVORITES,
        label: 'Favorites',
    },
    {
        id: sections.RECENT_UPDATES,
        label: 'Recently Updated',
    },
];

const defaultLayout = {
    layout_sections: modules.map((i) => ({ section: i.id })),
};

function HomePage() {
    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );
    const { unseenNotifications, refreshNotifications, seeNotification } =
        useContext(NotificationsContext);
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const [hasError, setError] = useState(false);
    const [recentContexts, setRecentContexts] = useState([]);
    const [recentReports, setRecentReports] = useState([]);
    const [pageLayout, setPageLayout] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [features, setFeatures] = useState([]);
    const [favorites, setFavorites] = useState({
        [entityTypes.ADVERTISERS]: [],
        [entityTypes.CAMPAIGNS]: [],
        [entityTypes.CONTEXTS]: [],
        [entityTypes.DISPLAY_REPORTS]: [],
        [entityTypes.VIDEO_REPORTS]: [],
        [entityTypes.EVENT_LOG_REPORTS]: [],
    });
    const [togglingFavoriteError, setTogglingFavoriteError] = useState({});
    const [isCancellingSchedule, setCancellingSchedule] = useState(false);

    const unseenCountInTopBar = unseenNotifications.length;

    const pageSections = (pageLayout.layout_sections || []).map(({ section }) =>
        modules.find((module) => module.id === section),
    );

    const loadPageLayouts = async () => {
        const layouts = (await api().homepageLayouts.list()).results.concat([
            defaultLayout,
        ]);
        setPageLayout(layouts[0]);
    };

    const loadNotifications = async () => {
        try {
            const { results: notificationsData } =
                await api().notifications.list({
                    ordering: '-created',
                });
            const filteredNotifications = notificationsData.filter(
                (notification) =>
                    notification.category !== 'Insights' || insightsService,
            );
            const unseenCount = filteredNotifications.filter(
                ({ is_seen: isSeen }) => !isSeen,
            ).length;
            if (unseenCount !== unseenCountInTopBar) {
                refreshNotifications();
            }
            setNotifications(filteredNotifications);
        } catch (err) {
            // todo: this
        }
    };

    const loadFeatures = async () => {
        try {
            const { results: featuresData } = await api().features.list();

            setFeatures(featuresData);
        } catch (err) {
            // todo: this
        }
    };

    const loadFavorites = async () => {
        const callbacks = [
            api().advertisers.list({
                verbose: true,
                is_favourite: true,
                ordering: '-deep_updated',
            }),
            api().contextGroups.list({
                verbose: true,
                is_favourite: true,
                is_public: false,
                ordering: '-deep_updated',
            }),
            api().contexts.list({
                verbose: true,
                is_favourite: true,
                is_public: false,
                ordering: '-updated',
            }),
            api().optimizeReportTemplates.list({
                verbose: true,
                is_favourite: true,
                ordering: '-updated',
            }),
            api().videoReportTemplates.list({
                verbose: true,
                is_favourite: true,
                ordering: '-updated',
            }),
            api().eventLogReportTemplates.list({
                verbose: true,
                is_favourite: true,
                ordering: '-updated',
            }),
        ];

        const [
            { results: advertisers },
            { results: campaigns },
            { results: contexts },
            { results: displayReports },
            { results: videoReports },
            { results: eventLogReports },
        ] = await Promise.all(callbacks);

        setFavorites({
            [entityTypes.ADVERTISERS]: advertisers,
            [entityTypes.CAMPAIGNS]: campaigns,
            [entityTypes.CONTEXTS]: contexts,
            [entityTypes.DISPLAY_REPORTS]: displayReports.map((report) => ({
                ...report,
                type: entityTypes.DISPLAY_REPORTS,
            })),
            [entityTypes.VIDEO_REPORTS]: videoReports.map((report) => ({
                ...report,
                type: entityTypes.VIDEO_REPORTS,
            })),
            [entityTypes.EVENT_LOG_REPORTS]: eventLogReports.map((report) => ({
                ...report,
                type: entityTypes.EVENT_LOG_REPORTS,
            })),
        });
    };

    const loadRecentUpdates = async () => {
        const callbacks = [
            api().contexts.list({
                is_public: false,
                ordering: '-updated',
                limit: 6,
                verbose: true,
                field: [
                    'id',
                    'name',
                    'updated',
                    'group',
                    'group_name',
                    'advertiser',
                    'advertiser_name',
                    'is_favourite',
                    'is_video',
                    'scale',
                ],
            }),
            api().optimizeReportTemplates.list({
                ordering: '-deep_updated',
                limit: 6,
                verbose: true,
            }),
            api().videoReportTemplates.list({
                ordering: '-deep_updated',
                limit: 6,
                verbose: true,
            }),
            api().eventLogReportTemplates.list({
                ordering: '-deep_updated',
                limit: 6,
                verbose: true,
            }),
        ];

        const [
            { results: recentContextsData },
            { results: recentOptimiseReportData },
            { results: recentVideoReportData },
            { results: recentEventLogReportData },
        ] = await Promise.all(callbacks);

        setRecentContexts(recentContextsData);
        setRecentReports(
            [
                ...recentOptimiseReportData.map((item) => ({
                    ...item,
                    type: entityTypes.DISPLAY_REPORTS,
                })),
                ...recentVideoReportData.map((item) => ({
                    ...item,
                    type: entityTypes.VIDEO_REPORTS,
                })),
                ...recentEventLogReportData.map((item) => ({
                    ...item,
                    type: entityTypes.EVENT_LOG_REPORTS,
                })),
            ].sort((a, b) => a.deep_updated < b.deep_updated),
        );
    };

    const loadData = useCallback(async () => {
        setLoading(true);
        setError(false);

        try {
            await Promise.all([
                loadPageLayouts(),
                loadNotifications(),
                loadFavorites(),
                loadFeatures(),
                loadRecentUpdates(),
            ]);
        } catch (err) {
            if (err instanceof AbortError) {
                return;
            }

            setError(true);
        } finally {
            setLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleFavorite = async (entityType, entityId, isFavorite) => {
        const favoriteItem = (
            isFavorite
                ? [...recentContexts, ...recentReports]
                : favorites[entityType]
        ).find(({ id }) => id === entityId);
        setFavorites((prevState) => ({
            ...prevState,
            [entityType]: getUpdatedList(prevState[entityType], entityId, {
                isFavoriteLoading: true,
            }),
        }));
        setRecentContexts((prevState) =>
            getUpdatedList(prevState, entityId, {
                isFavoriteLoading: true,
            }),
        );
        setRecentReports((prevState) =>
            getUpdatedList(prevState, entityId, {
                isFavoriteLoading: true,
            }),
        );
        setTogglingFavoriteError({});

        try {
            let apiRoute;
            if (
                entityType === entityTypes.DISPLAY_REPORTS ||
                entityType === entityTypes.VIDEO_REPORTS ||
                entityType === entityTypes.EVENT_LOG_REPORTS
            ) {
                apiRoute = templateMethodMap[favoriteItem.type];
            } else {
                apiRoute = entityType;
            }

            await api()[apiRoute].favorite(entityId, isFavorite);

            setFavorites((prevState) => ({
                ...prevState,
                [entityType]: isFavorite
                    ? [
                          ...prevState[entityType],
                          { ...favoriteItem, is_favourite: true },
                      ]
                    : [
                          ...prevState[entityType].filter(
                              ({ id }) => id !== entityId,
                          ),
                      ],
            }));
            setRecentContexts((prevState) =>
                getUpdatedList(prevState, entityId, {
                    is_favourite: isFavorite,
                    isFavoriteLoading: false,
                }),
            );
            setRecentReports((prevState) =>
                getUpdatedList(prevState, entityId, {
                    is_favourite: isFavorite,
                    isFavoriteLoading: false,
                }),
            );
        } catch (err) {
            setTogglingFavoriteError({
                title: favoriteItem.name,
                isFavorite,
            });
            setFavorites((prevState) => ({
                ...prevState,
                [entityType]: getUpdatedList(prevState[entityType], entityId, {
                    isFavoriteLoading: false,
                }),
            }));
            setRecentContexts((prevState) =>
                getUpdatedList(prevState, entityId, {
                    isFavoriteLoading: false,
                }),
            );
            setRecentReports((prevState) =>
                getUpdatedList(prevState, entityId, {
                    isFavoriteLoading: false,
                }),
            );
        }
    };

    const savePageLayout = async (updatedLayoutSections) => {
        setSaving(true);

        try {
            const newLayout = {
                ...pageLayout,
                layout_sections: updatedLayoutSections,
            };
            const method = pageLayout.id
                ? api().homepageLayouts.update
                : api().homepageLayouts.create;
            const args = pageLayout.id ? ['current', newLayout] : [newLayout];
            const layout = await method(...args);
            setPageLayout(layout);
        } catch (err) {
            if (err instanceof AbortError) return;
            showToast(
                'There Was An Unexpected Error',
                <p>
                    The page layout has not been saved. Please try again in a
                    few moments.
                </p>,
                null,
                'danger',
            );
        } finally {
            setSaving(false);
        }
    };

    const cancelTemplateSchedule = async (template) => {
        setCancellingSchedule(true);
        const method = templateMethodMap[template.type];
        try {
            await api()[method].update(template.id, {
                schedule: null,
            });

            const updatedFavorites = {
                [entityTypes.DISPLAY_REPORTS]: favorites[
                    entityTypes.DISPLAY_REPORTS
                ].map((dr) => {
                    if (dr.id === template.id) {
                        return {
                            ...dr,
                            schedule: null,
                        };
                    }
                    return dr;
                }),
                [entityTypes.VIDEO_REPORTS]: favorites[
                    entityTypes.VIDEO_REPORTS
                ].map((vr) => {
                    if (vr.id === template.id) {
                        return {
                            ...vr,
                            schedule: null,
                        };
                    }
                    return vr;
                }),
            };

            const updatedRecentReports = recentReports.map((rp) => {
                if (rp.id === template.id) {
                    return {
                        ...rp,
                        schedule: null,
                    };
                }

                return rp;
            });

            setFavorites((prev) => ({
                ...prev,
                ...updatedFavorites,
            }));
            setRecentReports(updatedRecentReports);
            setCancellingSchedule(false);
            showToast(
                'Scheduling',
                <p>Scheduling stopped for {template.name}.</p>,
                10,
            );
        } catch (err) {
            if (err instanceof AbortError) return;
            setCancellingSchedule(false);
            showToast(
                'There Was An Unexpected Error',
                <p>
                    An unexpected error occurred while stopping scheduling for{' '}
                    {template.title}.
                </p>,
                10,
                'danger',
            );
        }
    };

    const handleDelete = async (entityType, entityId) => {
        await api()[entityType].destroy(entityId);
        loadData();
    };

    useLayoutEffect(() => {
        setDocumentTitle(['4D']);
        loadData();

        return () => {
            api().abortAll();
        };
    }, [loadData]);

    return (
        <>
            {isCancellingSchedule && (
                <LoadingLayer message="Stopping Scheduling" />
            )}
            <Home
                isLoading={isLoading}
                isSaving={isSaving}
                hasError={hasError}
                recentContexts={recentContexts}
                recentReports={recentReports}
                pageSections={pageSections}
                notifications={notifications}
                features={features}
                favoriteAdvertisers={favorites[entityTypes.ADVERTISERS]}
                favoriteCampaigns={favorites[entityTypes.CAMPAIGNS]}
                favoriteContexts={favorites[entityTypes.CONTEXTS]}
                favoriteReports={[
                    ...favorites[entityTypes.DISPLAY_REPORTS],
                    ...favorites[entityTypes.VIDEO_REPORTS],
                    ...favorites[entityTypes.EVENT_LOG_REPORTS],
                ]}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavorite={toggleFavorite}
                onNotificationsChange={loadNotifications}
                onSeeNotification={seeNotification}
                onSavePageLayout={savePageLayout}
                onDelete={handleDelete}
                onRetry={loadData}
                onCancelScheduling={cancelTemplateSchedule}
                pageLayout={pageLayout}
            />
        </>
    );
}

export default HomePage;
