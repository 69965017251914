import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './stepper.module.scss';

const cx = classNames.bind(styles);

function Step({ children, isActive, isCompleted, onClick }) {
    const classes = cx({
        step: true,
        active: isActive,
        completed: isCompleted,
    });

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li className={classNames(classes)} onClick={onClick}>
            <span className={styles.label}>{children}</span>

            {isCompleted && (
                <span className={styles.icon}>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
            )}
        </li>
    );
}

function Stepper({ steps, currentStep, onStepChange }) {
    return (
        <div>
            <ul className={styles.container}>
                {steps.map((step, index) => (
                    <Step
                        key={step.id}
                        isActive={currentStep === index}
                        isCompleted={currentStep > index}
                        onClick={() => onStepChange(index)}
                    >
                        {step.label}
                    </Step>
                ))}
            </ul>
        </div>
    );
}

Stepper.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    currentStep: PropTypes.number.isRequired,
    onStepChange: PropTypes.func.isRequired,
};

export default Stepper;
