import React from 'react';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import DrawerFooter from 'js/components/drawer/footer';
import Col from 'js/components/grid/column';
import Layout from 'js/components/layout/layout';
import Number from 'js/components/number/number';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import Statistic from 'js/components/statistic/statistic';
import Text from 'js/components/text/text';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import styles from './inspect-drawer.module.scss';

const MatchedItem = ({
    matchedPage: {
        url,
        domain,
        title,
        thumbnail,
        channel,
        channel_id: channelId,
        channel_thumbnail: channelThumbnail,
        channel_follower_count: channelFollowerCount,
    },
}) => (
    <div className={styles['matched-item']}>
        <Row>
            <Col>
                <Text size="small" color={['gray', 'dark']}>
                    {domain}
                </Text>

                <h3 className={styles['content-title']}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {title || url.split('?')[0].split('#')[0]}{' '}
                        <span className={styles['content-title-icon']}>
                            <FontAwesomeIcon icon={faExternalLink} />
                        </span>
                    </a>
                </h3>
                <Box margin={['base', 0, 'small']}>
                    <Row alignItems="center">
                        {channelThumbnail && (
                            <Col span="auto">
                                <img
                                    className={styles['channel-thumbnail']}
                                    src={channelThumbnail}
                                    alt={`Thumbnail for ${channel} channel`}
                                />
                            </Col>
                        )}
                        <Col span="auto">
                            <div className={styles['channel-link']}>
                                <a
                                    href={`https://www.youtube.com/channel/${channelId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {channel}
                                </a>
                            </div>
                            <Text>
                                <Number
                                    value={channelFollowerCount}
                                    abbreviationFormats={[
                                        {
                                            target: 1e6,
                                            symbol: 'm',
                                            maxFractionDigits: 2,
                                        },
                                        {
                                            target: 1e4,
                                            appliedValue: 1e3,
                                            symbol: 'k',
                                            maxFractionDigits: 0,
                                        },
                                        {
                                            target: 1e3,
                                            appliedValue: 1e3,
                                            symbol: 'k',
                                            maxFractionDigits: 2,
                                        },
                                        {
                                            target: 1,
                                            appliedValue: 1,
                                            symbol: '',
                                            maxFractionDigits: 0,
                                        },
                                    ]}
                                />{' '}
                                Subscribers
                            </Text>
                        </Col>
                    </Row>
                </Box>
            </Col>

            {thumbnail && (
                <Col span="auto">
                    <div className={styles['content-image']}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img src={thumbnail} alt={title} />
                        </a>
                    </div>
                </Col>
            )}
        </Row>
    </div>
);

export const MatchedVideos = ({ videos, isLoading, hasError, onReload }) => {
    if (isLoading) {
        return (
            <Spinner
                size="large"
                message="Loading"
                color={['gray', 'dark']}
                isCentered
            />
        );
    }

    if (hasError) {
        return (
            <Alert
                theme="danger"
                title="There Was An Error Loading Data"
                actionItems={<Button onClick={onReload}>Retry</Button>}
            >
                <p>
                    Hopefully it is only a temporary issue. Please try again in
                    a few moments.
                </p>
            </Alert>
        );
    }

    if (!videos.length) {
        return <Alert theme="empty" title="There Are No Matched Videos" />;
    }

    return (
        <>
            {videos.map((item) => (
                <Box margin={[0, 0, 'base']} key={item.url}>
                    <MatchedItem matchedPage={item} />
                </Box>
            ))}
        </>
    );
};

export const InspectDrawerSummary = ({
    report,
    template,
    impressions,
    clicks,
    completes,
    backgroundImg,
    header,
}) => {
    const isDV360Report =
        template.subtype === videoReportSubtypes.YOUTUBE_DV360;

    return (
        <Section backgroundImg={backgroundImg}>
            <Container size="medium">
                <Box padding={['large', 0]}>
                    <div className={styles.summary}>
                        {header && <Box padding="base">{header}</Box>}

                        <Box
                            padding="base"
                            borderTop={['base', 'solid', 'gray', 'lightest']}
                        >
                            <Row gap="base">
                                <Statistic
                                    name="Impressions"
                                    comparison={
                                        <>
                                            <Number
                                                value={
                                                    impressions /
                                                    report.impressions
                                                }
                                                isPercentage
                                                maximumFractionDigits={2}
                                            />{' '}
                                            Of Total
                                        </>
                                    }
                                >
                                    <Number value={impressions} />
                                </Statistic>

                                <Statistic
                                    name="Clicks"
                                    comparison={
                                        <>
                                            <Number
                                                value={clicks / report.clicks}
                                                isPercentage
                                                maximumFractionDigits={2}
                                            />{' '}
                                            Of Total
                                        </>
                                    }
                                >
                                    <Number value={clicks} />
                                </Statistic>

                                <Statistic
                                    name={
                                        isDV360Report
                                            ? 'True Views'
                                            : 'Completes'
                                    }
                                    comparison={
                                        <>
                                            <Number
                                                value={
                                                    completes / report.completes
                                                }
                                                isPercentage
                                                maximumFractionDigits={2}
                                            />{' '}
                                            Of Total
                                        </>
                                    }
                                >
                                    <Number value={completes} />
                                </Statistic>
                            </Row>
                        </Box>
                    </div>
                </Box>
            </Container>
        </Section>
    );
};

const InspectDrawer = ({ summary, content, onClose }) => (
    <Drawer onClose={onClose}>
        <Layout
            footer={
                <DrawerFooter>
                    <Container size="medium">
                        <Box padding={['small', 0]}>
                            <Row justifyContent="flex-end">
                                <Col span="auto">
                                    <Button onClick={onClose}>Done</Button>
                                </Col>
                            </Row>
                        </Box>
                    </Container>
                </DrawerFooter>
            }
            isFooterSticky
        >
            {summary}

            <Container size="medium">{content}</Container>
        </Layout>
    </Drawer>
);

export default InspectDrawer;
