import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import permissionGroups from 'js/enums/permission-groups.enum';
import { getJwtPayload } from 'js/utils';
import { useIsMounting } from 'js/hooks';
import { NotificationsContext } from 'js/contexts';
import Breadcrumbs from 'js/components/breadcrumbs/breadcrumbs';
import CreateNewButton from 'js/components/button/create-new-button';
import NotificationsButton from 'js/components/button/notifications-button';
import Container from 'js/components/container/container';
import SearchBar from 'js/components/search-bar/search-bar';
import Section from 'js/components/section/section';
import Text from 'js/components/text/text';
import styles from './top-bar.module.scss';

function TopBar({
    title,
    breadcrumbs,
    searchPlaceholder,
    onNotificationsChange,
}) {
    const isMounting = useIsMounting();
    const [isMobile, setMobile] = useState(false);
    const { unseenNotifications, seeAllNotifications } =
        useContext(NotificationsContext);
    const { pathname } = useLocation();
    const isNotificationsPage =
        pathname.replace(/\/+$/, '') === '/notifications';

    const isReadOnly = () => {
        const jwt = getJwtPayload(localStorage.getItem('AuthToken'));

        return (
            !jwt.is_superuser && jwt.roles.includes(permissionGroups.VIEW_ONLY)
        );
    };

    useEffect(() => {
        const MAX_MOBILE_WIDTH = 576; // Equal to `sm` breakpoint in _tokens.scss
        const handleResize = () => {
            setMobile(window.innerWidth < MAX_MOBILE_WIDTH);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!isMounting && onNotificationsChange) {
            onNotificationsChange();
        }
    }, [unseenNotifications]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Section theme="primary" hasBorderBottom>
            <Container>
                <div className={styles.topbar}>
                    <div className={styles.breadcrumbs}>
                        {title && (
                            <Text size="large" weight="bolder">
                                <h1>{title}</h1>
                            </Text>
                        )}

                        {breadcrumbs && (
                            <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
                        )}
                    </div>

                    <div className={styles.actions}>
                        <SearchBar
                            align={isMobile ? 'left' : 'right'}
                            placeholder={searchPlaceholder}
                            hasOverlap={!isMobile}
                        />

                        <NotificationsButton
                            notifications={unseenNotifications}
                            active={isNotificationsPage}
                            onSeen={seeAllNotifications}
                        />

                        {!isReadOnly() && <CreateNewButton />}
                    </div>
                </div>
            </Container>
        </Section>
    );
}

TopBar.defaultProps = {
    title: undefined,
    breadcrumbs: undefined,
    searchPlaceholder: 'Search',
    onNotificationsChange: undefined,
};

TopBar.propTypes = {
    title: PropTypes.node,
    breadcrumbs: PropTypes.arrayOf(PropTypes.node),
    searchPlaceholder: PropTypes.string,
    onNotificationsChange: PropTypes.func,
};

export default TopBar;
