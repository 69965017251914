import {
    faCheckCircle,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

export const exampleCampaignName = 'Example - Summer 2022';
export const exampleCampaignId = '12345678';
export const exampleLineItemName = 'Days Out - Summer 2022';
export const exampleLineItemId = '123456789101112';
export const tabName = 'Name & IDs';

export const alertMessages = {
    uploadConfirmed: {
        msg: 'Upload confirmed.',
        theme: 'primary',
        icon: faCheckCircle,
    },
    errorOnSave: {
        msg: 'There was an error saving the campaigns and line items.',
        theme: 'danger',
        icon: faExclamationCircle,
    },
    noItems: {
        msg: 'Template unsuccessfully uploaded - 4D is unable to read any items from the file.',
        theme: 'danger',
        icon: faExclamationCircle,
    },
    incompleteData: {
        msg: 'Template unsuccessfully uploaded - The file contains rows with incomplete data.',
        theme: 'danger',
        icon: faExclamationCircle,
    },
    invalidSource: {
        msg: 'Template unsuccessfully uploaded - 4D is unable to recognize some ‘Source’ values.',
        theme: 'danger',
        icon: faExclamationCircle,
    },
    failedToOpen: {
        msg: 'Template unsuccessfully uploaded - 4D is unable to open the file.',
        theme: 'danger',
        icon: faExclamationCircle,
    },
};
