import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import Box from '../box/box';
import Col from '../grid/col';
import Row from '../grid/row';
import Section from '../section/section';
import SeparatedSection from '../separated-section/separated-section';
import styles from './groups.styles';
import Emblem from '../emblem/emblem';
import { color } from '../tokens.styles';

const parameterTypes = {
    impressions: { label: 'Imps', color: color.purple.base },
    ctr: { label: 'CTR', color: color.aqua.dark },
    viewRate: { label: 'View Rate', color: color.blue.light },
    starts: { label: 'Started', color: color.aqua.dark },
    completionRate: { label: 'Completed', color: color.blue.light },
    trueViewRate: { label: 'T.V. Rate', color: color.blue.light },
};

function TopGroup({
    group,
    orientation,
    background,
    showTitle,
    parameters,
    mainParameter,
}) {
    const isPortrait = orientation === 'portrait';
    const { icon, label: title } = group;
    return (
        <Row gutter="small">
            <Col span="auto" gutter="small">
                <Emblem
                    orientation={orientation}
                    logo={icon}
                    label={title}
                    background={background}
                    size={isPortrait ? 70 : 75}
                />
            </Col>

            <Col gutter="small">
                {showTitle && (
                    <Text
                        style={
                            isPortrait
                                ? styles.itemTitlePortrait
                                : styles.itemTitleLandscape
                        }
                    >
                        {title}
                    </Text>
                )}

                {parameters.map((parameter) => (
                    <Row alignItems="center" gutter="smallest" key={parameter}>
                        <Col span="auto" gutter="smallest">
                            <View
                                style={[
                                    styles.dot,
                                    {
                                        backgroundColor:
                                            parameterTypes[parameter].color,
                                    },
                                ]}
                            />
                        </Col>

                        <Col gutter="smallest">
                            <Text
                                style={
                                    isPortrait
                                        ? styles.parameterPortrait
                                        : styles.parameterLandscape
                                }
                            >
                                <Text
                                    style={
                                        parameter === mainParameter ||
                                        !mainParameter
                                            ? styles.mainParameter
                                            : null
                                    }
                                >
                                    {parameterTypes[parameter].label}
                                </Text>{' '}
                                {group[parameter]}
                            </Text>
                        </Col>
                    </Row>
                ))}
            </Col>
        </Row>
    );
}

function Groups({
    orientation,
    title,
    groups,
    itemsPerRow,
    hasBorderTop,
    background,
    showGroupTitles,
    parameters,
    mainParameter,
}) {
    const isPortrait = orientation === 'portrait';
    const rowSize = itemsPerRow || groups.length;
    const topGroupRows = Array(Math.ceil(groups.length / rowSize))
        .fill()
        .map((_, index) => index * rowSize)
        .map((begin) => groups.slice(begin, begin + rowSize));

    const topGroups = (row) =>
        row.map((group) => (
            <TopGroup
                group={group}
                orientation={orientation}
                showTitle={showGroupTitles}
                background={background}
                parameters={parameters}
                mainParameter={mainParameter}
                key={group.label}
            />
        ));

    return title ? (
        <Section hasBorderTop={hasBorderTop}>
            <Box padding={['base', 0]}>
                <Row>
                    <Col span={isPortrait ? 12 : 4}>
                        <Text
                            style={
                                isPortrait
                                    ? styles.titlePortrait
                                    : styles.titleLandscape
                            }
                        >
                            {title}
                        </Text>
                    </Col>

                    <Col span={isPortrait ? 12 : 8}>
                        {topGroupRows.map((row, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Box padding={['small', 0, 0]} key={index}>
                                <SeparatedSection
                                    alignItems="flex-start"
                                    items={topGroups(row)}
                                />
                            </Box>
                        ))}
                    </Col>
                </Row>
            </Box>
        </Section>
    ) : (
        topGroupRows.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box margin={['base', 0, 0]} key={index}>
                <SeparatedSection
                    alignItems="flex-start"
                    items={topGroups(row)}
                />
            </Box>
        ))
    );
}

TopGroup.defaultProps = {
    orientation: 'portrait',
    background: undefined,
    showTitle: false,
    mainParameter: undefined,
};

TopGroup.propTypes = {
    group: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    background: PropTypes.string,
    showTitle: PropTypes.bool,
    parameters: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(parameterTypes)))
        .isRequired,
    mainParameter: PropTypes.oneOf(Object.keys(parameterTypes)),
};

Groups.defaultProps = {
    orientation: 'portrait',
    title: undefined,
    itemsPerRow: undefined,
    hasBorderTop: false,
    showGroupTitles: false,
    background: undefined,
    mainParameter: undefined,
};

Groups.propTypes = {
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    title: PropTypes.node,
    groups: PropTypes.array.isRequired,
    itemsPerRow: PropTypes.number,
    hasBorderTop: PropTypes.bool,
    background: PropTypes.string,
    showGroupTitles: PropTypes.bool,
    parameters: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(parameterTypes)))
        .isRequired,
    mainParameter: PropTypes.oneOf(Object.keys(parameterTypes)),
};

export default Groups;
