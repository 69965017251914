import React, { useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import ContextDetailContext from '../../contexts/context-detail.context';

export default function SectionWaypoint({
    sectionId,
    children,
    onEnter,
    onLeave,
}) {
    const {
        pageSections: { anchorRefs: pageAnchorRefs },
    } = useContext(ContextDetailContext);

    return (
        <Waypoint onEnter={onEnter} onLeave={onLeave}>
            <div id={sectionId} ref={pageAnchorRefs[`${sectionId}Ref`]}>
                {children}
            </div>
        </Waypoint>
    );
}
