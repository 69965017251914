import React, { useState } from 'react';
import sortTypes from 'js/enums/sort-types.enum';
import { sortOptions } from 'js/constants';
import { createPresetUrl } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardCreateNew from 'js/components/card/card-create-new';
import CardLoading from 'js/components/card/card-loading';
import CardReport from 'js/components/card/card-report';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import Select from 'js/components/select/select';
import Text from 'js/components/text/text';

const Wrapper = ({ children }) => (
    <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
        <Box padding={['base', 0, 0]}>{children}</Box>
    </Col>
);

const FavoriteTemplates = ({
    templates,
    onCancelTemplateSchedule,
    onToggleTemplateFavorite,
    onDelete,
}) => (
    <Panel theme="secondary">
        <Box padding="base">
            <Text weight="bold" size="large">
                Favorites
            </Text>

            {templates.map((template) => (
                <Box padding={['small', 0, 0]} key={template.id}>
                    <CardReport
                        isSlim
                        report={template}
                        onCancelSchedule={() =>
                            onCancelTemplateSchedule(template)
                        }
                        onToggleFavorite={() =>
                            onToggleTemplateFavorite(template)
                        }
                        onDelete={() => onDelete(template.type, template.id)}
                    />
                </Box>
            ))}
        </Box>
    </Panel>
);

const TemplateList = ({
    templates,
    advertiserId,
    isLoading,
    hasError,
    onCancelTemplateSchedule,
    onToggleTemplateFavorite,
    onRetry,
    onDelete,
}) => {
    const [sorting, setSorting] = useState(sortTypes.UPDATED_DESC);

    const sortedTemplates = [...templates].sort((a, b) => {
        const desc = sorting[0] === '-';
        const field = sorting
            .substring(desc ? 1 : 0)
            .replace('updated', 'deep_updated');
        return a[field].localeCompare(b[field]) * (desc ? -1 : 1);
    });

    const favoriteTemplates = sortedTemplates.filter(
        (item) => item.is_favourite,
    );

    const creationUrl = createPresetUrl('/insights/new/', {
        advertiser: advertiserId,
    });

    return (
        <>
            <SectionTitle
                hasBar
                extension={[
                    <Button to={creationUrl} theme="outline">
                        Create Report
                    </Button>,
                    <Select
                        prefix="Sort by"
                        theme="filter"
                        options={sortOptions}
                        selectedValues={sorting}
                        onChange={setSorting}
                    />,
                ]}
            >
                Reports
            </SectionTitle>

            {favoriteTemplates.length > 0 && (
                <Box padding={['large', 0, 0]}>
                    <FavoriteTemplates
                        templates={favoriteTemplates}
                        onCancelTemplateSchedule={onCancelTemplateSchedule}
                        onToggleTemplateFavorite={onToggleTemplateFavorite}
                        onDelete={onDelete}
                    />
                </Box>
            )}

            <Box margin={['base', 0, 0]}>
                <Row gutter="base">
                    {hasError && (
                        <Wrapper>
                            <Alert
                                theme="danger"
                                actionItems={
                                    <Button onClick={() => onRetry()}>
                                        Retry
                                    </Button>
                                }
                                title="There was an error loading the reports"
                            >
                                <p>
                                    Hopefully it is only a temporary issue.
                                    Please try again in a few moments.
                                </p>
                            </Alert>
                        </Wrapper>
                    )}

                    {!hasError && isLoading && (
                        <Wrapper>
                            <CardLoading loadingText="Loading Reports" />
                        </Wrapper>
                    )}

                    {!hasError && !isLoading && (
                        <>
                            {sortedTemplates.map((template) => (
                                <Wrapper key={template.id}>
                                    <CardReport
                                        report={template}
                                        onCancelSchedule={() =>
                                            onCancelTemplateSchedule(template)
                                        }
                                        onToggleFavorite={() =>
                                            onToggleTemplateFavorite(template)
                                        }
                                        onDelete={() =>
                                            onDelete(template.type, template.id)
                                        }
                                    />
                                </Wrapper>
                            ))}

                            <Wrapper>
                                <CardCreateNew
                                    title="Create New Report"
                                    to={creationUrl}
                                />
                            </Wrapper>
                        </>
                    )}
                </Row>
            </Box>
        </>
    );
};

export default TemplateList;
