import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Pill from 'js/components/pill/pill';
import Text from 'js/components/text/text';
import CardBase, { getRecentlyUpdated } from './card-base';
import CardWrapper from './components/card-wrapper';
import CardBaseTemplate from './components/card-base-template';
import CardSlimTemplate from './components/card-slim-template';

const CampaignPills = ({ campaign: { targeting_contexts: contextCount } }) => (
    <Box margin={[0, 'small', 0, 0]}>
        <Pill outline theme="muted">
            {contextCount || 0} Contexts
        </Pill>
    </Box>
);

const CardCampaign = ({
    isSlim,
    campaign,
    isPreview,
    onToggleFavorite,
    onDeleteCampaign,
}) => {
    const {
        id: campaignId,
        advertiser: campaignAdvertiserId,
        advertiser_name: campaignAdvertiserName,
        name: campaignName,
        deep_updated: campaignDeepUpdated,
        is_favourite: isCampaignFavorite,
        isFavoriteLoading: isCampaignFavoriteLoading,
    } = campaign;

    const campaignTopline =
        campaignAdvertiserName ||
        (isPreview && (
            <Text color={['gray', 'light']} inline>
                Advertiser name
            </Text>
        ));
    const campaignTitle = campaignName || (
        <Text color={['gray', 'light']}>Campaign name</Text>
    );
    const campaignDate = !campaignDeepUpdated
        ? new Date()
        : campaignDeepUpdated;
    const campaignLink = `/context/${campaignAdvertiserId}/${campaignId}/`;
    const updatedDate = getRecentlyUpdated(campaignDate);

    const [deleteErrors, setDeleteErrors] = useState({});
    const [deleteWarning, setDeleteWarning] = useState(false);

    const toggleDeleteWarningModal = () => {
        setDeleteWarning((state) => !state);
        setDeleteErrors({});
    };

    const onDeleteItem = async () => {
        setDeleteErrors({});
        try {
            await onDeleteCampaign(campaignId);
            toggleDeleteWarningModal();
        } catch (err) {
            setDeleteErrors(err);
        }
    };

    return (
        <>
            {deleteWarning && (
                <ConfirmModal
                    confirm="Yes, Delete Campaign"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Campaign"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage={`There was an unexpected error and the campaign was 
                        not deleted. Please try again in a few moments.`}
                    onCancel={toggleDeleteWarningModal}
                    onConfirm={onDeleteItem}
                >
                    <p>
                        Deleting a campaign is permanent and cannot be undone.
                        Any contexts in this campaign will also be deleted.
                    </p>
                </ConfirmModal>
            )}
            <CardWrapper to={!isPreview && campaignLink} title={campaignTitle}>
                <CardBase type="full" isPreview={isPreview} isSlim={isSlim}>
                    {isSlim ? (
                        <CardSlimTemplate
                            pill={<CampaignPills campaign={campaign} />}
                            isFavorite={isCampaignFavorite}
                            isLoading={isCampaignFavoriteLoading}
                            isPreview={isPreview}
                            to={`/campaigns/${campaignId}/`}
                            title={campaignTitle}
                            updatedDate={updatedDate}
                            onDelete={onDeleteCampaign}
                            onToggleFavorite={onToggleFavorite}
                            onToggleDeleteWarningModal={
                                toggleDeleteWarningModal
                            }
                        />
                    ) : (
                        <CardBaseTemplate
                            topline={campaignTopline}
                            pill={<CampaignPills campaign={campaign} />}
                            isFavorite={isCampaignFavorite}
                            isLoading={isCampaignFavoriteLoading}
                            isPreview={isPreview}
                            to={`/campaigns/${campaignId}/`}
                            title={campaignTitle}
                            updatedDate={updatedDate}
                            onDelete={onDeleteCampaign}
                            onToggleFavorite={onToggleFavorite}
                            onToggleDeleteWarningModal={
                                toggleDeleteWarningModal
                            }
                        />
                    )}
                </CardBase>
            </CardWrapper>
        </>
    );
};

CardCampaign.defaultProps = {
    isPreview: false,
    onToggleFavorite: undefined,
    onDeleteCampaign: undefined,
    isSlim: false,
};

CardCampaign.propTypes = {
    campaign: PropTypes.shape({
        id: PropTypes.string,
        advertiser: PropTypes.string,
        advertiser_name: PropTypes.node,
        name: PropTypes.node.isRequired,
        deep_updated: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        targeting_contexts: PropTypes.number,
        to: PropTypes.string,
        is_favourite: PropTypes.bool,
        isFavoriteLoading: PropTypes.bool,
    }).isRequired,
    isPreview: PropTypes.bool,
    onToggleFavorite: PropTypes.func,
    onDeleteCampaign: PropTypes.func,
    isSlim: PropTypes.bool,
};

export default CardCampaign;
