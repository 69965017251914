import React from 'react';
import { getEventFilterForReportType } from 'js/utils';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import Box from 'js/components/box/box';
import Checkbox from 'js/components/checkbox/checkbox';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypeOptions from '../../enums/video-report-subtype-options.enum';
import ReportTypeRadio from './report-type-radio';

const ReportSubtypeRadio = ({ options, selectedValue, onChange }) => (
    <Box
        margin={['small', 0, 0, 'base']}
        padding={['small', 0, 'small', 'base']}
        borderLeft={['thick', 'solid', 'gray', 'lightest']}
    >
        <Checkbox
            radio
            options={options}
            selectedValues={selectedValue}
            onChange={onChange}
        />
    </Box>
);

export default function ReportTypeStep({
    template,
    eventHierarchy,
    onChangeType,
}) {
    const firstVideoSubtype = Object.values(videoReportSubtypes).find(
        (subtype) =>
            eventHierarchy.some(
                getEventFilterForReportType(entityTypes.VIDEO_REPORTS, subtype),
            ),
    );

    const reportTypeOptions = [
        {
            label: 'Display Insights',
            value: entityTypes.DISPLAY_REPORTS,
        },
        {
            label: 'Video Insights',
            value: entityTypes.VIDEO_REPORTS,
            content: (
                <ReportSubtypeRadio
                    options={videoReportSubtypeOptions}
                    selectedValue={
                        videoReportSubtypeOptions.find(({ subtypes }) =>
                            subtypes.includes(template.subtype),
                        )?.value
                    }
                    onChange={(newValue) => {
                        const option = videoReportSubtypeOptions.find(
                            ({ value }) => value === newValue,
                        );
                        const subtype =
                            option.subtypes.find((item) =>
                                eventHierarchy.some(
                                    getEventFilterForReportType(
                                        entityTypes.VIDEO_REPORTS,
                                        item,
                                    ),
                                ),
                            ) || option.subtypes[0];
                        onChangeType(entityTypes.VIDEO_REPORTS, subtype);
                    }}
                />
            ),
        },
        {
            label: 'Event Log',
            value: entityTypes.EVENT_LOG_REPORTS,
        },
    ];

    return (
        <ReportTypeRadio
            label="Report Type"
            options={reportTypeOptions}
            selectedValue={template.type}
            onChange={(type) =>
                onChangeType(
                    type,
                    type === entityTypes.VIDEO_REPORTS
                        ? firstVideoSubtype ||
                              videoReportSubtypes.YOUTUBE_GADS_FILE
                        : null,
                )
            }
        />
    );
}
