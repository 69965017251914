import React, { useContext, useState } from 'react';
import Box from 'js/components/box/box';
import Panel from 'js/components/panel/panel';
import sectionTypes from 'js/enums/section-types.enum';
import ContextDetailContext from '../../contexts/context-detail.context';
import KeywordsPanel from '../keywords-panel/keywords-panel';
import PageSection from '../page-section/page-section';
import SectionWaypoint from '../section-waypoint/section-waypoint';
import VideoPanel from '../video-panel/video-panel';

export default function DimensionsSection() {
    const {
        context: {
            current: { is_video: isVideo },
        },
    } = useContext(ContextDetailContext);

    const [isKeywordsPanelVisible, setIsKeywordsPanelVisible] = useState(false);

    const description = `Target advertising based on the ${
        isVideo ? 'video' : 'page'
    } it will be displayed on, as well as the time and location it will be viewed in.`;

    return (
        <PageSection title="Dimensions" description={description}>
            <Box margin={['large', 0, 0]}>
                <Panel bordered>
                    {isVideo ? (
                        <SectionWaypoint
                            sectionId={sectionTypes.VIDEO_DIMENSION}
                        >
                            <VideoPanel />
                        </SectionWaypoint>
                    ) : (
                        <SectionWaypoint
                            sectionId={sectionTypes.KEYWORDS_DIMENSION}
                            onEnter={() => setIsKeywordsPanelVisible(true)}
                            onLeave={() => setIsKeywordsPanelVisible(false)}
                        >
                            <KeywordsPanel isVisible={isKeywordsPanelVisible} />
                        </SectionWaypoint>
                    )}
                </Panel>
            </Box>
        </PageSection>
    );
}
