import { spacing } from '../tokens.styles';

const styles = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        padding: spacing.large,
    },

    titleContainer: {
        right: 150, // portrait coverstrip width
    },

    titleLandscapeContainer: {
        right: 441, // landscape coverstrip width
    },

    logo: {
        maxWidth: 145,
        objectFit: 'contain',
        objectPosition: 'left top',
    },

    logoPortrait: {
        height: 25,
    },

    logoLandscape: {
        height: 35,
    },

    titleLogo: {
        maxWidth: 240,
        height: 45,
    },

    sideLogo: {
        maxWidth: 145,
    },

    titleSideLogo: {
        maxWidth: 250,
        height: 45,
    },
};

export default styles;
