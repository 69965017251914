import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { reloadPage } from 'js/utils';
import ActionBar from 'js/components/action-bar/action-bar';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import TopBar from 'js/components/top-bar/top-bar';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Navigation from 'js/components/navigation/navigation';
import CampaignForm from 'js/components/campaign/form';
import { CampaignDetailContext } from 'js/contexts';

const LoadingErrorContent = (isNew) => (
    <Section>
        <Container>
            <Box padding={['large', 0]}>
                <Alert
                    theme="danger"
                    title={`There was an error loading the ${
                        isNew ? 'page' : 'campaign'
                    }`}
                    actionItems={<Button onClick={reloadPage}>Retry</Button>}
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try again
                        in a few moments.
                    </p>
                </Alert>
            </Box>
        </Container>
    </Section>
);

export default function CampaignDetail({
    breadcrumbs,
    loading,
    loadErrors,
    advertisers,
    onAdvertiserCreate,
}) {
    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {
        campaign: {
            current: campaign,
            saved: savedCampaign,
            save: saveCampaign,
            delete: deleteCampaign,
            saving,
            setName,
            setAdvertiser,
        },
        errors: { save: saveErrors, delete: deleteErrors },
    } = useContext(CampaignDetailContext);

    const handleSave = async () => {
        try {
            const newCampaign = await saveCampaign();
            history.push(
                `/context/${newCampaign.advertiser}/${newCampaign.id}/`,
            );
        } catch {} // eslint-disable-line no-empty
    };

    const handleDelete = async () => {
        try {
            await deleteCampaign();
            history.push(`/context/${savedCampaign.advertiser}/`);
        } catch {} // eslint-disable-line no-empty
    };

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={breadcrumbs} />}
        >
            {campaign.id && !loading && (
                <ActionBar>
                    <Button
                        theme="outline"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        Delete
                    </Button>
                </ActionBar>
            )}

            {loading && (
                <Box padding={['large', 0]}>
                    <Spinner
                        size="large"
                        message="Loading"
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            )}

            {Object.keys(loadErrors).length > 0 && (
                <LoadingErrorContent isNew={!campaign.id} />
            )}

            {saving && (
                <LoadingLayer
                    message={`${campaign.id ? 'Saving' : 'Creating'} Campaign`}
                />
            )}

            {!loading && !Object.keys(loadErrors).length ? (
                <CampaignForm
                    campaign={campaign}
                    preset={{}}
                    advertisers={advertisers}
                    saving={saving}
                    saveErrors={saveErrors}
                    onNameChange={setName}
                    onAdvertiserChange={setAdvertiser}
                    onAdvertiserCreate={onAdvertiserCreate}
                    onSave={handleSave}
                />
            ) : null}

            {showDeleteModal ? (
                <ConfirmModal
                    confirm="Yes, Delete Campaign"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Campaign"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage="
                        You were unable to delete the campaign because there
                        was an unexpected error. Please try again in a few
                        moments."
                    onCancel={() => setShowDeleteModal(false)}
                    onConfirm={handleDelete}
                >
                    <p>
                        Deleting a campaign is permanent and cannot be undone.
                        Any campaigns and contexts in this campaign will also be
                        deleted.
                    </p>
                </ConfirmModal>
            ) : null}
        </Layout>
    );
}
