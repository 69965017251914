import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Label from './label';

function Node({
    isDropdown,
    onClick,
    onMouseEnter,
    onMouseLeave,
    hoverColor,
    activeColor,
    svgRef,
    nodeData,
    bgColor,
    style,
    hasChildren,
    id,
    x0,
    x1,
    y0,
    y1,
}) {
    const [color, setColor] = useState(bgColor);

    const width = x1 - x0;
    const height = y1 - y0;

    const paddedCurrentWidth =
        width -
        (Number(style.paddingLeft) || 0) -
        (Number(style.paddingRight) || 4);
    const clipWidth = Math.max(0, paddedCurrentWidth);

    useEffect(() => {
        setColor(bgColor);
    }, [bgColor]);

    return (
        <g
            onClick={(event) => {
                onClick(
                    event,
                    svgRef,
                    nodeData,
                    setColor,
                    activeColor,
                    isDropdown,
                );
            }}
            onMouseEnter={() => onMouseEnter(setColor, hoverColor, isDropdown)}
            onMouseLeave={() => onMouseLeave(setColor, bgColor, isDropdown)}
            style={isDropdown ? { cursor: 'pointer' } : {}}
            transform={`translate(${x0},${y0})`}
            id={`${id}`}
        >
            <rect
                id={`rect-${id}`}
                fill={color}
                width={width}
                height={height}
                style={
                    isDropdown
                        ? {
                              cursor: 'pointer',
                              ...style,
                          }
                        : { ...style }
                }
            />

            <clipPath id={`clip-${id}`}>
                <rect width={clipWidth} height={height} />
            </clipPath>

            <a href={nodeData.link} target="_blank" rel="noreferrer noopener">
                <text>
                    <Label
                        label={nodeData.label}
                        sublabel={nodeData.sublabel}
                        hasChildren={hasChildren}
                        containerWidth={clipWidth}
                        containerHeight={height}
                        style={style}
                    />
                </text>
            </a>
        </g>
    );
}

Node.defaultProps = {
    isDropdown: false,
    style: {},
    hasChildren: false,
    svgRef: undefined,
    hoverColor: undefined,
    activeColor: undefined,
    onClick: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
};

Node.propTypes = {
    isDropdown: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    hoverColor: PropTypes.string,
    activeColor: PropTypes.string,
    svgRef: PropTypes.object,
    nodeData: PropTypes.object.isRequired,
    bgColor: PropTypes.string.isRequired,
    style: PropTypes.object,
    hasChildren: PropTypes.bool,
    id: PropTypes.string.isRequired,
    x0: PropTypes.number.isRequired,
    x1: PropTypes.number.isRequired,
    y0: PropTypes.number.isRequired,
    y1: PropTypes.number.isRequired,
};

export default Node;
