import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import entityTypes from 'js/enums/entity-types.enum';
import { AbortError, api, getUpdatedList, setDocumentTitle } from 'js/utils';
import { showToast } from 'js/components/toast/toast';
import ActionBar from 'js/components/action-bar/action-bar';
import Button from 'js/components/button/button';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import templateMethodMap from 'js/enums/templateMethodMap.enum';
import ReportTemplateList from './report-template-list';

const dateString = 'YYYY-MM-DD';

const initialDateTemplate = {
    range: '30',
    start_date: null,
    end_date: null,
};

const getSubtractedDaysDate = (days) =>
    moment().subtract(days, 'days').format(dateString);

const getDateRange = (selectedRange, startDate, endDate) =>
    ({
        30: { activity_date_after: getSubtractedDaysDate(30) },
        7: { activity_date_after: getSubtractedDaysDate(7) },
        all: {},
        custom: {
            activity_date_after: moment(startDate).format(dateString),
            activity_date_before: moment(endDate).format(dateString),
        },
    }[selectedRange]);

const dateRangeMapTracker = (selectedRange, startDate, endDate) => ({
    ...{
        30: {
            startDate: getSubtractedDaysDate(30),
        },
        7: {
            startDate: getSubtractedDaysDate(7),
        },
        custom: {
            startDate,
            endDate,
        },
    }[selectedRange],
    selectedRange,
});

function ReportTemplateListPage() {
    const [previouslySelectedRange, setPreviouslySelectedRange] = useState(
        dateRangeMapTracker(
            initialDateTemplate.range,
            initialDateTemplate.start_date,
            initialDateTemplate.end_date,
        ),
    );
    const [selectedRange, setSelectedRange] = useState(
        initialDateTemplate.range,
    );
    const [startDate, setStartDate] = useState(initialDateTemplate.start_date);
    const [endDate, setEndDate] = useState(initialDateTemplate.end_date);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isCancellingSchedule, setCancellingSchedule] = useState(false);
    const [isEventDataLoading, setEventDataLoading] = useState(false);
    const [hasEventDataError, setHasEventDataError] = useState(false);
    const [eventAuditData, setEventAuditData] = useState([]);
    const [eventHierarchyData, setEventHierarchyData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [advertisers, setAdvertisers] = useState([]);

    const { advertiserId: advertiserIdParam } = useParams();
    const selectedAdvertiser = advertisers.find(
        ({ id }) => id === advertiserIdParam,
    );

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb
                key="insights"
                label="Insights"
                to={selectedAdvertiser ? '/insights/' : ''}
            />,
        ];
        if (selectedAdvertiser) {
            breadcrumbs.push(
                <Crumb
                    key="advertiser"
                    label={selectedAdvertiser.name}
                    isCollapsible
                />,
            );
        }
        if (hasError) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (isLoading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        }

        return breadcrumbs;
    };

    const loadData = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const [
                { results: advertisersData },
                { results: displayTemplatesData },
                { results: videoTemplatesData },
                { results: eventLogTemplatesData },
            ] = await Promise.all([
                api().advertisers.list({
                    ordering: '-deep_updated',
                    verbose: true,
                }),
                api().optimizeReportTemplates.list({
                    ordering: '-deep_updated',
                    verbose: true,
                }),
                api().videoReportTemplates.list({
                    ordering: '-deep_updated',
                    verbose: true,
                }),
                api().eventLogReportTemplates.list({
                    ordering: '-deep_updated',
                    verbose: true,
                }),
            ]);

            const templatesData = [
                ...displayTemplatesData.map((item) => ({
                    ...item,
                    type: entityTypes.DISPLAY_REPORTS,
                })),
                ...videoTemplatesData.map((item) => ({
                    ...item,
                    type: entityTypes.VIDEO_REPORTS,
                })),
                ...eventLogTemplatesData.map((item) => ({
                    ...item,
                    type: entityTypes.EVENT_LOG_REPORTS,
                })),
            ].sort((a, b) => a.deep_updated < b.deep_updated);

            setAdvertisers(
                advertisersData.filter((item) => item.context_groups > 0),
            );
            setTemplates(templatesData);
            setLoading(false);
        } catch (err) {
            if (err instanceof AbortError) return;
            setHasError(true);
            setLoading(false);
        }
    };

    const loadEventData = async () => {
        setEventDataLoading(true);
        setHasEventDataError(false);

        try {
            const [{ results: eventAudits }, { results: eventHierarchy }] =
                await Promise.all([
                    api().auditEvent.list({
                        verbose: true,
                        ...getDateRange(selectedRange, startDate, endDate),
                    }),
                    api().eventHierarchy.list({ verbose: true }),
                ]);

            setEventAuditData(eventAudits);
            setEventHierarchyData(eventHierarchy);
            setEventDataLoading(false);
        } catch (err) {
            if (err instanceof AbortError) return;
            setHasEventDataError(true);
            setEventDataLoading(false);
        }
    };

    const toggleAdvertiserFavorite = async (advertiser) => {
        const isFavorite = !advertiser.is_favourite;

        setAdvertisers((prevAdvertisers) => [
            ...getUpdatedList(prevAdvertisers, advertiser.id, {
                isFavoriteLoading: true,
            }),
        ]);

        try {
            await api().advertisers.favorite(advertiser.id, isFavorite);

            setAdvertisers((prevAdvertisers) => [
                ...getUpdatedList(prevAdvertisers, advertiser.id, {
                    is_favourite: isFavorite,
                    isFavoriteLoading: false,
                }),
            ]);
        } catch (err) {
            if (err instanceof AbortError) return;

            setAdvertisers((prevAdvertisers) => [
                ...getUpdatedList(prevAdvertisers, advertiser.id, {
                    isFavoriteLoading: false,
                }),
            ]);

            showToast(
                'There Was An Unexpected Error',
                <p>
                    It was not possible to{' '}
                    {isFavorite ? 'favorite' : 'unfavorite'} {advertiser.name}.
                    Please try again in a few moments.
                </p>,
                10,
                'danger',
            );
        }
    };

    const toggleTemplateFavorite = async (template) => {
        const isFavorite = !template.is_favourite;

        setTemplates((prevTemplates) => [
            ...getUpdatedList(prevTemplates, template.id, {
                isFavoriteLoading: true,
            }),
        ]);

        try {
            const method = templateMethodMap[template.type];
            await api()[method].favorite(template.id, isFavorite);

            setTemplates((prevTemplates) => [
                ...getUpdatedList(prevTemplates, template.id, {
                    is_favourite: isFavorite,
                    isFavoriteLoading: false,
                }),
            ]);
        } catch (err) {
            if (err instanceof AbortError) return;

            setTemplates((prevTemplates) => [
                ...getUpdatedList(prevTemplates, template.id, {
                    isFavoriteLoading: false,
                }),
            ]);

            showToast(
                'There Was An Unexpected Error',
                <p>
                    It was not possible to{' '}
                    {isFavorite ? 'favorite' : 'unfavorite'} {template.name}.
                    Please try again in a few moments.
                </p>,
                10,
                'danger',
            );
        }
    };

    const cancelTemplateSchedule = async (template) => {
        setCancellingSchedule(true);
        const method = templateMethodMap[template.type];

        try {
            await api()[method].update(template.id, {
                schedule: null,
            });

            setTemplates((prevTemplates) => [
                ...getUpdatedList(prevTemplates, template.id, {
                    schedule: null,
                }),
            ]);
            setCancellingSchedule(false);

            showToast(
                'Scheduling',
                <p>Scheduling stopped for {template.name}.</p>,
                10,
            );
        } catch (err) {
            if (err instanceof AbortError) return;

            setCancellingSchedule(false);

            showToast(
                'There Was An Unexpected Error',
                <p>
                    An unexpected error occurred while stopping scheduling for{' '}
                    {template.title}.
                </p>,
                10,
                'danger',
            );
        }
    };

    const handleDelete = async (reportType, entityId) => {
        await api()[templateMethodMap[reportType]].destroy(entityId);
        loadData();
    };

    useLayoutEffect(() => {
        setDocumentTitle(['Insights']);

        loadData();
        if (!advertiserIdParam) {
            loadEventData();
        }

        return () => {
            api().abortAll();
        };
    }, [advertiserIdParam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const handleNetworkRequests = async () => {
            const all = 'all';
            const {
                startDate: prevStartDate,
                selectedRange: prevSelectedRange,
            } = previouslySelectedRange;

            // if all data were loaded do not ever load again
            if (prevSelectedRange === all) return;

            // load all data once if selected and set a tracker
            if (selectedRange === all) {
                setPreviouslySelectedRange(
                    dateRangeMapTracker(selectedRange, startDate, endDate),
                );
                await loadEventData();
                return;
            }

            const { startDate: newStartDate } = dateRangeMapTracker(
                selectedRange,
                startDate,
                endDate,
            );
            // check if we have a range already loaded, if not load data in range
            if (moment(prevStartDate) > moment(newStartDate)) {
                setPreviouslySelectedRange(
                    dateRangeMapTracker(selectedRange, startDate, endDate),
                );
                await loadEventData();
            }
        };
        handleNetworkRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRange, startDate, endDate]);

    return (
        <Layout
            header={
                <>
                    <TopBar breadcrumbs={getBreadcrumbs()} />
                    <ActionBar>
                        <Button theme="outline" to="/insights/tag/">
                            Get The Tag
                        </Button>
                        <Button
                            theme="outline"
                            to="/insights/rename-data-sources/"
                        >
                            Rename Data Sources
                        </Button>
                    </ActionBar>
                </>
            }
            sidebar={<Navigation />}
        >
            {isCancellingSchedule && (
                <LoadingLayer message="Stopping Scheduling" />
            )}
            <ReportTemplateList
                selectedRange={selectedRange}
                startDate={startDate}
                endDate={endDate}
                selectedAdvertiserId={advertiserIdParam}
                advertisers={advertisers}
                templates={templates}
                eventAuditData={eventAuditData}
                eventHierarchyData={eventHierarchyData}
                isEventDataLoading={isEventDataLoading}
                hasEventDataError={hasEventDataError}
                isLoading={isLoading}
                hasError={hasError}
                onRetry={loadData}
                onCancelTemplateSchedule={cancelTemplateSchedule}
                onToggleTemplateFavorite={toggleTemplateFavorite}
                onToggleAdvertiserFavorite={toggleAdvertiserFavorite}
                onChangeRange={setSelectedRange}
                onChangeStart={setStartDate}
                onChangeEnd={setEndDate}
                onDelete={handleDelete}
            />
        </Layout>
    );
}

export default ReportTemplateListPage;
