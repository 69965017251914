import React from 'react';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import EventsSourceTab from './events-source-tab';
import FileSourceTab from './file-source-tab';
import SourceSwitch from './source-switch';

const DataSourceStep = ({
    template,
    errors,
    campaignLabel,
    lineItemLabel,
    eventHierarchy,
    nextCampaignId,
    onAddCampaign,
    onChangeCampaign,
    onRemoveCampaign,
    onSetNextSourceId,
    onChangeSource,
    onChangeType,
    onChangeFile,
    onFileUploadError,
}) => (
    <>
        {template.type === entityTypes.VIDEO_REPORTS &&
        template.subtype !== videoReportSubtypes.DEFAULT ? (
            <SourceSwitch template={template} onChangeType={onChangeType} />
        ) : null}

        {template.subtype === videoReportSubtypes.YOUTUBE_GADS_FILE ? (
            <FileSourceTab
                errors={errors}
                template={template}
                onChangeFile={onChangeFile}
                onFileUploadError={onFileUploadError}
            />
        ) : (
            <EventsSourceTab
                template={template}
                errors={errors}
                campaignLabel={campaignLabel}
                lineItemLabel={lineItemLabel}
                eventHierarchy={eventHierarchy}
                nextCampaignId={nextCampaignId}
                onAddCampaign={onAddCampaign}
                onChangeCampaign={onChangeCampaign}
                onRemoveCampaign={onRemoveCampaign}
                onSetNextSourceId={onSetNextSourceId}
                onChangeSource={onChangeSource}
            />
        )}
    </>
);

export default DataSourceStep;
