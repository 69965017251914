import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import InfoButton from 'js/components/button/info-button';
import Collapse from 'js/components/collapse/collapse';
import Label from 'js/components/label/label';
import styles from './checkbox.module.scss';

const cx = classNames.bind(styles);

let instanceId = 0;

function Checkbox({
    options,
    radio: isRadio,
    selectedValues,
    label,
    hint,
    info,
    isReadOnly,
    hideIcon,
    hasError,
    errorMessage,
    onChange,
}) {
    const [thisInstanceId, setThisInstanceId] = useState('');

    useEffect(() => {
        instanceId += 1;
        setThisInstanceId(`checkbox-${instanceId}`);
    }, []);

    // An option with an empty string value is treated as a 'Select All'
    const isSelectAllOption = (value) => value === '';

    const isSelected = (value) => {
        if (isRadio) {
            return selectedValues === value;
        }

        if (isSelectAllOption(value)) {
            return !selectedValues.length;
        }

        return selectedValues.includes(value);
    };

    const toggleValue = (value, checked) => {
        if (isRadio) {
            if (selectedValues !== value) {
                onChange(value);
            }
        } else if (isSelectAllOption(value)) {
            onChange([]);
        } else if (checked) {
            onChange([...selectedValues, value]);
        } else {
            onChange(selectedValues.filter((item) => item !== value));
        }
    };

    const getOptionIcon = (option) => {
        const hasSquareIcon = !(isRadio || isSelectAllOption(option.value));

        if (isSelected(option.value)) {
            return hasSquareIcon ? faCheckSquare : faCheckCircle;
        }

        return hasSquareIcon ? faSquare : faCircle;
    };

    return (
        <Label
            errorMessage={errorMessage}
            hasError={hasError}
            hint={hint}
            info={info}
            label={label}
        >
            <div>
                {options.map((option) => {
                    const optionClasses = cx({
                        option: true,
                        'select-all': isSelectAllOption(option.value),
                        'read-only': isReadOnly,
                    });

                    return (
                        <div
                            key={`${thisInstanceId}-${option.value}`}
                            className={optionClasses}
                        >
                            <input
                                id={`${thisInstanceId}-${option.value}`}
                                type="checkbox"
                                checked={isSelected(option.value)}
                                tabIndex={isReadOnly ? '-1' : null}
                                className={styles.checkbox}
                                value={option.value}
                                onChange={({ target }) =>
                                    toggleValue(option.value, target.checked)
                                }
                            />

                            <label
                                className={styles.label}
                                htmlFor={`${thisInstanceId}-${option.value}`}
                            >
                                <div
                                    className={cx({
                                        'icon-container': true,
                                        hidden: hideIcon,
                                    })}
                                >
                                    <FontAwesomeIcon
                                        icon={getOptionIcon(option)}
                                    />
                                </div>

                                <div className={styles['label-content']}>
                                    {option.label}{' '}
                                    {option.hint ? (
                                        <div
                                            className={styles['hint-container']}
                                        >
                                            <InfoButton>
                                                {option.hint}
                                            </InfoButton>
                                        </div>
                                    ) : null}
                                </div>
                            </label>

                            {option.content && (
                                <Collapse isOpen={isSelected(option.value)}>
                                    {option.content}
                                </Collapse>
                            )}
                        </div>
                    );
                })}
            </div>
        </Label>
    );
}

Checkbox.defaultProps = {
    radio: false,
    selectedValues: [],
    label: undefined,
    hint: undefined,
    info: undefined,
    isReadOnly: false,
    hideIcon: false,
    hasError: false,
    errorMessage: undefined,
};

Checkbox.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]).isRequired,
            hint: PropTypes.node,
            content: PropTypes.node,
        }),
    ).isRequired,
    radio: PropTypes.bool,
    selectedValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        ),
    ]),
    label: PropTypes.node,
    hint: PropTypes.node,
    info: PropTypes.node,
    isReadOnly: PropTypes.bool,
    hideIcon: PropTypes.bool,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.node,
    onChange: PropTypes.func.isRequired,
};

export default Checkbox;
