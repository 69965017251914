const languageTypes = {
    ANY: '',
    EN: 'en',
    FR: 'fr',
    IT: 'it',
    ES: 'es',
    DE: 'de',
    PT: 'pt',
    SV: 'sv',
};

export default languageTypes;
