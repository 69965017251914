import React, { useContext, useEffect, useState } from 'react';
import { api, setDocumentTitle } from 'js/utils';
import { withCampaignDetail } from 'js/hocs';
import { CampaignDetailContext } from 'js/contexts';
import Crumb from 'js/components/breadcrumbs/crumb';
import { useParams } from 'react-router-dom';
import CampaignDetail from './campaign-detail';

function CampaignDetailPage() {
    const {
        campaign: { saved: savedCampaign, loading: campaignLoading },
        errors: { load: campaignLoadErrors },
    } = useContext(CampaignDetailContext);

    const { campaignId } = useParams();

    const [advertisers, setAdvertisers] = useState([]);
    const [advertisersLoading, setAdvertisersLoading] = useState(true);
    const [advertisersLoadErrors, setAdvertisersLoadErrors] = useState({});

    const loadAdvertisers = async () => {
        setAdvertisersLoading(true);
        setAdvertisersLoadErrors({});
        try {
            const { results: advertisersData } = await api().advertisers.list({
                field: ['id', 'name'],
                ordering: 'name',
            });
            setAdvertisers(advertisersData);
        } catch (errors) {
            setAdvertisersLoadErrors(errors);
        }
        setAdvertisersLoading(false);
    };

    const appendAdvertiser = (advertiser) => {
        setAdvertisers((list) => {
            list.push(advertiser);
            list.sort((a, b) => a.name.localeCompare(b.name));
            return list;
        });
    };

    const getBreadcrumbs = () => {
        const breadcrumbs = [<Crumb key="home" to="/" label="4D" />];

        if (
            Object.keys(advertisersLoadErrors).length > 0 ||
            Object.keys(campaignLoadErrors).length > 0
        ) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (advertisersLoading || campaignLoading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (
            savedCampaign.advertiser &&
            savedCampaign.advertiser_name &&
            savedCampaign.name
        ) {
            breadcrumbs.push(
                <Crumb
                    key="advertisers"
                    to="/context/"
                    label="Advertisers"
                    isCollapsible
                />,
                <Crumb
                    key="advertiser"
                    to={`/context/${savedCampaign.advertiser}/`}
                    label={savedCampaign.advertiser_name}
                    isCollapsible
                />,
                <Crumb
                    key="campaign"
                    to={`/context/${savedCampaign.advertiser}/${savedCampaign.id}/`}
                    label={savedCampaign.name}
                    isCollapsible
                />,
                <Crumb key="edit" label="Edit" />,
            );
        } else {
            breadcrumbs.push(
                <Crumb
                    key="campaigns"
                    label="Campaigns"
                    isInactive
                    isCollapsible
                />,
                <Crumb key="create" label="Create New" />,
            );
        }

        return breadcrumbs;
    };

    useEffect(() => {
        loadAdvertisers();
    }, [campaignId]);

    useEffect(() => {
        // TODO: make coherent with breadcrumb
        setDocumentTitle([savedCampaign.name || 'New', 'Campaigns']);
    }, [savedCampaign.name]);

    return (
        <CampaignDetail
            breadcrumbs={getBreadcrumbs()}
            loading={advertisersLoading || campaignLoading}
            loadErrors={{ ...advertisersLoadErrors, ...campaignLoadErrors }}
            advertisers={advertisers}
            onAdvertiserCreate={appendAdvertiser}
        />
    );
}

export default withCampaignDetail(CampaignDetailPage);
