import ruleTypes from 'js/enums/rule-types.enum';
import { dedupe } from './utils';

let lastRuleKey = 0;

class Rule {
    constructor({
        key = ++lastRuleKey, // eslint-disable-line no-plusplus
        aggregation = ruleTypes.INCLUDED,
        keywords = [],
        topics = [],
        logos = [],
    } = {}) {
        if (!Object.values(ruleTypes).includes(aggregation)) {
            throw new Error(`Invalid rule aggregation: ${aggregation}`);
        }

        if (key > lastRuleKey) lastRuleKey = key;

        this.key = key;
        this.aggregation = aggregation;
        this.keywords = dedupe(Rule.cleanItems('keywords', keywords));
        this.topics = dedupe(Rule.cleanItems('topics', topics));
        this.logos = dedupe(Rule.cleanItems('logos', logos));
    }

    static cleanItems(field, items) {
        return items
            .map((item) =>
                (field === 'keywords' ? item.toLowerCase() : item).trim(),
            )
            .filter((item) => item);
    }
}

export default Rule;
