import React from 'react';
import Text from 'js/components/text/text';
import Video from 'js/components/video/video';
import Box from 'js/components/box/box';
import orderOverviewImg from './images/dv360-order-overview.png';
import sdfUploadSuccessImg from './images/dv360-upload-success.png';
import troubleshootingSvg from './images/tile-troubleshooting-bg.svg';
import uploadSvg from './images/tile-upload-bg.svg';
import downloadSvg from './images/tile-download-bg.svg';
import sdfUploadVid from './images/dv360-sdf-upload.mp4';
import styles from './task-detail.module.scss';

const uploadGuideSteps = [
    {
        key: 'step1',
        title: 'Your SDF Download is Ready',
        description:
            'Understand what happens now, and understand where to go using DV360.',
        image: downloadSvg,
        pageCopy: (
            <div>
                <Text size="huge">Your SDF Download is Ready</Text>
                <Box margin={['base', 0, 'large', 0]}>
                    <Text>
                        Google Display and Video 360 (DV360) use Structured Data
                        File (SDF) uploads to bulk-modify targeting. 4D provides
                        data using the Google SDF format so it&apos;s trivial to
                        upload your new contextual YouTube Targeting
                        configuration. As part of this process, 4D downloads the
                        existing configuration from DV360, which takes some
                        time. Once 4D has finished creating the SDF data, the
                        file will be automatically downloaded by your browser.
                        The file will be named
                        &quot;4D-SDF-AdGroups-&#123;context_id&#125;-&#123;datetime&#125;.csv&quot;,
                        substituting with your Context ID and the current date
                        and time where appropriate.
                    </Text>
                </Box>
                <Box margin={['large', 0]}>
                    <Text>
                        Once your 4D SDF download is ready we will present you
                        with a redirect button. After clicking it you will be
                        automatically redirected to an Insertion Order Overview
                        page in DV360. This page will represent the DV360
                        Insertion order associated to your 4D Context. On this
                        page, you will see a list of DV360 YouTube & partners
                        video line items. See the example below.
                    </Text>
                </Box>
                <Box margin={['large', 0]}>
                    <img
                        src={orderOverviewImg}
                        style={{ width: '100%' }}
                        alt="DV360 Order Overview Page"
                    />
                </Box>
                <Box margin={['large', 0, 'base', 0]}>
                    <Text>The key features of the page are:</Text>
                </Box>
                <Box margin={[0, 0, 'large', 0]}>
                    <ol className={styles['override-ol']}>
                        <li>The Advertiser and Campaign in the page header.</li>
                        <li>The Insertion Order Details.</li>
                        <li>
                            The List of Line Items associated with the Insertion
                            Order.
                        </li>
                        <li>A set of &quot;Bulk Operation&quot; Buttons.</li>
                    </ol>
                </Box>
                <Box margin={['large', 0]}>
                    <Text>
                        <em>Only</em> the Ad Groups associated with your 4D
                        Context. You should verify that the Advertiser and
                        Campaign, Insertion Order and List of Line items are
                        correct, and the Ad Groups associated to the 4D Context
                        are contained within this entity hierarchy. If the
                        entity hierarchy is incorrect, and you attempt to upload
                        SDF data containing AdGroups that are not controlled by
                        the entities described, the upload process will fail.
                    </Text>
                </Box>
            </div>
        ),
    },
    {
        key: 'step2',
        title: 'Uploading the SDF Data',
        description: 'Upload the SDF data to the DV360 website.',
        image: uploadSvg,
        pageCopy: (
            <div>
                <Text size="huge">Uploading the SDF Data</Text>
                <Box margin={['base', 0, 'large', 0]}>
                    <Text>
                        To begin the SDF data upload into DV360, make sure that
                        a &quot;4D-SDF-AdGroups-context_id-datetime.csv&quot;
                        file has been downloaded and contains the correct 4D
                        Context ID and date/time, and that you are viewing the
                        Insertion Order Overview page in DV360. 4D should
                        automatically download the file and redirect your
                        browser to this location.
                    </Text>
                </Box>
                <Box margin={['large', 0]}>
                    <Text>
                        To Upload the SDF data, request an SDF Upload from the
                        menu above the list of YouTube & partners video line
                        items on the right side of the browser window. A modal
                        will open and request that you upload the SDF data
                        either by dragging the file onto the modal, or by using
                        your computer file system.
                    </Text>
                </Box>
                <Box margin={['large', 0]}>
                    <Video src={sdfUploadVid} />
                </Box>
                <Box margin={['large', 0]}>
                    <Text>
                        Once the SDF data has been added to the modal, click
                        &quot;Upload&quot; to finalise the request to DV360 and
                        begin the upload process. DV360 will verify that the
                        file contains valid data. Once the file has been
                        successfully uploaded, the model will show a status icon
                        indicating the success of the upload. DV360 will also
                        send you an automated email detailing the completed
                        upload process.
                    </Text>
                </Box>
                <Box margin={['large', 0]}>
                    <img
                        src={sdfUploadSuccessImg}
                        style={{ width: '100%' }}
                        alt="DV360 Upload Success Notification"
                    />
                </Box>
            </div>
        ),
    },
    {
        key: 'step3',
        title: 'Troubleshooting',
        description: 'Common issues and FAQs.',
        image: troubleshootingSvg,
        pageCopy: (
            <div>
                <Text size="huge">Troubleshooting</Text>
                <Box margin={['base', 0, 'large', 0]}>
                    <Text>
                        DV360 provides comprehensive documentation for it&apos;s
                        SDF upload and download process. In the even that the
                        DV360 documentation is unclear, 4D can help.
                    </Text>
                </Box>
                <Box margin={['large', 0]}>
                    <Text weight="bold" size="larger">
                        Useful Links:
                    </Text>
                    <Box margin={['base', 0, 'large', 0]}>
                        <li>
                            <a href="https://support.google.com/displayvideo/answer/6301070">
                                Google Display & Video 360 SDF documentation and
                                examples
                            </a>
                        </li>
                        <li>
                            <a href="https://developers.google.com/display-video/api/structured-data-file/format">
                                Google Display & Video 360 SDF Overview
                            </a>
                        </li>
                        <li>
                            <a href="https://4d-silverbulletcloud.atlassian.net/wiki/spaces/4DKB/overview">
                                4D Knowledge Base
                            </a>
                        </li>
                    </Box>

                    <Text weight="bold" size="larger">
                        Frequently Asked Questions:
                    </Text>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                I didn&apos;t get taken to DV360
                            </Text>
                        </Box>
                        <Text>
                            DV360 requires authentication. If you are not logged
                            into DV360 then 4D will be unable to redirect you to
                            the correct location for your SDF upload.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                I can&apos;t see the adgroups for my context on
                                DV360
                            </Text>
                        </Box>
                        <Text>
                            AdGroups aren&apos;t visible from the Insertion
                            Order Overview page. Each of your DV360 Ad Groups is
                            only visible when inspecting the Line Item Overview
                            page. You can view the Line Item Overview page by
                            clicking on a Line Item in the YouTube & partners
                            video line items list from the Insertion Order
                            Overview page in DV360.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                I can&apos;t see my campaign/insertion
                                order/line items
                            </Text>
                        </Box>
                        <Text>
                            You might not have permissions to view or modify
                            some entities in DV360. Contact your DV360 Account
                            representative or Admin to review your access
                            permissions.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                My 4D Context is used to optimize the targeting
                                of multiple YouTube Ad Groups. Why do I only get
                                one file?
                            </Text>
                        </Box>
                        <Text>
                            4D Bundles the configuration of all of your DV360 Ad
                            Groups into a single file. The File can be opened as
                            an Excel file, and each row represents 1 Ad Group.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                Why does my download take so long?
                            </Text>
                        </Box>
                        <Text>
                            4D downloads the current configuration from DV360
                            first to make sure your targeting is as up-to-date
                            as possible. To accommodate this extra work, the
                            download takes longer than other 4D processes.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                How does 4D know which Ad Groups to update?
                            </Text>
                        </Box>
                        <Text>
                            When creating a 4D YouTube Context, users must
                            assign the Context to a 4D Channel. The 4D Channel
                            stores information pertaining to which DV360 Ad
                            Groups should be updated. Only Ad Groups configured
                            in the 4D Channel will be updated.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                How does 4D access my DV360 account?
                            </Text>
                        </Box>
                        <Text>
                            4D Uses Service Accounts to create a programmatic
                            connection to a DV360 account. Service accounts are
                            represented by an email and carry no password. This
                            makes them exceptionally secure. Since Service
                            Accounts carry no password, no users can login and
                            data can only be accessed programmatically. Read
                            more about service accounts{' '}
                            <a href="https://developers.google.com/display-video/api/guides/how-tos/service-account">
                                here
                            </a>
                            .
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">My upload failed</Text>
                        </Box>
                        <Text>
                            Uploads can fail for many reasons. Most commonly,
                            opening the SDF data in Excel and resaving it will
                            damage the structure of the file. If the problem
                            persists, contact your 4D Account Manager.
                        </Text>
                    </Box>
                    <Box margin={['base', 0]}>
                        <Box margin={[0, 0, 'small', 0]}>
                            <Text weight="bold">
                                How can I see prior 4D modifications to my Ad
                                Group targeting?
                            </Text>
                        </Box>
                        <Text>
                            DV360 keeps a record of all of the SDF Operations
                            that take place for a DV360 account. You can find
                            the operations history by clicking the Bulk Status
                            button in the header of DV360. From here you can see
                            all bulk upload activity for your DV360 account.
                        </Text>
                    </Box>
                </Box>
            </div>
        ),
    },
];

export default uploadGuideSteps;
