import React, { useEffect, useMemo, useState } from 'react';
import { dedupeBy } from 'js/utils';
import SectionTitle from 'js/components/section-title/section-title';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import Box from 'js/components/box/box';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Alert from 'js/components/alert/alert';
import Select from 'js/components/select/select';
import moment from 'moment/moment';
import { displayMetrics, videoMetrics } from './metrics';
import StatsPanel from './stats-panel';
import ChartPanel from './chart-panel';
import DateSelector from '../date-selector/date-selector';
import styles from './tag-performance-dashboard.module.scss';

const dataTypes = {
    DISPLAY: 'display',
    VIDEO: 'video',
};
const dataTypeOptions = [
    { label: 'Display', value: dataTypes.DISPLAY },
    { label: 'Video', value: dataTypes.VIDEO },
];

const TagPerformanceDashboard = ({
    isLoading,
    hasError,
    eventAuditData,
    eventHierarchyData,
    selectedRange,
    startDate,
    endDate,
    onChangeRange,
    onChangeStart,
    onChangeEnd,
}) => {
    const [panelData, setPanelData] = useState([]);
    const [selectedDataType, setSelectedDataType] = useState(dataTypes.DISPLAY);
    const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
    const [selectedLineItemIds, setSelectedLineItemIds] = useState([]);
    const [campaignSearchTerm, setCampaignSearchTerm] = useState('');
    const [lineItemSearchTerm, setLineItemSearchTerm] = useState('');

    const metrics =
        selectedDataType === dataTypes.DISPLAY ? displayMetrics : videoMetrics;

    const campaigns = useMemo(
        () =>
            dedupeBy(eventHierarchyData, ['campaign']).map(
                ({ campaign: id, campaign_name: name }) => ({
                    id,
                    name,
                }),
            ),
        [eventHierarchyData],
    );

    const filteredCampaigns = campaigns.filter(({ id, name }) =>
        `${name || ''} ${id}`
            .toLowerCase()
            .includes(campaignSearchTerm.toLowerCase()),
    );

    const campaignOptions = filteredCampaigns.map(({ id, name }) => ({
        value: id,
        label: name ? `${name} (${id})` : id,
    }));
    if (!campaignSearchTerm) {
        campaignOptions.unshift({ value: '', label: 'All' });
    }

    const lineItems = useMemo(
        () =>
            dedupeBy(eventHierarchyData, ['campaign', 'line_item']).map(
                (item) => ({
                    id: item.line_item,
                    name: item.line_item_name,
                    campaignId: item.campaign,
                }),
            ),
        [eventHierarchyData],
    );

    const filteredLineItems = lineItems.filter(
        ({ id, name, campaignId }) =>
            (!selectedCampaignIds.length ||
                selectedCampaignIds.includes(campaignId)) &&
            `${name || ''} ${id}`
                .toLowerCase()
                .includes(lineItemSearchTerm.toLowerCase()),
    );

    const lineItemOptions = filteredLineItems.map(({ id, name }) => ({
        value: id,
        label: name ? `${name} (${id})` : id,
    }));
    if (!lineItemSearchTerm) {
        lineItemOptions.unshift({ value: '', label: 'All' });
    }

    const filterData = (data) => {
        let filtered = data.filter(
            (item) => item.is_video === (selectedDataType === dataTypes.VIDEO),
        );
        if (selectedCampaignIds.length) {
            filtered = filtered.filter((item) =>
                selectedCampaignIds.includes(item.campaign),
            );
        }
        if (selectedLineItemIds.length) {
            filtered = filtered.filter((item) =>
                selectedLineItemIds.includes(item.line_item),
            );
        }
        if (selectedRange === 'custom') {
            const start = moment(startDate);
            const end = moment(endDate);
            filtered = filtered.filter((item) => {
                const activityDate = moment(item.activity_date);
                return (
                    start.isSameOrBefore(activityDate) &&
                    end.isSameOrAfter(activityDate)
                );
            });
        } else if (selectedRange !== 'all') {
            const range = parseInt(selectedRange, 10);
            const xDaysAgo = moment().subtract(range, 'd');
            filtered = filtered.filter((item) => {
                const activityDate = moment(item.activity_date);
                return activityDate.isSameOrAfter(xDaysAgo);
            });
        }

        return filtered;
    };

    const aggregateData = (data) =>
        data.reduce((aggr, record) => {
            const date = record.activity_date;
            let aggrRecord = aggr.find((item) => item.activity_date === date);
            if (!aggrRecord) {
                aggrRecord = { activity_date: date };
                aggr.push(aggrRecord);
            }
            Object.entries(record).forEach(([key, value]) => {
                if (typeof value === 'number') {
                    aggrRecord[key] = (aggrRecord[key] || 0) + value;
                }
            });
            return aggr;
        }, []);

    useEffect(() => {
        setPanelData(aggregateData(filterData(eventAuditData)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        eventAuditData,
        selectedDataType,
        selectedCampaignIds,
        selectedLineItemIds,
        selectedRange,
        startDate,
        endDate,
    ]);

    useEffect(() => {
        setSelectedLineItemIds((prevIds) =>
            lineItems
                .filter(
                    ({ id, campaignId }) =>
                        prevIds.includes(id) &&
                        (!selectedCampaignIds.length ||
                            selectedCampaignIds.includes(campaignId)),
                )
                .map(({ id }) => id),
        );
    }, [selectedCampaignIds, lineItems]);

    return (
        <>
            <SectionTitle
                hasBar
                extension={[
                    <DateSelector
                        range={selectedRange}
                        onChangeRange={onChangeRange}
                        start={startDate}
                        onChangeStart={onChangeStart}
                        end={endDate}
                        onChangeEnd={onChangeEnd}
                    />,

                    <Select
                        prefix="Campaigns"
                        theme="filter"
                        width={450}
                        searchPlaceholder="Search Campaigns by name or ID"
                        options={campaignOptions}
                        selectedValues={selectedCampaignIds}
                        multi
                        emptyMessage={
                            <p>
                                There are no campaigns that match your search.
                            </p>
                        }
                        onChange={setSelectedCampaignIds}
                        onSearch={setCampaignSearchTerm}
                    >
                        {selectedCampaignIds.length
                            ? `${selectedCampaignIds.length} Selected`
                            : 'All'}
                    </Select>,

                    <Select
                        prefix="Line Items"
                        theme="filter"
                        width="fixed"
                        searchPlaceholder="Search Line Items by name or ID"
                        options={lineItemOptions}
                        selectedValues={selectedLineItemIds}
                        multi
                        emptyMessage={
                            <p>
                                There are no line items that match your search.
                            </p>
                        }
                        onChange={setSelectedLineItemIds}
                        onSearch={setLineItemSearchTerm}
                    >
                        {selectedLineItemIds.length
                            ? `${selectedLineItemIds.length} Selected`
                            : 'All'}
                    </Select>,
                    <Select
                        prefix="Type"
                        theme="filter"
                        options={dataTypeOptions}
                        selectedValues={selectedDataType}
                        onChange={setSelectedDataType}
                    />,
                ]}
            >
                Performance Dashboard
            </SectionTitle>

            <Box margin={['large', 0, 0, 0]}>
                {isLoading && (
                    <div className={styles['loading-wrapper']}>
                        <LoadingLayer
                            position="absolute"
                            message="Loading Tag Performance Data"
                        />
                    </div>
                )}
                {!isLoading &&
                    (hasError ? (
                        <Alert theme="danger" title="An Error Occured">
                            We were unable to load the Perfomance Dashboard.
                            Please try again later.
                        </Alert>
                    ) : (
                        <Panel theme="secondary">
                            <Box padding="base">
                                <Text size="larger" weight="bold">
                                    Summary
                                </Text>
                                {panelData.length ? (
                                    <>
                                        <StatsPanel
                                            metrics={metrics}
                                            auditData={panelData}
                                        />
                                        <ChartPanel
                                            metrics={metrics}
                                            auditData={panelData}
                                        />
                                    </>
                                ) : (
                                    <Box padding={['base', 0]}>
                                        <Alert theme="empty">
                                            No data available
                                        </Alert>
                                    </Box>
                                )}
                            </Box>
                        </Panel>
                    ))}
            </Box>
        </>
    );
};

export default TagPerformanceDashboard;
