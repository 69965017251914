import PropTypes from 'prop-types';

function TabContent({ children }) {
    return children;
}

TabContent.defaultProps = {};

TabContent.propTypes = {
    tabId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default TabContent;
