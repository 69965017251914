import React from 'react';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import SwitchButton from 'js/components/button/switch-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import InspectDrawer, {
    InspectDrawerSummary,
    MatchedPages,
} from './inspect-drawer/inspect-drawer';
import InspectDrawerBg from './images/inspect-drawer-bg.svg';

const Summary = ({
    report,
    context,
    topic,
    onChangeContextRules,
    showBlockButton,
}) => {
    const {
        topic: topicId,
        topic_name: topicName,
        topic_group_name: topicGroup,
        impressions,
        clicks,
    } = topic;

    const ruleSet = new RuleSet(context.rules);

    const allTargetedTopics = context.rules
        .filter(({ aggregation }) => aggregation === ruleTypes.INCLUDED)
        .map(({ topics }) => topics)
        .flat();

    const allBlockedTopics = context.rules
        .find(({ aggregation }) => aggregation === ruleTypes.EXCLUDED)
        .topics.flat();

    const toggleTarget = (addTopics, removeTopics) => {
        const firstRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
        );

        ruleSet.changeRule(firstRule.key, { addTopics });
        ruleSet.changeAllRules({ removeTopics });

        onChangeContextRules(ruleSet.rules);
    };

    const toggleBlock = (addTopics, removeTopics) => {
        const notRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.EXCLUDED,
        );

        ruleSet.changeAllRules({ removeTopics });
        ruleSet.changeRule(notRule.key, { addTopics });

        onChangeContextRules(ruleSet.rules);
    };

    const isTargeted = allTargetedTopics.includes(topic.topic);
    const isBlocked = allBlockedTopics.includes(topicId);

    const buttons = [
        <Col span="auto">
            <SwitchButton
                size="small"
                active={isTargeted}
                onChange={() =>
                    toggleTarget(
                        isTargeted ? [] : [topicId],
                        isTargeted ? [topicId] : [],
                    )
                }
            >
                Target
            </SwitchButton>
        </Col>,
    ];
    if (showBlockButton) {
        buttons.push(
            <Col span="auto">
                <SwitchButton
                    size="small"
                    active={isBlocked}
                    onChange={() =>
                        toggleBlock(
                            isBlocked ? [] : [topicId],
                            isBlocked ? [topicId] : [],
                        )
                    }
                >
                    Block
                </SwitchButton>
            </Col>,
        );
    }

    return (
        <InspectDrawerSummary
            report={report}
            impressions={impressions}
            clicks={clicks}
            backgroundImg={InspectDrawerBg}
            header={
                <Row>
                    <Col>
                        <Text weight="bolder" color={['gray', 'dark']}>
                            Topic
                        </Text>
                        <Text size="huge">
                            {topicGroup} - {topicName}
                        </Text>
                    </Col>
                    {buttons}
                </Row>
            }
        />
    );
};

const TopicInspectDrawer = ({
    report,
    context,
    topic,
    matchedPages,
    isLoading,
    hasError,
    onChangeContextRules,
    onClose,
    onReload,
    showBlockButton,
}) => (
    <InspectDrawer
        summary={
            <Summary
                report={report}
                context={context}
                topic={topic}
                onChangeContextRules={onChangeContextRules}
                showBlockButton={showBlockButton}
            />
        }
        content={
            <>
                <Box margin={['large', 0]}>
                    <Text size="large" color={['gray', 'dark']}>
                        The following pages are examples of content that the
                        Topic ‘{topic.topic_name}’ matches.
                    </Text>
                </Box>

                <MatchedPages
                    pages={matchedPages}
                    isLoading={isLoading}
                    hasError={hasError}
                    onReload={onReload}
                />
            </>
        }
        onClose={onClose}
    />
);

export default TopicInspectDrawer;
