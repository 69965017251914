import React from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import Col from 'js/components/grid/column';
import Text from 'js/components/text/text';
import styles from './statistic.module.scss';

const Statistic = ({ name, info, comparison, children }) => (
    <Col span={12} spanSm={6} spanLg="equal">
        {name && (
            <Box margin={[0, 'small', 0, 0]}>
                <Text inline color={['gray', 'dark']} size="large">
                    {name}{' '}
                    {info && (
                        <Box display="inline-block">
                            <InfoButton>{info}</InfoButton>
                        </Box>
                    )}
                </Text>
            </Box>
        )}

        <Box margin={name ? ['smaller', 0, 0] : 'none'}>
            <Text color={['gray', 'darkest']} size="large" weight="bolder">
                <div className={styles['word-wrap-container']}>{children}</div>
            </Text>
        </Box>

        {comparison && (
            <Box margin={name || children ? ['smaller', 0, 0] : 'none'}>
                <Text color={['gray', 'dark']}>{comparison}</Text>
            </Box>
        )}
    </Col>
);

Statistic.defaultProps = {
    name: undefined,
    info: undefined,
    comparison: undefined,
    children: undefined,
};

Statistic.propTypes = {
    name: PropTypes.node,
    info: PropTypes.node,
    comparison: PropTypes.node,
    children: PropTypes.node,
};

export default Statistic;
