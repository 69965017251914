import React, { useContext } from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import Number from 'js/components/number/number';
import EntityItem from './entity-item';
import entities from '../../../enums/matched-videos-entities.enum';
import styles from '../matched-items-section.module.scss';
import ContextDetailContext from '../../../contexts/context-detail.context';

const VideoTitle = ({ title, url }) => (
    <h3 className={styles['content-title']}>
        <a href={url} target="_blank" rel="noopener noreferrer">
            {title || url.split('?')[0].split('#')[0]}{' '}
        </a>
    </h3>
);

const VideoImage = ({ image, url }) => (
    <div
        className={styles['content-image']}
        style={{
            backgroundImage: `url(${image})`,
        }}
    >
        <a href={url} target="_blank" rel="noopener noreferrer">
            <div>
                <img src={image} alt="" />
            </div>
        </a>
    </div>
);

export default function MatchedVideoItem({
    matchedVideo: {
        url,
        title,
        thumbnail,
        channel,
        logos: logoIds,
        channel_follower_count: channelFollowerCount,
        channel_thumbnail: channelThumbnail,
        channel_id: channelId,
        topics: topicIds,
    },
}) {
    const {
        context: {
            current: { rules },
            setRules,
        },
        topics: { groups: topicGroups, list: topicList },
        logoGroups,
        matchedVideos: { setNeedsReload: setMatchedVideosNeedsReload },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);

    const topics = topicList.filter(
        (topic) => topicIds.includes(topic.id) && !topic.is_brand_safety,
    );

    const bsTopics = topicList.filter(
        (topic) => topicIds.includes(topic.id) && topic.is_brand_safety,
    );

    const logos = logoGroups
        .map((group) => group.logos)
        .flat()
        .filter((brand) => logoIds.includes(brand.id));

    const matchedItemListingStyle = styles['matched-item-listing'];

    return (
        <div className={styles['matched-item']}>
            <Row>
                <Col>
                    <Box margin={[0, 0, 'small']}>
                        <VideoTitle title={title} url={url} />
                    </Box>
                    <Box margin={[0, 0, 'base']}>
                        <Row alignItems="center">
                            {channelThumbnail && (
                                <Col span="auto">
                                    <img
                                        className={styles['channel-thumbnail']}
                                        src={channelThumbnail}
                                        alt={`Thumbnail for ${channel} channel`}
                                    />
                                </Col>
                            )}
                            <Col span="auto">
                                <div className={styles['channel-link']}>
                                    <a
                                        href={`https://www.youtube.com/channel/${channelId}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {channel}
                                    </a>
                                </div>
                                <Text>
                                    <Number
                                        value={channelFollowerCount}
                                        abbreviationFormats={[
                                            {
                                                target: 1e6,
                                                symbol: 'm',
                                                maxFractionDigits: 2,
                                            },
                                            {
                                                target: 1e4,
                                                appliedValue: 1e3,
                                                symbol: 'k',
                                                maxFractionDigits: 0,
                                            },
                                            {
                                                target: 1e3,
                                                appliedValue: 1e3,
                                                symbol: 'k',
                                                maxFractionDigits: 2,
                                            },
                                            {
                                                target: 1,
                                                appliedValue: 1,
                                                symbol: '',
                                                maxFractionDigits: 0,
                                            },
                                        ]}
                                    />{' '}
                                    Subscribers
                                </Text>
                            </Col>
                        </Row>
                    </Box>
                </Col>

                {thumbnail && (
                    <Col span="auto">
                        <VideoImage image={thumbnail} url={url} />
                    </Col>
                )}
            </Row>

            {topics.length > 0 && (
                <>
                    <Box margin={['base', 0, 'small']}>
                        <Text color={['gray', 'dark']}>Topics</Text>
                    </Box>

                    <div className={matchedItemListingStyle}>
                        {topics.map((topic) => (
                            <EntityItem
                                key={topic.id}
                                entityType={entities.TOPICS}
                                entity={topic}
                                entityGroups={topicGroups}
                                isReadOnly={isReadOnly}
                                rules={rules}
                                onChangeRules={(newRules) => {
                                    setRules(newRules);
                                    setMatchedVideosNeedsReload(true);
                                }}
                            />
                        ))}
                    </div>
                </>
            )}

            {bsTopics.length > 0 && (
                <>
                    <Box margin={['base', 0, 'small']}>
                        <Text color={['gray', 'dark']}>
                            Brand Safety Topics
                        </Text>
                    </Box>

                    <div className={matchedItemListingStyle}>
                        {bsTopics.map((topic) => (
                            <EntityItem
                                key={topic.id}
                                entityType={entities.TOPICS}
                                entity={topic}
                                entityGroups={topicGroups}
                                isReadOnly={isReadOnly}
                                rules={rules}
                                onChangeRules={(newRules) => {
                                    setRules(newRules);
                                    setMatchedVideosNeedsReload(true);
                                }}
                            />
                        ))}
                    </div>
                </>
            )}

            {logos.length > 0 && (
                <>
                    <Box margin={['base', 0, 'small']}>
                        <Text color={['gray', 'dark']}>Brands</Text>
                    </Box>

                    <div className={matchedItemListingStyle}>
                        {logos.map((logo) => (
                            <EntityItem
                                key={logo.id}
                                entityType={entities.LOGOS}
                                entity={logo}
                                entityGroups={logoGroups}
                                isReadOnly={isReadOnly}
                                rules={rules}
                                onChangeRules={(newRules) => {
                                    setRules(newRules);
                                    setMatchedVideosNeedsReload(true);
                                }}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
