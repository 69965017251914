import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import SVGImage from 'js/components/svg-image/svg-image';
import styles from './emblem.module.scss';

const cx = classNames.bind(styles);

const Emblem = ({ name, logo, background, color, radius }) => (
    <div
        className={classNames(
            cx({
                emblem: true,
                [`color--${color[0]}-${color[1]}`]: color,
                [`background--${background[0]}-${background[1]}`]: background,
            }),
        )}
        style={{
            width: 2 * radius,
            height: 2 * radius,
            minWidth: 2 * radius,
            minHeight: 2 * radius,
            borderRadius: radius,
        }}
    >
        {logo ? (
            <SVGImage
                src={logo}
                width={radius}
                height={radius}
                x={radius / 2}
                y={radius / 2}
                className={styles[`color--${color[0]}-${color[1]}`]}
            />
        ) : (
            <span className={styles.name}>{name}</span>
        )}
    </div>
);

const colors = [
    'purple',
    'red',
    'yellow',
    'aqua',
    'gray',
    'blue',
    'black',
    'darkest',
    'darker',
    'dark',
    'base',
    'light',
    'lighter',
    'lightest',
    'background',
    'white',
];

Emblem.defaultProps = {
    logo: undefined,
    background: ['purple', 'base'],
    color: ['gray', 'white'],
    radius: 60,
};

Emblem.propTypes = {
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    background: PropTypes.arrayOf(PropTypes.oneOf(colors)),
    color: PropTypes.arrayOf(PropTypes.oneOf(colors)),
    radius: PropTypes.number,
};

export default Emblem;
