import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import entityTypes from 'js/enums/entity-types.enum';
import Collapse from 'js/components/collapse/collapse';
import Label from 'js/components/label/label';
import SVGImage from 'js/components/svg-image/svg-image';
import VideoActive from 'media/images/context-types/video-active.svg';
import VideoInactive from 'media/images/context-types/video-inactive.svg';
import DisplayActive from 'media/images/context-types/display-active.svg';
import DisplayInactive from 'media/images/context-types/display-inactive.svg';
import EventLogActive from './images/event-log-active.svg';
import EventLogInactive from './images/event-log-inactive.svg';
import styles from './report-type-radio.module.scss';

const cx = classNames.bind(styles);

let instanceId = 0;

function ReportTypeRadio({ options, selectedValue, label, onChange }) {
    const [thisInstanceId, setThisInstanceId] = useState('');

    useEffect(() => {
        instanceId += 1;
        setThisInstanceId(`radio-${instanceId}`);
    }, []);

    const isSelected = (value) => selectedValue === value;

    const getOptionIcon = (optionValue) => {
        switch (optionValue) {
            case entityTypes.DISPLAY_REPORTS:
                return isSelected(optionValue)
                    ? DisplayActive
                    : DisplayInactive;

            case entityTypes.VIDEO_REPORTS:
                return isSelected(optionValue) ? VideoActive : VideoInactive;

            case entityTypes.EVENT_LOG_REPORTS:
                return isSelected(optionValue)
                    ? EventLogActive
                    : EventLogInactive;

            default:
                return null;
        }
    };

    return (
        <Label label={label}>
            <div className={styles.container}>
                {options.map((option) => {
                    const optionClasses = cx({
                        option: true,
                        'read-only': option.readOnly,
                    });

                    return (
                        <div
                            key={`${thisInstanceId}-${option.value}`}
                            className={optionClasses}
                        >
                            <input
                                id={`${thisInstanceId}-${option.value}`}
                                type="radio"
                                checked={isSelected(option.value)}
                                tabIndex={option.readOnly ? '-1' : null}
                                className={styles.radio}
                                value={option.value}
                                onChange={() => onChange(option.value)}
                            />

                            <label
                                className={styles.label}
                                htmlFor={`${thisInstanceId}-${option.value}`}
                            >
                                <div className={styles['icon-container']}>
                                    <SVGImage
                                        src={getOptionIcon(option.value)}
                                        className={styles.icon}
                                    />
                                </div>

                                <div className={styles['label-content']}>
                                    <p>{option.label} </p>
                                </div>
                            </label>

                            {option.content && (
                                <Collapse isOpen={isSelected(option.value)}>
                                    {option.content}
                                </Collapse>
                            )}
                        </div>
                    );
                })}
            </div>
        </Label>
    );
}

export default ReportTypeRadio;
