import React, {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useIsMounting } from 'js/hooks';
import { AbortError, api, setDocumentTitle } from 'js/utils';
import { NotificationsContext } from 'js/contexts';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import NotificationList from './notification-list';

function NotificationListPage() {
    const { seeAllNotifications, unseenNotifications, refreshNotifications } =
        useContext(NotificationsContext);
    const isMounting = useIsMounting();
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [hasErrors, setErrors] = useState(false);
    const history = useHistory();

    const unseenCountInTopBar = unseenNotifications.length;

    const loadNotifications = async () => {
        try {
            const { results: notificationsData } =
                await api().notifications.list({
                    ordering: '-created',
                });

            const unseenCount = notificationsData.filter(
                ({ is_seen: isSeen }) => !isSeen,
            ).length;
            if (unseenCount !== unseenCountInTopBar) {
                refreshNotifications();
            }

            setNotifications(notificationsData);
        } catch (err) {
            if (err instanceof AbortError) return;
            throw err;
        }
    };

    const loadData = useCallback(async () => {
        setLoading(true);
        setErrors(false);

        try {
            await loadNotifications();
        } catch (err) {
            setErrors(true);
        } finally {
            setLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        setDocumentTitle(['Notifications']);
        loadData();

        return () => {
            api().abortAll();
        };
    }, [loadData]);

    useEffect(
        () =>
            history.listen(() => {
                if (!isMounting) {
                    seeAllNotifications();
                }
            }),
        [history, isMounting, unseenNotifications, seeAllNotifications],
    );

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb key="notifications" label="Notifications" />,
                    ]}
                    onNotificationsChange={() => {
                        loadNotifications();
                    }}
                />
            }
        >
            <NotificationList
                notifications={notifications}
                isLoading={isLoading}
                hasErrors={hasErrors}
                onRetry={loadData}
            />
        </Layout>
    );
}

export default NotificationListPage;
