import React, { useContext } from 'react';
import Box from 'js/components/box/box';
import { MATCHED_VIDEOS_REGION } from '../../../constants';
import ContextDetailContext from '../../../contexts/context-detail.context';
import PageSection from '../../page-section/page-section';
import styles from '../matched-items-section.module.scss';
import ReloadNotice from '../reload-notice';
import MatchedVideos from './matched-videos';
import RegionFilter from '../region-filter';

export default function MatchedVideosSection() {
    const {
        matchedVideos: {
            load: getMatchedVideos,
            needsReload: needsMatchedVideosReload,
            region,
            setRegion,
        },
    } = useContext(ContextDetailContext);

    const updateRegion = (r) => {
        setRegion(r);
        localStorage.setItem(MATCHED_VIDEOS_REGION, r);
    };

    return (
        <div className={styles['matched-pages-section']}>
            <PageSection
                title="Matched Videos"
                description="These are the videos that have the strongest
                match to this context. You can use them to verify
                or improve the accuracy of this context."
                extension={[
                    <RegionFilter value={region} onChange={updateRegion} />,
                ]}
            >
                <Box padding={['base', 0, 0, 0]}>
                    {needsMatchedVideosReload && (
                        <div className={styles.notices}>
                            <ReloadNotice
                                onReload={() => {
                                    getMatchedVideos();
                                }}
                                isVideo
                            />
                        </div>
                    )}

                    <MatchedVideos />
                </Box>
            </PageSection>
        </div>
    );
}
