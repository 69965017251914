import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INITIALLY_VISIBLE_TOPICS } from 'js/constants';
import ruleTypes from 'js/enums/rule-types.enum';
import { useLimitList } from 'js/hooks';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import ClickArea from 'js/components/click-area/click-area';
import Text from 'js/components/text/text';
import Sparkline from 'js/components/sparkline/sparkline';
import Collapse from 'js/components/collapse/collapse';
import TopicButton from 'js/components/button/topic-button';
import { RuleSet } from 'js/utils';
import styles from './recommendations-tab.module.scss';
import recommendationActions from './enums/actions.enum';
import recommendationAttributes from './enums/attributes.enum';
import { getRecommendationAppliedCount } from './utils';

function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

const Entities = ({
    recommendation,
    context,
    report,
    onApplyRecommendationEntity,
}) => {
    const { attribute, action } = recommendation;
    const entities =
        attribute === recommendationAttributes.LOGOS
            ? report.logos
                  .filter((i) =>
                      recommendation.entities
                          .map((j) => j.name)
                          .includes(i.logo),
                  )
                  .map((i) => ({ ...i, id: i.logo, name: i.logo_name }))
            : report.topics
                  .filter((i) =>
                      recommendation.entities
                          .map((j) => j.name)
                          .includes(i.topic),
                  )
                  .map((i) => ({ ...i, id: i.topic, name: i.topic_name }));
    const [hasMore, isExpanded, visible, showMore, showLess] = useLimitList(
        entities,
        INITIALLY_VISIBLE_TOPICS,
    );

    const buttonProps = visible.map((i) => {
        const isTargeted = context.rules.some(
            ({ aggregation, [attribute]: attr }) =>
                attr.includes(i.id) && aggregation === ruleTypes.INCLUDED,
        );
        const isBlocked = context.rules.some(
            ({ aggregation, [attribute]: attr }) =>
                attr.includes(i.id) && aggregation === ruleTypes.EXCLUDED,
        );

        let theme;
        let logo;
        if (isTargeted) {
            theme = 'targeted';
        } else if (isBlocked) {
            theme = 'blocked';
        } else {
            theme = 'default';
        }

        if (attribute === recommendationAttributes.TOPICS) {
            logo = i.topic_group_logo;
        }

        const actionIcon = isBlocked || isTargeted ? 'remove' : 'add';
        return {
            entity: i.id,
            theme,
            actionIcon,
            logo,
            active: false,
            children: i.name,
            onClick: () =>
                onApplyRecommendationEntity(
                    i.id,
                    !!(isTargeted || isBlocked) ===
                        (action === recommendationActions.ADD),
                ),
        };
    });

    return (
        <>
            {buttonProps.map(
                ({
                    entity,
                    theme,
                    logo,
                    actionIcon,
                    active,
                    children,
                    onClick,
                }) => (
                    <Box
                        key={entity}
                        margin={['small', 'small', 0, 0]}
                        display="inline-block"
                    >
                        <TopicButton
                            theme={theme}
                            action={actionIcon}
                            logo={logo}
                            active={active}
                            onClick={onClick}
                        >
                            {children}
                        </TopicButton>
                    </Box>
                ),
            )}

            {hasMore && (
                <Row>
                    <Col span="auto">
                        <Box padding={['small', 0, 0]}>
                            <Button
                                theme="outline"
                                size="small"
                                onClick={showMore}
                            >
                                Show All {entities.length}{' '}
                                {titleCase(attribute)}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            )}

            {isExpanded && (
                <Row>
                    <Col span="auto">
                        <Box padding={['small', 0, 0]}>
                            <Button
                                theme="outline"
                                size="small"
                                onClick={showLess}
                            >
                                Show Fewer {titleCase(attribute)}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            )}
        </>
    );
};

const RecommendationEntities = ({
    recommendation,
    context,
    report,
    onChangeContextRules,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { attribute, action } = recommendation;
    const recommendedEntities = recommendation.entities.map((e) => e.name);

    const appliedCount = getRecommendationAppliedCount(recommendation, context);

    const totalCount = recommendedEntities.length;

    const applyRecommendatonEntity = (entity, isApplied) => {
        const applyAction =
            (action === recommendationActions.ADD) === isApplied
                ? recommendationActions.REMOVE
                : recommendationActions.ADD;

        const operations = {
            [[recommendationActions.ADD, recommendationAttributes.TOPICS]]:
                'addTopics',
            [[recommendationActions.ADD, recommendationAttributes.LOGOS]]:
                'addLogos',
            [[recommendationActions.REMOVE, recommendationAttributes.TOPICS]]:
                'removeTopics',
            [[recommendationActions.REMOVE, recommendationAttributes.LOGOS]]:
                'removeLogos',
        };
        const operation = operations[[applyAction, attribute]];
        const ruleSet = new RuleSet(context.rules);
        if (operation.startsWith(recommendationActions.ADD)) {
            const targetRule = context.rules.find(
                ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
            );
            ruleSet.changeRule(targetRule.key, { [operation]: [entity] });
        } else {
            ruleSet.changeAllRules({ [operation]: [entity] });
        }
        onChangeContextRules(ruleSet.rules);
    };

    const attrDisplayName = attribute === 'logos' ? 'brands' : 'topics';

    return (
        <Box margin={['base', 0, 0]} marginSm={['small', 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    <ClickArea onClick={() => setIsExpanded(!isExpanded)}>
                        <Row alignItems="center">
                            <Col>
                                <Text inline weight="bold">
                                    {appliedCount}/{totalCount}
                                </Text>
                                <Text inline weight="bold">
                                    {' '}
                                    {`${attrDisplayName} ${
                                        action === recommendationActions.ADD
                                            ? 'targeted'
                                            : 'untargeted'
                                    }`}
                                </Text>
                            </Col>

                            <Col span="auto">
                                <div className={styles.expander}>
                                    {`${
                                        isExpanded ? 'Hide' : 'Show'
                                    } ${attrDisplayName}`}
                                    <Text inline color={['gray', 'light']}>
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={
                                                isExpanded
                                                    ? faChevronUp
                                                    : faChevronDown
                                            }
                                        />
                                    </Text>
                                </div>
                            </Col>
                        </Row>
                    </ClickArea>

                    <Box padding={['smaller', 0, 0]}>
                        <Sparkline
                            value={appliedCount}
                            maxValue={totalCount}
                            noLabel
                            color={['purple', 'base']}
                        />
                    </Box>

                    <Collapse isOpen={isExpanded}>
                        <Box padding={['base', 0, 0]}>
                            <Entities
                                recommendation={recommendation}
                                context={context}
                                report={report}
                                onApplyRecommendationEntity={
                                    applyRecommendatonEntity
                                }
                            />
                        </Box>
                    </Collapse>
                </Box>
            </Panel>
        </Box>
    );
};

export default RecommendationEntities;
