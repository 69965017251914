import React from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { reloadPage } from 'js/utils';
import ActionBar from 'js/components/action-bar/action-bar';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import CardCreateNew from 'js/components/card/card-create-new';
import CardGeneric from 'js/components/card/card-generic';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import TopBar from 'js/components/top-bar/top-bar';

const Wrapper = ({ breadcrumbs, newChannelLink, children, isReadOnly }) => (
    <Layout
        sidebar={<Navigation />}
        header={<TopBar breadcrumbs={breadcrumbs} />}
    >
        {!isReadOnly && (
            <ActionBar>
                <Button theme="outline" to={newChannelLink}>
                    Create Seat
                </Button>
            </ActionBar>
        )}

        <Section>
            <Container>
                <Box padding={['base', 0, 'large']}>{children}</Box>
            </Container>
        </Section>
    </Layout>
);

function ChannelList({ channels, loading, error, integration, isReadOnly }) {
    const { integrationId } = useParams();
    const newChannelLink = `/settings/channels/${integrationId}/new/`;

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb
                key="settings"
                to="/settings/"
                label="Settings"
                isCollapsible
            />,
            <Crumb
                key="channels"
                to="/settings/channels/"
                label="Channels"
                isCollapsible
            />,
        ];

        if (error) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (integration.name) {
            breadcrumbs.push(
                <Crumb key="integration" label={integration.name} />,
            );
        }

        return breadcrumbs;
    };

    if (loading) {
        return (
            <Wrapper
                breadcrumbs={getBreadcrumbs()}
                newChannelLink={newChannelLink}
                isReadOnly={isReadOnly}
            >
                <Box padding={['base', 0, 0]}>
                    <Spinner
                        size="large"
                        message="Loading Channels"
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            </Wrapper>
        );
    }

    if (error) {
        return (
            <Wrapper
                breadcrumbs={getBreadcrumbs()}
                newChannelLink={newChannelLink}
                isReadOnly={isReadOnly}
            >
                <Box padding={['base', 0, 0]}>
                    <Alert
                        theme="danger"
                        title="There was an error while loading the channels"
                        actionItems={
                            <Button onClick={reloadPage}>
                                Retry Loading Channels
                            </Button>
                        }
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            loading the page again in a few moments.
                        </p>
                    </Alert>
                </Box>
            </Wrapper>
        );
    }

    if (!channels.length) {
        return (
            <Wrapper
                breadcrumbs={getBreadcrumbs()}
                newChannelLink={newChannelLink}
                isReadOnly={isReadOnly}
            >
                <Box padding={['base', 0, 0]}>
                    <Alert
                        theme="empty"
                        title="There are no seats for this channel"
                    />
                </Box>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            breadcrumbs={getBreadcrumbs()}
            newChannelLink={newChannelLink}
            isReadOnly={isReadOnly}
        >
            <Row>
                {channels.map((item) => (
                    <Col
                        key={item.id}
                        span={12}
                        spanSm={12}
                        spanMd={6}
                        spanLg={4}
                    >
                        <Box padding={['base', 0, 0]}>
                            <CardGeneric
                                to={
                                    !isReadOnly
                                        ? `/settings/channels/${integration.id}/${item.id}/`
                                        : ''
                                }
                                title={item.description}
                                description={
                                    <>Seat ID {item.integration_key || 0}</>
                                }
                            />
                        </Box>
                    </Col>
                ))}
                {!isReadOnly && (
                    <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                        <Box padding={['base', 0, 0]}>
                            <CardCreateNew
                                to={newChannelLink}
                                title="Add New Seat"
                            />
                        </Box>
                    </Col>
                )}
            </Row>
        </Wrapper>
    );
}

export default withRouter(ChannelList);
