const subscriptions = {};

const on = (eventName, callback) => {
    if (!subscriptions[eventName]) {
        subscriptions[eventName] = [];
    }
    subscriptions[eventName].push(callback);
};

const off = (eventName, callback) => {
    if (subscriptions[eventName]) {
        subscriptions[eventName].filter(
            (existingCallback) => existingCallback !== callback,
        );
        if (subscriptions[eventName].length === 0) {
            delete subscriptions[eventName];
        }
    }
};

const emit = (eventName, payload) => {
    if (subscriptions[eventName]) {
        subscriptions[eventName].forEach((callback) => callback(payload));
    }
};

export default { on, off, emit };
