import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Spinner from 'js/components/spinner/spinner';
import styles from './breadcrumbs.module.scss';

const cx = classNames.bind(styles);

function Crumb({ label, to, onClick, isInactive, isLoading, hasError }) {
    const crumbClasses = cx({
        crumb: true,
        'crumb--inactive': isInactive,
    });

    if (isLoading) {
        return (
            <li className={crumbClasses}>
                <Spinner size="small" />
            </li>
        );
    }

    if (hasError) {
        return (
            <li className={crumbClasses}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Uh Oh&hellip;
            </li>
        );
    }

    return (
        <li className={crumbClasses}>
            {to || onClick ? (
                <Link className={styles.link} to={to} onClick={onClick}>
                    {label}
                </Link>
            ) : (
                <span>{label}</span>
            )}
        </li>
    );
}

Crumb.defaultProps = {
    label: undefined,
    to: undefined,
    onClick: undefined,
    isInactive: false,
    isLoading: false,
    hasError: false,
};

Crumb.propTypes = {
    label: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    isInactive: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
};

export default Crumb;
