import React from 'react';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';

const NoResults = () => (
    <Box
        margin={['small', 0]}
        padding="base"
        border={['base', 'dashed', 'gray', 'lighter']}
        borderRadius="round"
    >
        <Text color={['gray', 'base']}>No results found.</Text>
    </Box>
);

export default NoResults;
