import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Label from 'js/components/label/label';
import Select from 'js/components/select/select';
import Row from 'js/components/grid/row';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';
import Switch from 'js/components/switch/switch';
import Text from 'js/components/text/text';
import Panel from 'js/components/panel/panel';
import ChannelProvider from '../channel-provider/channel-provider';
import ContextDetailContext from '../../../contexts/context-detail.context';

const PausingAlert = () => (
    <Box
        padding="base"
        background={['purple', 'lightest']}
        borderRadius="rounder"
    >
        <Row alignItems="center">
            <Col span="auto">
                <Text color={['purple', 'base']} size="large">
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
                </Text>
            </Col>
            <Col>
                <Text color={['purple', 'darker']}>
                    Deals with 28 days of impression inactivity will
                    automatically be paused. You can manage your deal status via
                    the notification and context details pages.
                </Text>
            </Col>
        </Row>
    </Box>
);

const CountryAlert = () => (
    <Box
        padding={['small', 'base']}
        background={['purple', 'lightest']}
        borderRadius="round"
    >
        <Row alignItems="center" gutter="small" gap="small">
            <Col span="auto">
                <Text color={['purple', 'base']}>
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
                </Text>
            </Col>
            <Col>
                <Text color={['purple', 'darker']}>
                    Not seeing the country you want? Contact the 4D Account
                    Management Team to add it.
                </Text>
            </Col>
            <Col span={12} spanSm="auto">
                <SupportEmailLink type="button">Contact 4D</SupportEmailLink>
            </Col>
        </Row>
    </Box>
);

const ChannelPanel = ({
    channel,
    dealProviders,
    isReadOnly,
    onCreateDeal,
    onActivateDeal,
    onSetChannelGeotargets,
    onToggleChannel,
}) => {
    const {
        context: {
            current: { channels: contextChannels },
            saved: { channels: savedChannels },
        },
        deals: { current: deals, errors: dealErrors, geotargets },
    } = useContext(ContextDetailContext);
    const [geotargetSearchTerm, setGeotargetSearchTerm] = useState('');
    const filteredGeotargets = geotargets.filter(({ country }) =>
        country.toLowerCase().includes(geotargetSearchTerm.toLowerCase()),
    );
    const isChecked = contextChannels.includes(channel.id);
    const isSaved = savedChannels.includes(channel.id);
    let hasDeals = false;
    let geotargetIds = [];

    const geotargetInfo = `
        Required setting to manage the geographic targeting associated with your deal.
        Please select one or more countries to only buy impressions in the geos associated with your campaign.
    `;

    return (
        <Box
            key={channel.id}
            padding="base"
            margin={[0, 'base', 'base', 'base']}
            border={['base', 'solid', 'gray', 'lighter']}
            borderRadius="rounder"
        >
            <Row
                gutter="small"
                alignItems="center"
                justifyContent="space-between"
            >
                <Col span="auto">
                    <Text weight="bolder" inline>
                        {channel.description}
                    </Text>
                    <Text weight="bolder" inline color={['gray', 'light']}>
                        {' '}
                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                    </Text>
                    <Text weight="bolder" inline>
                        {'ID '}
                        {channel.integration_key}
                    </Text>
                </Col>
                <Col span={12} spanMd="auto">
                    <Box padding={['base', 0, 0]} paddingMd={[0, 'smallest']}>
                        <Switch
                            isDisabled={isReadOnly}
                            isChecked={isChecked}
                            onChange={onToggleChannel}
                        />
                    </Box>
                </Col>
            </Row>

            {isChecked && dealProviders.length > 0 && (
                <Box
                    padding="base"
                    margin={['base', 0, 0]}
                    background={['gray', 'background']}
                    borderRadius="rounder"
                >
                    <Box
                        padding="base"
                        background={['gray', 'white']}
                        borderRadius="rounder"
                    >
                        <Label
                            label="Deal IDs"
                            info={
                                <p>
                                    A Deal ID is a unique code that’s specific
                                    to your context. You’ll use this Deal ID to
                                    execute 4D targeting within your DSP. Be
                                    aware that unused deals will be paused after
                                    28 days of impression inactivity.
                                </p>
                            }
                        />

                        {dealProviders.map((provider) => {
                            const deal = deals.find(
                                (item) =>
                                    item.deal_provider === provider.id &&
                                    item.channel === channel.id,
                            );
                            if (deal) {
                                // all the deals of a channel are assumed to have the same geo-targets
                                geotargetIds = [...deal.geotargets];
                                hasDeals = true;
                            }

                            return (
                                <Box key={provider.id} margin={['small', 0, 0]}>
                                    <ChannelProvider
                                        isReadOnly={isReadOnly}
                                        provider={provider}
                                        deal={deal}
                                        isSaved={isSaved}
                                        onCreateDeal={() =>
                                            onCreateDeal(channel, provider)
                                        }
                                        onActivateDeal={() =>
                                            onActivateDeal(deal)
                                        }
                                    />
                                </Box>
                            );
                        })}

                        {hasDeals && !isSaved && (
                            <Box margin={['base', 0, 0]}>
                                <PausingAlert />
                            </Box>
                        )}
                    </Box>

                    {hasDeals && !isReadOnly && (
                        <Box
                            margin={['base', 0, 0]}
                            padding="base"
                            background={['gray', 'white']}
                            borderRadius="rounder"
                        >
                            <Select
                                multi
                                width="same"
                                label="Geo-targeting"
                                header={<CountryAlert />}
                                placement="bottom"
                                options={filteredGeotargets.map(
                                    ({ id, country }) => ({
                                        value: id,
                                        label: country,
                                    }),
                                )}
                                selectedValues={geotargetIds}
                                required
                                hasError={!!dealErrors[channel.id]}
                                errorMessage={<p>{dealErrors[channel.id]}</p>}
                                emptyMessage={
                                    <p>
                                        There are no countries that match your
                                        search.
                                    </p>
                                }
                                info={<p>{geotargetInfo}</p>}
                                onChange={(selectedIds) =>
                                    onSetChannelGeotargets(channel, selectedIds)
                                }
                                onSearch={setGeotargetSearchTerm}
                            >
                                {geotargets
                                    .filter(({ id }) =>
                                        geotargetIds.includes(id),
                                    )
                                    .map(({ country }) => country)
                                    .join(', ') || 'Select country'}
                            </Select>
                        </Box>
                    )}

                    {hasDeals && isReadOnly && (
                        <Box margin={['base', 0, 0, 0]}>
                            <Panel>
                                <Box padding="base">
                                    <Label
                                        label="Geo-targeting"
                                        info={<p>{geotargetInfo}</p>}
                                    >
                                        {geotargets
                                            .filter(
                                                ({ id }) =>
                                                    !geotargetIds.length ||
                                                    geotargetIds.includes(id),
                                            )
                                            .map(({ country }) => country)
                                            .join(', ')}
                                    </Label>
                                </Box>
                            </Panel>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ChannelPanel;
