import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import styles from './grid.styles';

function Row(props) {
    const { children, reverse } = props;
    const classes = [styles.row];

    if (reverse) {
        classes.push(styles['row--reverse']);
    }

    const {
        gutter: gutterProp,
        alignItems: alignItemsProp,
        justifyContent: justifyContentProp,
    } = props;

    if (gutterProp) {
        classes.push(styles[`row--gutter-${gutterProp}`]);
    }

    if (alignItemsProp) {
        classes.push(styles[`row--align-items-${alignItemsProp}`]);
    }

    if (justifyContentProp) {
        classes.push(styles[`row--justify-content-${justifyContentProp}`]);
    }

    return <View style={classes}>{children}</View>;
}

Row.defaultProps = {
    gutter: 'base',
    reverse: false,
    alignItems: '',
    justifyContent: '',
};

/* eslint-disable react/no-unused-prop-types */
Row.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.oneOf([
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
    ]),
    reverse: PropTypes.bool,
    alignItems: PropTypes.oneOf([
        '',
        'flex-start',
        'center',
        'flex-end',
        'stretch',
    ]),
    justifyContent: PropTypes.oneOf([
        '',
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'start',
    ]),
};
/* eslint-enable react/no-unused-prop-types */

export default Row;
