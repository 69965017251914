import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from '@react-pdf/renderer';
import Col from '../grid/col';
import Row from '../grid/row';
import styles from './logos.styles';

function Logos({ orientation, isTitle, logo4D, brandLogo, fixed }) {
    const isPortrait = orientation === 'portrait';
    const getTitleClass = () => {
        if (isTitle) {
            if (isPortrait) {
                return styles.titleContainer;
            }

            return styles.titleLandscapeContainer;
        }

        return null;
    };

    const classes = [styles.container, getTitleClass()];

    return (
        <View style={classes} fixed={fixed} break={fixed}>
            {logo4D ? (
                <Row justifyContent="space-between" alignItems="center">
                    <Col span="auto">
                        <Image
                            src={logo4D}
                            style={[
                                styles.logo,
                                isPortrait
                                    ? styles.logoPortrait
                                    : styles.logoLandscape,
                                isTitle ? styles.titleLogo : null,
                            ]}
                        />
                    </Col>

                    {brandLogo && (
                        <Col span="auto">
                            <Image
                                src={brandLogo}
                                style={[
                                    styles.logo,
                                    isPortrait
                                        ? styles.logoPortrait
                                        : styles.logoLandscape,
                                    isTitle
                                        ? styles.titleSideLogo
                                        : styles.sideLogo,
                                ]}
                            />
                        </Col>
                    )}
                </Row>
            ) : (
                brandLogo && (
                    <Image
                        src={brandLogo}
                        style={[
                            styles.logo,
                            isPortrait
                                ? styles.logoPortrait
                                : styles.logoLandscape,
                            isTitle ? styles.titleLogo : null,
                        ]}
                    />
                )
            )}
        </View>
    );
}

Logos.defaultProps = {
    orientation: 'portrait',
    isTitle: false,
    logo4D: undefined,
    brandLogo: undefined,
    fixed: false,
};

Logos.propTypes = {
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    isTitle: PropTypes.bool,
    logo4D: PropTypes.string,
    brandLogo: PropTypes.string,
    fixed: PropTypes.bool,
};

export default Logos;
