import React from 'react';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Text from 'js/components/text/text';

const MonthCalendar = ({ selectedDay, onChange }) => (
    <Box
        borderLeft={['thick', 'solid', 'gray', 'lightest']}
        margin={['small', 0, 0, 'base']}
        padding="base"
    >
        <Text size="large" weight="bold">
            On which day of the month?
        </Text>

        <Box margin={['base', 0]}>
            {[1, 2, 3, 4].map((weekIndex) => (
                <Box key={weekIndex} display="flex" margin={['small', 0, 0]}>
                    {[1, 2, 3, 4, 5, 6, 7].map((dayIndex) => {
                        const day = (weekIndex - 1) * 7 + dayIndex;
                        return (
                            <Box key={day} margin={[0, 'small', 0, 0]}>
                                <Button
                                    square
                                    className="calendar"
                                    active={day === selectedDay}
                                    onClick={() => onChange(day)}
                                >
                                    {day}
                                </Button>
                            </Box>
                        );
                    })}
                </Box>
            ))}
        </Box>

        <Text size="small" color={['gray', 'dark']}>
            <Text weight="bold" inline>
                Snapshots can sometimes take over 24 hours to generate.
            </Text>{' '}
            If you need a report for the 7th day of the month, we recommend that
            you schedule a snapshot to be generated on 5th or 6th day.
        </Text>
    </Box>
);

export default MonthCalendar;
