import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import EditNameButton from './buttons/edit-name-button';
import styles from '../rename-data-sources.module.scss';
import HasDataButton from './buttons/has-data-button';

const LineItemResult = ({ lineItem, onLineItemNameChange }) => {
    const [nameDisplayed, setNameDisplayed] = useState(lineItem.lineItemName);
    const onUpdateNameHandler = (newName) => {
        const updatedCampaign = {
            ...lineItem,
            lineItemName: newName,
        };
        onLineItemNameChange([updatedCampaign]);
    };

    return (
        <Box
            margin={['small', 0]}
            padding="small"
            border={['base', 'solid', 'gray', 'light']}
            borderRadius="round"
        >
            <Row justifyContent="space-between">
                <Col span="auto">
                    <div>
                        <div className={styles['result-text']}>
                            <Text weight="bold" color={['gray', 'darkest']}>
                                {nameDisplayed}
                            </Text>
                        </div>
                        <div className={styles['result-text']}>
                            <Text color={['gray', 'dark']}>
                                {lineItem.lineItemId}
                            </Text>
                        </div>
                    </div>
                </Col>
                <Col span="auto">
                    {lineItem.hasData && <HasDataButton />}
                    <EditNameButton
                        name={lineItem.lineItemName}
                        onUpdateNameDisplayed={setNameDisplayed}
                        onSave={onUpdateNameHandler}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default LineItemResult;
