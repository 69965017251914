import React from 'react';
import PropTypes from 'prop-types';
import styles from './drawer.module.scss';

function DrawerFooter({ children }) {
    return <div className={styles.footer}>{children}</div>;
}

DrawerFooter.defaultProps = {};

DrawerFooter.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DrawerFooter;
