import React, { useState, useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { reactLayerPortal } from 'js/utils';
import styles from 'js/components/drawer/drawer.module.scss';
import Button from 'js/components/button/button';
import Layer from 'js/components/layer/layer';

function Drawer({ children, onClose }) {
    const transitionNode = useRef();
    const containerNode = useRef();
    const [isClosing, setIsClosing] = useState(false);
    const [isTrapPaused, setIsTrapPaused] = useState(false);

    const handleClick = ({ target }) => {
        if (target === containerNode.current) {
            setIsClosing(true);
        }
    };
    const handleKeyDown = ({ key }) => {
        if (key === 'Escape') {
            setIsClosing(true);
        }
    };

    return (
        <CSSTransition
            nodeRef={transitionNode}
            classNames="fade"
            timeout={300}
            appear
            in={!isClosing}
            onExited={onClose}
        >
            <Layer
                ref={transitionNode}
                isFixedWithBackground
                preventBodyScroll
                onKeyDown={handleKeyDown}
            >
                <CSSTransition
                    nodeRef={containerNode}
                    classNames="fade-slide-left"
                    timeout={300}
                    appear
                    in={!isClosing}
                >
                    <FocusTrap
                        paused={isTrapPaused}
                        focusTrapOptions={{
                            escapeDeactivates: false,
                            allowOutsideClick: true,
                        }}
                        containerElements={[reactLayerPortal]}
                    >
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div
                            className={styles.wrapper}
                            onClick={handleClick}
                            onMouseDown={() => setIsTrapPaused(true)}
                            onMouseUp={() => setIsTrapPaused(false)}
                            ref={containerNode}
                        >
                            <div className={styles['close-button-container']}>
                                <Button
                                    square
                                    theme="outline"
                                    onClick={() => setIsClosing(true)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>
                            <div className={styles.container}>{children}</div>
                        </div>
                    </FocusTrap>
                </CSSTransition>
            </Layer>
        </CSSTransition>
    );
}

Drawer.defaultProps = {
    onClose: undefined,
};

Drawer.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
};

export default Drawer;
