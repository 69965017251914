import React from 'react';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';

export default function ReloadNotice({ onReload, isVideo }) {
    return (
        <Box
            shadow="deep"
            padding={['small', 'base']}
            background={['purple', 'darker']}
            borderRadius="rounder"
        >
            <Row alignItems="center">
                <Col>
                    <Text size="small" color={['purple', 'lightest']}>
                        <p>
                            <Text
                                inline
                                weight="bold"
                                color={['gray', 'white']}
                            >
                                Your settings have been updated,{' '}
                            </Text>
                            but the matched {isVideo ? 'videos' : 'pages'} have
                            not yet been updated to allow you to make further
                            adjustments using the currently visible pages.
                        </p>
                    </Text>
                </Col>

                <Col span="auto">
                    <Button onClick={onReload} theme="dark">
                        Reload {isVideo ? 'Videos' : 'Pages'}
                    </Button>
                </Col>
            </Row>
        </Box>
    );
}
