import history from 'js/history';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';

export const reactRoot = document.getElementById('root');
export const reactLayerPortal = document.getElementById('layer-portal');

/**
 * gets the payload as an object from a jwt token
 * @param {string} token
 * @returns {object} a jwt payload object
 */
export function getJwtPayload(token) {
    try {
        // Get Token Header
        const base64HeaderUrl = token.split('.')[0];
        const base64Header = base64HeaderUrl
            .replace('-', '+')
            .replace('_', '/');
        const headerData = JSON.parse(unescape(window.atob(base64Header)));

        // Get Token payload and date's
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const dataJWT = JSON.parse(unescape(window.atob(base64)));
        dataJWT.header = headerData;

        return dataJWT;
    } catch (err) {
        return {
            services: [],
        };
    }
}

/**
 * checks if jwt token is valid
 * @param {string} token
 * @returns {boolean}
 */
export function validJwtAge(token) {
    try {
        return Boolean(getJwtPayload(token).exp > Date.now() / 1000);
    } catch (err) {
        return false;
    }
}

/**
 * checks if email is valid
 * @param {string} email
 * @returns {boolean}
 */
export function validEmail(email) {
    // adaptation of Django's validator
    const emailRegex =
        /^[A-Z0-9!#$%&`'*+/=?^_{|}~-]+(\.[A-Z0-9!#$%&`'*+/=?^_{|}~-]+)*@([A-Z0-9]([A-Z0-9-]{0,61}[A-Z0-9])?\.)+[A-Z0-9]{2,63}$/i;
    return emailRegex.test(email);
}

/**
 * checks if a MIME type has a match in the provided allowed set
 * @param {string} type
 * @param {string[]} validTypes
 */
export function validMimeType(type, validTypes = []) {
    if (!validTypes.length) return true;

    return (
        validTypes.includes(type) ||
        validTypes.includes(type.replace(/\/.*/, '/*'))
    );
}

/**
 * checks if url is valid
 * @param {string} url
 * @param {string} protocol
 * @returns {boolean}
 */
export function validUrl(url, protocol = 'https?') {
    const urlRegex = new RegExp(
        `^${protocol}://[a-z0-9]+([.-][a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$`,
    );
    return urlRegex.test(url);
}

/**
 * cleans URL string
 * @param {string} url string needs cleaning
 * @returns {string}
 */
export function cleanUrl(url) {
    const httpsUrl = url.replace(/^(.*?:\/\/|)/, 'https://');
    return validUrl(httpsUrl, 'https') ? httpsUrl : url;
}

/**
 * cleans string
 * @param {string} string needs cleaning
 * @returns {string}
 */
export function cleanString(string) {
    return string.toLowerCase().trim();
}

/**
 * cleans a file name to avoid problematic chars
 * @param {string} fileName
 * @returns {string}
 */
export function cleanFileName(fileName) {
    return fileName
        .trim()
        .replace(/[/\\|]/g, '-')
        .replace(/[:"?<>]/g, '')
        .replace(/[\s.]+(\.[a-z0-9]+)$/i, '$1');
}

/**
 * capitalize string
 * @param {string} string needs capitalizing
 * @returns {string}
 */
export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * ordinal version of a number
 * @param {number} number
 * @returns {string}
 */
export function ordinal(number) {
    const int = Number.parseInt(number, 10);
    if (Number.isNaN(int)) return number;
    return `${int}${
        [11, 12, 13].includes(int % 100)
            ? 'th'
            : ['st', 'nd', 'rd'][(int % 10) - 1] || 'th'
    }`;
}

/* eslint-disable no-bitwise, no-plusplus */
/**
 * generates hash
 * @param {string} s
 * @returns {string}
 */
export function hash(s) {
    let a = 1;
    let c = 0;
    let h;
    let o;
    if (s) {
        a = 0;
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = (a << (6 & 268435455)) + o + (o << 14);
            c = a & 266338304;
            a = c !== 0 ? (a ^ c) >> 21 : a;
        }
    }
    return String(a);
}
/* eslint-enable no-bitwise, no-plusplus */

/**
 * creates deep copy of object
 * @param {object} obj
 * @returns {object}
 */
export function deepCopy(obj) {
    // TODO: this, but properly
    return JSON.parse(JSON.stringify(obj));
}

/**
 * freezes an object recursively
 * @param {object} obj The object
 * @returns {object} The same object, now frozen
 */
export function deepFreeze(obj) {
    Object.values(obj).forEach(
        (val) => Object.isFrozen(val) || deepFreeze(val),
    );
    return Object.freeze(obj);
}

/**
 * suspends execution for a given number of milliseconds
 * @param {number} ms
 * @returns {Promise}
 */
export function sleep(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((res) => setTimeout(res, ms));
}

/**
 * reloads current page
 * @returns {Promise}
 */
export async function reloadPage() {
    const { location } = history;
    await history.push('/reload/');
    await history.replace(location);
}

/**
 * creates iterator
 * @param {number} start
 * @param {number} end
 * @returns {Generator<number>}
 */
export function* counter(start = 0, end = Infinity) {
    let next = start;
    while (next <= end) {
        yield next;
        next += 1;
    }
}

/**
 * creates an array of words from a comma-separated string
 * @param {string} text
 * @returns {string[]}
 */
export function csvSplit(text) {
    return text
        .split(/[\t\n,]/)
        .map((item) => cleanString(item))
        .filter((item) => !!item);
}

/**
 * converts a comma-separated string (optionally double-quote escaped) into a list of items
 * @param {string} string
 * @param {string} separator
 * @returns {string[]}
 */
export function csvQuoteSplit(string, separator = ',') {
    const items = [''];
    let itemIndex = 0;
    let isOutOfQuotes = true;
    let prevChar;

    string.split('').forEach((char) => {
        if (char === '"') {
            isOutOfQuotes = !isOutOfQuotes;
            if (prevChar === char) {
                items[itemIndex] += char;
            }
            prevChar = !prevChar || prevChar === char ? '-' : char;
        } else if (isOutOfQuotes && char === separator) {
            itemIndex += 1;
            items[itemIndex] = '';
            prevChar = null;
        } else {
            items[itemIndex] += char;
            prevChar = char;
        }
    });

    return items;
}

/**
 * creates an array of unique, non-repeating primitive values
 * @param {any[]} items
 * @returns {any[]}
 */
export function dedupe(items) {
    return Array.from(new Set(items));
}

/**
 * creates an array of unique (by a set of attributes), non-repeating objects
 * @param {object[]} objects
 * @param {string[]} attrs
 */
export function dedupeBy(objects, attrs) {
    return objects.filter((object, index) => {
        const firstIndex = objects.findIndex((item) =>
            attrs.every((attr) => item[attr] === object[attr]),
        );
        return index === firstIndex;
    });
}

/**
 * formats an array
 * @param {string[]} items
 * @param {number} limit
 * @param {function} wrapper
 * @returns {string[]}
 */
export function enumerate(
    items,
    limit = Infinity,
    wrapper = (item, separator) => `“${item}”${separator}`,
) {
    return items.map((item, index) => {
        let separator = ', ';

        if (index === items.length - 2) {
            separator = ' and ';
        }

        if (index === Math.min(items.length, limit) - 1) {
            separator = '';
        }

        if (index >= limit) {
            return null;
        }

        return wrapper(item, separator);
    });
}

/**
 * updates list items with new fields
 * @param {object[]} list
 * @param {string} itemId
 * @param {object} update
 * @returns {object[]}
 */
export function getUpdatedList(list, itemId, update) {
    return list.map((item) => {
        if (item.id === itemId) {
            return {
                ...item,
                ...update,
            };
        }

        return item;
    });
}

/**
 * generates a numeric sequence as an array
 * @param {number} length
 * @param {number} [start=0]
 * @returns {number[]}
 */
export function getSequence(length, start = 0) {
    return Array.from({ length }, (_, i) => i + start);
}

/**
 * generates an Excel file
 * @param {array} sheets A sheet is an object having a `name`, an optional `title` and a `data` array; `data` items can
 *                       be objects (whose keys become the header) or arrays (whose left values become the header).
 * @param {string} fileName
 */
export const exportToXLSX = async (sheets, fileName) => {
    const workBook = new Excel.Workbook();
    workBook.creator = 'Silverbullet';

    const defaultFont = { name: 'Arial', color: { argb: 'FF404040' } };
    const headerFont = {
        ...defaultFont,
        bold: true,
        color: { argb: 'FF0052CC' },
    };
    const headerBorder = {
        bottom: { style: 'thin' },
        color: { argb: 'FFE8E8E8' },
    };
    const titleFont = { ...headerFont, size: 18 };

    const formatCell = (cell, { isHeader, isTabular } = {}) => {
        /* eslint-disable no-param-reassign */
        if (typeof cell.value === 'number') {
            cell.numFmt = Number.isInteger(cell.value) ? '#,##0' : '#,##0.00';
        } else if (/^-?[0-9]+(\.[0-9]+)?%$/.test(cell.value)) {
            cell.value = Number(cell.value.slice(0, -1)) / 100;
            cell.numFmt = '#,##0.00%';
        }

        if (isHeader) {
            cell.font = headerFont;
            if (isTabular) {
                cell.border = headerBorder;
                cell.alignment = { horizontal: 'center' };
            }
        } else {
            cell.font = defaultFont;
            if (!isTabular) {
                cell.alignment = { horizontal: 'left' };
            } else if (cell.value instanceof Date) {
                cell.alignment = { horizontal: 'center' };
            }
        }
        /* eslint-enable no-param-reassign */
    };

    const logoImages = await Promise.all(
        sheets.map(async (sheet) => {
            if (!sheet.logo) return null;

            const response = await fetch(sheet.logo);
            const blob = await response.blob();
            const buffer = await blob.arrayBuffer();

            const signature = new Uint8Array(buffer.slice(1, 4));
            const isPng = new TextDecoder().decode(signature) === 'PNG';

            return workBook.addImage({
                buffer,
                extension: isPng ? 'png' : 'jpeg',
            });
        }),
    );

    sheets.forEach(({ name, title, logo, data }, index) => {
        const isTabular = !(data[0] instanceof Array);
        const dataKeys = isTabular ? Object.keys(data[0]) : [];

        const workSheet = workBook.addWorksheet(name);
        workSheet.views = [{ showGridLines: false }];
        workSheet.properties.defaultColWidth = 24;
        workSheet.columns = dataKeys.map((key) => ({ header: key }));

        data.forEach((dataRow) => {
            const dataValues = Object.values(dataRow);
            workSheet.addRow(dataValues);
        });
        workSheet.eachRow((row, rowIndex) =>
            row.eachCell((cell, colIndex) => {
                const isHeader = (isTabular ? rowIndex : colIndex) === 1;
                formatCell(cell, { isHeader, isTabular });
            }),
        );

        if (title || logo) {
            const titleRow = workSheet.insertRow(
                1,
                logo ? ['', title] : [title],
            );
            titleRow.font = titleFont;
            titleRow.height = titleFont.size + 4;
        }

        if (logo) {
            workSheet.insertRow(1);
            workSheet.addImage(logoImages[index], {
                tl: { col: 0.85, row: 0.5 },
                ext: { width: 60, height: 60 },
            });
            workSheet.insertRow(3);
            workSheet.mergeCells('A1:A3');
        }
    });

    const fileBuffer = await workBook.xlsx.writeBuffer();
    const fileBlob = new Blob([fileBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    FileSaver.saveAs(fileBlob, cleanFileName(fileName));
};

/**
 * generates a TXT file
 * @param {string} text
 * @param {string} fileName
 */
export const exportToTXT = (text, fileName) => {
    const blob = new Blob([text], { type: 'text/plain' });
    FileSaver.saveAs(blob, cleanFileName(fileName));
};

/**
 * generates a CSV file. assumes all objects have same keys
 * @param {array} data
 * @param {string} fileName
 */
export const exportToCSV = (data, fileName) => {
    const columnHeaders = Object.keys(data[0]);

    const csvContent = `${columnHeaders.join(',')}\n${data
        .map((obj) => columnHeaders.map((field) => obj[field]).join(','))
        .join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, cleanFileName(fileName));
};

/**
 * split array into chunks
 * @param {array} array
 * @param {number} chunkSize
 * @returns {[]}
 */
export const arrayToChunks = (array, chunkSize) =>
    Array(Math.ceil(array.length / chunkSize))
        .fill()
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));

/**
 * checks to see if topics and/or logos have changed in a video context's rules
 * @param {oldRules} array
 * @param {newRules} array
 * @returns {boolean}
 */
export const topicsOrLogosHasChanged = (oldRules, newRules) => {
    const compare = ['aggregation', 'topics', 'logos'];
    return (
        oldRules.length !== newRules.length ||
        oldRules.some(
            (oldRule, i) =>
                JSON.stringify(oldRule, compare) !==
                JSON.stringify(newRules[i], compare),
        )
    );
};

/**
 * calculates impression threshold to determine what highlights to show in reports
 * @param {totalImpressions} number
 * @returns {number}
 */
export const getImpressionThreshold = (totalImpressions) =>
    totalImpressions * 0.05;
