import { color, borderWidth } from '../tokens.styles';

const styles = {
    'container--has-border-top': {
        borderTop: `${borderWidth.base}px solid ${color.gray.lightest})`,
    },

    'container--has-border-bottom': {
        borderBottom: `${borderWidth.base}px solid ${color.gray.lightest})`,
    },

    'container--primary': {
        backgroundColor: color.gray.white,
    },
};

export default styles;
