import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import entityTypes from 'js/enums/entity-types.enum';
import { getEventTagSourceOrDefault, getScheduleType } from 'js/utils';
import Box from 'js/components/box/box';
import Pill from 'js/components/pill/pill';
import SVGImage from 'js/components/svg-image/svg-image';
import CardBase, { getRecentlyUpdated } from './card-base';
import CardWrapper from './components/card-wrapper';
import CardBaseTemplate from './components/card-base-template';
import CardSlimTemplate from './components/card-slim-template';
import DisplayPill from './images/display-pill.svg';
import EventLogPill from './images/event-log-pill.svg';
import ScheduledPill from './images/schedule-pill.svg';
import EllipsisButton from '../button/ellipsis-button';
import styles from './card.module.scss';
import DropdownButton from '../dropdown-menu/button';
import ConfirmModal from '../modal/confirm-modal';

const ReportPills = ({ report: { type: reportType, schedule } }) => {
    const { typeIcon, typeLabel } = {
        [entityTypes.DISPLAY_REPORTS]: {
            typeIcon: <SVGImage src={DisplayPill} />,
            typeLabel: 'Display',
        },
        [entityTypes.VIDEO_REPORTS]: {
            typeIcon: <FontAwesomeIcon icon={faPlay} />,
            typeLabel: 'Video',
        },
        [entityTypes.EVENT_LOG_REPORTS]: {
            typeIcon: <SVGImage src={EventLogPill} />,
            typeLabel: 'Event Log',
        },
    }[reportType];

    const scheduleType = getScheduleType(schedule);
    return (
        <>
            <Box margin={[0, 'small', 0, 0]}>
                <Pill theme="muted" outline>
                    <div className={styles['pill-content']}>
                        {typeIcon}
                        {typeLabel}
                    </div>
                </Pill>
            </Box>

            {scheduleType ? (
                <Box margin={[0, 'small', 0, 0]}>
                    <Pill theme="muted" outline>
                        <div className={styles['pill-content']}>
                            <SVGImage src={ScheduledPill} />
                            {scheduleType}
                        </div>
                    </Pill>
                </Box>
            ) : null}
        </>
    );
};

const CardReport = ({
    report,
    onToggleFavorite,
    isSlim,
    onCancelSchedule,
    onDelete,
}) => {
    const {
        id: reportId,
        source: reportSourceId,
        name: reportTitle,
        type: reportType,
        advertiser: reportAdvertiser,
        deep_updated: reportDate,
        is_ready: isReportReady,
        is_favourite: isReportFavorite,
        isFavoriteLoading: isReportFavoriteLoading,
        schedule,
    } = report;

    const [deleteErrors, setDeleteErrors] = useState({});
    const [deleteWarning, setDeleteWarning] = useState(false);

    const reportTopline = getEventTagSourceOrDefault(reportSourceId);

    const updatedDate = isReportReady
        ? getRecentlyUpdated(reportDate)
        : 'Processing';

    const reportLinkMap = {
        [entityTypes.DISPLAY_REPORTS]: `/insights/display/${reportId}/`,
        [entityTypes.VIDEO_REPORTS]: `/insights/video/${reportId}/`,
        [entityTypes.EVENT_LOG_REPORTS]: `/insights/event-log/${reportId}/`,
    };
    const scheduleType = getScheduleType(schedule);

    const handleCancelSchedule = scheduleType
        ? () => onCancelSchedule(reportId, reportType, reportTitle)
        : null;

    const toggleDeleteWarningModal = () => {
        setDeleteWarning((state) => !state);
        setDeleteErrors({});
    };

    const onDeleteItem = async () => {
        setDeleteErrors({});
        try {
            await onDelete();
            toggleDeleteWarningModal();
        } catch (err) {
            setDeleteErrors(err);
        }
    };

    const ellipsis = (
        <EllipsisButton>
            <DropdownButton onClick={onToggleFavorite}>
                {isReportFavorite
                    ? 'Remove from Favorites'
                    : 'Add to Favorites'}
            </DropdownButton>
            <DropdownButton to={`/insights/${reportAdvertiser}/${reportId}/`}>
                Edit
            </DropdownButton>
            {scheduleType && (
                <DropdownButton onClick={handleCancelSchedule}>
                    Stop Scheduling
                </DropdownButton>
            )}
            <DropdownButton onClick={toggleDeleteWarningModal}>
                Delete
            </DropdownButton>
        </EllipsisButton>
    );

    return (
        <>
            {deleteWarning && (
                <ConfirmModal
                    confirm="Yes, Delete Insights Report"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Insights Report"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage={`There was an unexpected error and the report was 
                    not deleted. Please try again in a few moments.`}
                    onCancel={toggleDeleteWarningModal}
                    onConfirm={onDeleteItem}
                >
                    <p>
                        Deleting an insights report is permanent and cannot be
                        undone.
                    </p>
                </ConfirmModal>
            )}
            <CardWrapper
                title={reportTitle}
                to={reportId && reportLinkMap[reportType]}
            >
                <CardBase type="full" isSlim={isSlim}>
                    {isSlim ? (
                        <CardSlimTemplate
                            pill={<ReportPills report={report} />}
                            isFavorite={isReportFavorite}
                            isLoading={isReportFavoriteLoading}
                            title={reportTitle}
                            updatedDate={updatedDate}
                            onToggleFavorite={onToggleFavorite}
                            ellipsis={
                                <Box
                                    padding={['none', 'none', 'none', 'small']}
                                >
                                    {ellipsis}
                                </Box>
                            }
                        />
                    ) : (
                        <CardBaseTemplate
                            topline={reportTopline}
                            pill={<ReportPills report={report} />}
                            isFavorite={isReportFavorite}
                            isLoading={isReportFavoriteLoading}
                            title={reportTitle}
                            updatedDate={updatedDate}
                            onToggleFavorite={onToggleFavorite}
                            ellipsis={
                                <div className={styles['quick-actions']}>
                                    {ellipsis}
                                </div>
                            }
                        />
                    )}
                </CardBase>
            </CardWrapper>
        </>
    );
};

CardReport.defaultProps = {
    onToggleFavorite: undefined,
    isSlim: false,
};

CardReport.propTypes = {
    report: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.node,
        source: PropTypes.string,
        type: PropTypes.oneOf([
            entityTypes.DISPLAY_REPORTS,
            entityTypes.VIDEO_REPORTS,
            entityTypes.EVENT_LOG_REPORTS,
        ]).isRequired,
        updated: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        campaigns: PropTypes.array.isRequired,
        is_ready: PropTypes.bool,
        is_favourite: PropTypes.bool,
        isFavoriteLoading: PropTypes.bool,
    }).isRequired,
    onToggleFavorite: PropTypes.func,
    isSlim: PropTypes.bool,
};

export default CardReport;
