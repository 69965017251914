import React, { useState } from 'react';
import { RuleSet } from 'js/utils';
import ruleTypes from 'js/enums/rule-types.enum';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import RuleSelector from 'js/components/dropdown-menu/rule-selector';
import TopicButton from 'js/components/button/topic-button';
import entities from '../../../enums/matched-videos-entities.enum';

const EntityItem = ({
    entityType,
    entity,
    entityGroups,
    isReadOnly,
    rules,
    onChangeRules,
}) => {
    const [showMenu, setShowMenu] = useState(false);

    const isTargeted = rules.some(
        (rule) =>
            rule.aggregation === ruleTypes.INCLUDED &&
            rule[entityType].includes(entity.id),
    );
    const isBlocked = rules.some(
        (rule) =>
            rule.aggregation === ruleTypes.EXCLUDED &&
            rule[entityType].includes(entity.id),
    );

    let ruleAction;
    switch (entityType) {
        case entities.TOPICS:
            ruleAction = { removeTopics: [entity.id] };
            break;
        case entities.LOGOS:
            ruleAction = { removeLogos: [entity.id] };
            break;
        default:
            ruleAction = {};
    }

    const removeTopic = () => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeAllRules(ruleAction);
        onChangeRules(ruleSet.rules);
    };

    const handleChangeRules = (newRules) => {
        onChangeRules(newRules);
        setShowMenu(false);
    };

    const buttonText =
        entityType !== entities.LOGOS
            ? entity.name
            : `${entity.group_name} - ${entity.name}`;

    const theme =
        (isTargeted && 'targeted') || (isBlocked && 'blocked') || 'default';

    const actionIcon = theme === 'default' ? 'add' : 'remove';
    return (
        <div>
            <DropdownMenu
                content={
                    <RuleSelector
                        ruleItemId={entity.id}
                        topicGroups={
                            entityType === entities.TOPICS ? entityGroups : []
                        }
                        logoGroups={
                            entityType === entities.LOGOS ? entityGroups : []
                        }
                        rules={rules}
                        onChangeRules={handleChangeRules}
                    />
                }
                showMenu={showMenu}
                onHide={() => setShowMenu(false)}
            >
                <TopicButton
                    theme={
                        (isTargeted && 'targeted') ||
                        (isBlocked && 'blocked') ||
                        'default'
                    }
                    action={actionIcon}
                    logo={entity.group_logo}
                    active={showMenu}
                    isReadOnly={isReadOnly}
                    onClick={
                        isTargeted || isBlocked
                            ? removeTopic
                            : () => setShowMenu(!showMenu)
                    }
                >
                    {buttonText}
                </TopicButton>
            </DropdownMenu>
        </div>
    );
};

export default EntityItem;
