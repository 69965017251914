import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Alert from 'js/components/alert/alert';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import HighlightsSection from '../../../components/highlights-section/highlights-section';
import SectionWrapper from '../../../components/section-wrapper/section-wrapper';
import TopicsTreemapSection from './topics-treemap-section';
import TopicsTableSection from './topics-table-section';
import PanelWrapper from '../../../components/panel-wrapper';
import panelTabs from '../../enums/panel-tabs.enum';

const tabs = [
    { id: panelTabs.CHART, label: 'Impression Share' },
    { id: panelTabs.HIGHLIGHTS, label: 'Highlights' },
    { id: panelTabs.TABLE, label: 'Table' },
];

const TopicsPanel = ({
    report,
    context,
    kpis,
    tableColumns,
    onChangeContextRules,
    onInspectTopic,
}) => {
    const { topic_groups: indexedGroupsData, topics: indexedTopicsData } =
        report;
    const [currentTab, setCurrentTab] = useState(panelTabs.CHART);

    const highlightsData = indexedGroupsData.map((item) => ({
        ...item,
        name: item.topic_group_name,
        logo: item.topic_group_logo,
    }));

    const checkDisplayTopicsPanel = () => {
        const indexedGroupsWithImpressions = indexedGroupsData.filter(
            (group) => group.impressions > 0,
        );

        return (
            indexedTopicsData.length > 0 &&
            indexedGroupsWithImpressions.length > 0
        );
    };

    const panelTitle = 'Topic Themes';
    const panelDescription = `This panel displays Topic recommendations 
        that can help improve performance and scale. Topics are combinations of 
        keywords that follow an overall theme. Use these insights to understand 
        how Topics overlap with your media and can improve your targeting.`;

    if (!checkDisplayTopicsPanel()) {
        return (
            <SectionWrapper title={panelTitle} description={panelDescription}>
                <Alert size="base" theme="empty">
                    <p>Not enough data available</p>
                </Alert>
            </SectionWrapper>
        );
    }

    return (
        <SectionWrapper title={panelTitle} description={panelDescription}>
            <PanelWrapper>
                <Box margin={['base', 0, 0]}>
                    <Tabs
                        tabs={tabs}
                        currentTab={currentTab}
                        onChangeTab={setCurrentTab}
                    >
                        <TabContent tabId={panelTabs.CHART}>
                            <TopicsTreemapSection report={report} />
                        </TabContent>

                        <TabContent tabId={panelTabs.HIGHLIGHTS}>
                            <Box margin={['base', 0]}>
                                <HighlightsSection
                                    data={highlightsData}
                                    report={report}
                                    kpis={kpis}
                                    impressionsHint="If you do not want to show your advertising on these Topics themes, make an adjustment to your targeting and keywords"
                                    ctrHint="If CTR is one of your key metrics, you might want to target keywords from these top performing Topics themes."
                                    viewRateHint="If View Rate is one of your key metrics, you might want to target keywords from these top performing Topics themes."
                                />
                            </Box>
                        </TabContent>

                        <TabContent tabId={panelTabs.TABLE}>
                            <TopicsTableSection
                                report={report}
                                context={context}
                                tableColumns={tableColumns}
                                onChangeContextRules={onChangeContextRules}
                                onInspectTopic={onInspectTopic}
                            />
                        </TabContent>
                    </Tabs>
                </Box>
            </PanelWrapper>
        </SectionWrapper>
    );
};

export default TopicsPanel;
