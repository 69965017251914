import React, { useContext } from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import NoMatchedPagesNotice from './no-matched-pages-notice';
import MatchedPageItem from './matched-page-item';
import ContextDetailContext from '../../../contexts/context-detail.context';

export default function MatchedPages() {
    const {
        context: {
            current: context,
            current: { rules },
        },
        channels,
        matchedPages: {
            list: matchedPages,
            load: getMatchedPages,
            loadingPageMatches: loading,
            pageMatchError: loadingError,
            needsReload,
        },
    } = useContext(ContextDetailContext);

    const hasActiveDimensions = rules.filter(
        (item) => item.keywords.length || item.topics.length,
    ).length;

    if (loading) {
        return (
            <Spinner
                size="large"
                message="Loading"
                color={['gray', 'dark']}
                isCentered
            />
        );
    }

    if (loadingError) {
        return (
            <Alert
                theme="danger"
                title="There Was An Unexpected Error Loading Matching Pages"
                actionItems={
                    <Button onClick={getMatchedPages}>
                        Retry Loading Pages
                    </Button>
                }
            >
                <p>
                    Hopefully it is only a temporary issue. Please try loading
                    the matching pages again in a few moments.
                </p>
            </Alert>
        );
    }

    // No Active Dimensions
    if (!hasActiveDimensions && !needsReload)
        return (
            <Alert theme="empty" title="No Active Dimensions">
                <Text color={['gray', 'dark']}>
                    <Box margin={[0, 0, 'small']}>
                        <p>
                            When a dimension has been activated you will be able
                            to view matched pages here.
                        </p>
                    </Box>
                </Text>
            </Alert>
        );

    if (!matchedPages.length)
        return <NoMatchedPagesNotice context={context} channels={channels} />;

    return (
        <Box margin={[0, 0, '-base', 0]}>
            {matchedPages.map((item) => (
                <Box margin={[0, 0, 'base']} key={item.url}>
                    <MatchedPageItem matchedPage={item} />
                </Box>
            ))}
        </Box>
    );
}
