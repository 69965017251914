import React from 'react';
import FavoritesButton from 'js/components/button/favorites-button';
import EllipsisButton from 'js/components/button/ellipsis-button';
import DropdownButton from 'js/components/dropdown-menu/button';
import styles from '../card.module.scss';

const Ellipsis = ({
    onToggleFavorite,
    isFavorite,
    onToggleDeleteWarningModal,
    to,
}) => (
    <div className={styles['quick-actions']}>
        <EllipsisButton size="small">
            {onToggleFavorite && (
                <DropdownButton onClick={onToggleFavorite}>
                    {isFavorite ? 'Remove from' : 'Add to'} Favorites
                </DropdownButton>
            )}

            {to && <DropdownButton to={to}>Edit</DropdownButton>}

            {onToggleDeleteWarningModal && (
                <DropdownButton onClick={onToggleDeleteWarningModal}>
                    Delete
                </DropdownButton>
            )}
        </EllipsisButton>
    </div>
);

const CardBaseTemplate = ({
    scale,
    isAdvertiserCardType,
    logo,
    topline,
    pill,
    isFavorite,
    isLoading,
    isPreview,
    to,
    title,
    updatedDate,
    onDelete,
    onToggleFavorite,
    onToggleDeleteWarningModal,
    ellipsis,
}) => (
    <>
        <div className={styles.content}>
            {logo}
            {topline && <p className={styles.topline}>{topline}</p>}
            {onToggleFavorite && (
                <div className={styles.favorites}>
                    <FavoritesButton
                        size="small"
                        isFavorite={isFavorite}
                        isLoading={isLoading}
                        onToggleFavorite={onToggleFavorite}
                    />
                </div>
            )}
            <h3 className={styles.title}>{title}</h3>
            {updatedDate && <p className={styles.date}>{updatedDate}</p>}
        </div>
        <div className={styles.badge}>
            {scale}
            {pill}
            {ellipsis ?? (
                <div>
                    {isAdvertiserCardType
                        ? !isPreview &&
                          onDelete && (
                              <Ellipsis
                                  onToggleFavorite={onToggleFavorite}
                                  isFavorite={isFavorite}
                                  onToggleDeleteWarningModal={
                                      onToggleDeleteWarningModal
                                  }
                                  to={to}
                              />
                          )
                        : onDelete && (
                              <Ellipsis
                                  onToggleFavorite={onToggleFavorite}
                                  isFavorite={isFavorite}
                                  onToggleDeleteWarningModal={
                                      onToggleDeleteWarningModal
                                  }
                                  to={to}
                              />
                          )}
                </div>
            )}
        </div>
    </>
);

export default CardBaseTemplate;
