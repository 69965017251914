import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSortNumericDown,
    faSortNumericDownAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faSort } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames/bind';
import sortTypes from 'js/enums/sort-types.enum';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import styles from './sorting-dropdown.module.scss';

const cx = classNames.bind(styles);

function SortingIcon({ selectedValue }) {
    if (selectedValue === sortTypes.NONE)
        return <FontAwesomeIcon icon={faSort} />;
    if (selectedValue[0] === '-')
        return <FontAwesomeIcon icon={faSortNumericDownAlt} />;

    return <FontAwesomeIcon icon={faSortNumericDown} />;
}

function SortingDropdown({ options, selectedValue, onSortingChange }) {
    const [showMenu, setShowMenu] = useState(false);

    const value =
        options.ascending === selectedValue ||
        options.descending === selectedValue
            ? selectedValue
            : sortTypes.NONE;

    const isAscending = value !== sortTypes.NONE && value[0] !== '-';
    const isDescending = value !== sortTypes.NONE && value[0] === '-';

    const sortingIconClasses = cx({
        icon: true,
        active: showMenu,
    });

    const changeSorting = (sorting) => {
        onSortingChange(sorting);
        setShowMenu(false);
    };

    return (
        <DropdownMenu
            content={
                <ul>
                    <DropdownButton
                        active={isDescending}
                        onClick={() => changeSorting(options.descending)}
                    >
                        High To Low
                    </DropdownButton>

                    <DropdownButton
                        active={isAscending}
                        onClick={() => changeSorting(options.ascending)}
                    >
                        Low To High
                    </DropdownButton>
                </ul>
            }
            onHide={() => setShowMenu(false)}
            showMenu={showMenu}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
                className={sortingIconClasses}
                onClick={() => setShowMenu((state) => !state)}
            >
                <SortingIcon selectedValue={value} />
            </span>
        </DropdownMenu>
    );
}

SortingDropdown.defaultProps = {
    selectedValue: sortTypes.NONE,
};

SortingDropdown.propTypes = {
    options: PropTypes.shape({
        ascending: PropTypes.string,
        descending: PropTypes.string,
    }).isRequired,
    selectedValue: PropTypes.string,
    onSortingChange: PropTypes.func.isRequired,
};

export default SortingDropdown;
