import React from 'react';
import Container from 'js/components/container/container';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import SectionTitle from 'js/components/section-title/section-title';
import Box from 'js/components/box/box';
import Tile from 'js/components/tile/tile';
import uploadGuideSteps from '../upload-guide-copy';

const StepTile = ({ step, onClickTile }) => (
    <Box margin={[0, 0, 'base', 0]} marginSm={0}>
        <Tile
            hasShadow
            hasOverlay
            title={step.title}
            description={step.description}
            backgroundImage={step.image}
            background={['gray', 'lightest']}
            onClick={() => onClickTile(step)}
        />
    </Box>
);

const StepsSection = ({ onClickTile }) => (
    <Box
        margin={[0, 0, 'base', 0]}
        borderTop={['base', 'solid', 'gray', 'lightest']}
    >
        <Container>
            <Box margin={['large', 0, 'base', 0]}>
                <SectionTitle>Upload Guide</SectionTitle>
            </Box>
            <Row>
                {uploadGuideSteps.map((step) => (
                    <Col key={step.key} span={12} spanMd={4}>
                        <StepTile step={step} onClickTile={onClickTile} />
                    </Col>
                ))}
            </Row>
        </Container>
    </Box>
);

export default StepsSection;
