import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Modal from 'js/components/modal/modal';
import { withCampaignDetail } from 'js/hocs';
import { CampaignDetailContext } from 'js/contexts';
import CampaignForm from './form';

function CampaignModal({
    title,
    preset,
    advertisers,
    onAdvertiserCreate,
    onSave,
    onClose,
}) {
    const {
        campaign: {
            current: campaign,
            save: saveCampaign,
            saving,
            setName,
            setAdvertiser,
        },
        errors: { save: saveErrors },
    } = useContext(CampaignDetailContext);

    useEffect(() => {
        // This reconciles the actual campaign with the composed copy we pass
        // to CampaignForm below.
        if (preset.name) setName(preset.name);
        if (preset.advertiser) setAdvertiser(preset.advertiser);
    }, [preset.advertiser, preset.name, setAdvertiser, setName]);

    const handleSave = async () => {
        try {
            const newCampaign = await saveCampaign();
            if (onSave) onSave(newCampaign);
        } catch {} // eslint-disable-line no-empty
    };

    return (
        <>
            <Modal title={title} onClose={onClose}>
                <CampaignForm
                    isModal
                    preset={preset}
                    campaign={campaign}
                    advertisers={advertisers}
                    saving={saving}
                    saveErrors={saveErrors}
                    onNameChange={setName}
                    onAdvertiserChange={setAdvertiser}
                    onAdvertiserCreate={onAdvertiserCreate}
                    onSave={handleSave}
                />
            </Modal>
            {saving && (
                <LoadingLayer
                    message={`${campaign.id ? 'Saving' : 'Creating'} Campaign`}
                />
            )}
        </>
    );
}

CampaignModal.defaultProps = {
    preset: {},
};

CampaignModal.propTypes = {
    title: PropTypes.string.isRequired,
    preset: PropTypes.object,
    advertisers: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAdvertiserCreate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withCampaignDetail(CampaignModal);
