import React, { useState } from 'react';
import Box from 'js/components/box/box';
import PropTypes from 'prop-types';
import Popper from 'js/components/popper/popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from 'js/components/text/text';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import IndicatorBar from 'js/components/indicator-bar/indicator-bar';
import styles from '../card.module.scss';

const DEFAULT_RANGE = 10;

const levels = {
    UNKNOWN: {
        background: ['gray', 'base'],
        name: 'unknown',
        message: 'The scale of this context is unknown.',
    },
    LOW: {
        background: ['red', 'dark'],
        name: 'low',
        message:
            'The scale of this context is low. This indicates that there may be an insufficient amount of inventory to run a small campaign successfully.',
        min: 1,
    },
    MEDIUM: {
        background: ['yellow', 'base'],
        name: 'medium',
        message:
            'The scale of this context is medium. This indicates that there may be a sufficient amount of inventory to run a small to medium sized campaign successfully.',
        min: 0.4 * DEFAULT_RANGE,
    },
    HIGH: {
        background: ['aqua', 'darker'],
        name: 'high',
        message:
            'The scale of this context is high. This indicates that there should be a sufficient amount of inventory to run a typical campaign successfully.',
        min: 0.8 * DEFAULT_RANGE,
    },
};

const ScaleIndicator = ({ scale, level }) =>
    [...Array(5).keys()].map((index) => {
        const currentStep = index + 1;
        const lowerCondition = currentStep <= scale ? 'active' : level.name;
        const higherCondition =
            currentStep <= Math.ceil(scale / 2) ? 'active' : level.name;

        return (
            <div
                key={index}
                data-level={
                    scale < DEFAULT_RANGE / 2 ? lowerCondition : higherCondition
                }
                className={styles['tile-scale']}
            />
        );
    });

const InteractiveScale = ({ scale }) => {
    const [isActive, setActive] = useState(false);

    const level = Object.values(levels).reduce(
        (acc, item) =>
            scale !== null && scale >= item.min && item.min > (acc.min || 0)
                ? item
                : acc,
        levels.UNKNOWN,
    );

    return (
        <Popper
            content={
                <div className={styles['interactive-scale-tooltip']}>
                    <IndicatorBar
                        value={scale}
                        lowMessage="Low Scale"
                        mediumMessage="Medium Scale"
                        highMessage="High Scale"
                        loadingMessage="Calculating Scale"
                        unknownMessage="Unknown Scale"
                    />
                    <Box padding={['small', 'none', 'none']}>
                        <Text>{level.message}</Text>
                    </Box>
                </div>
            }
            theme="tooltip"
            width="fixed"
            placement="bottom-start"
            offset={[0, 4]}
            showContent={isActive}
        >
            <div
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
            >
                <Box
                    background={level.background}
                    padding={['smallest', 'smaller']}
                    borderRadius="base"
                >
                    <div className={styles['indicator-value']}>
                        <Text color={['gray', 'white']}>
                            {level?.min ? (
                                <Text weight="bold">{scale}</Text>
                            ) : (
                                <FontAwesomeIcon icon={faQuestion} />
                            )}
                        </Text>
                    </div>
                    <Box
                        display="flex"
                        padding={['none', 'none', 'smallest', 'none']}
                    >
                        <ScaleIndicator scale={scale} level={level} />
                    </Box>
                </Box>
            </div>
        </Popper>
    );
};

InteractiveScale.defaultProps = {
    scale: 0,
};

InteractiveScale.propTypes = {
    scale: PropTypes.number,
};

export default InteractiveScale;
