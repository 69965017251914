import React, { useContext, useEffect, useState } from 'react';
import editorModes from 'js/enums/editor-modes.enum';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import TopicSelector from 'js/components/topic-selector/topic-selector';
import ruleItemTypes from 'js/enums/rule-item-types.enum';
import RuleKeywordTextForm from './rule-keyword-text-form';
import RuleKeywords from './rule-keywords';
import RuleMenu from './rule-menu';
import RuleRelatedKeywords from './rule-related-keywords';
import RuleTopics from './rule-topics';
import ContextDetailContext from '../../../contexts/context-detail.context';

const Rule = ({ headerLabel, rule, focus, onCsvError }) => {
    const {
        context: {
            current: { rules, language },
            setRules,
        },
        page: { isReadOnly },
        topics: { groups },
    } = useContext(ContextDetailContext);
    const ruleSet = new RuleSet(rules);

    const [editorMode, setEditorMode] = useState(editorModes.RICH_UI);
    const [relatedKeyword, setRelatedKeyword] = useState('');

    const filteredGroups = groups.filter((group) => group.topics.length);

    const visibleTopicGroups =
        rule.aggregation === ruleTypes.EXCLUDED
            ? filteredGroups
            : filteredGroups
                  .map((group) => ({
                      ...group,
                      topics: group.topics.filter(
                          (topic) => !topic.is_brand_safety,
                      ),
                  }))
                  .filter((group) => group.topics.length);

    const isTargetedRule = rule.aggregation === ruleTypes.INCLUDED;

    const onAddKeyword = (keyword) => {
        ruleSet.changeRule(rule.key, { addKeywords: [keyword] });
        setRules(ruleSet.rules);
        setRelatedKeyword(keyword);
    };

    const addRuleTopics = (ruleKey, topics) => {
        ruleSet.changeRule(ruleKey, { addTopics: topics });
        setRules(ruleSet.rules);
    };

    const removeRuleTopics = (ruleKey, topics) => {
        ruleSet.changeRule(ruleKey, { removeTopics: topics });
        setRules(ruleSet.rules);
    };

    const removeTopics = (topics) => {
        ruleSet.changeAllRules({ removeTopics: topics });
        setRules(ruleSet.rules);
    };

    const toggleTopic = (itemId) => {
        if (rule.topics.includes(itemId)) {
            removeRuleTopics(rule.key, [itemId]);
        } else {
            addRuleTopics(rule.key, [itemId]);
        }
    };

    const toggleTopicGroup = (groupId) => {
        const allActiveTopics = rules.map((item) => item.topics).flat();
        const groupTopics = groups
            .find((item) => item.id === groupId)
            .topics.map((item) => item.id);

        if (groupTopics.every((item) => allActiveTopics.includes(item))) {
            removeTopics(groupTopics);
        } else {
            const topicsToAdd = groupTopics.filter(
                (g) => !allActiveTopics.includes(g),
            );
            addRuleTopics(rule.key, topicsToAdd);
        }
    };

    const toggleEditorMode = () => {
        setEditorMode(
            editorMode === editorModes.RICH_UI
                ? editorModes.BULK_EDIT
                : editorModes.RICH_UI,
        );
    };

    useEffect(() => {
        setRelatedKeyword('');
    }, [editorMode]);

    useEffect(() => {
        if (!rule.keywords.length) {
            setRelatedKeyword('');
        }
    }, [rule.keywords]);

    return (
        <>
            <Box margin={['base', 0]}>
                <Box margin={[0, 0, 'small']}>
                    <Row justifyContent="space-between" alignItems="center">
                        <Col>
                            <Text size="large" weight="bold">
                                {headerLabel}
                            </Text>
                        </Col>

                        {!isReadOnly && (
                            <Col span="auto">
                                <RuleMenu
                                    rule={rule}
                                    editorMode={editorMode}
                                    onToggleEditorMode={toggleEditorMode}
                                    onLoadStart={() => onCsvError('')}
                                    onLoadEnd={() =>
                                        setEditorMode(editorModes.RICH_UI)
                                    }
                                    onError={(error) => onCsvError(error)}
                                />
                            </Col>
                        )}
                    </Row>
                </Box>
                {!isReadOnly && (
                    <TopicSelector
                        itemDisplayName={ruleItemTypes.TOPIC}
                        itemType={ruleItemTypes.TOPIC}
                        groups={visibleTopicGroups}
                        rules={rules}
                        ruleKey={rule.key}
                        language={language}
                        onAddKeyword={onAddKeyword}
                        onTarget={isTargetedRule ? toggleTopic : null}
                        onBlock={!isTargetedRule ? toggleTopic : null}
                        onTargetAll={isTargetedRule ? toggleTopicGroup : null}
                        onBlockAll={!isTargetedRule ? toggleTopicGroup : null}
                    />
                )}
            </Box>

            {!isReadOnly && (
                <RuleRelatedKeywords rule={rule} keyword={relatedKeyword} />
            )}

            {isReadOnly && !rule.topics.length && !rule.keywords.length && (
                <Box margin={[0, 0, 'base']}>
                    <Alert theme="empty">
                        <p>You have not selected any topics or keywords.</p>
                    </Alert>
                </Box>
            )}

            {rule.topics.length > 0 && <RuleTopics rule={rule} />}

            {editorMode === editorModes.RICH_UI && rule.keywords.length > 0 && (
                <RuleKeywords
                    rule={rule}
                    onToggleEditorMode={toggleEditorMode}
                />
            )}

            {editorMode === editorModes.BULK_EDIT && (
                <RuleKeywordTextForm
                    rule={rule}
                    focus={focus}
                    onToggleEditorMode={toggleEditorMode}
                />
            )}
        </>
    );
};

export default Rule;
