import React from 'react';
import Box from 'js/components/box/box';
import Checkbox from 'js/components/checkbox/checkbox';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import SVGImage from 'js/components/svg-image/svg-image';
import DisplayActive from 'media/images/context-types/display-active.svg';
import DisplayInactive from 'media/images/context-types/display-inactive.svg';
import VideoActive from 'media/images/context-types/video-active.svg';
import VideoInactive from 'media/images/context-types/video-inactive.svg';
import styles from '../report-tag.module.scss';

const CheckButton = ({ label, active, disabled, icon, info, onClick }) => (
    <Checkbox
        radio
        hideIcon
        isReadOnly={disabled}
        options={[
            {
                label: (
                    <div className={styles['check-button-label']}>
                        <SVGImage src={icon} width={35} height={35} />
                        <Box margin={[0, 0, 0, 'small']}>{label}</Box>
                    </div>
                ),
                value: true,
                hint: info,
            },
        ]}
        selectedValues={active}
        onChange={() => onClick()}
    />
);

const TypeSelector = ({ hasDisplay, hasVideo, isVideo, onChange }) => (
    <Box padding={['large', 0, 0]}>
        <Row>
            <Col>
                <CheckButton
                    label="Display"
                    active={!isVideo}
                    disabled={!hasDisplay}
                    icon={isVideo ? DisplayInactive : DisplayActive}
                    info={
                        <p>
                            The 4D display tag collects media exposure data in a
                            privacy compliant manner, helping you to generate
                            contextual insights and improve marketing outcomes.
                        </p>
                    }
                    onClick={onChange}
                />
            </Col>
            <Col>
                <CheckButton
                    label="Video"
                    active={isVideo}
                    disabled={!hasVideo}
                    icon={isVideo ? VideoActive : VideoInactive}
                    info={
                        <p>
                            The 4D video tag collects media exposure data in a
                            privacy compliant manner, helping you to generate
                            contextual insights and improve marketing outcomes.
                        </p>
                    }
                    onClick={onChange}
                />
            </Col>
        </Row>
    </Box>
);

export default TypeSelector;
