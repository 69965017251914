import React, { useContext } from 'react';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import AutoOptimize from 'media/images/various/auto-optimize.svg';
import SVGImage from 'js/components/svg-image/svg-image';
import Rule from './rule';
import ContextDetailContext from '../../../contexts/context-detail.context';
import classes from './rules-section.module.scss';

export const AutoOptPanel = ({ onViewDetails }) => (
    <Panel theme="secondary">
        <Box padding="base">
            <Row justifyContent="space-between">
                <Col span="auto" alignSelf="center">
                    <Row>
                        <Col span="auto" alignSelf="center">
                            <SVGImage
                                className={classes['auto-opt-icon']}
                                src={AutoOptimize}
                            />
                        </Col>
                        <Col span="auto" alignSelf="center">
                            <Text size="large" color={['gray', 'darker']}>
                                Auto Optimizations have enhanced the targeting
                                of this context.
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col span="auto" alignSelf="center">
                    <Button theme="light-blue" onClick={onViewDetails}>
                        View Details
                    </Button>
                </Col>
            </Row>
        </Box>
    </Panel>
);

const RulesSection = ({ focus, onCsvError }) => {
    const {
        context: {
            current: { rules },
            setRules,
        },
        page: { isReadOnly },
        optimize: {
            current: { recommendations: optimizeRecommendations },
        },
        pageSections: { scrollTo: smoothScrollToAnchor },
    } = useContext(ContextDetailContext);

    const extendableRules = rules.filter(
        (rule) => rule.aggregation === ruleTypes.INCLUDED,
    );

    const fixedRules = rules.filter(
        (rule) => rule.aggregation === ruleTypes.EXCLUDED,
    );

    const focusedRule =
        extendableRules.length > 1
            ? [...extendableRules]
                  .filter((rule) => rule.keywords.length === 0)
                  .sort((a, b) => b.key - a.key)[0]
            : null;

    const addKeywordGroup = () => {
        const ruleSet = new RuleSet(rules);
        ruleSet.addRule({
            aggregation: ruleTypes.INCLUDED,
        });
        setRules(ruleSet.rules);
    };

    const getHeaderLabelExtendableRules = (i) => {
        if (i > 0) return 'and';

        return 'Target content featuring';
    };

    const getHeaderLabelFixedRules = (i) => {
        if (i > 0) return 'and';

        return `${
            extendableRules.length > 0 ? 'and block' : 'Block'
        } content featuring`;
    };

    const scrollToOptimizeSection = (e) => {
        setTimeout(() => {
            smoothScrollToAnchor(e, 'optimize');
        }, 0);
        e.preventDefault();
    };

    const hasOptimizations = optimizeRecommendations.length > 0;

    return (
        <Box padding={['small', 'base']}>
            {extendableRules.map((rule, i) => (
                <Box key={rule.key} padding={['small', 0]}>
                    <Panel bordered>
                        <Box padding={[0, 'base']}>
                            <Rule
                                headerLabel={getHeaderLabelExtendableRules(i)}
                                rule={rule}
                                focus={focus && rule === focusedRule}
                                onCsvError={onCsvError}
                            />
                        </Box>
                        {i === 0 && hasOptimizations && (
                            <Box margin={[0, 'base', 'base']}>
                                <AutoOptPanel
                                    onViewDetails={scrollToOptimizeSection}
                                />
                            </Box>
                        )}
                    </Panel>
                </Box>
            ))}

            {!isReadOnly && (
                <Box padding={['small', 0]}>
                    <Row>
                        <Col span={12} spanMd="auto" spanLg="auto">
                            <Button theme="outline" onClick={addKeywordGroup}>
                                Add Group
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}

            {fixedRules.map((rule, i) => (
                <Box key={rule.key} padding={['small', 0]}>
                    <Panel bordered>
                        <Box padding={[0, 'base']}>
                            <Rule
                                headerLabel={getHeaderLabelFixedRules(i)}
                                rule={rule}
                                onCsvError={onCsvError}
                            />
                        </Box>
                    </Panel>
                </Box>
            ))}
        </Box>
    );
};

export default RulesSection;
