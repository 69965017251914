import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Form = forwardRef(({ onSubmit, children, ...props }, ref) => (
    <form
        {...props}
        ref={ref}
        onSubmit={(evt) => {
            onSubmit();
            evt.preventDefault();
        }}
    >
        {children}
    </form>
));

Form.defaultProps = {
    onSubmit: () => {},
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.node.isRequired,
};

Form.displayName = 'Form';

export default Form;
