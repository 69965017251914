import React, { forwardRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './layer.module.scss';

/*
    The reason this counter exists is so that we do not remove the no-scroll
    class from the body until we should (when no layer with preventBodyScroll
    exists): the reason it is a counter is that multiple layers can exist
    at the same time.
*/
let noScrollInstanceCount = 0;

const Layer = forwardRef(
    (
        {
            children,
            isFixed,
            isFixedWithBackground,
            preventBodyScroll,
            onClick,
            onKeyDown,
            layerId,
        },
        ref,
    ) => {
        const classes = [styles.container];

        if (isFixed) {
            classes.push(styles['is-fixed']);
        } else if (isFixedWithBackground) {
            classes.push(styles['is-fixed'], styles['translucent-background']);
        }

        useEffect(() => {
            if (preventBodyScroll) {
                noScrollInstanceCount += 1;
                if (noScrollInstanceCount === 1) {
                    document.body.classList.add(styles['no-scroll']);
                }
                return () => {
                    noScrollInstanceCount -= 1;
                    if (noScrollInstanceCount === 0) {
                        document.body.classList.remove(styles['no-scroll']);
                    }
                };
            }
            return () => {};
        }, [preventBodyScroll]);

        return ReactDOM.createPortal(
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
                className={classNames(classes)}
                onClick={onClick}
                onKeyDown={onKeyDown}
                tabIndex="-1"
                ref={ref}
            >
                {children}
            </div>,
            document.getElementById(layerId),
        );
    },
);

Layer.defaultProps = {
    isFixed: false,
    isFixedWithBackground: false,
    preventBodyScroll: false,
    onClick: undefined,
    onKeyDown: undefined,
    layerId: 'layer-portal',
};

Layer.propTypes = {
    children: PropTypes.node.isRequired,
    isFixed: PropTypes.bool,
    isFixedWithBackground: PropTypes.bool,
    preventBodyScroll: PropTypes.bool,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    layerId: PropTypes.string,
};

Layer.displayName = 'Layer';

export default Layer;
