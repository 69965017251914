import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CardBase from './card-base';
import styles from './card.module.scss';

const CardCreateNew = ({ title, to, hasShadow }) => (
    <Link to={to}>
        <CardBase type="link" hasShadow={hasShadow}>
            <div className={styles.icon}>
                <p>
                    <FontAwesomeIcon icon={faPlus} />
                </p>
            </div>

            <h3 className={styles.title}>{title}</h3>
        </CardBase>
    </Link>
);

CardCreateNew.defaultProps = {
    hasShadow: false,
};

CardCreateNew.propTypes = {
    title: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    hasShadow: PropTypes.bool,
};

export default CardCreateNew;
