import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Row from '../grid/row';
import Col from '../grid/column';
import Button from '../button/button';
import EventBus from '../../event-bus';
import styles from './toast.module.scss';

export function showToast(title, content, timeoutSeconds, theme) {
    EventBus.emit('addToast', {
        title,
        content,
        timeoutSeconds,
        theme,
    });
}

function Toast({ title, children, timeoutSeconds, onClose, theme }) {
    const [timerBarWidth, setTimerBarWidth] = useState('100%');
    const [timerIsActive, setTimerIsActive] = useState(!!timeoutSeconds);
    const requestRef = useRef();
    const transitionNode = useRef();
    const lastTickMsRef = useRef(Date.now());
    const secondsAliveRef = useRef(0);
    const timerIsActiveRef = useRef(false);

    const classes = [styles.container];
    if (theme) {
        classes.push(styles[`container--theme-${theme}`]);
    }

    useEffect(() => {
        function step() {
            if (secondsAliveRef.current <= timeoutSeconds) {
                const currentMs = Date.now();

                if (timerIsActiveRef.current) {
                    secondsAliveRef.current +=
                        (currentMs - lastTickMsRef.current) / 1000;

                    setTimerBarWidth(
                        `${
                            (1 - secondsAliveRef.current / timeoutSeconds) * 100
                        }%`,
                    );
                }
                lastTickMsRef.current = currentMs;

                requestRef.current = window.requestAnimationFrame(step);
            } else {
                onClose();
            }
        }

        if (timeoutSeconds) {
            requestRef.current = window.requestAnimationFrame(step);
        }

        return () => cancelAnimationFrame(requestRef.current);
    }, [onClose, timeoutSeconds]);

    useEffect(() => {
        timerIsActiveRef.current = timerIsActive;
    }, [timerIsActive]);

    return (
        <CSSTransition
            nodeRef={transitionNode}
            classNames="fade-scale"
            timeout={300}
            appear
            in
        >
            <div
                ref={transitionNode}
                className={classNames(classes)}
                onMouseEnter={() => setTimerIsActive(false)}
                onMouseLeave={() => setTimerIsActive(true)}
            >
                <Row>
                    <Col>
                        {title && <h2 className={styles.title}>{title}</h2>}
                        {children && (
                            <div className={styles.content}>{children}</div>
                        )}
                    </Col>
                    <Col span="auto">
                        <Button
                            square
                            theme="dark"
                            size="small"
                            onClick={onClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Col>
                </Row>
                {timeoutSeconds && (
                    <>
                        <div
                            className={classNames([
                                styles['timer-bar'],
                                styles['timer-bar--empty'],
                            ])}
                        />
                        <div
                            className={styles['timer-bar']}
                            style={{ width: timerBarWidth }}
                        />
                    </>
                )}
            </div>
        </CSSTransition>
    );
}

Toast.defaultProps = {
    title: undefined,
    children: undefined,
    timeoutSeconds: undefined,
    onClose: undefined,
    theme: undefined,
};

Toast.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    timeoutSeconds: PropTypes.number,
    onClose: PropTypes.func,
    theme: PropTypes.oneOf(['danger']),
};

export default Toast;
