import React, { Fragment, useContext, useState } from 'react';
import { faPlus, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { enumerate, RuleSet } from 'js/utils';
import ruleTypes from 'js/enums/rule-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Pill from 'js/components/pill/pill';
import Row from 'js/components/grid/row';
import RuleSelector from 'js/components/dropdown-menu/rule-selector';
import Text from 'js/components/text/text';
import TopicButton from 'js/components/button/topic-button';
import styles from '../matched-items-section.module.scss';
import ContextDetailContext from '../../../contexts/context-detail.context';

const PageTitle = ({ title, url }) => (
    <h3 className={styles['content-title']}>
        <a href={url} target="_blank" rel="noopener noreferrer">
            {title || url.split('?')[0].split('#')[0]}{' '}
        </a>
    </h3>
);

const PageImage = ({ image, url }) => (
    <div
        className={styles['content-image']}
        style={{
            backgroundImage: `url(${image})`,
        }}
    >
        <a href={url} target="_blank" rel="noopener noreferrer">
            <div>
                <img src={image} alt="" />
            </div>
        </a>
    </div>
);

const TopicItem = ({
    topic,
    topicGroups,
    isReadOnly,
    rules,
    onChangeRules,
}) => {
    const [showMenu, setShowMenu] = useState(false);

    const isTargeted = rules.some(
        (rule) =>
            rule.aggregation === ruleTypes.INCLUDED &&
            rule.topics.includes(topic.id),
    );
    const isBlocked = rules.some(
        (rule) =>
            rule.aggregation === ruleTypes.EXCLUDED &&
            rule.topics.includes(topic.id),
    );

    const removeTopic = () => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeAllRules({ removeTopics: [topic.id] });
        onChangeRules(ruleSet.rules);
    };

    const handleChangeRules = (newRules) => {
        onChangeRules(newRules);
        setShowMenu(false);
    };

    const theme =
        (isTargeted && 'targeted') || (isBlocked && 'blocked') || 'default';
    const actionIcon = theme === 'default' ? 'add' : 'remove';

    return (
        <div>
            <DropdownMenu
                content={
                    <RuleSelector
                        ruleItemId={topic.id}
                        topicGroups={topicGroups}
                        rules={rules}
                        onChangeRules={handleChangeRules}
                    />
                }
                showMenu={showMenu}
                onHide={() => setShowMenu(false)}
            >
                <TopicButton
                    theme={theme}
                    action={actionIcon}
                    logo={topic.group_logo}
                    active={showMenu}
                    isReadOnly={isReadOnly}
                    onClick={
                        isTargeted || isBlocked
                            ? removeTopic
                            : () => setShowMenu(!showMenu)
                    }
                >
                    {topic.name}
                </TopicButton>
            </DropdownMenu>
        </div>
    );
};

const KeywordItem = ({
    keyword,
    matches,
    rules,
    topicGroups,
    isReadOnly,
    onChangeRules,
}) => {
    let theme = 'muted';
    let light = true;
    let outline = false;
    let prefixIcon = null;
    let suffixIcon = faPlus;

    const targetedKeywords = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item.keywords)
        .flat();

    const blockedKeywords = rules
        .filter((item) => item.aggregation === ruleTypes.EXCLUDED)
        .map((item) => item.keywords)
        .flat();

    const removeKeyword = () => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeAllRules({
            removeKeywords: [keyword],
        });
        onChangeRules(ruleSet.rules);
    };

    if ([...matches, keyword].some((item) => blockedKeywords.includes(item))) {
        const isStemming = !blockedKeywords.includes(keyword);
        theme = 'danger';
        light = isStemming;
        outline = true;
        prefixIcon = isStemming ? faQuestion : null;
        suffixIcon = isStemming ? faPlus : faTimes;
    } else if (
        [...matches, keyword].some((item) => targetedKeywords.includes(item))
    ) {
        const isStemming = !targetedKeywords.includes(keyword);
        theme = 'keyword';
        light = isStemming;
        outline = false;
        prefixIcon = isStemming ? faQuestion : null;
        suffixIcon = isStemming ? faPlus : faTimes;
    }

    return (
        <Pill
            key={keyword}
            outline={outline}
            theme={theme}
            light={light}
            prefixButton={prefixIcon && <FontAwesomeIcon icon={prefixIcon} />}
            prefixDropdownContent={
                prefixIcon === faQuestion && (
                    <KeywordHelp keyword={keyword} matches={matches} />
                )
            }
            suffixButton={!isReadOnly && <FontAwesomeIcon icon={suffixIcon} />}
            suffixDropdownContent={
                !isReadOnly &&
                suffixIcon === faPlus && (
                    <div className={styles.dropdown}>
                        <RuleSelector
                            keyword={keyword}
                            topicGroups={topicGroups}
                            rules={rules}
                            onChangeRules={onChangeRules}
                        />
                    </div>
                )
            }
            onSuffixClick={
                !isReadOnly && suffixIcon === faTimes ? removeKeyword : null
            }
        >
            {keyword}
        </Pill>
    );
};

const KeywordHelp = ({ keyword, matches }) => {
    const stemmingMatches = matches.filter((item) => item !== keyword);

    return (
        <Box padding={['base']}>
            <Text color={['gray', 'dark']}>
                4D considers this keyword to be matching as it is closely
                related to{' '}
                {enumerate(stemmingMatches, Infinity, (item, separator) => (
                    <Fragment key={item}>
                        <Text weight="bold" inline>
                            {`“${item}”`}
                        </Text>
                        {separator}
                    </Fragment>
                ))}
                {stemmingMatches.length === 1 ? (
                    <>, which is specified as a keyword in this context.</>
                ) : (
                    <>, which are specified as keywords in this context.</>
                )}
            </Text>
        </Box>
    );
};

const ShowMore = ({ hasMore, isExpanded, onMore, onLess }) => (
    <Row gutter="smaller">
        {hasMore && (
            <Col span="auto">
                <Button theme="outline" size="small" onClick={onMore}>
                    Show More
                </Button>
            </Col>
        )}

        {isExpanded && (
            <Col span="auto">
                <Button theme="outline" size="small" onClick={onLess}>
                    Show Fewer
                </Button>
            </Col>
        )}
    </Row>
);

const ENTITIES_PER_PAGE = 10;

export default function MatchedPageItem({
    matchedPage: {
        url,
        domain,
        og_title: ogTitle,
        og_image: ogImage,
        entities,
        topics: topicIds,
    },
}) {
    const {
        context: {
            current: { rules },
            setRules,
        },
        topics: { groups: topicGroups, list: topicList },
        matchedPages: { setNeedsReload: setMatchedPagesNeedsReload },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);

    const topics = topicList.filter(({ id }) => topicIds.includes(id));

    const [visibleEntitiesCount, setVisibleEntitiesCount] =
        useState(ENTITIES_PER_PAGE);
    const [isEntitiesExpanded, setIsEntitiesExpanded] = useState(false);
    const hasMoreEntities = visibleEntitiesCount < entities.length;
    const visibleEntities = entities.slice(0, visibleEntitiesCount);

    const showMoreEntities = () => {
        setVisibleEntitiesCount((count) => count + ENTITIES_PER_PAGE);
        setIsEntitiesExpanded(true);
    };

    const showLessEntities = () => {
        setVisibleEntitiesCount(ENTITIES_PER_PAGE);
        setIsEntitiesExpanded(false);
    };

    return (
        <div className={styles['matched-item']}>
            <Row>
                <Col>
                    <Text size="small" color={['gray', 'dark']}>
                        {domain}
                    </Text>

                    <Box margin={[0, 0, 'base']}>
                        <PageTitle title={ogTitle} url={url} />
                    </Box>
                </Col>

                {ogImage && (
                    <Col span="auto">
                        <PageImage image={ogImage} url={url} />
                    </Col>
                )}
            </Row>

            {topics.length > 0 && (
                <>
                    <Box margin={['base', 0, 'small']}>
                        <Text color={['gray', 'dark']}>Topics</Text>
                    </Box>

                    <div className={styles['matched-item-listing']}>
                        {topics.map((topic) => (
                            <TopicItem
                                key={topic.id}
                                topic={topic}
                                topicGroups={topicGroups}
                                isReadOnly={isReadOnly}
                                rules={rules}
                                onChangeRules={(newRules) => {
                                    setRules(newRules);
                                    setMatchedPagesNeedsReload(true);
                                }}
                            />
                        ))}
                    </div>
                </>
            )}

            {entities.length > 0 && (
                <>
                    <Box margin={['base', 0, 'small']}>
                        <Text color={['gray', 'dark']}>Keywords</Text>
                    </Box>

                    <div className={styles['matched-item-listing']}>
                        {visibleEntities.map(({ name, matches }) => (
                            <KeywordItem
                                key={name}
                                keyword={name}
                                matches={matches}
                                rules={rules}
                                topicGroups={topicGroups}
                                isReadOnly={isReadOnly}
                                onChangeRules={(newRules) => {
                                    setRules(newRules);
                                    setMatchedPagesNeedsReload(true);
                                }}
                            />
                        ))}
                    </div>

                    {entities.length > ENTITIES_PER_PAGE && (
                        <Box margin={['small', 0, 0]}>
                            <ShowMore
                                hasMore={hasMoreEntities}
                                isExpanded={isEntitiesExpanded}
                                onMore={showMoreEntities}
                                onLess={showLessEntities}
                            />
                        </Box>
                    )}
                </>
            )}
        </div>
    );
}
