import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';
import Box from '../box/box';
import Col from '../grid/col';
import Row from '../grid/row';

const separator = (
    <Col gutter="base" span="auto">
        <Box
            display="inline-block"
            margin={['small', 0]}
            borderLeft={['base', 'solid', 'gray', 'lightest']}
        >
            <Text>&#8203;</Text>
        </Box>
    </Col>
);

function SeparatedSection({ orientation, items, alignItems }) {
    const isPortrait = orientation === 'portrait';

    return (
        <Row alignItems={alignItems} gutter="base">
            {items.map((item, index) => {
                const isFirstItem = index === 0;

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {isPortrait && !isFirstItem && separator}

                        <Col gutter="base" span={isPortrait ? 'fluid' : 12}>
                            {item}
                        </Col>
                    </Fragment>
                );
            })}
        </Row>
    );
}

SeparatedSection.defaultProps = {
    orientation: 'portrait',
    alignItems: 'center',
};

SeparatedSection.propTypes = {
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    items: PropTypes.array.isRequired,
    alignItems: PropTypes.oneOf([
        '',
        'flex-start',
        'center',
        'flex-end',
        'stretch',
    ]),
};

export default SeparatedSection;
