import React from 'react';
import { Link } from 'react-router-dom';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';
import HelpDropdownSubsection from 'js/components/help-dropdown/help-dropdown-subsection';
import HelpDropdown from 'js/components/help-dropdown/help-dropdown';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';

const Help = () => (
    <Box padding={['large', 0]}>
        <Box margin={[0, 0, 'base', 0]}>
            <Text size="larger" weight="bolder">
                I can’t see my DSP advertising IDs
            </Text>
        </Box>
        <HelpDropdown title="My campaign is live">
            <HelpDropdownSubsection title="I have implemented the 4D Tag">
                <p>
                    You’re all set! Data picked up by the 4D Tag can take up to
                    24 hours to populate on this screen.
                </p>
                <p>Time is ticking. Over 24 hours and no data to be found?</p>
                <p>
                    We’ve got you covered! Contact your dedicated{' '}
                    <SupportEmailLink />.
                </p>
            </HelpDropdownSubsection>
            <HelpDropdownSubsection title="I have not implemented the 4D tag">
                <p>
                    Optimizations are generated based on your live campaign data
                    which is collected by the 4D Tag.{' '}
                    <Link to="/insights/tag/" target="_blank">
                        Click here
                    </Link>{' '}
                    to get the 4D Tag for this account and instructions on how
                    to implement this.
                </p>
            </HelpDropdownSubsection>
        </HelpDropdown>
        <HelpDropdown title="My campaign is not live">
            <HelpDropdownSubsection title="I have implemented the 4D Tag">
                <p>
                    Almost there! Your DSP advertising IDs will appear in this
                    section once your campaigns have been set live and are
                    receiving impressions.
                </p>
                <p>
                    Don’t forget that it can take up to 24 hours for your DSP
                    advertising IDs to populate on this screen.
                </p>
            </HelpDropdownSubsection>
            <HelpDropdownSubsection title="I have not implemented the 4D Tag">
                <p>
                    Optimizations are generated based on your live campaign data
                    which is collected by the 4D Tag.{' '}
                    <Link to="/insights/tag/" target="_blank">
                        Click here
                    </Link>{' '}
                    to get the 4D Tag for this account and instructions on how
                    to implement this.
                </p>
            </HelpDropdownSubsection>
        </HelpDropdown>
    </Box>
);

export default Help;
