import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

function useResizeObserver(ref) {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref?.current;
        if (!observeTarget) return () => {};
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setDimensions(entry.contentRect);
            });
        });

        resizeObserver.observe(observeTarget);

        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
}

export default useResizeObserver;
