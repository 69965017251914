import * as d3 from 'd3';

let canvas;

export const getTextDimensions = (
    text,
    style = {
        fontVariant: 'normal',
        fontWeight: 'bold',
        fontFamily: 'Arial',
    },
) => {
    // re-use canvas object for better performance
    if (!canvas) {
        canvas = document.createElement('canvas');
    }

    const context = canvas.getContext('2d');
    const { fontVariant, fontWeight, fontSize, fontFamily } = style;
    const heightSpacingAround = 2;
    const height = Number(fontSize) + heightSpacingAround;

    if (context) {
        context.font = `${fontVariant} ${fontWeight} ${fontSize}px '${fontFamily}'`;

        return {
            width: Number(context.measureText(text).width),
            height,
        };
    }

    return { width: 0, height };
};

const SIDE_PADDING = 10;

export const truncateText = (text, style, maxWidth, ellipsis = '...') => {
    const getCharWidth = (char) => getTextDimensions(char, style).width;

    const truncatedChars = [];
    const charArray = Array.from(text);
    const ellipsisWidth = getCharWidth(ellipsis);

    if (maxWidth - ellipsisWidth < 0) {
        return text.charAt(0);
    }

    let currentWidth = ellipsisWidth;
    let didTruncate = false;

    for (let i = 0; i < charArray.length; i += 1) {
        const charWidth = getCharWidth(charArray[i]);

        if (currentWidth + charWidth <= maxWidth - SIDE_PADDING) {
            truncatedChars[i] = charArray[i];
            currentWidth += charWidth;
        } else {
            truncatedChars[i] = ellipsis;
            didTruncate = true;
            break;
        }
    }

    if (didTruncate) {
        return truncatedChars.join('');
    }

    return text;
};

export const getTopParent = (node) => {
    if (node.parent) {
        return getTopParent(node.parent);
    }

    return node;
};

export const resolvePath = (object, path, defaultValue) =>
    path
        .split('.')
        .filter((p) => p)
        .reduce((o, p) => (o ? o[p] : defaultValue), object);

export const getColorDomainFn = (
    topNode,
    valuePropInData,
    customD3ColorScale,
) => {
    const nodes = topNode.descendants();

    const d = d3.extent(nodes, (n) => {
        if (n.parent !== null) {
            return resolvePath(n, valuePropInData);
        }

        return null;
    });

    return customD3ColorScale.domain(d);
};

export const getFontSize = (width, height) =>
    Math.max(
        Math.min(
            width / 5,
            height / 2,
            Math.sqrt(width * width + height * height) / 15,
        ),
        9,
    );
