import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './tabs.module.scss';

function TabButton({ children, isActive, to, onClick }) {
    const classes = [];

    if (isActive) {
        classes.push(styles.active);
    }

    return (
        <li className={classNames(classes)}>
            {onClick ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <span className={styles.tab} onClick={onClick}>
                    {children}
                </span>
            ) : (
                <Link className={styles.tab} to={to}>
                    {children}
                </Link>
            )}
        </li>
    );
}

TabButton.defaultProps = {
    isActive: false,
    to: undefined,
    onClick: undefined,
};

TabButton.propTypes = {
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
};

export default TabButton;
