import React, { useState } from 'react';
import Box from 'js/components/box/box';
import classNames from 'classnames/bind';
import Col from 'js/components/grid/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import Row from 'js/components/grid/row';
import Video from 'js/components/video/video';
import Text from 'js/components/text/text';
import styles from '../report-tag.module.scss';

const positions = {
    FIRST: 'First',
    FIRST_QUARTILE: 'First Quartile',
    MIDPOINT: 'Midpoint',
    THIRD_QUARTILE: 'Third Quartile',
    COMPLETE: 'Complete',
};

const cx = classNames.bind(styles);

const Tracker = ({ trackerPositions }) => (
    <Box padding={['base', 0, 0]}>
        <Row alignItems="flex-end" gutter="smallest">
            {trackerPositions.map(({ position, timeComplete }, index) => {
                const classes = cx({
                    'tracker-bar': true,
                    'left-radius': index === 0,
                    'right-radius': index === trackerPositions.length - 1,
                    'time-complete': timeComplete,
                });
                return (
                    <Col key={position}>
                        <Row
                            alignItems="center"
                            justifyContent="center"
                            gutter="smallest"
                        >
                            <Col span="auto">
                                <Text
                                    color={
                                        timeComplete
                                            ? ['purple', 'darker']
                                            : ['gray', 'base']
                                    }
                                    size="base"
                                    align="center"
                                    weight="bold"
                                >
                                    {position}
                                </Text>
                            </Col>
                            {timeComplete && (
                                <Col span="auto">
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faCheck}
                                        color="#242A6B"
                                    />
                                </Col>
                            )}
                        </Row>
                        <div className={classes} />
                    </Col>
                );
            })}
        </Row>
    </Box>
);

const VastPreview = ({ videoUrl, videoDuration }) => {
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const [trackerPositions, setTrackerPositions] = useState([
        { position: positions.FIRST, timeComplete: false },
        { position: positions.FIRST_QUARTILE, timeComplete: false },
        { position: positions.MIDPOINT, timeComplete: false },
        {
            position: positions.THIRD_QUARTILE,
            timeComplete: false,
        },
        { position: positions.COMPLETE, timeComplete: false },
    ]);

    const updateVideoTracker = (key) => {
        if (!key)
            return trackerPositions.map((tp) => ({
                ...tp,
                timeComplete: false,
            }));
        return trackerPositions.map((tp) => {
            if (tp.position === key) return { ...tp, timeComplete: true };
            return tp;
        });
    };

    const handleTimeUpdate = (currentTime) => {
        if (isVideoEnded) setTrackerPositions(updateVideoTracker(null));

        const durationInSeconds = videoDuration
            .split(':')
            .reduce((acc, time) => 60 * acc + +time);

        const first = durationInSeconds / 5;
        const firstQuartile = first + durationInSeconds / 4;
        const midpoint = durationInSeconds / 2;
        const thirdQuartile = first + midpoint;
        const complete = durationInSeconds - 2;

        if (first < currentTime)
            setTrackerPositions(updateVideoTracker(positions.FIRST));

        if (firstQuartile < currentTime)
            setTrackerPositions(updateVideoTracker(positions.FIRST_QUARTILE));

        if (midpoint < currentTime)
            setTrackerPositions(updateVideoTracker(positions.MIDPOINT));

        if (thirdQuartile < currentTime)
            setTrackerPositions(updateVideoTracker(positions.THIRD_QUARTILE));

        if (complete < currentTime) {
            setTrackerPositions(updateVideoTracker(positions.COMPLETE));
            setIsVideoEnded(true);
        }
    };

    return (
        <Box padding={['base', 0, 0]}>
            <Box padding={[0, 0, 'small']}>
                <Text color={['gray', 'black']} size="large" weight="bold">
                    Preview
                </Text>
            </Box>
            <Video
                squarePlayer
                className={styles['video-player']}
                src={videoUrl}
                onTimeUpdate={handleTimeUpdate}
            />
            <Tracker trackerPositions={trackerPositions} />
        </Box>
    );
};

export default VastPreview;
