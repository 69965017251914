import React, { useEffect } from 'react';
import { setDocumentTitle } from 'js/utils';
import { withAdvertiserList } from 'js/hocs';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import AdvertiserListBody from './components/advertiser-list-body';

function AdvertiserListPage({
    loading,
    errors,
    readOnly,
    advertisers,
    ordering,
    togglingFavoriteError,
    onToggleFavoriteAdvertiser,
    onUpdateFilter,
    onDeleteAdvertiser,
}) {
    useEffect(() => {
        setDocumentTitle(['Contexts']);
    }, []);

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb key="advertisers" label="Advertisers" />,
                    ]}
                />
            }
        >
            <AdvertiserListBody
                errors={errors}
                loading={loading}
                readOnly={readOnly}
                advertisers={advertisers}
                ordering={ordering}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavoriteAdvertiser={onToggleFavoriteAdvertiser}
                onUpdateFilter={onUpdateFilter}
                onDeleteAdvertiser={onDeleteAdvertiser}
            />
        </Layout>
    );
}

export default withAdvertiserList(AdvertiserListPage);
