import React from 'react';
import { daysOfTheWeek } from 'js/constants';
import { capitalize } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Text from 'js/components/text/text';

const WeekCalendar = ({ selectedDay, onChange }) => (
    <Box
        borderLeft={['thick', 'solid', 'gray', 'lightest']}
        margin={['small', 0, 0, 'base']}
        padding="base"
    >
        <Text size="large" weight="bold">
            On which day of the week?
        </Text>

        <Box display="flex" margin={['small', 0, 'base']}>
            {daysOfTheWeek.map((day) => (
                <Box key={day} margin={['small', 'small', 'small', 0]}>
                    <Button
                        square
                        className="calendar"
                        active={day === selectedDay}
                        onClick={() => onChange(day)}
                    >
                        {capitalize(day.slice(0, 2))}
                    </Button>
                </Box>
            ))}
        </Box>

        <Text size="small" color={['gray', 'dark']}>
            <Text weight="bold" inline>
                Snapshots can sometimes take over 24 hours to generate.
            </Text>{' '}
            If you need a report for Monday, we recommend that you schedule a
            snapshot to be generated on Saturday or Sunday.
        </Text>
    </Box>
);

export default WeekCalendar;
