import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ActionBar, { Divider } from 'js/components/action-bar/action-bar';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import FavoritesButton from 'js/components/button/favorites-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Select from 'js/components/select/select';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';

const ReportSelector = ({
    reports,
    selectedReport,
    hasProcessingReport,
    onSelect,
}) => {
    const currentYear = moment().year();
    const reportDates = ({ start_date: startDate, end_date: endDate }) => {
        const formatDate = (date) =>
            moment(date).format(
                `Do MMM${moment(date).year() !== currentYear ? ' YYYY' : ''}`,
            );
        return `${formatDate(startDate)} – ${formatDate(endDate)}`;
    };

    return (
        <Select
            theme="filter"
            width="fixed"
            prefix="Snapshot"
            header={
                hasProcessingReport && (
                    <Box padding={['small', 'small', 0]}>
                        <Row gutter="small">
                            <Col span="auto">
                                <Spinner />
                            </Col>
                            <Col>
                                <Text color={['gray', 'dark']}>
                                    {`${
                                        reports.length ? 'A new' : 'This'
                                    } snapshot is currently being generated.`}
                                </Text>
                            </Col>
                        </Row>
                    </Box>
                )
            }
            footer={
                !hasProcessingReport &&
                reports.length === 1 && (
                    <Box padding={[0, 'small', 'small']}>
                        <Text color={['gray', 'dark']}>
                            This report only has a single snapshot. To create
                            additional snapshots, click the ‘Create New
                            Snapshot’ button.
                        </Text>
                    </Box>
                )
            }
            options={reports.map((report) => ({
                label: reportDates(report),
                value: report.id,
            }))}
            selectedValues={selectedReport?.id}
            onChange={onSelect}
        >
            {selectedReport ? reportDates(selectedReport) : <Spinner />}
        </Select>
    );
};

const ActionButtons = ({
    template: {
        id: templateId,
        advertiser: advertiserId,
        is_favourite: isFavorite,
        is_ready: isReady,
    },
    report,
    onSwitchReport,
    reports,
    onDelete,
    onSnapshot,
    onToggleFavorite,
}) => {
    const history = useHistory();
    const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);

    return (
        <ActionBar>
            <FavoritesButton
                key="favorite"
                isFavorite={isFavorite}
                isLoading={isFavoriteLoading}
                onToggleFavorite={async () => {
                    setIsFavoriteLoading(true);
                    try {
                        await onToggleFavorite();
                    } finally {
                        setIsFavoriteLoading(false);
                    }
                }}
            />

            <Button
                to={`/insights/${advertiserId}/${templateId}/`}
                theme="outline"
            >
                Edit
            </Button>

            <Button onClick={onDelete} theme="outline">
                Delete
            </Button>

            <Divider />

            <ReportSelector
                reports={reports}
                selectedReport={report}
                hasProcessingReport={!isReady}
                onSelect={(reportId) => {
                    history.replace(
                        `/insights/event-log/${templateId}/${
                            reportId === reports[0].id ? '' : `${reportId}/`
                        }`,
                    );
                    onSwitchReport(reportId || reports[0].id);
                }}
            />

            <Button onClick={onSnapshot} theme="outline">
                Create New Snapshot
            </Button>
        </ActionBar>
    );
};

export default ActionButtons;
