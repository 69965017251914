import React from 'react';
import classNames from 'classnames/bind';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import SwitchButton from 'js/components/button/switch-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import styles from './topic-selector.module.scss';

const cx = classNames.bind(styles);

const TopicName = ({ active, isUsedInAnotherGroup, info, children }) => {
    const classes = cx({
        'topic-name': true,
        active,
    });

    return (
        <div className={classes}>
            <Row justifyContent="space-between" alignItems="center">
                <Col span="auto">
                    <Box display="grid">
                        <div className={styles.label}>
                            <Text inline weight="bold">
                                {children}
                            </Text>

                            {isUsedInAnotherGroup && (
                                <span> — Used in another group</span>
                            )}
                        </div>
                    </Box>
                </Col>

                {info && (
                    <Col span="auto">
                        <InfoButton fluidTooltip>
                            <Box padding="small">
                                <div className={styles['tooltip-image']}>
                                    <img src={info} alt={children} />
                                </div>
                            </Box>
                        </InfoButton>
                    </Col>
                )}
            </Row>
        </div>
    );
};

function Topic({
    itemType,
    topic,
    rules,
    ruleKey,
    targetedItems,
    blockedItems,
    onTarget,
    onBlock,
}) {
    const isTargetedItem = targetedItems.includes(topic.id);
    const isBlockedItem = blockedItems.includes(topic.id);

    const isUsedInAnotherGroup =
        typeof ruleKey === 'number'
            ? rules
                  .filter((rule) => rule.key !== ruleKey)
                  .map((rule) => rule[itemType])
                  .flat()
                  .includes(topic.id)
            : false;

    return (
        <Box margin={['small', 0]} key={topic.id}>
            <Row justifyContent="center" alignItems="center" gutter="small">
                <Col>
                    <TopicName
                        active={isTargetedItem || isBlockedItem}
                        isUsedInAnotherGroup={isUsedInAnotherGroup}
                        info={topic.image}
                    >
                        {topic.name}
                    </TopicName>
                </Col>

                {!isUsedInAnotherGroup && !topic.is_brand_safety && onTarget && (
                    <Col span="auto">
                        <SwitchButton
                            theme="outline"
                            active={isTargetedItem}
                            onChange={() => onTarget(topic.id)}
                        >
                            Target
                        </SwitchButton>
                    </Col>
                )}

                {!isUsedInAnotherGroup && onBlock && (
                    <Col span="auto">
                        <SwitchButton
                            theme="outline"
                            active={isBlockedItem}
                            onChange={() => onBlock(topic.id)}
                        >
                            Block
                        </SwitchButton>
                    </Col>
                )}
            </Row>
        </Box>
    );
}

export default Topic;
