// eslint-disable-next-line import/no-unused-modules
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './scss/main.scss';
import App from './App';
import history from './js/history';
import Toaster from './js/components/toast/toaster';
import * as serviceWorker from './serviceWorker';
import 'array-flat-polyfill';

ReactDOM.render(
    <StrictMode>
        <Router history={history}>
            <App />
            <Toaster />
        </Router>
    </StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
