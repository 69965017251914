import React from 'react';
import Box from 'js/components/box/box';
import CardAdvertiser from 'js/components/card/card-advertiser';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import Container from 'js/components/container/container';

const FavoriteAdvertisers = ({ advertisers, onDelete, onToggleFavorite }) => (
    <Container>
        <Box padding={['large', 0, 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    <Text weight="bold" size="large">
                        Favorites
                    </Text>

                    {advertisers.map((advertiser) => (
                        <Box padding={['small', 0, 0]} key={advertiser.id}>
                            <CardAdvertiser
                                isSlim
                                advertiser={advertiser}
                                onDeleteAdvertiser={() =>
                                    onDelete(advertiser.id)
                                }
                                onToggleFavorite={() =>
                                    onToggleFavorite(advertiser.id)
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Panel>
        </Box>
    </Container>
);

export default FavoriteAdvertisers;
