import React from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import DatePicker from './date-picker';
import styles from './date-picker.module.scss';

const cx = classNames.bind(styles);

function DateRangePicker({
    maxDate,
    minDate,
    startDate,
    endDate,
    hasError,
    errorMessage,
    hideUnavailableDates,
    onStartDateChange,
    onEndDateChange,
}) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const formatDate = (date) => date.format('D MMMM YYYY');

    const containerClasses = cx({
        'date-range-container': true,
        'has-error': hasError,
    });

    return (
        <>
            <div className={containerClasses}>
                <div className={cx('date-container', 'start-date-container')}>
                    <div>
                        <Text>Start Date</Text>

                        <Text weight="bolder" size="large">
                            <p>{formatDate(startDateMoment)}</p>
                        </Text>

                        <DatePicker
                            maxDate={maxDate}
                            minDate={minDate}
                            rangeDate={endDate}
                            selectedDate={startDate}
                            hideUnavailableDates={hideUnavailableDates}
                            onDateSelected={(date) => {
                                onStartDateChange(date);
                                if (moment(date).isAfter(endDateMoment)) {
                                    onEndDateChange(date);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={cx('date-container', 'end-date-container')}>
                    <div>
                        <Text>End Date</Text>

                        <Text weight="bolder" size="large">
                            <p>{formatDate(endDateMoment)}</p>
                        </Text>

                        <DatePicker
                            maxDate={maxDate}
                            minDate={minDate}
                            rangeDate={startDate}
                            selectedDate={endDate}
                            hideUnavailableDates={hideUnavailableDates}
                            onDateSelected={(date) => {
                                onEndDateChange(date);
                                if (moment(date).isBefore(startDateMoment)) {
                                    onStartDateChange(date);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {errorMessage && hasError && (
                <Box margin={['small', 0, 0]}>
                    <Text color={['red', 'dark']}>{errorMessage}</Text>
                </Box>
            )}
        </>
    );
}

DateRangePicker.defaultProps = {
    maxDate: undefined,
    minDate: undefined,
    hasError: false,
    errorMessage: undefined,
    hideUnavailableDates: false,
};

DateRangePicker.propTypes = {
    maxDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    minDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]).isRequired,
    endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
        .isRequired,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.node,
    hideUnavailableDates: PropTypes.bool,
    onStartDateChange: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
};

export default DateRangePicker;
