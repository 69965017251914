import ruleTypes from 'js/enums/rule-types.enum';
import Rule from './rule';
import { dedupe, deepCopy, deepFreeze } from './utils';

class RuleSet {
    #rules;

    constructor(rules = []) {
        this.#rules = rules.map((rule) => new Rule(rule));
        this.#checkRules();

        Object.values(ruleTypes).forEach((aggregation) => {
            if (!this.#rules.some((rule) => rule.aggregation === aggregation)) {
                this.#rules.push(new Rule({ aggregation }));
            }
        });
    }

    get rules() {
        return deepFreeze(deepCopy(this.#rules));
    }

    toJSON() {
        return this.#rules;
    }

    #checkRules = () => {
        const keys = this.#rules.map(({ key }) => key);
        const dupeKey = keys.find(
            (key, index) => keys.lastIndexOf(key) !== index,
        );
        if (dupeKey) {
            throw new Error(`Duplicate rule key: ${dupeKey}`);
        }

        const orRules = this.#rules.filter(
            (r) => r.aggregation === ruleTypes.INCLUDED,
        );
        const notRules = this.#rules.filter(
            (r) => r.aggregation === ruleTypes.EXCLUDED,
        );
        if (orRules.length > 1 && notRules.length > 1) {
            throw new Error(
                `Too many OR (${orRules.length}) or NOT (${notRules.length}) rules`,
            );
        }
    };

    addRule({
        aggregation = ruleTypes.INCLUDED,
        keywords = [],
        topics = [],
        logos = [],
    } = {}) {
        const rule = new Rule({ aggregation });
        this.#rules.push(rule);
        try {
            this.#checkRules();
        } catch (error) {
            this.#rules.pop();
            throw error;
        }
        this.changeRule(rule.key, {
            addKeywords: keywords,
            addTopics: topics,
            addLogos: logos,
        });

        return rule.key;
    }

    removeRule(ruleKey) {
        const ruleIndex = this.#rules.findIndex(({ key }) => key === ruleKey);
        if (ruleIndex < 0) {
            throw new Error(`Invalid rule key: ${ruleKey}`);
        }
        const { aggregation } = this.#rules[ruleIndex];

        this.#rules.splice(ruleIndex, 1);

        if (!this.#rules.some((rule) => rule.aggregation === aggregation)) {
            this.#rules.push(new Rule({ aggregation }));
        }
    }

    changeRule(
        ruleKey,
        {
            addKeywords = [],
            removeKeywords = [],
            addTopics = [],
            removeTopics = [],
            addLogos = [],
            removeLogos = [],
        },
    ) {
        const rule = this.#rules.find(({ key }) => key === ruleKey);
        if (!rule) {
            throw new Error(`Invalid rule key: ${ruleKey}`);
        }

        const removeItems = (field, items) => {
            if (!items.length) return;

            rule[field] = rule[field].filter(
                (item) => !Rule.cleanItems(field, items).includes(item),
            );
        };

        const addItems = (field, items) => {
            if (!items.length) return;

            rule[field] = dedupe([
                ...Rule.cleanItems(field, items),
                ...rule[field],
            ]);

            const oppositeRules = this.#rules.filter(
                ({ aggregation }) => aggregation !== rule.aggregation,
            );
            oppositeRules.forEach((r) => {
                // eslint-disable-next-line no-param-reassign
                r[field] = r[field].filter(
                    (item) => !Rule.cleanItems(field, items).includes(item),
                );
            });
        };

        removeItems('keywords', removeKeywords);
        addItems('keywords', addKeywords);
        removeItems('topics', removeTopics);
        addItems('topics', addTopics);
        removeItems('logos', removeLogos);
        addItems('logos', addLogos);
    }

    changeAllRules(changeRuleArgs) {
        this.#rules.forEach((rule) =>
            this.changeRule(rule.key, changeRuleArgs),
        );
    }
}

export default RuleSet;
