import React from 'react';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import ReportSample from './images/report-sample.png';
import styles from './pdf-download-panel.module.scss';

function PdfDownloadPanel({ onDownload }) {
    return (
        <Box background={['purple', 'lightest']}>
            <Container>
                <div className={styles.container}>
                    <div className={styles['image-wrapper']}>
                        <img
                            src={ReportSample}
                            className={styles.image}
                            alt="Report Sample"
                        />
                    </div>

                    <div>
                        <Text size="huge" color={['purple', 'darker']}>
                            Sharing insights is easier than ever with our PDF
                            export
                        </Text>

                        <Box margin={['base', 0]}>
                            <Text size="large" color={['purple', 'darker']}>
                                Export a PDF report with your campaign insights.
                                Available in either Portrait (A4) mode for easy
                                printing or Landscape (16 x 9) mode to make
                                slideshows a breeze.
                            </Text>
                        </Box>

                        <Row>
                            <Col span="auto">
                                <Button onClick={onDownload}>
                                    Download Report
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </Box>
    );
}

export default PdfDownloadPanel;
