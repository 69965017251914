import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import {
    api,
    ApiError,
    getJwtPayload,
    reloadPage,
    setDocumentTitle,
} from 'js/utils';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';
import Login from './login';

const PROMOS_FETCH_INIT = 'PROMOS_FETCH_INIT';
const PROMOS_FETCH_SUCCESS = 'PROMOS_FETCH_SUCCESS';
const PROMOS_FETCH_FAILURE = 'PROMOS_FETCH_FAILURE';

const promosReducer = (state, action) => {
    switch (action.type) {
        case PROMOS_FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        case PROMOS_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                promos: action.payload,
            };
        case PROMOS_FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        default:
            throw new Error();
    }
};

export default function LoginPage() {
    const [promosState, promosDispatch] = useReducer(promosReducer, {
        isLoading: true,
        hasError: false,
        promos: [],
    });
    const [randomPromo, setRandomPromo] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setLoggingIn] = useState(false);
    const [isAmending, setAmending] = useState(false);
    const [loginErrors, setLoginErrors] = useState({});

    const selectedPromo = useRef();

    const loadLoginPromos = useCallback(async () => {
        promosDispatch({ type: PROMOS_FETCH_INIT });

        try {
            const { results } = await api().loginPromos.list();

            promosDispatch({
                type: PROMOS_FETCH_SUCCESS,
                payload: results,
            });
        } catch (err) {
            promosDispatch({ type: PROMOS_FETCH_FAILURE });
        }
    }, []);

    const updateEmail = (value) => {
        if (isAmending && !value) {
            loginErrors.email = true;
        } else {
            delete loginErrors.email;
        }

        setEmail(value);
        setLoginErrors(loginErrors);
    };

    const updatePassword = (value) => {
        if (isAmending && !value) {
            loginErrors.password = true;
        } else {
            delete loginErrors.password;
        }

        setPassword(value);
        setLoginErrors(loginErrors);
    };

    const loginUser = async () => {
        if (!email || !password) {
            setLoginErrors({ email: !email, password: !password });
            setAmending(true);
            return;
        }

        setLoggingIn(true);

        try {
            const { token } = await api().login(email, password);
            const { roles = [], is_superuser: isSuperuser } =
                getJwtPayload(token);
            if (roles.length || isSuperuser) {
                localStorage.setItem('AuthToken', token);
                await reloadPage();
            } else {
                // TODO: login form counterpart (in login.js)?
                throw new Error({
                    detail: `You do not have permission to use the 4D interface. 
                        Your user profile is restricted to API access only.`,
                });
            }
        } catch (err) {
            // TODO: redesign the error system so the original message is kept and we can recover the error message.
            if (err instanceof ApiError) {
                setLoginErrors({
                    detail: (
                        <>
                            Your account has been temporarily blocked for too
                            many failed password attempts. Please wait 15
                            minutes before trying again or contact{' '}
                            <SupportEmailLink /> for support
                        </>
                    ),
                });
            } else {
                setLoginErrors(err);
            }
            setLoggingIn(false);
            setAmending(true);
        }
    };

    useEffect(() => {
        // So that the selected promo does not change with each re-render
        if (!selectedPromo.current) {
            selectedPromo.current =
                promosState.promos[
                    Math.floor(Math.random() * promosState.promos.length)
                ];

            setRandomPromo(selectedPromo.current);
        }
    }, [promosState.promos]);

    useLayoutEffect(() => {
        setDocumentTitle(['Login']);
        loadLoginPromos();

        return () => {
            api().abortAll();
        };
    }, [loadLoginPromos]);

    return (
        <Login
            isLoggingIn={isLoggingIn}
            loginErrors={loginErrors}
            randomPromo={randomPromo}
            onUpdateEmail={updateEmail}
            onUpdatePassword={updatePassword}
            onLoginUser={loginUser}
        />
    );
}
