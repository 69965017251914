import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MIN_PASSWORD_LENGTH } from 'js/constants';
import { api, reloadPage, setDocumentTitle } from 'js/utils';
import SetPassword from './set-password';

function SetPasswordPage({ isNewUser }) {
    const params = useParams();
    const history = useHistory();

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isAmending, setAmending] = useState(false);
    const [errors, setErrors] = useState({});

    const getLinkProperties = () => {
        const components = unescape(atob(params.token)).split('#');

        return {
            created: components.shift(),
            token: components.shift(),
            email: components.join('#'),
        };
    };

    const checkInvalid = () => {
        const { token } = errors;

        if (token) return true;

        try {
            const { created } = getLinkProperties();
            const createdDate = new Date(created);
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return createdDate < yesterday;
        } catch (err) {
            return true;
        }
    };

    const setPassword = (password) => {
        delete errors.new_password1;
        delete errors.new_password2;

        setNewPassword(password);
        setErrors(errors);
    };

    const setPasswordConfirmation = (password) => {
        delete errors.new_password2;

        setNewPasswordConfirmation(password);
        setErrors(errors);
    };

    const validatePasswords = useCallback(
        ({ throwErrors = false } = {}) => {
            setAmending(true);

            const updatedErrors = {};

            if (!newPassword) {
                updatedErrors.new_password1 = 'A new password is required.';
            } else if (newPassword.length < MIN_PASSWORD_LENGTH) {
                updatedErrors.new_password1 = 'Your new password is too short.';
            } else if (!newPasswordConfirmation) {
                updatedErrors.new_password2 =
                    'Password confirmation is required.';
            } else if (newPassword !== newPasswordConfirmation) {
                updatedErrors.new_password2 =
                    'Your password confirmation does not match your new password.';
            }

            if (updatedErrors.new_password1 || updatedErrors.new_password2) {
                setErrors((prevState) => ({
                    ...prevState,
                    ...updatedErrors,
                }));

                if (throwErrors && Object.keys(updatedErrors).length) {
                    throw updatedErrors;
                }
            }
        },
        [newPassword, newPasswordConfirmation],
    );

    const updatePassword = async () => {
        setLoading(true);

        try {
            validatePasswords({ throwErrors: true });
            const { token, email } = getLinkProperties();

            await api().confirmPasswordReset(
                token,
                email,
                newPassword,
                newPasswordConfirmation,
            );
            const { token: authToken } = await api().login(email, newPassword);
            localStorage.setItem('AuthToken', authToken);

            if (isNewUser) {
                await history.push('/');
            } else {
                await reloadPage();
            }
        } catch (err) {
            setErrors(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAmending) validatePasswords();
    }, [isAmending, validatePasswords]);

    useLayoutEffect(() => {
        setDocumentTitle(['Set Password']);

        return () => {
            api().abortAll();
        };
    }, []);

    return (
        <SetPassword
            password={newPassword}
            isNewUser={isNewUser}
            isLoading={isLoading}
            errors={errors}
            isLinkInvalid={checkInvalid()}
            setPassword={setPassword}
            setPasswordConfirmation={setPasswordConfirmation}
            updatePassword={updatePassword}
        />
    );
}

export default SetPasswordPage;
