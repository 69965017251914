import React from 'react';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import SectionTitle from 'js/components/section-title/section-title';
import Text from 'js/components/text/text';

export default function SectionWrapper({ title, description, children }) {
    return (
        <Box padding={['large', 0]}>
            <Container>
                <Box margin={[0, 0, 'large']}>
                    <SectionTitle hasBar info={description}>
                        <Text weight="base" size="huge">
                            {title}
                        </Text>
                    </SectionTitle>
                </Box>

                {children}
            </Container>
        </Box>
    );
}
