import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './dropdown-menu.module.scss';

const cx = classNames.bind(styles);

const Wrapper = ({ to, onClick, children }) => {
    if (onClick) {
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div className={styles['item-content']} onClick={onClick}>
                {children}
            </div>
        );
    }

    if (to && to.startsWith('/')) {
        return (
            <Link className={styles['item-content']} to={to}>
                {children}
            </Link>
        );
    }

    return (
        <a
            className={styles['item-content']}
            href={to}
            rel="noopener noreferrer"
            target="_blank"
        >
            {children}
        </a>
    );
};

function DropdownLink({ children, to, onClick, hint, isShowMore, tooltip }) {
    const classes = cx({
        item: true,
        link: true,
        'link--show-more': isShowMore,
    });

    return (
        <li className={classNames(classes)}>
            <Wrapper to={to} onClick={onClick}>
                <>
                    <div className={styles.label}>{children}</div>

                    {hint && <div className={styles['item-hint']}>{hint}</div>}
                </>
            </Wrapper>

            {tooltip && <div className={styles.tooltip}>{tooltip}</div>}
        </li>
    );
}

DropdownLink.defaultProps = {
    to: undefined,
    onClick: undefined,
    hint: undefined,
    isShowMore: false,
    tooltip: undefined,
};

DropdownLink.propTypes = {
    /**
     * The content of the DropdownLink
     */
    children: PropTypes.node.isRequired,
    /**
     * The target url to navigate to on click
     */
    to: PropTypes.string,
    /**
     * An onClick callback for the DropdownLink
     */
    onClick: PropTypes.func,
    /**
     * text for the DropdownLink containing extra information
     */
    hint: PropTypes.node,
    /**
     * if true, style the DropdownLink as part of the searchbar
     * This should be used in conjunction with a DropdownMenu with the theme="search" prop
     */
    isShowMore: PropTypes.bool,
    /**
     * A tooltip for the DropdownLink
     * TODO: fix this or remove it
     */
    tooltip: PropTypes.node,
};

export default DropdownLink;
