import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Notification from 'js/components/notification/notification';
import Text from 'js/components/text/text';
import notificationCategories from 'js/enums/notification-categories.enum';
import styles from './notifications-button.module.scss';
import SectionTitle from '../section-title/section-title';

const MAX_VISIBLE_NOTIFICATIONS = 4;

const cx = classNames.bind(styles);

const NotificationsMenu = ({ notifications, onAction }) => {
    const showTooltip = notifications.filter(({ category }) =>
        [
            notificationCategories.DEAL_PAUSED,
            notificationCategories.DEAL_READY,
            notificationCategories.DEAL_UNPAUSED,
            notificationCategories.DEAL_WILL_BE_PAUSED,
        ].includes(category),
    ).length;
    const tooltipText =
        'Your notification lists included an important Deal ID update. Clicking off the panel will clear the list, but they can still be found and reviewed on the notification page.';

    // TODO: The rendering of notifications grouped by category
    // should be simplified once AV-2941 is completed
    const dealIdNotifications = notifications.filter(({ category }) =>
        [
            notificationCategories.DEAL_PAUSED,
            notificationCategories.DEAL_READY,
            notificationCategories.DEAL_UNPAUSED,
            notificationCategories.DEAL_WILL_BE_PAUSED,
        ].includes(category),
    );
    const contextNotifications = notifications.filter(({ category }) =>
        [
            notificationCategories.AUTO_OPTIMIZATION_APPLIED,
            notificationCategories.AUTO_OPTIMIZATION_INCOMPLETE,
            notificationCategories.AUTO_OPTIMIZATION_STOPPED,
        ].includes(category),
    );
    const insightsNotifications = notifications.filter(({ category }) =>
        [
            notificationCategories.OPTIMISE_REPORT_READY,
            notificationCategories.EVENT_LOG_REPORT_READY,
            notificationCategories.CAMPAIGN_INACTIVE,
        ].includes(category),
    );
    const videoInsightsNotifications = notifications.filter(
        ({ category }) =>
            category === notificationCategories.VIDEO_REPORT_READY,
    );

    const renderCategoryNotifications = (title, categoryNotifications) => {
        if (!categoryNotifications.length) return null;
        return (
            <Box margin={['base', 0, 0]}>
                <Text size="large" weight="bold" color={['gray', 'darkest']}>
                    {title}
                </Text>
                {categoryNotifications.map((notification) => (
                    <Box key={notification.id} margin={['small', 0, 'base']}>
                        <Notification
                            notification={notification}
                            onAction={onAction}
                        />
                    </Box>
                ))}
            </Box>
        );
    };

    return (
        <div className={styles.menu}>
            <Box margin={['small', 'base', 0]}>
                <SectionTitle info={showTooltip ? tooltipText : null}>
                    <Text color={['gray', 'darkest']}>Notifications</Text>
                </SectionTitle>
            </Box>
            <Box padding={['smallest', 'base', 0]}>
                {!notifications.length ? (
                    <Box margin={['small', 0, 'base']}>
                        <Text size="large">
                            No new notifications – you’re all caught up
                        </Text>
                    </Box>
                ) : (
                    <>
                        {renderCategoryNotifications(
                            'Deal IDs',
                            dealIdNotifications,
                        )}
                        {renderCategoryNotifications(
                            'Contexts',
                            contextNotifications,
                        )}
                        {renderCategoryNotifications(
                            'Insights',
                            insightsNotifications,
                        )}
                        {renderCategoryNotifications(
                            'Video Insights',
                            videoInsightsNotifications,
                        )}
                    </>
                )}
            </Box>

            <Box display="inline-block" margin={[0, 'base', 'small']}>
                <Button theme="outline" to="/notifications/">
                    See All Notifications
                </Button>
            </Box>
        </div>
    );
};

const CounterButton = ({ count, active, onClick }) => (
    <Button
        theme="outline"
        square
        active={active}
        className={cx({ container: true, active })}
        onClick={onClick}
    >
        <p className={styles['icon-wrapper']}>
            <FontAwesomeIcon
                icon={faBellSolid}
                className={cx('icon', 'hover-show')}
            />
            <FontAwesomeIcon
                icon={faBell}
                className={cx('icon', 'hover-hidden')}
            />
        </p>

        {count > 0 && (
            <div className={styles.counter}>{count > 9 ? '9+' : count}</div>
        )}
    </Button>
);

const NotificationsButton = ({ notifications, active, onSeen }) => {
    const history = useHistory();
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const visibleNotifications = notifications.slice(
        0,
        MAX_VISIBLE_NOTIFICATIONS,
    );

    useEffect(
        () =>
            history.listen((location) => {
                if (isMenuVisible && location.pathname !== '/notifications/') {
                    onSeen();
                }
            }),
        [history, isMenuVisible], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const toggleShowMenu = () => {
        if (isMenuVisible) {
            onSeen();
            setIsMenuVisible(false);
        } else if (!active) {
            setIsMenuVisible(true);
        }
    };

    const hideMenu = () => {
        if (isMenuVisible) {
            onSeen();
            setIsMenuVisible(false);
        }
    };

    return (
        <DropdownMenu
            content={
                <ul>
                    <NotificationsMenu
                        notifications={visibleNotifications}
                        onAction={hideMenu}
                    />
                </ul>
            }
            onHide={hideMenu}
            showMenu={isMenuVisible}
            placement="bottom-start"
        >
            <CounterButton
                active={active || isMenuVisible}
                count={notifications.length}
                onClick={toggleShowMenu}
            />
        </DropdownMenu>
    );
};

NotificationsButton.defaultProps = {
    active: false,
};

NotificationsButton.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    active: PropTypes.bool,
    onSeen: PropTypes.func.isRequired,
};

export default NotificationsButton;
