import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import styles from '../login.module.scss';

const cx = classNames.bind(styles);

function Promo({ promo }) {
    const transitionNode = useRef();

    return (
        <CSSTransition
            nodeRef={transitionNode}
            classNames="fade-scale"
            timeout={500}
            appear
            in
        >
            <div ref={transitionNode} className={cx('promo', 'slower')}>
                <img
                    className={styles.logo}
                    src={promo.logo}
                    alt="Promo logo"
                />

                <Box margin={['base', 0]} marginMd={['large', 0]}>
                    <p className={styles.headline}>{promo.headline}</p>
                </Box>

                <Row>
                    <Col span="auto">
                        <Button to={promo.action_url}>Read Case Study</Button>
                    </Col>
                </Row>
            </div>
        </CSSTransition>
    );
}

export default Promo;
