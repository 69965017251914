import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { getJwtPayload } from 'js/utils';
import searchTypes from 'js/enums/search-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownLink from 'js/components/dropdown-menu/link';
import Title from 'js/components/dropdown-menu/title';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import styles from './search-bar.module.scss';

const MAX_VISIBLE_ITEMS = 3;

const getLinkByType = (type, item) => {
    const links = {
        [searchTypes.ADVERTISERS]: `/context/${item.id}/`,
        [searchTypes.CAMPAIGNS]: `/context/${item.advertiser}/${item.id}/`,
        [searchTypes.DISPLAY_CONTEXTS]: `/contexts/${item.id}/`,
        [searchTypes.VIDEO_CONTEXTS]: `/contexts/${item.id}/`,
        [searchTypes.DEALS]: `/contexts/${item.contextId}/#channels`,
        [searchTypes.DISPLAY_REPORTS]: `/insights/display/${item.id}/`,
        [searchTypes.VIDEO_REPORTS]: `/insights/video/${item.id}/`,
    };

    return links[type];
};

const getMetadataByType = (type, item) => {
    const metadataSeparator = (
        <Text inline weight="base" color={['gray', 'light']}>
            {' '}
            <FontAwesomeIcon size="sm" icon={faChevronRight} />{' '}
        </Text>
    );

    const metadata = {
        [searchTypes.ADVERTISERS]: '',
        [searchTypes.CAMPAIGNS]: (
            <span>
                {metadataSeparator}
                {item.advertiser_name}
            </span>
        ),
        [searchTypes.DISPLAY_CONTEXTS]: (
            <span>
                {metadataSeparator}
                {item.group_name}
                {metadataSeparator}
                {item.advertiser_name}
            </span>
        ),
        [searchTypes.VIDEO_CONTEXTS]: (
            <span>
                {metadataSeparator}
                {item.group_name}
                {metadataSeparator}
                {item.advertiser_name}
            </span>
        ),
        [searchTypes.DEALS]: (
            <span>
                {metadataSeparator}
                {item.context_name}
                {metadataSeparator}
                {item.group_name}
                {metadataSeparator}
                {item.advertiser_name}
            </span>
        ),
        [searchTypes.DISPLAY_REPORTS]: (
            <span>
                {item.context_group_name ? (
                    <>
                        {metadataSeparator} {item.context_group_name}
                    </>
                ) : null}
                {item.advertiser_name ? (
                    <>
                        {metadataSeparator} {item.advertiser_name}
                    </>
                ) : null}
            </span>
        ),
        [searchTypes.VIDEO_REPORTS]: (
            <span>
                {metadataSeparator}
                {item.context_group_name}
                {metadataSeparator}
                {item.advertiser_name}
            </span>
        ),
    };

    return metadata[type];
};

const SearchResultsItem = ({ item, logo, type, onRedirect }) => (
    <DropdownLink onClick={() => onRedirect(type, item)}>
        <div className={styles['label-container']}>
            {logo && <img src={logo} alt="" className={styles.logo} />}
            <div className={styles['label-wrapper']}>
                <div className={styles.label}>
                    {item.name}

                    <Text inline weight="base" color={['gray', 'dark']}>
                        {getMetadataByType(type, item)}
                    </Text>
                </div>
            </div>

            {item.icon && (
                <div className={styles['icon-wrapper']}>{item.icon}</div>
            )}
        </div>
    </DropdownLink>
);

function SearchResultsContent({ results, accounts, onExpandGroup, onClose }) {
    const history = useHistory();

    const redirect = (type, item) => {
        onClose();
        history.push(getLinkByType(type, item));
    };

    const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
    const hasForeignResults = results.some(({ items }) =>
        items.some(({ account }) => account !== jwt.account),
    );

    return (
        <ul>
            {results.map(
                ({ id, isExpanded, items, title }) =>
                    items.length > 0 && (
                        <Fragment key={id}>
                            <Title>
                                <Text inline weight="bolder">
                                    {title}
                                </Text>{' '}
                                ({items.length})
                            </Title>

                            {items.map((item, index) => {
                                if (
                                    isExpanded ||
                                    (!isExpanded && index < MAX_VISIBLE_ITEMS)
                                ) {
                                    return (
                                        <SearchResultsItem
                                            key={item.id}
                                            item={item}
                                            type={id}
                                            logo={
                                                hasForeignResults &&
                                                accounts.find(
                                                    (account) =>
                                                        account.id ===
                                                        item.account,
                                                ).logo
                                            }
                                            onRedirect={redirect}
                                        />
                                    );
                                }

                                return null;
                            })}

                            {!isExpanded && items.length > MAX_VISIBLE_ITEMS && (
                                <DropdownLink
                                    isShowMore
                                    onClick={() => onExpandGroup(id)}
                                >
                                    Show {items.length - MAX_VISIBLE_ITEMS} more{' '}
                                    {title.toLowerCase()}
                                </DropdownLink>
                            )}
                        </Fragment>
                    ),
            )}
        </ul>
    );
}

const SearchResults = forwardRef(
    (
        {
            results,
            accounts,
            isLoading,
            errors,
            showResults,
            onExpandGroup,
            onReloadResults,
            children,
            onClose,
        },
        ref,
    ) => {
        if (errors.searchError) {
            return (
                <DropdownMenu
                    ref={ref}
                    theme="search"
                    placement="bottom-end"
                    content={
                        <div className={styles['error--fatal']}>
                            <p>
                                There was an unexpected error while searching.
                                Please try again in a moment.
                            </p>

                            <div className={styles['action-items__wrapper']}>
                                <div
                                    className={
                                        styles['action-items__container']
                                    }
                                >
                                    <div
                                        className={
                                            styles['action-items__inner']
                                        }
                                    >
                                        <Button onClick={onReloadResults}>
                                            Retry
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    showMenu
                >
                    {children}
                </DropdownMenu>
            );
        }

        if (errors.shortSearch) {
            return (
                <DropdownMenu
                    ref={ref}
                    theme="search"
                    placement="bottom-end"
                    content={
                        <div className={styles.error}>
                            <span>{errors.shortSearch}</span>
                        </div>
                    }
                    showMenu
                >
                    {children}
                </DropdownMenu>
            );
        }

        if (isLoading)
            return (
                <DropdownMenu
                    ref={ref}
                    theme="search"
                    placement="bottom-end"
                    content={
                        <Box padding="small">
                            <Spinner
                                size="large"
                                color={['gray', 'dark']}
                                isCentered
                            />
                        </Box>
                    }
                    showMenu
                >
                    {children}
                </DropdownMenu>
            );

        if (results.length && results.every((group) => !group.items.length)) {
            return (
                <DropdownMenu
                    ref={ref}
                    theme="search"
                    placement="bottom-end"
                    content={
                        <div className={styles.error}>
                            <span>
                                There are no items that match your search.
                            </span>
                        </div>
                    }
                    showMenu={showResults}
                >
                    {children}
                </DropdownMenu>
            );
        }

        return (
            <DropdownMenu
                ref={ref}
                theme="search"
                placement="bottom-end"
                content={
                    <SearchResultsContent
                        results={results}
                        accounts={accounts}
                        onExpandGroup={onExpandGroup}
                        onClose={onClose}
                    />
                }
                showMenu={showResults}
            >
                {children}
            </DropdownMenu>
        );
    },
);

SearchResults.defaultProps = {};

SearchResults.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            isExpanded: PropTypes.bool.isRequired,
            items: PropTypes.array.isRequired,
        }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    showResults: PropTypes.bool.isRequired,
    onExpandGroup: PropTypes.func.isRequired,
    onReloadResults: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

SearchResults.displayName = 'SearchResults';

export default SearchResults;
