const strategyTypes = {
    BROAD: {
        name: 'Broad',
        value: 'broad',
        precision: 2,
        scale: 5,
        infoText:
            'High scale, low precision. Get your advertising in front of more consumers and spend less time building the perfect context.',
    },
    BALANCED: {
        name: 'Balanced',
        value: 'balanced',
        precision: 3,
        scale: 3,
        infoText:
            'Equal balance of precision and scale. The default match strategy to ensure you match the right content without sacrificing scale.',
    },
    FOCUSED: {
        name: 'Focused',
        value: 'focused',
        precision: 5,
        scale: 2,
        infoText:
            'High precision, low scale. The strictest match strategy to give you full control over what your advertising matches.',
    },
};

export default strategyTypes;
