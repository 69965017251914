import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Spinner from 'js/components/spinner/spinner';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';
import Text from 'js/components/text/text';
import styles from './channel-provider.module.scss';
import DealStateActionsButton from './deal-state-actions-button';
import DealStateIcon from './deal-state-icon';

const PausedAlert = ({ isReadOnly, name, onReactivate }) => (
    <Box
        padding="base"
        background={['purple', 'lightest']}
        borderRadius="rounder"
    >
        <Row alignItems="center" gap="base">
            <Col span="auto">
                <Text color={['purple', 'base']} size="large">
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
                </Text>
            </Col>
            <Col>
                <Text color={['purple', 'darker']}>
                    This {name} deal has been paused due to lack of impression
                    activity
                </Text>
            </Col>
            {!isReadOnly && (
                <Col span={12} spanSm="auto">
                    <Button onClick={onReactivate}>Reactivate Deal</Button>
                </Col>
            )}
        </Row>
    </Box>
);

const DealDelayAlert = ({ name, delay }) => (
    <Box
        padding="base"
        background={['purple', 'lightest']}
        borderRadius="rounder"
    >
        <Text color={['purple', 'darker']}>
            You’ll be notified once the <strong>{name}</strong> Deal ID is
            created. Please allow up to {delay} for a deal ID to be generated.
            Questions or concerns, reach out to: <SupportEmailLink />
        </Text>
    </Box>
);

const ChannelProvider = ({
    isReadOnly,
    provider,
    deal,
    isSaved,
    onCreateDeal,
    onActivateDeal,
}) => {
    const isPaused = deal && !deal.is_active;
    const isReady = !!deal?.deal_id;
    const isVideo = provider.is_video;
    const dealDelay =
        (isVideo && '3-5 business days') ||
        (provider.provider_type === 'pubmatic' && '3 days') ||
        '';

    let stateMessage;
    if (deal) {
        if (isReady) {
            stateMessage = (
                <>
                    {provider.name} <strong>{deal.deal_id}</strong>
                </>
            );
        } else {
            stateMessage = isSaved ? (
                <>
                    {provider.name} <Spinner color={['purple', 'base']} />{' '}
                    <strong>Generating</strong>
                </>
            ) : (
                <strong>
                    {provider.name}’s Deal ID will be generated on context save
                </strong>
            );
        }
    } else {
        stateMessage = `You can generate a ${provider.name} ID if required`;
    }

    return (
        <>
            <Row alignItems="center" gutter="small" gap="small">
                <Col span="auto">{stateMessage}</Col>
                <Col>
                    <hr className={styles['line-through']} />
                </Col>
                {!deal ? (
                    <Col span={12} spanSm="auto">
                        <Button theme="outline" onClick={onCreateDeal}>
                            Generate {provider.name} Deal ID
                        </Button>
                    </Col>
                ) : (
                    <>
                        <Col span="auto">
                            <DealStateIcon deal={deal} />
                        </Col>
                        {isReady && (
                            <Col span="auto">
                                <DealStateActionsButton deal={deal} />
                            </Col>
                        )}
                    </>
                )}
            </Row>

            {isPaused && (
                <Box margin={['smaller', 0, 0]}>
                    <PausedAlert
                        isReadOnly={isReadOnly}
                        name={provider.name}
                        onReactivate={() => onActivateDeal(deal)}
                    />
                </Box>
            )}

            {deal && !isReady && dealDelay && (
                <Box margin={['smaller', 0, 0]}>
                    <DealDelayAlert name={provider.name} delay={dealDelay} />
                </Box>
            )}
        </>
    );
};

export default ChannelProvider;
