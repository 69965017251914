import React from 'react';
import Box from 'js/components/box/box';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Button from 'js/components/button/button';

const StatusHero = ({ isLoading, hasError, statusMsg, redirectUrl }) => {
    let statusIcon;
    if (isLoading) {
        statusIcon = <Spinner size="large" />;
    } else if (!isLoading && !hasError) {
        statusIcon = (
            <Button onClick={() => window.location.replace(redirectUrl)}>
                Redirect to DV360
            </Button>
        );
    } else if (!isLoading && hasError) {
        statusIcon = (
            <Text size="huge">
                <FontAwesomeIcon icon={faExclamationCircle} />
            </Text>
        );
    }

    return (
        <Box padding={['large']}>
            <Row justifyContent="center">
                <Col span="auto">
                    <div>
                        <Text size="huge" weight="bold">
                            {statusMsg.title}
                        </Text>
                        <Box margin="base">
                            <Text size="large">{statusMsg.description}</Text>
                        </Box>
                    </div>
                </Col>
            </Row>
            <Row justifyContent="center">
                <Col span="auto">
                    <Box padding={['base', 0]}>{statusIcon}</Box>
                </Col>
            </Row>
        </Box>
    );
};

export default StatusHero;
