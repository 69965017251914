import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

const Button = forwardRef(
    (
        {
            active,
            children,
            className,
            square,
            disabled,
            prefix,
            size,
            suffix,
            tabIndex,
            theme,
            to,
            type,
            hasError,
            ...buttonProps
        },
        ref,
    ) => {
        const classes = cx({
            container: true,
            [theme]: theme,
            [size]: size,
            square,
            active,
            error: hasError,
            [className]: className,
        });

        const content = (
            <span className={styles.inner}>
                {prefix && <span className={styles.prefix}>{prefix}</span>}
                <span className={styles.content}>{children}</span>
                {suffix && <span className={styles.suffix}>{suffix}</span>}
            </span>
        );

        const getTabIndex = () => {
            if (disabled) return '-1';

            return tabIndex;
        };

        if (to) {
            if (to.startsWith('/')) {
                return (
                    <Link className={classNames(classes)} to={to}>
                        {content}
                    </Link>
                );
            }

            return (
                <a
                    className={classNames(classes)}
                    href={to}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {content}
                </a>
            );
        }

        return (
            <button
                {...buttonProps}
                type={type}
                disabled={disabled}
                className={classNames(classes)}
                tabIndex={getTabIndex()}
                ref={ref}
            >
                {content}
            </button>
        );
    },
);

Button.defaultProps = {
    active: false,
    className: undefined,
    square: false,
    disabled: false,
    prefix: undefined,
    size: 'base',
    suffix: undefined,
    tabIndex: undefined,
    theme: 'primary',
    to: undefined,
    type: 'button',
    hasError: false,
};

Button.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    square: PropTypes.bool,
    disabled: PropTypes.bool,
    prefix: PropTypes.node,
    size: PropTypes.oneOf(['base', 'small', 'large', 'smaller']),
    suffix: PropTypes.node,
    tabIndex: PropTypes.string,
    theme: PropTypes.oneOf([
        'primary',
        'outline',
        'dark',
        'light-blue',
        'select',
        'switch',
        'custom',
    ]),
    to: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit']),
    hasError: PropTypes.bool,
};

Button.displayName = 'Button';

export default Button;
