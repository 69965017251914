import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validMimeType } from 'js/utils';

function DropArea({ mimeTypes, onEnter, onLeave, onDrop, children }) {
    let enterTarget;
    const [isActive, setIsActive] = useState(false);

    const handleEnter = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        const { items } = ev.dataTransfer;
        const { kind, type } = items[0];
        const isAllowedType = validMimeType(type, mimeTypes);
        if (items.length !== 1 || kind !== 'file' || !isAllowedType) return;

        if (onEnter) onEnter(ev);
        enterTarget = ev.target;
        setIsActive(true);
    };

    const handleLeave = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (enterTarget === ev.target) {
            if (onLeave) onLeave(ev);
            enterTarget = null;
            setIsActive(false);
        }
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        const { files } = ev.dataTransfer;
        if (!isActive || files.length !== 1) return;

        if (onDrop) onDrop(files[0]);
        enterTarget = null;
        setIsActive(false);
    };

    return (
        <div
            onDragEnter={handleEnter}
            onDragOver={handleEnter}
            onDragLeave={handleLeave}
            onDrop={handleDrop}
        >
            {children}
        </div>
    );
}

DropArea.defaultProps = {
    mimeTypes: [],
    onEnter: undefined,
    onLeave: undefined,
    onDrop: undefined,
};

DropArea.propTypes = {
    /**
     * A list of accepted mimetypes for the files uploaded via the DropArea
     */
    mimeTypes: PropTypes.array,
    /**
     * Callback for when the user enters the DropArea
     */
    onEnter: PropTypes.func,
    /**
     * Callback for when the user leaves the DropArea
     */
    onLeave: PropTypes.func,
    /**
     * Callback for when the user submits a file in the DropArea
     */
    onDrop: PropTypes.func,
    /**
     * The child content of the dropArea
     */
    children: PropTypes.node.isRequired,
};

export default DropArea;
