import React, { useEffect, useState } from 'react';
import Panel from 'js/components/panel/panel';
import Box from 'js/components/box/box';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import ColoredLine from 'js/components/trend-chart/colored-line';
import Select from 'js/components/select/select';
import TrendChart from 'js/components/trend-chart/trend-chart';
import Tooltip from 'js/components/trend-chart/tooltip';

const metricColors = {
    left: '#3040E8',
    right: '#1CBEC9',
};

const ChartPanel = ({ metrics, auditData }) => {
    const visibleMetrics = metrics.filter((item) => !item.hidden);

    const [leftMetricId, setLeftMetricId] = useState('impressions');
    const [rightMetricId, setRightMetricId] = useState('ctr');

    useEffect(() => {
        if (!metrics.find(({ id }) => id === leftMetricId)) {
            setLeftMetricId('impressions');
        }
        if (!metrics.find(({ id }) => id === rightMetricId)) {
            setRightMetricId('ctr');
        }
    }, [metrics]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectOptions = visibleMetrics.map(({ name, id }) => ({
        label: name,
        value: id,
    }));

    const reportSeries = visibleMetrics.map((metric) => ({
        ...metric,
        color: {
            [leftMetricId]: metricColors.left,
            [rightMetricId]: metricColors.right,
        }[metric.id],
        isSkinny: metric.id === rightMetricId,
        axis: { [leftMetricId]: 'left', [rightMetricId]: 'right' }[metric.id],
        data: auditData.map((j) => ({
            date: j.activity_date,
            value: metric.getValue(j),
        })),
    }));

    return (
        <Panel>
            <Box padding="base">
                <Row justifyContent="space-between">
                    <Col span="auto">
                        <Select
                            options={selectOptions.filter(
                                (option) => option.value !== rightMetricId,
                            )}
                            selectedValues={leftMetricId}
                            onChange={setLeftMetricId}
                        >
                            <Box display="flex">
                                <ColoredLine
                                    color={metricColors.left}
                                    height={4}
                                />
                                {
                                    selectOptions.find(
                                        (option) =>
                                            option.value === leftMetricId,
                                    )?.label
                                }
                            </Box>
                        </Select>
                    </Col>

                    <Col span="auto">
                        <Select
                            options={selectOptions.filter(
                                (option) => option.value !== leftMetricId,
                            )}
                            selectedValues={rightMetricId}
                            onChange={setRightMetricId}
                        >
                            <Box display="flex">
                                <ColoredLine
                                    color={metricColors.right}
                                    height={2}
                                />
                                {
                                    selectOptions.find(
                                        (option) =>
                                            option.value === rightMetricId,
                                    )?.label
                                }
                            </Box>
                        </Select>
                    </Col>
                </Row>
                <div style={{ height: 400 }}>
                    <TrendChart series={reportSeries} tooltip={<Tooltip />} />
                </div>
            </Box>
        </Panel>
    );
};

export default ChartPanel;
