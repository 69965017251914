import React from 'react';
import PropTypes from 'prop-types';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import Button from 'js/components/button/button';
import SVGImage from 'js/components/svg-image/svg-image';
import styles from './topic-button.module.scss';

const cx = classNames.bind(styles);

function TopicButton({
    theme,
    logo,
    active,
    action,
    isReadOnly,
    children,
    onClick,
}) {
    const classes = cx({
        container: true,
        [theme]: theme,
        active,
        'read-only': isReadOnly,
    });

    let suffix = null;
    switch (action) {
        case 'add':
            suffix = <FontAwesomeIcon icon={faPlus} />;
            break;
        case 'remove':
            suffix = <FontAwesomeIcon icon={faTimes} />;
            break;
        default:
            suffix = null;
    }

    return (
        <Button
            theme="custom"
            square
            prefix={
                logo && (
                    <div className={styles['topic-logo']}>
                        <SVGImage src={logo} />
                    </div>
                )
            }
            suffix={suffix}
            className={classNames(classes)}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}

TopicButton.defaultProps = {
    theme: 'default',
    logo: '',
    active: false,
    action: null,
    isReadOnly: false,
    onClick: undefined,
};

TopicButton.propTypes = {
    theme: PropTypes.oneOf([
        'default',
        'targeted',
        'blocked',
        'muted',
        'blue',
        'blue-outline',
    ]),
    logo: PropTypes.string,
    active: PropTypes.bool,
    action: PropTypes.oneOf([null, 'add', 'remove']),
    isReadOnly: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

export default TopicButton;
