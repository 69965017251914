import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'js/components/spinner/spinner';
import CardBase from './card-base';

function CardLoading({ loadingText }) {
    return (
        <CardBase type="loading">
            <Spinner
                size="large"
                color={['gray', 'dark']}
                message={loadingText}
            />
        </CardBase>
    );
}

CardLoading.defaultProps = {
    loadingText: undefined,
};

CardLoading.propTypes = {
    loadingText: PropTypes.node,
};

export default CardLoading;
