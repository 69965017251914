import * as d3 from 'd3';
import { useResizeObserver } from 'js/hooks';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { everyNth, parseDate, valueFormat } from './helpers';
import styles from './trend-chart.module.scss';
import LineSeries from './components/line-series';
import Translate from './components/translate';
import { ChartAxis, ChartGrid } from './components/axis';
import { TooltipLayer } from './tooltip';

import ColoredLine from './colored-line';

const ChartWrapper = ({ children, wrapperRef }) => (
    <div className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.container}>{children}</div>
    </div>
);

const TrendChart = ({ series, XAxisWidth, YAxisHeight, tooltip }) => {
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!dimensions) return;
        // getting dimensions width height dynamically
        setWidth(dimensions.width);
        setHeight(dimensions.height);
    }, [dimensions]);

    if (!series.length) return <ChartWrapper wrapperRef={wrapperRef} />;

    const [start, end] = d3.extent(
        series.map((i) => i.data.map((d) => parseDate(d.date))).flat(),
    );
    const allDates = [];
    for (
        const dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
    ) {
        allDates.push(d3.timeFormat('%Y-%m-%d')(new Date(dt)));
    }

    const completeSeries = series.map((s) => ({
        ...s,
        color: s.color || 'transparent',
        legend: (
            <ColoredLine
                color={s.color || 'transparent'}
                isSkinny={s.isSkinny}
            />
        ),
        data: allDates.map((a) => {
            const original = s.data.find((d) => d.date === a);
            return {
                date: a,
                value: original?.value || 0,
                missing: !original,
                formattedValue: valueFormat(original?.value || 0, s.format),
            };
        }),
    }));

    const leftSeries = completeSeries.filter((s) => s.axis === 'left');
    const rightSeries = completeSeries.filter((s) => s.axis === 'right');

    const leftScale = leftSeries.length
        ? d3
              .scaleLinear()
              .domain(
                  d3.extent([
                      0,
                      0,
                      ...leftSeries
                          .map((s) => s.data.map((i) => i.value))
                          .flat(),
                  ]),
              )
              .nice(2)
              .range([height - 2 * YAxisHeight, 0])
        : undefined;

    const rightScale = rightSeries.length
        ? d3
              .scaleLinear()
              .domain(
                  d3.extent([
                      0,
                      0,
                      ...rightSeries
                          .map((s) => s.data.map((i) => i.value))
                          .flat(),
                  ]),
              )
              .nice(2)
              .range([height - 2 * YAxisHeight, 0])
        : undefined;

    const dateScale = d3
        .scaleTime()
        .domain(d3.extent(allDates.map(parseDate)))
        .range([0, width - 2 * XAxisWidth]);

    const bottomAxisTickInterval = Math.max(
        parseInt(allDates.length / ((width - 2 * XAxisWidth + 1) / 100), 10),
        1,
    );

    const axisBottomFactory = () =>
        d3
            .axisBottom(dateScale)
            .tickFormat(d3.timeFormat('%_d %b'))
            .ticks(
                d3.timeDay.filter(
                    (d) =>
                        d3.timeDay.count(parseDate(allDates[0]), d) %
                            bottomAxisTickInterval ===
                        0,
                ),
            );

    const axisLeftFactory = () =>
        leftScale
            ? d3
                  .axisLeft(leftScale)
                  .tickFormat((value) =>
                      valueFormat(value, leftSeries[0].format),
                  )
                  .tickValues(everyNth(leftScale.ticks(), 2))
                  .ticks(everyNth(leftScale.ticks(), 2).length)
            : undefined;

    const axisRightFactory = () =>
        rightScale
            ? d3
                  .axisRight(rightScale)
                  .tickFormat((value) =>
                      valueFormat(value, rightSeries[0].format),
                  )
                  .tickValues(everyNth(rightScale.ticks(), 2))
                  .ticks(everyNth(rightScale.ticks(), 2).length)
            : undefined;

    if (!width || !height) return <ChartWrapper wrapperRef={wrapperRef} />;

    return (
        <ChartWrapper wrapperRef={wrapperRef}>
            <svg className={styles['chart-svg']} width={width} height={height}>
                <ChartGrid
                    axisBottom={axisBottomFactory()}
                    axisLeft={axisLeftFactory()}
                    XAxisWidth={XAxisWidth}
                    YAxisHeight={YAxisHeight}
                    width={width}
                    height={height}
                />
                <ChartAxis
                    axisBottom={axisBottomFactory()}
                    axisLeft={axisLeftFactory()}
                    axisRight={axisRightFactory()}
                    XAxisWidth={XAxisWidth}
                    YAxisHeight={YAxisHeight}
                    width={width}
                    height={height}
                />
                <Translate x={XAxisWidth} y={YAxisHeight}>
                    {completeSeries
                        .filter((i) => i.axis)
                        .map((i) => (
                            <LineSeries
                                key={i.id}
                                series={i}
                                dateScale={dateScale}
                                yScale={
                                    i.axis === 'left' ? leftScale : rightScale
                                }
                            />
                        ))}
                    <TooltipLayer
                        series={completeSeries}
                        width={width - 2 * XAxisWidth}
                        height={height - 2 * YAxisHeight}
                    >
                        {tooltip}
                    </TooltipLayer>
                </Translate>
            </svg>
        </ChartWrapper>
    );
};

TrendChart.defaultProps = {
    XAxisWidth: 48,
    YAxisHeight: 32,
    tooltip: undefined,
};

TrendChart.propTypes = {
    /**
     * The data series for the trend chart. Can include series which are not visible/rendered.
     */
    series: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
            format: PropTypes.string,
            color: PropTypes.string,
            axis: PropTypes.oneOf(['left', 'right']),
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    date: PropTypes.string.isRequired,
                    value: PropTypes.number.isRequired,
                }),
            ),
            isSkinny: PropTypes.bool,
        }),
    ).isRequired,
    /**
     * The total width of the x axes on either side of the chart.
     */
    XAxisWidth: PropTypes.number,
    /**
     * The total height of the y axis on the bottom (and top) of the chart.
     */
    YAxisHeight: PropTypes.number,
    /**
     * The tooltip to overlay on the chart.
     * "series" and "date" props will be passed to the tooltip component on render, if possible.
     * "series" is the augmented series data from the TrendChart.
     * "date" is the currently hovered date from the TrendChart.
     */
    tooltip: PropTypes.element,
};

export default TrendChart;
