import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import Carousel from 'js/components/carousel/carousel';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import styles from './spotlight-carousel.module.scss';

const renderSlide = ({ description, original, originalAlt }) => (
    <div className={styles.slide}>
        {original && (
            <div className={styles['slide-image']}>
                <img src={original} alt={originalAlt} />
            </div>
        )}

        <div className={styles['panel-wrapper']}>
            <Box margin={[0, 0, 'small']}>
                <Text
                    weight="bolder"
                    size="larger"
                    color={['purple', 'darker']}
                >
                    {description.slideType}
                </Text>
            </Box>

            <div className={styles.panel}>
                {description.logo && (
                    <Box margin={[0, 0, 'small']}>
                        <img
                            className={styles.logo}
                            src={description.logo}
                            alt={description.name}
                        />
                    </Box>
                )}

                <Text size="huge">{description.name}</Text>

                {description.type && (
                    <Text weight="bolder" color={['purple', 'base']}>
                        {description.type}
                    </Text>
                )}

                <Box margin={['small', 0]}>
                    <Text size="large">
                        <p>{description.text}</p>
                    </Text>
                </Box>

                {description.action && (
                    <Row>
                        <Col span="auto">{description.action}</Col>
                    </Row>
                )}
            </div>
        </div>
    </div>
);

function LeftNav({ onClick, disabled }) {
    return (
        <button
            type="button"
            className="image-gallery-icon image-gallery-left-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Previous Slide"
        >
            <svg
                className="image-gallery-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 35"
                fill="none"
            >
                <path
                    d="M0.556986 16.1398L15.9572 0.563427C16.7 -0.187809 17.9041 -0.187809 18.6468 0.563427L20.443 2.38017C21.1844 3.13012 21.1859 4.34558 20.4461 5.0973L8.24118 17.5L20.4461 29.9027C21.1859 30.6544 21.1844 31.8699 20.443 32.6198L18.6468 34.4366C17.904 35.1878 16.6999 35.1878 15.9572 34.4366L0.557064 18.8602C-0.185673 18.109 -0.185673 16.8911 0.556986 16.1398Z"
                    fill="currentColor"
                />
            </svg>
        </button>
    );
}

function RightNav({ onClick, disabled }) {
    return (
        <button
            type="button"
            className="image-gallery-icon image-gallery-right-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Next Slide"
        >
            <svg
                className="image-gallery-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 35"
                fill="none"
            >
                <path
                    d="M20.443 18.8602L5.04278 34.4366C4.30004 35.1878 3.09587 35.1878 2.35322 34.4366L0.557034 32.6198C-0.184435 31.8699 -0.185861 30.6544 0.553865 29.9027L12.7588 17.5L0.553865 5.0973C-0.185861 4.34558 -0.184435 3.13012 0.557034 2.38017L2.35322 0.563428C3.09595 -0.187809 4.30012 -0.187809 5.04278 0.563428L20.4429 16.1398C21.1857 16.891 21.1857 18.1089 20.443 18.8602Z"
                    fill="currentColor"
                />
            </svg>
        </button>
    );
}

function SpotlightCarousel({ slides }) {
    const controllerRef = useRef();
    const onMouseOver = () => {
        controllerRef.current.pause();
    };
    const onMouseLeave = () => {
        controllerRef.current.play();
    };

    return (
        <div className={styles.container}>
            <Carousel
                slides={slides.map(({ description, src }) => ({
                    original: src,
                    description,
                    originalHeight: 450,
                    originalWidth: 1200,
                    originalAlt: `${description.type} slide`,
                    renderItem: renderSlide,
                }))}
                infinite
                autoPlay
                showBullets
                renderLeftNav={(onClick, disabled) => (
                    <LeftNav onClick={onClick} disabled={disabled} />
                )}
                renderRightNav={(onClick, disabled) => (
                    <RightNav onClick={onClick} disabled={disabled} />
                )}
                controllerRef={controllerRef}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                slideInterval={6000}
            />
        </div>
    );
}

SpotlightCarousel.defaultProps = {};

SpotlightCarousel.propTypes = {
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            description: PropTypes.object.isRequired,
            height: PropTypes.number,
            width: PropTypes.number,
            originalAlt: PropTypes.string,
            renderItem: PropTypes.func,
        }),
    ).isRequired,
};

export default SpotlightCarousel;
