import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MIN_PASSWORD_LENGTH } from 'js/constants';
import { api, setDocumentTitle } from 'js/utils';
import UserSettingsPasswordChange from './user-settings-password-change';

const mapApiErrors = (errors) => {
    const keyMap = {
        old_password: 'oldPassword',
        new_password1: 'newPassword',
        new_password2: 'confirmPassword',
    };
    const newErrors = {};
    Object.keys(errors).forEach((key) => {
        newErrors[keyMap[key] || key] = errors[key];
    });
    return newErrors;
};

function UserSettingsPasswordChangePage() {
    const history = useHistory();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [saving, setSaving] = useState(false);
    const [amending, setAmending] = useState(false);
    const [errors, setErrors] = useState({});

    useLayoutEffect(() => {
        setDocumentTitle(['Change Your Password', 'Your Profile', 'Settings']);

        return () => {
            api().abortAll();
        };
    }, []);

    const validatePasswords = ({ throwErrors = false } = {}) => {
        const passwordErrors = { ...errors };
        setAmending(true);
        if (!oldPassword) {
            passwordErrors.oldPassword = 'Your current password is required.';
        }
        if (!newPassword) {
            passwordErrors.newPassword = 'A new password is required.';
        } else if (newPassword.length < MIN_PASSWORD_LENGTH) {
            passwordErrors.newPassword = 'Your new password is too short.';
        } else if (!confirmPassword) {
            passwordErrors.confirmPassword =
                'Password confirmation is required.';
        } else if (newPassword !== confirmPassword) {
            passwordErrors.confirmPassword =
                'Your password confirmation does not match your new password.';
        }
        if (
            passwordErrors.oldPassword ||
            passwordErrors.newPassword ||
            passwordErrors.confirmPassword
        ) {
            setErrors(passwordErrors);
            if (throwErrors) throw passwordErrors;
        }
    };

    const submitOldPassword = (value) => {
        setOldPassword(value);
        const newErrors = { ...errors };
        delete newErrors.oldPassword;
        setErrors(newErrors);
    };

    const submitNewPassword = (value) => {
        setNewPassword(value);
        const newErrors = { ...errors };
        delete newErrors.newPassword;
        delete newErrors.confirmPassword;
        setErrors(newErrors);
    };

    const submitConfirmPassword = (value) => {
        setConfirmPassword(value);
        const newErrors = { ...errors };
        delete newErrors.confirmPassword;
        setErrors(newErrors);
    };

    const savePassword = async () => {
        setSaving(true);
        try {
            validatePasswords({ throwErrors: true });
            await api().changePassword(oldPassword, newPassword, newPassword);
            setSaving(false);
            history.push('/settings/your-profile/');
        } catch (passwordErrors) {
            setErrors(mapApiErrors(passwordErrors));
            setSaving(false);
        }
    };

    useEffect(() => {
        if (amending) validatePasswords();
    }, [oldPassword, newPassword, confirmPassword]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <UserSettingsPasswordChange
            saving={saving}
            passwordErrors={errors}
            oldPassword={oldPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            setOldPassword={submitOldPassword}
            setNewPassword={submitNewPassword}
            setConfirmPassword={submitConfirmPassword}
            onSavePassword={savePassword}
        />
    );
}

export default UserSettingsPasswordChangePage;
