import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AbortError, api, getDisplayName, getPreset } from 'js/utils';
import { CampaignDetailContext } from 'js/contexts';

const campaignTemplate = {
    id: null,
    name: '',
    advertiser: null,
    contexts: 0,
};

const ContextProvider = ({ children }) => {
    const { campaignId } = useParams();
    const isNew = !campaignId || campaignId === 'new';
    const [savedCampaign, setSavedCampaign] = useState({});
    const presetCampaign = useMemo(() => getPreset(), []);
    const [campaign, setCampaign] = useState({
        ...campaignTemplate,
        ...presetCampaign,
        id: isNew ? null : campaignId,
    });
    const [amending, setAmending] = useState(false);
    const [loading, setLoading] = useState(!isNew);
    const [loadErrors, setLoadErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [saveErrors, setSaveErrors] = useState({});
    const [deleting, setDeleting] = useState(false);
    const [deleteErrors, setDeleteErrors] = useState({});

    const validateCampaign = useCallback(
        ({ campaignData, throwErrors = false } = {}) => {
            setAmending(true);

            const errors = {};
            if (!campaignData.name) {
                errors.name = 'A campaign name cannot be empty.';
            }
            if (!campaignData.advertiser) {
                errors.advertiser = 'An advertiser is required.';
            }

            setSaveErrors((prevState) => ({ ...prevState, ...errors }));

            if (throwErrors && (errors.name || errors.advertiser)) {
                throw errors;
            }
        },
        [],
    );

    const loadCampaign = useCallback(
        async (id) => {
            setLoading(true);
            setLoadErrors({});
            try {
                const campaignData = await api().contextGroups.retrieve(id, {
                    verbose: true,
                });
                setCampaign(campaignData);
                setSavedCampaign(campaignData);
                validateCampaign({ campaignData });
            } catch (errors) {
                if (errors instanceof AbortError) return;
                setLoadErrors(errors);
            }
            setLoading(false);
        },
        [validateCampaign],
    );

    const saveCampaign = async () => {
        setSaving(true);
        setSaveErrors({});
        try {
            validateCampaign({ campaignData: campaign, throwErrors: true });

            let newCampaign;
            if (campaign.id) {
                newCampaign = await api().contextGroups.update(
                    campaign.id,
                    campaign,
                );
            } else {
                newCampaign = await api().contextGroups.create(campaign);
            }
            setCampaign(newCampaign);
            setSavedCampaign(newCampaign);
            setSaving(false);

            return newCampaign;
        } catch (errors) {
            if (errors.non_field_errors) {
                delete errors.non_field_errors;
                errors.name = 'This Name is already in use';
            }
            setSaveErrors(errors);
            setSaving(false);
            throw errors;
        }
    };

    const deleteCampaign = async () => {
        setDeleting(true);
        setDeleteErrors({});
        try {
            await api().contextGroups.destroy(campaign.id);
            setCampaign({ ...campaignTemplate });
            setDeleting(false);
        } catch (errors) {
            setDeleteErrors(errors);
            setDeleting(false);
            throw errors;
        }
    };

    const setName = useCallback(
        (name) => {
            delete saveErrors.name;
            setCampaign((prevState) => ({ ...prevState, name }));
        },
        [saveErrors.name],
    );

    const setAdvertiser = useCallback(
        (advertiserId) => {
            delete saveErrors.advertiser;
            setCampaign((prevState) => ({
                ...prevState,
                advertiser: advertiserId,
            }));
        },
        [saveErrors.advertiser],
    );

    const providerValue = useMemo(
        () => ({
            campaign: {
                current: campaign,
                saved: savedCampaign,
                preset: presetCampaign,
                loading,
                saving,
                deleting,
                save: saveCampaign,
                delete: deleteCampaign,
                setName,
                setAdvertiser,
            },
            errors: {
                load: loadErrors,
                save: saveErrors,
                delete: deleteErrors,
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            campaign,
            deleteErrors,
            deleting,
            loadErrors,
            loading,
            presetCampaign,
            saveErrors,
            savedCampaign,
            saving,
            setAdvertiser,
            setName,
        ],
    );

    useEffect(() => {
        setSaveErrors({});

        if (isNew) {
            setAmending(false);
            setCampaign((prevState) => ({
                ...campaignTemplate,
                ...presetCampaign,
                id: null,
                advertiser:
                    prevState.advertiser ||
                    presetCampaign.advertiser ||
                    campaignTemplate.advertiser,
            }));
            setSavedCampaign({});
        } else {
            loadCampaign(campaignId);
        }
    }, [campaignId, isNew, loadCampaign, presetCampaign]);

    useEffect(() => {
        if (amending && !loading) {
            validateCampaign({ campaignData: campaign });
        }
    }, [amending, campaign, loading, validateCampaign]);

    return (
        <CampaignDetailContext.Provider value={providerValue}>
            {children}
        </CampaignDetailContext.Provider>
    );
};

export default function withCampaignDetail(Wrapped) {
    function WithCampaignDetail(props) {
        return (
            <ContextProvider>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Wrapped {...props} />
            </ContextProvider>
        );
    }

    WithCampaignDetail.displayName = `withCampaignDetail(${getDisplayName(
        Wrapped,
    )})`;

    return WithCampaignDetail;
}
