import React, { useState } from 'react';
import { capitalize, createPresetUrl } from 'js/utils';
import entityTypes from 'js/enums/entity-types.enum';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Select from 'js/components/select/select';

const ContextStep = ({
    template,
    advertisers,
    contexts,
    onChangeAdvertiser,
    onChangeContext,
}) => {
    const [advertiserSearchTerm, setAdvertiserSearchTerm] = useState('');
    const [contextSearchTerm, setContextSearchTerm] = useState('');

    const advertiserOptions = advertisers.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    const visibleAdvertiserOptions = advertiserOptions.filter(({ label }) =>
        label.toLowerCase().includes(advertiserSearchTerm.toLowerCase()),
    );

    const contextOptions = contexts.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    const visibleContextOptions = contextOptions.filter(({ label }) =>
        label.toLowerCase().includes(contextSearchTerm.toLowerCase()),
    );

    if (!contexts.length) {
        const reportType =
            (template.type === entityTypes.DISPLAY_REPORTS && 'display') ||
            (template.type === entityTypes.VIDEO_REPORTS && 'video') ||
            '';
        const newContextUrl = createPresetUrl('/contexts/new/', {
            is_video: template.type === entityTypes.VIDEO_REPORTS,
        });

        return (
            <Alert
                theme="empty"
                title={`You Have No ${capitalize(reportType)} Contexts`}
                actionItems={[
                    <Button to={newContextUrl} key="new">
                        Create Context
                    </Button>,
                ]}
            >
                <Box padding={[0, 0, 'small']}>
                    For 4D to be able to create a report of this type, you first
                    need to create a {reportType} context.
                </Box>
            </Alert>
        );
    }

    return (
        <>
            <Select
                label="Advertiser"
                required
                width="same"
                options={visibleAdvertiserOptions}
                selectedValues={template.advertiser || advertisers[0].id}
                onChange={onChangeAdvertiser}
                onSearch={
                    advertiserOptions.length > 5
                        ? setAdvertiserSearchTerm
                        : null
                }
            />
            <Box padding={['base', 0, 0]}>
                <Select
                    label="Context"
                    required
                    width="same"
                    options={visibleContextOptions}
                    selectedValues={template.context || contexts[0].id}
                    onChange={onChangeContext}
                    onSearch={
                        contextOptions.length > 5 ? setContextSearchTerm : null
                    }
                />
            </Box>
        </>
    );
};

export default ContextStep;
