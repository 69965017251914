/* eslint-disable react/jsx-props-no-spreading */

import React, { useLayoutEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import filterTypes from 'js/enums/filter-types.enum';
import permissionGroups from 'js/enums/permission-groups.enum';
import sortTypes from 'js/enums/sort-types.enum';
import { sortOptions } from 'js/constants';
import { api, getDisplayName, getJwtPayload, getUpdatedList } from 'js/utils';

const filterOptionsMap = {
    [filterTypes.ORDERING]: sortOptions,
};

const withContextList = (Wrapped) => {
    function WithContextList(props) {
        const [loading, setLoading] = useState(true);
        const [errors, setErrors] = useState({});
        const [advertiser, setAdvertiser] = useState([]);
        const [campaign, setCampaign] = useState({});
        const [contexts, setContexts] = useState([]);
        const [ordering, setOrdering] = useState(sortTypes.UPDATED_DESC);
        const [togglingFavoriteError, setTogglingFavoriteError] = useState({});

        const history = useHistory();

        const { campaignId, advertiserId } = props.match.params; // eslint-disable-line react/destructuring-assignment

        const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
        const isViewOnlyUser =
            !jwt.is_superuser && jwt.roles.includes(permissionGroups.VIEW_ONLY);

        const initFilterParams = () => {
            const filterParams = Object.fromEntries(
                new URLSearchParams(history.location.search).entries(),
            );
            const selectedOrdering =
                filterParams[filterTypes.ORDERING] || sortTypes.UPDATED_DESC;
            setOrdering(selectedOrdering);

            return filterParams;
        };

        const loadContextData = async () => {
            const filterParams = initFilterParams();

            delete filterParams.search;

            const callbacks = [
                api().contexts.list({
                    group: campaignId,
                    ordering: '-updated',
                    verbose: true,
                    is_public: false,
                    ...filterParams,
                }),
            ];

            if (!campaign.id) {
                callbacks.push(
                    api().contextGroups.retrieve(campaignId, {
                        verbose: true,
                        is_public: false,
                    }),
                );
            }

            const [{ results: contextsData }, campaignData] = await Promise.all(
                callbacks,
            );

            if (campaignData) {
                setCampaign(campaignData);
            }

            setContexts(contextsData);
        };

        const loadAdvertiserData = async () => {
            const advertiserData = await api().advertisers.retrieve(
                advertiserId,
            );

            if (advertiserData) {
                setAdvertiser(advertiserData);
            }
        };

        const loadData = async () => {
            setLoading(true);
            setErrors({});

            try {
                await Promise.all([loadContextData(), loadAdvertiserData()]);
            } catch (err) {
                setErrors({
                    ...errors,
                    loadingFailed: 'Failed to load',
                });
            } finally {
                setLoading(false);
            }
        };

        const toggleFavoriteCampaign = async () => {
            const isFavorite = !campaign.is_favourite;

            setCampaign((prevState) => ({
                ...prevState,
                isFavoriteLoading: true,
            }));

            let success = false;
            setTogglingFavoriteError({});
            try {
                await api().contextGroups.favorite(campaign.id, isFavorite);
                success = true;
            } catch (err) {
                setTogglingFavoriteError({
                    title: campaign.name,
                    isFavorite,
                });
            } finally {
                setCampaign((prevState) => ({
                    ...prevState,
                    is_favourite: success ? isFavorite : !isFavorite,
                    isFavoriteLoading: false,
                }));
            }
        };

        const toggleFavoriteContext = async (contextId) => {
            const context = contexts.find(({ id }) => id === contextId);
            const isFavorite = !context.is_favourite;

            setContexts((prevState) =>
                getUpdatedList(prevState, contextId, {
                    isFavoriteLoading: true,
                }),
            );

            let success = false;
            setTogglingFavoriteError({});
            try {
                await api().contexts.favorite(contextId, isFavorite);
                success = true;
            } catch (err) {
                setTogglingFavoriteError({
                    title: context.name,
                    isFavorite,
                });
            } finally {
                setContexts((prevState) =>
                    getUpdatedList(prevState, contextId, {
                        is_favourite: success ? isFavorite : !isFavorite,
                        isFavoriteLoading: false,
                    }),
                );
            }
        };

        const updateFilter = async (filterType, filterValue) => {
            const params = new URLSearchParams(history.location.search);
            const defaultValue = filterOptionsMap[filterType][0].value;

            if (filterValue === defaultValue) {
                params.delete(filterType);
            } else {
                params.set(filterType, filterValue);
            }

            await history.replace({
                ...history.location,
                search: params.toString(),
            });

            await loadData();
        };

        const deleteContext = async (contextId) => {
            await api().contexts.destroy(contextId);
            await loadContextData();
        };

        useLayoutEffect(() => {
            loadData();

            return () => {
                api().abortAll();
            };
        }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <Wrapped
                {...props}
                loading={loading}
                errors={errors}
                readOnly={isViewOnlyUser}
                advertiser={advertiser}
                campaign={campaign}
                contexts={contexts}
                ordering={ordering}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavoriteCampaign={toggleFavoriteCampaign}
                onToggleFavoriteContext={toggleFavoriteContext}
                onUpdateFilter={updateFilter}
                onDeleteContext={deleteContext}
            />
        );
    }

    WithContextList.displayName = `withContextList(${getDisplayName(Wrapped)})`;

    return withRouter(WithContextList);
};

export default withContextList;
