import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { validMimeType } from 'js/utils';
import Button from 'js/components/button/button';
import DropArea from 'js/components/drop-area/drop-area';

export const cropImage = async (file, data) => {
    const img = new Image();
    img.src = data;
    await img.decode();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const { data: imgData } = ctx.getImageData(0, 0, img.width, img.height);

    const isTransparent = ({ a }) => a === 0;
    const isWhitish = ({ r, g, b, a }) =>
        a === 255 && r >= 250 && g >= 250 && b >= 250;

    let [x0, y0, x1, y1] = [Infinity, Infinity, 0, 0];
    for (let y = 0, i = 0; y < canvas.height; y += 1) {
        for (let x = 0; x < canvas.width; x += 1, i += 4) {
            const [r, g, b, a] = imgData.slice(i, i + 4);
            const pixel = { r, g, b, a };
            if (!isTransparent(pixel) && !isWhitish(pixel)) {
                if (x < x0) x0 = x;
                if (y < y0) y0 = y;
                if (x > x1) x1 = x;
                if (y > y1) y1 = y;
            }
        }
    }

    const croppedWidth = x1 - x0 + 1;
    const croppedHeight = y1 - y0 + 1;
    const croppedData = ctx.getImageData(x0, y0, croppedWidth, croppedHeight);
    canvas.width = croppedWidth;
    canvas.height = croppedHeight;
    ctx.putImageData(croppedData, 0, 0);

    return [file, canvas.toDataURL(file.type)];
};

export const validateImage = async (file, data) => {
    const image = new Image();
    image.src = data;
    try {
        await image.decode();
    } catch (error) {
        throw Error('Unable to read the image');
    }
    return [file, data];
};

export const validateFileSize = (...args) => {
    const [minSize, maxSize] = args;
    async function vfs(file) {
        if (maxSize && file.size > maxSize) {
            const maxSizeQuant = Math.min(
                Math.max(Math.floor(Math.log(maxSize) / Math.log(1024)), 0) ||
                    0,
                4,
            );
            const maxSizeName = {
                0: 'bytes',
                1: 'kilobytes',
                2: 'megabytes',
                3: 'gigabytes',
                4: 'terabytes',
            }[maxSizeQuant];
            const maxSizeBytes = Math.floor(maxSize / 1024 ** maxSizeQuant);
            throw Error(
                `The file is too big, must be less than ${maxSizeBytes} ${maxSizeName}`,
            );
        }
        if (minSize && file.size < minSize) {
            const minSizeQuant = Math.min(
                Math.max(Math.floor(Math.log(minSize) / Math.log(1024)), 0) ||
                    0,
                4,
            );
            const minSizeName = {
                0: 'bytes',
                1: 'kilobytes',
                2: 'megabytes',
                3: 'gigabytes',
                4: 'terabytes',
            }[minSizeQuant];
            const minSizeBytes = Math.floor(minSize / 1024 ** minSizeQuant);
            throw Error(
                `The file is too small, must be more than ${minSizeBytes} ${minSizeName}`,
            );
        }
    }
    vfs.args = args.join(', ');
    return vfs;
};

export const validateImageDimensions = (...args) => {
    const [minImageWidth, minImageHeight, maxImageWidth, maxImageHeight] = args;
    async function vid(file, data) {
        const image = new Image();
        image.src = data;
        await image.decode();
        if (minImageHeight && image.height < minImageHeight) {
            throw Error(
                `The image is smaller than the minimum height of ${minImageHeight}px. ` +
                    'Please try a larger image.',
            );
        }
        if (maxImageHeight && image.height > maxImageHeight) {
            throw Error(
                `The image height is larger than the maximum height of ${maxImageHeight}px. ` +
                    'Please try a smaller image.',
            );
        }
        if (minImageWidth && image.width < minImageWidth) {
            throw Error(
                `The image is smaller than the minimum width of ${minImageWidth}px. ` +
                    'Please try a larger image.',
            );
        }
        if (maxImageWidth && image.width > maxImageWidth) {
            throw Error(
                `The image width is larger than the maximum width of ${maxImageWidth}px. ` +
                    'Please try a smaller image.',
            );
        }
        return [file, data];
    }
    vid.args = args.join(', ');
    return vid;
};

export const validateMimeTypes = (mimeTypes) => {
    async function validator(file) {
        if (!validMimeType(file.type, mimeTypes)) {
            throw Error(`Unsupported file type: ${file.type}`);
        }
    }
    validator.mimeTypes = mimeTypes;
    validator.args = mimeTypes.join(', ');
    return validator;
};

export const validateData = async (file, data) => {
    if (data === null) {
        throw Error('Unable to open the file');
    }
    return [file, data];
};

function FileButton({
    onClick,
    onLoadStart,
    onLoadEnd,
    onError,
    droparea,
    sanitizers,
    children,
    ...buttonProps
}) {
    const inputRef = useRef(null);
    const [hasMounted, setHasMounted] = useState(false);
    const [isDropActive, setIsDropActive] = useState(false);
    const mimeTypes = sanitizers.find((s) => s.mimeTypes)?.mimeTypes || [];

    const handleChange = async (file) => {
        const fileValidators = sanitizers.filter(
            (sanitizer) => sanitizer.length === 1,
        );

        try {
            await Promise.all(
                fileValidators.map((validator) => validator(file)),
            );
        } catch (err) {
            onError(err.message);
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {
            if (onLoadStart) onLoadStart();
        };
        reader.onloadend = async (ev) => {
            let data = ev.target.result;
            const dataValidators = sanitizers.filter(
                (sanitizer) => sanitizer.length === 2,
            );

            try {
                [, data] = await dataValidators.reduce(
                    async (acc, validator) => validator(...(await acc)),
                    Promise.resolve([file, data]),
                );
            } catch (err) {
                onError(err.message);
                return;
            }

            if (onLoadEnd) onLoadEnd(data);
        };
    };

    const handleClick = (evt) => {
        if (onClick) onClick(evt);
        inputRef.current.click();
    };

    useEffect(
        () => {
            if (hasMounted) {
                onError();
                onLoadEnd();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sanitizers.map((s) => s.name + s.args).join()],
    );

    useEffect(() => setHasMounted(true), []);

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                accept={mimeTypes.join(',')}
                onChange={(ev) => {
                    handleChange(ev.target.files[0]);
                    // eslint-disable-next-line no-param-reassign
                    ev.target.value = null;
                }}
                style={{ display: 'none' }}
            />

            {droparea ? (
                <DropArea
                    mimeTypes={mimeTypes}
                    onEnter={() => setIsDropActive(true)}
                    onLeave={() => setIsDropActive(false)}
                    onDrop={(file) => {
                        setIsDropActive(false);
                        handleChange(file);
                    }}
                >
                    <Button
                        className="droparea"
                        onClick={handleClick}
                        active={isDropActive}
                        {...buttonProps} // eslint-disable-line react/jsx-props-no-spreading
                    >
                        {children}
                    </Button>
                </DropArea>
            ) : (
                <Button
                    onClick={handleClick}
                    {...buttonProps} // eslint-disable-line react/jsx-props-no-spreading
                >
                    {children}
                </Button>
            )}
        </>
    );
}

FileButton.defaultProps = {
    onClick: () => {},
    onLoadStart: () => {},
    onLoadEnd: () => {},
    onError: () => {},
    droparea: false,
    sanitizers: [],
};

FileButton.propTypes = {
    onClick: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadEnd: PropTypes.func,
    onError: PropTypes.func,
    droparea: PropTypes.bool,
    sanitizers: PropTypes.arrayOf(PropTypes.func),
    children: PropTypes.node.isRequired,
};

export default FileButton;
