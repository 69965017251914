import React from 'react';
import SectionTitle from 'js/components/section-title/section-title';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import LineItemResult from '../components/line-item-result';
import NoResults from '../components/no-results';

const LineItemsResults = ({ searchResults, onLineItemNameChange }) => {
    const sortedResults = searchResults.sort((a, b) => {
        if (b.lineItemName.length === 0) {
            return -1;
        }
        if (a.lineItemName.length === 0) {
            return 1;
        }
        return a.lineItemName.localeCompare(b.lineItemName);
    });
    return (
        <>
            <Box margin={['base', 0, 'small']}>
                <SectionTitle info="Line Item IDs are also known as Ad Group IDs in The Trade Desk">
                    <Text size="large">Line Items</Text>
                </SectionTitle>
            </Box>
            {searchResults.length === 0 ? (
                <NoResults />
            ) : (
                sortedResults.map((lineItem) => (
                    <LineItemResult
                        key={lineItem.lineItemId}
                        lineItem={lineItem}
                        onLineItemNameChange={onLineItemNameChange}
                    />
                ))
            )}
        </>
    );
};

export default LineItemsResults;
