import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { api, getJwtPayload, setDocumentTitle } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import Text from 'js/components/text/text';
import TopBar from 'js/components/top-bar/top-bar';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Box from 'js/components/box/box';
import Spinner from 'js/components/spinner/spinner';
import { showToast } from 'js/components/toast/toast';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import styles from './error.module.scss';

const errors = {
    401: {
        title: 'Uh oh! Nothing to see here…',
        message: (
            <p>
                You do not have authorization to view this page. If you should
                have access, please contact either your account admin to request
                access or our support team at <SupportEmailLink />
            </p>
        ),
    },
    403: {
        title: 'Uh oh! Nothing to see here…',
        message: (
            <p>
                You do not have permission to view this page. If you should have
                access, please contact either your account admin to request
                access or our support team at <SupportEmailLink />
            </p>
        ),
    },
    404: {
        title: 'Oops, taken a wrong turn?',
        message: (
            <p>
                This page does not exist. If you believe something should be
                here, please contact our support team at <SupportEmailLink />
            </p>
        ),
    },
    429: {
        title: 'Knock knock, let me in…',
        message: (
            <p>
                You have incorrectly entered your password three times so we
                have locked your account for safety reasons. You can try again
                in 15 minutes or contact our support team at{' '}
                <SupportEmailLink />
            </p>
        ),
    },
    null: {
        title: 'Houston, we have a problem…',
        message: (
            <p>
                There was an error loading your data. Please retry and if the
                error persists, contact our support team at <SupportEmailLink />
            </p>
        ),
    },
};

const ErrorPage = ({ isLoggedIn, code = null }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSwitchingAccount, setIsSwitchingAccount] = useState(false);
    const [pageData, setPageData] = useState({});
    const { accountId } = useParams();

    setDocumentTitle(['Error']);

    const history = useHistory();
    const { location } = window;
    const { search } = location;
    const redirectUri = new URLSearchParams(search).get('redirectUri') || '/';
    const redirectParams = new URLSearchParams({ redirectUri });
    const { name = null, username = null } = getJwtPayload(
        localStorage.getItem('AuthToken'),
    );

    useEffect(() => {
        const switchAccount = async () => {
            setIsSwitchingAccount(true);
            try {
                const { token } = await api({ scope: 'error' }).masquerade(
                    accountId,
                );
                localStorage.setItem('AuthToken', token);
                window.location.href = redirectUri;
            } catch (err) {
                setIsSwitchingAccount(false);
                showToast(
                    'Uh oh!',
                    <Text color={['gray', 'white']}>
                        Something went wrong, please try again.
                    </Text>,
                    3,
                );
            }
        };
        const getErrorMessageData = async () => {
            setIsLoading(true);
            let data = { ...errors.null, actionItems: [] };
            if (errors[code]) {
                data = { ...errors[code], actionItems: [] };
            } else if (code === 'account') {
                try {
                    const account = await api({
                        scope: 'error',
                    }).accounts.retrieve(accountId, {
                        complete: true,
                    });
                    data = {
                        title: 'Almost There…',
                        message: (
                            <p>
                                You do not have permissions to view this page
                                from this account. To view this page, switch to
                                the &quot;
                                <Text weight="bold" inline>
                                    {account.name}
                                </Text>
                                &quot; account.
                            </p>
                        ),
                        actionItems: [
                            <Button onClick={switchAccount}>
                                Switch Account
                            </Button>,
                        ],
                    };
                } catch (err) {
                    // doesn't matter if there is an error, revert to the null error page
                }
            }
            setPageData(data);
            setIsLoading(false);
        };

        getErrorMessageData();
        return () => {
            api({ scope: 'error' }).abortAll();
        };
    }, [accountId, code, redirectUri]);

    const { title, message, actionItems = [] } = pageData;

    const hello = (
        <>
            <Text inline weight="base" color={['gray', 'dark']}>
                Hello,
            </Text>{' '}
            {name || username}
        </>
    );

    const backButton = (
        <Button
            theme={actionItems.length ? 'outline' : 'primary'}
            key="back"
            onClick={() => history.goBack()}
        >
            Back
        </Button>
    );

    const loginButton = (
        <Button
            theme={actionItems.length ? 'outline' : 'primary'}
            key="login"
            to={`/login?${redirectParams}`}
        >
            Visit Login Page
        </Button>
    );

    return (
        <Layout
            header={isLoggedIn && <TopBar title={hello} />}
            sidebar={isLoggedIn && <Navigation />}
        >
            {isSwitchingAccount && <LoadingLayer message="Switching Account" />}
            <div className={styles.container}>
                <div
                    className={classNames([
                        styles.image,
                        styles[`image--${code}`],
                    ])}
                >
                    <div className={styles.content}>
                        <Container size="small">
                            <Alert
                                theme={
                                    code === 'account' ? 'primary' : 'danger'
                                }
                                title={title}
                                actionItems={
                                    !isLoading && (
                                        <Row>
                                            {[
                                                isLoggedIn
                                                    ? backButton
                                                    : loginButton,
                                                ...actionItems,
                                            ].map((component, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Col key={i} span="auto">
                                                    {component}
                                                </Col>
                                            ))}
                                        </Row>
                                    )
                                }
                            >
                                {isLoading ? (
                                    <Box padding={['large', 0]}>
                                        <Spinner
                                            size="large"
                                            color={['gray', 'dark']}
                                            isCentered
                                        />
                                    </Box>
                                ) : (
                                    message
                                )}
                            </Alert>
                        </Container>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ErrorPage;
