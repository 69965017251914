import React from 'react';
import ConfirmModal from 'js/components/modal/confirm-modal';

export default function DeleteWarningModal({
    deleteErrors,
    toggleDeleteWarningModal,
    onDeleteContext,
}) {
    return (
        <ConfirmModal
            confirm="Yes, Delete Context"
            cancel="No, Cancel"
            loadingMessage="Deleting Context"
            hasError={Object.keys(deleteErrors).length > 0}
            errorMessage={`There was an unexpected error and the context was 
                    not deleted. Please try again in a few moments.`}
            onCancel={toggleDeleteWarningModal}
            onConfirm={onDeleteContext}
        >
            <p>Deleting a context is permanent and cannot be undone.</p>
        </ConfirmModal>
    );
}
