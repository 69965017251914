import React, { useState } from 'react';
import moment from 'moment';
import ruleTypes from 'js/enums/rule-types.enum';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import Switch from 'js/components/switch/switch';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import Text from 'js/components/text/text';
import PageSection from '../page-section/page-section';
import Benefits from './benefits/benefits';
import Settings from './settings/settings';
import Timeline from './timeline/timeline';
import optimizeTabs from '../../enums/optimize-tabs.enum';
import Rocket from './images/rocket.svg';
import Plus from './images/plus.svg';
import Minus from './images/minus.svg';
import styles from './optimize-section.module.scss';

const OverviewItem = ({ label, icon, value, date }) => (
    <Box padding={['small', 0, 0]} paddingXl="none">
        <Text size="large" color={['gray', 'dark']}>
            {label}
        </Text>

        <Box margin={['small', 0, 0]}>
            <Panel bordered>
                <Box padding="base">
                    <div className={styles.overview}>
                        <img
                            src={icon}
                            className={styles['overview-icon']}
                            alt=""
                        />

                        <div className={styles['overview-text']}>
                            {value}
                            {date && (
                                <Text weight="base" size="small">
                                    {date}
                                </Text>
                            )}
                        </div>
                    </div>
                </Box>
            </Panel>
        </Box>
    </Box>
);

const OptimizeSection = ({
    isReadOnly,
    isVideo,
    isActive,
    targetMetric,
    controls,
    campaigns,
    recommendations,
    rules,
    eventHierarchy,
    onToggleActive,
    onChangeTarget,
    onChangeControls,
    onChangeCampaigns,
    onChangeRules,
    onLoadEventHierarchy,
    topicGroups,
    logoGroups,
    language,
}) => {
    const [currentTabId, setCurrentTabId] = useState(
        campaigns.length ? optimizeTabs.OVERVIEW : optimizeTabs.SETTINGS,
    );

    const targetedKeywords = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item.keywords)
        .flat();

    const targetedTopics = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item.topics)
        .flat();

    const targetedLogos = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item.logos)
        .flat();

    const targetedRecommendations = recommendations.filter(
        (item) => item.action === 'add',
    );

    const untargetedRecommendations = recommendations.filter(
        (item) => item.action === 'remove',
    );

    const appliedTargetedRecommendations = targetedRecommendations.filter(
        (rec) =>
            rec.entities.some(
                ({ name: id }) =>
                    targetedTopics.includes(id) ||
                    targetedKeywords.includes(id) ||
                    targetedLogos.includes(id),
            ),
    );

    const appliedUntargetedRecommendations = untargetedRecommendations.filter(
        (rec) =>
            rec.entities.some(
                ({ name: id }) =>
                    !(
                        targetedTopics.includes(id) ||
                        targetedKeywords.includes(id) ||
                        targetedLogos.includes(id)
                    ),
            ),
    );

    const appliedTargetedKeywordsCount = appliedTargetedRecommendations
        .filter((r) => r.attribute === 'keywords')
        .reduce((count, { entities }) => {
            const keywords = entities.map(({ name }) => name);
            const intersection = keywords.filter((keyword) =>
                targetedKeywords.includes(keyword),
            );

            return count + intersection.length;
        }, 0);

    const appliedTargetedTopicsCount = appliedTargetedRecommendations
        .filter((r) => r.attribute === 'topics')
        .reduce((count, { entities }) => {
            const topics = entities.map(({ name }) => name);
            const intersection = topics.filter((t) =>
                targetedTopics.includes(t),
            );

            return count + intersection.length;
        }, 0);

    const appliedTargetedLogosCount = appliedTargetedRecommendations
        .filter((r) => r.attribute === 'logos')
        .reduce((count, { entities }) => {
            const logos = entities.map(({ name }) => name);
            const intersection = logos.filter((t) => targetedLogos.includes(t));

            return count + intersection.length;
        }, 0);

    const appliedUntargetedKeywordsCount = appliedUntargetedRecommendations
        .filter((r) => r.attribute === 'keywords')
        .reduce((count, { entities }) => {
            const keywords = entities.map(({ name }) => name);
            const delta = keywords.filter(
                (keyword) => !targetedKeywords.includes(keyword),
            );

            return count + delta.length;
        }, 0);

    const appliedUntargetedTopicsCount = appliedUntargetedRecommendations
        .filter((r) => r.attribute === 'topics')
        .reduce((count, { entities }) => {
            const topics = entities.map(({ name }) => name);
            const delta = topics.filter((t) => !targetedKeywords.includes(t));

            return count + delta.length;
        }, 0);

    const appliedUntargetedLogosCount = appliedUntargetedRecommendations
        .filter((r) => r.attribute === 'logos')
        .reduce((count, { entities }) => {
            const logos = entities.map(({ name }) => name);
            const delta = logos.filter((t) => !targetedLogos.includes(t));

            return count + delta.length;
        }, 0);

    const appliedRecommendations = [
        ...appliedTargetedRecommendations,
        ...appliedUntargetedRecommendations,
    ];

    const latestAppliedRecommendationDate =
        appliedRecommendations.length > 0
            ? appliedRecommendations.sort((a, b) => {
                  const aDate = new Date(a.created);
                  const bDate = new Date(b.created);

                  return bDate - aDate;
              })[0].created
            : null;

    const totalAppliedText = `${appliedRecommendations.length} optimization${
        appliedRecommendations.length !== 1 ? 's' : ''
    }`;
    const topicsAddedText = `${appliedTargetedTopicsCount} Topic${
        appliedTargetedTopicsCount !== 1 ? 's' : ''
    }`;
    const topicsRemovedText = `${appliedUntargetedTopicsCount} Topic${
        appliedUntargetedTopicsCount !== 1 ? 's' : ''
    }`;
    const secondaryEntityAddedText = isVideo
        ? `${appliedTargetedLogosCount} Brand${
              appliedTargetedLogosCount !== 1 ? 's' : ''
          }`
        : `${appliedTargetedKeywordsCount} Keyword${
              appliedTargetedKeywordsCount !== 1 ? 's' : ''
          }`;
    const secondaryEntityRemovedText = isVideo
        ? `${appliedUntargetedLogosCount} Brand${
              appliedUntargetedLogosCount !== 1 ? 's' : ''
          }`
        : `${appliedUntargetedKeywordsCount} Keyword${
              appliedUntargetedKeywordsCount !== 1 ? 's' : ''
          }`;

    const buttonsWrapper = ({ children }) => (
        <Box margin={[0, 0, 'base']}>{children}</Box>
    );

    return (
        <PageSection
            title="Optimize"
            description="Boost the performance of your advertising campaigns."
        >
            <Box margin={['large', 0, 0]}>
                <Panel bordered>
                    <Box padding="base">
                        <SectionTitle
                            info={
                                <>
                                    <p>
                                        4D can automatically generate and apply
                                        optimizations based on your campaign
                                        data to improve the performance of your
                                        advertising based on your campaign
                                        goals.
                                    </p>
                                    <p>
                                        4D optimizations will generate every
                                        250k impressions and automatically
                                        update your context when this feature is
                                        switched on. You can switch this feature
                                        off from this section at any time.
                                    </p>
                                </>
                            }
                            extension={[
                                <Switch
                                    isDisabled={isReadOnly}
                                    labels={['Off', 'On']}
                                    isChecked={isActive}
                                    onChange={onToggleActive}
                                />,
                            ]}
                        >
                            Auto Optimizations
                        </SectionTitle>

                        <Box margin={['base', 0, 0]}>
                            <Panel theme="secondary">
                                <Box padding="base">
                                    {isActive ? (
                                        <Tabs
                                            tabs={optimizeTabs}
                                            currentTab={currentTabId}
                                            hideTabButtons={
                                                campaigns.length === 0
                                            }
                                            buttonsWrapper={buttonsWrapper}
                                            onChangeTab={setCurrentTabId}
                                        >
                                            <TabContent
                                                tabId={optimizeTabs.OVERVIEW}
                                            >
                                                <Panel>
                                                    <Box padding="base">
                                                        <Text
                                                            weight="bold"
                                                            size="large"
                                                        >
                                                            Last 30 Days
                                                        </Text>

                                                        <Box
                                                            margin={[
                                                                'small',
                                                                0,
                                                                0,
                                                            ]}
                                                        >
                                                            <Row>
                                                                <Col
                                                                    span={12}
                                                                    spanXl="equal"
                                                                >
                                                                    <OverviewItem
                                                                        label="Applied"
                                                                        icon={
                                                                            Rocket
                                                                        }
                                                                        value={
                                                                            totalAppliedText
                                                                        }
                                                                        date={
                                                                            appliedRecommendations.length >
                                                                                0 &&
                                                                            `Latest ${moment(
                                                                                latestAppliedRecommendationDate,
                                                                            ).fromNow()}`
                                                                        }
                                                                    />
                                                                </Col>

                                                                <Col
                                                                    span={12}
                                                                    spanXl="equal"
                                                                >
                                                                    <OverviewItem
                                                                        label="Added"
                                                                        icon={
                                                                            Plus
                                                                        }
                                                                        value={
                                                                            <div>
                                                                                <div>
                                                                                    {
                                                                                        topicsAddedText
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        secondaryEntityAddedText
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Col>

                                                                <Col
                                                                    span={12}
                                                                    spanXl="equal"
                                                                >
                                                                    <OverviewItem
                                                                        label="Removed"
                                                                        icon={
                                                                            Minus
                                                                        }
                                                                        value={
                                                                            <div>
                                                                                <div>
                                                                                    {
                                                                                        topicsRemovedText
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        secondaryEntityRemovedText
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Box>
                                                    </Box>
                                                </Panel>
                                            </TabContent>

                                            <TabContent
                                                tabId={optimizeTabs.TIMELINE}
                                            >
                                                <Timeline
                                                    isReadOnly={isReadOnly}
                                                    recommendations={
                                                        recommendations
                                                    }
                                                    rules={rules}
                                                    logoGroups={logoGroups}
                                                    topicGroups={topicGroups}
                                                    onChangeRules={
                                                        onChangeRules
                                                    }
                                                />
                                            </TabContent>

                                            <TabContent
                                                tabId={optimizeTabs.SETTINGS}
                                            >
                                                <Settings
                                                    isReadOnly={isReadOnly}
                                                    isVideo={isVideo}
                                                    targetMetric={targetMetric}
                                                    controls={controls}
                                                    campaigns={campaigns}
                                                    eventHierarchy={
                                                        eventHierarchy
                                                    }
                                                    onChangeTarget={
                                                        onChangeTarget
                                                    }
                                                    onChangeControls={
                                                        onChangeControls
                                                    }
                                                    onChangeCampaigns={
                                                        onChangeCampaigns
                                                    }
                                                    onLoadEventHierarchy={
                                                        onLoadEventHierarchy
                                                    }
                                                    topicGroups={topicGroups}
                                                    logoGroups={logoGroups}
                                                    language={language} // TODO: come back and move these props above the onDoX..
                                                />
                                            </TabContent>
                                        </Tabs>
                                    ) : (
                                        <Benefits />
                                    )}
                                </Box>
                            </Panel>
                        </Box>
                    </Box>
                </Panel>
            </Box>
        </PageSection>
    );
};

export default OptimizeSection;
