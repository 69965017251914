import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import CardIntegration from 'js/components/card/card-integration';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import TopBar from 'js/components/top-bar/top-bar';
import { reloadPage } from 'js/utils';
import React from 'react';

const Wrapper = ({ children }) => (
    <Layout
        sidebar={<Navigation />}
        header={
            <TopBar
                breadcrumbs={[
                    <Crumb key="home" to="/" label="4D" />,
                    <Crumb
                        key="settings"
                        to="/settings/"
                        label="Settings"
                        isCollapsible
                    />,
                    <Crumb key="channels" label="Channels" />,
                ]}
            />
        }
    >
        <Section>
            <Container>
                <Box padding={['base', 0, 'large']}>{children}</Box>
            </Container>
        </Section>
    </Layout>
);

export default function IntegrationList({ isLoading, isError, integrations }) {
    if (isError) {
        return (
            <Wrapper>
                <Box padding={['base', 0, 0]}>
                    <Alert
                        theme="danger"
                        title="There was an error while loading the channels"
                        actionItems={
                            <Button onClick={reloadPage}>
                                Retry Loading Channels
                            </Button>
                        }
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            loading the page again in a few moments.
                        </p>
                    </Alert>
                </Box>
            </Wrapper>
        );
    }

    if (isLoading) {
        return (
            <Wrapper>
                <Box padding={['base', 0, 0]}>
                    <Spinner
                        size="large"
                        message="Loading Channels"
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Row>
                {integrations.map((integration) => (
                    <Col key={integration.id} span={12} spanMd={6} spanLg={4}>
                        <Box padding={['base', 0, 0]}>
                            <CardIntegration integration={integration} />
                        </Box>
                    </Col>
                ))}
            </Row>
        </Wrapper>
    );
}
