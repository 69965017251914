import React, { useContext, useState } from 'react';
import sectionTypes from 'js/enums/section-types.enum';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Popper from 'js/components/popper/popper';
import SVGImage from 'js/components/svg-image/svg-image';
import Text from 'js/components/text/text';
import AutoOptimize from 'media/images/various/auto-optimize.svg';
import styles from './dimensions-panel.module.scss';
import ContextDetailContext from '../../contexts/context-detail.context';

const AutoOptimizeIcon = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleShowTooltip = () => {
        setShowTooltip((previous) => !previous);
    };

    const popperText =
        'This dimension is being managed by Auto Optimizations. For more information, visit the Optimize section below';

    return (
        <Popper
            content={<Box padding="base">{popperText}</Box>}
            theme="tooltip"
            width="fixed"
            placement="top-end"
            offset={[0, 4]}
            showContent={showTooltip}
        >
            <div
                className={styles['auto-opt-icon-container']}
                onMouseEnter={toggleShowTooltip}
                onMouseLeave={toggleShowTooltip}
            >
                <div className={styles['auto-opt-icon-background']}>
                    <SVGImage src={AutoOptimize} />
                </div>
            </div>
        </Popper>
    );
};
export default function DimensionPanelHeader({ origin }) {
    const {
        optimize: {
            current: { is_active: optimizeIsActive, recommendations },
        },
    } = useContext(ContextDetailContext);

    const {
        name: originName,
        description: originDescription,
        provider_name: providerName,
        provider_logo: providerLogo,
    } = origin;

    const descriptionByParagraphs = originDescription
        .split('\n')
        .filter((p) => p.trim().length);

    const showAutoOptActiveIcon =
        optimizeIsActive &&
        recommendations.length > 0 &&
        (origin.id === sectionTypes.KEYWORDS_DIMENSION ||
            sectionTypes.VIDEO_DIMENSION);

    return (
        <Box padding="base">
            <Row
                gutter="small"
                alignItems="center"
                justifyContent="space-between"
            >
                <Col span="auto">
                    <Row gutter="small" alignItems="center">
                        <Col span="auto">
                            <img
                                className={styles.logo}
                                src={providerLogo}
                                alt={providerName}
                            />
                        </Col>

                        <Col span="auto">
                            <Box padding={[0, 0, 0, 'small']}>
                                <Text weight="bolder" size="larger">
                                    {originName}
                                </Text>
                            </Box>
                        </Col>

                        {descriptionByParagraphs.length > 0 && (
                            <Col span="auto">
                                <InfoButton>
                                    {descriptionByParagraphs.map(
                                        (paragraph) => (
                                            <p key={paragraph}>{paragraph}</p>
                                        ),
                                    )}
                                </InfoButton>
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col span="auto">
                    <Row gutter="small" alignItems="center">
                        {showAutoOptActiveIcon && (
                            <Col span="auto">
                                <Box padding={[0, 'smallest']}>
                                    <AutoOptimizeIcon />
                                </Box>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Box>
    );
}
