import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './dropdown-menu.module.scss';

function DropdownTitle({ children }) {
    const classes = [styles.item, styles.title];

    return <li className={classNames(classes)}>{children}</li>;
}

DropdownTitle.defaultProps = {};

DropdownTitle.propTypes = {
    /**
     * The content of the DropdownTitle
     */
    children: PropTypes.node.isRequired,
};

export default DropdownTitle;
