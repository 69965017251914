import React, { useContext, useMemo, useState } from 'react';
import { origins } from 'js/constants';
import sectionTypes from 'js/enums/section-types.enum';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';
import SectionTitle from 'js/components/section-title/section-title';
import Text from 'js/components/text/text';
import DimensionPanelHeader from '../dimensions-panel/dimensions-panel-header';
import PageAnalyzerDrawer from '../page-analyzer-drawer/page-analyzer-drawer';
import RulesSection from './rules-section/rules-section';
import ContextDetailContext from '../../contexts/context-detail.context';
import MatchingStrategySection from './matching-strategy-section/matching-strategy-section';

function KeywordsDrawerSection({ title, description, buttonLabel, onClick }) {
    return (
        <SectionTitle
            info={description}
            extension={[
                <Button theme="outline" onClick={onClick}>
                    {buttonLabel}
                </Button>,
            ]}
        >
            <Text weight="bold" color={['gray', 'darkest']} size="large">
                {title}
            </Text>
        </SectionTitle>
    );
}

function KeywordsPanelBody({ focus }) {
    const {
        page: { isReadOnly },
    } = useContext(ContextDetailContext);

    const [csvError, setCsvError] = useState('');
    const [isPageAnalyzerVisible, setPageAnalyzerVisible] = useState(false);

    const togglePageAnalyzerVisible = () => {
        setPageAnalyzerVisible((state) => !state);
    };

    return (
        <>
            {!isReadOnly && (
                <Section theme="secondary" hasBorderTop hasBorderBottom>
                    <Row gutter="none">
                        <Col span={12}>
                            <Box padding="base">
                                <KeywordsDrawerSection
                                    title="Get Keywords From A Page"
                                    description={`Find out which keywords you could 
                                        use to target or block a specific page and 
                                        other pages like it.`}
                                    buttonLabel="Open Page Analyzer"
                                    onClick={togglePageAnalyzerVisible}
                                />
                            </Box>
                        </Col>
                    </Row>
                </Section>
            )}

            <MatchingStrategySection />

            {csvError && (
                <Box padding={['base', 'base', 0]}>
                    <Alert
                        title={csvError}
                        theme="danger"
                        actionItems={
                            <Button onClick={() => setCsvError('')}>
                                Dismiss
                            </Button>
                        }
                    >
                        <p>
                            No keywords have been added because your CSV could
                            not be processed. Please check the formatting of
                            your file, ensuring that keywords are separated by a
                            new line or comma, before trying again.
                        </p>
                    </Alert>
                </Box>
            )}

            {isPageAnalyzerVisible && (
                <PageAnalyzerDrawer onClose={togglePageAnalyzerVisible} />
            )}

            <RulesSection focus={focus} onCsvError={setCsvError} />
        </>
    );
}

export default function KeywordsPanel({ isVisible = true }) {
    const {
        priceStructure: { display_targeting_cpm_usd: targetPrice },
    } = useContext(ContextDetailContext);

    const keywordsOrigin = useMemo(
        () => ({
            ...origins.find(({ id }) => id === sectionTypes.KEYWORDS_DIMENSION),
            cpm_usd: targetPrice,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [targetPrice],
    );

    return (
        <>
            <DimensionPanelHeader origin={keywordsOrigin} />

            <KeywordsPanelBody focus={isVisible} />
        </>
    );
}
