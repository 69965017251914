import React from 'react';
import Box from 'js/components/box/box';
import BubbleChart from 'js/components/bubble-chart/bubble-chart';
import { DropdownTooltipContent } from '../../../components/dropdown-tooltip/dropdown-tooltip';
import styles from './keywords-panel.module.scss';

const KeywordBubbleTooltip = ({ data }) => {
    let d = [
        {
            title: 'Impressions',
            value: data.impressions,
            isDelta: false,
            comparedValue: data.totalImpressions,
        },
        {
            title: 'Clicks',
            value: data.clicks,
            isDelta: false,
            comparedValue: data.totalClicks,
        },
        {
            title: 'CTR',
            value: data.clicks / data.impressions,
            isDelta: true,
            comparedValue: data.totalClicks / data.totalImpressions,
        },
        {
            title: 'Viewable Impressions',
            value: data.inViews,
            isDelta: false,
            comparedValue: data.totalInViews,
        },
        {
            title: 'View Rate',
            value: data.inViews / data.impressions,
            isDelta: true,
            comparedValue: data.totalInViews / data.impressions,
        },
    ];

    if (!data.totalInViews) {
        d = d.slice(0, -2);
    }

    return (
        <div className={styles['content-container']}>
            <Box padding="base">
                <DropdownTooltipContent
                    dropdownData={{ label: data.label, data: d }}
                />
            </Box>
        </div>
    );
};

export default function KeywordBubblesSection({
    report: { keywords, impressions, clicks, in_views: inViews },
}) {
    const bubblesData = [...keywords]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 50)
        .map((keyword) => ({
            label: keyword.name,
            value: keyword.impressions / impressions,
            impressions: keyword.impressions,
            clicks: keyword.clicks,
            inViews: keyword.in_views,
            totalImpressions: impressions,
            totalClicks: clicks,
            totalInViews: inViews,
        }));

    return (
        <Box margin={['base', 0]}>
            <div className={styles['bubble-chart-container']}>
                <BubbleChart
                    data={bubblesData}
                    colors={{
                        low: '#6673FF',
                        high: '#6673FF',
                        active: '#242A6B',
                    }}
                    legend={{
                        lowLabel: 'Fewer Impressions',
                        highLabel: 'More Impressions',
                        position: 'bottom',
                    }}
                    viewport={[0.3, 0.25, 0.7, 0.75]}
                    isAnimated
                    tooltip={KeywordBubbleTooltip}
                />
            </div>
        </Box>
    );
}
