import React from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';

const NoMatchedVideosNotice = () => (
    <Alert theme="empty" title="Low Number Of Matching Videos">
        <Box margin={[0, 0, 'small']}>
            <p>
                Try targeting or blocking more topics in order to increase your
                chances of seeing matched videos.
            </p>
        </Box>
    </Alert>
);

export default NoMatchedVideosNotice;
