import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sleep } from 'js/utils';
import Box from 'js/components/box/box';
import Popper from 'js/components/popper/popper';

function CopyButton({ value, message, children }) {
    const [showTooltip, setShowTooltip] = useState(false);

    const copy = async () => {
        navigator.clipboard.writeText(value);

        if (message) {
            setShowTooltip(true);
            await sleep(4000);
            setShowTooltip(false);
        }
    };

    return (
        <Popper
            content={<Box padding={['small', 'base']}>{message}</Box>}
            showContent={showTooltip}
            theme="tooltip"
            placement="right"
            offset={[0, 4]}
        >
            {React.cloneElement(children, { onClick: copy })}
        </Popper>
    );
}

CopyButton.defaultProps = {
    message: '',
};

CopyButton.propTypes = {
    value: PropTypes.string.isRequired,
    message: PropTypes.node,
    children: PropTypes.node.isRequired,
};

export default CopyButton;
