import { useEffect } from 'react';

function useScrollObserver(ref, onScroll) {
    useEffect(() => {
        const scrollTarget = ref.current;
        scrollTarget.addEventListener('scroll', onScroll, true);

        return () => {
            scrollTarget.removeEventListener('scroll', onScroll, true);
        };
    }, [ref, onScroll]);
}

export default useScrollObserver;
