import React from 'react';

import AdformLogo from 'media/images/logos/adform-logo.svg';
import IristvLogo from 'media/images/logos/iristv.png';
import TreasuredataLogo from 'media/images/logos/treasuredata.png';
import XandrLogo from 'media/images/logos/xandr-logo.svg';
import YoutubeLogo from 'media/images/logos/youtube.png';

import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import StatusCard from './status-card';
import styles from '../status-list.module.scss';

export const statusMap = {
    xandrAsync: {
        title: (
            <>
                <img className={styles.logo} src={XandrLogo} alt="Xandr Logo" />{' '}
                Async
            </>
        ),
        description: (
            <>
                <p>
                    This service provides 4D segment data for each deal back to
                    Xandr.
                </p>
                <p>
                    If there is an error, deals cannot be created or updated in
                    Xandr.
                </p>
            </>
        ),
    },
    xandrRealtime: {
        title: (
            <>
                <img className={styles.logo} src={XandrLogo} alt="Xandr Logo" />{' '}
                Realtime
            </>
        ),
        description: (
            <>
                <p>
                    This service processes and uploads 4D segment data to our
                    Xandr endpoint.
                </p>
                <p>
                    If there is an error, segment data (Context matches) will
                    not be available to the Xandr endpoint.
                </p>
            </>
        ),
    },
    xandrVideobeet: {
        title: (
            <>
                <img className={styles.logo} src={XandrLogo} alt="Xandr Logo" />{' '}
                Videobeet
            </>
        ),
        description: (
            <>
                <p>
                    This service provides 4D segment data for each deal back to
                    the Xandr EMEA Videobeet seat.
                </p>
                <p>
                    If there is an error, deals specific to Videobeet cannot be
                    created or updated in Xandr.
                </p>
            </>
        ),
    },
    iristv: {
        title: (
            <img className={styles.logo} src={IristvLogo} alt="IrisTV Logo" />
        ),
        description: (
            <>
                <p>
                    This service provides 4D segment data for each deal back to
                    IrisTV.
                </p>
                <p>
                    If there is an error, segment data (Context matches) cannot
                    be sent to IrisTV.
                </p>
            </>
        ),
    },
    youtube: {
        title: (
            <img className={styles.logo} src={YoutubeLogo} alt="Youtube Logo" />
        ),
        description: (
            <>
                <p>This service records YouTube video context data.</p>
                <p>
                    If there is an error, downstream YouTube video lists cannot
                    be generated.
                </p>
            </>
        ),
    },
    adform: {
        title: (
            <img className={styles.logo} src={AdformLogo} alt="Adform Logo" />
        ),
        description: (
            <>
                <p>This service provides 4D segment data back to Adform.</p>
                <p>
                    If there is an error, segment data (Context matches) cannot
                    be sent to Adform.
                </p>
            </>
        ),
    },
    treasuredata: {
        title: (
            <img
                className={styles.logo}
                src={TreasuredataLogo}
                alt="Treasure Data Logo"
            />
        ),
        description: (
            <>
                <p>
                    This service provides 4D context matches from customer urls.
                </p>
                <p>
                    If there is an error, enriched URL data (Context matches)
                    cannot be generated.
                </p>
            </>
        ),
    },

    eventEndpoint: {
        title: 'Event',
        description: (
            <>
                <p>
                    This endpoint receives 4D Display Tag data and 4D Video Tag
                    for Insights Reports.
                </p>
                <p>If there is an error, Insights Reports will be blank.</p>
            </>
        ),
    },
    iristvEndpoint: {
        title: (
            <img className={styles.logo} src={IristvLogo} alt="IrisTV Logo" />
        ),
        description: (
            <>
                <p>This endpoint receives data from IrisTV.</p>
                <p>If there is an error, IrisTV deals will not be populated.</p>
            </>
        ),
    },
    serveEndpoint: {
        title: 'Serve',
        description: (
            <>
                <p>This endpoint generates and hosts the 4D VAST Video Tag.</p>
                <p>
                    If there is an error, customers&apos; creatives will not
                    render but the customer may still be charged.
                </p>
            </>
        ),
    },
    xandrEndpoint: {
        title: <img className={styles.logo} src={XandrLogo} alt="Xandr Logo" />,
        description: (
            <>
                <p>
                    This endpoint receives URL data from Xandr and responds with
                    4D segment data (Context matches).
                </p>
                <p>
                    If there is an error, 4D Xandr deals will not be populated.
                </p>
            </>
        ),
    },
    treasuredataEndpoint: {
        title: (
            <img
                className={styles.logo}
                src={TreasuredataLogo}
                alt="Treasure Data Logo"
            />
        ),
        description: (
            <>
                <p>
                    This endpoint generates secure upload/download links for 4D
                    Treasure Data customers.
                </p>
                <p>
                    If there is an error, data cannot be received from Treasure
                    Data.
                </p>
            </>
        ),
    },

    displayContexts: {
        title: 'Context Matching (Display)',
        description: (
            <>
                <p>
                    URLs from display ad opportunities are being matched to
                    Contexts.
                </p>
                <p>
                    If there is an error, all display services will not respond
                    with updated Context matches.
                </p>
            </>
        ),
        statuses: ['Matching is occurring.', 'Matching is not occurring.'],
    },
    videoContexts: {
        title: 'Context Matching (Video)',
        description: (
            <>
                <p>
                    URLs from video ad opportunities are being matched to
                    Contexts.
                </p>
                <p>
                    If there is an error, all video services will not respond
                    with updated Context matches.
                </p>
            </>
        ),
        statuses: ['Matching is occurring.', 'Matching is not occurring.'],
    },
    insights: {
        title: 'Insights',
        description: (
            <>
                <p>Insights Reports can be processed.</p>
                <p>
                    If there is an error, Insights Reports cannot be processed.
                </p>
            </>
        ),
        statuses: [
            'Reports are generating. Report queue is up.',
            'Reports are not generating. Report queue is down.',
        ],
    },
};

function StatusPanel({ title, description, statuses, getChart, getStatuses }) {
    return (
        <Box padding={['large', 0]}>
            <Box margin={[0, 0, 'large']}>
                <SectionTitle info={description} hasBar>
                    {title}
                </SectionTitle>
            </Box>

            <Panel theme="secondary">
                <Box padding={['small', 'base']}>
                    <Row>
                        {statuses.map((item) => {
                            const status = statusMap[item.id];

                            return (
                                <Col
                                    key={item.id}
                                    span={12}
                                    spanSm={12}
                                    spanMd={6}
                                    spanLg={4}
                                >
                                    <div
                                        className={
                                            styles['status-card-wrapper']
                                        }
                                    >
                                        <StatusCard
                                            title={status.title}
                                            description={status.description}
                                            chart={getChart(item.data)}
                                            statuses={getStatuses(
                                                item.data,
                                                item.id,
                                            )}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Box>
            </Panel>
        </Box>
    );
}

export default StatusPanel;
