import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { permissions } from 'js/constants';
import { getJwtPayload } from 'js/utils';
import Pill from 'js/components/pill/pill';
import CardBase from './card-base';
import styles from './card.module.scss';

const getBadges = (user) => {
    const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
    const { email } = user;
    const isCurrentUser = email === jwt.email;
    return (
        <>
            {isCurrentUser && <Pill theme="muted">You</Pill>}
            {permissions
                .filter((i) => user.roles.includes(i.value))
                .map(({ label, pillOutline, pillTheme }) => (
                    <Pill outline={pillOutline} theme={pillTheme} key={label}>
                        {label}
                    </Pill>
                ))}
        </>
    );
};

function CardUser({ user, isReadOnly }) {
    const { first_name: firstName, last_name: lastName, id, email } = user;
    const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
    const isCurrentUser = email === jwt.email;
    const to = isCurrentUser
        ? `/settings/your-profile/`
        : `/settings/users/${id}`;

    if (isReadOnly && !isCurrentUser) {
        return (
            <CardBase type="user">
                <div className={styles.content}>
                    {(firstName || lastName) && (
                        <p className={styles.topline}>
                            {firstName} {lastName}
                        </p>
                    )}

                    <h3 className={styles.title}>{email}</h3>
                </div>

                <div className={styles.badge}>{getBadges(user)}</div>
            </CardBase>
        );
    }

    return (
        <Link to={to} title={email}>
            <CardBase type="user">
                <div className={styles.content}>
                    {(firstName || lastName) && (
                        <p className={styles.topline}>
                            {firstName} {lastName}
                        </p>
                    )}

                    <h3 className={styles.title}>{email}</h3>
                </div>

                <div className={styles.badge}>{getBadges(user)}</div>
            </CardBase>
        </Link>
    );
}

CardUser.defaultProps = {};

CardUser.propTypes = {
    /**
     * The user object (from the 4D api) represented by the card
     */
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        email: PropTypes.string.isRequired,
        roles: PropTypes.array.isRequired,
    }).isRequired,
};

export default CardUser;
