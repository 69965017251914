import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import Label from '../label/label';

let instanceId = 0;

function Input({
    type,
    value,
    label,
    hint,
    info,
    prefix,
    maxLength,
    required,
    autoComplete,
    placeholder,
    isActive,
    focusOnShow,
    hasError,
    errorMessage,
    onClear,
    onSetInputRef,
    ...inputProps
}) {
    const inputElement = useRef();
    const [thisInstanceId, setThisInstanceId] = useState('');
    const focusInput = () => {
        inputElement.current?.focus();
    };
    useEffect(() => {
        instanceId += 1;
        setThisInstanceId(`input-${instanceId}`);
    }, []);
    useEffect(() => {
        if (focusOnShow) {
            focusInput();
        }
    }, [focusOnShow]);
    useEffect(() => {
        if (onSetInputRef) {
            onSetInputRef(inputElement);
        }
    }, [onSetInputRef]);
    const classes = [styles.container];
    const inputClasses = [];
    if (hasError) {
        classes.push(styles['has-error']);
    }
    if (isActive) {
        classes.push(styles['is-active']);
    }
    if (prefix) {
        inputClasses.push(styles['has-prefix']);
    }
    if (onClear && value && value.length) {
        inputClasses.push(styles['has-clear-button']);
    }

    return (
        <Label
            errorMessage={errorMessage}
            forId={thisInstanceId}
            hasError={hasError}
            hint={hint}
            info={info}
            label={label}
            required={required}
        >
            <div className={classNames(classes)}>
                <input
                    {...inputProps}
                    id={thisInstanceId}
                    value={value}
                    className={classNames(inputClasses)}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    ref={inputElement}
                    type={type}
                    autoComplete={autoComplete}
                />
                {prefix ? <div className={styles.prefix}>{prefix}</div> : null}
                {onClear && value && value.length ? (
                    <div className={styles['clear-button-container']}>
                        <button
                            type="button"
                            onClick={() => {
                                focusInput();
                                onClear();
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                ) : null}
            </div>
        </Label>
    );
}

Input.defaultProps = {
    type: 'text',
    value: undefined,
    label: undefined,
    hint: undefined,
    info: undefined,
    prefix: undefined,
    maxLength: undefined,
    required: false,
    placeholder: undefined,
    autoComplete: 'off',
    isActive: false,
    focusOnShow: false,
    hasError: false,
    errorMessage: undefined,
    onClear: undefined,
    onSetInputRef: undefined,
};

Input.propTypes = {
    type: PropTypes.oneOf(['text', 'password', 'number']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.node,
    hint: PropTypes.node,
    info: PropTypes.node,
    prefix: PropTypes.node,
    maxLength: PropTypes.number,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    isActive: PropTypes.bool,
    focusOnShow: PropTypes.bool,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.node,
    onClear: PropTypes.func,
    onSetInputRef: PropTypes.func,
};

export default Input;
