import React, { useState } from 'react';
import { INITIALLY_VISIBLE_LOGOS } from 'js/constants';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import { useLimitList } from 'js/hooks';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import TopicButton from 'js/components/button/topic-button';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import DropdownTitle from 'js/components/dropdown-menu/title';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import styles from '../summary-footer.module.scss';

function Logos({ rule, logos, onRemoveLogo }) {
    const isTargeting = rule.aggregation === ruleTypes.INCLUDED;

    const filteredLogos = logos.filter((logo) =>
        rule.logos.find((logoId) => logoId === logo.logo),
    );

    const [
        hasMoreLogos,
        isLogosExpanded,
        visibleLogos,
        showMoreLogos,
        showLessLogos,
    ] = useLimitList(filteredLogos, INITIALLY_VISIBLE_LOGOS);

    return (
        <>
            {visibleLogos.map((logo) => (
                <Box
                    key={logo.logo}
                    margin={[0, 'small', 'small', 0]}
                    display="inline-block"
                >
                    <TopicButton
                        theme={isTargeting ? 'targeted' : 'blocked'}
                        action="remove"
                        onClick={() => onRemoveLogo(logo.logo)}
                    >
                        {logo.logo_name}
                    </TopicButton>
                </Box>
            ))}

            {hasMoreLogos && (
                <Row>
                    <Col span="auto">
                        <Button
                            theme="outline"
                            size="small"
                            onClick={showMoreLogos}
                        >
                            Show All {filteredLogos.length} Brands
                        </Button>
                    </Col>
                </Row>
            )}

            {isLogosExpanded && (
                <Row>
                    <Col span="auto">
                        <Button
                            theme="outline"
                            size="small"
                            onClick={showLessLogos}
                        >
                            Show Fewer Brands
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
}

function LogoDropdown({ logo, isTargeting, showAdded, onAdd, onRemove }) {
    const { logo_name: name } = logo;
    const [showMenu, setShowMenu] = useState(false);

    let theme;
    if (showAdded) {
        theme = isTargeting ? 'targeted' : 'blocked';
    } else {
        theme = 'default';
    }

    const buttonActionIcon = theme === 'default' ? 'add' : 'remove';

    return (
        <DropdownMenu
            content={
                <ul className={styles.dropdown}>
                    <DropdownTitle>{name}</DropdownTitle>

                    <DropdownDivider />

                    <DropdownButton onClick={onAdd}>
                        Add Back To This Group
                    </DropdownButton>
                </ul>
            }
            showMenu={showMenu}
            onHide={() => setShowMenu(false)}
        >
            <TopicButton
                theme={theme}
                action={buttonActionIcon}
                active={showMenu}
                onClick={showAdded ? onRemove : () => setShowMenu(true)}
            >
                {name}
            </TopicButton>
        </DropdownMenu>
    );
}

function RuleLogosChanges({ rule, logos, showAdded, onAddLogo, onRemoveLogo }) {
    const { aggregation, isNew } = rule;
    const isTargeting = aggregation === ruleTypes.INCLUDED;
    const changedLogos = showAdded ? rule.addedLogos : rule.removedLogos;

    if (!changedLogos.length) return null;

    return (
        <Box margin={['small', 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    {showAdded ? (
                        <Text>
                            {isNew
                                ? 'This is a new group with the following brands'
                                : 'The following brands have been added to this group'}
                        </Text>
                    ) : (
                        <Text>
                            The following brands have been removed from this
                            group
                        </Text>
                    )}

                    {changedLogos.map((logoId) => {
                        const logo = logos.find((l) => l.logo === logoId);

                        if (!logo) return null;

                        return (
                            <Box
                                key={logoId}
                                margin={['small', 'small', 0, 0]}
                                display="inline-block"
                            >
                                <LogoDropdown
                                    logo={logo}
                                    isTargeting={isTargeting}
                                    showAdded={showAdded}
                                    onAdd={() => onAddLogo(logoId)}
                                    onRemove={() => onRemoveLogo(logoId)}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Panel>
        </Box>
    );
}

function LogosChanges({
    context,
    contextChanges,
    logos,
    onChangeContextRules,
}) {
    const { rules } = context;
    const { rules: changedRules } = contextChanges;

    const addLogo = (ruleKey, logo) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(ruleKey, { addLogos: [logo] });
        onChangeContextRules(ruleSet.rules);
    };

    const removeLogo = (ruleKey, logo) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(ruleKey, { removeLogos: [logo] });
        onChangeContextRules(ruleSet.rules);
    };

    if (
        !changedRules.map((rule) => rule.logos).flat().length &&
        !changedRules.map((rule) => rule.addedLogos).flat().length &&
        !changedRules.map((rule) => rule.removedLogos).flat().length
    ) {
        return null;
    }

    return (
        <Box margin={['large', 0]}>
            <Panel bordered>
                <Box padding="base">
                    <Text size="larger" weight="bolder">
                        Brands
                    </Text>

                    {changedRules.map((rule, index) => {
                        if (
                            !rule.logos.length &&
                            !rule.addedLogos.length &&
                            !rule.removedLogos.length
                        )
                            return null;

                        const isTargeting =
                            rule.aggregation === ruleTypes.INCLUDED;
                        const title = isTargeting
                            ? 'Target content featuring'
                            : 'Block content featuring';

                        return (
                            <Box margin={['base', 0]} key={rule.key}>
                                <Panel bordered>
                                    <Box padding="base">
                                        {index === 0 ? (
                                            <Text size="large" weight="bold">
                                                {title}
                                            </Text>
                                        ) : (
                                            <Text size="large" weight="bold">
                                                and{' '}
                                                {index ===
                                                    changedRules.length - 1 &&
                                                rule.aggregation !==
                                                    changedRules[index - 1]
                                                        .aggregation
                                                    ? title.toLowerCase()
                                                    : ''}
                                            </Text>
                                        )}

                                        <RuleLogosChanges
                                            rule={rule}
                                            logos={logos}
                                            showAdded
                                            onAddLogo={(logo) =>
                                                addLogo(rule.key, logo)
                                            }
                                            onRemoveLogo={(logo) =>
                                                removeLogo(rule.key, logo)
                                            }
                                        />

                                        <RuleLogosChanges
                                            rule={rule}
                                            logos={logos}
                                            onAddLogo={(logo) =>
                                                addLogo(rule.key, logo)
                                            }
                                            onRemoveLogo={(logo) =>
                                                removeLogo(rule.key, logo)
                                            }
                                        />

                                        <Logos
                                            rule={rule}
                                            logos={logos}
                                            onRemoveLogo={(logo) =>
                                                removeLogo(rule.key, logo)
                                            }
                                        />
                                    </Box>
                                </Panel>
                            </Box>
                        );
                    })}
                </Box>
            </Panel>
        </Box>
    );
}

export default LogosChanges;
