import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ClickArea from 'js/components/click-area/click-area';
import PropTypes from 'prop-types';
import styles from './help-dropdown.module.scss';

const TitleBar = ({ isOpen, onClick, children }) => (
    <div className={styles.help}>
        <ClickArea onClick={onClick}>
            <div className={styles['title-bar']}>
                <span className={styles.title}>{children}</span>
                <div className={styles.bar} />
                <span className={styles.chevron}>
                    <FontAwesomeIcon
                        icon={isOpen ? faChevronUp : faChevronDown}
                    />
                </span>
            </div>
        </ClickArea>
    </div>
);

TitleBar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default TitleBar;
