import React, { Fragment } from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';

const getLowMatchedPagesSuggestions = ({ context: { rules } }) => {
    const suggestions = [];
    const isKeywordsAdded =
        rules.filter((item) => item.keywords.length).length > 0;

    if (isKeywordsAdded)
        suggestions.push({
            title: 'Keywords',
            message:
                'Reduce the number of keyword groups, add more keywords to each blocking group or reduce the number of allowed keywords.',
        });

    return suggestions;
};

const LowMatchedPages = ({ context, channels }) => {
    const suggestions = getLowMatchedPagesSuggestions({
        context,
        channels,
    });

    if (!suggestions.length) return null;

    return (
        <>
            <Box margin={[0, 0, 'small']}>
                <p>
                    Here are some things that you can do to improve the chance
                    of matching more pages:
                </p>
            </Box>

            {suggestions.map(({ title, message }) => (
                <Fragment key={title}>
                    <Box margin={[0, 0, 'small']}>
                        <Text weight="bolder">{title}</Text>

                        <p>{message}</p>
                    </Box>
                </Fragment>
            ))}
        </>
    );
};

const NoMatchedPagesNotice = ({ context, channels }) => (
    <Alert theme="empty" title="Low Number Of Matching Pages">
        <LowMatchedPages context={context} channels={channels} />
    </Alert>
);

export default NoMatchedPagesNotice;
