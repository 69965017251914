import React, { useEffect, useState } from 'react';
import { reloadPage } from 'js/utils';
import {
    notificationCategoryGroups,
    notificationCategoryTypes,
} from 'js/enums/notification-categories.enum';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Navigation from 'js/components/navigation/navigation';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import Spinner from 'js/components/spinner/spinner';
import SwitchButton from 'js/components/button/switch-button';
import TopBar from 'js/components/top-bar/top-bar';
import Table from 'js/components/table/table';
import TableRow from 'js/components/table/table-row';
import Text from 'js/components/text/text';

const Wrapper = ({ saving, children }) => (
    <Layout
        sidebar={<Navigation />}
        header={
            <TopBar
                breadcrumbs={[
                    <Crumb key="home" to="/" label="4D" />,
                    <Crumb
                        key="settings"
                        to="/settings/"
                        label="Settings"
                        isCollapsible
                    />,
                    <Crumb
                        key="profile"
                        to="/settings/your-profile/"
                        label="Your Profile"
                        isCollapsible
                    />,
                    <Crumb key="notifications" label="Notifications" />,
                ]}
            />
        }
    >
        {saving && <LoadingLayer message="Saving Settings" />}

        <Container size="medium">
            <Box padding={['large', 0]}>{children}</Box>
        </Container>
    </Layout>
);

const NotificationSettingRow = ({
    label,
    isInApp,
    isEmail,
    hasBorder,
    onToggleIsInApp,
    onToggleIsEmail,
}) => (
    <Box padding={[0, 'base']}>
        <TableRow hasBorder={hasBorder}>
            <Text color={['gray', 'darker']}>{label}</Text>
            <SwitchButton
                size="small"
                active={isInApp}
                disabled={!onToggleIsInApp}
                onChange={onToggleIsInApp || (() => {})}
            >
                In App
            </SwitchButton>
            <SwitchButton
                size="small"
                active={isEmail}
                disabled={!onToggleIsEmail}
                onChange={onToggleIsEmail || (() => {})}
            >
                Email
            </SwitchButton>
        </TableRow>
    </Box>
);

export default function UserSettingsNotifications({
    isLoading,
    isSaving,
    hasLoadingError,
    hasSavingError,
    userSettings,
    onSave,
}) {
    const [notificationSettings, setNotificationSettings] = useState({});
    useEffect(
        () => setNotificationSettings({ ...userSettings }),
        [userSettings],
    );

    const getSetting = (isInApp, isEmail) => {
        if (isInApp && isEmail) return 'both';
        if (isInApp) return 'in_app';
        if (isEmail) return 'email';
        return 'none';
    };

    const getIsInApp = ({ supportsInApp, settingId }) =>
        settingId
            ? ['in_app', 'both'].includes(notificationSettings[settingId])
            : supportsInApp;

    const getIsEmail = ({ supportsEmail, settingId }) =>
        settingId
            ? ['email', 'both'].includes(notificationSettings[settingId])
            : supportsEmail;

    const toggleIsInApp = ({ supportsInApp, supportsEmail, settingId }) => {
        const isInApp = getIsInApp({ supportsInApp, settingId });
        const isEmail = getIsEmail({ supportsEmail, settingId });
        setNotificationSettings({
            ...notificationSettings,
            [settingId]: getSetting(!isInApp, isEmail),
        });
    };

    const toggleIsEmail = ({ supportsInApp, supportsEmail, settingId }) => {
        const isInApp = getIsInApp({ supportsInApp, settingId });
        const isEmail = getIsEmail({ supportsEmail, settingId });
        setNotificationSettings({
            ...notificationSettings,
            [settingId]: getSetting(isInApp, !isEmail),
        });
    };

    if (isLoading) {
        return (
            <Wrapper saving={isSaving}>
                <Spinner
                    size="large"
                    message="Loading Your Settings"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Wrapper>
        );
    }

    if (hasLoadingError) {
        return (
            <Wrapper saving={isSaving}>
                <Alert
                    theme="danger"
                    title="There was an error while loading your settings"
                    actionItems={
                        <Button onClick={reloadPage}>
                            Retry Loading Settings
                        </Button>
                    }
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try
                        loading the page again in a few moments.
                    </p>
                </Alert>
            </Wrapper>
        );
    }

    return (
        <Wrapper saving={isSaving}>
            <Box padding={[0, 0, 'large']}>
                <SectionTitle
                    hasBar
                    info="Choose what type of messages you want to receive from 4D."
                >
                    Notifications
                </SectionTitle>
            </Box>

            <Panel bordered>
                <Table noWrap firstColFluidWidth verticallyCentered>
                    {Object.values(notificationCategoryGroups)
                        .filter(
                            (item) => item !== notificationCategoryGroups.ALL,
                        )
                        .map((group) => (
                            <Box
                                padding={['small', 0, 'small', 'base']}
                                key={group}
                            >
                                <TableRow isHeader>
                                    <Text weight="bold" size="larger">
                                        {group}
                                    </Text>
                                </TableRow>

                                {notificationCategoryTypes
                                    .filter((item) => item.group === group)
                                    .map((item, index, items) => (
                                        <NotificationSettingRow
                                            key={item.id}
                                            label={item.label}
                                            hasBorder={index < items.length - 1}
                                            isInApp={getIsInApp(item)}
                                            isEmail={getIsEmail(item)}
                                            onToggleIsInApp={
                                                item.supportsInApp &&
                                                item.settingId
                                                    ? () => toggleIsInApp(item)
                                                    : undefined
                                            }
                                            onToggleIsEmail={
                                                item.supportsEmail &&
                                                item.settingId
                                                    ? () => toggleIsEmail(item)
                                                    : undefined
                                            }
                                        />
                                    ))}
                            </Box>
                        ))}
                </Table>
            </Panel>

            {hasSavingError && (
                <Box margin={[0, 0, 'large']}>
                    <Alert theme="danger">
                        <p>
                            You were unable to save your settings because there
                            was an unexpected error. Please try again in a few
                            moments.
                        </p>
                    </Alert>
                </Box>
            )}

            <Box margin={['large', 0, 0]}>
                <Row justifyContent="flex-end">
                    <Col span="auto">
                        <Button
                            type="submit"
                            onClick={() => onSave({ ...notificationSettings })}
                        >
                            Save Settings
                        </Button>
                    </Col>
                </Row>
            </Box>
        </Wrapper>
    );
}
