import React, { useCallback, useContext, useEffect, useState } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api, AbortError, deepCopy } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import RuleSelector from 'js/components/dropdown-menu/rule-selector';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import Keyword from './keyword';
import ContextDetailContext from '../../../contexts/context-detail.context';
import styles from './rules-section.module.scss';

const RELATED_KEYWORD_LIST_LIMIT = 6;

const EmptyMessage = () => (
    <Box
        padding={['small', 'base']}
        border={['base', 'dashed', 'gray', 'lighter']}
        borderRadius="round"
    >
        <Text color={['gray', 'dark']}>
            This is where we would show you some related keywords, but it looks
            like you’re targeting or blocking them all already, or we weren’t
            able to identify any in the first place.
        </Text>
    </Box>
);

const ErrorMessage = ({ onRetry }) => (
    <>
        <Text color={['red', 'base']}>
            <Box margin={['small', 0]}>
                <p>There was an unexpected error when loading data.</p>
            </Box>

            <Box margin={['small', 0]}>
                <p>
                    Hopefully it is only a temporary issue. Please try again in
                    a few moments.
                </p>
            </Box>
        </Text>

        <Button onClick={onRetry}>Retry</Button>
    </>
);

const ListHeader = ({ keyword, history, onGoBack }) => (
    <Box margin={[0, 0, 'small']}>
        <Row gutter="small" alignItems="center">
            {history.length > 0 && (
                <Col span="auto">
                    <Button
                        theme="outline"
                        square
                        size="small"
                        onClick={() => onGoBack()}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                </Col>
            )}

            <Col>
                <Text color={['gray', 'dark']}>
                    Keywords related to “<em>{keyword}</em>”
                </Text>
            </Col>
        </Row>
    </Box>
);

const ListContent = ({
    rule,
    relatedWords,
    isLoading,
    hasError,
    onOpenNewKeyword,
    onRetry,
}) => {
    const {
        context: { current: context, setRules },
        topics: { groups: topicGroups },
    } = useContext(ContextDetailContext);
    const { rules } = context;

    const usedRelatedWords = relatedWords.filter((word) =>
        rules.some(({ keywords }) => keywords.includes(word)),
    );
    const visibleRelatedWords = relatedWords
        .filter((word) => !usedRelatedWords.includes(word))
        .slice(0, RELATED_KEYWORD_LIST_LIMIT);

    if (hasError) {
        return <ErrorMessage onRetry={onRetry} />;
    }

    if (isLoading) {
        return (
            <Spinner
                size="small"
                message="Loading"
                color={['gray', 'dark']}
                isCentered
            />
        );
    }

    if (!visibleRelatedWords.length) {
        return <EmptyMessage />;
    }

    return visibleRelatedWords.map((word) => (
        <Box key={word} margin={[0, 0, 'smaller']}>
            <Keyword
                keyword={word}
                rule={rule}
                onPrefixClick={() => onOpenNewKeyword(word)}
                suffixContent={
                    <div className={styles.dropdown}>
                        <RuleSelector
                            keyword={word}
                            targetRuleKey={rule.key}
                            topicGroups={topicGroups}
                            rules={rules}
                            onChangeRules={setRules}
                        />
                    </div>
                }
            />
        </Box>
    ));
};

const EditKeywordForm = ({ initialKeyword, onUpdateKeyword }) => {
    const [keyword, setKeyword] = useState(initialKeyword);

    return (
        <li>
            <Box padding={['small', 'base']}>
                <Box margin={[0, 0, 'small']}>
                    <Text color={['gray', 'dark']}>Edit</Text>
                </Box>

                <Form onSubmit={() => onUpdateKeyword(keyword)}>
                    <Row gutter="small">
                        <Col>
                            <Input
                                focusOnShow
                                onChange={(e) => setKeyword(e.target.value)}
                                value={keyword}
                            />
                        </Col>

                        <Col span="auto">
                            <Button type="submit">Apply</Button>
                        </Col>
                    </Row>
                </Form>
            </Box>
        </li>
    );
};

const RelatedKeywordList = ({
    relatedKeyword,
    rule,
    onChangeRelatedKeyword,
}) => {
    const {
        context: { current: context },
    } = useContext(ContextDetailContext);
    const { language } = context;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [relations, setRelations] = useState([]);
    const [history, setHistory] = useState([]);

    const getRelatedKeywords = useCallback(
        async (word) => {
            if (word in relations) return;

            setIsLoading(true);
            setHasError(false);

            try {
                const { results } = await api().relatedAnalytics.create({
                    language,
                    keywords: word.split(' '),
                });
                const relatedWords = results
                    .map((item) => item.entity)
                    .filter((item) => !item.includes("'"));

                setRelations({
                    ...deepCopy(relations),
                    [word]: relatedWords,
                });
            } catch (errors) {
                // there are no matches for this keyword
                if (errors.count === 0) {
                    setRelations({ ...deepCopy(relations), [word]: [] });
                    setIsLoading(false);
                    return;
                }
                if (errors instanceof AbortError) return;
                setHasError(true);
            }

            setIsLoading(false);
        },
        [language, relations],
    );

    const openNewKeyword = (newKeyword) => {
        onChangeRelatedKeyword(newKeyword);
        setHistory((prev) => [...prev, relatedKeyword]);
    };

    const openPreviousKeyword = () => {
        const newHistory = [...history];
        onChangeRelatedKeyword(newHistory.pop());
        setHistory(newHistory);
    };

    useEffect(() => {
        if (!(relatedKeyword in relations)) {
            getRelatedKeywords(relatedKeyword);
        }
    }, [getRelatedKeywords, relatedKeyword, relations]);

    return (
        <li>
            <Box padding={['small', 'base']}>
                <ListHeader
                    keyword={relatedKeyword}
                    history={history}
                    onGoBack={openPreviousKeyword}
                />

                <ListContent
                    rule={rule}
                    relatedWords={relations[relatedKeyword] || []}
                    isLoading={isLoading}
                    hasError={hasError}
                    onOpenNewKeyword={openNewKeyword}
                    onRetry={() => getRelatedKeywords(relatedKeyword)}
                />
            </Box>
        </li>
    );
};

const RuleRelatedKeywordsDropdown = ({
    rule,
    keyword: initialKeyword,
    onUpdateKeyword,
}) => {
    const [relatedKeyword, setRelatedKeyword] = useState(initialKeyword);

    return (
        <ul className={styles.dropdown}>
            {onUpdateKeyword && (
                <>
                    <EditKeywordForm
                        initialKeyword={initialKeyword}
                        onUpdateKeyword={onUpdateKeyword}
                    />

                    <DropdownDivider />
                </>
            )}

            <RelatedKeywordList
                relatedKeyword={relatedKeyword}
                rule={rule}
                onChangeRelatedKeyword={setRelatedKeyword}
            />
        </ul>
    );
};

export default RuleRelatedKeywordsDropdown;
