import React, { useContext, useEffect, useState } from 'react';
import {
    faCheckCircle,
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from 'js/components/box/box';
import ClickArea from 'js/components/click-area/click-area';
import Text from 'js/components/text/text';
import Counter from 'js/components/counter/counter';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Spinner from 'js/components/spinner/spinner';
import CopyButton from 'js/components/button/copy-button';
import Button from 'js/components/button/button';
import styles from './channels.module.scss';
import { autoDealProviderTypes } from '../../../constants';
import ContextDetailContext from '../../../contexts/context-detail.context';
import ChannelPanel from './channel-panel';

const TTDAlertPanel = ({ integrationChannels, contextChannels, contextId }) => {
    const isIntegrationChannelEnabled = integrationChannels.some((ic) =>
        contextChannels.includes(ic.id),
    );

    if (!isIntegrationChannelEnabled) return null;

    let alertIcon = null;
    let alertMessage = null;
    let alertButton = null;

    alertIcon = contextId ? (
        <FontAwesomeIcon icon={faCheckCircle} size="2x" />
    ) : (
        <Spinner />
    );
    alertMessage = contextId ? (
        <>
            Your TTD custom category ID is{' '}
            <Text weight="bold" inline>
                sbds-{contextId.toLowerCase()}
            </Text>
        </>
    ) : (
        'Your TTD custom category ID will be displayed here after the context has been successfully created.'
    );
    if (contextId) {
        alertButton = (
            <CopyButton
                value={`sbds-${contextId.toLowerCase()}`}
                message="Your TTD custom ID has been copied to your clipboard."
            >
                <Button theme="outline">Copy ID</Button>
            </CopyButton>
        );
    }

    return (
        <Box
            padding="base"
            background={['purple', 'lightest']}
            borderRadius="rounder"
            margin="base"
        >
            <Row alignItems="center">
                <Col span="auto">
                    <Text color={['purple', 'base']} size="large">
                        {alertIcon}
                    </Text>
                </Col>
                <Col>
                    <Text color={['purple', 'darker']}>{alertMessage}</Text>
                </Col>
                {alertButton && <Col span="auto">{alertButton}</Col>}
            </Row>
        </Box>
    );
};

export default function Channels({
    channels,
    isDirect,
    isReadOnly,
    onCreateDeal,
    onActivateDeal,
    onSetChannelGeotargets,
    onSetChannels,
}) {
    const {
        context: {
            current: {
                channels: contextChannels,
                is_video: isVideo,
                id: contextId,
            },
        },
    } = useContext(ContextDetailContext);

    const integrations = [];

    channels.forEach((channel) => {
        const integration = integrations.find(
            (i) => i.id === channel.integration,
        );

        if (integration) {
            integration.channelsList.push(channel);
        } else {
            integrations.push({
                id: channel.integration,
                name: channel.name,
                logo: channel.logo,
                deal_providers: channel.deal_providers,
                channelsList: [channel],
            });
        }
    });

    const [expandedState, setExpandedState] = useState({});

    const isAutoDealProvider = (provider) =>
        autoDealProviderTypes.includes(provider.provider_type);

    const integrationRemainsOpen = (integrationChannelList) =>
        integrationChannelList.some((channel) =>
            contextChannels.includes(channel.id),
        );

    const toggleExpandedState = (channelId) => {
        setExpandedState({
            ...expandedState,
            [channelId]: !expandedState[channelId],
        });
    };

    const toggleChannel = (channelId) => {
        const newContextChannels = contextChannels.includes(channelId)
            ? contextChannels.filter((channel) => channel !== channelId)
            : [...contextChannels, channelId];

        onSetChannels(newContextChannels);
    };

    useEffect(() => {
        const initialExpandedState = {};
        integrations.forEach((integration) => {
            initialExpandedState[integration.id] = integrationRemainsOpen(
                integration.channelsList,
            );
        });

        setExpandedState(initialExpandedState);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <>
            {integrations.map(
                ({
                    id,
                    name,
                    logo,
                    channelsList,
                    deal_providers: dealProviders,
                }) => {
                    const selectedChannelsCount = channelsList.reduce(
                        (count, channel) =>
                            count +
                            Number(contextChannels.includes(channel.id)),
                        0,
                    );

                    const filteredDealProviders = dealProviders
                        .filter(
                            (p) =>
                                p.is_video === isVideo || p.is_video === null,
                        )
                        .sort(
                            (a, b) =>
                                isAutoDealProvider(b) - isAutoDealProvider(a) ||
                                a.name.localeCompare(b.name),
                        );

                    if (
                        !channelsList.length ||
                        (!isDirect && filteredDealProviders.length === 0)
                    )
                        return null;

                    return (
                        <Box
                            margin={[0, 0, 'base', 0]}
                            border={['base', 'solid', 'gray', 'lighter']}
                            borderRadius="rounder"
                            key={id}
                        >
                            <ClickArea
                                onClick={() => toggleExpandedState(id)}
                                isDisabled={integrationRemainsOpen(
                                    channelsList,
                                )}
                            >
                                <header className={styles.header}>
                                    <div className={styles.logo}>
                                        {logo ? (
                                            <img
                                                className={styles['logo-image']}
                                                src={logo}
                                                alt={`${name} Logo`}
                                            />
                                        ) : (
                                            <Text
                                                size="large"
                                                weight="bold"
                                                color={['gray', 'darkest']}
                                            >
                                                {name}
                                            </Text>
                                        )}
                                    </div>
                                    <div
                                        className={styles['counter-container']}
                                    >
                                        <Counter
                                            theme="round"
                                            selected={selectedChannelsCount}
                                            total={channelsList.length}
                                        />
                                    </div>
                                    {!integrationRemainsOpen(channelsList) && (
                                        <div className={styles.chevron}>
                                            <FontAwesomeIcon
                                                icon={
                                                    expandedState[id]
                                                        ? faChevronUp
                                                        : faChevronDown
                                                }
                                            />
                                        </div>
                                    )}
                                </header>
                            </ClickArea>
                            {expandedState[id] &&
                                channelsList
                                    .sort(({ updated }) => updated)
                                    .map((channel) => (
                                        <ChannelPanel
                                            key={channel.id}
                                            channel={channel}
                                            dealProviders={
                                                filteredDealProviders
                                            }
                                            isReadOnly={isReadOnly}
                                            onCreateDeal={onCreateDeal}
                                            onActivateDeal={onActivateDeal}
                                            onSetChannelGeotargets={
                                                onSetChannelGeotargets
                                            }
                                            onToggleChannel={() =>
                                                toggleChannel(channel.id)
                                            }
                                        />
                                    ))}

                            {expandedState[id] && name === 'The Trade Desk' && (
                                <TTDAlertPanel
                                    integrationChannels={channelsList}
                                    contextChannels={contextChannels}
                                    contextId={contextId}
                                />
                            )}
                        </Box>
                    );
                },
            )}
        </>
    );
}
