import React from 'react';
import PropTypes from 'prop-types';
import styles from './badge.module.scss';

function Badge({ children }) {
    return <div className={styles.container}>{children}</div>;
}

Badge.defaultProps = {};

Badge.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Badge;
