import { useRef } from 'react';

const useIsMounting = () => {
    const isMountingRef = useRef(true);

    if (isMountingRef.current) {
        isMountingRef.current = false;
        return true;
    }
    return false;
};

export default useIsMounting;
