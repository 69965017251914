import React from 'react';
import Box from 'js/components/box/box';
import FavoritesButton from 'js/components/button/favorites-button';
import EllipsisButton from 'js/components/button/ellipsis-button';
import DropdownButton from 'js/components/dropdown-menu/button';
import Text from 'js/components/text/text';
import styles from '../card.module.scss';

const CardSlimTemplate = ({
    scale,
    logo,
    pill,
    isFavorite,
    isLoading,
    to,
    title,
    updatedDate,
    onDelete,
    onToggleFavorite,
    onToggleDeleteWarningModal,
    ellipsis,
    providerName,
    onCancelSchedule,
}) => (
    <div className={styles['card-slim-container']}>
        {onToggleFavorite && (
            <Box padding={['none', 'small', 'none', 'none']}>
                <FavoritesButton
                    size="small"
                    isFavorite={isFavorite}
                    isLoading={isLoading}
                    onToggleFavorite={onToggleFavorite}
                />
            </Box>
        )}
        <div className={styles['card-slim-content']}>
            <Box padding={['none', 'smaller', 'none', 'none']}>
                <Text size="base" weight="bold" color={['gray', 'black']}>
                    {title}
                </Text>
            </Box>
            {updatedDate && <Text color={['gray', 'dark']}>{updatedDate}</Text>}
            {providerName && (
                <Text color={['gray', 'dark']}>By {providerName}</Text>
            )}
        </div>
        <Box display="none" displayMd="flex">
            {scale}
            {logo}
            {pill}
            {ellipsis ??
                ((onDelete || onCancelSchedule) && (
                    <Box>
                        <EllipsisButton size="small">
                            {onToggleFavorite && (
                                <DropdownButton onClick={onToggleFavorite}>
                                    {isFavorite ? 'Remove from' : 'Add to'}{' '}
                                    Favorites
                                </DropdownButton>
                            )}

                            {to && (
                                <DropdownButton to={to}>Edit</DropdownButton>
                            )}

                            {onToggleDeleteWarningModal && (
                                <DropdownButton
                                    onClick={onToggleDeleteWarningModal}
                                >
                                    Delete
                                </DropdownButton>
                            )}

                            {onCancelSchedule && (
                                <DropdownButton onClick={onCancelSchedule}>
                                    Stop Scheduling
                                </DropdownButton>
                            )}
                        </EllipsisButton>
                    </Box>
                ))}
        </Box>
    </div>
);

export default CardSlimTemplate;
