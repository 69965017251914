import React, { forwardRef } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useClickOutside, useCombinedRefs } from 'js/hooks';
import Popper from 'js/components/popper/popper';
import styles from './dropdown-menu.module.scss';

const cx = classNames.bind(styles);

const DropdownMenu = forwardRef(
    (
        {
            className,
            theme,
            children,
            content,
            onHide,
            placement,
            offset,
            flipVariations,
            showMenu,
            width,
            insideRefs,
        },
        ref,
    ) => {
        const dropdownRef = useCombinedRefs(ref);
        const containerClasses = cx({
            container: true,
            'container--fixed-width': width === 'fixed',
            'container--same-width': width === 'same',
            [`container--theme-${theme}`]: theme,
            [className]: className,
        });

        useClickOutside([dropdownRef, ...insideRefs], (evt) => {
            if (showMenu && onHide) {
                onHide(evt);
            }
        });

        return (
            <Popper
                content={
                    <div ref={dropdownRef} className={containerClasses}>
                        {content}
                    </div>
                }
                theme="tooltip"
                placement={placement}
                offset={offset}
                flipVariations={flipVariations}
                showContent={showMenu}
                width={width}
            >
                <span className={styles.wrapper}>{children}</span>
            </Popper>
        );
    },
);

export const dropdownPlacements = [
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
];

DropdownMenu.defaultProps = {
    className: undefined,
    theme: undefined,
    children: undefined,
    content: undefined,
    onHide: undefined,
    placement: 'bottom-start',
    offset: [0, 0],
    flipVariations: true,
    showMenu: false,
    width: 'fluid',
    insideRefs: [],
};

DropdownMenu.propTypes = {
    /**
     * Any custom css classes to add to the dropdown menu
     */
    className: PropTypes.string,
    /**
     * The a "search" style to add to the dropdown
     */
    theme: PropTypes.oneOf(['search']),
    /**
     * The clickable part of the menu, used to open and close the menu
     */
    children: PropTypes.node,
    /**
     * the content of the menu, only visible when the dropdown is open
     */
    content: PropTypes.node, // TODO: make isRequired
    /**
     * A callback when the dropdown is hidden
     */
    onHide: PropTypes.func,
    /**
     * The placement of the dropdown content, relative to the dropdown children
     */
    placement: PropTypes.oneOf(dropdownPlacements),
    /**
     * the skidding and difference of the underlying popper.js component
     */
    offset: PropTypes.arrayOf(PropTypes.number),
    /**
     * if true, the popper content can flip to the opposite placement if
     * there is not enough space to render it in the specified position
     */
    flipVariations: PropTypes.bool,
    /**
     * if true, show the menu content
     */
    showMenu: PropTypes.bool,
    /**
     * the width of the dropdown, either as pixels or as a predetermined option:
     * fluid: No style: TODO: remove
     * fixed: fixes the width at 250px
     * same: fixes the width to be the same as the children of the DropdownMenu
     */
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['fluid', 'fixed', 'same']),
    ]),
    /**
     * a list of Refs with handlers.
     * Used to determine if an interaction with the content of the DropdownMenu
     * was clicked/interacted with or not
     */
    insideRefs: PropTypes.arrayOf(PropTypes.object),
};

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
