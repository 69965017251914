import classNames from 'classnames/bind';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './counter.module.scss';

const cx = classNames.bind(styles);

function Counter({ theme, selected, total }) {
    const classes = cx({
        container: true,
        [theme]: theme,
    });

    return (
        <div className={classes}>
            {selected > 0 ? (
                <div className={styles['count-label']}>{selected}</div>
            ) : (
                <span>{selected}</span>
            )}
            <span>/{total}</span>
        </div>
    );
}

Counter.defaultProps = {
    theme: 'round',
};

Counter.propTypes = {
    theme: PropTypes.oneOf(['round', 'square']),
    selected: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default Counter;
