import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import videoTagSides from 'js/enums/video-tag-sides.enum';
import videoTagTypes from 'js/enums/video-tag-types.enum';
import { ReactComponent as PlayIcon } from './images/play.svg';
import { ReactComponent as PauseIcon } from './images/pause.svg';
import { ReactComponent as PlayIconSquare } from './images/play-square.svg';
import { ReactComponent as PauseIconSquare } from './images/pause-square.svg';
import styles from './video.module.scss';

// The browser only emits progress events a few times per second, inducing a
// perceivable lag (between 0 and 250ms on Firefox and Chrome) when displaying
// tags; to compensate, we emit tags before their time by this amount (ideally
// set to the average lag):
const AVERAGE_PROGRESS_LAG_MS = 100;

const findLast = (array, condition) => {
    for (let i = array.length - 1; i >= 0; i -= 1) {
        if (condition(array[i], i, array)) {
            return array[i];
        }
    }
    return undefined;
};

const filterTagsBySide = (tags, side) => {
    const sideTags = {};
    Object.entries(tags).forEach(([typeId, items]) => {
        const tagType = videoTagTypes.find(({ id }) => id === typeId);
        if (tagType?.side === side) {
            sideTags[typeId] = items;
        }
    });
    return sideTags;
};

const VideoTag = ({ color, order, children }) => (
    <div style={{ backgroundColor: color, order }} className={styles.tag}>
        {children}
    </div>
);

const VideoTags = ({ tags, side, hasHeader }) => {
    const classes = [styles.tagsContainer, styles[side]];
    if (hasHeader) {
        classes.push(styles.hasHeader);
    }
    return (
        <div className={classNames(classes)}>
            {Object.entries(tags).map(([typeId, items]) => {
                const { color, order } = videoTagTypes.find(
                    ({ id }) => id === typeId,
                );
                return items.map((item, index) => (
                    <VideoTag
                        color={color}
                        order={order}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${typeId}.${index}`}
                    >
                        {item}
                    </VideoTag>
                ));
            })}
        </div>
    );
};

function Video({
    src,
    type,
    poster,
    controls,
    header,
    tagTrack,
    timestamp,
    paused,
    squarePlayer,
    onTimeUpdate,
    ...videoProps
}) {
    const videoRef = useRef();
    const prevSrc = useRef(src);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [currentLeftTags, setCurrentLeftTags] = useState({});
    const [currentBottomTags, setCurrentBottomTags] = useState({});
    const hasCurrentLeftTags = Object.values(currentLeftTags).flat().length > 0;
    const hasCurrentBottomTags =
        Object.values(currentBottomTags).flat().length > 0;

    const resetTags = () => {
        setCurrentBottomTags({});
        setCurrentLeftTags({});
    };

    const updateTags = (currentTimeMs) => {
        const currentTags = findLast(
            tagTrack,
            ({ timeMs }) => timeMs <= currentTimeMs + AVERAGE_PROGRESS_LAG_MS,
        );
        if (currentTags) {
            setCurrentBottomTags(
                filterTagsBySide(currentTags, videoTagSides.BOTTOM),
            );
            setCurrentLeftTags(
                filterTagsBySide(currentTags, videoTagSides.LEFT),
            );
        }
    };

    const toggleIsPlaying = () => {
        setIsPlaying((wasPlaying) => {
            const video = videoRef.current;
            if (wasPlaying) {
                video.pause();
            } else {
                if (video.ended) resetTags();
                video.play();
            }
            return !wasPlaying;
        });
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video && prevSrc.current !== src) {
            setIsPlaying(false);
            resetTags();
            video.load();
            prevSrc.current = src;
        }
    }, [src]);

    useEffect(() => {
        const video = videoRef.current;
        if (paused) {
            setIsPlaying(false);
            video.pause();
        }
    }, [paused]);

    return (
        /* eslint-disable jsx-a11y/media-has-caption */
        <div
            className={styles.container}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
        >
            <video
                ref={videoRef}
                poster={
                    poster
                        ? `${poster}?t=${encodeURIComponent(timestamp)}`
                        : null
                }
                preload="none"
                playsInline
                disablePictureInPicture
                disableRemotePlayback
                controls={controls}
                onEnded={!controls ? toggleIsPlaying : null}
                onTimeUpdate={(ev) =>
                    onTimeUpdate
                        ? onTimeUpdate(ev.target.currentTime)
                        : updateTags(ev.target.currentTime * 1000)
                }
                {...videoProps}
            >
                <source
                    src={`${src}?t=${encodeURIComponent(timestamp)}`}
                    type={type}
                />
            </video>
            {header ? <div className={styles.header}>{header}</div> : null}
            {hasCurrentLeftTags && (
                <VideoTags
                    tags={currentLeftTags}
                    side={videoTagSides.LEFT}
                    hasHeader={!!header}
                />
            )}
            {hasCurrentBottomTags && (
                <VideoTags
                    tags={currentBottomTags}
                    side={videoTagSides.BOTTOM}
                />
            )}
            {!controls && (!isPlaying || isActive) && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                    className={styles.buttonContainer}
                    onClick={toggleIsPlaying}
                >
                    {squarePlayer ? (
                        <button>
                            {isPlaying ? (
                                <PauseIconSquare />
                            ) : (
                                <PlayIconSquare />
                            )}
                        </button>
                    ) : (
                        <button>
                            {isPlaying ? <PauseIcon /> : <PlayIcon />}
                        </button>
                    )}
                </div>
            )}
        </div>
        /* esline-enable jsx-a11y/media-has-caption */
    );
}

Video.defaultProps = {
    type: 'video/mp4',
    poster: '',
    controls: false,
    header: '',
    tagTrack: [],
    timestamp: new Date(),
    paused: false,
    squarePlayer: false,
    onTimeUpdate: undefined,
};

Video.propTypes = {
    src: PropTypes.string.isRequired,
    type: PropTypes.string,
    poster: PropTypes.string,
    controls: PropTypes.bool,
    header: PropTypes.node,
    tagTrack: PropTypes.array,
    timestamp: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    paused: PropTypes.bool,
    squarePlayer: PropTypes.bool,
    onTimeUpdate: PropTypes.func,
};

export default Video;
