import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '../alert/alert';
import Box from '../box/box';
import Button from '../button/button';
import Col from '../grid/column';
import Row from '../grid/row';
import Modal from './modal';
import Text from '../text/text';
import LoadingLayer from '../loading-layer/loading-layer';

function ConfirmModal({
    title,
    confirm,
    cancel,
    loadingMessage,
    errorMessage,
    hasError,
    onConfirm,
    onCancel,
    children,
}) {
    const [isExecuting, setIsExecuting] = useState(false);
    const [hasUnhandledError, setHasUnhandledError] = useState(false);
    const defaultErrorMessage = `
        There was an unexpected error. Please try again in a few moments.`;

    let isStillMounted = true;
    useEffect(
        () => () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isStillMounted = false;
        },
        [],
    );

    const handleConfirm = async () => {
        if (!onConfirm) return;
        setHasUnhandledError(false);
        setIsExecuting(true);
        try {
            await onConfirm();
        } catch {
            if (isStillMounted) setHasUnhandledError(true);
        }
        if (isStillMounted) setIsExecuting(false);
    };

    const handleCancel = async () => {
        if (!onCancel) return;
        setHasUnhandledError(false);
        await onCancel();
    };

    return (
        <>
            <Modal
                preventClosing
                actionItems={
                    <Row>
                        <Col span="auto">
                            <Button theme="outline" onClick={handleCancel}>
                                {cancel}
                            </Button>
                        </Col>
                        <Col span="auto">
                            <Button
                                disabled={hasError || hasUnhandledError}
                                onClick={handleConfirm}
                            >
                                {confirm}
                            </Button>
                        </Col>
                    </Row>
                }
                title={title}
                onClose={handleCancel}
            >
                <Text size="large" color={['gray', 'dark']} lineHeight="large">
                    {children}
                </Text>

                {hasError || hasUnhandledError ? (
                    <Box margin={['large', 0, 0, 0]}>
                        <Alert theme="danger">
                            <p>
                                {(hasError ? errorMessage : '') ||
                                    defaultErrorMessage}
                            </p>
                        </Alert>
                    </Box>
                ) : null}
            </Modal>
            {isExecuting && loadingMessage ? (
                <LoadingLayer message={loadingMessage} />
            ) : null}
        </>
    );
}

ConfirmModal.defaultProps = {
    title: 'Are You Sure?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    loadingMessage: '',
    errorMessage: '',
    hasError: false,
    onConfirm: undefined,
    onCancel: undefined,
};

ConfirmModal.propTypes = {
    title: PropTypes.string,
    confirm: PropTypes.string,
    cancel: PropTypes.string,
    loadingMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default ConfirmModal;
