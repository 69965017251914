import React from 'react';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INITIALLY_VISIBLE_KEYWORDS } from 'js/constants';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import { useLimitList } from 'js/hooks';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownButton from 'js/components/dropdown-menu/button';
import DropdownTitle from 'js/components/dropdown-menu/title';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Pill from 'js/components/pill/pill';
import Text from 'js/components/text/text';
import styles from '../summary-footer.module.scss';

function RuleKeywordsChanges({
    rule,
    showAdded,
    onAddKeyword,
    onRemoveKeyword,
}) {
    const { aggregation, isNew } = rule;
    const isTargeting = aggregation === ruleTypes.INCLUDED;

    let theme;
    if (showAdded) {
        theme = isTargeting ? 'keyword' : 'danger';
    } else {
        theme = 'muted';
    }

    const changedKeywords = showAdded
        ? rule.addedKeywords
        : rule.removedKeywords;

    if (!changedKeywords.length) return null;

    return (
        <Box margin={['small', 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    {showAdded ? (
                        <Text>
                            {isNew
                                ? 'This is a new group with the following keywords'
                                : 'The following keywords have been added to this group'}
                        </Text>
                    ) : (
                        <Text>
                            The following keywords have been removed from this
                            group
                        </Text>
                    )}

                    {changedKeywords.map((keyword) => (
                        <Box
                            key={keyword}
                            margin={['small', 'small', 0, 0]}
                            display="inline-block"
                        >
                            <Pill
                                outline={!showAdded || !isTargeting}
                                theme={theme}
                                suffixButton={
                                    <FontAwesomeIcon
                                        icon={showAdded ? faTimes : faPlus}
                                    />
                                }
                                onSuffixClick={
                                    showAdded &&
                                    (() => onRemoveKeyword(keyword))
                                }
                                suffixDropdownContent={
                                    !showAdded && (
                                        <ul className={styles.dropdown}>
                                            <DropdownTitle>
                                                {keyword}
                                            </DropdownTitle>

                                            <DropdownDivider />

                                            <DropdownButton
                                                onClick={() =>
                                                    onAddKeyword(keyword)
                                                }
                                            >
                                                Add Back To This Group
                                            </DropdownButton>
                                        </ul>
                                    )
                                }
                            >
                                {keyword}
                            </Pill>
                        </Box>
                    ))}
                </Box>
            </Panel>
        </Box>
    );
}

function Keywords({ rule, onRemoveKeyword }) {
    const { keywords, aggregation } = rule;
    const isTargeting = aggregation === ruleTypes.INCLUDED;

    const [
        hasMoreKeywords,
        isKeywordsExpanded,
        visibleKeywords,
        showMoreKeywords,
        showLessKeywords,
    ] = useLimitList(keywords, INITIALLY_VISIBLE_KEYWORDS);

    return (
        <>
            {visibleKeywords.map((keyword) => (
                <Box
                    key={keyword}
                    margin={[0, 'small', 'small', 0]}
                    display="inline-block"
                >
                    <Pill
                        outline={!isTargeting}
                        theme={isTargeting ? 'keyword' : 'danger'}
                        suffixButton={<FontAwesomeIcon icon={faTimes} />}
                        onSuffixClick={() => onRemoveKeyword(keyword)}
                    >
                        {keyword}
                    </Pill>
                </Box>
            ))}

            {hasMoreKeywords && (
                <Row>
                    <Col span="auto">
                        <Button
                            theme="outline"
                            size="small"
                            onClick={showMoreKeywords}
                        >
                            Show All {keywords.length} Keywords
                        </Button>
                    </Col>
                </Row>
            )}

            {isKeywordsExpanded && (
                <Row>
                    <Col span="auto">
                        <Button
                            theme="outline"
                            size="small"
                            onClick={showLessKeywords}
                        >
                            Show Fewer Keywords
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
}

function KeywordsChanges({ context, contextChanges, onChangeContextRules }) {
    const { rules } = context;
    const { rules: changedRules } = contextChanges;

    const addKeyword = (ruleKey, keyword) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(ruleKey, { addKeywords: [keyword] });
        onChangeContextRules(ruleSet.rules);
    };

    const removeKeyword = (ruleKey, keyword) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(ruleKey, { removeKeywords: [keyword] });
        onChangeContextRules(ruleSet.rules);
    };

    if (
        !changedRules.map(({ keywords }) => keywords).flat().length &&
        !changedRules.map(({ addedKeywords }) => addedKeywords).flat().length &&
        !changedRules.map(({ removedKeywords }) => removedKeywords).flat()
            .length
    ) {
        return null;
    }

    return (
        <Box margin={['large', 0]}>
            <Panel bordered>
                <Box padding="base">
                    <Text size="larger" weight="bolder">
                        Keywords
                    </Text>
                    {changedRules.map((rule, index) => {
                        if (
                            !rule.keywords.length &&
                            !rule.addedKeywords.length &&
                            !rule.removedKeywords.length
                        ) {
                            return null;
                        }

                        const isTargeting =
                            rule.aggregation === ruleTypes.INCLUDED;
                        const title = isTargeting
                            ? 'Target content featuring'
                            : 'Block content featuring';

                        return (
                            <Box margin={['base', 0]} key={rule.key}>
                                <Panel bordered>
                                    <Box padding="base">
                                        {index === 0 ? (
                                            <Text size="large" weight="bold">
                                                {title}
                                            </Text>
                                        ) : (
                                            <Text size="large" weight="bold">
                                                and{' '}
                                                {index ===
                                                    changedRules.length - 1 &&
                                                rule.aggregation !==
                                                    changedRules[index - 1]
                                                        .aggregation
                                                    ? title.toLowerCase()
                                                    : ''}
                                            </Text>
                                        )}

                                        <RuleKeywordsChanges
                                            rule={rule}
                                            showAdded
                                            onAddKeyword={(keyword) =>
                                                addKeyword(rule.key, keyword)
                                            }
                                            onRemoveKeyword={(keyword) =>
                                                removeKeyword(rule.key, keyword)
                                            }
                                        />

                                        <RuleKeywordsChanges
                                            rule={rule}
                                            onAddKeyword={(keyword) =>
                                                addKeyword(rule.key, keyword)
                                            }
                                            onRemoveKeyword={(keyword) =>
                                                removeKeyword(rule.key, keyword)
                                            }
                                        />

                                        <Keywords
                                            rule={rule}
                                            onRemoveKeyword={(keyword) =>
                                                removeKeyword(rule.key, keyword)
                                            }
                                        />
                                    </Box>
                                </Panel>
                            </Box>
                        );
                    })}
                </Box>
            </Panel>
        </Box>
    );
}

export default KeywordsChanges;
