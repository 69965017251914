import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from '@react-pdf/renderer';
import styles from './aside.styles';
import Box from '../box/box';
import Panel from '../panel/panel';
import Row from '../grid/row';
import Col from '../grid/col';
import Rocket from './images/rocket.png';
import { color, fontWeight } from '../tokens.styles';
// eslint-disable-next-line import/no-named-default
import { default as recommendationStyles } from './recommendation.styles';

function Recommendation({ isPortrait, title, text }) {
    return (
        <Box padding={['smaller', 0]}>
            <Row gutter="smaller">
                <Col span="auto" gutter="smaller">
                    <Image
                        src={Rocket}
                        style={
                            isPortrait
                                ? recommendationStyles.iconPortrait
                                : recommendationStyles.iconLandscape
                        }
                    />
                </Col>

                <Col gutter="smaller">
                    <Text
                        style={
                            isPortrait
                                ? recommendationStyles.contentPortrait
                                : recommendationStyles.contentLandscape
                        }
                    >
                        <Text
                            style={{
                                fontWeight: fontWeight.bold,
                                color: color.purple.base,
                            }}
                        >
                            {title}
                            {'\n'}
                        </Text>
                        <Text>{text}</Text>
                    </Text>
                </Col>
            </Row>
        </Box>
    );
}

function Aside({
    orientation,
    title,
    description,
    hasLogo,
    fixed,
    recommendations,
    children,
}) {
    const isPortrait = orientation === 'portrait';
    const classes = [
        styles.container,
        isPortrait ? styles.containerPortrait : styles.containerLandscape,
    ];

    if (hasLogo) {
        classes.push(
            isPortrait
                ? styles.containerPortraitWithLogo
                : styles.containerLandscapeWithLogo,
        );
    }

    return (
        <View style={classes} fixed={fixed}>
            <View>
                <Text
                    style={
                        isPortrait
                            ? styles.titlePortrait
                            : styles.titleLandscape
                    }
                >
                    {title}
                </Text>

                {description && (
                    <Text
                        style={
                            isPortrait
                                ? styles.descriptionPortrait
                                : styles.descriptionLandscape
                        }
                    >
                        {description}
                    </Text>
                )}
            </View>
            {children}

            {recommendations.length > 0 && (
                <Panel>
                    <Box margin={['base', 0, 0]}>
                        <Box margin={[0, 'base', 'smaller', 'base']}>
                            <Text
                                style={
                                    isPortrait
                                        ? recommendationStyles.panelTitlePortrait
                                        : recommendationStyles.panelTitleLandscape
                                }
                            >
                                Recommendations
                            </Text>
                        </Box>
                        <Panel>
                            <Box padding={['smaller', 'base']}>
                                <Row>
                                    {recommendations.map(
                                        (
                                            { title: recommendation, text },
                                            index,
                                        ) => (
                                            <Col
                                                span={isPortrait ? 'fluid' : 12}
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                            >
                                                <Recommendation
                                                    orientation={orientation}
                                                    title={recommendation}
                                                    text={text}
                                                />
                                            </Col>
                                        ),
                                    )}
                                </Row>
                            </Box>
                        </Panel>
                    </Box>
                </Panel>
            )}
        </View>
    );
}

Aside.defaultProps = {
    orientation: 'portrait',
    description: undefined,
    hasLogo: false,
    fixed: false,
    children: undefined,
    recommendations: [],
};

Aside.propTypes = {
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
    hasLogo: PropTypes.bool,
    fixed: PropTypes.bool,
    children: PropTypes.node,
    recommendations: PropTypes.array,
};

export default Aside;
