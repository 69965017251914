import React from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import styles from '../report-detail.module.scss';

export default function PanelWrapper({ logo, title, description, children }) {
    return (
        <Box
            background={['gray', 'background']}
            border={['base', 'solid', 'gray', 'background']}
            borderRadius="round"
        >
            <Box padding={[0, 'base']}>
                {(logo || title) && (
                    <Box margin={['base', 0, 'small']}>
                        <Row gutter="small" alignItems="center">
                            {logo && (
                                <Col span="auto">
                                    <img
                                        className={styles['logo-image']}
                                        src={logo}
                                        alt={`${title} Logo`}
                                    />
                                </Col>
                            )}

                            <Col span="auto">
                                <Text inline weight="bolder" size="largest">
                                    {title}
                                </Text>
                            </Col>
                        </Row>
                    </Box>
                )}

                {description && (
                    <Box margin={['small', 0]}>
                        <Text color={['gray', 'dark']} width="readable">
                            {description}
                        </Text>
                    </Box>
                )}

                {children}
            </Box>
        </Box>
    );
}
