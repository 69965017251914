import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Collapse from 'js/components/collapse/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ClickArea from 'js/components/click-area/click-area';
import LineItemResult from './line-item-result';
import styles from '../rename-data-sources.module.scss';
import HasDataButton from './buttons/has-data-button';
import EditNameButton from './buttons/edit-name-button';

const CampaignResult = ({
    campaign,
    onCampaignNameChange,
    onLineItemNameChange,
}) => {
    const [nameDisplayed, setNameDisplayed] = useState(campaign.campaignName);
    const [showLineItems, setShowLineItems] = useState(false);

    const handleToggleLineItems = () => {
        setShowLineItems((prevState) => !prevState);
    };

    const onUpdateNameHandler = (newName) => {
        const updatedCampaign = {
            ...campaign,
            campaignName: newName,
        };
        onCampaignNameChange([updatedCampaign]);
    };

    const sortedLineItems = campaign.lineItems.sort((a, b) => {
        if (b.lineItemName.length === 0) {
            return -1;
        }
        if (a.lineItemName.length === 0) {
            return 1;
        }
        return a.lineItemName.localeCompare(b.lineItemName);
    });

    return (
        <>
            <Box
                margin={['small', 0, 0, 0]}
                padding="small"
                border={['base', 'solid', 'gray', 'light']}
                borderRadius="round"
            >
                <ClickArea
                    onClick={handleToggleLineItems}
                    isDisabled={campaign.lineItems.length === 0}
                >
                    <Row justifyContent="space-between">
                        <Col span="auto">
                            <div>
                                <div className={styles['result-text']}>
                                    <Text
                                        weight="bold"
                                        color={['gray', 'darkest']}
                                    >
                                        {nameDisplayed}
                                    </Text>
                                </div>
                                <div className={styles['result-text']}>
                                    <Text color={['gray', 'dark']}>
                                        {campaign.campaignId}
                                    </Text>
                                </div>
                            </div>
                        </Col>
                        <Col span="auto">
                            {campaign.hasData && <HasDataButton />}
                            <EditNameButton
                                name={campaign.campaignName}
                                onSave={onUpdateNameHandler}
                                onUpdateNameDisplayed={setNameDisplayed}
                            />
                            {campaign.lineItems.length > 0 && (
                                <div className={styles['line-item-dropdown']}>
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            showLineItems
                                                ? faChevronUp
                                                : faChevronDown
                                        }
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </ClickArea>
            </Box>
            <Collapse isOpen={showLineItems}>
                <Box
                    borderLeft={['thick', 'solid', 'gray', 'lightest']}
                    margin={['base', 0, 'base', 'base']}
                    padding={['0', 0, 0, 'base']}
                >
                    <Text size="large" weight="bold">
                        Line Items
                    </Text>
                    {sortedLineItems.map((lineItem) => (
                        <LineItemResult
                            key={lineItem.lineItemId}
                            lineItem={lineItem}
                            onLineItemNameChange={onLineItemNameChange}
                        />
                    ))}
                </Box>
            </Collapse>
        </>
    );
};

export default CampaignResult;
