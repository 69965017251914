import React, { useState } from 'react';
import { getJwtPayload } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import CardAdvertiser from 'js/components/card/card-advertiser';
import CardCampaign from 'js/components/card/card-campaign';
import CardContext from 'js/components/card/card-context';
import CardReport from 'js/components/card/card-report';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import entityTypes from 'js/enums/entity-types.enum';
import serviceTypes from 'js/enums/service-types.enum';
import templateMethodMap from 'js/enums/templateMethodMap.enum';
import pageSections from '../enums/page-sections.enum';
import SectionWrapper from './section-wrapper/section-wrapper';

const FavoritesPanelTab = ({ itemsName, children }) => {
    if (!React.Children.count(children)) {
        return (
            <Box margin={['base', 0]}>
                <Alert theme="empty">
                    <p>
                        Favorited {itemsName} will appear here, enabling you to
                        quickly access them.
                    </p>
                </Alert>
            </Box>
        );
    }

    return React.Children.map(children, (child) => (
        <Box margin={['base', 0]}>{child}</Box>
    ));
};

const Favorites = ({
    sectionTitle,
    reports,
    advertisers,
    campaigns,
    contexts,
    onDelete,
    onToggleFavorite,
    pageLayout,
    onSavePageLayout,
    onCancelScheduling,
}) => {
    const tabs = {
        REPORTS: 'Insights Reports',
        ADVERTISERS: 'Advertisers',
        CAMPAIGNS: 'Campaigns',
        CONTEXTS: 'Contexts',
    };
    const [currentTab, setCurrentTab] = useState(tabs.REPORTS);

    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );

    const favoritesSection = pageLayout.layout_sections.find(
        (layout) => layout.section === pageSections.FAVORITES,
    );
    const favoritesIndex = pageLayout.layout_sections.indexOf(favoritesSection);

    const [showCollapse, setShowCollapse] = useState(
        !favoritesSection.is_collapsed,
    );

    const toggleCollapse = () => {
        setShowCollapse((prevState) => !prevState);
        favoritesSection.is_collapsed = !favoritesSection.is_collapsed;
        const layoutSections = [...pageLayout.layout_sections];
        layoutSections[favoritesIndex] = favoritesSection;
        onSavePageLayout(layoutSections);
    };

    const buttonsWrapper = ({ children }) => (
        <Box margin={['base', 0]} marginLg={['large', 0]}>
            <Row justifyContent="center">
                <Col span="auto">{children}</Col>
            </Row>
        </Box>
    );

    return (
        <SectionWrapper
            title={sectionTitle}
            isOpen={showCollapse}
            toggleCollapse={toggleCollapse}
        >
            <Panel bordered>
                <Box padding={[0, 'base']}>
                    <Tabs
                        tabs={tabs}
                        currentTab={currentTab}
                        buttonsWrapper={buttonsWrapper}
                        onChangeTab={setCurrentTab}
                    >
                        {insightsService && (
                            <TabContent tabId={tabs.REPORTS}>
                                <FavoritesPanelTab itemsName="reports">
                                    {reports.map((report) => (
                                        <CardReport
                                            key={report.id}
                                            report={report}
                                            isSlim
                                            onToggleFavorite={() =>
                                                onToggleFavorite(
                                                    report.type,
                                                    report.id,
                                                )
                                            }
                                            onDelete={() =>
                                                onDelete(
                                                    templateMethodMap[
                                                        report.type
                                                    ],
                                                    report.id,
                                                )
                                            }
                                            onCancelSchedule={() =>
                                                onCancelScheduling(report)
                                            }
                                        />
                                    ))}
                                </FavoritesPanelTab>
                            </TabContent>
                        )}

                        <TabContent tabId={tabs.ADVERTISERS}>
                            <FavoritesPanelTab itemsName="advertisers">
                                {advertisers.map((advertiser) => (
                                    <CardAdvertiser
                                        key={advertiser.id}
                                        advertiser={advertiser}
                                        isSlim
                                        onDeleteAdvertiser={() =>
                                            onDelete(
                                                entityTypes.ADVERTISERS,
                                                advertiser.id,
                                            )
                                        }
                                        onToggleFavorite={() =>
                                            onToggleFavorite(
                                                entityTypes.ADVERTISERS,
                                                advertiser.id,
                                            )
                                        }
                                    />
                                ))}
                            </FavoritesPanelTab>
                        </TabContent>

                        <TabContent tabId={tabs.CAMPAIGNS}>
                            <FavoritesPanelTab itemsName="campaigns">
                                {campaigns.map((campaign) => (
                                    <CardCampaign
                                        key={campaign.id}
                                        campaign={campaign}
                                        isSlim
                                        onDeleteCampaign={() =>
                                            onDelete(
                                                entityTypes.CAMPAIGNS,
                                                campaign.id,
                                            )
                                        }
                                        onToggleFavorite={() =>
                                            onToggleFavorite(
                                                entityTypes.CAMPAIGNS,
                                                campaign.id,
                                            )
                                        }
                                    />
                                ))}
                            </FavoritesPanelTab>
                        </TabContent>

                        <TabContent tabId={tabs.CONTEXTS}>
                            <FavoritesPanelTab itemsName="contexts">
                                {contexts.map((context) => (
                                    <CardContext
                                        key={context.id}
                                        context={context}
                                        topline={context.group_name}
                                        hasDealID
                                        isSlim
                                        onDeleteContext={() =>
                                            onDelete(
                                                entityTypes.CONTEXTS,
                                                context.id,
                                            )
                                        }
                                        onToggleFavorite={() =>
                                            onToggleFavorite(
                                                entityTypes.CONTEXTS,
                                                context.id,
                                            )
                                        }
                                    />
                                ))}
                            </FavoritesPanelTab>
                        </TabContent>
                    </Tabs>
                </Box>
            </Panel>
        </SectionWrapper>
    );
};

export default Favorites;
