import React from 'react';
import { getJwtPayload } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import { showToast } from 'js/components/toast/toast';
import TopBar from 'js/components/top-bar/top-bar';
import sections from './enums/page-sections.enum';
import CustomizationBar from './components/customization-bar/customization-bar';
import Spotlight from './components/spotlight/spotlight';
import Favorites from './components/favorites';
import Notifications from './components/notifications';
import RecentUpdates from './components/recent-updates/recent-updates';

function Wrapper({ isSaving, children, onNotificationsChange }) {
    const { name, email } = getJwtPayload(localStorage.getItem('AuthToken'));

    return (
        <Layout
            header={
                <TopBar
                    title={
                        <>
                            <Text inline weight="base" color={['gray', 'dark']}>
                                Hello,
                            </Text>{' '}
                            {name || email} {isSaving && <Spinner />}
                        </>
                    }
                    onNotificationsChange={onNotificationsChange}
                />
            }
            sidebar={<Navigation />}
        >
            {children}
        </Layout>
    );
}

export default function Home({
    isLoading,
    isSaving,
    hasError,
    recentContexts,
    recentReports,
    pageSections,
    notifications,
    features,
    favoriteAdvertisers,
    favoriteCampaigns,
    favoriteContexts,
    favoriteReports,
    togglingFavoriteError,
    onToggleFavorite,
    onNotificationsChange,
    onSeeNotification,
    onSavePageLayout,
    onDelete,
    onRetry,
    onCancelScheduling,
    pageLayout,
}) {
    const panels = pageSections.map(({ id, label }) => {
        switch (id) {
            case sections.SPOTLIGHT:
                return (
                    <Spotlight
                        key={id}
                        sectionTitle={label}
                        features={features}
                        pageLayout={pageLayout}
                        onSavePageLayout={onSavePageLayout}
                    />
                );

            case sections.NOTIFICATIONS:
                return (
                    <Notifications
                        key={id}
                        sectionTitle={label}
                        notifications={notifications}
                        pageLayout={pageLayout}
                        onAction={onSeeNotification}
                        onSavePageLayout={onSavePageLayout}
                    />
                );

            case sections.FAVORITES:
                return (
                    <Favorites
                        key={id}
                        sectionTitle={label}
                        advertisers={favoriteAdvertisers}
                        campaigns={favoriteCampaigns}
                        contexts={favoriteContexts}
                        reports={favoriteReports}
                        onToggleFavorite={onToggleFavorite}
                        onDelete={onDelete}
                        pageLayout={pageLayout}
                        onSavePageLayout={onSavePageLayout}
                        onCancelScheduling={onCancelScheduling}
                    />
                );

            case sections.RECENT_UPDATES:
                return (
                    <RecentUpdates
                        key={id}
                        sectionTitle={label}
                        recentContexts={recentContexts}
                        recentReports={recentReports}
                        onToggleFavorite={onToggleFavorite}
                        onDelete={onDelete}
                        pageLayout={pageLayout}
                        onSavePageLayout={onSavePageLayout}
                        onCancelScheduling={onCancelScheduling}
                    />
                );

            default:
                return () => null;
        }
    });

    if (Object.keys(togglingFavoriteError).length) {
        showToast(
            'There Was An Unexpected Error',
            <p>
                It was not possible to{' '}
                {togglingFavoriteError.isFavorite ? 'favorite' : 'unfavorite'}{' '}
                {togglingFavoriteError.title}. Please try again in a few
                moments.
            </p>,
            5,
            'danger',
        );
    }

    if (isLoading) {
        return (
            <Wrapper
                isSaving={isSaving}
                onNotificationsChange={onNotificationsChange}
            >
                <Box padding={['large', 0]}>
                    <Spinner
                        size="large"
                        message="Loading"
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            </Wrapper>
        );
    }

    if (hasError) {
        return (
            <Wrapper
                isSaving={isSaving}
                onNotificationsChange={onNotificationsChange}
            >
                <Box padding={['large', 0]}>
                    <Container size="small">
                        <Alert
                            theme="danger"
                            title="There was an error loading the page"
                            actionItems={
                                <Button onClick={onRetry}>Retry</Button>
                            }
                        >
                            <p>
                                Hopefully it is only a temporary issue. Please
                                try again in a few moments.
                            </p>
                        </Alert>
                    </Container>
                </Box>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            isSaving={isSaving}
            onNotificationsChange={onNotificationsChange}
        >
            <CustomizationBar
                pageSections={pageSections}
                onSavePageLayout={onSavePageLayout}
            />

            {panels}
        </Wrapper>
    );
}
