import React from 'react';
import { Text } from '@react-pdf/renderer';
import Number, { getNumber } from 'js/components/number/number';
import { fontWeight } from './components/tokens.styles';
import { videoPdfPages } from '../pdf-download-drawer/enums/pdf-pages.enum';

const sum = (arr) => arr.reduce((a, b) => a + b, 0);

export const getCtr = (i) => (i.impressions ? i.clicks / i.impressions : 0);
export const getViewRate = (i) =>
    i.impressions ? i.in_views / i.impressions : 0;
export const getCompletionRate = (i) =>
    i.impressions ? i.completes / i.impressions : 0;

const getArrCtr = (arr) =>
    arr.some((i) => i.impressions > 0)
        ? sum(arr.map((i) => i.clicks)) / sum(arr.map((i) => i.impressions))
        : 0;

const getName = (name, arr) =>
    `’${name}’${arr.length > 1 ? ` & ${arr.length - 1} others` : ''}`;

export const getVideoReportTopicRecommendations = (report, isDV360Report) => {
    const topicRecommendations = report.recommendations
        .filter(
            ({ attribute, target_metric: targetMetric }) =>
                attribute === 'topics' && targetMetric === 'cr',
        )
        .map((i) => i.entities)
        .flat()
        .map((i) => i.name);
    const recommendedTopics = report.topics
        .filter((i) => topicRecommendations.includes(i.topic))
        .sort(
            (a, b) => b.completes / b.impressions - a.completes / a.impressions,
        )
        .map((topic) => {
            const viewRateTerm = isDV360Report
                ? 'True View Rate'
                : 'Completion Rate';
            const completionRate =
                topic.completes && topic.impressions
                    ? topic.completes / topic.impressions
                    : 0;

            const reportCompRate = report.completes
                ? report.completes / report.impressions
                : 0;
            const ratio = completionRate / reportCompRate - 1;

            return {
                title: 'Topic',
                text: (
                    <Text>
                        Videos featuring the topic{' '}
                        <Text style={{ fontWeight: fontWeight.medium }}>
                            {topic.topic_name}
                        </Text>{' '}
                        had a {viewRateTerm} of{' '}
                        <Number
                            value={completionRate}
                            isPercentage
                            maximumFractionDigits={2}
                        />
                        {', '}
                        <Number
                            value={ratio}
                            isPercentage
                            maximumFractionDigits={2}
                            signDisplay="never"
                        />{' '}
                        greater than the report average
                    </Text>
                ),
            };
        });

    return recommendedTopics.slice(0, 3);
};

export const getVideoReportLogoRecommendations = (report, isDV360Report) => {
    const logoRecommendations = report.recommendations
        .filter(
            ({ attribute, target_metric: targetMetric }) =>
                attribute === 'logos' && targetMetric === 'cr',
        )
        .map((i) => i.entities)
        .flat()
        .map((i) => i.name);
    const recommendedLogos = report.logos
        .filter((i) => logoRecommendations.includes(i.logo))
        .sort(
            (a, b) => b.completes / b.impressions - a.completes / a.impressions,
        )
        .map((logo) => {
            const viewRateTerm = isDV360Report
                ? 'True View Rate'
                : 'Completion Rate';
            const completionRate =
                logo.completes && logo.impressions
                    ? logo.completes / logo.impressions
                    : 0;

            const reportCompRate = report.completes
                ? report.completes / report.impressions
                : 0;
            const ratio = completionRate / reportCompRate - 1;

            return {
                title: 'Brand',
                text: (
                    <Text>
                        Videos featuring the brand{' '}
                        <Text style={{ fontWeight: fontWeight.medium }}>
                            {logo.logo_name}
                        </Text>{' '}
                        had a {viewRateTerm} of{' '}
                        <Number
                            value={completionRate}
                            isPercentage
                            maximumFractionDigits={2}
                        />
                        {', '}
                        <Number
                            value={ratio}
                            isPercentage
                            maximumFractionDigits={2}
                            signDisplay="never"
                        />{' '}
                        greater than the report average
                    </Text>
                ),
            };
        });

    return recommendedLogos.slice(0, 3);
};

export const getCombinedVideoReportRecommendations = (
    report,
    isDV360Report,
    pages,
) => {
    let recs = [
        ...getVideoReportTopicRecommendations(report, isDV360Report),
        ...getVideoReportLogoRecommendations(report, isDV360Report),
    ];

    if (
        !pages.some((p) => p.label === videoPdfPages.TOPICS) &&
        !pages.some((p) => p.label === videoPdfPages.LOGOS)
    ) {
        return recs;
    }

    if (!pages.some((p) => p.label === videoPdfPages.TOPICS)) {
        recs = recs.filter((r) => r.title !== 'Topic');
    }

    if (!pages.some((p) => p.label === videoPdfPages.LOGOS)) {
        recs = recs.filter((r) => r.title !== 'Brand');
    }

    return recs;
};

export const getDisplayReportRecommendations = (report) => {
    if (!report.recommendations?.length) return [];

    const topicRecommendations = report.recommendations
        .filter(
            ({ attribute, target_metric: targetMetric }) =>
                attribute === 'topics' && targetMetric === 'ctr',
        )
        .map((i) => i.entities)
        .flat()
        .map((i) => i.name);

    const keywordRecommendations = report.recommendations.filter(
        ({ attribute, target_metric: targetMetric }) =>
            attribute === 'keywords' && targetMetric === 'ctr',
    );

    const reportCtr = getCtr(report);
    const goodTopics = report.topics.filter((i) =>
        topicRecommendations.includes(i.topic),
    );
    const goodKeywords = keywordRecommendations.find(
        ({ action }) => action === 'add',
    );
    const badKeywords = keywordRecommendations.find(
        ({ action }) => action === 'remove',
    );
    const recommendations = [];

    if (goodTopics.length) {
        const topicImprovement = getArrCtr(goodTopics) / reportCtr - 1;
        const topicNames = getName(
            `${goodTopics[0].topic_group_name} - ${goodTopics[0].topic_name}`,
            goodTopics,
        );
        recommendations.push({
            title: 'Topic',
            text: (
                <Text>
                    Target pages featuring the topic{' '}
                    <Text style={{ fontWeight: fontWeight.medium }}>
                        {topicNames}
                    </Text>{' '}
                    , which had a CTR{' '}
                    <Text style={{ fontWeight: fontWeight.medium }}>
                        {getNumber({
                            value: topicImprovement,
                            isPercentage: true,
                            maximumFractionDigits: 2,
                            signDisplay: 'never',
                        })}{' '}
                        better
                    </Text>{' '}
                    than the report average.
                </Text>
            ),
        });
    }

    if (goodKeywords) {
        const keywordImprovement =
            goodKeywords.target_metric_value / 100 / reportCtr - 1;
        const keywordNames = getName(
            goodKeywords.entities[0].name,
            goodKeywords.entities,
        );
        recommendations.push({
            title: 'Keyword',
            text: (
                <Text>
                    Target pages featuring the keyword{' '}
                    <Text style={{ fontWeight: fontWeight.medium }}>
                        {keywordNames}
                    </Text>
                    , which had a CTR{' '}
                    <Text style={{ fontWeight: fontWeight.medium }}>
                        {getNumber({
                            value: keywordImprovement,
                            isPercentage: true,
                            maximumFractionDigits: 2,
                            signDisplay: 'never',
                        })}{' '}
                        better
                    </Text>{' '}
                    than the report average.
                </Text>
            ),
        });
    }

    if (badKeywords) {
        const keywordImpairment =
            badKeywords.target_metric_value / 100 / reportCtr - 1;
        const keywordNames = getName(
            badKeywords.entities[0].name,
            badKeywords.entities,
        );
        recommendations.push({
            title: 'Keyword',
            text: (
                <Text>
                    Avoid pages featuring the keyword{' '}
                    <Text style={{ fontWeight: fontWeight.medium }}>
                        {keywordNames}
                    </Text>
                    , which had a CTR{' '}
                    <Text style={{ fontWeight: fontWeight.medium }}>
                        {getNumber({
                            value: keywordImpairment,
                            isPercentage: true,
                            maximumFractionDigits: 2,
                            signDisplay: 'never',
                        })}{' '}
                        worse
                    </Text>{' '}
                    than the report average.
                </Text>
            ),
        });
    }
    return recommendations;
};

export const getOrientationCssPrefix = (orientation) =>
    orientation ? `${orientation.toLowerCase()}-` : '';

export const getBase64ImageSource = async (url, color) => {
    const img = await new Promise((resolve, reject) => {
        const i = document.createElement('img');
        i.crossOrigin = 'anonymous';
        i.onload = () => resolve(i);
        i.onerror = reject;
        i.src = url;
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.clientWidth || img.width * 4;
    canvas.height = img.clientHeight || img.height * 4;
    const ctx = canvas.getContext('2d');
    if (color) {
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'destination-in';
    }
    ctx.drawImage(img, 0, 0, img.width * 4, img.height * 4);
    return canvas.toDataURL('image/png');
};
