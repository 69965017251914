import React from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './card.module.scss';

const cx = classNames.bind(styles);

export const getRecentlyUpdated = (updatedDate) => {
    if (updatedDate) {
        const date =
            updatedDate instanceof Date
                ? updatedDate.toISOString()
                : updatedDate;

        return `Updated ${moment(date).fromNow()}`;
    }

    return null;
};

const CardBase = ({ children, type, isPreview, hasShadow, isSlim }) => {
    const classes = cx({
        container: !isSlim,
        'container-slim': isSlim,
        [`container--card-${type}`]: type,
        'container--preview': !isSlim && isPreview,
        shadow: hasShadow,
    });

    return <div className={classNames(classes)}>{children}</div>;
};

CardBase.defaultProps = {
    type: '',
    isPreview: false,
    hasShadow: false,
    isSlim: false,
};

CardBase.propTypes = {
    isSlim: PropTypes.bool,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['', 'generic', 'loading', 'link', 'full', 'user']),
    isPreview: PropTypes.bool,
    hasShadow: PropTypes.bool,
};

export default CardBase;
