import React, { useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { reactLayerPortal } from 'js/utils';
import Button from '../button/button';
import Container from '../container/container';
import Layer from '../layer/layer';
import Form from '../form/form';
import Row from '../grid/row';
import Col from '../grid/column';
import Box from '../box/box';
import styles from './modal.module.scss';

function Modal({
    actionItems,
    children,
    onClose,
    preventClosing,
    title,
    insideRefs,
}) {
    const transitionNode = useRef();
    const modalContainerNode = useRef();
    const [isTrapPaused, setIsTrapPaused] = useState(false);

    const renderActionItems = () => {
        if (actionItems) {
            return (
                <Box padding={['large', 0, 0]}>
                    <Row justifyContent="flex-end">
                        <Col span="auto">{actionItems}</Col>
                    </Row>
                </Box>
            );
        }
        return null;
    };
    const renderTitle = () => {
        if (title) {
            return (
                <Col>
                    <h3 className={styles.title}>{title}</h3>
                </Col>
            );
        }
        return null;
    };
    const handleClick = ({ target }) => {
        const someRefContainTarget = insideRefs
            .filter((ref) => ref.current)
            .some((ref) => ref.current.contains(target));

        if (
            !someRefContainTarget &&
            !modalContainerNode.current.contains(target) &&
            !preventClosing
        ) {
            onClose();
        }
    };
    const handleKeyDown = ({ key }) => {
        if (key === 'Escape' && !preventClosing) {
            onClose();
        }
    };
    return (
        <CSSTransition
            nodeRef={transitionNode}
            classNames="fade-scale"
            timeout={300}
            appear
            in
        >
            <Layer
                ref={transitionNode}
                isFixedWithBackground
                preventBodyScroll
                onClick={handleClick}
                onKeyDown={handleKeyDown}
            >
                <FocusTrap
                    paused={isTrapPaused}
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        allowOutsideClick: true,
                    }}
                    containerElements={[reactLayerPortal]}
                >
                    <div className={styles.wrapper}>
                        <Container size="small">
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div
                                className={styles.container}
                                ref={modalContainerNode}
                                onMouseDown={() => setIsTrapPaused(true)}
                                onMouseUp={() => setIsTrapPaused(false)}
                            >
                                {title || !preventClosing ? (
                                    <Box padding={[0, 0, 'base']}>
                                        <Row
                                            alignItems="center"
                                            justifyContent={
                                                title
                                                    ? 'space-between'
                                                    : 'flex-end'
                                            }
                                        >
                                            {renderTitle()}
                                            {preventClosing ? null : (
                                                <Col span="auto">
                                                    <Button
                                                        square
                                                        theme="outline"
                                                        onClick={onClose}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                        />
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </Box>
                                ) : null}
                                <Form>
                                    <div className={styles.content}>
                                        {children}
                                    </div>
                                    {renderActionItems()}
                                </Form>
                            </div>
                        </Container>
                    </div>
                </FocusTrap>
            </Layer>
        </CSSTransition>
    );
}

Modal.defaultProps = {
    actionItems: undefined,
    onClose: undefined,
    preventClosing: false,
    title: undefined,
    insideRefs: [],
};

Modal.propTypes = {
    actionItems: PropTypes.node,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    preventClosing: PropTypes.bool,
    title: PropTypes.string,
    insideRefs: PropTypes.arrayOf(PropTypes.object),
};

export default Modal;
