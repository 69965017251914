import React from 'react';
import PropTypes from 'prop-types';

const ClickArea = ({ onClick, children, isDisabled }) => {
    if (isDisabled) {
        return <span>{children}</span>;
    }
    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
                onClick={(ev) => {
                    onClick();
                    ev.stopPropagation();
                }}
                style={{ cursor: onClick ? 'pointer' : 'inherit' }}
            >
                {children}
            </span>
        </>
    );
};

ClickArea.defaultProps = {
    onClick: undefined,
    isDisabled: false,
};
ClickArea.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default ClickArea;
