import React, { useState, useLayoutEffect } from 'react';
import moment from 'moment';
import { AbortError, setDocumentTitle, api } from 'js/utils';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import StatusList from './status-list';

const endpoints = [
    {
        id: 'eventEndpoint',
        urls: {
            development: 'http://localhost:8082/health/',
            production: 'https://ptingless.4d.silverbulletcloud.com/health/',
            staging:
                'https://ptingless-{CLUSTER}.4d.silverbulletcloud.com/health/',
        },
    },
    {
        id: 'iristvEndpoint',
        urls: {
            development: 'http://localhost:8086/health/',
            production: 'https://iristv.4d.silverbulletcloud.com/health/',
            staging:
                'https://iristv-{CLUSTER}.4d.silverbulletcloud.com/health/',
        },
    },
    {
        id: 'serveEndpoint',
        urls: {
            development: 'http://localhost:8083/health/',
            production: 'https://serve.4d.silverbulletcloud.com/health/',
            staging: 'https://serve-{CLUSTER}.4d.silverbulletcloud.com/health/',
        },
    },
    {
        id: 'xandrEndpoint',
        urls: {
            development: 'http://localhost:8085/ready',
            production: 'http://xandr.4d.silverbulletcloud.com/ready',
            staging: 'http://xandr-{CLUSTER}.4d.silverbulletcloud.com/ready',
        },
    },
    {
        id: 'treasuredataEndpoint',
        urls: {
            development: 'http://localhost:8084/health/',
            production: 'https://treasuredata.4d.silverbulletcloud.com/health/',
            staging:
                'https://treasuredata-{CLUSTER}.4d.silverbulletcloud.com/health/',
        },
    },
];

function StatusPage() {
    const [integrationStatuses, setIntegrationStatuses] = useState([]);
    const [endpointStatuses, setEndpointStatuses] = useState([]);
    const [internalStatuses, setInternalStatuses] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [hasError, setError] = useState(false);

    const loadData = async () => {
        try {
            setLoading(true);
            setError(false);

            const [
                // Integration Data
                { results: adformData },
                { results: iristvData },
                { results: treasuredataData },
                { results: xandrData },
                { results: youtubeData },
                // Internal Data
                { results: pageData },
                { results: videoData },
                { results: reportData },
            ] = await Promise.all([
                // Integration Endpoints
                api().auditAdform.list(),
                api().auditIristv.list(),
                api().auditTreasuredata.list(),
                api().auditXandr.list(),
                api().auditYoutube.list(),
                // Internal Endpoints
                api().auditPage.list(),
                api().auditVideo.list(),
                api().auditReport.list(),
            ]);

            const isRecent7Days = (item) =>
                moment(item.activity_date) > moment().subtract(7, 'days');

            const xandrAsync = xandrData
                .filter((item) => item.integration_type === 'ASYNC')
                .filter(isRecent7Days);
            const xandrRealtime = xandrData
                .filter((item) => item.integration_type === 'REALTIME')
                .filter(isRecent7Days);
            const xandrVideobeet = xandrData
                .filter((item) => item.integration_type === 'VIDEOBEET')
                .filter(isRecent7Days);

            setIntegrationStatuses([
                { id: 'xandrAsync', data: xandrAsync },
                { id: 'xandrRealtime', data: xandrRealtime },
                { id: 'xandrVideobeet', data: xandrVideobeet },
                { id: 'iristv', data: iristvData.filter(isRecent7Days) },
                { id: 'youtube', data: youtubeData.filter(isRecent7Days) },
                { id: 'adform', data: adformData.filter(isRecent7Days) },
                {
                    id: 'treasuredata',
                    data: treasuredataData.filter(isRecent7Days),
                },
            ]);

            const statuses = [];

            await endpoints.reduce(async (promise, endpoint) => {
                // This line will wait for the last async function to finish.
                // The first iteration uses an already resolved Promise
                // so, it will immediately continue.
                await promise;
                try {
                    let url;

                    if (process.env.REACT_APP_CLUSTER === 'dev') {
                        url = endpoint.urls.development;
                    } else if (process.env.REACT_APP_CLUSTER === 'avocado') {
                        url = endpoint.urls.production;
                    } else {
                        url = endpoint.urls.staging.replace(
                            '{CLUSTER}',
                            process.env.REACT_APP_CLUSTER,
                        );
                    }

                    const response = await fetch(url);

                    statuses.push({
                        id: endpoint.id,
                        data: [{ is_anomaly: response.status !== 200 }],
                    });
                } catch (e) {
                    statuses.push({
                        id: endpoint.id,
                        data: [{ is_anomaly: true }],
                    });
                }
            }, Promise.resolve());

            setEndpointStatuses(statuses);

            setInternalStatuses([
                { id: 'displayContexts', data: pageData.filter(isRecent7Days) },
                { id: 'videoContexts', data: videoData.filter(isRecent7Days) },
                { id: 'insights', data: reportData.filter(isRecent7Days) },
            ]);
        } catch (errors) {
            if (errors instanceof AbortError) return;

            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        setDocumentTitle(['Status Dashboard', 'Settings']);
        loadData();

        return () => {
            api().abortAll();
        };
    }, []);

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb
                            key="settings"
                            to="/settings/"
                            label="Settings"
                        />,
                        <Crumb
                            key="status-dashboard"
                            label="Status Dashboard"
                        />,
                    ]}
                />
            }
        >
            <StatusList
                integrationStatuses={integrationStatuses}
                endpointStatuses={endpointStatuses}
                internalStatuses={internalStatuses}
                isLoading={isLoading}
                hasError={hasError}
                onRetry={loadData}
            />
        </Layout>
    );
}

export default StatusPage;
