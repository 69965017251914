import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import EditNameButton from '../../components/buttons/edit-name-button';
import styles from '../../rename-data-sources.module.scss';

const RecognizedItemsPanel = ({ item, type, onNameUpdate }) => {
    const name = type === 'campaign' ? item.campaignName : item.lineItemName;
    const id = type === 'campaign' ? item.campaignId : item.lineItemId;

    const [nameDisplayed, setNameDisplay] = useState(name);

    const applyNameChangeHandler = (newName) => {
        onNameUpdate(type, id, name, newName);
    };

    return (
        <Box
            margin={['small', 0]}
            padding="small"
            border={['base', 'solid', 'gray', 'light']}
            borderRadius="round"
        >
            <Row justifyContent="space-between">
                <Col span="auto">
                    <div>
                        <div className={styles['result-text']}>
                            <Text weight="bold" color={['gray', 'darkest']}>
                                {nameDisplayed}
                            </Text>
                        </div>
                        <div className={styles['result-text']}>
                            <Text color={['gray', 'dark']}>{id}</Text>
                        </div>
                    </div>
                </Col>
                <Col span="auto">
                    <EditNameButton
                        name={name}
                        id={id}
                        type={type}
                        onSave={applyNameChangeHandler}
                        onUpdateNameDisplayed={setNameDisplay}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default RecognizedItemsPanel;
