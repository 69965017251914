import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import styles from './section-navigation.module.scss';
import ContextDetailContext from '../../contexts/context-detail.context';

const cx = classNames.bind(styles);

function NavItem({ section, isActive, smoothScrollToAnchor }) {
    const history = useHistory();
    const { location } = history;
    const navItemRef = useRef();
    const classes = cx({
        'nav-item': true,
        active: isActive,
    });

    const onNavItemClick = (e) => {
        setTimeout(() => {
            smoothScrollToAnchor(e, section.id);
        }, 0);
        e.preventDefault();
    };

    useEffect(() => {
        if (location.hash === `#${section.id}`) {
            navItemRef.current.click();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [section.id]);

    useEffect(() => {
        if (isActive) {
            location.hash = `#${section.id}`;
            history.replace(location);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isActive]);

    return (
        <li className={classes}>
            <a
                ref={navItemRef}
                href={`#${section.id}`}
                className={styles.title}
                onClick={onNavItemClick}
            >
                {section.title}
            </a>
        </li>
    );
}

export default function SectionNavigation() {
    const {
        pageSections: { list: pageSections, scrollTo: smoothScrollToAnchor },
    } = useContext(ContextDetailContext);

    const [activePageSections, setActivePageSections] = useState([]);

    useEffect(() => {
        let ticking = false;

        const getActiveSectionIds = (sections) => {
            const activeSectionIds = [];

            const reversed = [...sections];
            reversed.reverse();

            reversed.forEach(({ id: sectionId }) => {
                const documentElement = document.getElementById(sectionId);
                if (!documentElement) return;
                const sectionBoundingClientRect =
                    documentElement.getBoundingClientRect();

                if (
                    sectionBoundingClientRect.top <= window.innerHeight * 0.1 &&
                    sectionBoundingClientRect.bottom > window.innerHeight * 0.1
                ) {
                    activeSectionIds.push(sectionId);
                }
            });

            return activeSectionIds;
        };

        const activeSectionIds = getActiveSectionIds(pageSections);

        if (activeSectionIds.length === 0 && pageSections.length > 0) {
            activeSectionIds.push(pageSections[0].id);
        }

        setActivePageSections(getActiveSectionIds(pageSections));

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    setActivePageSections(getActiveSectionIds(pageSections));
                    ticking = false;
                });

                ticking = true;
            }
        };

        document
            .getElementById('layout-container')
            .addEventListener('scroll', handleScroll);

        return () =>
            document
                .getElementById('layout-container')
                .removeEventListener('scroll', handleScroll);
    }, [pageSections]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <ul>
                    {pageSections.map((section) => (
                        <NavItem
                            key={section.id}
                            section={section}
                            isActive={activePageSections.includes(section.id)}
                            smoothScrollToAnchor={smoothScrollToAnchor}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}
