import React, { useContext } from 'react';
import { getScaleSuggestions } from 'js/utils';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import IndicatorBar from 'js/components/indicator-bar/indicator-bar';
import Modal from 'js/components/modal/modal';
import Text from 'js/components/text/text';
import ContextDetailContext from '../../contexts/context-detail.context';

const SCALE_LOW_LEVEL = 4;
const SCALE_MEDIUM_LEVEL = 8;

export default function ScaleInfoModal({ onClose }) {
    const {
        context: { current: context },
        scale: {
            score: scaleScore,
            getScore: getScaleScore,
            isLoading: scaleChanging,
            errors: scaleErrors,
        },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);

    const suggestions = !isReadOnly ? getScaleSuggestions({ context }) : [];

    const hasError = Object.keys(scaleErrors).length > 0;
    const isUnknown = scaleScore === null;

    const suggestionsContent = suggestions.length > 0 && (
        <>
            <Box padding={['base', 0, 0]}>
                <Text size="large" weight="bolder">
                    <h2>How To Increase Scale</h2>
                </Text>
            </Box>

            {suggestions.map(({ title, message }) => (
                <Box key={title} padding={['small', 0, 0]}>
                    <Text weight="bold">
                        <h3>{title}</h3>
                    </Text>
                    <Text color={['gray', 'dark']}>
                        <p>{message}</p>
                    </Text>
                </Box>
            ))}
        </>
    );

    return (
        <Modal title="Scale" onClose={onClose}>
            <Text color={['gray', 'dark']}>
                <p>
                    Scale is an approximation of how much inventory will be
                    available to a campaign when using this context. The actual
                    scale of a campaign will be affected by other targeting
                    rules, as well as the spending policy of a campaign.
                </p>
            </Text>

            <Box padding={['large', 0, 0]}>
                <Row>
                    <Col span="auto">
                        <IndicatorBar
                            value={scaleScore}
                            lowMessage="Warning: Low Scale"
                            mediumMessage="Medium Scale"
                            highMessage="High Scale"
                            loading={scaleChanging}
                            loadingMessage="Calculating Scale"
                            unknownMessage="Unknown Scale"
                            hasError={hasError}
                            errorMessage="Unable To Calculate Scale"
                            onReload={getScaleScore}
                        />
                    </Col>
                </Row>
            </Box>

            <Box padding={['small', 0, 0]}>
                {!hasError && scaleChanging && (
                    <Text color={['gray', 'dark']}>
                        The scale of this context is being calculated.
                    </Text>
                )}

                {!hasError && !scaleChanging && isUnknown && (
                    <Text color={['gray', 'dark']}>
                        The scale of this context is unknown as there are no
                        active content dimensions on this context.
                    </Text>
                )}

                {!hasError && !scaleChanging && !isUnknown && (
                    <>
                        {scaleScore >= 0 && scaleScore < SCALE_LOW_LEVEL && (
                            <Text color={['gray', 'dark']}>
                                The scale of this context is low. This indicates
                                that there may be an insufficient amount of
                                inventory to run a small campaign successfully.
                            </Text>
                        )}

                        {scaleScore >= SCALE_LOW_LEVEL &&
                            scaleScore < SCALE_MEDIUM_LEVEL && (
                                <Text color={['gray', 'dark']}>
                                    The scale of this context is medium. This
                                    indicates that there may be a sufficient
                                    amount of inventory to run a small to medium
                                    sized campaign successfully.
                                </Text>
                            )}

                        {scaleScore >= SCALE_MEDIUM_LEVEL && (
                            <Text color={['gray', 'dark']}>
                                The scale of this context is high. This
                                indicates that there should be a sufficient
                                amount of inventory to run a typical campaign
                                successfully.
                            </Text>
                        )}
                    </>
                )}
            </Box>

            {suggestionsContent}
        </Modal>
    );
}
