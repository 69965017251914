import { color, fontSize, fontWeight } from '../tokens.styles';

const styles = {
    panelTitlePortrait: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.small,
    },

    panelTitleLandscape: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.base,
    },

    iconPortrait: {
        width: 20,
        height: 20,
    },

    iconLandscape: {
        width: 32,
        height: 32,
    },

    contentPortrait: {
        fontSize: fontSize.smallest,
        color: color.gray.darker,
    },

    contentLandscape: {
        fontSize: fontSize.smaller,
        color: color.gray.darker,
    },

    title: {
        fontWeight: fontWeight.bold,
        // fontSize: "125%",
        color: color.purple.base,
    },

    regular: {
        fontWeight: fontWeight.regular,
    },

    medium: {
        fontWeight: fontWeight.medium,
    },
};

export default styles;
