import Button from 'js/components/button/button';
import SectionTitle from 'js/components/section-title/section-title';
import React, { useEffect, useState } from 'react';
import Box from 'js/components/box/box';
import Spinner from 'js/components/spinner/spinner';
import Alert from 'js/components/alert/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import Col from 'js/components/grid/column';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { eventTagSources } from 'js/constants';
import * as Excel from 'exceljs';
import { Buffer } from 'buffer'; // eslint-disable-line import/no-nodejs-modules
import RecognizedItemsList from './components/recognized-items-list';
import DownloadTemplateButton from './components/download-template-button';
import UploadTemplateButton from './components/upload-template-button';
import {
    exampleCampaignName,
    exampleCampaignId,
    exampleLineItemName,
    exampleLineItemId,
    tabName,
    alertMessages,
} from './constants';

import styles from '../rename-data-sources.module.scss';

const sourceIsValid = (source) => {
    const validSources = eventTagSources.map((tagSrc) => tagSrc.label);
    return validSources.includes(source);
};

const getSourcesId = (sourceLabel) => {
    const { id } = eventTagSources.find(
        (tagSrc) => tagSrc.label === sourceLabel,
    );
    return id;
};

const BulkUploadSection = ({
    onUploadCampaignsFromTemplate,
    onUploadLineItemsFromTemplate,
}) => {
    const [isLoading, setIsLoading] = useState();
    const [alertMsg, setAlertMsg] = useState({});
    const [fileData, setFileData] = useState();
    const [recognizedCampaigns, setRecognizedCampaigns] = useState([]);
    const [recognizedLineItems, setRecognizedLineItems] = useState([]);

    const resetHandler = () => {
        setFileData();
        setRecognizedCampaigns([]);
        setRecognizedLineItems([]);
        setAlertMsg({});
    };

    const updateNameInMemoryHandler = (type, id, name, newName) => {
        if (type === 'campaign') {
            const updatedRecognizedCampaigns = recognizedCampaigns.map(
                (campaign) => {
                    if (
                        campaign.campaignName === name &&
                        campaign.campaignId === id
                    ) {
                        return {
                            ...campaign,
                            campaignName: newName,
                        };
                    }

                    return campaign;
                },
            );

            setRecognizedCampaigns(updatedRecognizedCampaigns);
        } else {
            const updatedRecognizedLineItems = recognizedLineItems.map(
                (lineItem) => {
                    if (
                        lineItem.lineItemName === name &&
                        lineItem.lineItemId === id
                    ) {
                        return {
                            ...lineItem,
                            lineItemName: newName,
                        };
                    }

                    return lineItem;
                },
            );

            setRecognizedLineItems(updatedRecognizedLineItems);
        }
    };

    const confirmUploadHandler = () => {
        setIsLoading(true);
        setAlertMsg({});

        try {
            if (recognizedCampaigns.length > 0)
                onUploadCampaignsFromTemplate(recognizedCampaigns);
            if (recognizedLineItems.length > 0)
                onUploadLineItemsFromTemplate(recognizedLineItems);

            resetHandler();
            setAlertMsg(alertMessages.uploadConfirmed);
        } catch (errors) {
            resetHandler();
            setAlertMsg(alertMessages.errorOnSave);
        } finally {
            setIsLoading(false);
        }
    };

    const extractDataFromFile = async (b64FileData) => {
        // get rows from file
        const workbook = new Excel.Workbook();
        const buffer = Buffer.from(b64FileData, 'base64');
        await workbook.xlsx.load(buffer);
        const rows = [];
        const worksheet = workbook.getWorksheet(tabName);
        worksheet.eachRow((row) => {
            rows.push(row.values);
        });

        // construct rows as json
        const headers = rows[0];
        headers.splice(5, 1, 'Source_1'); // template has two 'Source' columns
        const jsonData = rows.slice(1).map((row) => {
            const rowData = {};
            headers.forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        // validate data, set to state if valid
        const filteredCampaigns = jsonData.filter(
            (row) =>
                (row.Source || row['Campaign Name'] || row['Campaign ID']) &&
                row['Campaign Name'] !== exampleCampaignName &&
                row['Campaign ID'] !== exampleCampaignId,
        );

        const filteredLineItems = jsonData.filter(
            (row) =>
                (row.Source_1 ||
                    row['Line Item Name'] ||
                    row['Line Item ID']) &&
                row['Line Item Name'] !== exampleLineItemName &&
                row['Line Item ID'] !== exampleLineItemId,
        );

        if (filteredCampaigns.length === 0 && filteredLineItems.length === 0) {
            setAlertMsg(alertMessages.noItems);
        }

        filteredCampaigns.forEach((row) => {
            if (!row.Source || !row['Campaign Name'] || !row['Campaign ID']) {
                setAlertMsg(alertMessages.incompleteData);
            } else if (!sourceIsValid(row.Source)) {
                setAlertMsg(alertMessages.invalidSource);
            } else {
                const recognizedCampaign = {
                    source: getSourcesId(row.Source),
                    campaignName: row['Campaign Name'],
                    campaignId: row['Campaign ID'],
                };

                setRecognizedCampaigns((curr) => [...curr, recognizedCampaign]);
            }
        });

        filteredLineItems.forEach((row) => {
            if (
                !row.Source_1 ||
                !row['Line Item Name'] ||
                !row['Line Item ID']
            ) {
                setAlertMsg(alertMessages.incompleteData);
            } else if (!sourceIsValid(row.Source_1)) {
                setAlertMsg(alertMessages.invalidSource);
            } else {
                const recognizedLineItem = {
                    source: getSourcesId(row.Source_1),
                    lineItemName: row['Line Item Name'],
                    lineItemId: row['Line Item ID'],
                };

                setRecognizedLineItems((curr) => [...curr, recognizedLineItem]);
            }
        });
    };

    useEffect(() => {
        setRecognizedCampaigns([]);
        setRecognizedLineItems([]);

        if (fileData) {
            try {
                const base64String = fileData.replace(
                    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                    '',
                );
                extractDataFromFile(base64String);
            } catch (error) {
                setAlertMsg(alertMessages.failedToOpen);
            }
        }
    }, [fileData]);

    return (
        <>
            <SectionTitle
                hasBar
                info={
                    <>
                        <p>
                            Download a template which allows you to fill out
                            your campaign name and ID details
                        </p>
                        <p>
                            Upload your completed template using this button to
                            surface your campaign and line item names throughout
                            the platform
                        </p>
                    </>
                }
                extension={[
                    <DownloadTemplateButton />,
                    <UploadTemplateButton
                        onToggleLoading={setIsLoading}
                        onSetData={setFileData}
                        onError={setAlertMsg}
                    />,
                ]}
            >
                ID Renaming
            </SectionTitle>

            {isLoading && (
                <div className={styles['spinner-container']}>
                    <Box margin="auto">
                        <Spinner
                            size="small"
                            message="Loading"
                            color={['gray', 'dark']}
                        />
                    </Box>
                </div>
            )}

            {!isLoading && alertMsg.msg && (
                <Box margin={['large', 0]}>
                    <Alert theme={alertMsg.theme}>
                        <Row alignItems="center">
                            {alertMsg.icon && (
                                <Col span="auto">
                                    <Text size="huge">
                                        <FontAwesomeIcon icon={alertMsg.icon} />
                                    </Text>
                                </Col>
                            )}
                            <Col>
                                <Box padding="small">{alertMsg.msg}</Box>
                            </Col>
                        </Row>
                    </Alert>
                </Box>
            )}

            {!isLoading && alertMsg.theme !== 'danger' && fileData && (
                <Box
                    margin={['large', 0]}
                    padding="base"
                    border={['base', 'solid', 'gray', 'light']}
                    borderRadius="rounder"
                >
                    <Box margin={['small', 0, 'base', 0]}>
                        <Alert>
                            <Row alignItems="center">
                                <Col span="auto">
                                    <Text size="huge">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                    </Text>
                                </Col>
                                <Col>
                                    <Box padding="small">
                                        {recognizedCampaigns.length} Campaign ID
                                        {recognizedCampaigns.length === 1
                                            ? ''
                                            : 's'}{' '}
                                        and {recognizedLineItems.length} Line
                                        Item ID
                                        {recognizedLineItems.length === 1
                                            ? ''
                                            : 's'}{' '}
                                        successfully uploaded.
                                    </Box>
                                </Col>
                            </Row>
                        </Alert>
                    </Box>

                    {recognizedCampaigns.length > 0 && (
                        <RecognizedItemsList
                            items={recognizedCampaigns}
                            type="campaign"
                            title="Campaign IDs Mapped"
                            info="Campaign IDs are also known as Insertion Order IDs in Google Display & Video 360"
                            onNameUpdate={updateNameInMemoryHandler}
                        />
                    )}

                    {recognizedLineItems.length > 0 && (
                        <RecognizedItemsList
                            items={recognizedLineItems}
                            type="lineItem"
                            title="Line Item IDs Mapped"
                            info="Line Item IDs are also known as Ad Group IDs in The Trade Desk"
                            onNameUpdate={updateNameInMemoryHandler}
                        />
                    )}
                    <Row justifyContent="flex-end" gutter="small">
                        <Col span="auto">
                            <Button theme="outline" onClick={resetHandler}>
                                Remove
                            </Button>
                        </Col>
                        <Col span="auto">
                            <Button onClick={confirmUploadHandler}>
                                Confirm
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}
        </>
    );
};

export default BulkUploadSection;
