import Text from 'js/components/text/text';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Pill from 'js/components/pill/pill';
import CardBase, { getRecentlyUpdated } from './card-base';
import CardWrapper from './components/card-wrapper';
import CardBaseTemplate from './components/card-base-template';
import CardSlimTemplate from './components/card-slim-template';
import styles from './card.module.scss';

const CardAdvertiser = ({
    advertiser,
    isPreview,
    isSlim,
    to,
    onToggleFavorite,
    onDeleteAdvertiser,
}) => {
    const {
        id: advertiserId,
        logo: advertiserLogo,
        name: advertiserName,
        deep_updated: advertiserDeepUpdated,
        context_groups: advertiserCampaignsNumber,
        reports: advertiserReportsNumber,
        is_favourite: isAdvertiserFavorite,
        isFavoriteLoading: isAdvertiserFavoriteLoading,
    } = advertiser;

    const advertiserTitle = advertiserName || (
        <Text inline color={['gray', 'light']}>
            Advertiser name
        </Text>
    );
    const updatedDate = getRecentlyUpdated(advertiserDeepUpdated || new Date());
    const itemsCount =
        advertiserReportsNumber === undefined
            ? advertiserCampaignsNumber
            : advertiserReportsNumber;
    const itemsLabel =
        (advertiserReportsNumber === undefined ? 'Campaign' : 'Report') +
        (itemsCount === 1 ? '' : 's');

    const [deleteErrors, setDeleteErrors] = useState({});
    const [deleteWarning, setDeleteWarning] = useState(false);

    const toggleDeleteWarningModal = () => {
        setDeleteWarning((state) => !state);
        setDeleteErrors({});
    };

    const onDeleteItem = async () => {
        setDeleteErrors({});
        try {
            await onDeleteAdvertiser(advertiserId);
            toggleDeleteWarningModal();
        } catch (err) {
            setDeleteErrors(err);
        }
    };

    const pill = (
        <Pill outline theme="muted">
            {itemsCount || 0} {itemsLabel}
        </Pill>
    );

    const logo = advertiserLogo ? (
        <div className={styles['logo-container']}>
            <img
                className={styles.logo}
                src={advertiserLogo}
                alt={`${advertiserTitle} Logo`}
            />
        </div>
    ) : (
        <div className={styles['logo-preview']}>Logo</div>
    );

    return (
        <>
            {deleteWarning && (
                <ConfirmModal
                    confirm="Yes, Delete Advertiser"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Advertiser"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage={`There was an unexpected error and the advertiser was 
                        not deleted. Please try again in a few moments.`}
                    onCancel={toggleDeleteWarningModal}
                    onConfirm={onDeleteItem}
                >
                    <p>
                        Deleting an advertiser is permanent and cannot be
                        undone. Any campaigns and contexts in this advertiser
                        will also be deleted.
                    </p>
                </ConfirmModal>
            )}
            <CardWrapper
                to={!isPreview && (to || `/context/${advertiserId}/`)}
                title={advertiserTitle}
            >
                <CardBase type="full" isPreview={isPreview} isSlim={isSlim}>
                    {isSlim ? (
                        <CardSlimTemplate
                            logo={logo}
                            pill={<Box padding={['none', 'small']}>{pill}</Box>}
                            isFavorite={isAdvertiserFavorite}
                            isLoading={isAdvertiserFavoriteLoading}
                            to={`/advertisers/${advertiserId}/`}
                            title={advertiserTitle}
                            updatedDate={updatedDate}
                            onDelete={onDeleteAdvertiser}
                            onToggleFavorite={onToggleFavorite}
                            onToggleDeleteWarningModal={
                                toggleDeleteWarningModal
                            }
                        />
                    ) : (
                        <CardBaseTemplate
                            isAdvertiserCardType
                            logo={logo}
                            pill={pill}
                            isFavorite={isAdvertiserFavorite}
                            isLoading={isAdvertiserFavoriteLoading}
                            isPreview={isPreview}
                            to={`/advertisers/${advertiserId}/`}
                            title={advertiserTitle}
                            updatedDate={updatedDate}
                            onDelete={onDeleteAdvertiser}
                            onToggleFavorite={onToggleFavorite}
                            onToggleDeleteWarningModal={
                                toggleDeleteWarningModal
                            }
                        />
                    )}
                </CardBase>
            </CardWrapper>
        </>
    );
};

CardAdvertiser.defaultProps = {
    isPreview: false,
    isSlim: false,
    to: undefined,
    onToggleFavorite: undefined,
    onDeleteAdvertiser: undefined,
};

CardAdvertiser.propTypes = {
    advertiser: PropTypes.shape({
        id: PropTypes.string,
        logo: PropTypes.string.isRequired,
        name: PropTypes.node.isRequired,
        deep_updated: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        context_groups: PropTypes.number,
        reports: PropTypes.number,
        is_favourite: PropTypes.bool,
    }).isRequired,
    isPreview: PropTypes.bool,
    isSlim: PropTypes.bool,
    to: PropTypes.string,
    onToggleFavorite: PropTypes.func,
    onDeleteAdvertiser: PropTypes.func,
};

export default CardAdvertiser;
