import React from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Text from 'js/components/text/text';

const FormErrors = ({ errors }) => {
    let content;
    if (Object.keys(errors).length === 0) return null;
    if (errors.email) {
        content = `You can't be sent a password reset link at the moment 
            because there are problems that have been highlighted in the 
            form above.`;
    } else if (Object.keys(errors).length) {
        content = `You were not sent a password reset email because there 
        was an unexpected error. Please try again in a few moments.`;
    }
    if (!content) return null;
    return (
        <Box margin={[0, 0, 'large']}>
            <Alert theme="danger">
                <p>{content}</p>
            </Alert>
        </Box>
    );
};

const SuccessContent = ({ email }) => (
    <Alert title={"We've Sent You An Email"}>
        <p>
            A password reset email has been sent to <strong>{email}</strong>.
        </p>
        <p>
            It contains a password reset link to allow you to set a new password
            and should arrive in your inbox within the next couple of minutes.
        </p>
    </Alert>
);

const FormContent = ({ errors, setEmail, resetPassword }) => (
    <Form>
        <Box margin={[0, 0, 'large']}>
            <Text size="largest" weight="bolder">
                <h1>Reset Your Password</h1>
            </Text>
            <Box padding={['smaller', 0, 0]}>
                <Text
                    size="base"
                    color={['gray', 'dark']}
                    lineHeight="large"
                    spacing="small"
                >
                    <p>
                        To help make sure no one but you gains access to your
                        account we will send you an email containing a password
                        reset link.
                    </p>
                    <p>Fill in your email address below to get started.</p>
                </Text>
            </Box>
        </Box>
        <Box margin={[0, 0, 'large']}>
            <Input
                focusOnShow
                label="Your Email"
                maxLength={150}
                onChange={(evt) => setEmail(evt.target.value)}
                hasError={!!errors.email}
                errorMessage={<p>{errors.email}</p>}
            />
        </Box>
        <FormErrors errors={errors} />
        <Row justifyContent="flex-end" gutter="small">
            <Col span={12} spanMd="auto">
                <Button theme="outline" to="/login/">
                    Already Know Your Password?
                </Button>
            </Col>

            <Col span={12} spanMd="auto">
                <Box padding={['small', 0, 0]} paddingMd="none">
                    <Button
                        disabled={!!errors.email}
                        type="submit"
                        onClick={resetPassword}
                    >
                        Send Password Reset Email
                    </Button>
                </Box>
            </Col>
        </Row>
    </Form>
);

function PasswordReset({
    email,
    loading,
    success,
    errors,
    setEmail,
    resetPassword,
}) {
    return (
        <Layout alignContent="center">
            {loading ? (
                <LoadingLayer message="Sending Password Reset Email" />
            ) : null}
            <Container size="small">
                <Box padding={['larger', 0]}>
                    <Box
                        background={['gray', 'white']}
                        borderRadius="rounder"
                        shadow="deep"
                    >
                        <Box padding="large">
                            {success ? (
                                <SuccessContent email={email} />
                            ) : (
                                <FormContent
                                    errors={errors}
                                    setEmail={setEmail}
                                    resetPassword={resetPassword}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Layout>
    );
}

export default PasswordReset;
