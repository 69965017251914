import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './no-data-alert.styles';

const NoDataAlert = () => (
    <View style={styles.container}>
        <Text>Not enough data available</Text>
    </View>
);

export default NoDataAlert;
