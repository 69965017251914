import React from 'react';
import moment from 'moment';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';

const NoDataPanel = ({ report }) => {
    const { start_date: startDate, end_date: endDate } = report;
    return (
        <Box margin={['large', 0]}>
            <Container>
                <Alert theme="empty" title="This Report Has No Data">
                    <p>
                        No data has been recorded for this campaign and line
                        items between the{' '}
                        {moment(startDate).format('Do MMMM YYYY')} and{' '}
                        {moment(endDate).format('Do MMMM YYYY')}.
                    </p>
                </Alert>
            </Container>
        </Box>
    );
};

export default NoDataPanel;
