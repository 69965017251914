import PropTypes from 'prop-types';
import React from 'react';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'js/components/button/button';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';

function FavoritesButton({ size, isFavorite, isLoading, onToggleFavorite }) {
    return (
        <Button
            className="favorites"
            theme="outline"
            square
            size={size}
            onClick={(e) => {
                e.preventDefault();
                onToggleFavorite();
            }}
            active={!isLoading && isFavorite}
            disabled={isLoading}
        >
            {isLoading ? (
                <Text size="small">
                    <Spinner />
                </Text>
            ) : (
                <FontAwesomeIcon icon={isFavorite ? faStarSolid : faStar} />
            )}
        </Button>
    );
}

FavoritesButton.defaultProps = {
    size: 'base',
    isFavorite: false,
    isLoading: false,
};

FavoritesButton.propTypes = {
    size: PropTypes.oneOf(['base', 'small', 'large', 'smaller']),
    isFavorite: PropTypes.bool,
    isLoading: PropTypes.bool,
    onToggleFavorite: PropTypes.func.isRequired,
};

export default FavoritesButton;
