import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import editorModes from 'js/enums/editor-modes.enum';
import { cleanString, csvSplit, dedupe } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Label from 'js/components/label/label';
import Pill from 'js/components/pill/pill';
import Text from 'js/components/text/text';
import TextArea from 'js/components/textarea/textarea';
import styles from './input.module.scss';

const SwitchLink = ({ children, onClick }) => (
    <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className={styles.link} onClick={onClick}>
            {children}
        </span>
    </>
);

const KeywordInputRichUI = ({
    keywords,
    label,
    info,
    placeholder,
    emptyMessage,
    isReadOnly,
    theme,
    onChange,
    onToggleMode,
    hideInput,
}) => {
    const [keyword, setKeyword] = useState('');

    const addKeyword = (word) => {
        if (!cleanString(word)) return;

        const newKeywords = dedupe([...keywords, cleanString(word)]);
        onChange(newKeywords);
    };

    const removeKeyword = (word) => {
        const newKeywords = keywords.filter(
            (item) => item !== cleanString(word),
        );
        onChange(newKeywords);
    };

    return (
        <>
            {isReadOnly ? (
                <>
                    {label && <Label label={label} info={info} />}

                    {!keywords.length && emptyMessage && (
                        <Box padding={['base', 0, 0]}>
                            <Alert theme="empty">{emptyMessage}</Alert>
                        </Box>
                    )}
                </>
            ) : (
                <Form
                    onSubmit={() => {
                        addKeyword(keyword);
                        setKeyword('');
                    }}
                >
                    {label && (
                        <Box padding={hideInput ? 'none' : [0, 0, 'small']}>
                            <Row>
                                <Col span="auto">
                                    <Label label={label} info={info} />
                                </Col>
                                <Col>
                                    (
                                    <SwitchLink onClick={onToggleMode}>
                                        Switch To Bulk Edit Mode
                                    </SwitchLink>
                                    )
                                </Col>
                            </Row>
                        </Box>
                    )}
                    {!hideInput && (
                        <Row alignItems="flex-end" gutter="small">
                            <Col>
                                <Input
                                    value={keyword}
                                    placeholder={placeholder}
                                    onChange={(ev) => {
                                        setKeyword(ev.target.value);
                                    }}
                                />
                            </Col>

                            <Col span="auto">
                                <Button
                                    type="submit"
                                    theme="outline"
                                    disabled={!keyword}
                                >
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Form>
            )}

            <Box>
                {keywords.map((word) => (
                    <Box
                        key={word}
                        margin={['small', 'small', 0, 0]}
                        display="inline-block"
                    >
                        <Pill
                            outline
                            theme={theme}
                            suffixButton={
                                isReadOnly ? null : (
                                    <FontAwesomeIcon icon={faTimes} />
                                )
                            }
                            onSuffixClick={() => removeKeyword(word)}
                        >
                            <Text weight="bold">{word}</Text>
                        </Pill>
                    </Box>
                ))}
            </Box>
        </>
    );
};

const KeywordInputBulk = ({
    keywords,
    label,
    info,
    onChange,
    onToggleMode,
}) => {
    const [value, setValue] = useState(keywords.join('\n'));
    const [typingTimeout, setTypingTimeout] = useState(null);

    const commit = async (text) => {
        clearTimeout(typingTimeout);
        onChange(dedupe(csvSplit(text)));
    };

    const handleChange = (event) => {
        const text = event.target.value;
        setValue(text);
        setTypingTimeout(() => setTimeout(() => commit(text), 300));
    };

    useEffect(() => () => clearTimeout(typingTimeout), [typingTimeout]);

    return (
        <>
            {label && (
                <Box padding={[0, 0, 'small']}>
                    <Row>
                        <Col span="auto">
                            <Label label={label} info={info} />
                        </Col>
                        <Col>
                            (
                            <SwitchLink onClick={onToggleMode}>
                                Switch To Rich UI Mode
                            </SwitchLink>
                            )
                        </Col>
                    </Row>
                </Box>
            )}

            <Form>
                <TextArea
                    value={value}
                    rows={7}
                    onChange={handleChange}
                    onBlur={() => commit(value)}
                />

                <Box padding={['small', 0, 0]}>
                    <Text size="base" color={['gray', 'dark']}>
                        Keywords can be separated by a new line or comma.
                    </Text>
                </Box>
            </Form>
        </>
    );
};

const KeywordInput = ({
    keywords,
    label,
    info,
    placeholder,
    emptyMessage,
    isReadOnly,
    theme,
    onChange,
    hideInput,
}) => {
    const [editorMode, setEditorMode] = useState(editorModes.RICH_UI);

    return editorMode === editorModes.RICH_UI ? (
        <KeywordInputRichUI
            keywords={keywords}
            isReadOnly={isReadOnly}
            label={label}
            info={info}
            placeholder={placeholder}
            emptyMessage={emptyMessage}
            theme={theme}
            onChange={onChange}
            onToggleMode={() => setEditorMode(editorModes.BULK_EDIT)}
            hideInput={hideInput}
        />
    ) : (
        <KeywordInputBulk
            keywords={keywords}
            label={label}
            info={info}
            onChange={onChange}
            onToggleMode={() => setEditorMode(editorModes.RICH_UI)}
        />
    );
};

KeywordInput.defaultProps = {
    label: undefined,
    info: undefined,
    placeholder: 'Add keywords',
    emptyMessage: undefined,
    isReadOnly: false,
    theme: 'keyword',
    hideInput: false,
};

KeywordInput.propTypes = {
    keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node,
    info: PropTypes.node,
    placeholder: PropTypes.string,
    emptyMessage: PropTypes.node,
    isReadOnly: PropTypes.bool,
    theme: PropTypes.oneOf([
        'danger',
        'muted',
        'muted-light',
        'keyword',
        'default-darker',
        'blue',
        'blue-light',
    ]),
    onChange: PropTypes.func.isRequired,
    hideInput: PropTypes.bool,
};

export default KeywordInput;
