import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faMinus,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import Panel from 'js/components/panel/panel';

function PageList({ pages, onChangePages, allPages }) {
    const canRemovePages = pages.length > 1;

    const removedPages = allPages.filter(
        (p) => !pages.some((g) => g.id === p.id),
    );

    const changeOrder = (sectionIndex, offset) => {
        const orderedItem = pages[sectionIndex];

        pages.splice(sectionIndex, 1);
        pages.splice(sectionIndex + offset, 0, orderedItem);

        onChangePages([...pages]);
    };

    const removePage = (pageId) => {
        const newPages = pages.filter((pg) => pg.id !== pageId);
        onChangePages(newPages);
    };

    const addPage = (addedPage) => {
        const updatedPages = [...pages, addedPage];
        onChangePages(updatedPages);
    };

    return (
        <>
            <ul>
                {pages.map(({ id, label }, idx) => (
                    <Box padding={['smaller', 'base']} key={id}>
                        <Row alignItems="center">
                            <Col span={7}>
                                <Text size="large">{label}</Text>
                            </Col>

                            <Col span={3}>
                                <Row gutter="small" justifyContent="center">
                                    <Col span={3}>
                                        {idx < pages.length - 1 && (
                                            <Button
                                                size="small"
                                                theme="outline"
                                                square
                                                onClick={() => {
                                                    changeOrder(idx, 1);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                />
                                            </Button>
                                        )}
                                    </Col>

                                    <Col span={3}>
                                        {idx > 0 && (
                                            <Button
                                                size="small"
                                                theme="outline"
                                                square
                                                onClick={() => {
                                                    changeOrder(idx, -1);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faChevronUp}
                                                />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={2}
                                style={{
                                    justifyContent: 'end',
                                    textAlign: 'end',
                                }}
                            >
                                <Row justifyContent="flex-end">
                                    <Col span="auto">
                                        {canRemovePages && (
                                            <Button
                                                size="small"
                                                theme="outline"
                                                square
                                                onClick={() => removePage(id)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMinus}
                                                />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Box>
                ))}
            </ul>
            <ul>
                {removedPages.map((removed) => (
                    <Box padding={['smaller', 'base']} key={removed.id}>
                        <Row alignItems="center">
                            <Col span={10}>
                                <Text size="large" color={['gray', 'base']}>
                                    {removed.label}
                                </Text>
                            </Col>

                            <Col
                                span={2}
                                style={{
                                    justifyContent: 'end',
                                    textAlign: 'end',
                                }}
                            >
                                <Row justifyContent="flex-end">
                                    <Col span="auto">
                                        <Button
                                            size="small"
                                            square
                                            onClick={() => addPage(removed)}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Box>
                ))}
            </ul>
        </>
    );
}

export default function PdfPageSelector({ pages, onChangePages, allPages }) {
    return (
        <Box padding={['base', 0, 'smaller']}>
            <Text weight="bolder" size="large">
                Page Order
            </Text>

            <Box padding={['smallest', 0, 'small']}>
                <Text color={['gray', 'dark']}>
                    Select which pages to include in your report, as well as the
                    order in which they appear.
                </Text>
            </Box>
            <Panel theme="secondary">
                <Box padding={['smaller', 0]}>
                    <PageList
                        pages={pages}
                        onChangePages={onChangePages}
                        allPages={allPages}
                    />
                </Box>
            </Panel>
        </Box>
    );
}
