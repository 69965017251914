import React from 'react';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import SectionTitle from 'js/components/section-title/section-title';
import Text from 'js/components/text/text';

export default function SectionWrapper({ title, info, children }) {
    return (
        <Box margin={['large', 0]}>
            <Container>
                {title && (
                    <SectionTitle hasBar info={info}>
                        <Text weight="base" size="huge">
                            {title}
                        </Text>
                    </SectionTitle>
                )}

                {children}
            </Container>
        </Box>
    );
}
