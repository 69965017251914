import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { breakpointKeys } from 'js/constants';
import { getBreakpointCssPrefix } from 'js/utils';
import styles from './grid.module.scss';

function Col(props) {
    const { children, overflow } = props;
    const classes = [styles.col];

    if (overflow) {
        classes.push(styles[`overflow--${overflow}`]);
    }

    breakpointKeys.forEach((breakpointKey) => {
        const breakpointCssPrefix = getBreakpointCssPrefix(breakpointKey);

        const {
            [`span${breakpointKey}`]: spanProp,
            [`alignSelf${breakpointKey}`]: alignSelfProp,
            [`offset${breakpointKey}`]: offsetProp,
            [`order${breakpointKey}`]: orderProp,
        } = props;

        if (spanProp) {
            classes.push(styles[`${breakpointCssPrefix}col--${spanProp}`]);
        }

        if (alignSelfProp) {
            classes.push(
                styles[
                    `${breakpointCssPrefix}col--align-self-${alignSelfProp}`
                ],
            );
        }

        if (offsetProp) {
            classes.push(
                styles[`${breakpointCssPrefix}col--offset-${offsetProp}`],
            );
        }

        if (orderProp) {
            classes.push(
                styles[`${breakpointCssPrefix}col--order-${orderProp}`],
            );
        }
    });

    return <div className={classNames(classes)}>{children}</div>;
}

const spans = ['auto', 'equal', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

Col.defaultProps = {
    children: undefined,
    overflow: undefined,
    span: undefined,
    spanXs: undefined,
    spanSm: undefined,
    spanMd: undefined,
    spanLg: undefined,
    spanXl: undefined,
    alignSelf: undefined,
    alignSelfXs: undefined,
    alignSelfSm: undefined,
    alignSelfMd: undefined,
    alignSelfLg: undefined,
    alignSelfXl: undefined,
    offset: undefined,
    offsetXs: undefined,
    offsetSm: undefined,
    offsetMd: undefined,
    offsetLg: undefined,
    offsetXl: undefined,
    order: undefined,
    orderXs: undefined,
    orderSm: undefined,
    orderMd: undefined,
    orderLg: undefined,
    orderXl: undefined,
};

/* eslint-disable react/no-unused-prop-types */
Col.propTypes = {
    children: PropTypes.node,
    overflow: PropTypes.oneOf(['hidden']),
    span: PropTypes.oneOf(spans),
    spanXs: PropTypes.oneOf(spans),
    spanSm: PropTypes.oneOf(spans),
    spanMd: PropTypes.oneOf(spans),
    spanLg: PropTypes.oneOf(spans),
    spanXl: PropTypes.oneOf(spans),
    alignSelf: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    alignSelfXs: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    alignSelfSm: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    alignSelfMd: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    alignSelfLg: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    alignSelfXl: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offsetXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offsetSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offsetMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offsetLg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offsetXl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderLg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderXl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/* eslint-enable react/no-unused-prop-types */

export default Col;
