import React from 'react';
import { createPresetUrl, reloadPage } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardContext from 'js/components/card/card-context';
import CardCreateNew from 'js/components/card/card-create-new';
import CardLoading from 'js/components/card/card-loading';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';

const Wrapper = ({ children }) => (
    <Section>
        <Container>
            <Box padding={['base', 0, 0]}>
                <Row>{children}</Row>
            </Box>
        </Container>
    </Section>
);

function CampaignContexts({
    loading,
    hasLoadingError,
    readOnly,
    campaign,
    contexts,
    advertiser,
    onToggleFavorite,
    onDelete,
}) {
    const newContext = { group: campaign.id, advertiser: advertiser.id };
    const newContextUrl = createPresetUrl('/contexts/new/', newContext);

    if (loading)
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <CardLoading loadingText="Loading Contexts" />
                    </Box>
                </Col>
            </Wrapper>
        );

    if (hasLoadingError) {
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <Alert
                            theme="danger"
                            title="There was an error loading the contexts"
                            actionItems={
                                <Button onClick={reloadPage}>Retry</Button>
                            }
                        >
                            <p>
                                Hopefully it is only a temporary issue. Please
                                try again in a few moments.
                            </p>
                        </Alert>
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    if (!contexts.length) {
        if (readOnly) {
            return (
                <Wrapper>
                    <Box padding={['base', 0, 0]}>
                        <Alert theme="empty">You have no contexts.</Alert>
                    </Box>
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <CardCreateNew
                            title="Create New Context"
                            to={newContextUrl}
                        />
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {contexts.map((context) => (
                <Col
                    key={context.id}
                    span={12}
                    spanSm={12}
                    spanMd={6}
                    spanLg={4}
                >
                    <Box padding={['base', 0, 0]}>
                        <CardContext
                            context={context}
                            hasDealID
                            onToggleFavorite={() =>
                                onToggleFavorite(context.id)
                            }
                            onDeleteContext={() => onDelete(context.id)}
                        />
                    </Box>
                </Col>
            ))}

            <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                <Box padding={['base', 0, 0]}>
                    <CardCreateNew
                        title="Create New Context"
                        to={newContextUrl}
                    />
                </Box>
            </Col>
        </Wrapper>
    );
}

export default CampaignContexts;
