import React from 'react';
import {
    faEye,
    faEyeSlash,
    faPlayCircle,
} from '@fortawesome/free-regular-svg-icons';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import Container from 'js/components/container/container';
import Alert from 'js/components/alert/alert';
import HighlightsSection from '../../../components/highlights-section/highlights-section';
import PanelWrapper from '../../../components/panel-wrapper';
import TreemapSection from '../treemap-section';
import TableSection from '../table-section';
import targetingOptions from '../../enums/targetingOptions';

const LogosPanel = ({
    report,
    context,
    logoGroups,
    onChangeContextRules,
    isDV360Report,
    showClicks,
    onInspectItem,
}) => {
    const kpis = {
        IMPRESSIONS: {
            name: 'Impressions',
            getValue: (item) => item.impressions,
            color: ['purple', 'base'],
            isPercentage: false,
            hintIcon: faEyeSlash,
        },
        STARTED_VIEWS: {
            name: 'Started Views',
            getValue: (item) => item.starts,
            color: ['aqua', 'dark'],
            isPercentage: false,
            hintIcon: faPlayCircle,
        },
        COMPLETION_RATE: {
            name: `${isDV360Report ? 'True View' : 'Completion'} Rate`,
            getValue: (item) =>
                item.completes && item.impressions
                    ? item.completes / item.impressions
                    : 0,
            color: ['yellow', 'base'],
            isPercentage: true,
            hintIcon: faEye,
        },
    };

    const highlightsData = report.logos.map((item) => ({
        ...item,
        name: item.logo_name,
        logo: logoGroups.find((group) =>
            group.logos.find((logo) => logo.id === item.logo),
        )?.logo,
    }));

    return (
        <Container>
            {report.logos.length === 0 ? (
                <Alert
                    theme="empty"
                    title="This report did not identify any brand themes"
                />
            ) : (
                <>
                    <TreemapSection
                        report={report}
                        targetingOption={targetingOptions.LOGO}
                        isDV360Report={isDV360Report}
                        showClicks={showClicks}
                    />

                    <Box margin={['large', 0]}>
                        <PanelWrapper>
                            <Box margin={['base', 0]}>
                                <Box margin={[0, 0, 'base']}>
                                    <Text weight="bold" size="larger">
                                        Highlights
                                    </Text>
                                </Box>

                                <HighlightsSection
                                    data={highlightsData}
                                    report={report}
                                    kpis={kpis}
                                    impressionsHint="If you do not want to show your 
                                    advertising on these brands, make an adjustment 
                                    to your targeting."
                                    startedViewsHint="If started views is one of your key metrics, you 
                                might want to target these top performing brands."
                                    compRateHint={`If ${
                                        isDV360Report
                                            ? 'true view'
                                            : 'completion'
                                    } rate is one of your key metrics, you 
                                    might want to target these top performing brands.`}
                                />
                            </Box>
                        </PanelWrapper>
                    </Box>

                    <TableSection
                        report={report}
                        context={context}
                        targetData={report.logos}
                        dataGroups={logoGroups}
                        targetingOption={targetingOptions.LOGO}
                        onChangeContextRules={onChangeContextRules}
                        isDV360Report={isDV360Report}
                        showClicks={showClicks}
                        onInspectItem={onInspectItem}
                    />
                </>
            )}
        </Container>
    );
};

export default LogosPanel;
