/* eslint-disable no-console */

import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
    componentDidCatch(err, errInfo) {
        console.error(err); // todo: send to API
        console.log(errInfo);
        const { history, to } = this.props;
        history.replace(to);
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
};

export default withRouter(ErrorBoundary);
