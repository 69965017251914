import React, { useState } from 'react';
import * as d3 from 'd3';
import Box from 'js/components/box/box';
import Legend from 'js/components/treemap-chart/legend';
import TreeMapChart from 'js/components/treemap-chart/treemap-chart';
import CtrSwitchLegend from '../../../components/ctr-switch-legend';
import DropdownTooltip from '../../../components/dropdown-tooltip/dropdown-tooltip';
import styles from '../../../report-detail.module.scss';

function TopicsTreemapSection({ report }) {
    const [isCtrView, setCtrView] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [dropdownCoords, setDropdownCoords] = useState(true);
    const [dropdownData, setDropdownData] = useState({});

    const branchData = {
        children: [...report.topic_groups]
            .sort((a, b) => b.impressions - a.impressions)
            .slice(0, 25)
            .map((topicGroup) => ({
                label: topicGroup.topic_group_name,
                value: topicGroup.impressions,
                clicks: topicGroup.clicks,
                inViews: topicGroup.in_views,
                ctr: topicGroup.clicks / topicGroup.impressions,
            })),
    };

    const onClick = (event, svgRef, nodeData, setColor, color, isDropdown) => {
        if (!isDropdown) return;
        if (event.currentTarget === event.target) {
            setToggleDropdown(false);
        }
        const { label, value, clicks, ctr, inViews } = nodeData;
        const svgElement = svgRef.current.getBoundingClientRect();
        const x = event.clientX - svgElement.left;
        const y = event.clientY - svgElement.top;
        let data = [
            {
                title: 'Impressions',
                value,
                isDelta: false,
                comparedValue: report.impressions,
            },
            {
                title: 'Clicks',
                value: clicks,
                isDelta: false,
                comparedValue: report.clicks,
            },
            {
                title: 'CTR',
                value: ctr,
                isDelta: true,
                comparedValue: report.clicks / report.impressions,
            },
            {
                title: 'Viewable Impressions',
                value: inViews,
                isDelta: false,
                comparedValue: report.in_views,
            },
            {
                title: 'View Rate',
                value: inViews / value,
                isDelta: true,
                comparedValue: report.in_views / report.impressions,
            },
        ];

        if (!report.in_views) {
            data = data.slice(0, -2);
        }

        setDropdownData({
            label,
            data,
        });
        setColor(color);
        setDropdownCoords({ x, y });
        setToggleDropdown(true);
    };

    const onMouseEnter = (setColor, color, isDropdown) =>
        isDropdown && setColor(color);

    const onMouseLeave = (setColor, color, isDropdown) => {
        if (!isDropdown) return;
        setColor(color);
        setToggleDropdown(false);
    };

    const impressionsColorFlatScale = d3.scaleSequential(() => '#6673FF');
    const impressionsSizeScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateNumber(0.75, 1.25));
    const ctrColorScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateRgb('#4CDCE6', '#137F86'));
    const ctrColorFlatScale = d3.scaleSequential(() => '#4CDCE6');
    const ctrSizeFlatScale = d3.scaleSequential(() => 1.0);

    return (
        <Box margin={['base', 0]}>
            <div className={styles['treemap-chart-container']}>
                <TreeMapChart
                    data={branchData}
                    customD3ColorScale={
                        isCtrView ? ctrColorScale : impressionsColorFlatScale
                    }
                    colorableValue={isCtrView ? 'data.ctr' : 'value'}
                    leftLegend={
                        <CtrSwitchLegend
                            colorScale={ctrColorScale}
                            sizeScale={ctrSizeFlatScale}
                            isChecked={isCtrView}
                            onChange={() => setCtrView((prev) => !prev)}
                        />
                    }
                    rightLegend={
                        <Legend
                            lowLabel="Fewer Impressions"
                            highLabel="More Impressions"
                            colorScale={
                                isCtrView
                                    ? ctrColorFlatScale
                                    : impressionsColorFlatScale
                            }
                            sizeScale={impressionsSizeScale}
                        />
                    }
                    dropdownTooltip={
                        <DropdownTooltip
                            dropdownCoords={dropdownCoords}
                            setToggleDropdown={setToggleDropdown}
                            toggleDropdown={toggleDropdown}
                            dropdownData={dropdownData}
                        />
                    }
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    hoverColor={isCtrView ? '#0C5257' : '#3040E8'}
                    activeColor={isCtrView ? '#093F43' : '#242A6B'}
                />
            </div>
        </Box>
    );
}

export default TopicsTreemapSection;
