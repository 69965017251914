import * as d3 from 'd3';

export const everyNth = (arr, n) => arr.filter((obj, i) => i % n === 0);

export const getBottomAxisInterval = (chartWidth, data) =>
    // 1000 px can support 10 ticks
    Math.max(parseInt(data.length / ((chartWidth + 1) / 100), 10), 3);

export const parseDate = d3.timeParse('%Y-%m-%d');

export const valueFormat = (value, format) => {
    if (typeof format === 'function') {
        return format(value);
    }
    if (format === 'M' || format === 'm') {
        if (value / 1000000 >= 1) {
            return `${Math.round(value / 1000000)}${format}`;
        }
        return value / 1000 >= 1 ? `${Math.round(value / 100) / 10}K` : value;
    }
    if (format === 'K' || format === 'k') {
        return value / 1000 >= 1
            ? `${Math.round(value / 100) / 10}${format}`
            : value;
    }
    if (format === '%') {
        return `${Math.round(value * 10000) / 100}%`;
    }
    return value;
};
