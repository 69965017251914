import React from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';

const Benefit = ({ title, children }) => (
    <Panel>
        <Box padding="base">
            <Text size="larger">{title}</Text>
            <Box padding={['small', 0, 0]}>
                <Text color={['gray', 'dark']}>{children}</Text>
            </Box>
        </Box>
    </Panel>
);

const Benefits = () => (
    <>
        <Box padding={[0, 0, 'base']}>
            <Text size="larger" weight="bold">
                Benefits
            </Text>
        </Box>
        <Row gap="base">
            <Col span={12} spanSm={6}>
                <Benefit title="Intelligent Optimizations">
                    Advanced AI analyzes your campaign data to generate
                    performance-boosting optimizations and apply them
                    automatically to your context.
                </Benefit>
            </Col>
            <Col span={12} spanSm={6}>
                <Benefit title="Effortless Management">
                    We know you have a million things to do. That’s why with
                    Auto Optimizations you can simply opt in and let us take
                    care of the rest.
                </Benefit>
            </Col>
        </Row>
    </>
);

export default Benefits;
