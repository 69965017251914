import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './textarea.module.scss';

function TextArea({
    value,
    placeholder,
    rows,
    isActive,
    focusOnShow,
    ...textareaProps
}) {
    const textareaElement = useRef();

    useEffect(() => {
        const focusTextarea = () => {
            textareaElement.current?.focus();
            textareaElement.current?.setSelectionRange(
                value.length,
                value.length,
            );
        };

        if (focusOnShow) {
            focusTextarea();
        }
    }, [focusOnShow, value]);
    const classes = [styles.container];
    if (isActive) {
        classes.push(styles['is-active']);
    }
    return (
        <div className={classNames(classes)}>
            <textarea
                value={value}
                placeholder={placeholder}
                rows={rows}
                autoComplete="off"
                ref={textareaElement}
                {...textareaProps}
            />
        </div>
    );
}

TextArea.defaultProps = {
    value: undefined,
    placeholder: undefined,
    rows: 4,
    isActive: false,
    focusOnShow: false,
};

TextArea.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    isActive: PropTypes.bool,
    focusOnShow: PropTypes.bool,
};

export default TextArea;
