import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import entityTypes from 'js/enums/entity-types.enum';
import Box from 'js/components/box/box';
import ConfirmModal from 'js/components/modal/confirm-modal';
import ErrorBar from 'js/components/error-bar/error-bar';
import Spinner from 'js/components/spinner/spinner';
import { showToast } from 'js/components/toast/toast';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import { RuleSet } from 'js/utils';
import Tabs from 'js/components/tabs/tabs';
import Container from 'js/components/container/container';
import TabContent from 'js/components/tabs/content';
import SectionTitle from 'js/components/section-title/section-title';
import Text from 'js/components/text/text';
import InfoPanel from '../components/info-panel';
import NoDataPanel from '../components/no-data-panel';
import PdfDownloadDrawer from '../components/pdf-download-drawer/pdf-download-drawer';
import ProcessingPanel from '../components/processing-panel';
import SnapshotModal from '../components/snapshot-modal';
import SummaryPanel from '../components/summary-panel/summary-panel';
import ActionButtons from './components/action-buttons';
import ChannelsPanel from './components/channels-panel/channels-panel';
import LogosPanel from './components/logos-panel/logos-panel';
import RecommendationsTab from './components/recommendations-tab/recommendations-tab';
import TopicsPanel from './components/topics-panel/topics-panel';
import VideoInspectDrawer from './components/video-inspect-drawer';
import pageTabs from './enums/pageTabs';
import targetingOptions from './enums/targetingOptions';

const SectionWrapper = ({ title, description, children }) => (
    <Box margin={['large', 0, 'larger']}>
        <Container>
            <Box margin={[0, 0, 'large']}>
                <SectionTitle hasBar info={description}>
                    <Text weight="base" size="huge">
                        {title}
                    </Text>
                </SectionTitle>
            </Box>
        </Container>
        {children}
    </Box>
);

const InsightsPanel = ({
    report,
    context,
    topicGroups,
    logoGroups,
    onChangeContextRules,
    isDV360Report,
    showClicks,
    onInspectItem,
}) => {
    const insightsTabs = {
        TOPICS: 'Topics',
        LOGOS: 'Brands',
    };
    const [currentTab, setCurrentTab] = useState(insightsTabs.TOPICS);

    const buttonsWrapper = ({ children }) => (
        <Container>
            <Box margin={['large', 0]}>
                <SectionTitle
                    hasBar
                    extension={[<div key="children">{children}</div>]}
                >
                    <div>
                        <Text weight="base" size="huge">
                            {currentTab === insightsTabs.TOPICS
                                ? 'Topic Themes'
                                : 'Brand Themes'}
                        </Text>
                    </div>
                </SectionTitle>
            </Box>
        </Container>
    );

    return (
        <Tabs
            tabs={insightsTabs}
            currentTab={currentTab}
            onChangeTab={(newTab) => setCurrentTab(newTab)}
            buttonsWrapper={buttonsWrapper}
        >
            <TabContent tabId={insightsTabs.TOPICS}>
                <TopicsPanel
                    report={report}
                    context={context}
                    topicGroups={topicGroups}
                    onChangeContextRules={onChangeContextRules}
                    isDV360Report={isDV360Report}
                    showClicks={showClicks}
                    onInspectItem={onInspectItem}
                />
            </TabContent>
            <TabContent tabId={insightsTabs.LOGOS}>
                <LogosPanel
                    report={report}
                    context={context}
                    logoGroups={logoGroups}
                    onChangeContextRules={onChangeContextRules}
                    isDV360Report={isDV360Report}
                    showClicks={showClicks}
                    onInspectItem={onInspectItem}
                />
            </TabContent>
        </Tabs>
    );
};

const ReportContent = ({
    report,
    template,
    context,
    currentTab,
    topicGroups,
    logoGroups,
    onChangeContextRules,
    onChangeTab,
    onInspectItem,
}) => {
    const isDV360Report =
        template.subtype === videoReportSubtypes.YOUTUBE_DV360;
    const isYoutubeReport = [
        videoReportSubtypes.YOUTUBE_DV360,
        videoReportSubtypes.YOUTUBE_GADS,
        videoReportSubtypes.YOUTUBE_GADS_FILE,
    ].includes(template.subtype);
    const showClicks = !isDV360Report || (isDV360Report && report?.clicks);
    const hasChannelHighlights = report?.channel_highlights.length > 0;

    const buttonsWrapper = ({ children }) => (
        <Container>
            <Box padding={['large', 0, 0]}>{children}</Box>
        </Container>
    );

    if (!report) {
        return <ProcessingPanel />;
    }

    if (!report.impressions) {
        return <NoDataPanel report={report} />;
    }

    return (
        <Tabs
            tabs={pageTabs}
            currentTab={currentTab}
            onChangeTab={onChangeTab}
            buttonsWrapper={buttonsWrapper}
        >
            <TabContent tabId={pageTabs.OVERVIEW}>
                <SectionWrapper title="Overview">
                    <InfoPanel
                        report={report}
                        template={template}
                        context={context}
                        reportType="Video"
                    />
                    <SummaryPanel report={report} template={template} />
                </SectionWrapper>
            </TabContent>
            <TabContent tabId={pageTabs.RECOMMENDATIONS}>
                <RecommendationsTab
                    report={report}
                    context={context}
                    onChangeContextRules={onChangeContextRules}
                    isDV360Report={isDV360Report}
                />
            </TabContent>
            <TabContent tabId={pageTabs.INSIGHTS}>
                {isYoutubeReport && hasChannelHighlights && (
                    <SectionWrapper title="Channels">
                        <ChannelsPanel
                            report={report}
                            rules={context.rules}
                            topicGroups={topicGroups}
                            isDV360Report={isDV360Report}
                            onChangeContextRules={onChangeContextRules}
                        />
                    </SectionWrapper>
                )}
                <Box margin={[0, 0, 'larger', 0]}>
                    <InsightsPanel
                        report={report}
                        context={context}
                        topicGroups={topicGroups}
                        logoGroups={logoGroups}
                        onChangeContextRules={onChangeContextRules}
                        isDV360Report={isDV360Report}
                        showClicks={showClicks}
                        onInspectItem={onInspectItem}
                    />
                </Box>
            </TabContent>
        </Tabs>
    );
};

const ReportVideoDetail = ({
    report,
    reports,
    template,
    context,
    topicGroups,
    logoGroups,
    loading,
    hasLoadingError,
    hasDeletingError,
    hasSnapshotError,
    hasUnsavedChanges,
    onChangeContextRules,
    onDelete,
    onDiscardChanges,
    onExportExcel,
    onSnapshot,
    onToggleFavorite,
    onReload,
    onSwitchReport,
    matchedVideos,
    hasMatchedVideosError,
    isMatchedVideosLoading,
    onLoadMatchedVideos,
}) => {
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
    const [snapshotModalIsVisible, setSnapshotModalIsVisible] = useState(false);
    const [unsavedModalIsVisible, setUnsavedModalIsVisible] = useState(false);
    const [pdfDrawerIsVisible, setPdfDrawerIsVisible] = useState(false);
    const [inspectDrawerVisible, setInspectDrawerVisible] = useState(false);
    const [inspectDrawerItem, setInspectDrawerItem] = useState(false);
    const [nextLocation, setNextLocation] = useState();
    const [currentTab, setCurrentTab] = useState(pageTabs.OVERVIEW);

    const history = useHistory();

    const { is_ready: isReady } = template;

    const changeTab = (tabId) => {
        setCurrentTab(tabId);
        document.getElementById('layout-container').scrollTop = 0;
    };

    const handleSnapshot = async (dateRange) => {
        await onSnapshot(dateRange);
        setSnapshotModalIsVisible(false);
        showToast(
            'Success',
            <p>
                The snapshot has been created and will be ready to view within
                the next 24 hours.
            </p>,
            10,
        );
    };

    const handleLocationChange = (location) => {
        const { pathname: nextPathname } = location;
        const { pathname: currentPathname } = history.location;
        if (
            hasUnsavedChanges &&
            nextPathname !== currentPathname &&
            !nextPathname.startsWith('/login/') &&
            !nextPathname.startsWith('/reload/')
        ) {
            setUnsavedModalIsVisible(true);
            setNextLocation(location);

            return false;
        }

        return true;
    };

    const onInspectItem = (selectedItem) => {
        const dataKey =
            selectedItem.targetingOption === targetingOptions.TOPIC
                ? 'topics'
                : 'logos';
        const ruleSet = new RuleSet([{ [dataKey]: [selectedItem.id] }]);

        onLoadMatchedVideos({ ...context, rules: ruleSet.rules });
        setInspectDrawerItem(selectedItem);
        setInspectDrawerVisible(true);
    };

    useEffect(() => {
        if (nextLocation && !hasUnsavedChanges) {
            setNextLocation(null);
            const { pathname, search } = nextLocation;
            history.push(pathname + search);
        }
    }, [nextLocation, hasUnsavedChanges]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return (
            <Box margin={['large', 0]}>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Box>
        );
    }

    if (hasLoadingError) {
        return (
            <Box margin={['large', 0]}>
                <ErrorBar
                    size="small"
                    title="There Was An Error Loading The Insights Report"
                    onAction={onReload}
                />
            </Box>
        );
    }

    return (
        <>
            <ActionButtons
                template={template}
                report={report}
                onSwitchReport={onSwitchReport}
                reports={reports}
                onDelete={() => setDeleteModalIsVisible(true)}
                onExportExcel={onExportExcel}
                onExportPdf={() => setPdfDrawerIsVisible(true)}
                onSnapshot={() => setSnapshotModalIsVisible(true)}
                onToggleFavorite={onToggleFavorite}
            />

            <ReportContent
                report={report}
                template={template}
                context={context}
                currentTab={currentTab}
                topicGroups={topicGroups}
                logoGroups={logoGroups}
                onChangeContextRules={onChangeContextRules}
                onChangeTab={changeTab}
                onInspectItem={onInspectItem}
            />

            {deleteModalIsVisible && (
                <ConfirmModal
                    title={`Are You Sure You Want To Delete ${template.name}?`}
                    confirm="Yes, Delete Insights Report"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Insights Report"
                    hasError={hasDeletingError}
                    errorMessage="
                        You were unable to delete the insights report because there
                        was an unexpected error. Please try again in a few moments."
                    onConfirm={onDelete}
                    onCancel={() => setDeleteModalIsVisible(false)}
                />
            )}

            {snapshotModalIsVisible && (
                <SnapshotModal
                    type={entityTypes.VIDEO_REPORTS}
                    reports={reports}
                    isReady={isReady}
                    hasError={hasSnapshotError}
                    onConfirm={handleSnapshot}
                    onCancel={() => setSnapshotModalIsVisible(false)}
                />
            )}

            {unsavedModalIsVisible && (
                <ConfirmModal
                    confirm="Yes, Discard The Changes"
                    cancel="No, Stay On This Page"
                    onCancel={() => {
                        setNextLocation(null);
                        setUnsavedModalIsVisible(false);
                    }}
                    onConfirm={() => {
                        onDiscardChanges();
                        setUnsavedModalIsVisible(false);
                    }}
                >
                    <p>
                        There are unsaved changes that will be lost if you
                        navigate away from this page.
                    </p>
                    <p>Are you sure you want to continue?</p>
                </ConfirmModal>
            )}

            {pdfDrawerIsVisible && (
                <PdfDownloadDrawer
                    report={report}
                    template={template}
                    isVideo
                    onClose={() => setPdfDrawerIsVisible(false)}
                />
            )}

            {inspectDrawerVisible && (
                <VideoInspectDrawer
                    template={template}
                    report={report}
                    context={context}
                    inspectedItem={inspectDrawerItem}
                    onChangeContextRules={onChangeContextRules}
                    onClose={() => setInspectDrawerVisible(false)}
                    matchedVideos={matchedVideos}
                    isLoading={isMatchedVideosLoading}
                    hasError={hasMatchedVideosError}
                    onReload={onLoadMatchedVideos}
                />
            )}

            <Prompt when={hasUnsavedChanges} message={handleLocationChange} />
        </>
    );
};

export default ReportVideoDetail;
