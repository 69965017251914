import React from 'react';
import Box from 'js/components/box/box';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Text from 'js/components/text/text';

export default function DimensionWarningModal({
    toggleDimensionWarningModal,
    dimensionWarningSuccess,
    dimensionChanges,
}) {
    return (
        <ConfirmModal
            confirm="Yes, Continue"
            cancel="No, Cancel"
            onCancel={toggleDimensionWarningModal}
            onConfirm={dimensionWarningSuccess}
        >
            <p>
                The changes that you have made will deactivate the following
                settings:
            </p>

            <Box padding={['smaller', 0, 0]}>
                <Text
                    size="large"
                    weight="bolder"
                    spacing="smaller"
                    color={['gray', 'darkest']}
                >
                    {dimensionChanges.map((item) => (
                        <h2 key={item}>{item}</h2>
                    ))}
                </Text>
            </Box>

            <Box padding={['base', 0, 0]}>
                <p>Are you sure you want to continue?</p>
            </Box>
        </ConfirmModal>
    );
}
