import React, { useEffect, useRef, useState } from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ruleTypes from 'js/enums/rule-types.enum';
import { useClickOutside } from 'js/hooks';
import { capitalize, cleanString } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import ClickArea from 'js/components/click-area/click-area';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Scrollable from 'js/components/scrollable/scrollable';
import Text from 'js/components/text/text';
import TopicGroup from './topic-group';
import styles from './topic-selector.module.scss';

const HintMessage = ({ itemDisplayName, canAddKeyword }) => (
    <Alert size="small">
        <Box padding={['small', 0]}>
            <Row alignItems="center">
                <Col span="auto">
                    <Text color={['purple', 'base']}>
                        <FontAwesomeIcon icon={faInfo} size="lg" />
                    </Text>
                </Col>

                <Col>
                    <p>
                        Start typing to search {itemDisplayName}
                        {canAddKeyword ? ' or add a keyword' : ''}.
                    </p>
                </Col>
            </Row>
        </Box>
    </Alert>
);

const KeywordMessage = ({ keyword, isFocused, onAddKeyword }) => (
    <ClickArea onClick={() => onAddKeyword()}>
        <div className={styles['keyword-message']}>
            <Row
                justifyContent="space-between"
                alignItems="center"
                gutter="small"
            >
                <Col span="auto">
                    <Text weight="bold">Target ‘{keyword}’ as a keyword</Text>
                </Col>

                <Col span="auto">
                    <Text size="small" color={['purple', 'dark']}>
                        {isFocused ? 'Hit the ‘Enter’ key or click' : 'Click'}{' '}
                        here to target as keyword
                    </Text>
                </Col>
            </Row>
        </div>
    </ClickArea>
);

const TopicGroups = ({
    itemDisplayName,
    itemType,
    groups,
    searchTerm,
    rules,
    ruleKey,
    canAddKeyword,
    onTarget,
    onBlock,
    onTargetAll,
    onBlockAll,
}) => {
    const targetRules = rules.filter(
        (rule) => rule.aggregation === ruleTypes.INCLUDED,
    );
    const blockRules = rules.filter(
        (rule) => rule.aggregation === ruleTypes.EXCLUDED,
    );

    const targetedItems = targetRules.map((rule) => rule[itemType]).flat();
    const blockedItems = blockRules.map((rule) => rule[itemType]).flat();

    if (!groups.length) {
        return (
            <div className={styles['topic-group-list-wrapper']}>
                <Box padding="base">
                    <Alert theme="empty">
                        <p>
                            No {capitalize(itemDisplayName || itemType)} found{' '}
                            {searchTerm ? `matching '${searchTerm}'` : ''}
                        </p>
                    </Alert>
                </Box>
            </div>
        );
    }

    return (
        <div className={styles['topic-group-list-wrapper']}>
            <div className={styles['topic-group-list']}>
                <Scrollable hideScrollbar>
                    {!searchTerm.length && (
                        <Box
                            padding="base"
                            borderBottom={['base', 'solid', 'gray', 'lightest']}
                        >
                            <HintMessage
                                itemDisplayName={itemDisplayName || itemType}
                                canAddKeyword={canAddKeyword}
                            />
                        </Box>
                    )}

                    <Box padding={[0, 0, 'base']}>
                        {groups.map((group, index) => (
                            <Box
                                padding={[0, 'base']}
                                borderBottom={
                                    index < groups.length - 1
                                        ? ['base', 'solid', 'gray', 'lightest']
                                        : null
                                }
                                key={group.id}
                            >
                                <TopicGroup
                                    itemType={itemType}
                                    group={group}
                                    searchTerm={searchTerm}
                                    rules={rules}
                                    ruleKey={ruleKey}
                                    targetedItems={targetedItems}
                                    blockedItems={blockedItems}
                                    onTarget={onTarget}
                                    onBlock={onBlock}
                                    onTargetAll={onTargetAll}
                                    onBlockAll={onBlockAll}
                                />
                            </Box>
                        ))}
                    </Box>
                </Scrollable>
            </div>
        </div>
    );
};

const TopicSelector = ({
    itemDisplayName,
    itemType,
    groups,
    rules,
    ruleKey,
    language,
    placeholder,
    onAddKeyword,
    onTarget,
    onBlock,
    onTargetAll,
    onBlockAll,
}) => {
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [newInput, setNewInput] = useState('');
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [isForcedFocus, setForcedFocus] = useState(false);

    const containerRef = useRef();
    const formRef = useRef();

    const canAddKeyword = !!onAddKeyword;

    const addKeyword = () => {
        onAddKeyword(cleanString(newInput));
        setNewInput('');
        setActive(false);

        setForcedFocus(false);
        setTimeout(() => setForcedFocus(true), 0);
    };

    useEffect(() => {
        const input = cleanString(newInput);

        let filterFunction;

        if (input.length) {
            setActive(true);

            filterFunction = (item, group) =>
                (group.name.toLowerCase().includes(input) ||
                    item.name.toLowerCase().includes(input) ||
                    item.keywords?.some((k) =>
                        k.name.toLowerCase().includes(input),
                    )) &&
                (!item.language || item.language === language);
        } else {
            filterFunction = (item) =>
                !item.language || item.language === language;
        }

        const filtered = groups
            .map((group) => ({
                ...group,
                [itemType]: group[itemType].filter((item) =>
                    filterFunction(item, group),
                ),
            }))
            .filter((group) => group[itemType].length);

        setFilteredGroups(filtered);
    }, [newInput, language, groups, itemType]);

    useClickOutside([formRef, containerRef], () => {
        setNewInput('');
        setActive(false);
    });

    return (
        <DropdownMenu
            ref={containerRef}
            width="same"
            showMenu={isActive}
            offset={[0, 4]}
            flipVariations={false}
            content={
                <div className={styles['dropdown-content']}>
                    {canAddKeyword && cleanString(newInput).length > 0 && (
                        <Box
                            padding="base"
                            borderBottom={['base', 'solid', 'gray', 'lightest']}
                        >
                            <KeywordMessage
                                keyword={newInput}
                                isFocused={isFocused}
                                onAddKeyword={addKeyword}
                            />
                        </Box>
                    )}

                    <TopicGroups
                        itemDisplayName={itemDisplayName || itemType}
                        itemType={itemType}
                        groups={filteredGroups}
                        searchTerm={cleanString(newInput)}
                        rules={rules}
                        ruleKey={ruleKey}
                        canAddKeyword={canAddKeyword}
                        onTarget={onTarget}
                        onBlock={onBlock}
                        onTargetAll={onTargetAll}
                        onBlockAll={onBlockAll}
                    />

                    <footer className={styles.footer}>
                        <Row justifyContent="flex-end">
                            <Col span="auto">
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setNewInput('');
                                        setActive(false);
                                    }}
                                >
                                    Done
                                </Button>
                            </Col>
                        </Row>
                    </footer>
                </div>
            }
        >
            <Form
                ref={formRef}
                onSubmit={() => {
                    if (canAddKeyword) addKeyword();
                }}
            >
                <Input
                    value={newInput}
                    focusOnShow={isActive || isForcedFocus}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setNewInput(e.target.value);
                    }}
                    onClick={() => setActive(true)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
            </Form>
        </DropdownMenu>
    );
};

TopicSelector.defaultProps = {
    ruleKey: undefined,
    placeholder: 'Search topics or add keywords',
    onAddKeyword: undefined,
    onTarget: undefined,
    onBlock: undefined,
    onTargetAll: undefined,
    onBlockAll: undefined,
};

TopicSelector.propTypes = {
    itemDisplayName: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    rules: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number.isRequired,
            aggregation: PropTypes.string.isRequired,
            keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
            topics: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
    ).isRequired,
    ruleKey: PropTypes.number,
    placeholder: PropTypes.string,
    onAddKeyword: PropTypes.func,
    onTarget: PropTypes.func,
    onBlock: PropTypes.func,
    onTargetAll: PropTypes.func,
    onBlockAll: PropTypes.func,
};

export default TopicSelector;
