import React from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import FavoritesButton from 'js/components/button/favorites-button';
import SwitchButton from 'js/components/button/switch-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import styles from './card.module.scss';

const CardAccount = ({ account, isSelected, onToggleFavorite, onSelect }) => {
    const { name, logo, isFavoriteLoading, is_favourite: isFavorite } = account;

    return (
        <Row justifyContent="center" alignItems="center" gutter="small">
            <Col span="auto">
                {logo ? (
                    <div className={styles['account-logo']}>
                        <img src={logo} alt={name} />
                    </div>
                ) : (
                    <div className={styles['account-no-logo']} />
                )}
            </Col>

            <Col>
                <Box
                    borderRadius="round"
                    padding={['small', 'base']}
                    background={
                        isSelected ? ['gray', 'white'] : ['gray', 'lightest']
                    }
                    border={
                        isSelected
                            ? ['base', 'solid', 'gray', 'light']
                            : ['base', 'solid', 'gray', 'lightest']
                    }
                >
                    <Text inline weight="bold">
                        {name}
                    </Text>
                </Box>
            </Col>

            <Col span="auto">
                <SwitchButton
                    theme="outline"
                    active={isSelected}
                    onChange={onSelect}
                >
                    Select
                </SwitchButton>
            </Col>

            <Col span="auto">
                <FavoritesButton
                    isFavorite={isFavorite}
                    isLoading={isFavoriteLoading}
                    onToggleFavorite={onToggleFavorite}
                />
            </Col>
        </Row>
    );
};

CardAccount.defaultProps = {
    isSelected: false,
};

CardAccount.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.node.isRequired,
        logo: PropTypes.string,
        is_favourite: PropTypes.bool,
        isFavoriteLoading: PropTypes.bool,
    }).isRequired,
    isSelected: PropTypes.bool,
    onToggleFavorite: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default CardAccount;
