import PropTypes from 'prop-types';

export function getNumber({
    currencyCode,
    isPercentage,
    locale,
    maximumFractionDigits,
    minimumFractionDigits,
    showThousandSeparators = true,
    value,
    abbreviationFormats = [],
    signDisplay = 'auto',
}) {
    const { isNaN } = window.Number;
    const number = parseFloat(value);

    if (isNaN(number)) return '—';

    const getOptions = () => {
        const internalMaximumFractionDigits =
            minimumFractionDigits > maximumFractionDigits ||
            maximumFractionDigits === undefined
                ? minimumFractionDigits
                : maximumFractionDigits;
        const options = {
            maximumFractionDigits: internalMaximumFractionDigits ?? 4,
            minimumFractionDigits,
            signDisplay,
        };

        if (currencyCode !== undefined) {
            options.currency = currencyCode;
            options.maximumFractionDigits = maximumFractionDigits ?? 2;
            options.minimumFractionDigits = minimumFractionDigits ?? 2;
            options.style = 'currency';
        } else if (isPercentage === true) {
            options.style = 'percent';
        }

        if (!showThousandSeparators) {
            options.useGrouping = false;
        }

        return options;
    };

    const format = abbreviationFormats.find((f) => number >= f.target);
    if (format) {
        const {
            target,
            appliedValue = target,
            symbol,
            maxFractionDigits,
        } = format;
        const formatted = (number / appliedValue).toLocaleString(locale, {
            maximumFractionDigits: maxFractionDigits,
        });
        const parts = formatted.match(/([\D]*)([\d.,]+)([\D]*)/);

        return `${parts[1]}${parts[2]}${symbol}${parts[3]}`;
    }

    return number.toLocaleString(locale, getOptions());
}

function Number(props) {
    return getNumber(props);
}

Number.defaultProps = {
    showThousandSeparators: true,
    value: undefined,
    abbreviationFormats: [],
    signDisplay: 'auto',
};

Number.propTypes = {
    currencyCode: PropTypes.string,
    isPercentage: PropTypes.bool,
    locale: PropTypes.string,
    maximumFractionDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
    showThousandSeparators: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    abbreviationFormats: PropTypes.array,
    signDisplay: PropTypes.string,
};

export default Number;
