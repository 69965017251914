import React from 'react';
import PropTypes from 'prop-types';

const ChartLineIcon = ({ backgroundColor, width, height }) => (
    <div
        style={{
            margin: '0 5px 0 0',
            backgroundColor,
            width,
            height,
            borderRadius: '4px',
        }}
    >
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 150 15">
            <polyline
                fill="none"
                stroke="white"
                strokeWidth="10"
                strokeMiterlimit="10"
                points="0,37 25,15 60,27 90,-8, 115,15, 150,5"
            />
        </svg>
    </div>
);

ChartLineIcon.defaultProps = {
    width: '21px',
    height: '21px',
};

ChartLineIcon.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default ChartLineIcon;
