import React from 'react';
import ActionBar from 'js/components/action-bar/action-bar';
import Button from 'js/components/button/button';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import UserListBody from './components/user-list-body';

const UserList = ({ loading, users, hasErrors, isReadOnly }) => (
    <Layout
        sidebar={<Navigation />}
        header={
            <TopBar
                breadcrumbs={[
                    <Crumb key="home" to="/" label="4D" />,
                    <Crumb
                        key="settings"
                        to="/settings/"
                        label="Settings"
                        isCollapsible
                    />,
                    <Crumb key="users" label="Users" />,
                ]}
            />
        }
    >
        {!isReadOnly && (
            <ActionBar>
                <Button to="/settings/users/new/" theme="outline">
                    Create User
                </Button>
            </ActionBar>
        )}

        <UserListBody
            loading={loading}
            users={users}
            hasErrors={hasErrors}
            isReadOnly={isReadOnly}
        />
    </Layout>
);

export default UserList;
