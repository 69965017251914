import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCloudUploadAlt,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import Box from 'js/components/box/box';
import FileButton from 'js/components/button/file-button';
import Label from 'js/components/label/label';
import styles from './input.module.scss';
import Alert from '../alert/alert';
import Row from '../grid/row';
import Col from '../grid/column';
import Text from '../text/text';

let instanceId = 0;

const FileInput = ({
    errorMessage,
    hasError,
    required,
    src,
    hint,
    info,
    label,
    sanitizers,
    onLoadStart,
    onLoadEnd,
    onError,
}) => {
    const [thisInstanceId, setThisInstanceId] = useState('');
    useEffect(() => {
        instanceId += 1;
        setThisInstanceId(`file-input-${instanceId}`);
    }, []);

    const isImage = sanitizers.some((i) =>
        i.mimeTypes?.every((m) => m.startsWith('image/')),
    );

    return (
        <Label
            forId={thisInstanceId}
            hasError={hasError}
            errorMessage={errorMessage}
            hint={hint}
            info={info}
            label={label}
            required={required}
        >
            {src ? (
                <Box margin={['small', 0]}>
                    {isImage ? (
                        <div className={styles['image-container']}>
                            <img src={src} alt={label} />
                        </div>
                    ) : (
                        <Box padding={['none', 'none', 'small']}>
                            <Alert>
                                <Row
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="small"
                                >
                                    <Col span="auto">
                                        <Text color={['purple', 'base']}>
                                            <FontAwesomeIcon
                                                style={{ fontSize: '33px' }}
                                                icon={faCheckCircle}
                                            />
                                        </Text>
                                    </Col>
                                    <Col>
                                        <p>
                                            Your file has been prepared for
                                            upload.
                                        </p>
                                    </Col>
                                </Row>
                            </Alert>
                        </Box>
                    )}
                </Box>
            ) : null}
            <FileButton
                id={thisInstanceId}
                droparea
                sanitizers={sanitizers}
                prefix={<FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />}
                onLoadStart={onLoadStart}
                onLoadEnd={onLoadEnd}
                onError={onError}
            >
                <em>
                    Drag and drop (or click) here to{' '}
                    {src ? 'replace the' : 'upload a'} file.
                </em>
            </FileButton>
        </Label>
    );
};

FileInput.defaultProps = {
    errorMessage: undefined,
    hasError: false,
    required: false,
    src: undefined,
    hint: undefined,
    info: undefined,
    label: undefined,
    sanitizers: [],
    onLoadStart: undefined,
    onLoadEnd: undefined,
    onError: undefined,
};

FileInput.propTypes = {
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
    required: PropTypes.bool,
    src: PropTypes.string,
    hint: PropTypes.node,
    info: PropTypes.node,
    label: PropTypes.node,
    sanitizers: PropTypes.arrayOf(PropTypes.func),
    onLoadStart: PropTypes.func,
    onLoadEnd: PropTypes.func,
    onError: PropTypes.func,
};

export default FileInput;
