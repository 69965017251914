import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import styles from './section.styles';

function Section({ children, theme, hasBorderTop, hasBorderBottom }) {
    const classes = [];

    if (theme) {
        classes.push(styles[`container--${theme}`]);
    }

    if (hasBorderTop) {
        classes.push(styles['container--has-border-top']);
    }

    if (hasBorderBottom) {
        classes.push(styles['container--has-border-bottom']);
    }

    return (
        <View style={classes} wrap={false}>
            {children}
        </View>
    );
}

Section.defaultProps = {
    theme: 'primary',
    hasBorderTop: false,
    hasBorderBottom: false,
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.oneOf(['primary']),
    hasBorderTop: PropTypes.bool,
    hasBorderBottom: PropTypes.bool,
};

export default Section;
