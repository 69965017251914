import React from 'react';
import { sortOptions } from 'js/constants';
import { createPresetUrl } from 'js/utils';
import filterTypes from 'js/enums/filter-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import FilterDropdown from 'js/components/filter-dropdown/filter-dropdown';
import SectionTitle from 'js/components/section-title/section-title';
import { showToast } from 'js/components/toast/toast';
import CampaignContexts from './campaign-contexts';
import FavoriteContexts from './favorite-contexts';

function ContextListBody({
    loading,
    errors,
    readOnly,
    advertiser,
    campaign,
    contexts,
    ordering,
    togglingFavoriteError,
    onToggleFavoriteContext,
    onUpdateFilter,
    onDeleteContext,
}) {
    const checkIfErrorTypeExists = (errorType) =>
        Object.keys(errors).some((key) => key === errorType);
    const hasLoadingError = checkIfErrorTypeExists('loadingFailed');
    const hasFavoriteContexts = contexts.some((item) => item.is_favourite);

    const newContext = { group: campaign.id, advertiser: advertiser.id };
    const newContextUrl = createPresetUrl('/contexts/new/', newContext);

    const titleButtons = [
        <Col span="auto">
            <Button theme="outline" to={newContextUrl}>
                Create Context
            </Button>
        </Col>,
        <Col span="auto">
            <FilterDropdown
                name="Sort Order"
                prefix="Sort by"
                param={filterTypes.ORDERING}
                options={sortOptions}
                selectedValue={ordering}
                onFilterChange={onUpdateFilter}
            />
        </Col>,
    ];

    if (Object.keys(togglingFavoriteError).length) {
        showToast(
            'There Was An Unexpected Error',
            <p>
                It was not possible to{' '}
                {togglingFavoriteError.isFavorite ? 'favorite' : 'unfavorite'}{' '}
                {togglingFavoriteError.title}. Please try again in a few
                moments.
            </p>,
            5,
            'danger',
        );
    }

    return (
        <Box padding={['large', 0]}>
            <Container>
                <SectionTitle hasBar extension={titleButtons}>
                    Contexts
                </SectionTitle>
            </Container>

            {hasFavoriteContexts && (
                <FavoriteContexts
                    contexts={contexts.filter((item) => item.is_favourite)}
                    onToggleFavorite={onToggleFavoriteContext}
                    onDelete={onDeleteContext}
                />
            )}

            <CampaignContexts
                loading={loading}
                hasLoadingError={hasLoadingError}
                readOnly={readOnly}
                advertiser={advertiser}
                campaign={campaign}
                contexts={contexts}
                onToggleFavorite={onToggleFavoriteContext}
                onDelete={onDeleteContext}
            />
        </Box>
    );
}

export default ContextListBody;
