import { eventTagSources, daysOfTheWeek } from 'js/constants';
import entityTypes from 'js/enums/entity-types.enum';
import sectionTypes from 'js/enums/section-types.enum';
import scheduleTypes from 'js/enums/schedule-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import history from 'js/history';
import { deepCopy, ordinal } from './utils';

/**
 * returns a breakpoint prefix
 * @param {string} breakpointKey
 * @returns {string}
 */
export const getBreakpointCssPrefix = (breakpointKey) =>
    breakpointKey ? `${breakpointKey.toLowerCase()}-` : '';

/**
 * returns a display label for React component
 * @param Component
 * @returns {string}
 */
export const getDisplayName = (Component) =>
    Component.displayName || Component.name || 'Component';

/**
 * sets document title for current page
 * @param {string[]} chunks
 */
export const setDocumentTitle = (chunks) => {
    let title;

    if (chunks.length) {
        title = chunks.join(' - ');
    } else {
        title = '4D';
    }

    document.title = title;
};

/**
 * Appends a preset param to a URL, to pass encoded data (directly or,
 * if too long, as a reference to a localStorage variable)
 * @param {string} url
 * @param {object} data
 * @returns {string}
 */
export const createPresetUrl = (url, data) => {
    if (!Object.keys(data).length) return url;

    const prefix = url.includes('?') ? '&' : '?';
    const encodedData = btoa(escape(JSON.stringify(data)));

    // use localStorage if too large to pass in the URL
    // (the actual limit, imposed AWS CloudFront, is 8192 chars for the whole URL)
    const needsStoring = encodedData.length > 8000;

    const params = new URLSearchParams();
    if (needsStoring) {
        const presetId = `${localStorage.length}${new Date().getTime()}`;
        params.set('preset', presetId);
        try {
            localStorage.setItem(`preset-${presetId}`, encodedData);
        } catch {
            // pass
        }
    } else {
        params.set('preset', encodedData);
    }

    return `${url}${prefix}${params}`;
};

/**
 * Gets preset data from the current page URL
 * @returns {object}
 */
export const getPreset = () => {
    const params = new URLSearchParams(window.location.search);
    const preset = params.get('preset');

    let data = {};
    if (preset) {
        const isStored = /^\d+$/.test(preset);
        try {
            const encodedData = isStored
                ? localStorage.getItem(`preset-${preset}`)
                : preset;
            data = JSON.parse(unescape(atob(encodedData)));
        } catch {
            // pass
        }
    }
    return data;
};

/**
 * Removes all localStorage presets */
export const clearPresets = () => {
    for (let i = localStorage.length - 1; i >= 0; i -= 1) {
        const item = localStorage.key(i);
        if (item.startsWith('preset-')) {
            localStorage.removeItem(item);
        }
    }
};

/**
 * Opens the Context Builder page, prefilled with data copied from a context
 * @param {object} context
 * @param {{emptyCampaign: boolean, namePrefix: string, newTab: boolean}} options
 */
export const copyContext = (
    context,
    { emptyCampaign, namePrefix = 'Copy of', newTab } = {},
) => {
    const { name, group: campaignId, group_name: campaignName } = context;
    const copiedContext = {
        ...deepCopy(context),
        id: undefined,
        name: `${namePrefix} ${name}`.trim(),
        group: emptyCampaign ? undefined : campaignId,
        group_name: emptyCampaign ? undefined : campaignName,
        is_favourite: false,
        created: undefined,
        updated: undefined,
    };
    const url = createPresetUrl('/contexts/new/', copiedContext);

    if (newTab) {
        window.open(url, '_blank', 'noopener,noreferrer')?.focus();
    } else {
        history.push(url);
    }
};

/* Defined logic related to the API responses.
 * These definitions are UI only.
 */

/**
 * checks if targeting entities have been applied in the given dimension
 * @param {object} origin
 * @param {object} context
 * @returns {boolean}
 */
export const isActiveOrigin = (origin, context) => {
    const { rules } = context;

    if (origin.id === sectionTypes.KEYWORDS_DIMENSION) {
        const hasKeywords = rules.some(
            (rule) => rule.keywords.length || rule.topics.length,
        );
        return hasKeywords;
    }
    if (origin.id === sectionTypes.VIDEO_DIMENSION) {
        const hasTopics = rules.some(
            (rule) => rule.topics.length || rule.logos.length,
        );
        return hasTopics;
    }
    return false;
};

/**
 * returns reach suggestions
 * @param context
 * @returns {object[]}
 */
export function getScaleSuggestions({ context: { rules } }) {
    const suggestions = [];
    const isKeywordsAdded = rules.filter((item) => item.keywords.length).length;

    if (isKeywordsAdded) {
        suggestions.push({
            title: 'Content Keywords',
            message: `Reduce the number of ‘and’ keyword groups, add more ‘or’ 
                keywords to each group or reduce the number of blocked keywords.`,
        });
    }
    return suggestions;
}

/**
 * returns a filtering function for an event-hierarchy list,
 * to select events suitable for a given report type and subtype
 * @param {string} type entity type of the report
 * @param {string} subtype report subtype
 * @returns {function} a function to be applied (via .find() or .filter()) to an event-hierarchy list
 */
export const getEventFilterForReportType =
    (type, subtype) =>
    ({ is_video: isVideo, source }) => {
        if (isVideo !== (type === entityTypes.VIDEO_REPORTS)) {
            return false;
        }
        switch (source) {
            case 'youtube':
                return subtype === videoReportSubtypes.YOUTUBE_DV360;
            case 'youtube_gads':
                return subtype === videoReportSubtypes.YOUTUBE_GADS;
            default:
                return (
                    subtype !== videoReportSubtypes.YOUTUBE_DV360 &&
                    subtype !== videoReportSubtypes.YOUTUBE_GADS &&
                    subtype !== videoReportSubtypes.YOUTUBE_GADS_FILE
                );
        }
    };

export function getEventTagSourceOrDefault(id) {
    const eventTagSource = eventTagSources.find((i) => i.id === id);
    if (eventTagSource) {
        return eventTagSource.label;
    }
    return id;
}

/**
 * describes the scheduling of an action
 * @param {string} schedule Scheduling value
 * @param {string} [action='This report is scheduled to generate a '] Scheduled action (prefix for the description)
 * @param {string} [thing=Snapshot] Name of the scheduled object
 * @returns {string} The composed description, or null if @schedule is null
 */
export const getScheduleDescription = (
    schedule,
    action = 'This report is scheduled to generate a ',
    thing = 'Snapshot',
) => {
    const days = {
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
        sun: 'Sunday',
    };

    let description;
    if (days[schedule]) {
        description = `${action} weekly ${thing} every ${days[schedule]}`;
    } else if (Number.parseInt(schedule, 10)) {
        description = `${action} monthly ${thing} on the ${ordinal(schedule)}`;
    } else {
        description = ''; // not scheduled
    }

    return description;
};

/**
 * describes the scheduling type from it's value
 * @param {string} schedule Scheduling value
 * @returns {string} The schedule type, or null if @schedule is null
 */
export const getScheduleType = (schedule) => {
    if (daysOfTheWeek.includes(schedule)) return scheduleTypes.WEEKLY;
    if (Number.parseInt(schedule, 10)) return scheduleTypes.MONTHLY;
    return null;
};
