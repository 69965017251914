import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Text from 'js/components/text/text';
import styles from './spinner.module.scss';

const cx = classNames.bind(styles);

function Spinner({ message, size, color, placement, isCentered }) {
    const classes = cx({
        container: true,
        [size]: size,
        [placement]: placement,
    });

    const spinner = (
        <Text inline color={color}>
            <div className={classes}>
                <div className={styles['icon-wrapper']}>
                    <span className={styles.icon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28 28"
                        >
                            <path d="M14 0V5C9 5 5 9 5 14C5 19 9 23 14 23C19 23 23 19 23 14H28C28 22 21.5 28 14 28C6 28 0 21.5 0 14C0 6.5 6 0 14 0Z" />
                        </svg>
                    </span>
                </div>

                {message && <span className={styles.message}>{message}</span>}
            </div>
        </Text>
    );

    return isCentered ? (
        <div className={styles.centered}>{spinner}</div>
    ) : (
        spinner
    );
}

const colors = PropTypes.oneOf([
    'purple',
    'red',
    'yellow',
    'aqua',
    'gray',
    'blue',
    'black',
    'darkest',
    'darker',
    'dark',
    'base',
    'light',
    'lighter',
    'lightest',
    'background',
    'white',
]);

Spinner.defaultProps = {
    message: undefined,
    size: undefined,
    color: undefined,
    placement: 'bottom',
    isCentered: false,
};

Spinner.propTypes = {
    message: PropTypes.node,
    size: PropTypes.oneOf(['small', 'base', 'large']),
    color: PropTypes.arrayOf(colors),
    placement: PropTypes.oneOf(['bottom', 'left', 'top', 'right']),
    isCentered: PropTypes.bool,
};

export default Spinner;
