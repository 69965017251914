import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AbortError } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Checkbox from 'js/components/checkbox/checkbox';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownScrollableList from 'js/components/dropdown-menu/scrollable-list';
import DropdownSearch from 'js/components/dropdown-menu/search';
import DropdownSort from 'js/components/dropdown-menu/sort';
import Label from 'js/components/label/label';

let instanceId = 0;

const Options = ({
    radio,
    options,
    selectedValues,
    scrollable,
    emptyMessage,
    onChange,
}) => {
    if (!options.length) {
        return (
            <Box margin={['small', 0]}>
                <Alert theme="empty" size="small">
                    <Box padding={['small', 0]}>{emptyMessage}</Box>
                </Alert>
            </Box>
        );
    }

    const checkbox = (
        <Checkbox
            radio={radio}
            options={options}
            selectedValues={selectedValues}
            onChange={onChange}
        />
    );

    if (!scrollable) {
        return checkbox;
    }

    return <DropdownScrollableList>{checkbox}</DropdownScrollableList>;
};

const FilterButton = ({
    prefix,
    isActive,
    isDefault,
    hasError,
    children,
    onClick,
}) => (
    <Button
        theme={!isActive && !isDefault ? 'dark' : 'outline'}
        prefix={prefix}
        suffix={<FontAwesomeIcon icon={faChevronDown} />}
        hasError={hasError}
        onClick={onClick}
        active={isActive}
    >
        {children}
    </Button>
);

const SelectButton = ({ prefix, hasError, children, onClick }) => (
    <Button
        theme="select"
        prefix={prefix}
        hasError={hasError}
        suffix={<FontAwesomeIcon icon={faChevronDown} />}
        onClick={onClick}
    >
        {children || <>&nbsp;</>}
    </Button>
);

const Select = forwardRef(
    (
        {
            theme,
            header,
            footer,
            options,
            selectedValues,
            multi,
            width,
            label,
            info,
            hint,
            prefix,
            required,
            placement,
            children,
            searchPlaceholder,
            hasError,
            errorMessage,
            emptyMessage,
            sortingOptions,
            onChange,
            onSort,
            onSearch,
        },
        ref,
    ) => {
        const [thisInstanceId, setThisInstanceId] = useState();
        const [showMenu, setShowMenu] = useState(false);
        const [searching, setSearching] = useState(false);
        const [searchError, setSearchError] = useState('');

        const selectValues = (values) => {
            if (multi) {
                onChange(values);
            } else {
                onChange(values);
                setShowMenu(false);
            }
        };

        const preSearch = () => {
            setSearchError('');
            setSearching(true);
        };

        const search = async (term) => {
            try {
                setSearchError('');
                await onSearch(term);
                setSearching(false);
            } catch (err) {
                if (err instanceof AbortError) return;

                setSearchError('Failed to load search results');
                setSearching(false);
            }
        };

        useEffect(() => {
            instanceId += 1;
            setThisInstanceId(`select-${instanceId}`);
        }, []);

        return (
            <Label
                forId={thisInstanceId}
                hint={hint}
                info={info}
                label={label}
                required={required}
                errorMessage={errorMessage}
            >
                <DropdownMenu
                    ref={ref}
                    id={thisInstanceId}
                    content={
                        <>
                            {onSearch && (
                                <DropdownSearch
                                    placeholder={searchPlaceholder}
                                    searching={searching}
                                    hasError={!!searchError}
                                    onPreSearch={preSearch}
                                    onSearch={search}
                                />
                            )}

                            {!searching && !searchError && (
                                <>
                                    {header && (
                                        <Box margin={[0, 'small', 'small']}>
                                            {header}
                                        </Box>
                                    )}

                                    {onSort && (
                                        <DropdownSort
                                            sortingOptions={sortingOptions}
                                            onSort={onSort}
                                        />
                                    )}

                                    {options.length || onSearch ? (
                                        <Box margin={['-small', 'small']}>
                                            <Options
                                                radio={!multi}
                                                options={options}
                                                selectedValues={selectedValues}
                                                scrollable={onSearch || onSort}
                                                emptyMessage={emptyMessage}
                                                onChange={selectValues}
                                            />
                                        </Box>
                                    ) : null}

                                    {footer && (
                                        <Box margin={['small', 'small', 0]}>
                                            {footer}
                                        </Box>
                                    )}
                                </>
                            )}
                        </>
                    }
                    width={width}
                    offset={[0, 4]}
                    showMenu={showMenu}
                    onHide={() => setShowMenu(false)}
                    placement={placement}
                >
                    {theme === 'filter' ? (
                        <FilterButton
                            isActive={showMenu}
                            isDefault={
                                !selectedValues.length ||
                                options[0]?.value === selectedValues
                            }
                            prefix={prefix}
                            hasError={hasError}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            {children ||
                                options.find(
                                    (option) => option.value === selectedValues,
                                )?.label}
                        </FilterButton>
                    ) : (
                        <SelectButton
                            prefix={prefix}
                            hasError={hasError}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            {children ||
                                options.find(
                                    (option) => option.value === selectedValues,
                                )?.label}
                        </SelectButton>
                    )}
                </DropdownMenu>
            </Label>
        );
    },
);

Select.defaultProps = {
    theme: 'select',
    header: undefined,
    footer: undefined,
    selectedValues: [],
    multi: false,
    width: 'fluid',
    label: undefined,
    info: undefined,
    hint: undefined,
    prefix: undefined,
    required: false,
    placement: 'bottom-start',
    children: undefined,
    searchPlaceholder: '',
    hasError: false,
    errorMessage: undefined,
    emptyMessage: 'There are no results that match your search.',
    sortingOptions: undefined,
    onSort: undefined,
    onSearch: undefined,
};

Select.propTypes = {
    theme: PropTypes.oneOf(['select', 'filter']),
    header: PropTypes.node,
    footer: PropTypes.node,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ).isRequired,
    selectedValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        ),
    ]),
    multi: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['fluid', 'fixed', 'same']),
    ]),
    label: PropTypes.node,
    info: PropTypes.node,
    hint: PropTypes.node,
    prefix: PropTypes.node,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    placement: PropTypes.oneOf([
        'auto',
        'auto-start',
        'auto-end',
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
    ]),
    children: PropTypes.node,
    searchPlaceholder: PropTypes.string,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.node,
    emptyMessage: PropTypes.node,
    sortingOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    onSearch: PropTypes.func,
};

Select.displayName = 'Select';

export default Select;
