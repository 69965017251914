const sectionTypes = {
    DETAILS: 'details',
    CHANNELS: 'channels',
    DEAL_IDS: 'deal-ids',
    DIMENSIONS: 'dimensions',
    KEYWORDS_DIMENSION: 'topics-keywords',
    VIDEO_DIMENSION: 'video',
    OPTIMIZE: 'optimize',
    MATCHED_PAGES: 'matched-pages',
    MATCHED_VIDEOS: 'matched-videos',
};

export default sectionTypes;
