import React, { useContext } from 'react';
import { origins } from 'js/constants';
import serviceTypes from 'js/enums/service-types.enum';
import ruleTypes from 'js/enums/rule-types.enum';
import sectionTypes from 'js/enums/section-types.enum';
import { capitalize, getJwtPayload, RuleSet } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import KeywordInput from 'js/components/input/keyword-input';
import Label from 'js/components/label/label';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import TopicSelector from 'js/components/topic-selector/topic-selector';
import ruleItemTypes from 'js/enums/rule-item-types.enum';
import languageTypes from 'js/enums/language-types.enum';
import { AutoOptPanel } from '../keywords-panel/rules-section/rules-section';
import ContextDetailContext from '../../contexts/context-detail.context';
import DimensionPanelHeader from '../dimensions-panel/dimensions-panel-header';
import RuleItems from './rule-items/rule-items';

const VideoRuleItem = ({
    itemType,
    isReadOnly,
    groups,
    rules,
    language,
    required,
    onAddRuleItems,
    onRemoveRuleItems,
}) => {
    const nonEmptyGroups = groups.filter((group) => group[itemType].length);
    const items = groups.map(({ [itemType]: _items }) => _items).flat();

    const descriptions = {
        topics: 'Target or block video content about specific Topics.',
        brands: 'Target or block video content with specific Brands present in the content.',
    };

    const itemTypesMapper = {
        topics: 'topics',
        logos: 'brands',
    };

    const itemDisplayName = itemTypesMapper[itemType];

    const getOtherItemTypes = (type) =>
        Object.values(itemTypesMapper).filter((_type) => _type !== type);

    const targetRule = rules.filter(
        (item) => item.aggregation === ruleTypes.INCLUDED,
    )[0];
    const blockRule = rules.filter(
        (item) => item.aggregation === ruleTypes.EXCLUDED,
    )[0];

    const hasSelectedItems =
        targetRule[itemType].length > 0 || blockRule[itemType].length > 0;

    const toggleItem = (rule, itemId) => {
        const r = targetRule.key === rule.key ? targetRule : blockRule;

        if (r[itemType].includes(itemId)) {
            return onRemoveRuleItems(rule.key, itemType, [itemId]);
        }

        return onAddRuleItems(rule.key, itemType, [itemId]);
    };

    const toggleGroup = (rule, groupId) => {
        const r = targetRule.key === rule.key ? targetRule : blockRule;
        const itemIds = nonEmptyGroups
            .find((group) => group.id === groupId)
            [itemType].map((item) => item.id);

        if (itemIds.every((item) => r[itemType].includes(item))) {
            return onRemoveRuleItems(rule.key, itemType, itemIds);
        }

        return onAddRuleItems(rule.key, itemType, itemIds);
    };

    return (
        <Box padding={[0, 0, 'base']}>
            <Panel bordered>
                <Box padding="base">
                    <Row gutter="small">
                        <Col span="auto">
                            <Label
                                label={capitalize(itemDisplayName)}
                                required={required}
                                info={
                                    <>
                                        <p>{descriptions[itemDisplayName]}</p>
                                        <p>
                                            {capitalize(itemDisplayName)} have
                                            an ‘or’ relationship with{' '}
                                            {getOtherItemTypes(itemDisplayName)
                                                .map((type) => capitalize(type))
                                                .join(' and ')}
                                            .
                                        </p>
                                    </>
                                }
                            />
                        </Col>
                    </Row>

                    {!isReadOnly && (
                        <>
                            <Box margin={['small', 0]}>
                                <Text weight="bold" size="large">
                                    Target or block content featuring
                                </Text>
                            </Box>

                            <TopicSelector
                                itemDisplayName={itemDisplayName}
                                itemType={itemType}
                                groups={nonEmptyGroups}
                                rules={rules}
                                language={language}
                                placeholder={`Search ${capitalize(
                                    itemDisplayName,
                                )}`}
                                onTarget={(itemId) =>
                                    toggleItem(targetRule, itemId)
                                }
                                onBlock={(itemId) =>
                                    toggleItem(blockRule, itemId)
                                }
                                onTargetAll={(groupId) =>
                                    toggleGroup(targetRule, groupId)
                                }
                                onBlockAll={(groupId) =>
                                    toggleGroup(blockRule, groupId)
                                }
                            />
                        </>
                    )}

                    {isReadOnly && !hasSelectedItems ? (
                        <Box margin={['base', 0, 0]}>
                            <Alert theme="empty">
                                <p>
                                    You have not selected any {itemType} to
                                    target or block.
                                </p>
                            </Alert>
                        </Box>
                    ) : (
                        <Row>
                            <Col span={12}>
                                <RuleItems
                                    itemDisplayName={itemDisplayName}
                                    itemType={itemType}
                                    isReadOnly={isReadOnly}
                                    rule={targetRule}
                                    items={items}
                                    onRemoveRuleItems={onRemoveRuleItems}
                                />
                            </Col>

                            <Col span={12}>
                                <RuleItems
                                    itemDisplayName={itemDisplayName}
                                    itemType={itemType}
                                    isBlocked
                                    isReadOnly={isReadOnly}
                                    rule={blockRule}
                                    items={items}
                                    onRemoveRuleItems={onRemoveRuleItems}
                                />
                            </Col>
                        </Row>
                    )}
                </Box>
            </Panel>
        </Box>
    );
};

const VideoRuleBlockKeywords = ({ rule, isReadOnly, onChangeRuleItems }) => {
    const label = isReadOnly
        ? 'Blocked Keywords'
        : 'And block content featuring';
    const info = (
        <p>
            Block video content whose title or description includes specific
            Keywords.
        </p>
    );
    const emptyMessage = <p>You have not selected any keywords to block.</p>;

    const changeKeywords = (keywords) => {
        onChangeRuleItems(rule.key, ruleItemTypes.KEYWORD, keywords);
    };

    return (
        <Box padding={[0, 0, 'base']}>
            <Panel bordered>
                <Box padding="base">
                    <KeywordInput
                        keywords={rule.keywords}
                        label={label}
                        info={info}
                        emptyMessage={emptyMessage}
                        isReadOnly={isReadOnly}
                        theme="danger"
                        onChange={changeKeywords}
                    />
                </Box>
            </Panel>
        </Box>
    );
};

const VideoPanelBody = ({
    logosService,
    topicGroups,
    logoGroups,
    rules,
    language,
    hasRecommendations,
    isReadOnly,
    onAddRuleItems,
    onChangeRuleItems,
    onRemoveRuleItems,
    onViewOptimizeDetails,
}) => {
    const blockRule = rules.find(
        (rule) => rule.aggregation === ruleTypes.EXCLUDED,
    );

    return (
        <Box padding={[0, 'base']}>
            {hasRecommendations && (
                <Box margin={[0, 0, 'base']}>
                    <AutoOptPanel onViewDetails={onViewOptimizeDetails} />
                </Box>
            )}

            <VideoRuleItem
                itemType={ruleItemTypes.TOPIC}
                isReadOnly={isReadOnly}
                groups={topicGroups}
                rules={rules}
                language={languageTypes.EN}
                required
                onAddRuleItems={onAddRuleItems}
                onRemoveRuleItems={onRemoveRuleItems}
            />

            {logosService && (
                <VideoRuleItem
                    itemType={ruleItemTypes.LOGO}
                    isReadOnly={isReadOnly}
                    groups={logoGroups}
                    rules={rules}
                    language={language}
                    onAddRuleItems={onAddRuleItems}
                    onRemoveRuleItems={onRemoveRuleItems}
                />
            )}

            <VideoRuleBlockKeywords
                rule={blockRule}
                isReadOnly={isReadOnly}
                onChangeRuleItems={onChangeRuleItems}
            />
        </Box>
    );
};

export default function VideoPanel() {
    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const logosService = services.find(
        (service) => service.name === serviceTypes.LOGOS,
    );

    const {
        page: { isReadOnly },
        context: {
            current: { rules, language },
            setRules,
        },
        optimize: {
            current: { recommendations: optimizeRecommendations },
        },
        priceStructure: { youtube_targeting_cpm_usd: videoCpm },
        topics: { groups: topicGroups },
        logoGroups,
        pageSections: { scrollTo: smoothScrollToAnchor },
    } = useContext(ContextDetailContext);

    let serviceName = 'Topics';
    let serviceDescription = 'topics';

    if (logosService) {
        serviceName = 'Topics & Brands';
        serviceDescription = 'topics and brands';
    }
    const videoOrigin = {
        ...origins.find(({ id }) => id === sectionTypes.VIDEO_DIMENSION),
        name: serviceName,
        description: `Target or block content that matches specific ${serviceDescription} using the 4D Vision Engine.`,
        cpm_usd: videoCpm,
    };

    const addRuleItems = (ruleKey, itemType, items) => {
        const ruleSet = new RuleSet(rules);
        const action = `add${capitalize(itemType)}`;
        ruleSet.changeRule(ruleKey, { [action]: items });
        setRules(ruleSet.rules);
    };

    const removeRuleItems = (ruleKey, itemType, items) => {
        const ruleSet = new RuleSet(rules);
        const action = `remove${capitalize(itemType)}`;
        ruleSet.changeRule(ruleKey, { [action]: items });
        setRules(ruleSet.rules);
    };

    const changeRuleItems = (ruleKey, itemType, items) => {
        const rule = rules.find(({ key }) => key === ruleKey);
        const ruleSet = new RuleSet(rules);
        const removeAction = `remove${capitalize(itemType)}`;
        const addAction = `add${capitalize(itemType)}`;
        ruleSet.changeRule(ruleKey, {
            [removeAction]: rule.keywords,
            [addAction]: items,
        });
        setRules(ruleSet.rules);
    };

    const scrollToOptimizeSection = (e) => {
        setTimeout(() => {
            smoothScrollToAnchor(e, 'optimize');
        }, 0);
        e.preventDefault();
    };

    return (
        <>
            <DimensionPanelHeader origin={videoOrigin} />
            <VideoPanelBody
                logosService={logosService}
                topicGroups={topicGroups}
                logoGroups={logoGroups}
                rules={rules}
                language={language}
                hasRecommendations={optimizeRecommendations.length > 0}
                isReadOnly={isReadOnly}
                onAddRuleItems={addRuleItems}
                onChangeRuleItems={changeRuleItems}
                onRemoveRuleItems={removeRuleItems}
                onViewOptimizeDetails={scrollToOptimizeSection}
            />
        </>
    );
}
