import React from 'react';
import classNames from 'classnames';
import Box from 'js/components/box/box';
import SwitchButton from 'js/components/button/switch-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import { RuleSet, enumerate } from 'js/utils';
import ruleTypes from 'js/enums/rule-types.enum';
import recommendationActions from './enums/actions.enum';
import recommendationAttributes from './enums/attributes.enum';
import styles from './recommendations-tab.module.scss';
import RecommendationEntities from './recommendation-entities';
import { getRecommendationAppliedCount, formatKpi } from './utils';

const Recommendation = ({
    recommendation,
    report,
    context,
    onChangeContextRules,
    isDV360Report,
}) => {
    const {
        attribute,
        action,
        entities,
        target_metric: targetMetric,
        target_metric_value: targetMetricValue,
        target_metric_baseline: targetMetricBaseline,
    } = recommendation;
    const recommendedEntities = entities.map((i) => i.name);
    const isAdding = action === recommendationActions.ADD;
    const isTopics = attribute === recommendationAttributes.TOPICS;
    const attributeDisplayTerm = isTopics ? 'topics' : 'brands';

    const appliedCount = getRecommendationAppliedCount(recommendation, context);

    const totalCount = recommendedEntities.length;

    const applyFactory = (unapply) => {
        const addLogos = [];
        const addTopics = [];
        const removeLogos = [];
        const removeTopics = [];

        const ruleSet = new RuleSet(context.rules);
        const firstRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
        );

        if (isTopics) {
            (isAdding !== !!unapply ? addTopics : removeTopics).push(
                ...recommendedEntities,
            );
        } else {
            (isAdding !== !!unapply ? addLogos : removeLogos).push(
                ...recommendedEntities,
            );
        }
        ruleSet.changeRule(firstRule.key, { addTopics, addLogos });
        ruleSet.changeAllRules({ removeTopics, removeLogos });
        return () => onChangeContextRules(ruleSet.rules);
    };

    const onApply = applyFactory(false);
    const onUnapply = applyFactory(true);

    const namedEntities = !isTopics
        ? report.logos
              .filter((i) => recommendedEntities.includes(i.logo))
              .map((i) => i.logo_name)
        : report.topics
              .filter((i) => recommendedEntities.includes(i.topic))
              .map((i) => i.topic_name);

    const enumeratedNames = `${
        namedEntities.length > 3 ? ', including' : ':'
    } ${enumerate(
        namedEntities.slice(0, 3),
        3,
        (item, separator) => `’${item}’${separator}`,
    ).join(' ')}`;

    const title = `${
        isAdding ? 'Target' : 'Stop Targeting'
    } ${totalCount} new ${
        totalCount > 1
            ? attributeDisplayTerm
            : attributeDisplayTerm.slice(0, -1)
    }${enumeratedNames}`;

    const improvement = (
        <>
            Content featuring {totalCount > 1 ? 'these' : 'this'}{' '}
            {totalCount > 1
                ? attributeDisplayTerm
                : attributeDisplayTerm.slice(0, -1)}{' '}
            had a {formatKpi(targetMetric, isDV360Report)} of{' '}
            <Number
                value={targetMetricValue / 100}
                isPercentage
                maximumFractionDigits={2}
            />
            {', '}
            <Number
                value={targetMetricValue / targetMetricBaseline - 1}
                isPercentage
                maximumFractionDigits={2}
                signDisplay="never"
            />{' '}
            {targetMetricValue / targetMetricBaseline > 1 ? 'greater' : 'less'}{' '}
            than the report average
        </>
    );

    return (
        <Panel bordered>
            <Box padding="base" paddingSm={['small', 'base']}>
                <Row gutter="none">
                    <Col>
                        <Row gutter="none">
                            <Col span={12} spanSm="auto">
                                <div
                                    className={classNames([
                                        styles.label,
                                        styles.highlight,
                                    ])}
                                >
                                    Action
                                </div>
                            </Col>

                            <Col>
                                <div
                                    className={classNames([
                                        styles.description,
                                        styles.highlight,
                                    ])}
                                >
                                    {title}
                                </div>
                            </Col>
                        </Row>

                        <Row gutter="none">
                            <Col span={12} spanSm="auto">
                                <div className={styles.label}>Improvement</div>
                            </Col>

                            <Col>
                                <div className={styles.description}>
                                    {improvement}
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12} spanSm="auto">
                        <div className={styles.buttons}>
                            <SwitchButton
                                size="small"
                                active={appliedCount === totalCount}
                                onChange={
                                    appliedCount === totalCount
                                        ? onUnapply
                                        : onApply
                                }
                            >
                                Apply Optimization
                            </SwitchButton>
                        </div>
                    </Col>
                </Row>

                <RecommendationEntities
                    recommendation={recommendation}
                    context={context}
                    report={report}
                    onChangeContextRules={onChangeContextRules}
                />
            </Box>
        </Panel>
    );
};

export default Recommendation;
