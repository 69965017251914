import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Container from 'js/components/container/container';
import Layer from 'js/components/layer/layer';
import Spinner from 'js/components/spinner/spinner';
import styles from './loading-layer.module.scss';

function LoadingLayer({ message, position, size, transparent }) {
    const transitionNode = useRef();

    useEffect(() => {
        if (position !== 'absolute' && document.activeElement) {
            document.activeElement.blur();
        }
    }, [position]);

    if (position === 'absolute') {
        const classes = [styles.absolute];
        if (transparent) classes.push(styles.transparent);
        return (
            <div className={classNames(classes)}>
                <Spinner
                    size={size}
                    color={['gray', 'dark']}
                    message={message}
                />
            </div>
        );
    }

    return (
        <CSSTransition
            nodeRef={transitionNode}
            classNames="fade-scale"
            timeout={300}
            appear
            in
        >
            <Layer ref={transitionNode} isFixedWithBackground preventBodyScroll>
                <div className={styles.wrapper}>
                    <Container>
                        <div className={styles.container}>
                            <Spinner
                                size="large"
                                color={['gray', 'dark']}
                                message={message}
                            />
                        </div>
                    </Container>
                </div>
            </Layer>
        </CSSTransition>
    );
}

LoadingLayer.defaultProps = {
    message: 'Loading',
    position: '',
    size: 'large',
    transparent: false,
};

LoadingLayer.propTypes = {
    message: PropTypes.string,
    position: PropTypes.oneOf(['', 'absolute']),
    size: PropTypes.oneOf(['large', 'base', 'small']),
    transparent: PropTypes.bool,
};

export default LoadingLayer;
