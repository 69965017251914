import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CardBase from './card-base';
import styles from './card.module.scss';

const Wrapper = ({ to, openInNewWindow, children }) =>
    to ? (
        <Link to={to} target={openInNewWindow ? '_blank' : undefined}>
            {children}
        </Link>
    ) : (
        children
    );

function CardGeneric({
    title,
    description,
    topline,
    to,
    openInNewWindow,
    hasShadow,
    isSlim,
}) {
    return (
        <Wrapper to={to} openInNewWindow={openInNewWindow}>
            <CardBase type="generic" hasShadow={hasShadow} isSlim={isSlim}>
                <div className={styles['content-wrapper']}>
                    <div className={styles.content}>
                        {topline && !isSlim && (
                            <p className={styles.topline}>{topline}</p>
                        )}
                        <h3 className={styles.title}>{title}</h3>
                        {description && !isSlim && (
                            <div className={styles.description}>
                                {description}
                            </div>
                        )}
                    </div>
                    {to && (
                        <p className={styles.icon}>
                            <FontAwesomeIcon
                                icon={
                                    openInNewWindow
                                        ? faExternalLink
                                        : faChevronRight
                                }
                            />
                        </p>
                    )}
                </div>
            </CardBase>
        </Wrapper>
    );
}

CardGeneric.defaultProps = {
    description: undefined,
    topline: undefined,
    to: undefined,
    openInNewWindow: false,
    hasShadow: false,
    isSlim: false,
};

CardGeneric.propTypes = {
    /**
     * The title, the prominant text
     */
    title: PropTypes.node.isRequired,
    /**
     * The description, the longest body of text
     */
    description: PropTypes.node,
    /**
     * The topline on the card, the supplimentary text
     */
    topline: PropTypes.node,
    /**
     * a URL path to a local resource
     */
    to: PropTypes.string,
    /**
     * If true, open the URL from "to" in a new window
     */
    openInNewWindow: PropTypes.bool,
    /**
     * If true, give the card shadow
     */
    hasShadow: PropTypes.bool,
    /**
     * If true, transform the card into a list
     */
    isSlim: PropTypes.bool,
};

export default CardGeneric;
