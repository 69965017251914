import React, { useState } from 'react';
import Button from 'js/components/button/button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Text from 'js/components/text/text';
import styles from '../report-detail.module.scss';

const SaveButton = ({
    hasUnsavedChanges,
    isNewContext,
    onCreateContext,
    onSaveContext,
}) => {
    const [isHintVisible, setHintVisible] = useState(false);

    return (
        <DropdownMenu
            content={
                <div className={styles['hint-dropdown']}>
                    <Text color={['gray', 'dark']}>
                        {isNewContext ? (
                            <p>
                                It’s not possible to create a context because
                                you have not selected any keywords or dimension
                                signals.
                            </p>
                        ) : (
                            <>
                                <p>
                                    There are no unsaved changes for this
                                    context.
                                </p>
                                <p>
                                    This button will be enabled when there are
                                    changes that can be saved.
                                </p>
                            </>
                        )}
                    </Text>
                </div>
            }
            showMenu={isHintVisible}
            placement="bottom-end"
        >
            <div
                onMouseEnter={() => setHintVisible(!hasUnsavedChanges)}
                onMouseLeave={() => setHintVisible(false)}
            >
                <Button
                    disabled={!hasUnsavedChanges}
                    onClick={() => {
                        setHintVisible(false);
                        if (isNewContext) {
                            onCreateContext();
                        } else {
                            onSaveContext();
                        }
                    }}
                >
                    {isNewContext ? 'Create Context' : 'Save Optimizations'}
                </Button>
            </div>
        </DropdownMenu>
    );
};

export default SaveButton;
