import React, { useState } from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment/moment';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Collapse from 'js/components/collapse/collapse';
import DateRangePicker from 'js/components/date-picker/date-range-picker';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Text from 'js/components/text/text';
import styles from './date-selector.module.scss';

export const optionValues = {
    LAST_30: '30',
    LAST_7: '7',
    ALL: 'all',
    CUSTOM: 'custom',
};

const options = [
    { value: optionValues.LAST_30, name: 'Last 30 Days' },
    { value: optionValues.LAST_7, name: 'Last 7 Days' },
    { value: optionValues.ALL, name: 'All Time' },
    { value: optionValues.CUSTOM, name: 'Custom Date' },
];

const DropdownOption = ({ children, isSelected, onClick }) => {
    const classes = [styles['dropdown-option']];

    if (isSelected) {
        classes.push(styles.selected);
    }

    return (
        <li className={classNames(classes)}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={styles.content} onClick={onClick}>
                <span className={styles.icon}>
                    <FontAwesomeIcon
                        icon={isSelected ? faCheckCircle : faCircle}
                    />
                </span>

                <div className={styles.label}>{children}</div>
            </div>
        </li>
    );
};

function DateSelector({
    range,
    onChangeRange,
    start,
    onChangeStart,
    end,
    onChangeEnd,
}) {
    const [showMenu, setShowMenu] = useState(false);
    const [dateRangeVisible, setDateRangeVisible] = useState(false);

    const defaultStartDate = moment().subtract(30, 'days').toDate();
    const defaultEndDate = moment().toDate();
    const defaultValue = options[0].value;

    const getLabel = (selectedValue) => {
        const selectedOption = options.find(
            (item) => item.value === selectedValue,
        );

        if (!selectedOption) {
            return 'Unknown Date Range';
        }

        if (selectedOption.value === optionValues.CUSTOM) {
            if (!start || !end) {
                return 'Custom';
            }

            return `Custom - ${moment(start).format('D MMM')} - ${moment(
                end,
            ).format('D MMM')}`;
        }

        return selectedOption.name;
    };

    const handleChangeRange = (rangeValue) => {
        onChangeRange(rangeValue);
        if (rangeValue === optionValues.CUSTOM) {
            onChangeStart(start || defaultStartDate);
            onChangeEnd(end || defaultEndDate);
        } else {
            onChangeStart(null);
            onChangeEnd(null);
        }
    };

    return (
        <DropdownMenu
            content={
                <ul>
                    {options.map((item) => (
                        <DropdownOption
                            key={item.value}
                            isSelected={item.value === range}
                            onClick={() => {
                                handleChangeRange(item.value);
                                setDateRangeVisible(
                                    item.value === optionValues.CUSTOM,
                                );
                            }}
                        >
                            {item.name}
                        </DropdownOption>
                    ))}

                    <li>
                        <Box margin={[0, 0, '-small']}>
                            <Collapse isOpen={dateRangeVisible}>
                                <Box margin="small">
                                    <Text color={['gray', 'darkest']}>
                                        <DateRangePicker
                                            startDate={
                                                start || defaultStartDate
                                            }
                                            endDate={end || defaultEndDate}
                                            maxDate={moment().toDate()}
                                            hideUnavailableDates
                                            onStartDateChange={onChangeStart}
                                            onEndDateChange={onChangeEnd}
                                        />
                                    </Text>
                                </Box>
                            </Collapse>
                        </Box>
                    </li>
                </ul>
            }
            onHide={() => setShowMenu(false)}
            showMenu={showMenu}
            placement="bottom-end"
        >
            <Button
                theme="outline"
                prefix="Date Range"
                suffix={<FontAwesomeIcon icon={faChevronDown} />}
                onClick={() => setShowMenu(!showMenu)}
                active={showMenu || range !== defaultValue}
            >
                {getLabel(range)}
            </Button>
        </DropdownMenu>
    );
}

export default DateSelector;
