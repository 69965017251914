import React from 'react';
import { Link } from 'react-router-dom';

const CardWrapper = ({ to, title, children, openInNewWindow }) =>
    to ? (
        <Link to={to} target={openInNewWindow ? '_blank' : null} title={title}>
            {children}
        </Link>
    ) : (
        children
    );

export default CardWrapper;
