import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Panel from 'js/components/panel/panel';
import Row from 'js/components/grid/row';
import notificationCategories, {
    notificationCategoryGroups,
} from 'js/enums/notification-categories.enum';
import styles from './notification.module.scss';

const cx = classNames.bind(styles);

const Notification = ({ notification, onAction }) => {
    const history = useHistory();
    const {
        location: { pathname: currentUrl },
    } = history;

    const {
        category,
        created,
        message,
        object_id: objectId,
        is_seen: isSeen,
        is_urgent: isUrgent,
    } = notification;

    let actionUrl = null;
    let actionName = null;
    let categoryText = null;
    if (
        [
            notificationCategories.AUTO_OPTIMIZATION_APPLIED,
            notificationCategories.AUTO_OPTIMIZATION_INCOMPLETE,
        ].includes(category)
    ) {
        actionName = 'Visit Context';
        actionUrl = `/contexts/${objectId}/`;
        categoryText = notificationCategoryGroups.CONTEXTS;
    } else if (category === notificationCategories.AUTO_OPTIMIZATION_STOPPED) {
        actionName = 'Fix It';
        actionUrl = `/contexts/${objectId}/#optimize`;
        categoryText = notificationCategoryGroups.CONTEXTS;
    } else if (
        [
            notificationCategories.DEAL_PAUSED,
            notificationCategories.DEAL_READY,
            notificationCategories.DEAL_UNPAUSED,
            notificationCategories.DEAL_WILL_BE_PAUSED,
        ].includes(category)
    ) {
        actionName = 'Manage Deal';
        actionUrl = `/contexts/${objectId}/#channels`;
        categoryText = notificationCategoryGroups.DEAL_IDS;
    } else if (category === notificationCategories.OPTIMISE_REPORT_READY) {
        actionName = 'Visit Report';
        actionUrl = `/insights/display/${objectId}/`;
        categoryText = notificationCategoryGroups.INSIGHTS;
    } else if (category === notificationCategories.VIDEO_REPORT_READY) {
        actionName = 'Visit Report';
        actionUrl = `/insights/video/${objectId}/`;
        categoryText = notificationCategoryGroups.INSIGHTS;
    } else if (category === notificationCategories.EVENT_LOG_REPORT_READY) {
        actionName = 'Visit Report';
        actionUrl = `/insights/event-log/${objectId}/`;
        categoryText = notificationCategoryGroups.INSIGHTS;
    } else if (category === notificationCategories.EVENT_AUDIT_INACTIVE) {
        categoryText = notificationCategoryGroups.INSIGHTS;
    }

    const gotoActionUrl = async () => {
        if (onAction) {
            await onAction();
        }
        if (actionUrl !== currentUrl) {
            history.push(actionUrl);
        }
    };

    return (
        <Panel bordered>
            <Box padding="base">
                <Row
                    justifyContent="space-between"
                    alignItems="center"
                    gutter="none"
                    gap="small"
                >
                    <Col span="auto">
                        <div className={cx({ category: true, seen: isSeen })}>
                            {categoryText}

                            {isUrgent && (
                                <span className={styles.icon}>
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />
                                </span>
                            )}
                            <div className={styles.time}>
                                {moment(created).format('ll')}
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className={cx({ content: true, seen: isSeen })}>
                            {message}

                            {actionUrl && (
                                <Box displayLg="none" margin={['base', 0, 0]}>
                                    <Row>
                                        <Col span="auto">
                                            <Button
                                                theme="outline"
                                                onClick={gotoActionUrl}
                                            >
                                                {actionName}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Box>
                            )}
                        </div>
                    </Col>

                    {actionUrl && (
                        <Box display="none" displayLg="block">
                            <Col span="auto">
                                <Button theme="outline" onClick={gotoActionUrl}>
                                    {actionName}
                                </Button>
                            </Col>
                        </Box>
                    )}
                </Row>
            </Box>
        </Panel>
    );
};

Notification.defaultProps = {
    onAction: undefined,
};
Notification.propTypes = {
    notification: PropTypes.shape({
        category: PropTypes.string.isRequired,
        created: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        object_id: PropTypes.string.isRequired,
        is_seen: PropTypes.bool.isRequired,
        is_urgent: PropTypes.bool.isRequired,
    }).isRequired,
    onAction: PropTypes.func,
};
export default Notification;
