import React from 'react';
import PropTypes from 'prop-types';
import TabButtons from 'js/components/tabs/buttons';
import TabButton from 'js/components/tabs/button';

function Tabs({
    tabs,
    currentTab,
    tabStyle,
    size,
    hideTabButtons,
    children,
    buttonsWrapper,
    onChangeTab,
}) {
    const Wrapper = buttonsWrapper;

    const innerTabs =
        tabs.length > -1
            ? tabs
            : Object.keys(tabs).map((tabId) => ({
                  id: tabs[tabId],
                  label: tabs[tabId],
              }));

    return (
        <>
            {!hideTabButtons && (
                <Wrapper>
                    <TabButtons tabStyle={tabStyle} size={size}>
                        {innerTabs.map((tab) => (
                            <TabButton
                                key={tab.id}
                                isActive={currentTab === tab.id}
                                to={tab.to}
                                onClick={
                                    onChangeTab
                                        ? () => onChangeTab(tab.id)
                                        : null
                                }
                            >
                                {tab.label}
                            </TabButton>
                        ))}
                    </TabButtons>
                </Wrapper>
            )}

            <>
                {React.Children.toArray(children).find(
                    (child) => child.props.tabId === currentTab,
                )}
            </>
        </>
    );
}

Tabs.defaultProps = {
    tabStyle: undefined,
    size: undefined,
    hideTabButtons: false,
    buttonsWrapper: React.Fragment,
    onChangeTab: undefined,
};

Tabs.propTypes = {
    tabs: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.string),
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                label: PropTypes.string,
                to: PropTypes.string,
            }),
        ),
    ]).isRequired,
    currentTab: PropTypes.string.isRequired,
    tabStyle: PropTypes.oneOf(['flush', 'block', 'switch']),
    size: PropTypes.oneOf(['small']),
    hideTabButtons: PropTypes.bool,
    children: PropTypes.node.isRequired,
    buttonsWrapper: PropTypes.elementType,
    onChangeTab: PropTypes.func,
};

export default Tabs;
