import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import styles from './grid.styles';

function Col({
    children,
    overflow,
    gutter,
    span,
    alignSelf,
    offset,
    order,
    debug,
}) {
    const classes = [styles.col];

    if (overflow) {
        classes.push(styles[`overflow--${overflow}`]);
    }

    if (gutter) {
        classes.push(styles[`col--gutter-${gutter}`]);
    }

    if (span) {
        classes.push(styles[`col--${span}`]);
    }

    if (alignSelf) {
        classes.push(styles[`col--align-self-${alignSelf}`]);
    }

    if (offset) {
        classes.push(styles[`col--offset-${offset}`]);
    }

    if (order) {
        classes.push(styles[`col--order-${order}`]);
    }

    return (
        <View debug={debug} style={classes}>
            {children}
        </View>
    );
}

const spans = ['fluid', 'auto', 'equal', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

Col.defaultProps = {
    children: undefined,
    overflow: undefined,
    gutter: 'base',
    span: 'fluid',
    alignSelf: undefined,
    offset: undefined,
    order: undefined,
};

/* eslint-disable react/no-unused-prop-types */
Col.propTypes = {
    children: PropTypes.node,
    overflow: PropTypes.oneOf(['hidden']),
    gutter: PropTypes.oneOf([
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
    ]),
    span: PropTypes.oneOf(spans),
    alignSelf: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/* eslint-enable react/no-unused-prop-types */

export default Col;
