import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { breakpointKeys } from 'js/constants';
import { getBreakpointCssPrefix } from 'js/utils';
import styles from './grid.module.scss';

function Row(props) {
    const { children, reverse } = props;
    const classes = [styles.row];

    if (reverse) {
        classes.push(styles['row--reverse']);
    }

    breakpointKeys.forEach((breakpointKey) => {
        const breakpointCssPrefix = getBreakpointCssPrefix(breakpointKey);

        const {
            [`gap${breakpointKey}`]: gapProp,
            [`gutter${breakpointKey}`]: gutterProp,
            [`alignItems${breakpointKey}`]: alignItemsProp,
            [`justifyContent${breakpointKey}`]: justifyContentProp,
        } = props;

        if (gapProp) {
            classes.push(styles[`${breakpointCssPrefix}row--gap-${gapProp}`]);
        }

        if (gutterProp) {
            classes.push(
                styles[`${breakpointCssPrefix}row--gutter-${gutterProp}`],
            );
        }

        if (alignItemsProp) {
            classes.push(
                styles[
                    `${breakpointCssPrefix}row--align-items-${alignItemsProp}`
                ],
            );
        }

        if (justifyContentProp) {
            classes.push(
                styles[
                    `${breakpointCssPrefix}row--justify-content-${justifyContentProp}`
                ],
            );
        }
    });

    return <div className={classNames(classes)}>{children}</div>;
}

Row.defaultProps = {
    gap: 'none',
    gutter: 'base',
    reverse: false,
    alignItems: '',
    justifyContent: '',
};

/* eslint-disable react/no-unused-prop-types */
Row.propTypes = {
    children: PropTypes.node.isRequired,
    gap: PropTypes.oneOf([
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
    ]),
    gutter: PropTypes.oneOf([
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
    ]),
    reverse: PropTypes.bool,
    alignItems: PropTypes.oneOf([
        '',
        'flex-start',
        'center',
        'flex-end',
        'stretch',
    ]),
    justifyContent: PropTypes.oneOf([
        '',
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'start',
    ]),
};
/* eslint-enable react/no-unused-prop-types */

export default Row;
