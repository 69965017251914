import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import styles from './page-content.styles';

function PageContent({ children }) {
    return <View style={styles.container}>{children}</View>;
}

PageContent.defaultProps = {};

PageContent.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageContent;
