import React from 'react';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import Box from 'js/components/box/box';
import Checkbox from 'js/components/checkbox/checkbox';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import SVGImage from 'js/components/svg-image/svg-image';
import SparkleStar from '../images/sparkle-star.svg';
import CloudArrowUpRegular from '../images/cloud-arrow-up-regular.svg';
import CloudArrowUpSolid from '../images/cloud-arrow-up-solid.svg';
import styles from './data-source-step.module.scss';

const CheckButton = ({ label, active, disabled, icon, info, onClick }) => (
    <Checkbox
        radio
        hideIcon
        isReadOnly={disabled}
        options={[
            {
                label: (
                    <div className={styles['check-button-label']}>
                        {icon}
                        <Box margin={[0, 0, 0, 'small']}>{label}</Box>
                    </div>
                ),
                value: true,
                hint: info,
            },
        ]}
        selectedValues={active}
        onChange={() => onClick()}
    />
);

const FileIconActive = (
    <SVGImage src={CloudArrowUpSolid} width={35} height={35} />
);
const FileIconInactive = (
    <SVGImage src={CloudArrowUpRegular} width={35} height={35} />
);
const EventsIconActive = (
    <SVGImage src={SparkleStar} width={35} height={35} fill="currentColor" />
);
const EventsIconInactive = (
    <SVGImage src={SparkleStar} width={35} height={35} fill="transparent" />
);

const SourceSwitch = ({ template, onChangeType }) => {
    const { type, subtype } = template;
    const isFile = subtype === videoReportSubtypes.YOUTUBE_GADS_FILE;

    return (
        <Box padding={[0, 0, 'large']}>
            <Row>
                <Col>
                    <CheckButton
                        label="From Integration"
                        active={!isFile}
                        icon={isFile ? EventsIconInactive : EventsIconActive}
                        info="Use data you’ve collected via 4D’s API or Script based integrations with Google."
                        onClick={() =>
                            onChangeType(type, videoReportSubtypes.YOUTUBE_GADS)
                        }
                    />
                </Col>
                <Col>
                    <CheckButton
                        label="From File"
                        active={isFile}
                        icon={isFile ? FileIconActive : FileIconInactive}
                        info="Use data you’ve downloaded directly from Google reporting."
                        onClick={() =>
                            onChangeType(
                                type,
                                videoReportSubtypes.YOUTUBE_GADS_FILE,
                            )
                        }
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default SourceSwitch;
