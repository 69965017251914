import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';
import Text from 'js/components/text/text';
import styles from './customization-bar.module.scss';

function CustomizationDropdown({ pageSections, onSavePageLayout }) {
    const [showMenu, setShowMenu] = useState(false);
    const [orderedPageSections, setOrderedPageSections] = useState([]);

    /* note
     * since changeOrder removes the DOM element that was clicked on the
     * next render, the dropdown callback gets all confused and thinks
     * we clicked outside of the dropdown.
     * Before running this function, you have to stop propagation
     * of the DOM event inside the dropdown, which just so happens to be
     * bound to the root DOM node, just like all react synthetic events:
     * e.nativeEvent.stopImmediatePropagation();
     */
    const changeOrder = (sectionIndex, offset) => {
        const orderedItem = orderedPageSections[sectionIndex];
        orderedPageSections.splice(sectionIndex, 1);
        orderedPageSections.splice(sectionIndex + offset, 0, orderedItem);

        setOrderedPageSections([...orderedPageSections]);
    };

    const saveOrder = () => {
        onSavePageLayout(
            orderedPageSections.map(({ id }) => ({ section: id })),
        );
        setShowMenu(false);
    };

    useEffect(() => {
        if (!showMenu) {
            setOrderedPageSections([...pageSections]);
        }
    }, [pageSections, showMenu]);

    return (
        <DropdownMenu
            content={
                <ul>
                    <div className={styles['dropdown-container']}>
                        <Box padding={[0, 'base']}>
                            <Box padding={['small', 0]}>
                                <Text weight="bolder" size="larger">
                                    Customize your homepage
                                </Text>
                            </Box>

                            <Box padding={['small', 0]}>
                                <Text>
                                    Create your own unique 4D homepage
                                    experience by changing the order of the
                                    features
                                </Text>
                            </Box>
                        </Box>

                        <DropdownDivider />

                        <Box padding={['base', 'base', 'smaller']}>
                            <Row>
                                <Col span={9}>
                                    <Text weight="bolder" size="large">
                                        Module
                                    </Text>
                                </Col>

                                <Col span={3}>
                                    <Text weight="bolder" size="large">
                                        Order
                                    </Text>
                                </Col>
                            </Row>
                        </Box>

                        <DropdownDivider />

                        {orderedPageSections.map(({ id, label }, idx) => (
                            <Fragment key={id}>
                                <Box padding={['smaller', 'base']}>
                                    <Row alignItems="center">
                                        <Col span={9}>
                                            <Text weight="bold" size="large">
                                                {label}
                                            </Text>
                                        </Col>

                                        <Col span={3}>
                                            <Row gutter="small">
                                                <Col span={6}>
                                                    {idx <
                                                        orderedPageSections.length -
                                                            1 && (
                                                        <Button
                                                            theme="outline"
                                                            square
                                                            onClick={(e) => {
                                                                e.nativeEvent.stopImmediatePropagation();
                                                                changeOrder(
                                                                    idx,
                                                                    1,
                                                                );
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faChevronDown
                                                                }
                                                            />
                                                        </Button>
                                                    )}
                                                </Col>

                                                <Col span={6}>
                                                    {idx > 0 && (
                                                        <Button
                                                            theme="outline"
                                                            square
                                                            onClick={(e) => {
                                                                e.nativeEvent.stopImmediatePropagation();
                                                                changeOrder(
                                                                    idx,
                                                                    -1,
                                                                );
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faChevronUp
                                                                }
                                                            />
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Box>

                                <DropdownDivider />
                            </Fragment>
                        ))}

                        <Box
                            margin={['base', 0, 'small']}
                            padding={[0, 'base']}
                        >
                            <Row justifyContent="flex-end">
                                <Col span="auto">
                                    <Button onClick={saveOrder}>Save</Button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </ul>
            }
            onHide={() => setShowMenu(false)}
            showMenu={showMenu}
            placement="bottom-start"
        >
            <Button
                theme="outline"
                square
                onClick={() => setShowMenu(!showMenu)}
                active={showMenu}
            >
                <FontAwesomeIcon icon={faSlidersH} />
            </Button>
        </DropdownMenu>
    );
}

export default function CustomizationBar({ pageSections, onSavePageLayout }) {
    return (
        <Section hasBorderBottom>
            <Box padding={['small', 0]}>
                <Container>
                    <Row justifyContent="flex-end">
                        <Col span="auto">
                            <CustomizationDropdown
                                pageSections={pageSections}
                                onSavePageLayout={onSavePageLayout}
                            />
                        </Col>
                    </Row>
                </Container>
            </Box>
        </Section>
    );
}
