import React, { useEffect } from 'react';
import { eventTagSources } from 'js/constants';
import { setDocumentTitle } from 'js/utils';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import ReportTag from './report-tag';

const sources = eventTagSources
    .filter((source) => source.availableForDisplay || source.videoUrlParams)
    .sort((a, b) => a.label.localeCompare(b.label));

function ReportTagPage() {
    useEffect(() => {
        setDocumentTitle(['Get The 4D Tag']);
    }, []);

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb
                            key="insights"
                            to="/insights/"
                            label="Insights"
                            isCollapsible
                        />,
                        <Crumb key="get-tag" label="Get The Tag" />,
                    ]}
                />
            }
        >
            <ReportTag sources={sources} />
        </Layout>
    );
}

export default ReportTagPage;
