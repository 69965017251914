import React, { useEffect, useState } from 'react';
import { gzipSync } from 'zlib'; // eslint-disable-line import/no-nodejs-modules
import { getJwtPayload } from 'js/utils';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Tabs from 'js/components/tabs/tabs';
import TabContent from 'js/components/tabs/content';
import DisplayTab from './components/display-tab';
import VideoTab from './components/video-tab';
import TypeSelector from './components/type-selector';
import vastErrors from './enums/vast-errors.enum';
import styles from './report-tag.module.scss';

const isDisplaySource = (source) => !!source.availableForDisplay;
const isVideoSource = (source) => !!source.videoUrlParams;

const isValidTime = (timeString) => {
    const pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;
    return !!(timeString && timeString.match(pattern));
};

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

const fetchXMLDocument = async (url) => {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const stream = new ReadableStream({
        start(controller) {
            const push = () => {
                reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    push();
                });
            };
            push();
        },
    });

    const textDocument = await new Response(stream, {
        headers: { 'Content-Type': 'text/html' },
    }).text();

    return new DOMParser().parseFromString(textDocument, 'text/xml');
};

function ReportTag({ sources }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(vastErrors.NONE);
    const [currentSourceId, setCurrentSourceId] = useState(sources[0].id);
    const [isVideo, setIsVideo] = useState(false);

    const [vastTag, setVastTag] = useState('');
    const [wrappedVastTag, setWrappedVastTag] = useState('');
    const [videoDuration, setVideoDuration] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const token = localStorage.getItem('AuthToken');
    const { account: accountId } = getJwtPayload(token);

    useEffect(() => {
        setError(vastErrors.NONE);
        setWrappedVastTag('');
        setVideoUrl('');
        setVideoDuration('');
    }, [vastTag]);

    const handleTabChange = (sourceId) => {
        const source = sources.find(({ id }) => id === sourceId);

        setVastTag('');
        setCurrentSourceId(source.id);

        if (!isDisplaySource(source)) {
            setIsVideo(true);
        }
        if (!isVideoSource(source)) {
            setIsVideo(false);
        }
    };

    const handleVastWrap = async (source, value) => {
        setWrappedVastTag('');
        setVideoDuration('');
        setVideoUrl('');

        if (value === '') {
            setError(vastErrors.EMPTY);
            return;
        }

        setLoading(true);
        try {
            const baseUrl = 'https://serve.4d.silverbulletcloud.com/vast-tag';
            const encodedUrl = encodeURIComponent(
                arrayBufferToBase64(gzipSync(value)),
            );

            let xml = await fetchXMLDocument(value);
            const adTagUrl = xml.getElementsByTagName('VASTAdTagURI')[0];
            if (adTagUrl) {
                xml = await fetchXMLDocument(adTagUrl.childNodes[0].data);
            }

            const vastVersion = xml
                .getElementsByTagName('VAST')[0]
                ?.getAttribute('version');
            const vastVideoDuration =
                xml.getElementsByTagName('Duration')[0]?.childNodes[0]?.data;
            const vastVideoUrl =
                xml.getElementsByTagName('MediaFile')[0]?.childNodes[0]?.data;

            if (isValidTime(vastVideoDuration) && vastVideoUrl && vastVersion) {
                setWrappedVastTag(
                    `${baseUrl}/${encodedUrl}/?source-id=${source.id}&avocado-id=${accountId}&version=${vastVersion}&${source.videoUrlParams}`,
                );
                setVideoDuration(vastVideoDuration);
                setVideoUrl(vastVideoUrl);
                setError(vastErrors.NONE);
            } else {
                setError(vastErrors.UNEXPECTED);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(vastErrors.INVALID);
        }
    };

    const handleVastChange = async (value) => {
        setVastTag(value);
        setError(vastErrors.NONE);
    };

    const buttonsWrapper = ({ children }) => (
        <Box margin={[0, 0, 'large']}>
            <Row justifyContent="center">
                <Col span="auto">{children}</Col>
            </Row>
        </Box>
    );

    return (
        <Container size="medium">
            <Box padding={['large', 0, 'large', 0]}>
                <Tabs
                    tabs={sources}
                    currentTab={currentSourceId}
                    buttonsWrapper={buttonsWrapper}
                    onChangeTab={handleTabChange}
                >
                    {sources.map((source) => (
                        <TabContent tabId={source.id} key={source.id}>
                            <Container size="medium">
                                <Panel bordered>
                                    <Box
                                        padding={['large', 'large', 0, 'large']}
                                    >
                                        <div className={styles['logo-wrapper']}>
                                            <img
                                                src={source.logoImg}
                                                alt={source.label}
                                            />
                                        </div>

                                        <TypeSelector
                                            hasDisplay={isDisplaySource(source)}
                                            hasVideo={isVideoSource(source)}
                                            isVideo={isVideo}
                                            onChange={() =>
                                                setIsVideo((prev) => !prev)
                                            }
                                        />
                                    </Box>

                                    {!isVideo ? (
                                        <DisplayTab
                                            source={source}
                                            accountId={accountId}
                                            key={source.id}
                                        />
                                    ) : (
                                        <VideoTab
                                            source={source}
                                            accountId={accountId}
                                            loading={loading}
                                            error={error}
                                            vastTag={vastTag}
                                            wrappedVastTag={wrappedVastTag}
                                            videoDuration={videoDuration}
                                            videoUrl={videoUrl}
                                            key={source.id}
                                            onVastChange={handleVastChange}
                                            onVastWrap={() =>
                                                handleVastWrap(source, vastTag)
                                            }
                                        />
                                    )}
                                </Panel>
                            </Container>
                        </TabContent>
                    ))}
                </Tabs>
            </Box>
        </Container>
    );
}

export default ReportTag;
