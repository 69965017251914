import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import entityTypes from 'js/enums/entity-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import ConfirmModal from 'js/components/modal/confirm-modal';
import ErrorBar from 'js/components/error-bar/error-bar';
import Spinner from 'js/components/spinner/spinner';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import { showToast } from 'js/components/toast/toast';
import InfoPanel from '../components/info-panel';
import NoDataPanel from '../components/no-data-panel';
import PdfDownloadDrawer from '../components/pdf-download-drawer/pdf-download-drawer';
import PdfDownloadPanel from '../components/pdf-download-panel/pdf-download-panel';
import ProcessingPanel from '../components/processing-panel';
import SnapshotModal from '../components/snapshot-modal';
import SummaryPanel from '../components/summary-panel/summary-panel';
import ActionButtons from './components/action-buttons';
import InsightsOverviewPanel from './components/insights-overview-panel/insights-overview-panel';
import RecommendationPanel from './components/recommendation-panel/recommendation-panel';
import RecommendationsTab from './components/recommendations-tab/recommendations-tab';
import ReportTab from './components/report-tab';
import TopicInspectDrawer from './components/topic-inspect-drawer';
import pageTabs from './enums/page-tabs.enum';

const ReportContent = ({
    report,
    template,
    context,
    onExportPdf,
    onChangeContextRules,
    onInspectTopic,
    currentTab,
    onChangeTab,
}) => {
    const changeTab = (tabId) => {
        onChangeTab(tabId);
        document.getElementById('layout-container').scrollTop = 0;
    };

    if (!report) {
        return <ProcessingPanel />;
    }

    if (!report.impressions) {
        return <NoDataPanel report={report} />;
    }

    const buttonsWrapper = ({ children }) => (
        <Container>
            <Box padding={['large', 0, 0]}>{children}</Box>
        </Container>
    );

    return (
        <Tabs
            tabs={pageTabs}
            currentTab={currentTab}
            buttonsWrapper={buttonsWrapper}
            onChangeTab={changeTab}
        >
            <TabContent tabId={pageTabs.OVERVIEW}>
                <InfoPanel
                    report={report}
                    template={template}
                    context={context}
                    reportType="Display"
                />

                <SummaryPanel report={report} template={template} />

                <PdfDownloadPanel onDownload={onExportPdf} />
                <InsightsOverviewPanel
                    report={report}
                    onChangeTab={changeTab}
                />

                <RecommendationPanel
                    template={template}
                    report={report}
                    context={context}
                    onChangeTab={changeTab}
                />
            </TabContent>

            <TabContent tabId={pageTabs.RECOMMENDATIONS}>
                <RecommendationsTab
                    report={report}
                    context={context}
                    onChangeContextRules={onChangeContextRules}
                />
            </TabContent>

            <TabContent tabId={pageTabs.INSIGHTS}>
                <ReportTab
                    report={report}
                    context={context}
                    onChangeContextRules={onChangeContextRules}
                    onInspectTopic={onInspectTopic}
                />
            </TabContent>
        </Tabs>
    );
};

const ReportDisplayDetail = ({
    report,
    reports,
    template,
    context,
    loading,
    hasLoadingError,
    hasDeletingError,
    hasSnapshotError,
    hasUnsavedChanges,
    onChangeContextRules,
    onDelete,
    onDiscardChanges,
    onExportExcel,
    onReload,
    onSnapshot,
    onSwitchReport,
    onToggleFavorite,
    matchedPages,
    isMatchedPagesLoading,
    hasMatchedPagesError,
    onGetMatchedPages,
}) => {
    const [isPdfDownloadDrawerVisible, setPdfDownloadDrawerVisible] =
        useState(false);
    const [isTopicInspectDrawerVisible, setTopicInspectDrawerVisible] =
        useState(false);
    const [inspectedTopic, setInspectedTopic] = useState();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isSnapshotModalVisible, setSnapshotModalVisible] = useState(false);
    const [isUnsavedModalVisible, setUnsavedModalVisible] = useState(false);
    const [nextLocation, setNextLocation] = useState();
    const [currentTabId, setCurrentTabId] = useState(pageTabs.OVERVIEW);

    const history = useHistory();

    const { is_ready: isReady } = template;

    const handleSnapshot = async (dateRange) => {
        await onSnapshot(dateRange);
        setSnapshotModalVisible(false);
        showToast(
            'Success',
            <p>
                The snapshot has been created and will be ready to view within
                the next 24 hours.
            </p>,
            10,
        );
    };

    const handleLocationChange = (location) => {
        const { pathname: nextPathname } = location;
        const { pathname: currentPathname } = history.location;
        if (
            hasUnsavedChanges &&
            nextPathname !== currentPathname &&
            !nextPathname.startsWith('/login/') &&
            !nextPathname.startsWith('/reload/')
        ) {
            setUnsavedModalVisible(true);
            setNextLocation(location);

            return false;
        }

        return true;
    };

    const toggleTopicInspectDrawerVisible = (selectedTopic) => {
        const ruleSet = new RuleSet([{ topics: [selectedTopic.topic] }]);
        onGetMatchedPages({ ...context, rules: ruleSet.rules });
        setInspectedTopic(selectedTopic);
        setTopicInspectDrawerVisible(true);
    };

    useEffect(() => {
        if (nextLocation && !hasUnsavedChanges) {
            setNextLocation(null);
            const { pathname, search } = nextLocation;
            history.push(pathname + search);
        }
    }, [nextLocation, hasUnsavedChanges]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return (
            <Box margin={['large', 0]}>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Box>
        );
    }

    if (hasLoadingError) {
        return (
            <Box margin={['large', 0]}>
                <ErrorBar
                    size="small"
                    title="There Was An Error Loading The Insights Report"
                    onAction={onReload}
                />
            </Box>
        );
    }

    return (
        <>
            <ActionButtons
                template={template}
                report={report}
                reports={reports}
                onDelete={() => setDeleteModalVisible(true)}
                onSnapshot={() => setSnapshotModalVisible(true)}
                onToggleFavorite={onToggleFavorite}
                onSwitchReport={onSwitchReport}
                onExportExcel={onExportExcel}
                onExportPdf={() => setPdfDownloadDrawerVisible(true)}
            />

            <ReportContent
                report={report}
                template={template}
                context={context}
                onExportPdf={() => setPdfDownloadDrawerVisible(true)}
                onChangeContextRules={onChangeContextRules}
                onInspectTopic={toggleTopicInspectDrawerVisible}
                currentTab={currentTabId}
                onChangeTab={setCurrentTabId}
            />

            {isPdfDownloadDrawerVisible && (
                <PdfDownloadDrawer
                    report={report}
                    template={template}
                    onClose={() => setPdfDownloadDrawerVisible(false)}
                />
            )}

            {isTopicInspectDrawerVisible && (
                <TopicInspectDrawer
                    report={report}
                    context={context}
                    topic={inspectedTopic}
                    matchedPages={matchedPages}
                    isLoading={isMatchedPagesLoading}
                    hasError={hasMatchedPagesError}
                    onChangeContextRules={onChangeContextRules}
                    onClose={() => setTopicInspectDrawerVisible(false)}
                    onReload={onGetMatchedPages}
                    showBlockButton={currentTabId !== pageTabs.RECOMMENDATIONS}
                />
            )}

            {isDeleteModalVisible && (
                <ConfirmModal
                    title={`Are You Sure You Want To Delete ${template.name}?`}
                    confirm="Yes, Delete Insights Report"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Insights Report"
                    hasError={hasDeletingError}
                    errorMessage="
                        You were unable to delete the insights report because there
                        was an unexpected error. Please try again in a few moments."
                    onConfirm={onDelete}
                    onCancel={() => setDeleteModalVisible(false)}
                />
            )}

            {isSnapshotModalVisible && (
                <SnapshotModal
                    type={entityTypes.DISPLAY_REPORTS}
                    reports={reports}
                    isReady={isReady}
                    hasError={hasSnapshotError}
                    onConfirm={handleSnapshot}
                    onCancel={() => setSnapshotModalVisible(false)}
                />
            )}

            {isUnsavedModalVisible && (
                <ConfirmModal
                    confirm="Yes, Discard The Changes"
                    cancel="No, Stay On This Page"
                    onCancel={() => {
                        setNextLocation(null);
                        setUnsavedModalVisible(false);
                    }}
                    onConfirm={() => {
                        onDiscardChanges();
                        setUnsavedModalVisible(false);
                    }}
                >
                    <p>
                        There are unsaved changes that will be lost if you
                        navigate away from this page.
                    </p>
                    <p>Are you sure you want to continue?</p>
                </ConfirmModal>
            )}

            <Prompt when={hasUnsavedChanges} message={handleLocationChange} />
        </>
    );
};

export default ReportDisplayDetail;
