import React, { useEffect, useState } from 'react';
import EventBus from '../../event-bus';
import Toast from './toast';
import Box from '../box/box';
import styles from './toast.module.scss';

let toastCount = 0;

function Toaster() {
    const [toasts, setToasts] = useState([]);
    useEffect(() => {
        const addToastHandler = ({ title, content, timeoutSeconds, theme }) => {
            toastCount += 1;
            setToasts((curr) => [
                ...curr,
                { id: toastCount, title, content, timeoutSeconds, theme },
            ]);
        };
        EventBus.on('addToast', addToastHandler);
        return () => EventBus.off('addToast', addToastHandler);
    }, []);
    return (
        toasts.length > 0 && (
            <div className={styles.toaster}>
                {toasts.map((toast) => (
                    <Box key={toast.id} margin={[0, 0, 'base']}>
                        <Toast
                            title={toast.title}
                            timeoutSeconds={toast.timeoutSeconds}
                            onClose={() => {
                                setToasts((curr) =>
                                    curr.filter(
                                        (existingToast) =>
                                            existingToast.id !== toast.id,
                                    ),
                                );
                            }}
                            theme={toast.theme}
                        >
                            {toast.content}
                        </Toast>
                    </Box>
                ))}
            </div>
        )
    );
}

Toaster.defaultProps = {};

Toaster.propTypes = {};

export default Toaster;
