import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Tabs from 'js/components/tabs/tabs';
import TabContent from 'js/components/tabs/content';
import RecentContexts from './recent-contexts';
import RecentReports from './recent-reports';
import SectionWrapper from '../section-wrapper/section-wrapper';
import pageSections from '../../enums/page-sections.enum';

const Wrapper = ({ sectionTitle, children, pageLayout, onSavePageLayout }) => {
    const recentUpdatesSection = pageLayout.layout_sections.find(
        (layout) => layout.section === pageSections.RECENT_UPDATES,
    );
    const recentContextsIndex =
        pageLayout.layout_sections.indexOf(recentUpdatesSection);

    const [showCollapse, setShowCollapse] = useState(
        !recentUpdatesSection.is_collapsed,
    );

    const toggleCollapse = () => {
        setShowCollapse((prevState) => !prevState);
        recentUpdatesSection.is_collapsed = !recentUpdatesSection.is_collapsed;
        const layoutSections = [...pageLayout.layout_sections];
        layoutSections[recentContextsIndex] = recentUpdatesSection;
        onSavePageLayout(layoutSections);
    };
    return (
        <SectionWrapper
            isOpen={showCollapse}
            toggleCollapse={toggleCollapse}
            title={sectionTitle}
        >
            {children}
        </SectionWrapper>
    );
};

export default function RecentUpdates({
    sectionTitle,
    recentContexts,
    recentReports,
    onToggleFavorite,
    onDelete,
    pageLayout,
    onSavePageLayout,
    onCancelScheduling,
}) {
    const tabs = [
        { id: 'REPORTS', label: 'Insights Reports' },
        { id: 'CONTEXTS', label: 'Contexts' },
    ];
    const [currentTabId, setCurrentTabId] = useState(tabs[0].id);

    const buttonsWrapper = ({ children }) => (
        <Box margin={['base', 0]} marginLg={['large', 0]}>
            <Row justifyContent="center">
                <Col span="auto">{children}</Col>
            </Row>
        </Box>
    );

    return (
        <Wrapper
            sectionTitle={sectionTitle}
            pageLayout={pageLayout}
            onSavePageLayout={onSavePageLayout}
        >
            <Panel bordered>
                <Box padding={[0, 'base']}>
                    <Tabs
                        tabs={tabs}
                        currentTab={currentTabId}
                        buttonsWrapper={buttonsWrapper}
                        onChangeTab={setCurrentTabId}
                    >
                        <TabContent tabId="REPORTS">
                            <Box padding={[0, 0, 'base', 0]}>
                                <RecentReports
                                    reports={recentReports}
                                    onToggleFavorite={onToggleFavorite}
                                    onDelete={onDelete}
                                    onCancelScheduling={onCancelScheduling}
                                />
                            </Box>
                        </TabContent>
                        <TabContent tabId="CONTEXTS">
                            <Box padding={[0, 0, 'base', 0]}>
                                <RecentContexts
                                    contexts={recentContexts}
                                    onToggleFavorite={onToggleFavorite}
                                    onDelete={onDelete}
                                />
                            </Box>
                        </TabContent>
                    </Tabs>
                </Box>
            </Panel>
        </Wrapper>
    );
}
