import Panel from 'js/components/panel/panel';
import React, { useEffect, useMemo, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eventTagSources } from 'js/constants';
import { AbortError, getEventTagSourceOrDefault } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Checkbox from 'js/components/checkbox/checkbox';
import Col from 'js/components/grid/column';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import DrawerFooter from 'js/components/drawer/footer';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import Select from 'js/components/select/select';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import Help from './help';

const LoadingError = ({ onRetry }) => (
    <Alert
        theme="danger"
        title="There was an unexpected error when loading data"
        actionItems={<Button onClick={onRetry}>Retry</Button>}
    >
        <p>
            Hopefully it is only a temporary issue. Please try again in a few
            moments.
        </p>
    </Alert>
);

const EmptyMessage = () => (
    <Alert
        theme="empty"
        title="You Have No Data To Auto-Optimize A Context With"
    >
        <p>
            For you to be able to auto-apply optimizations to a context, 4D
            needs to have received data generated by the tag for this account.
        </p>
        <p>
            If you have recently implemented the tag for a campaign, it may take
            some time for the data to be recognized. If that is the case, please
            check back soon.
        </p>
    </Alert>
);

const ConnectButton = ({ disabled, onClick }) => (
    <Row justifyContent="flex-end">
        <Col span="auto">
            <Button disabled={disabled} onClick={onClick}>
                Connect
            </Button>
        </Col>
    </Row>
);

const OptimizeCampaignDrawer = ({
    isVideo,
    eventHierarchy,
    onAddCampaign,
    onClose,
    onLoad,
}) => {
    const [hasError, setError] = useState(false);
    const [isLoading, setLoading] = useState(!eventHierarchy);
    const [selectedSourceId, setSelectedSourceId] = useState();
    const [selectedCampaignId, setSelectedCampaignId] = useState();
    const [selectedLineItemIds, setSelectedLineItemIds] = useState([]);
    const [searchedCampaign, setSearchedCampaign] = useState('');
    const [searchedLineItem, setSearchedLineItem] = useState('');

    const selection = {
        source: selectedSourceId,
        campaign_id: selectedCampaignId,
        line_items: selectedLineItemIds.map((id) => ({ line_item_id: id })),
    };

    const sourceOptions = useMemo(() => {
        const visibleSources = [
            ...new Set(eventHierarchy?.map(({ source }) => source)),
        ];

        return visibleSources
            .map((id) => ({ value: id, label: getEventTagSourceOrDefault(id) }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [eventHierarchy]);

    const campaignOptions = useMemo(() => {
        const visibleCampaigns = [
            ...new Map(
                eventHierarchy
                    ?.filter(
                        ({ source, campaign, campaign_name: campaignName }) =>
                            source === selectedSourceId &&
                            (campaign
                                .toLowerCase()
                                .includes(searchedCampaign.toLowerCase()) ||
                                campaignName
                                    ?.toLowerCase()
                                    .includes(searchedCampaign.toLowerCase())),
                    )
                    .map(({ campaign, campaign_name: campaignName }) => ({
                        id: campaign,
                        name: campaignName,
                    }))
                    .map((campaign) => [campaign.id, campaign]),
            ).values(),
        ];

        return visibleCampaigns
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(({ id, name }) => ({
                value: id,
                label: (
                    <Text>
                        {name && (
                            <>
                                <Text inline weight="bold">
                                    {name}
                                </Text>{' '}
                            </>
                        )}

                        <Text inline weight="base">
                            {id}
                        </Text>
                    </Text>
                ),
            }));
    }, [eventHierarchy, searchedCampaign, selectedSourceId]);

    const lineItemOptions = useMemo(() => {
        const visibleLineItems = [
            ...new Map(
                eventHierarchy
                    ?.filter(
                        ({
                            source,
                            campaign,
                            line_item: lineItemId,
                            line_item_name: lineItemName,
                        }) =>
                            source === selectedSourceId &&
                            campaign === selectedCampaignId &&
                            (lineItemId
                                .toLowerCase()
                                .includes(searchedLineItem.toLowerCase()) ||
                                lineItemName
                                    ?.toLowerCase()
                                    .includes(searchedLineItem.toLowerCase())),
                    )
                    .map(
                        ({
                            line_item: lineItemId,
                            line_item_name: lineItemName,
                        }) => ({
                            id: lineItemId,
                            name: lineItemName,
                        }),
                    )
                    .map((lineItem) => [lineItem.id, lineItem]),
            ).values(),
        ];

        return visibleLineItems
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(({ id, name }) => ({
                value: id,
                label: (
                    <>
                        {name && (
                            <>
                                <Text inline>{name}</Text>{' '}
                            </>
                        )}

                        <Text inline weight="base">
                            {id}
                        </Text>
                    </>
                ),
            }));
    }, [
        eventHierarchy,
        searchedLineItem,
        selectedCampaignId,
        selectedSourceId,
    ]);

    const { campaignLabel = 'Campaign', lineItemLabel = 'Line Item' } =
        eventTagSources.find(({ id }) => id === selectedSourceId) || {};

    const loadData = async () => {
        setError(false);
        setLoading(true);
        try {
            await onLoad();
            setLoading(false);
        } catch (error) {
            if (error instanceof AbortError) return;
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, [isVideo]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedSourceId(sourceOptions[0]?.value);
    }, [sourceOptions]);

    useEffect(() => {
        setSelectedCampaignId(campaignOptions[0]?.value);
        setSelectedLineItemIds([]);
    }, [campaignOptions]);

    return (
        <Drawer onClose={onClose}>
            <Layout
                header={
                    <Container size="medium">
                        <Box padding={['large', 0]}>
                            <Text size="huge">Connect Data Source</Text>
                        </Box>
                    </Container>
                }
                footer={
                    <DrawerFooter>
                        <Container size="medium">
                            <Box padding={['small', 0]}>
                                <ConnectButton
                                    disabled={!selectedCampaignId}
                                    onClick={() => onAddCampaign(selection)}
                                />
                            </Box>
                        </Container>
                    </DrawerFooter>
                }
                isFooterSticky
            >
                <Container size="medium">
                    {isLoading && (
                        <Spinner
                            size="large"
                            message="Loading"
                            color={['gray', 'dark']}
                            isCentered
                        />
                    )}

                    {!isLoading && hasError && (
                        <LoadingError onRetry={loadData} />
                    )}

                    {eventHierarchy?.length === 0 && <EmptyMessage />}

                    {eventHierarchy?.length > 0 && (
                        <>
                            <Box margin={[0, 0, 'large']}>
                                <Panel theme="tertiary">
                                    <Box padding="base">
                                        <Text color={['purple', 'darker']}>
                                            IDs will only appear in this screen
                                            once the 4D Tag has collected data
                                            for them
                                        </Text>
                                    </Box>
                                </Panel>
                            </Box>

                            <Box margin={['base', 0]}>
                                <Select
                                    label="Platform"
                                    width="same"
                                    options={sourceOptions}
                                    selectedValues={selectedSourceId}
                                    onChange={setSelectedSourceId}
                                />
                            </Box>

                            <Box margin={['base', 0]}>
                                <Select
                                    label={campaignLabel}
                                    width="same"
                                    options={campaignOptions}
                                    selectedValues={selectedCampaignId}
                                    emptyMessage={`
                                        There are no ${campaignLabel}s that match
                                        your search.
                                    `}
                                    onChange={setSelectedCampaignId}
                                    onSearch={setSearchedCampaign}
                                />
                            </Box>

                            <Box margin={['base', 0]}>
                                <Input
                                    label={`${lineItemLabel}s`}
                                    placeholder="Search by name or by ID"
                                    value={searchedLineItem}
                                    prefix={<FontAwesomeIcon icon={faSearch} />}
                                    onChange={(e) =>
                                        setSearchedLineItem(e.target.value)
                                    }
                                    onClear={() => setSearchedLineItem('')}
                                    focusOnShow
                                />
                            </Box>

                            <Box margin={['base', 0]}>
                                {lineItemOptions.length > 0 ? (
                                    <Checkbox
                                        options={[
                                            {
                                                label: `All ${campaignLabel} ${lineItemLabel}s`,
                                                value: '',
                                            },
                                            ...lineItemOptions,
                                        ]}
                                        selectedValues={selectedLineItemIds}
                                        onChange={setSelectedLineItemIds}
                                    />
                                ) : (
                                    <Alert theme="empty">
                                        <p>
                                            There are no {lineItemLabel}s that
                                            match your search.
                                        </p>
                                    </Alert>
                                )}
                            </Box>
                        </>
                    )}
                </Container>

                <Box
                    borderTop={['base', 'solid', 'gray', 'lightest']}
                    margin={['large', 0, 0]}
                >
                    <Container size="medium">
                        <Help />
                    </Container>
                </Box>
            </Layout>
        </Drawer>
    );
};

export default OptimizeCampaignDrawer;
