import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setDocumentTitle } from 'js/utils';
import discoverTabs from './enums/discover-tabs.enum';
import Discover from './discover';

const tabs = [
    {
        id: discoverTabs.FEATURE,
        label: 'Feature Release',
        to: '/discover/feature/',
    },
    {
        id: discoverTabs.VIDEO,
        label: 'Video Explore',
        to: '/discover/video/',
    },
    {
        id: discoverTabs.PAGE,
        label: 'Page Explore',
        to: '/discover/page/',
    },
];

const DiscoverPage = () => {
    const { tabId: currentTabId } = useParams();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const chunks = [
            'Discover',
            tabs.find(({ id }) => id === currentTabId).label,
        ];
        setDocumentTitle(chunks);
        setErrors({});
    }, [currentTabId, tabs]);

    return Discover({
        tabs: {
            list: tabs,
            current: tabs.find(({ id }) => id === currentTabId),
        },
        loading,
        errors,
        setLoading,
        setErrors,
    });
};

export default DiscoverPage;
