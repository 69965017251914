import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api, copyContext } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CopyButton from 'js/components/button/copy-button';
import EllipsisButton from 'js/components/button/ellipsis-button';
import CollapseDropdown from 'js/components/collapse-dropdown/collapse-dropdown';
import Divider from 'js/components/dropdown-menu/divider';
import DropdownButton from 'js/components/dropdown-menu/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Pill from 'js/components/pill/pill';
import LoadingSpinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import { showToast } from 'js/components/toast/toast';
import SVGImage from 'js/components/svg-image/svg-image';
import CardBase, { getRecentlyUpdated } from './card-base';
import CardWrapper from './components/card-wrapper';
import CardBaseTemplate from './components/card-base-template';
import CardSlimTemplate from './components/card-slim-template';
import InteractiveScale from './components/interactive-scale';
import DisplayPill from './images/display-pill.svg';
import styles from './card.module.scss';

const ContextPills = ({ context: { is_video: isVideo } }) => (
    <Pill theme="muted" outline>
        <div className={styles['pill-content']}>
            {isVideo ? (
                <FontAwesomeIcon icon={faPlay} />
            ) : (
                <SVGImage src={DisplayPill} />
            )}
            {isVideo ? 'Video' : 'Display'}
        </div>
    </Pill>
);

const DealContent = ({ loading, errors, integrations, onIntegration }) => {
    if (loading) {
        return <LoadingSpinner />;
    }
    if (errors) {
        return (
            <>
                <Text color={['red', 'dark']}>
                    There was an unexpected error <br />
                    loading deal IDs. Please try again in a<br /> few moments.
                </Text>
                <Box margin={['small', 0, 0, 0]}>
                    <Row>
                        <Col span="auto">
                            <Button
                                theme="outline"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onIntegration();
                                }}
                                type="button"
                            >
                                Retry
                            </Button>
                        </Col>
                    </Row>
                </Box>
            </>
        );
    }

    if (integrations.length <= 0) {
        return <Text>This context has no deal IDs</Text>;
    }

    return integrations.map((integration) => (
        <Box margin={[0, 0, 'small', 0]} key={integration.id}>
            <CollapseDropdown
                key={integration.id}
                label={
                    <img
                        className={styles['deal-logo']}
                        width="99px"
                        src={integration.logo}
                        alt={integration.name}
                    />
                }
                icons={[faChevronDown, faChevronUp]}
            >
                <Box
                    margin={['small', 'none', 'small', 'base']}
                    padding={['none', 'none', 'none', 'small']}
                    borderLeft={['thick', 'solid', 'gray', 'lightest']}
                >
                    {Object.values(integration.channels).map((channel) => (
                        <Fragment key={channel.id}>
                            <Text
                                weight="bolder"
                                color={['gray', 'darkest']}
                                inline
                            >
                                {channel.description}
                            </Text>
                            <Text
                                weight="base"
                                color={['gray', 'dark']}
                                inline
                                size="small"
                            >
                                {` (Seat ID ${channel.integration_key})`}
                            </Text>
                            <Box>
                                {channel.deals.map((deal) => (
                                    <Row
                                        gutter="small"
                                        alignItems="center"
                                        key={deal.id}
                                    >
                                        <Col span="auto">
                                            <Text
                                                weight="base"
                                                color={['gray', 'dark']}
                                                size="small"
                                                inline
                                            >
                                                {deal.deal_provider_type}
                                            </Text>{' '}
                                            {deal.deal_id ? (
                                                <Text
                                                    weight="bold"
                                                    inline
                                                    size="small"
                                                >
                                                    {deal.deal_id}
                                                </Text>
                                            ) : (
                                                <LoadingSpinner />
                                            )}
                                        </Col>
                                        {!!deal.deal_id && (
                                            <Col>
                                                <CopyButton
                                                    value={deal.deal_id}
                                                    message={
                                                        <Text
                                                            color={[
                                                                'gray',
                                                                'darker',
                                                            ]}
                                                        >
                                                            Deal ID Copied
                                                        </Text>
                                                    }
                                                >
                                                    <Button
                                                        square
                                                        size="small"
                                                        theme="outline"
                                                    >
                                                        <Text size="small">
                                                            <FontAwesomeIcon
                                                                icon={faCopy}
                                                            />
                                                        </Text>
                                                    </Button>
                                                </CopyButton>
                                            </Col>
                                        )}
                                    </Row>
                                ))}
                            </Box>
                        </Fragment>
                    ))}
                </Box>
            </CollapseDropdown>
        </Box>
    ));
};

const CardContext = ({
    isSlim,
    context,
    topline,
    openInNewWindow,
    hasDealID,
    onToggleFavorite,
    onDeleteContext,
}) => {
    const {
        scale,
        id: contextId,
        name: contextTitle,
        updated: contextDate,
        is_favourite: isContextFavorite,
        isFavoriteLoading: isContextFavoriteLoading,
    } = context;

    const contextTopline =
        topline ??
        (context.advertiser_name && context.group_name
            ? `${context.advertiser_name} - ${context.group_name}`
            : null);

    const updatedDate = getRecentlyUpdated(contextDate);
    const [deleteErrors, setDeleteErrors] = useState({});
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [isCopyingContext, setIsCopyingContext] = useState(false);
    const [channels, setChannels] = useState([]);
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const toggleDeleteWarningModal = () => {
        setDeleteWarning((state) => !state);
        setDeleteErrors({});
    };

    const onDeleteItem = async () => {
        setDeleteErrors({});
        try {
            await onDeleteContext(contextId);
            toggleDeleteWarningModal();
        } catch (err) {
            setDeleteErrors(err);
        }
    };

    const createContextCopy = async (ctxId) => {
        setIsCopyingContext(true);
        try {
            const currentContext = await api().contexts.retrieve(ctxId, {
                verbose: true,
            });

            copyContext(currentContext, { emptyCampaign: false });
        } catch (err) {
            setIsCopyingContext(false);
            showToast(
                'There Was An Unexpected Error',
                <p>
                    Context copying failed. Please try again in a few moments.
                </p>,
                null,
                'danger',
            );
        }
    };

    const getIntegrations = async () => {
        try {
            setLoading(true);
            const callbacks = [
                api().channels.list({ verbose: true, contexts: contextId }),
                api().deals.list({ verbose: true, contexts: contextId }),
            ];
            const [{ results: channelData }, { results: dealData }] =
                await Promise.all(callbacks);
            setChannels(channelData);
            setDeals(dealData);
            setLoading(false);
            setErrors(false);
        } catch {
            setErrors(true);
        }
    };

    const integrations = {};
    deals.forEach((deal) => {
        const channel = channels.find((c) => c.id === deal.channel);
        if (!channel) {
            return;
        }
        if (!Object.keys(integrations).includes(channel.integration)) {
            integrations[channel.integration] = {
                id: channel.integration,
                logo: channel.logo,
                name: channel.name,
                channels: {},
            };
        }
        if (
            !Object.keys(integrations[channel.integration].channels).includes(
                channel.id,
            )
        ) {
            integrations[channel.integration].channels[channel.id] = {
                id: channel.id,
                description: channel.description,
                integration_key: channel.integration_key,
                deals: [],
            };
        }
        integrations[channel.integration].channels[channel.id].deals.push({
            deal_id: deal.deal_id,
            deal_provider_type: deal.deal_provider_type,
            id: deal.id,
        });
    });

    const ellipsis = (
        <EllipsisButton
            size="small"
            onClick={!Object.values(integrations).length && getIntegrations}
        >
            {hasDealID && (
                <div>
                    <div className={styles['deal-scroll']}>
                        <Box padding={['small', 'base']}>
                            <Box margin={[0, 0, 'small', 0]}>
                                <Text weight="bolder" spacing="base">
                                    Deal IDs
                                </Text>
                            </Box>

                            <DealContent
                                errors={errors}
                                integrations={Object.values(integrations)}
                                loading={loading}
                                onIntegration={getIntegrations}
                            />
                        </Box>
                    </div>

                    <Divider />
                </div>
            )}

            {onToggleFavorite && (
                <DropdownButton onClick={onToggleFavorite}>
                    {isContextFavorite ? 'Remove from' : 'Add to'} Favorites
                </DropdownButton>
            )}

            <DropdownButton onClick={() => createContextCopy(contextId)}>
                Create Copy
            </DropdownButton>

            <Divider />

            <DropdownButton onClick={toggleDeleteWarningModal}>
                Delete
            </DropdownButton>
        </EllipsisButton>
    );

    const getInteractiveScale = (padding) => (
        <Box padding={padding}>
            <InteractiveScale scale={scale} />
        </Box>
    );

    return (
        <>
            {isCopyingContext && <LoadingLayer message="Copying Context" />}
            {deleteWarning && (
                <ConfirmModal
                    confirm="Yes, Delete Context"
                    cancel="No, Cancel"
                    loadingMessage="Deleting Context"
                    hasError={Object.keys(deleteErrors).length > 0}
                    errorMessage={`There was an unexpected error and the context was 
                        not deleted. Please try again in a few moments.`}
                    onCancel={toggleDeleteWarningModal}
                    onConfirm={onDeleteItem}
                >
                    <p>Deleting a context is permanent and cannot be undone.</p>
                </ConfirmModal>
            )}
            <CardWrapper
                to={contextId && `/contexts/${contextId}/`}
                title={contextTitle}
                openInNewWindow={openInNewWindow}
            >
                <CardBase type="full" isSlim={isSlim}>
                    {isSlim ? (
                        <CardSlimTemplate
                            scale={getInteractiveScale([
                                'none',
                                'small',
                                'none',
                                'none',
                            ])}
                            pill={<ContextPills context={context} />}
                            isFavorite={isContextFavorite}
                            isLoading={isContextFavoriteLoading}
                            ellipsis={
                                <Box
                                    padding={['none', 'none', 'none', 'small']}
                                >
                                    {ellipsis}
                                </Box>
                            }
                            title={contextTitle}
                            updatedDate={updatedDate}
                            onDelete={onDeleteContext}
                            onToggleFavorite={onToggleFavorite}
                            onToggleDeleteWarningModal={
                                toggleDeleteWarningModal
                            }
                        />
                    ) : (
                        <CardBaseTemplate
                            scale={getInteractiveScale([
                                'none',
                                'smaller',
                                'none',
                                'none',
                            ])}
                            topline={contextTopline}
                            pill={<ContextPills context={context} />}
                            isFavorite={isContextFavorite}
                            isLoading={isContextFavoriteLoading}
                            ellipsis={
                                <div className={styles['quick-actions']}>
                                    {ellipsis}
                                </div>
                            }
                            title={contextTitle}
                            updatedDate={updatedDate}
                            onDelete={onDeleteContext}
                            onToggleFavorite={onToggleFavorite}
                            onToggleDeleteWarningModal={
                                toggleDeleteWarningModal
                            }
                        />
                    )}
                </CardBase>
            </CardWrapper>
        </>
    );
};

CardContext.defaultProps = {
    topline: undefined,
    openInNewWindow: false,
    hasDealID: false,
    onToggleFavorite: undefined,
    onDeleteContext: undefined,
    isSlim: false,
};

CardContext.propTypes = {
    context: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.node.isRequired,
        advertiser_name: PropTypes.string,
        group_name: PropTypes.string,
        updated: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        scale: PropTypes.number,
        is_video: PropTypes.bool,
        is_favourite: PropTypes.bool,
    }).isRequired,
    topline: PropTypes.node,
    openInNewWindow: PropTypes.bool,
    hasDealID: PropTypes.bool,
    onToggleFavorite: PropTypes.func,
    onDeleteContext: PropTypes.func,
    isSlim: PropTypes.bool,
};

export default CardContext;
