import React, { useState } from 'react';
import Collapse from 'js/components/collapse/collapse';
import PropTypes from 'prop-types';
import TitleBar from './title-bar';

const HelpDropdownSubsection = ({ title, children }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <TitleBar isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
                <h3>{title}</h3>
            </TitleBar>
            <Collapse isOpen={isOpen}>{children}</Collapse>
        </>
    );
};

HelpDropdownSubsection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default HelpDropdownSubsection;
