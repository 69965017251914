/* eslint-disable react/jsx-props-no-spreading */

import React, { useLayoutEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import filterTypes from 'js/enums/filter-types.enum';
import permissionGroups from 'js/enums/permission-groups.enum';
import sortTypes from 'js/enums/sort-types.enum';
import { sortOptions } from 'js/constants';
import { api, getDisplayName, getJwtPayload, getUpdatedList } from 'js/utils';

const filterOptionsMap = {
    [filterTypes.ORDERING]: sortOptions,
};

const withCampaignList = (Wrapped) => {
    function WithCampaignList(props) {
        const [loading, setLoading] = useState(true);
        const [errors, setErrors] = useState({});
        const [advertiser, setAdvertiser] = useState({});
        const [campaigns, setCampaigns] = useState([]);
        const [ordering, setOrdering] = useState(sortTypes.UPDATED_DESC);
        const [togglingFavoriteError, setTogglingFavoriteError] = useState({});

        const history = useHistory();

        const { advertiserId } = props.match.params; // eslint-disable-line react/destructuring-assignment

        const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
        const isViewOnlyUser =
            !jwt.is_superuser && jwt.roles.includes(permissionGroups.VIEW_ONLY);

        const initFilterParams = () => {
            const filterParams = Object.fromEntries(
                new URLSearchParams(history.location.search).entries(),
            );
            const selectedOrdering =
                filterParams[filterTypes.ORDERING] || sortTypes.UPDATED_DESC;
            setOrdering(selectedOrdering);

            return filterParams;
        };

        const loadCampaignsData = async () => {
            const filterParams = initFilterParams();
            delete filterParams.search;

            const callbacks = [
                api().contextGroups.list({
                    advertiser: advertiserId,
                    ordering: '-deep_updated',
                    is_public: false,
                    verbose: true,
                    ...filterParams,
                }),
            ];

            if (!advertiser.id) {
                callbacks.push(
                    api().advertisers.retrieve(advertiserId, {
                        verbose: true,
                    }),
                );
            }

            const [{ results }, advertiserData] = await Promise.all(callbacks);

            if (advertiserData) {
                setAdvertiser(advertiserData);
            }

            setCampaigns(results);
        };

        const loadData = async () => {
            setLoading(true);
            setErrors({});

            try {
                await loadCampaignsData();
            } catch (err) {
                setErrors({
                    ...errors,
                    loadingFailed: 'Failed to load',
                });
            } finally {
                setLoading(false);
            }
        };

        const toggleFavoriteAdvertiser = async () => {
            const isFavorite = !advertiser.is_favourite;

            setAdvertiser((prevState) => ({
                ...prevState,
                isFavoriteLoading: true,
            }));

            let success = false;
            setTogglingFavoriteError({});
            try {
                await api().advertisers.favorite(advertiser.id, isFavorite);
                success = true;
            } catch (err) {
                setTogglingFavoriteError({
                    title: advertiser.name,
                    isFavorite,
                });
            } finally {
                setAdvertiser((prevState) => ({
                    ...prevState,
                    is_favourite: success ? isFavorite : !isFavorite,
                    isFavoriteLoading: false,
                }));
            }
        };

        const toggleFavoriteCampaign = async (campaignId) => {
            const campaign = campaigns.find((item) => item.id === campaignId);
            const isFavorite = !campaign.is_favourite;

            setCampaigns((prevState) =>
                getUpdatedList(prevState, campaignId, {
                    isFavoriteLoading: true,
                }),
            );

            let success = false;
            setTogglingFavoriteError({});
            try {
                await api().contextGroups.favorite(campaignId, isFavorite);
                success = true;
            } catch (err) {
                setTogglingFavoriteError({
                    title: campaign.name,
                    isFavorite,
                });
            } finally {
                setCampaigns((prevState) =>
                    getUpdatedList(prevState, campaignId, {
                        is_favourite: success ? isFavorite : !isFavorite,
                        isFavoriteLoading: false,
                    }),
                );
            }
        };

        const updateFilter = async (filterType, filterValue) => {
            const params = new URLSearchParams(history.location.search);
            const defaultValue = filterOptionsMap[filterType][0].value;

            if (filterValue === defaultValue) {
                params.delete(filterType);
            } else {
                params.set(filterType, filterValue);
            }

            await history.replace({
                ...history.location,
                search: params.toString(),
            });

            await loadData();
        };

        const deleteCampaign = async (campaignId) => {
            await api().contextGroups.destroy(campaignId);
            await loadCampaignsData();
        };

        useLayoutEffect(() => {
            loadData();

            return () => {
                api().abortAll();
            };
        }, [advertiserId]); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <Wrapped
                {...props}
                loading={loading}
                errors={errors}
                readOnly={isViewOnlyUser}
                advertiser={advertiser}
                campaigns={campaigns}
                ordering={ordering}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavoriteCampaign={toggleFavoriteCampaign}
                onToggleFavoriteAdvertiser={toggleFavoriteAdvertiser}
                onUpdateFilter={updateFilter}
                onDeleteCampaign={deleteCampaign}
            />
        );
    }

    WithCampaignList.displayName = `withCampaignList(${getDisplayName(
        Wrapped,
    )})`;

    return withRouter(WithCampaignList);
};

export default withCampaignList;
