import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Panel from 'js/components/panel/panel';
import SpotlightCarousel from 'js/components/spotlight-carousel/spotlight-carousel';
import Text from 'js/components/text/text';
import React, { useState } from 'react';
import { getJwtPayload } from 'js/utils';
import pageSections from '../../enums/page-sections.enum';
import SectionWrapper from '../section-wrapper/section-wrapper';
import styles from './spotlight.module.scss';

export default function Spotlight({
    sectionTitle,
    features,
    pageLayout,
    onSavePageLayout,
}) {
    const featureSlides = features
        .filter((item) => item.is_spotlight)
        .map(({ description, name, spotlight_image: slideImage }) => ({
            src: slideImage,
            description: {
                slideType: (
                    <Text
                        weight="bolder"
                        size="larger"
                        color={['purple', 'base']}
                    >
                        Feature Release
                    </Text>
                ),
                name,
                text: description,
                action: (
                    <Button
                        to={`/discover/feature/?selected=${encodeURIComponent(
                            name,
                        )}`}
                    >
                        View Feature
                    </Button>
                ),
            },
        }));

    const seatSpecificSectionTitle =
        getJwtPayload(
            localStorage.getItem('AuthToken'),
        ).account_name?.toLowerCase() === 'silverpush'
            ? 'What’s New in Mirrors'
            : sectionTitle;

    const spotlightSection = pageLayout.layout_sections.find(
        (layout) => layout.section === pageSections.SPOTLIGHT,
    );
    const spotlightIndex = pageLayout.layout_sections.indexOf(spotlightSection);

    const [showCollapse, setShowCollapse] = useState(
        !spotlightSection.is_collapsed,
    );

    const toggleCollapse = () => {
        setShowCollapse((prevState) => !prevState);
        spotlightSection.is_collapsed = !spotlightSection.is_collapsed;
        const layoutSections = [...pageLayout.layout_sections];
        layoutSections[spotlightIndex] = spotlightSection;
        onSavePageLayout(layoutSections);
    };

    return (
        <SectionWrapper
            title={seatSpecificSectionTitle}
            isOpen={showCollapse}
            toggleCollapse={toggleCollapse}
        >
            <Panel bordered>
                <Box padding="base">
                    <div className={styles['carousel-wrapper']}>
                        <SpotlightCarousel slides={featureSlides} />
                    </div>
                </Box>
            </Panel>
        </SectionWrapper>
    );
}
