import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Number from 'js/components/number/number';
import Text from 'js/components/text/text';
import styles from './sparkline.module.scss';

const formats = [
    { target: 1e6, symbol: 'm', maxFractionDigits: 2 },
    {
        target: 1e4,
        appliedValue: 1e3,
        symbol: 'k',
        maxFractionDigits: 0,
    },
    {
        target: 1e3,
        appliedValue: 1e3,
        symbol: 'k',
        maxFractionDigits: 2,
    },
    {
        target: 1,
        appliedValue: 1,
        symbol: '',
        maxFractionDigits: 0,
    },
];

function Percentage({ value, comparedValue, isDelta, theme }) {
    let ratio = isDelta ? value / comparedValue - 1 : value / comparedValue;
    const percentageDelta = (ratio * 100).toFixed(2);
    let color = ['gray', 'dark'];

    if (!isDelta) {
        // empty
    } else if (percentageDelta > 0) {
        if (theme === 'colorful') {
            color = ['aqua', 'darker'];
        }
    } else if (percentageDelta < 0) {
        if (theme === 'colorful') {
            color = ['red', 'dark'];
        }
    } else {
        // Make zero value positive
        ratio = Math.abs(ratio);
    }

    return (
        <Text inline size="small" color={color}>
            (
            {parseFloat((ratio * 100).toFixed(2)) === 0 && isDelta ? (
                'Equal'
            ) : (
                <Number
                    value={ratio}
                    maximumFractionDigits={2}
                    isPercentage
                    signDisplay={isDelta ? 'always' : 'auto'}
                />
            )}
            )
        </Text>
    );
}

function Bar({ value, max, color }) {
    const classes = [styles['bar-value']];
    if (color) {
        classes.push(styles[`color--${color[0]}-${color[1]}`]);
    }
    return (
        <div className={styles.bar}>
            <div
                className={classNames(classes)}
                style={{ width: `${(value / max) * 100}%` }}
            />
        </div>
    );
}

function Sparkline({
    value,
    comparedValue,
    isDelta,
    maxValue,
    noLabel,
    theme,
    color,
}) {
    return (
        <div>
            {!noLabel && (
                <div className={styles.value}>
                    <Text inline>
                        {isDelta ? (
                            <Number
                                value={value}
                                isPercentage
                                maximumFractionDigits={2}
                            />
                        ) : (
                            <Number
                                value={value}
                                abbreviationFormats={formats}
                            />
                        )}
                    </Text>{' '}
                    <Percentage
                        value={value}
                        comparedValue={comparedValue}
                        isDelta={isDelta}
                        theme={theme}
                    />
                </div>
            )}

            {maxValue && <Bar value={value} max={maxValue} color={color} />}
        </div>
    );
}

Sparkline.defaultProps = {
    comparedValue: undefined,
    isDelta: false,
    maxValue: undefined,
    noLabel: false,
    theme: undefined,
    color: ['gray', 'base'],
};

Sparkline.propTypes = {
    value: PropTypes.number.isRequired,
    comparedValue: PropTypes.number,
    isDelta: PropTypes.bool,
    noLabel: PropTypes.bool,
    maxValue: PropTypes.number,
    theme: PropTypes.oneOf(['colorful']),
    color: PropTypes.arrayOf(
        PropTypes.oneOf([
            'purple',
            'red',
            'yellow',
            'aqua',
            'gray',
            'blue',
            'black',
            'darkest',
            'darker',
            'dark',
            'base',
            'light',
            'lighter',
            'lightest',
            'background',
            'white',
        ]),
    ),
};

export default Sparkline;
