import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Scrollable from 'js/components/scrollable/scrollable';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import logo from 'media/images/logos/logo.svg';
import styles from './terms-and-conditions.module.scss';

const cx = classNames.bind(styles);

const Wrapper = ({ children, scrollableRef, buttonBar, onBottom }) => (
    <Layout alignContent="center">
        <Container size="small">
            <Box padding={['large', 0]}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <Scrollable
                            ref={scrollableRef}
                            hideScrollbar
                            focusOnShow
                            onBottom={onBottom}
                        >
                            <Box padding="large">
                                <Header />

                                {children}
                            </Box>
                        </Scrollable>
                    </div>

                    {buttonBar}
                </div>
            </Box>
        </Container>
    </Layout>
);

const Header = () => (
    <Box margin={[0, 0, 'large']}>
        <img className={styles.logo} src={logo} alt="4D" />
        <Text weight="bolder" size="largest">
            <h1>Terms &amp; Conditions</h1>
        </Text>
        <Box padding={['smaller', 0, 0]}>
            <Text color={['gray', 'dark']}>
                Before using 4D you will need to agree to the Terms &amp;
                Conditions of the platform.
            </Text>
        </Box>
    </Box>
);

const LoadingErrorMessage = ({ onReload }) => (
    <Alert
        theme="danger"
        actionItems={<Button onClick={onReload}>Retry</Button>}
    >
        <p>
            There was an unexpected error while loading the terms &amp;
            conditions. Please try again in a few moments.
        </p>
    </Alert>
);

const SavingErrorMessage = () => (
    <Box margin={[0, 0, 'small']}>
        <Alert theme="danger">
            <p>
                There was an unexpected error while agreeing to the terms &amp;
                conditions. Please try again in a few moments.
            </p>
        </Alert>
    </Box>
);

export default function TermsAndConditions({
    loading,
    loadingError,
    saving,
    savingError,
    onAccept,
    onReload,
    children,
}) {
    const [isBottom, setIsBottom] = useState(false);
    const acceptButtonRef = useRef();
    const scrollableRef = useRef();

    const buttonBarClasses = cx({
        buttonBar: true,
        isBottom: !loading && isBottom,
    });

    const scrollDown = () => {
        const scrollable = scrollableRef.current;
        scrollable.scrollTop += scrollable.scrollHeight;
    };

    useEffect(() => {
        if (!loading && isBottom && acceptButtonRef.current) {
            acceptButtonRef.current.focus();
        }
    }, [loading, isBottom]);

    if (loading) {
        return (
            <Wrapper scrollableRef={scrollableRef} onBottom={setIsBottom}>
                <Spinner size="large" color={['gray', 'dark']} isCentered />
            </Wrapper>
        );
    }

    if (loadingError) {
        return (
            <Wrapper scrollableRef={scrollableRef} onBottom={setIsBottom}>
                <LoadingErrorMessage onReload={onReload} />
            </Wrapper>
        );
    }

    return (
        <>
            {saving ? <LoadingLayer message="Saving" /> : null}

            <Wrapper
                scrollableRef={scrollableRef}
                buttonBar={
                    <div className={classNames(buttonBarClasses)}>
                        {savingError && <SavingErrorMessage />}

                        <div className={styles.scrollDownButton}>
                            <Button square onClick={scrollDown}>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </Button>
                        </div>

                        <div className={styles.acceptButton}>
                            <Button
                                disabled={!isBottom}
                                ref={acceptButtonRef}
                                onClick={onAccept}
                            >
                                Agree
                            </Button>
                        </div>
                    </div>
                }
                onBottom={setIsBottom}
            >
                {children}
            </Wrapper>
        </>
    );
}
