import React, { useContext } from 'react';
import Box from 'js/components/box/box';
import { MATCHED_PAGES_REGION } from '../../../constants';
import ContextDetailContext from '../../../contexts/context-detail.context';
import PageSection from '../../page-section/page-section';
import styles from '../matched-items-section.module.scss';
import MatchedPages from './matched-pages';
import ReloadNotice from '../reload-notice';
import RegionFilter from '../region-filter';

export default function MatchedPagesSection() {
    const {
        matchedPages: {
            load: getMatchedPages,
            needsReload: needsMatchedPagesReload,
            region,
            setRegion,
        },
    } = useContext(ContextDetailContext);

    const updateRegion = (r) => {
        setRegion(r);
        localStorage.setItem(MATCHED_PAGES_REGION, r);
    };

    return (
        <div className={styles['matched-pages-section']}>
            <PageSection
                title="Matched Pages"
                description="These are the pages that have the strongest
                match to this context. You can use them to verify
                or improve the accuracy of this context."
                extension={[
                    <RegionFilter value={region} onChange={updateRegion} />,
                ]}
            >
                <Box padding={['base', 0, 0, 0]}>
                    {needsMatchedPagesReload && (
                        <div className={styles.notices}>
                            <ReloadNotice
                                onReload={() => {
                                    getMatchedPages();
                                }}
                            />
                        </div>
                    )}

                    <MatchedPages />
                </Box>
            </PageSection>
        </div>
    );
}
