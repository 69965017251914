import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Modal from 'js/components/modal/modal';
import { withAdvertiserDetail } from 'js/hocs';
import { AdvertiserDetailContext } from 'js/contexts';
import AdvertiserForm from './form';

function AdvertiserModal({ title, onSave, onClose }) {
    const {
        advertiser: {
            current: advertiser,
            save: saveAdvertiser,
            saving,
            setName,
            setLogo,
        },
        errors: { save: saveErrors },
    } = useContext(AdvertiserDetailContext);

    const handleSave = async () => {
        try {
            const newAdvertiser = await saveAdvertiser();
            if (onSave) onSave(newAdvertiser);
        } catch {} // eslint-disable-line no-empty
    };

    return (
        <>
            <Modal title={title} onClose={onClose}>
                <AdvertiserForm
                    isModal
                    advertiser={advertiser}
                    saving={saving}
                    saveErrors={saveErrors}
                    onNameChange={setName}
                    onLogoChange={setLogo}
                    onSave={handleSave}
                />
            </Modal>
            {saving && (
                <LoadingLayer
                    message={`${
                        advertiser.id ? 'Saving' : 'Creating'
                    } Advertiser`}
                />
            )}
        </>
    );
}

AdvertiserModal.defaultProps = {
    title: undefined,
    onSave: undefined,
    onClose: undefined,
};

AdvertiserModal.propTypes = {
    title: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default withAdvertiserDetail(AdvertiserModal);
