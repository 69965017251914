import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardCampaign from 'js/components/card/card-campaign';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Label from 'js/components/label/label';
import Panel from 'js/components/panel/panel';
import Section from 'js/components/section/section';
import Select from 'js/components/select/select';
import Text from 'js/components/text/text';
import AdvertiserModal from 'js/components/advertiser/modal';

const FormErrorContent = ({ saveErrors, isNew }) => {
    const action = isNew ? 'create' : 'update';

    if (saveErrors.name || saveErrors.advertiser) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You can&apos;t {action} a campaign at the moment because
                        there are problems that have been highlighted in the
                        form above.
                    </p>
                </Alert>
            </Box>
        );
    }

    if (Object.keys(saveErrors).length) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You were unable to {action} the campaign because there
                        was an unexpected error. Please try again in a few
                        moments.
                    </p>
                </Alert>
            </Box>
        );
    }

    return null;
};

const FormContent = ({
    campaign,
    advertisers,
    preset,
    saveErrors,
    onNameChange,
    onAdvertiserChange,
    onAdvertiserCreate,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [advertiserModal, setAdvertiserModal] = useState(false);

    const firstAdvertiserId = advertisers.length > 0 ? advertisers[0].id : null;

    const advertiser = advertisers.find(
        (item) =>
            item.id ===
            (preset.advertiser || campaign.advertiser || firstAdvertiserId),
    );

    const advertiserOptions = advertisers.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    const visibleAdvertiserOptions = advertiserOptions.filter(({ label }) =>
        label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const toggleAdvertiserModal = () => {
        setAdvertiserModal((state) => !state);
    };

    useEffect(() => {
        if (!campaign.advertiser && advertiser) {
            onAdvertiserChange(advertiser.id);
        }
    }, [advertiser, campaign.advertiser, onAdvertiserChange]);

    return (
        <>
            <Section hasBorderBottom>
                <Box margin={[0, 0, 'base']}>
                    {preset.name ? (
                        <Label label="Name">
                            <Text size="large" weight="bolder">
                                {campaign.name}
                            </Text>
                        </Label>
                    ) : (
                        <Input
                            focusOnShow
                            maxLength={50}
                            required
                            label="Name"
                            value={campaign.name || ''}
                            onChange={(evt) => onNameChange(evt.target.value)}
                            hasError={!!saveErrors.name}
                            errorMessage={<p>{saveErrors.name}</p>}
                        />
                    )}
                </Box>

                <Box margin={[0, 0, 'base']}>
                    {preset.advertiser ? (
                        <Label label="Advertiser">
                            <Text size="large" weight="bolder">
                                {advertiser.name}
                            </Text>
                        </Label>
                    ) : (
                        <Label
                            label="Advertiser"
                            required
                            hasError={!!saveErrors.advertiser}
                            errorMessage={<p>{saveErrors.advertiser}</p>}
                        >
                            <Row
                                justifyContent="space-between"
                                alignItems="center"
                                gutter="small"
                            >
                                {advertisers.length > 0 ? (
                                    <>
                                        <Col>
                                            <Select
                                                width="same"
                                                options={
                                                    visibleAdvertiserOptions
                                                }
                                                selectedValues={
                                                    advertiser.id ||
                                                    firstAdvertiserId // take first if advertiser id not in list
                                                }
                                                onChange={onAdvertiserChange}
                                                onSearch={
                                                    advertiserOptions.length > 5
                                                        ? setSearchTerm
                                                        : null
                                                }
                                            />
                                        </Col>

                                        <Col span="auto">or</Col>
                                    </>
                                ) : null}

                                <Col span="auto">
                                    <Button
                                        theme="outline"
                                        onClick={toggleAdvertiserModal}
                                    >
                                        Create Advertiser
                                    </Button>
                                </Col>
                            </Row>
                        </Label>
                    )}
                </Box>
            </Section>

            <Box margin={['base', 0, 'large']}>
                <Label label="Card Preview">
                    <CardCampaign
                        campaign={{
                            ...campaign,
                            advertiser: advertiser?.id,
                            advertiser_name: advertiser?.name,
                        }}
                        isPreview
                    />
                </Label>
            </Box>

            {advertiserModal && (
                <AdvertiserModal
                    title="Create Advertiser"
                    onSave={(newAdvertiser) => {
                        onAdvertiserCreate(newAdvertiser);
                        onAdvertiserChange(newAdvertiser.id);
                        toggleAdvertiserModal();
                    }}
                    onClose={toggleAdvertiserModal}
                />
            )}
        </>
    );
};

const FormWrapper = ({ isModal, children }) =>
    isModal ? (
        children
    ) : (
        <Box padding={['large', 0]}>
            <Container size="small">
                <Form>
                    <Panel bordered>
                        <Box padding="base">{children}</Box>
                    </Panel>
                </Form>
            </Container>
        </Box>
    );

function CampaignForm({
    campaign,
    preset,
    advertisers,
    saving,
    saveErrors,
    onNameChange,
    onAdvertiserChange,
    onAdvertiserCreate,
    onSave,
    isModal,
}) {
    return (
        <FormWrapper isModal={isModal}>
            <FormContent
                campaign={campaign}
                advertisers={advertisers}
                preset={preset}
                saveErrors={saveErrors}
                onNameChange={onNameChange}
                onAdvertiserChange={onAdvertiserChange}
                onAdvertiserCreate={onAdvertiserCreate}
            />

            <FormErrorContent saveErrors={saveErrors} isNew={!campaign.id} />

            <Row justifyContent="flex-end">
                <Col span="auto">
                    <Button
                        type="submit"
                        disabled={saving || Object.keys(saveErrors).length > 0}
                        onClick={onSave}
                    >
                        {campaign.id ? 'Save' : 'Create Campaign'}
                    </Button>
                </Col>
            </Row>
        </FormWrapper>
    );
}

CampaignForm.defaultProps = {
    advertisers: [],
    saving: false,
    isModal: false,
};

CampaignForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    preset: PropTypes.object.isRequired,
    advertisers: PropTypes.arrayOf(PropTypes.object),
    saving: PropTypes.bool,
    saveErrors: PropTypes.object.isRequired,
    onNameChange: PropTypes.func.isRequired,
    onAdvertiserChange: PropTypes.func.isRequired,
    onAdvertiserCreate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isModal: PropTypes.bool,
};

export default CampaignForm;
