import React from 'react';
import { MIN_PASSWORD_LENGTH } from 'js/constants';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Text from 'js/components/text/text';

function FormErrors({ errors }) {
    if (errors.new_password1 || errors.new_password2) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You can&apos;t be set a new password at the moment
                        because there are problems that have been highlighted in
                        the form above.
                    </p>
                </Alert>
            </Box>
        );
    }

    if (Object.keys(errors).length) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You did not set a new password because there was an
                        unexpected error. Please try again in a few moments.
                    </p>
                </Alert>
            </Box>
        );
    }

    return null;
}

function InvalidLinkContent() {
    return (
        <Alert
            theme="danger"
            title="Your Password Reset Link Has Expired"
            actionItems={
                <Button to="/password-reset/">Reset Your Password</Button>
            }
        >
            <p>
                Password reset links expire to help make sure no one gains
                access to your account when they should not.
            </p>
            <p>
                If you still need to reset your password then you can start the
                process again by clicking the button below.
            </p>
        </Alert>
    );
}

function PasswordForm({
    password,
    isNewUser,
    errors,
    setPassword,
    setPasswordConfirmation,
    updatePassword,
}) {
    const title = isNewUser ? 'Welcome to 4D' : 'Set A New Password';

    return (
        <Form>
            <Text size="largest" weight="bolder">
                <h1>{title}</h1>
            </Text>

            {isNewUser && (
                <Box margin={['large', 0]}>
                    <Text
                        size="larger"
                        color={['gray', 'dark']}
                        lineHeight="large"
                    >
                        <p>
                            Your account doesn&apos;t have a password yet. You
                            will need to set one before you can login.
                        </p>
                    </Text>
                </Box>
            )}

            <Box margin={['large', 0]}>
                <Input
                    focusOnShow
                    type="password"
                    label="Your New Password"
                    maxLength={128}
                    hint={
                        <p>
                            Your new password must be at least{' '}
                            {MIN_PASSWORD_LENGTH} characters long. It is
                            currently <strong>{password.length}</strong>{' '}
                            {password.length === 1 ? 'character' : 'characters'}{' '}
                            long{' '}
                            {password.length < MIN_PASSWORD_LENGTH
                                ? '👎'
                                : '👍'}
                        </p>
                    }
                    onChange={(evt) => setPassword(evt.target.value)}
                    hasError={!!errors.new_password1}
                    errorMessage={<p>{errors.new_password1}</p>}
                />
            </Box>

            <Box margin={['large', 0]}>
                <Input
                    type="password"
                    label="Confirm Your New Password"
                    maxLength={128}
                    onChange={(evt) =>
                        setPasswordConfirmation(evt.target.value)
                    }
                    hasError={!!errors.new_password2}
                    errorMessage={<p>{errors.new_password2}</p>}
                />
            </Box>

            <FormErrors errors={errors} />

            <Row justifyContent="flex-end">
                <Col span="auto">
                    <Button
                        disabled={
                            !!errors.new_password1 || !!errors.new_password2
                        }
                        type="submit"
                        onClick={updatePassword}
                    >
                        Set Your Password And Login
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

function SetPassword({
    password,
    errors,
    isNewUser,
    isLoading,
    isLinkInvalid,
    setPassword,
    setPasswordConfirmation,
    updatePassword,
}) {
    return (
        <Layout alignContent="center">
            {isLoading ? <LoadingLayer message="Setting Password" /> : null}

            <Container size="small">
                <Box
                    margin={['larger', 0]}
                    padding="large"
                    background={['gray', 'white']}
                    borderRadius="rounder"
                    shadow="deep"
                >
                    {isLinkInvalid ? (
                        <InvalidLinkContent />
                    ) : (
                        <PasswordForm
                            password={password}
                            isNewUser={isNewUser}
                            errors={errors}
                            setPassword={setPassword}
                            setPasswordConfirmation={setPasswordConfirmation}
                            updatePassword={updatePassword}
                        />
                    )}
                </Box>
            </Container>
        </Layout>
    );
}

export default SetPassword;
