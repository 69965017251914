import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Text from 'js/components/text/text';
import styles from './date-picker.module.scss';

function DatePicker({
    maxDate,
    minDate,
    rangeDate,
    selectedDate,
    hideUnavailableDates,
    onDateSelected,
}) {
    const maxDateMoment = useMemo(() => moment(maxDate), [maxDate]);
    const minDateMoment = useMemo(() => moment(minDate), [minDate]);
    const rangeDateMoment = useMemo(() => moment(rangeDate), [rangeDate]);
    const selectedDateMoment = useMemo(
        () => moment(selectedDate),
        [selectedDate],
    );

    const [calendarDays, setCalendarDays] = useState([]);
    const [visibleDate, setVisibleDate] = useState(
        selectedDateMoment.clone().local().startOf('month'),
    );
    const [nextMonthIsAccessible, setNextMonthIsAccessible] = useState(true);
    const [previousMonthIsAccessible, setPreviousMonthIsAccessible] =
        useState(true);

    const setSelectedDate = (dateObject) => {
        setVisibleDate(dateObject.clone());
        onDateSelected(dateObject.toDate());
    };

    const viewNextMonth = () => {
        if (!maxDate || !maxDateMoment.isSame(visibleDate, 'month')) {
            setVisibleDate(visibleDate.clone().add(1, 'month'));
        }
    };

    const viewPreviousMonth = () => {
        if (!minDate || !minDateMoment.isSame(visibleDate, 'month')) {
            setVisibleDate(visibleDate.clone().subtract(1, 'month'));
        }
    };

    useEffect(() => {
        const constructDay = (dateObject) => {
            const day = {
                classes: [],
                dateObject: dateObject.clone(),
                formattedDay: dateObject.format('D'),
            };

            if (dateObject.isSame(selectedDateMoment, 'day')) {
                day.classes.push(styles.selection);

                if (
                    rangeDate &&
                    selectedDateMoment.isBefore(rangeDateMoment, 'day')
                ) {
                    day.classes.push(styles['selection-start']);
                }

                if (
                    rangeDate &&
                    selectedDateMoment.isAfter(rangeDateMoment, 'day')
                ) {
                    day.classes.push(styles['selection-end']);
                }
            }

            if (rangeDate && dateObject.isSame(rangeDateMoment, 'day')) {
                day.classes.push(styles['end-of-range']);

                if (selectedDateMoment.isAfter(rangeDateMoment, 'day')) {
                    day.classes.push(styles['range-start']);
                }

                if (selectedDateMoment.isBefore(rangeDateMoment, 'day')) {
                    day.classes.push(styles['range-end']);
                }
            }

            if (!dateObject.isSame(visibleDate, 'month')) {
                day.classes.push(styles.muted);
            }

            if (
                rangeDate &&
                ((selectedDateMoment.isAfter(rangeDateMoment, 'day') &&
                    dateObject.isBefore(selectedDateMoment, 'day') &&
                    dateObject.isAfter(rangeDateMoment, 'day')) ||
                    (selectedDateMoment.isBefore(rangeDateMoment, 'day') &&
                        dateObject.isAfter(selectedDateMoment, 'day') &&
                        dateObject.isBefore(rangeDateMoment, 'day')))
            ) {
                day.classes.push(styles['in-range']);
            }

            if (
                maxDate !== undefined &&
                dateObject.isAfter(maxDateMoment, 'day')
            ) {
                day.classes.push(
                    hideUnavailableDates ? styles.hidden : styles.disabled,
                );
            }

            if (
                minDate !== undefined &&
                dateObject.isBefore(minDateMoment, 'day')
            ) {
                day.classes.push(
                    hideUnavailableDates ? styles.hidden : styles.disabled,
                );
            }

            return day;
        };

        const newCalendarDays = [[]];
        const startOfVisibleMonth = visibleDate.clone().startOf('month');
        const endOfVisibleMonth = visibleDate.clone().endOf('month');
        const dayOfWeekOfStartOfMonth = startOfVisibleMonth.isoWeekday();
        /*
            We want our initial `currentDay` to be the Monday of the week
            that the first day of the month is in.
        */
        const currentDay = startOfVisibleMonth
            .clone()
            .subtract(dayOfWeekOfStartOfMonth - 1, 'days');
        const isBeforeEndOfMonth = () => currentDay.isBefore(endOfVisibleMonth);
        const currentWeekIsComplete = () =>
            newCalendarDays[newCalendarDays.length - 1].length === 7;

        while (isBeforeEndOfMonth() || !currentWeekIsComplete()) {
            newCalendarDays[newCalendarDays.length - 1].push(
                constructDay(currentDay.clone()),
            );
            currentDay.add(1, 'day');
            if (isBeforeEndOfMonth() && currentWeekIsComplete()) {
                newCalendarDays.push([]);
            }
        }
        setCalendarDays(newCalendarDays);
    }, [
        maxDate,
        maxDateMoment,
        minDate,
        minDateMoment,
        rangeDate,
        rangeDateMoment,
        selectedDateMoment,
        visibleDate,
        hideUnavailableDates,
    ]);

    useEffect(() => {
        setNextMonthIsAccessible(
            maxDate === undefined ||
                !visibleDate
                    .clone()
                    .add(1, 'month')
                    .startOf('month')
                    .isAfter(maxDateMoment, 'day'),
        );
    }, [maxDate, maxDateMoment, visibleDate]);

    useEffect(() => {
        setPreviousMonthIsAccessible(
            minDate === undefined ||
                !visibleDate
                    .clone()
                    .subtract(1, 'month')
                    .endOf('month')
                    .isBefore(minDateMoment, 'day'),
        );
    }, [minDate, minDateMoment, visibleDate]);

    useEffect(() => {
        /*
            We want to make it so that the visible month is the month that the date
            has just been changed to if the date is externally changed.
        */
        if (selectedDateMoment.isSame(rangeDateMoment, 'day')) {
            setVisibleDate(selectedDateMoment.clone());
        }
    }, [rangeDateMoment, selectedDateMoment]);

    return (
        <div className={styles.container}>
            <div className={styles['month-container']}>
                <button
                    className={styles.button}
                    type="button"
                    hidden={!previousMonthIsAccessible && hideUnavailableDates}
                    disabled={!previousMonthIsAccessible}
                    onClick={viewPreviousMonth}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <Text weight="bold">{visibleDate.format('MMM YYYY')}</Text>

                <button
                    className={styles.button}
                    type="button"
                    hidden={!nextMonthIsAccessible && hideUnavailableDates}
                    disabled={!nextMonthIsAccessible}
                    onClick={viewNextMonth}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>

            <div className={styles.calendar}>
                <table>
                    <thead>
                        <tr>
                            <th>Mo</th>
                            <th>Tu</th>
                            <th>We</th>
                            <th>Th</th>
                            <th>Fr</th>
                            <th>Sa</th>
                            <th>Su</th>
                        </tr>
                    </thead>

                    <tbody>
                        {calendarDays.map((week, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <tr key={index}>
                                {week.map((day) => (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                                    <td
                                        className={classNames(day.classes)}
                                        key={day.dateObject.dayOfYear()}
                                        onClick={() =>
                                            setSelectedDate(day.dateObject)
                                        }
                                    >
                                        {day.formattedDay}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

DatePicker.defaultProps = {
    maxDate: undefined,
    minDate: undefined,
    rangeDate: undefined,
    selectedDate: undefined,
    hideUnavailableDates: false,
};

DatePicker.propTypes = {
    maxDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    minDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    rangeDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    selectedDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    hideUnavailableDates: PropTypes.bool,
    onDateSelected: PropTypes.func.isRequired,
};

export default DatePicker;
