import React from 'react';
import { Page } from '@react-pdf/renderer';
import { getNumber } from 'js/components/number/number';
import Aside from '../components/aside/aside';
import AnnotatedChart from '../components/annotated-chart/annotated-chart';
import Logos from '../components/logos/logos';
import NoDataAlert from '../components/no-data-alert/no-data-alert';
import PageContent from '../components/page-content/page-content';
import Section from '../components/section/section';
import { color } from '../components/tokens.styles';
import styles from '../report.styles';
import { pageSizes } from '../constants';
import { getCombinedVideoReportRecommendations } from '../utils';

export default function VideoSummaryPage({
    orientation,
    logo4D,
    brandLogo,
    charts,
    report,
    isDV360Report,
    pages,
}) {
    const isPortrait = orientation === 'portrait';
    const pageClasses = [
        styles.page,
        isPortrait ? styles.pagePortrait : styles.pageLandscape,
    ];
    if (logo4D || brandLogo) {
        pageClasses.push(isPortrait ? null : styles.pageLandscapeWithLogo);
    }

    const hasImpressions = report.impressions > 0;
    const hasClicks = report.clicks > 0;
    const hasStarts = report.starts > 0;
    const hasCompletes = report.completes > 0;

    const recommendations = getCombinedVideoReportRecommendations(
        report,
        isDV360Report,
        pages,
    );

    const annotations = {
        IMPRESSIONS: {
            title: 'Impressions',
            color: color.purple.base,
            value: getNumber({
                value: report.impressions,
                maximumFractionDigits: 0,
            }),
            description:
                'The number of times an advert appeared on a consumer’s screen.',
        },
        CLICKS: {
            title: 'Clicks',
            color: color.aqua.darker,
            value: getNumber({
                value: report.clicks,
                maximumFractionDigits: 0,
            }),
            description:
                'The number of times an advert was clicked by a consumer.',
        },
        START_VIEWS: {
            title: 'Started Views',
            color: color.aqua.dark,
            value: getNumber({
                value: report.starts,
                maximumFractionDigits: 0,
            }),
            description: 'Measures the number of video ads started to play.',
        },
        TRUE_VIEWS: {
            title: 'True Views',
            color: color.aqua.light,
            value: getNumber({
                value: report.starts,
                maximumFractionDigits: 0,
            }),
            description:
                'TrueView count will be incremented when the viewer watches 30 seconds of the video ad (or the duration if it’s shorter than 30 seconds) or engages with your ad.',
        },
        COMPLETION_RATE: {
            title: `${isDV360Report ? 'True View' : 'Completion'} Rate`,
            color: color.blue.base,
            value: getNumber({
                value: report.impressions
                    ? report.completes / report.impressions
                    : 0,
                isPercentage: true,
                maximumFractionDigits: 2,
            }),
            description:
                'Measures the number of video ads run through to completion, compared to the total number of video ads served.',
        },
        COMPLETE_VIEWS: {
            title: 'Completed Views',
            color: color.aqua.light,
            value: getNumber({
                value: report.completes,
                maximumFractionDigits: 0,
            }),
            description:
                'Measures the number of video ads running through to completion.',
        },
    };

    return (
        <Page
            size={isPortrait ? pageSizes.portrait : pageSizes.landscape}
            orientation={orientation}
            style={pageClasses}
        >
            <Aside
                orientation={orientation}
                title="Summary"
                hasLogo={Boolean(logo4D || brandLogo)}
                recommendations={recommendations}
            />
            <PageContent orientation={orientation}>
                {hasImpressions ? (
                    <>
                        <Section hasBorderBottom={hasStarts || hasCompletes}>
                            <AnnotatedChart
                                orientation={orientation}
                                annotations={
                                    hasClicks
                                        ? [
                                              annotations.IMPRESSIONS,
                                              annotations.CLICKS,
                                          ]
                                        : [annotations.IMPRESSIONS]
                                }
                                chart={charts.IMPRESSIONS}
                            />
                        </Section>
                        {hasStarts && (
                            <Section hasBorderBottom={hasCompletes}>
                                <AnnotatedChart
                                    orientation={orientation}
                                    annotations={[annotations.START_VIEWS]}
                                    chart={charts.START_VIEWS}
                                />
                            </Section>
                        )}
                        {hasCompletes && (
                            <Section>
                                <AnnotatedChart
                                    orientation={orientation}
                                    annotations={[
                                        isDV360Report
                                            ? annotations.TRUE_VIEWS
                                            : annotations.COMPLETE_VIEWS,
                                        annotations.COMPLETION_RATE,
                                    ]}
                                    chart={charts.COMPLETE_VIEWS}
                                />
                            </Section>
                        )}
                    </>
                ) : (
                    <NoDataAlert />
                )}
            </PageContent>
            <Logos
                orientation={orientation}
                logo4D={logo4D}
                brandLogo={brandLogo}
            />
        </Page>
    );
}
