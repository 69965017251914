import React, { useState } from 'react';
import { INITIALLY_VISIBLE_TOPICS } from 'js/constants';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import { useLimitList } from 'js/hooks';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import TopicButton from 'js/components/button/topic-button';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import DropdownTitle from 'js/components/dropdown-menu/title';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import styles from '../summary-footer.module.scss';

function Topics({ rule, topics, onRemoveTopic }) {
    const isTargeting = rule.aggregation === ruleTypes.INCLUDED;

    const filteredTopics = topics.filter((topic) =>
        rule.topics.find((topicId) => topicId === topic.topic),
    );

    const [
        hasMoreTopics,
        isTopicsExpanded,
        visibleTopics,
        showMoreTopics,
        showLessTopics,
    ] = useLimitList(filteredTopics, INITIALLY_VISIBLE_TOPICS);

    return (
        <>
            {visibleTopics.map((topic) => (
                <Box
                    key={topic.topic}
                    margin={[0, 'small', 'small', 0]}
                    display="inline-block"
                >
                    <TopicButton
                        theme={isTargeting ? 'targeted' : 'blocked'}
                        action="remove"
                        logo={topic.topic_group_logo}
                        onClick={() => onRemoveTopic(topic.topic)}
                    >
                        {topic.topic_name}
                    </TopicButton>
                </Box>
            ))}

            {hasMoreTopics && (
                <Row>
                    <Col span="auto">
                        <Button
                            theme="outline"
                            size="small"
                            onClick={showMoreTopics}
                        >
                            Show All {filteredTopics.length} Topics
                        </Button>
                    </Col>
                </Row>
            )}

            {isTopicsExpanded && (
                <Row>
                    <Col span="auto">
                        <Button
                            theme="outline"
                            size="small"
                            onClick={showLessTopics}
                        >
                            Show Fewer Topics
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
}

function TopicDropdown({ topic, isTargeting, showAdded, onAdd, onRemove }) {
    const { topic_name: name, topic_group_logo: logo } = topic;
    const [showMenu, setShowMenu] = useState(false);

    let theme;
    if (showAdded) {
        theme = isTargeting ? 'targeted' : 'blocked';
    } else {
        theme = 'default';
    }

    const actionIcon = theme === 'default' ? 'add' : 'remove';

    return (
        <DropdownMenu
            content={
                <ul className={styles.dropdown}>
                    <DropdownTitle>{name}</DropdownTitle>

                    <DropdownDivider />

                    <DropdownButton onClick={onAdd}>
                        Add Back To This Group
                    </DropdownButton>
                </ul>
            }
            showMenu={showMenu}
            onHide={() => setShowMenu(false)}
        >
            <TopicButton
                theme={theme}
                action={actionIcon}
                logo={logo}
                active={showMenu}
                onClick={showAdded ? onRemove : () => setShowMenu(true)}
            >
                {name}
            </TopicButton>
        </DropdownMenu>
    );
}

function RuleTopicsChanges({
    rule,
    topics,
    showAdded,
    onAddTopic,
    onRemoveTopic,
}) {
    const { aggregation, isNew } = rule;
    const isTargeting = aggregation === ruleTypes.INCLUDED;
    const changedTopics = showAdded ? rule.addedTopics : rule.removedTopics;

    if (!changedTopics.length) return null;

    return (
        <Box margin={['small', 0]}>
            <Panel theme="secondary">
                <Box padding="base">
                    {showAdded ? (
                        <Text>
                            {isNew
                                ? 'This is a new group with the following topics'
                                : 'The following topics have been added to this group'}
                        </Text>
                    ) : (
                        <Text>
                            The following topics have been removed from this
                            group
                        </Text>
                    )}

                    {changedTopics.map((topicId) => {
                        const topic = topics.find((t) => t.topic === topicId);

                        if (!topic) return null;

                        return (
                            <Box
                                key={topicId}
                                margin={['small', 'small', 0, 0]}
                                display="inline-block"
                            >
                                <TopicDropdown
                                    topic={topic}
                                    isTargeting={isTargeting}
                                    showAdded={showAdded}
                                    onAdd={() => onAddTopic(topicId)}
                                    onRemove={() => onRemoveTopic(topicId)}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Panel>
        </Box>
    );
}

function TopicsChanges({
    context,
    contextChanges,
    topics,
    onChangeContextRules,
}) {
    const { rules } = context;
    const { rules: changedRules } = contextChanges;

    const addTopic = (ruleKey, topic) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(ruleKey, { addTopics: [topic] });
        onChangeContextRules(ruleSet.rules);
    };

    const removeTopic = (ruleKey, topic) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(ruleKey, { removeTopics: [topic] });
        onChangeContextRules(ruleSet.rules);
    };

    if (
        !changedRules.map((rule) => rule.topics).flat().length &&
        !changedRules.map((rule) => rule.addedTopics).flat().length &&
        !changedRules.map((rule) => rule.removedTopics).flat().length
    ) {
        return null;
    }

    return (
        <Box margin={['large', 0]}>
            <Panel bordered>
                <Box padding="base">
                    <Text size="larger" weight="bolder">
                        Topics
                    </Text>

                    {changedRules.map((rule, index) => {
                        if (
                            !rule.topics.length &&
                            !rule.addedTopics.length &&
                            !rule.removedTopics.length
                        )
                            return null;

                        const isTargeting =
                            rule.aggregation === ruleTypes.INCLUDED;
                        const title = isTargeting
                            ? 'Target content featuring'
                            : 'Block content featuring';

                        return (
                            <Box margin={['base', 0]} key={rule.key}>
                                <Panel bordered>
                                    <Box padding="base">
                                        {index === 0 ? (
                                            <Text size="large" weight="bold">
                                                {title}
                                            </Text>
                                        ) : (
                                            <Text size="large" weight="bold">
                                                and{' '}
                                                {index ===
                                                    changedRules.length - 1 &&
                                                rule.aggregation !==
                                                    changedRules[index - 1]
                                                        .aggregation
                                                    ? title.toLowerCase()
                                                    : ''}
                                            </Text>
                                        )}

                                        <RuleTopicsChanges
                                            rule={rule}
                                            topics={topics}
                                            showAdded
                                            onAddTopic={(topic) =>
                                                addTopic(rule.key, topic)
                                            }
                                            onRemoveTopic={(topic) =>
                                                removeTopic(rule.key, topic)
                                            }
                                        />

                                        <RuleTopicsChanges
                                            rule={rule}
                                            topics={topics}
                                            onAddTopic={(topic) =>
                                                addTopic(rule.key, topic)
                                            }
                                            onRemoveTopic={(topic) =>
                                                removeTopic(rule.key, topic)
                                            }
                                        />

                                        <Topics
                                            rule={rule}
                                            topics={topics}
                                            onRemoveTopic={(topic) =>
                                                removeTopic(rule.key, topic)
                                            }
                                        />
                                    </Box>
                                </Panel>
                            </Box>
                        );
                    })}
                </Box>
            </Panel>
        </Box>
    );
}

export default TopicsChanges;
