const entityTypes = {
    ADVERTISERS: 'advertisers',
    CAMPAIGNS: 'contextGroups',
    CONTEXTS: 'contexts',
    ORIGINS: 'origins',
    DISPLAY_REPORTS: 'displayReports',
    VIDEO_REPORTS: 'videoReports',
    EVENT_LOG_REPORTS: 'eventLogReports',
};

export default entityTypes;
