import React, { useEffect } from 'react';
import { setDocumentTitle } from 'js/utils';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import CardGeneric from 'js/components/card/card-generic';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';

const cards = [
    {
        title: 'Details',
        subtitle: 'Update your name and email address.',
        to: '/settings/your-profile/details/',
    },
    {
        title: 'Notifications',
        subtitle:
            'Configure how you wish to receive alerts regarding activity in 4D.',
        to: '/settings/your-profile/notifications/',
    },
    {
        title: 'Password',
        subtitle: 'Change your password.',
        to: '/settings/your-profile/password/',
    },
];

export default function UserSettingsMenuPage() {
    useEffect(() => {
        setDocumentTitle(['Your Profile', 'Settings']);
    }, []);

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb
                            key="settings"
                            to="/settings/"
                            label="Settings"
                        />,
                        <Crumb key="profile" label="Your Profile" />,
                    ]}
                />
            }
        >
            <Container>
                <Box padding={['base', 0, 'large']}>
                    <Row>
                        {cards.map((link) => (
                            <Col key={link.to} span={12} spanMd={6} spanLg={4}>
                                <Box padding={['base', 0, 0]}>
                                    <CardGeneric
                                        title={link.title}
                                        description={link.subtitle}
                                        to={link.to}
                                    />
                                </Box>
                            </Col>
                        ))}
                    </Row>
                </Box>
            </Container>
        </Layout>
    );
}
