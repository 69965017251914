import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import Select from 'js/components/select/select';
import Statistic from 'js/components/statistic/statistic';
import Text from 'js/components/text/text';
import TrendChart from 'js/components/trend-chart/trend-chart';
import ColoredLine from 'js/components/trend-chart/colored-line';
import Tooltip from 'js/components/trend-chart/tooltip';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import ChartLineIcon from './chart-line-icon';

const SummaryPanel = ({ report, template }) => {
    const LEFT_METRIC_COLOR = '#3040E8';
    const RIGHT_METRIC_COLOR = '#1CBEC9';

    const isDV360Report =
        template.subtype === videoReportSubtypes.YOUTUBE_DV360;
    const viewTerm = isDV360Report ? 'True Views' : 'Completed Views';
    const viewTermDesc = isDV360Report
        ? 'TrueView count will be incremented when the viewer watches 30 seconds of the video ad (or the duration if it’s shorter than 30 seconds) or engages with your ad.'
        : 'Measures the number of video ads running through to completion';

    const viewRateTerm = isDV360Report ? 'True View Rate' : 'Completion Rate';
    const viewRateTermDesc = isDV360Report
        ? 'Measures the number of True Views, compared to the total number of video ads served'
        : 'Measures the number of video ads run through to completion, compared to the total number of video ads served';

    const summaryData = [
        {
            id: 'impr',
            name: 'Impressions',
            format: 'k',
            description:
                'The number of times an advert appeared on a consumer’s screen.',
            getValue: (i) => i?.impressions,
        },
        {
            id: 'click',
            name: 'Clicks',
            format: 'k',
            description:
                'The number of times an advert was clicked by a consumer.',
            getValue: (i) => i?.clicks,
        },
        {
            id: 'ctr',
            name: 'CTR',
            format: '%',
            description:
                'The ratio of consumers who click on an ad, to the number of total consumers who view an ad. CTR is calculated by dividing the clicks by the impressions.',
            getValue: (i) =>
                i?.clicks && i?.impressions ? i.clicks / i.impressions : 0,
        },
        {
            id: 'vimpr',
            name: 'Viewable Impr.',
            format: 'k',
            description:
                'A measure of whether a given advert was seen by an actual consumer. An impression is considered to be viewable if at least 50% of the display ad is in view for 1 second or more (IAB standard).',
            getValue: (i) => i?.in_views,
        },
        {
            id: 'vr',
            name: 'View Rate',
            format: '%',
            description:
                'Measures the number of impressions that  were viewable compared with the total number of impressions.',
            getValue: (i) =>
                i?.in_views && i?.impressions ? i.in_views / i.impressions : 0,
        },
        {
            id: 'start',
            name: 'Started Views',
            format: 'k',
            description: 'Measures the number of video ads started to play',
            getValue: (i) => i?.starts,
        },
        {
            id: 'compl',
            name: viewTerm,
            format: 'k',
            description: viewTermDesc,
            getValue: (i) => i?.completes,
        },
        {
            id: 'cr',
            name: viewRateTerm,
            format: '%',
            description: viewRateTermDesc,
            getValue: (i) =>
                i?.completes && i?.impressions
                    ? i.completes / i.impressions
                    : 0,
        },
    ].filter((i) => i.getValue(report));

    const initialMetrics = summaryData.slice(0, 3).map((i) => i.id);

    const [leftMetricId, setLeftMetricId] = useState(initialMetrics[0]);
    const [rightMetricId, setRightMetricId] = useState(initialMetrics[2]);

    const selectOptions = summaryData.map((i) => ({
        label: i.name,
        value: i.id,
    }));

    const getIconColor = (id) => {
        if (id === leftMetricId) return LEFT_METRIC_COLOR;
        if (id === rightMetricId) return RIGHT_METRIC_COLOR;
        return null;
    };

    const reportSeries = summaryData.map((s) => ({
        ...s,
        color: getIconColor(s.id),
        axis: { [leftMetricId]: 'left', [rightMetricId]: 'right' }[s.id],
        isSkinny: s.id === rightMetricId,
        data: report.overviews.map((i) => ({
            date: i.activity_date,
            value: s.getValue(i),
        })),
    }));

    return (
        <Container>
            <Box margin={['large', 0]}>
                <Panel theme="secondary">
                    <Box padding="base">
                        <Box margin={[0, 0, 'base', 0]}>
                            <Text size="larger" weight="bold">
                                Summary
                            </Text>
                        </Box>

                        <Panel>
                            <Box padding="base">
                                <Row gap="base">
                                    {reportSeries.map((series) => (
                                        <Statistic
                                            key={series.id}
                                            name={series.name}
                                            info={series.description}
                                        >
                                            <Box display="flex">
                                                {[
                                                    leftMetricId,
                                                    rightMetricId,
                                                ].includes(series.id) && (
                                                    <ChartLineIcon
                                                        backgroundColor={getIconColor(
                                                            series.id,
                                                        )}
                                                    />
                                                )}

                                                <Number
                                                    value={series.getValue(
                                                        report,
                                                    )}
                                                    isPercentage={
                                                        series.format === '%'
                                                    }
                                                    maximumFractionDigits={
                                                        series.format === '%'
                                                            ? 2
                                                            : 0
                                                    }
                                                />
                                            </Box>
                                        </Statistic>
                                    ))}
                                </Row>
                            </Box>
                        </Panel>
                    </Box>
                </Panel>
            </Box>

            {reportSeries && (
                <Box margin={['large', 0]}>
                    <Panel theme="secondary">
                        <Box padding="base">
                            <Box margin={[0, 0, 'base', 0]}>
                                <Text size="larger" weight="bold">
                                    Daily Breakdown
                                </Text>
                            </Box>

                            <Panel>
                                <Box padding="base">
                                    <Row justifyContent="space-between">
                                        <Col span="auto">
                                            <Select
                                                options={selectOptions.filter(
                                                    (option) =>
                                                        option.value !==
                                                        rightMetricId,
                                                )}
                                                selectedValues={leftMetricId}
                                                onChange={setLeftMetricId}
                                            >
                                                <Box display="flex">
                                                    <ColoredLine
                                                        color={
                                                            LEFT_METRIC_COLOR
                                                        }
                                                    />
                                                    {
                                                        selectOptions.find(
                                                            (option) =>
                                                                option.value ===
                                                                leftMetricId,
                                                        )?.label
                                                    }
                                                </Box>
                                            </Select>
                                        </Col>

                                        <Col span="auto">
                                            <Select
                                                options={selectOptions.filter(
                                                    (option) =>
                                                        option.value !==
                                                        leftMetricId,
                                                )}
                                                selectedValues={rightMetricId}
                                                onChange={setRightMetricId}
                                            >
                                                <Box display="flex">
                                                    <ColoredLine
                                                        color={
                                                            RIGHT_METRIC_COLOR
                                                        }
                                                        isSkinny
                                                    />
                                                    {
                                                        selectOptions.find(
                                                            (option) =>
                                                                option.value ===
                                                                rightMetricId,
                                                        )?.label
                                                    }
                                                </Box>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <div style={{ height: 350 }}>
                                        <TrendChart
                                            series={reportSeries}
                                            tooltip={<Tooltip />}
                                        />
                                    </div>
                                </Box>
                            </Panel>
                        </Box>
                    </Panel>
                </Box>
            )}
        </Container>
    );
};

export default SummaryPanel;
