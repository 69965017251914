export const videoPdfPages = {
    SUMMARY: 'Summary',
    TOPICS: 'Topic Themes',
    LOGOS: 'Brand Themes',
};

export const displayPdfPages = {
    SUMMARY: 'Summary',
    TOPICS: 'Topic Themes',
    KEYWORDS: 'Keyword Themes',
};
