import {
    borderRadius,
    borderWidth,
    color,
    fontSize,
    spacing,
} from '../tokens.styles';

const styles = {
    container: {
        border: `${borderWidth.base}px dashed ${color.gray.light}`,
        padding: spacing.small,
        borderRadius: borderRadius.round,
        marginTop: spacing.base,
        color: color.gray.base,
        fontSize: fontSize.smallest,
    },
};

export default styles;
