import React, { Fragment, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './dropdown-menu.module.scss';

// TODO: remove

const cx = classNames.bind(styles);

const DropdownSort = ({ sortingOptions, onSort }) => {
    const [currentSorting, setCurrentSorting] = useState('');
    const sort = (option) => {
        onSort(option);
        setCurrentSorting(sortingOptions.find(({ id }) => id === option).id);
    };
    return (
        <div className={styles.sorting}>
            Sort By{' '}
            {sortingOptions.map(({ id, label }, index) => {
                const optionClasses = cx({
                    'sorting-option': true,
                    selected: currentSorting === id,
                });

                return (
                    <Fragment key={label}>
                        {index > 0 && <span> or </span>}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <span
                            className={optionClasses}
                            onClick={() => sort(id)}
                        >
                            {label}
                        </span>
                    </Fragment>
                );
            })}
        </div>
    );
};

DropdownSort.propTypes = {
    sortingOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onSort: PropTypes.func.isRequired,
};

export default DropdownSort;
