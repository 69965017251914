import React, { useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, RuleSet } from 'js/utils';
import ruleTypes from 'js/enums/rule-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Sparkline from 'js/components/sparkline/sparkline';
import InfoButton from 'js/components/button/info-button';
import ClickArea from 'js/components/click-area/click-area';
import Collapse from 'js/components/collapse/collapse';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import Text from 'js/components/text/text';
import TopicButton from 'js/components/button/topic-button';
import styles from './recommendations.module.scss';

const cx = classNames.bind(styles);

const RecommendationEntitiesPanel = ({
    isReadOnly,
    isPositive,
    entityLabel,
    entities,
    hasAppliedEntities,
    onHandleSuffixClick,
}) => {
    const [isExpanded, setExpanded] = useState(false);
    const entitiesCount = entities.length;
    const targetedCount = entities.filter(({ applied }) => {
        const removeEntities = hasAppliedEntities ? !applied : applied;
        return isPositive ? applied : removeEntities;
    }).length;

    return (
        <Panel theme={isExpanded ? 'primary' : 'secondary'} bordered>
            <Box padding="base">
                <ClickArea onClick={() => setExpanded(!isExpanded)}>
                    <Row alignItems="center">
                        <Col>
                            <Text weight="bold">
                                {`${targetedCount}/${entitiesCount} ${entityLabel}s `}
                                {isPositive ? 'targeted' : 'untargeted'}
                            </Text>
                        </Col>

                        <Col span="auto">
                            <Row gutter="small" alignItems="center">
                                <Col span="auto">
                                    <Text size="small" color={['gray', 'dark']}>
                                        {isExpanded ? 'Hide' : 'View'}{' '}
                                        {entityLabel}s
                                    </Text>
                                </Col>

                                <Col span="auto">
                                    <Text color={['gray', 'light']}>
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={
                                                isExpanded
                                                    ? faChevronUp
                                                    : faChevronDown
                                            }
                                        />
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Box padding={['smaller', 'none', 'none']}>
                        <Sparkline
                            noLabel
                            value={targetedCount}
                            comparedValue={entitiesCount}
                            maxValue={entitiesCount}
                            color={['blue', 'light']}
                        />
                    </Box>
                </ClickArea>

                <Collapse isOpen={isExpanded}>
                    <Box padding={['base', 0, 0]}>
                        {entities.map(
                            ({ id, applied, name, group_logo: groupLogo }) => {
                                const addedTheme = applied ? 'blue' : 'default';
                                const removedTheme = applied
                                    ? 'default'
                                    : 'blue-outline';
                                const appliedTheme = isPositive
                                    ? addedTheme
                                    : removedTheme;
                                const pillTheme = hasAppliedEntities
                                    ? appliedTheme
                                    : 'muted';

                                const positiveIcon = applied ? 'remove' : 'add';
                                const negativeIcon = applied ? 'remove' : 'add';
                                const actionIcon = isPositive
                                    ? positiveIcon
                                    : negativeIcon;
                                return (
                                    <Box
                                        key={id}
                                        margin={[0, 'small', 'smaller', 0]}
                                        display="inline-block"
                                    >
                                        <TopicButton
                                            theme={pillTheme}
                                            action={
                                                !hasAppliedEntities
                                                    ? null
                                                    : actionIcon
                                            }
                                            logo={groupLogo}
                                            isReadOnly={isReadOnly}
                                            onClick={() =>
                                                onHandleSuffixClick(id, applied)
                                            }
                                        >
                                            {name}
                                        </TopicButton>
                                    </Box>
                                );
                            },
                        )}
                    </Box>
                </Collapse>
            </Box>
        </Panel>
    );
};

function EntityRecommendation({
    isReadOnly,
    recommendation,
    rules,
    entityLabel,
    entityGroups,
    onToggleRecommendation,
    onChangeRules,
}) {
    const { attribute } = recommendation;
    const isPositive = recommendation.action === 'add';
    const targetMetricLabel = recommendation.target_metric.toUpperCase();

    const allEntities = entityGroups.map((g) => g[attribute]).flat();
    const targetedEntities = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item[attribute])
        .flat();
    const recommendedEntities = recommendation.entities.map(({ name }) => name);
    const appliedEntities = recommendedEntities.map((entity) => {
        if (targetedEntities.includes(entity)) return { entity, applied: true };
        return { entity, applied: false };
    });
    const hasAppliedEntities =
        recommendedEntities.filter((entity) =>
            isPositive
                ? targetedEntities.includes(entity)
                : !targetedEntities.includes(entity),
        ).length > 0;
    const visibleEntities = hasAppliedEntities
        ? appliedEntities
        : recommendedEntities.map((entity) => ({ entity, applied: false }));

    const visibleEntitiesWithDetails = visibleEntities.map((t) => {
        const entityDetails = allEntities.find(
            (fullEntity) => fullEntity.id === t.entity,
        );
        return {
            ...entityDetails,
            applied: t.applied,
        };
    });

    const targetMetricRatio =
        recommendation.target_metric_value /
            recommendation.target_metric_baseline -
        1;

    const recommendationClasses = cx({
        recommendation: true,
        active: hasAppliedEntities,
    });

    const addEntity = (entityId) => {
        const ruleSet = new RuleSet(rules);
        const rule = rules.find(
            (ruleObject) => ruleObject.aggregation === ruleTypes.INCLUDED,
        );
        ruleSet.changeRule(rule.key, {
            [`add${capitalize(attribute)}`]: [entityId],
        });
        onChangeRules(ruleSet.rules);
    };

    const removeEntity = (entityId) => {
        const ruleSet = new RuleSet(rules);
        const rule = rules.find(
            (ruleObject) => ruleObject.aggregation === ruleTypes.INCLUDED,
        );
        ruleSet.changeRule(rule.key, {
            [`remove${capitalize(attribute)}`]: [entityId],
        });
        onChangeRules(ruleSet.rules);
    };

    const handleSuffixClick = (entityId, applied) => {
        if (applied) removeEntity(entityId);
        else {
            addEntity(entityId);
        }
    };

    return (
        <div className={recommendationClasses}>
            <Panel bordered>
                <Box padding="base">
                    <Row alignItems="center">
                        <Col>
                            <Row gutter="small" alignItems="center">
                                <Col span="auto">
                                    <div className={styles['date-badge']}>
                                        {moment(recommendation.created).format(
                                            'D MMM YY',
                                        )}
                                    </div>
                                </Col>

                                <Col>
                                    {!hasAppliedEntities && (
                                        <Text
                                            weight="bolder"
                                            size="large"
                                            inline
                                        >
                                            Optimization removed –{' '}
                                        </Text>
                                    )}
                                    <Text weight="bold" size="large" inline>
                                        {visibleEntities.length}{' '}
                                        {visibleEntities.length > 1
                                            ? `${entityLabel}s`
                                            : entityLabel}{' '}
                                        {isPositive ? 'added' : 'removed'}
                                    </Text>{' '}
                                    <Box display="inline-block">
                                        <InfoButton>
                                            <Text weight="bolder">
                                                Improvement
                                            </Text>

                                            <Box margin={['small', 0, 0]}>
                                                <Text>
                                                    Pages featuring these{' '}
                                                    {entityLabel.toLowerCase()}s{' '}
                                                    had a {targetMetricLabel} of{' '}
                                                    <Number
                                                        value={
                                                            recommendation.target_metric_value
                                                        }
                                                        isPercentage
                                                        maximumFractionDigits={
                                                            2
                                                        }
                                                    />
                                                    {', '}
                                                    <Number
                                                        value={
                                                            targetMetricRatio
                                                        }
                                                        isPercentage
                                                        maximumFractionDigits={
                                                            2
                                                        }
                                                        signDisplay="never"
                                                    />{' '}
                                                    {targetMetricRatio > 0
                                                        ? 'greater'
                                                        : 'less'}{' '}
                                                    than the report average.
                                                </Text>
                                            </Box>
                                        </InfoButton>
                                    </Box>
                                </Col>
                            </Row>
                        </Col>
                        {!isReadOnly && (
                            <Col span="auto">
                                <Button
                                    theme="outline"
                                    size="small"
                                    onClick={() => {
                                        onToggleRecommendation({
                                            attribute,
                                            hasAppliedEntities,
                                            isPositive,
                                            entities: recommendedEntities,
                                        });
                                    }}
                                >
                                    {hasAppliedEntities ? 'Undo' : 'Reapply'}
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <Box margin={['base', 0, 0]}>
                        <RecommendationEntitiesPanel
                            isReadOnly={isReadOnly}
                            entityLabel={entityLabel}
                            entities={visibleEntitiesWithDetails}
                            hasAppliedEntities={hasAppliedEntities}
                            isPositive={isPositive}
                            onHandleSuffixClick={handleSuffixClick}
                        />
                    </Box>
                </Box>
            </Panel>
        </div>
    );
}

export default EntityRecommendation;
