import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardAdvertiser from 'js/components/card/card-advertiser';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import FileInput from 'js/components/input/file-input';
import Input from 'js/components/input/input';
import Label from 'js/components/label/label';
import Panel from 'js/components/panel/panel';
import Section from 'js/components/section/section';
import {
    cropImage,
    validateImage,
    validateImageDimensions,
    validateMimeTypes,
} from '../button/file-button';

const FormErrorContent = ({ saveErrors, isNew }) => {
    const action = isNew ? 'create' : 'update';

    if (saveErrors.name || saveErrors.logo) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You can&apos;t {action} an advertiser at the moment
                        because there are problems that have been highlighted in
                        the form above.
                    </p>
                </Alert>
            </Box>
        );
    }
    if (Object.keys(saveErrors).length) {
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You were unable to {action} the advertiser because there
                        was an unexpected error. Please try again in a few
                        moments.
                    </p>
                </Alert>
            </Box>
        );
    }
    return null;
};

const FormContent = ({
    advertiser,
    saveErrors,
    logoError,
    onNameChange,
    onLogoChange,
    onLogoError,
}) => (
    <>
        <Section hasBorderBottom>
            <Box margin={[0, 0, 'base']}>
                <Input
                    focusOnShow
                    maxLength={50}
                    required
                    label="Name"
                    value={advertiser.name || ''}
                    onChange={(evt) => onNameChange(evt.target.value)}
                    hasError={!!saveErrors.name}
                    errorMessage={<p>{saveErrors.name}</p>}
                />
            </Box>
            <Box margin={[0, 0, 'base']}>
                <FileInput
                    label="Logo"
                    hint={
                        <p>
                            The image used for the logo must be at least 200px
                            tall and be a png or jpeg file.
                        </p>
                    }
                    required
                    src={advertiser.logo}
                    sanitizers={[
                        validateImage,
                        cropImage,
                        validateMimeTypes(['image/jpeg', 'image/png']),
                        validateImageDimensions(1, 200, 2048, 2048),
                    ]}
                    onError={onLogoError}
                    onLoadStart={() => onLogoError('')}
                    onLoadEnd={(data) => onLogoChange(data)}
                    hasError={!!logoError || !!saveErrors.logo}
                    errorMessage={<p>{logoError || saveErrors.logo}</p>}
                />
            </Box>
        </Section>
        <Box margin={['base', 0, 'large']}>
            <Label label="Card Preview">
                <CardAdvertiser advertiser={advertiser} isPreview />
            </Label>
        </Box>
    </>
);

const FormWrapper = ({ isModal, children }) =>
    isModal ? (
        children
    ) : (
        <Box padding={['large', 0]}>
            <Container size="small">
                <Form>
                    <Panel bordered>
                        <Box padding="base">{children}</Box>
                    </Panel>
                </Form>
            </Container>
        </Box>
    );

function AdvertiserForm({
    advertiser,
    saving,
    saveErrors,
    onNameChange,
    onLogoChange,
    onSave,
    isModal,
}) {
    const [logoError, setLogoError] = useState('');

    return (
        <FormWrapper isModal={isModal}>
            <FormContent
                advertiser={advertiser}
                saveErrors={saveErrors}
                logoError={logoError}
                onNameChange={onNameChange}
                onLogoChange={onLogoChange}
                onLogoError={setLogoError}
            />
            <FormErrorContent saveErrors={saveErrors} isNew={!advertiser.id} />
            <Row justifyContent="flex-end">
                <Col span="auto">
                    <Button
                        type="submit"
                        disabled={saving || Object.keys(saveErrors).length > 0}
                        onClick={() => {
                            setLogoError('');
                            onSave();
                        }}
                    >
                        {advertiser.id ? 'Save' : 'Create Advertiser'}
                    </Button>
                </Col>
            </Row>
        </FormWrapper>
    );
}

AdvertiserForm.defaultProps = {
    advertiser: {},
    saving: false,
    saveErrors: {},
    isModal: false,
};

AdvertiserForm.propTypes = {
    advertiser: PropTypes.object,
    saving: PropTypes.bool,
    saveErrors: PropTypes.object,
    onNameChange: PropTypes.func.isRequired,
    onLogoChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isModal: PropTypes.bool,
};

export default AdvertiserForm;
