import Panel from 'js/components/panel/panel';
import ruleTypes from 'js/enums/rule-types.enum';
import React, { useRef, useState } from 'react';
import { capitalize, RuleSet } from 'js/utils';
import { useLimitList } from 'js/hooks';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import ConfirmModal from 'js/components/modal/confirm-modal';
import KeywordRecommendation from '../recommendations/keyword-recommendation';
import EntityRecommendation from '../recommendations/entity-recommendation';
import styles from '../optimize-section.module.scss';

const INITIALLY_VISIBLE_RECOMMENDATIONS = 2;

function Timeline({
    isReadOnly,
    recommendations,
    rules,
    logoGroups,
    topicGroups,
    onChangeRules,
}) {
    const [
        isToggleRecommendationModalVisible,
        setToggleRecommendationModalVisible,
    ] = useState(false);
    const [hasToggleError, setToggleError] = useState(false);

    const [
        hasMoreRecommendations,
        ,
        visibleRecommendations,
        showMoreRecommendations,
    ] = useLimitList(recommendations, INITIALLY_VISIBLE_RECOMMENDATIONS, 2);

    const toggleParams = useRef();

    const ruleSet = new RuleSet(rules);

    const undoRecommendation = (attribute, isPositive, entities) => {
        if (isPositive) {
            ruleSet.changeAllRules({
                [`remove${capitalize(attribute)}`]: entities,
            });
        } else {
            const firstRule = rules.find(
                ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
            );
            ruleSet.changeRule(firstRule.key, {
                [`add${capitalize(attribute)}`]: entities,
            });
        }

        onChangeRules(ruleSet.rules);
    };

    const applyRecommendation = (attribute, isPositive, entities) => {
        if (isPositive) {
            const firstRule = rules.find(
                ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
            );
            ruleSet.changeRule(firstRule.key, {
                [`add${capitalize(attribute)}`]: entities,
            });
        } else {
            ruleSet.changeAllRules({
                [`remove${capitalize(attribute)}`]: entities,
            });
        }

        onChangeRules(ruleSet.rules);
    };

    const toggleRecommendation = () => {
        setToggleError(false);

        try {
            const { attribute, hasAppliedEntities, isPositive, entities } =
                toggleParams.current;

            if (hasAppliedEntities) {
                undoRecommendation(attribute, isPositive, entities);
            } else {
                applyRecommendation(attribute, isPositive, entities);
            }

            setToggleRecommendationModalVisible(false);
        } catch (e) {
            setToggleError(true);
        }
    };

    const toggleDeleteWarningModal = (params) => {
        toggleParams.current = params;
        if (params.hasAppliedEntities) {
            setToggleRecommendationModalVisible(true);
        } else {
            toggleRecommendation();
        }
        setToggleError(false);
    };

    if (!recommendations.length) {
        return (
            <Alert theme="empty">
                <p>
                    Auto Optimizations has not made changes to this context yet
                </p>
            </Alert>
        );
    }

    return (
        <>
            <Panel>
                <Box padding="base">
                    <div className={styles.timeline}>
                        {visibleRecommendations.map((recommendation) => (
                            <div
                                key={recommendation.id}
                                className={styles['recommendation-wrapper']}
                            >
                                {recommendation.attribute === 'keywords' && (
                                    <KeywordRecommendation
                                        isReadOnly={isReadOnly}
                                        recommendation={recommendation}
                                        rules={rules}
                                        onToggleRecommendation={
                                            toggleDeleteWarningModal
                                        }
                                        onChangeRules={onChangeRules}
                                    />
                                )}
                                {recommendation.attribute === 'topics' && (
                                    <EntityRecommendation
                                        isReadOnly={isReadOnly}
                                        recommendation={recommendation}
                                        rules={rules}
                                        entityLabel="Topic"
                                        entityGroups={topicGroups}
                                        onToggleRecommendation={
                                            toggleDeleteWarningModal
                                        }
                                        onChangeRules={onChangeRules}
                                    />
                                )}
                                {recommendation.attribute === 'logos' && (
                                    <EntityRecommendation
                                        isReadOnly={isReadOnly}
                                        recommendation={recommendation}
                                        rules={rules}
                                        entityLabel="Brand"
                                        entityGroups={logoGroups}
                                        onToggleRecommendation={
                                            toggleDeleteWarningModal
                                        }
                                        onChangeRules={onChangeRules}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    {hasMoreRecommendations && (
                        <Box margin={['large', 0, 0]}>
                            <Row justifyContent="center">
                                <Col span="auto">
                                    <Button
                                        theme="outline"
                                        onClick={showMoreRecommendations}
                                    >
                                        View Older Optimizations
                                    </Button>
                                </Col>
                            </Row>
                        </Box>
                    )}
                </Box>
            </Panel>

            {isToggleRecommendationModalVisible && (
                <ConfirmModal
                    title="Are you Sure?"
                    confirm="Yes, Continue"
                    cancel="No, Cancel"
                    hasError={hasToggleError}
                    errorMessage={`There was an unexpected error and the 
                            optimization was not toggled. Please try again in a 
                            few moments.`}
                    onCancel={() => setToggleRecommendationModalVisible(false)}
                    onConfirm={toggleRecommendation}
                >
                    <p>
                        These recommendations have been applied as they support
                        your campaign&apos;s optimization goals. Reversing these
                        changes could impact your performance metrics.
                    </p>

                    <p>Are you sure you want to continue?</p>
                </ConfirmModal>
            )}
        </>
    );
}

export default Timeline;
