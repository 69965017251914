import React, { useCallback, useEffect, useState } from 'react';
import { marked } from 'marked';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import CardGeneric from 'js/components/card/card-generic';
import Section from 'js/components/section/section';
import SectionTitle from 'js/components/section-title/section-title';
import ClickArea from 'js/components/click-area/click-area';
import { AbortError, api, reloadPage } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Spinner from 'js/components/spinner/spinner';
import { useHistory, useParams } from 'react-router-dom';
import styles from '../../discover.module.scss';

const FeatureReleaseCard = ({ feature, onChangeDisplayedFeature }) => (
    <ClickArea onClick={() => onChangeDisplayedFeature(feature)}>
        <CardGeneric isSlim title={feature.name} />
    </ClickArea>
);

const MoreFeatureReleases = ({
    features,
    displayedFeature,
    onChangeDisplayedFeature,
}) => {
    const moreFeatures = features.filter(
        (feat) => feat.id !== displayedFeature.id,
    );

    return (
        <Section hasBorderTop>
            <Box padding={['large', 0]}>
                <Container size="medium">
                    <Box margin={[0, 0, 'large']}>
                        <SectionTitle>More Feature Releases</SectionTitle>
                    </Box>

                    {moreFeatures.map((feature) => (
                        <Box margin={['base', 0, 0]} key={feature.id}>
                            <FeatureReleaseCard
                                key={feature.id}
                                feature={feature}
                                onChangeDisplayedFeature={
                                    onChangeDisplayedFeature
                                }
                            />
                        </Box>
                    ))}
                </Container>
            </Box>
        </Section>
    );
};

const FeatureReleaseBody = ({
    loading,
    setLoading,
    errors,
    setErrors,
    scrollRef,
}) => {
    const { resource: currentResource } = useParams();
    const history = useHistory();
    const [feature, setFeature] = useState();
    const [features, setFeatures] = useState([]);

    const setDisplayedFeature = useCallback(
        (f) => {
            if (history.location.pathname === '/discover/feature/') {
                history.replace(`/discover/feature/${f.id}/`);
            } else {
                history.push(`/discover/feature/${f.id}/`);
            }
        },
        [history],
    );

    useEffect(() => {
        // todo: make use effect update the page ref by modifying the history
        const getFeatures = async () => {
            try {
                setLoading(true);
                const { results } = await api().features.list();
                setFeatures(results);
            } catch (err) {
                if (err instanceof AbortError) return;
                setErrors(err);
            } finally {
                setLoading(false);
            }
        };
        getFeatures();
    }, [setLoading, setErrors]);

    useEffect(() => {
        if (!currentResource && features.length > 0) {
            setDisplayedFeature(features[0]);
        } else {
            setFeature(features.find((i) => i.id === currentResource));
        }
    }, [currentResource, features, setDisplayedFeature]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }, [feature, scrollRef]);

    if (loading) {
        return (
            <Box padding={['large', 0]}>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Box>
        );
    }

    if (Object.keys(errors).length > 0) {
        return (
            <Container size="small">
                <Box padding={['large', 0]}>
                    <Alert
                        theme="danger"
                        title="There was an error loading the page"
                        actionItems={
                            <Button onClick={reloadPage}>Retry</Button>
                        }
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            again in a few moments.
                        </p>
                    </Alert>
                </Box>
            </Container>
        );
    }

    if (feature) {
        const {
            action_url: actionUrl,
            name,
            overview,
            spotlight_image: image,
        } = feature;

        const overviewHtml = marked.parse(overview);
        return (
            <>
                <Container size="medium">
                    <h1 className={styles['feature-heading']}>{name}</h1>

                    <Box margin={['base', 0]}>
                        <div
                            className={styles.overview}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: overviewHtml }}
                        />
                    </Box>

                    <Box margin={['base', 0]}>
                        <Row>
                            <Col span="auto">
                                <Button theme="outline" to={actionUrl}>
                                    Try This Feature Now
                                </Button>
                            </Col>
                        </Row>
                    </Box>

                    {image && (
                        <div className={styles['feature-image-container']}>
                            <div className={styles['feature-image']}>
                                <img src={image} alt="background" />
                            </div>
                        </div>
                    )}
                </Container>
                {features.length > 1 && (
                    <MoreFeatureReleases
                        features={features}
                        displayedFeature={feature}
                        onChangeDisplayedFeature={setDisplayedFeature}
                    />
                )}
            </>
        );
    }
    return null;
};

export default FeatureReleaseBody;
