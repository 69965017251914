import React, { useEffect, useState } from 'react';
import moment from 'moment';
import entityTypes from 'js/enums/entity-types.enum';
import { getScheduleType } from 'js/utils';
import Box from 'js/components/box/box';
import Checkbox from 'js/components/checkbox/checkbox';
import DateRangePicker from 'js/components/date-picker/date-range-picker';
import Text from 'js/components/text/text';
import MonthCalendar from './month-calendar';
import WeekCalendar from './week-calendar';
import scheduleOptions from '../enums/schedule-options.enum';
import scheduleTypes from '../../../enums/schedule-types.enum';

const getScheduleOption = (schedule) =>
    schedule === null
        ? scheduleOptions.NO_SCHEDULING
        : scheduleOptions.SCHEDULED;

const DatesStep = ({
    template,
    errors,
    onChangeSchedule,
    onChangeStartDate,
    onChangeEndDate,
}) => {
    const isEventLogReport = template.type === entityTypes.EVENT_LOG_REPORTS;
    const { schedule } = template;
    const startDate = template.start_date
        ? moment(template.start_date).toDate()
        : null;
    const endDate = template.end_date
        ? moment(template.end_date).toDate()
        : null;

    const defaultStartDate = moment().subtract(30, 'days').toDate();
    const defaultEndDate = isEventLogReport
        ? moment().subtract(1, 'days').toDate()
        : moment().toDate();
    const defaultDayOfTheWeek =
        getScheduleType(schedule) === scheduleTypes.WEEKLY ? schedule : 'mon';
    const defaultDayOfTheMonth =
        getScheduleType(schedule) === scheduleTypes.MONTHLY
            ? Number.parseInt(schedule, 10)
            : 1;

    const [scheduleOption, setScheduleOption] = useState(
        getScheduleOption(schedule),
    );

    const changeScheduleType = (scheduleType) => {
        switch (scheduleType) {
            case scheduleTypes.WEEKLY:
                onChangeSchedule(defaultDayOfTheWeek);
                onChangeStartDate(null);
                onChangeEndDate(null);
                break;
            case scheduleTypes.MONTHLY:
                onChangeSchedule(defaultDayOfTheMonth);
                onChangeStartDate(null);
                onChangeEndDate(null);
                break;
            default:
                onChangeSchedule(null);
                onChangeStartDate(defaultStartDate);
                onChangeEndDate(defaultEndDate);
                break;
        }
    };

    useEffect(() => {
        if (getScheduleOption(schedule) === scheduleOptions.NO_SCHEDULING) {
            onChangeStartDate(startDate || defaultStartDate);
            onChangeEndDate(endDate || defaultEndDate);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (scheduleOption !== getScheduleOption(schedule)) {
            changeScheduleType(
                scheduleOption === scheduleOptions.SCHEDULED
                    ? scheduleTypes.WEEKLY
                    : null,
            );
        }
    }, [scheduleOption]); // eslint-disable-line react-hooks/exhaustive-deps

    const options = [
        {
            label: 'Custom Dates (No Scheduling)',
            value: scheduleOptions.NO_SCHEDULING,
            content: (
                <Box
                    borderLeft={['thick', 'solid', 'gray', 'lightest']}
                    margin={['small', 0, 0, 'base']}
                    padding="base"
                >
                    <Box margin={[0, 0, 'small']}>
                        <Text color={['gray', 'dark']}>
                            <p>
                                Select the date range that you would like your
                                Insights Report to cover from the start and end
                                date sections below. It will be possible to
                                create additional snapshots for alternative date
                                ranges after you have created the report.
                            </p>
                            <p>
                                Insights Reports must cover a minimum of 7 days.
                            </p>
                        </Text>
                    </Box>

                    <DateRangePicker
                        startDate={startDate || defaultStartDate}
                        endDate={endDate || defaultEndDate}
                        maxDate={moment().toDate()}
                        hasError={!!errors.schedule}
                        errorMessage={<p>{errors.schedule}</p>}
                        hideUnavailableDates
                        onStartDateChange={onChangeStartDate}
                        onEndDateChange={onChangeEndDate}
                    />
                </Box>
            ),
        },
        {
            label: 'Scheduled 30 Day Snapshots',
            value: scheduleOptions.SCHEDULED,
            content: (
                <Box
                    borderLeft={['thick', 'solid', 'gray', 'lightest']}
                    margin={['small', 0, 0, 'base']}
                    padding="base"
                >
                    <Box margin={[0, 0, 'small']}>
                        <Text color={['gray', 'dark']}>
                            <p>
                                Scheduled reports will contain snapshots with 30
                                days of data, from the day the snapshot was
                                generated to 30 days in the past. The first
                                snapshot will be generated when the report is
                                created. Scheduled reports will continue to
                                generate snapshots until 4D stops recording data
                                for your data sources.
                            </p>
                        </Text>
                    </Box>

                    <Checkbox
                        radio
                        options={[
                            {
                                label: 'Weekly',
                                value: scheduleTypes.WEEKLY,
                                content: (
                                    <WeekCalendar
                                        selectedDay={defaultDayOfTheWeek}
                                        onChange={onChangeSchedule}
                                    />
                                ),
                            },
                            {
                                label: 'Monthly',
                                value: scheduleTypes.MONTHLY,
                                content: (
                                    <MonthCalendar
                                        selectedDay={defaultDayOfTheMonth}
                                        onChange={onChangeSchedule}
                                    />
                                ),
                            },
                        ]}
                        selectedValues={getScheduleType(schedule)}
                        onChange={changeScheduleType}
                    />
                </Box>
            ),
        },
    ];

    if (isEventLogReport) {
        return (
            <>
                <Box margin={[0, 0, 'small']}>
                    <Text color={['gray', 'dark']}>
                        <p>
                            It will be possible to create additional snapshots
                            for alternative date ranges after you have created
                            the report. The most recent date you can select is
                            yesterday.
                        </p>
                    </Text>
                </Box>

                <DateRangePicker
                    startDate={startDate || defaultStartDate}
                    endDate={endDate || defaultEndDate}
                    maxDate={moment().subtract(1, 'days').toDate()}
                    hasError={!!errors.schedule}
                    errorMessage={<p>{errors.schedule}</p>}
                    hideUnavailableDates
                    onStartDateChange={onChangeStartDate}
                    onEndDateChange={onChangeEndDate}
                />
            </>
        );
    }

    return (
        <Checkbox
            radio
            options={options}
            selectedValues={scheduleOption}
            onChange={setScheduleOption}
        />
    );
};

export default DatesStep;
