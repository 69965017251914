import React, { useEffect, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from 'js/components/box/box';
import SwitchButton from 'js/components/button/switch-button';
import ClickArea from 'js/components/click-area/click-area';
import Collapse from 'js/components/collapse/collapse';
import Counter from 'js/components/counter/counter';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import SVGImage from 'js/components/svg-image/svg-image';
import Text from 'js/components/text/text';
import Topic from './topic';
import styles from './topic-selector.module.scss';

function TopicGroup({
    itemType,
    group,
    searchTerm,
    rules,
    ruleKey,
    targetedItems,
    blockedItems,
    onTarget,
    onBlock,
    onTargetAll,
    onBlockAll,
}) {
    const [isExpanded, setExpanded] = useState(false);

    const items = group[itemType];
    const isBrandSafetyGroup = items.every((item) => item.is_brand_safety);

    const selectedItemsCount = items.reduce((count, item) => {
        if (targetedItems.includes(item.id) || blockedItems.includes(item.id)) {
            return count + 1;
        }

        return count;
    }, 0);

    const isAllItemsTargeted = items.every(({ id }) =>
        targetedItems.includes(id),
    );
    const isAllItemsBlocked = items.every(({ id }) =>
        blockedItems.includes(id),
    );

    const rule = rules.find(({ key }) => key === ruleKey);

    const isAllItemsUsedInAnotherGroup =
        typeof ruleKey === 'number'
            ? selectedItemsCount === items.length &&
              !items.some(({ id }) => rule[itemType].includes(id))
            : false;

    const itemList = items.map((item) => (
        <Topic
            key={item.name}
            itemType={itemType}
            topic={item}
            rules={rules}
            ruleKey={ruleKey}
            targetedItems={targetedItems}
            blockedItems={blockedItems}
            onTarget={onTarget}
            onBlock={onBlock}
        />
    ));

    useEffect(() => {
        setExpanded(searchTerm.length > 0);
    }, [searchTerm]);

    return searchTerm ? (
        <>
            <div className={styles['topic-group-name']}>
                <Row justifyContent="space-between" alignItems="center">
                    <Col span="auto">
                        <Row alignItems="center" gutter="small">
                            {group.logo && (
                                <Col span="auto">
                                    <div className={styles['topic-group-logo']}>
                                        <SVGImage src={group.logo} />
                                    </div>
                                </Col>
                            )}

                            <Col span="auto">
                                <Text size="large">{group.name}</Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            {itemList}
        </>
    ) : (
        <>
            <ClickArea onClick={() => setExpanded((prevState) => !prevState)}>
                <div className={styles['topic-group-name']}>
                    <Row justifyContent="space-between" alignItems="center">
                        <Col span="auto">
                            <Row alignItems="center" gutter="small">
                                {group.logo && (
                                    <Col span="auto">
                                        <div
                                            className={
                                                styles['topic-group-logo']
                                            }
                                        >
                                            <SVGImage src={group.logo} />
                                        </div>
                                    </Col>
                                )}

                                <Col span="auto">
                                    <Text size="large">{group.name}</Text>
                                </Col>
                            </Row>
                        </Col>

                        <Col span="auto">
                            <Row alignItems="center" gutter="small">
                                <Col span="auto">
                                    <Counter
                                        theme="square"
                                        selected={selectedItemsCount}
                                        total={items.length}
                                    />
                                </Col>

                                <Col span="auto">
                                    <FontAwesomeIcon
                                        icon={
                                            isExpanded
                                                ? faChevronUp
                                                : faChevronDown
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </ClickArea>

            <Collapse isOpen={isExpanded}>
                <Box
                    borderLeft={['thick', 'solid', 'gray', 'lightest']}
                    margin={['smaller', 0, 0, 'base']}
                    padding={[0, 0, 0, 'base']}
                >
                    {!isAllItemsUsedInAnotherGroup &&
                        (onTargetAll || onBlockAll) && (
                            <Box padding={['small', 0, 'base']}>
                                <Row gutter="small">
                                    {!isBrandSafetyGroup && onTargetAll && (
                                        <Col>
                                            <SwitchButton
                                                theme="outline"
                                                active={isAllItemsTargeted}
                                                onChange={() =>
                                                    onTargetAll(group.id)
                                                }
                                            >
                                                Target All
                                            </SwitchButton>
                                        </Col>
                                    )}

                                    {onBlockAll && (
                                        <Col>
                                            <SwitchButton
                                                theme="outline"
                                                active={isAllItemsBlocked}
                                                onChange={() =>
                                                    onBlockAll(group.id)
                                                }
                                            >
                                                Block All
                                            </SwitchButton>
                                        </Col>
                                    )}
                                </Row>
                            </Box>
                        )}

                    {itemList}
                </Box>
            </Collapse>
        </>
    );
}

export default TopicGroup;
