import React, { useContext } from 'react';
import Box from 'js/components/box/box';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import SectionTitle from 'js/components/section-title/section-title';
import Text from 'js/components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import InfoButton from 'js/components/button/info-button';
import ClickArea from 'js/components/click-area/click-area';
import strategyTypes from 'js/pages/context-detail/enums/matching-strategies.enum';
import Panel from 'js/components/panel/panel';
import styles from './matching-strategy-section.module.scss';
import ContextDetailContext from '../../../contexts/context-detail.context';

const Meter = ({ value, isSelected }) => (
    <>
        {[...Array(5)].map((item, i) => (
            <div
                key={i} // eslint-disable-line react/no-array-index-key
                className={
                    i >= value
                        ? styles['meter-square']
                        : styles[
                              isSelected
                                  ? 'meter-square-filled--selected'
                                  : 'meter-square-filled'
                          ]
                }
            />
        ))}
    </>
);

const StrategyTypeOption = ({
    strategy,
    isSelected,
    onSetStrategy,
    isReadOnly,
}) => {
    const unselectedClassName = isReadOnly
        ? 'strategy-option--read-only'
        : 'strategy-option';
    return (
        <Col span={12} spanSm={4}>
            <ClickArea
                key={strategy.value}
                onClick={() => onSetStrategy(strategy.value)}
                isDisabled={isReadOnly || isSelected}
            >
                <div className={styles['full-height']}>
                    <div
                        className={
                            styles[
                                isSelected
                                    ? 'strategy-option--selected'
                                    : unselectedClassName
                            ]
                        }
                    >
                        <Row
                            gutter="small"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {(isSelected || !isReadOnly) && (
                                <Col span="auto">
                                    <Text>
                                        <FontAwesomeIcon
                                            icon={
                                                isSelected
                                                    ? faCheckCircle
                                                    : faCircle
                                            }
                                        />
                                    </Text>
                                </Col>
                            )}
                            <Col>
                                <Text weight="bold">{strategy.name}</Text>
                            </Col>
                            <Col span="auto">
                                <InfoButton>{strategy.infoText}</InfoButton>
                            </Col>
                        </Row>
                        <Box margin={['small', 0, 0, 0]}>
                            <Row alignItems="center" gutter="smallest">
                                <Col span="auto">
                                    <Text
                                        size="smaller"
                                        weight="bold"
                                        textTransform="uppercase"
                                    >
                                        precision
                                    </Text>
                                </Col>
                                <Col>
                                    <hr className={styles['line-through']} />
                                </Col>
                                <Col span="auto">
                                    <Meter
                                        value={strategy.precision}
                                        isSelected={isSelected}
                                    />
                                </Col>
                            </Row>
                            <Row alignItems="center" gutter="smaller">
                                <Col span="auto">
                                    <Text
                                        size="smaller"
                                        weight="bold"
                                        textTransform="uppercase"
                                    >
                                        scale
                                    </Text>
                                </Col>
                                <Col>
                                    <hr className={styles['line-through']} />
                                </Col>
                                <Col span="auto">
                                    <Meter
                                        value={strategy.scale}
                                        isSelected={isSelected}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </ClickArea>
        </Col>
    );
};

const MatchingStrategySection = () => {
    const {
        context: { setStrategy, current: currentContext },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);
    return (
        <Box margin={['base', 'base', 0]}>
            <Panel bordered>
                <Box padding={['base', 'base', 'small', 'base']}>
                    <Box margin={[0, 0, 'small', 0]}>
                        <SectionTitle info="Matching strategy dictates how closely your context needs to match with the content of a webpage or video for it to be considered for your advertising campaign. ">
                            <Text size="large" weight="bold">
                                Matching Strategy
                            </Text>
                        </SectionTitle>
                    </Box>
                    <Row>
                        {Object.keys(strategyTypes).map((type) => (
                            <StrategyTypeOption
                                key={type}
                                strategy={strategyTypes[type]}
                                isSelected={
                                    currentContext.strategy ===
                                    strategyTypes[type].value
                                }
                                onSetStrategy={setStrategy}
                                isReadOnly={isReadOnly}
                            />
                        ))}
                    </Row>
                </Box>
            </Panel>
        </Box>
    );
};

export default MatchingStrategySection;
