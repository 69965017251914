import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import InsightsTable from '../../../components/insights-table/insights-table';

const KeywordButton = ({
    keywordName,
    isTargeting,
    rules,
    onChangeContextRules,
}) => {
    const targetRules = rules.filter(
        ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
    );
    const isKeywordTargeted = targetRules
        .map(({ keywords }) => keywords)
        .flat()
        .includes(keywordName);
    const blockRule = rules.find(
        ({ aggregation }) => aggregation === ruleTypes.EXCLUDED,
    );
    const isKeywordBlocked = blockRule.keywords.includes(keywordName);

    const addKeyword = (ruleKey, keyword) => {
        const ruleSet = new RuleSet(rules);
        if (ruleKey !== undefined) {
            ruleSet.changeRule(ruleKey, {
                addKeywords: [keyword],
            });
        } else {
            ruleSet.addRule({
                aggregation: ruleTypes.INCLUDED,
                keywords: [keyword],
            });
        }
        onChangeContextRules(ruleSet.rules);
    };

    const removeKeyword = (keyword) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeAllRules({
            removeKeywords: [keyword],
        });
        onChangeContextRules(ruleSet.rules);
    };

    return isTargeting ? (
        <Button
            size="small"
            theme="switch"
            prefix={
                <FontAwesomeIcon
                    icon={isKeywordTargeted ? faCheckCircle : faCircle}
                />
            }
            active={isKeywordTargeted}
            onClick={() => {
                if (isKeywordTargeted) {
                    removeKeyword(keywordName);
                } else {
                    addKeyword(targetRules[0].key, keywordName);
                }
            }}
        >
            Target
        </Button>
    ) : (
        <Button
            size="small"
            theme="switch"
            prefix={
                <FontAwesomeIcon
                    icon={isKeywordBlocked ? faCheckCircle : faCircle}
                />
            }
            active={isKeywordBlocked}
            onClick={() => {
                if (isKeywordBlocked) {
                    removeKeyword(keywordName);
                } else {
                    addKeyword(blockRule.key, keywordName);
                }
            }}
        >
            Block
        </Button>
    );
};

export default function TopKeywordsSection({
    report,
    context,
    tableColumns,
    onChangeContextRules,
}) {
    const getButtons = (item) => [
        <KeywordButton
            keywordName={item.name}
            isTargeting
            rules={context.rules}
            onChangeContextRules={onChangeContextRules}
        />,
        <KeywordButton
            keywordName={item.name}
            rules={context.rules}
            onChangeContextRules={onChangeContextRules}
        />,
    ];

    const tableData = {
        rows: report.keywords.map(
            ({ name, impressions, clicks, in_views: inViews }) => ({
                name,
                impressions,
                clicks,
                ctr: clicks / impressions,
                viewableImpressions: inViews,
                viewRate: inViews / impressions,
            }),
        ),
        comparedValues: {
            impressions: report.impressions,
            clicks: report.clicks,
            ctr: report.clicks / report.impressions,
            viewableImpressions: report.in_views,
            viewRate: report.in_views / report.impressions,
        },
    };

    return (
        <Box margin={['base', 0]}>
            <InsightsTable
                tableData={tableData}
                visibleColumns={tableColumns.map((option) => option.value)}
                filteringOptions={tableColumns}
                hasLimit
                getButtons={getButtons}
            />
        </Box>
    );
}
