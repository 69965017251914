import React, { Fragment, useState } from 'react';
import moment from 'moment';
import notificationCategories, {
    notificationCategoryGroups,
} from 'js/enums/notification-categories.enum';
import serviceTypes from 'js/enums/service-types.enum';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Notification from 'js/components/notification/notification';
import Panel from 'js/components/panel/panel';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';
import SectionTitle from 'js/components/section-title/section-title';
import Spinner from 'js/components/spinner/spinner';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import Text from 'js/components/text/text';
import { getJwtPayload } from 'js/utils';

const INITIAL_LIST_LIMIT = 30;
const PAGE_LIMIT = 10;

const getNotificationsByGroup = (notifications) => {
    const today = moment().startOf('day');
    const last7Days = moment().startOf('day').subtract(7, 'day');
    const last30Days = moment().startOf('day').subtract(30, 'day');

    const notificationsToday = notifications.filter((notification) =>
        today.isSame(notification.created, 'day'),
    );
    const notificationsLast7Days = notifications.filter((notification) =>
        moment(notification.created).isBetween(last7Days, today),
    );
    const notificationsLast30Days = notifications.filter((notification) =>
        moment(notification.created).isBetween(last30Days, last7Days),
    );

    return [
        {
            title: 'Today',
            list: notificationsToday,
        },
        {
            title: 'Last 7 Days',
            list: notificationsLast7Days,
        },
        {
            title: 'Last 30 Days',
            list: notificationsLast30Days,
        },
    ];
};

const Wrapper = ({ size, children }) => (
    <Section>
        <Container size={size}>
            <Box padding={['large', 0]}>{children}</Box>
        </Container>
    </Section>
);

const Message = ({ message }) => (
    <Box
        border={['base', 'dashed', 'gray', 'light']}
        borderRadius="round"
        margin={['base', 0]}
        padding="base"
    >
        <Text color={['gray', 'light']} size="large">
            {message}
        </Text>
    </Box>
);

function LimitButtons({
    currentLimit,
    minLimit,
    maxLimit,
    onViewLess,
    onViewMore,
}) {
    if (currentLimit <= minLimit && currentLimit >= maxLimit) return null;

    return (
        <Row>
            {currentLimit > minLimit && (
                <Col span="auto">
                    <Box padding={[0, 0, 'base']}>
                        <Button theme="outline" onClick={onViewLess}>
                            View Less
                        </Button>
                    </Box>
                </Col>
            )}

            {currentLimit < maxLimit && (
                <Col span="auto">
                    <Box padding={[0, 0, 'base']}>
                        <Button theme="outline" onClick={onViewMore}>
                            View More
                        </Button>
                    </Box>
                </Col>
            )}
        </Row>
    );
}

const Tab = ({ notifications, limit, onViewLess, onViewMore }) => {
    if (!notifications.length) {
        return <Message message="You currently have no notifications" />;
    }

    const visibleNotifications = notifications.slice(0, limit);
    const visibleNotificationsByGroup =
        getNotificationsByGroup(visibleNotifications);

    return (
        <>
            {visibleNotificationsByGroup.map(({ title, list }) => (
                <Fragment key={title}>
                    {list.length > 0 && (
                        <Box padding={['small', 'smaller', 'smaller']}>
                            <Text weight="bolder" size="large">
                                {title}
                            </Text>
                        </Box>
                    )}

                    {list.map((notification) => (
                        <Box key={notification.id} padding={[0, 0, 'base']}>
                            <Notification notification={notification} />
                        </Box>
                    ))}
                </Fragment>
            ))}

            <LimitButtons
                currentLimit={limit}
                minLimit={INITIAL_LIST_LIMIT}
                maxLimit={notifications.length}
                onViewLess={onViewLess}
                onViewMore={onViewMore}
            />
        </>
    );
};

function NotificationList({ notifications, isLoading, hasErrors, onRetry }) {
    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );

    const pageTabs = [
        {
            id: notificationCategoryGroups.ALL,
            label: 'All',
        },
        {
            id: notificationCategoryGroups.DEAL_IDS,
            label: 'Deal IDs',
        },
        {
            id: notificationCategoryGroups.CONTEXTS,
            label: 'Contexts',
        },
    ];

    if (insightsService) {
        pageTabs.splice(2, 0, {
            id: notificationCategoryGroups.INSIGHTS,
            label: 'Insights',
        });
    }
    const [currentTabId, setCurrentTabId] = useState(pageTabs[0].id);
    const [allNotificationsLimit, setAllNotificationsLimit] =
        useState(INITIAL_LIST_LIMIT);
    const [dealIdNotificationsLimit, setDealIdNotificationsLimit] =
        useState(INITIAL_LIST_LIMIT);
    const [insightsNotificationsLimit, setInsightsNotificationsLimit] =
        useState(INITIAL_LIST_LIMIT);
    const [contextNotificationsLimit, setContextNotificationsLimit] =
        useState(INITIAL_LIST_LIMIT);

    const allNotifications = notifications;
    const dealIdNotifications = notifications.filter((notification) =>
        [
            notificationCategories.DEAL_PAUSED,
            notificationCategories.DEAL_READY,
            notificationCategories.DEAL_UNPAUSED,
            notificationCategories.DEAL_WILL_BE_PAUSED,
        ].includes(notification.category),
    );
    const insightsNotifications = notifications.filter((notification) =>
        [
            notificationCategories.OPTIMISE_REPORT_READY,
            notificationCategories.VIDEO_REPORT_READY,
            notificationCategories.EVENT_LOG_REPORT_READY,
            notificationCategories.CAMPAIGN_INACTIVE,
        ].includes(notification.category),
    );
    const contextNotifications = notifications.filter((notification) =>
        [
            notificationCategories.AUTO_OPTIMIZATION_APPLIED,
            notificationCategories.AUTO_OPTIMIZATION_INCOMPLETE,
            notificationCategories.AUTO_OPTIMIZATION_STOPPED,
        ].includes(notification.category),
    );

    if (isLoading) {
        return (
            <Wrapper>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Wrapper>
        );
    }

    if (hasErrors) {
        return (
            <Wrapper size="small">
                <Alert
                    theme="danger"
                    title="There was an error loading the page"
                    actionItems={<Button onClick={onRetry}>Retry</Button>}
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try again
                        in a few moments.
                    </p>
                </Alert>
            </Wrapper>
        );
    }

    const buttonsWrapper = ({ children }) => (
        <Box padding={['base', 0]}>
            <Row justifyContent="center">
                <Col span="auto">{children}</Col>
            </Row>
        </Box>
    );

    return (
        <Wrapper>
            <SectionTitle>Notifications</SectionTitle>

            <Box margin={['large', 0, 0]}>
                <Panel bordered>
                    <Box padding={[0, 'base']}>
                        <Tabs
                            tabs={pageTabs}
                            currentTab={currentTabId}
                            buttonsWrapper={buttonsWrapper}
                            onChangeTab={setCurrentTabId}
                        >
                            <TabContent tabId={notificationCategoryGroups.ALL}>
                                <Tab
                                    notifications={allNotifications}
                                    limit={allNotificationsLimit}
                                    onViewLess={() =>
                                        setAllNotificationsLimit(
                                            INITIAL_LIST_LIMIT,
                                        )
                                    }
                                    onViewMore={() =>
                                        setAllNotificationsLimit(
                                            (prev) => prev + PAGE_LIMIT,
                                        )
                                    }
                                />
                            </TabContent>

                            <TabContent
                                tabId={notificationCategoryGroups.DEAL_IDS}
                            >
                                <Tab
                                    notifications={dealIdNotifications}
                                    limit={dealIdNotificationsLimit}
                                    onViewLess={() =>
                                        setDealIdNotificationsLimit(
                                            INITIAL_LIST_LIMIT,
                                        )
                                    }
                                    onViewMore={() =>
                                        setDealIdNotificationsLimit(
                                            (prev) => prev + PAGE_LIMIT,
                                        )
                                    }
                                />
                            </TabContent>

                            <TabContent
                                tabId={notificationCategoryGroups.INSIGHTS}
                            >
                                <Tab
                                    notifications={insightsNotifications}
                                    limit={insightsNotificationsLimit}
                                    onViewLess={() =>
                                        setInsightsNotificationsLimit(
                                            INITIAL_LIST_LIMIT,
                                        )
                                    }
                                    onViewMore={() =>
                                        setInsightsNotificationsLimit(
                                            (prev) => prev + PAGE_LIMIT,
                                        )
                                    }
                                />
                            </TabContent>

                            <TabContent
                                tabId={notificationCategoryGroups.CONTEXTS}
                            >
                                <Tab
                                    notifications={contextNotifications}
                                    limit={contextNotificationsLimit}
                                    onViewLess={() =>
                                        setContextNotificationsLimit(
                                            INITIAL_LIST_LIMIT,
                                        )
                                    }
                                    onViewMore={() =>
                                        setContextNotificationsLimit(
                                            (prev) => prev + PAGE_LIMIT,
                                        )
                                    }
                                />
                            </TabContent>
                        </Tabs>
                    </Box>
                </Panel>
            </Box>
        </Wrapper>
    );
}

export default NotificationList;
