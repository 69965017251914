import React from 'react';
import Input from 'js/components/input/input';

export default function NameStep({ template, errors, onChangeName }) {
    return (
        <Input
            label="Name"
            value={template.name}
            focusOnShow
            maxLength={100}
            hasError={!!errors.name}
            errorMessage={<p>{errors.name}</p>}
            onChange={(ev) => onChangeName(ev.target.value)}
        />
    );
}
