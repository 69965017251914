import React, { useState } from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Text from 'js/components/text/text';
import moment from 'moment';
import styles from './trend-chart.module.scss';
import { parseDate } from './helpers';

const getMousePosition = (e, width, height) => {
    const dimensions = e.currentTarget.getBoundingClientRect();
    const rawX = e.clientX - dimensions.left;
    const rawY = e.clientY - dimensions.top;
    const x = (rawX / dimensions.width) * width;
    const y = (rawY / dimensions.height) * height;
    return { x, y };
};

export const TooltipLayer = ({ series, width, height, children }) => {
    const [mousePosition, setMousePosition] = useState();

    const handleMouseMove = (event) => {
        const mouse = getMousePosition(event, width, height);
        if (mouse.x !== mousePosition?.x) {
            setMousePosition(mouse);
        }
    };

    const eventLayer = (
        <rect
            width={width > 0 ? width : 0}
            height={height > 0 ? height : 0}
            pointerEvents="all"
            fill="none"
            stroke="none"
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setMousePosition(undefined)}
        />
    );

    if (!mousePosition || !children || !series[0]?.data?.length) {
        return eventLayer;
    }

    const tooltipDataIndex = Math.round(
        (mousePosition.x / width) * (series[0].data.length - 1),
    );
    const tooltipDate = series[0].data[tooltipDataIndex].date;

    const tooltipX =
        (series[0].data.length === 1
            ? width / 2
            : (tooltipDataIndex / (series[0].data.length - 1)) * width) + 0.5; // 0.5 for the thickness of the line

    const placeRight = mousePosition.x < width / 2;

    return (
        <>
            <line
                className={styles.indicator}
                x1={tooltipX}
                y1={0}
                x2={tooltipX}
                y2={height}
            />
            <g pointerEvents="none">
                <foreignObject
                    overflow="visible"
                    width={width}
                    height={height}
                    x="0"
                    y="0"
                    style={{ position: 'relative' }}
                >
                    <div
                        className={styles.tooltipContainer}
                        style={
                            placeRight
                                ? { left: tooltipX }
                                : { right: width - tooltipX }
                        }
                    >
                        {React.Children.map(children, (child) =>
                            React.isValidElement(child)
                                ? React.cloneElement(child, {
                                      series,
                                      date: tooltipDate,
                                  })
                                : child,
                        )}
                    </div>
                </foreignObject>
            </g>
            {eventLayer}
        </>
    );
};

const Tooltip = ({ series, date }) => (
    <Box
        borderRadius="rounder"
        background={['gray', 'white']}
        padding="base"
        shadow="deep"
    >
        <Text color={['gray', 'darkest']} size="small">
            {moment(parseDate(date)).format('DD MMMM YYYY')}
        </Text>
        {series.map((s, i) => {
            const data = s.data.find((d) => d.date === date);
            return (
                <Box
                    margin={
                        i === series.length - 1
                            ? ['small', 0, 0, 0]
                            : ['small', 0]
                    }
                    key={s.id}
                >
                    <Row>
                        <Col span="auto">
                            <Box display="flex" padding={[0, 'large', 0, 0]}>
                                {s.legend}
                                <Text
                                    color={['gray', 'darkest']}
                                    weight="bolder"
                                >
                                    {s.name}
                                </Text>
                            </Box>
                        </Col>
                        <Col>
                            <Text align="right" color={['gray', 'darkest']}>
                                <Number
                                    value={data.value}
                                    isPercentage={s.format === '%'}
                                    maximumFractionDigits={
                                        s.format === '%' ? 2 : 0
                                    }
                                />
                            </Text>
                        </Col>
                    </Row>
                </Box>
            );
        })}
    </Box>
);

export default Tooltip;
