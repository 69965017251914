import React, { useEffect } from 'react';
import { withContextList } from 'js/hocs';
import { setDocumentTitle } from 'js/utils';
import ActionBar from 'js/components/action-bar/action-bar';
import Button from 'js/components/button/button';
import Crumb from 'js/components/breadcrumbs/crumb';
import FavoritesButton from 'js/components/button/favorites-button';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import { useParams } from 'react-router-dom';
import ContextListBody from './components/context-list-body';

function ContextListPage({
    loading,
    errors,
    readOnly,
    advertiser,
    campaign,
    contexts,
    ordering,
    togglingFavoriteError,
    onToggleFavoriteCampaign,
    onToggleFavoriteContext,
    onUpdateFilter,
    onDeleteContext,
}) {
    const { campaignId } = useParams();

    const getBreadcrumbs = () => {
        const breadcrumbs = [<Crumb key="home" to="/" label="4D" />];

        breadcrumbs.push(
            <Crumb
                key="advertisers"
                to="/context/"
                label="Advertisers"
                isCollapsible
            />,
        );

        if (Object.keys(errors).length > 0) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading && !advertiser.name && !campaign.name) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else {
            breadcrumbs.push(
                <Crumb
                    key="advertiser"
                    to={`/context/${advertiser.id}/`}
                    label={advertiser.name}
                    isCollapsible
                />,
                <Crumb key="campaign" label={campaign.name} />,
            );
        }

        return breadcrumbs;
    };

    useEffect(() => {
        if (campaign.name) {
            setDocumentTitle([campaign.name, advertiser.name, 'Contexts']);
        }
    }, [campaign, advertiser]);

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
        >
            <ActionBar>
                <FavoritesButton
                    key="favorite"
                    isFavorite={campaign.is_favourite}
                    isLoading={loading || campaign.isFavoriteLoading}
                    onToggleFavorite={onToggleFavoriteCampaign}
                />

                <Button
                    key="edit"
                    theme="outline"
                    to={`/campaigns/${campaignId}/`}
                >
                    Edit
                </Button>
            </ActionBar>

            <ContextListBody
                loading={loading}
                errors={errors}
                readOnly={readOnly}
                advertiser={advertiser}
                campaign={campaign}
                contexts={contexts}
                ordering={ordering}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavoriteContext={onToggleFavoriteContext}
                onUpdateFilter={onUpdateFilter}
                onDeleteContext={onDeleteContext}
            />
        </Layout>
    );
}

export default withContextList(ContextListPage);
