import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from './emblem.styles';

const EmblemContent = ({ orientation, logo, label, size }) => {
    const isPortrait = orientation === 'portrait';

    if (logo) {
        return (
            <Image
                src={logo}
                style={{ width: (size * 2) / 3, height: (size * 2) / 3 }}
            />
        );
    }

    return (
        <Text style={isPortrait ? styles.labelPortrait : styles.labelLandscape}>
            {label}
        </Text>
    );
};

function Emblem({ orientation, logo, label, background, color, size }) {
    return (
        <View
            style={[
                styles.emblem,
                {
                    width: size,
                    height: size,
                    backgroundColor: background,
                    color,
                },
            ]}
        >
            <EmblemContent
                orientation={orientation}
                logo={logo}
                label={label}
                size={size}
            />
        </View>
    );
}

Emblem.defaultProps = {
    orientation: 'portrait',
    logo: undefined,
    label: undefined,
    background: undefined,
    color: '#fff',
    size: 70,
};

Emblem.propTypes = {
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    logo: PropTypes.string,
    label: PropTypes.string,
    background: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
};

export default Emblem;
