import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useScroller } from 'js/hooks';
import styles from './tabs.module.scss';

const cx = classNames.bind(styles);

function TabButtons({ children, tabStyle, size }) {
    const scrollWrapperRef = useRef();
    const {
        isLeftScrollerVisible,
        isRightScrollerVisible,
        scrollToLeftEdge,
        scrollToRightEdge,
    } = useScroller(scrollWrapperRef);

    const listClasses = cx({
        list: true,
        [`tab-style--${tabStyle}`]: tabStyle,
        [`tab-size--${size}`]: size,
    });

    return (
        <div className={styles.container}>
            <div ref={scrollWrapperRef} className={styles['scroll-wrapper']}>
                {isLeftScrollerVisible && (
                    <button
                        className={cx('scroller', 'scroller--left')}
                        onClick={scrollToLeftEdge}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                )}

                <ul className={classNames(listClasses)}>{children}</ul>

                {isRightScrollerVisible && (
                    <button
                        className={cx('scroller', 'scroller--right')}
                        onClick={scrollToRightEdge}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                )}
            </div>
        </div>
    );
}

TabButtons.defaultProps = {
    tabStyle: undefined,
    size: undefined,
};

TabButtons.propTypes = {
    children: PropTypes.node.isRequired,
    tabStyle: PropTypes.oneOf(['flush', 'block', 'switch']),
    size: PropTypes.oneOf(['small']),
};

export default TabButtons;
