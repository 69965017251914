import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './section.module.scss';

const cx = classNames.bind(styles);

function Section({
    className,
    children,
    theme,
    backgroundImg,
    backgroundAppearance,
    hasBorderTop,
    hasDarkerBorderTop,
    hasBorderBottom,
    hasDarkerBorderBottom,
}) {
    const sectionClasses = cx({
        container: true,
        [`container--${theme}`]: theme,
        'container--has-border-top': hasBorderTop,
        'container--has-darker-border-top': hasDarkerBorderTop,
        'container--has-border-bottom': hasBorderBottom,
        'container--has-darker-border-bottom': hasDarkerBorderBottom,
        [className]: className,
    });

    const backgroundClasses = cx({
        [`background--${backgroundAppearance}`]: backgroundAppearance,
    });

    return (
        <div className={sectionClasses}>
            <div
                className={backgroundClasses}
                style={{
                    backgroundImage: backgroundImg
                        ? `url(${backgroundImg})`
                        : null,
                }}
            >
                {children}
            </div>
        </div>
    );
}

Section.defaultProps = {
    className: '',
    theme: 'primary',
    backgroundImg: undefined,
    backgroundAppearance: '',
    hasBorderTop: false,
    hasDarkerBorderTop: false,
    hasBorderBottom: false,
    hasDarkerBorderBottom: false,
};

Section.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    theme: PropTypes.oneOf(['primary', 'secondary']),
    backgroundImg: PropTypes.string,
    backgroundAppearance: PropTypes.oneOf(['', 'centered']),
    hasBorderTop: PropTypes.bool,
    hasDarkerBorderTop: PropTypes.bool,
    hasBorderBottom: PropTypes.bool,
    hasDarkerBorderBottom: PropTypes.bool,
};

export default Section;
