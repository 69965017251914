import React from 'react';
import { capitalize } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import DrawerFooter from 'js/components/drawer/footer';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import HelpDropdown from 'js/components/help-dropdown/help-dropdown';
import Layout from 'js/components/layout/layout';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import Section from 'js/components/section/section';
import Statistic from 'js/components/statistic/statistic';
import Text from 'js/components/text/text';

const SummaryPanel = ({ hasData, dealReportMetrics }) => (
    <Panel theme="secondary">
        <Box padding="base">
            <Box margin={[0, 0, 'base', 0]}>
                <Text size="larger" weight="bold">
                    Summary
                </Text>
            </Box>
            <Panel>
                {hasData ? (
                    <Box padding="base">
                        <Row gap="base">
                            {dealReportMetrics.map((metric) => (
                                <Statistic
                                    key={metric.key}
                                    name={metric.name}
                                    info={metric.helpText}
                                >
                                    <Number value={metric.value} />
                                </Statistic>
                            ))}
                        </Row>
                    </Box>
                ) : (
                    <Alert theme="empty">No data available</Alert>
                )}
            </Panel>
        </Box>
    </Panel>
);

const TroubleshootingSection = ({ dealReportMetrics }) => (
    <Section hasBorderTop>
        <Container size="medium">
            <Box padding={['large', 0]}>
                <Box margin={[0, 0, 'base', 0]}>
                    <Text size="larger" weight="bolder">
                        Troubleshooting
                    </Text>
                </Box>
                {dealReportMetrics.map((metric) => (
                    <HelpDropdown key={metric.key} title={metric.name}>
                        {metric.troubleShooting}
                    </HelpDropdown>
                ))}
            </Box>
        </Container>
    </Section>
);

const DrawerContent = ({
    deal,
    hasError,
    hasData,
    dealReportMetrics,
    onClose,
}) => (
    <Layout
        header={
            <Container size="medium">
                <Box padding={['large', 0]}>
                    <Text size="huge">
                        Deal Delivery Report for{' '}
                        <Text weight="bold" inline>
                            {capitalize(deal.deal_provider_type)} {deal.deal_id}
                        </Text>
                    </Text>
                </Box>
            </Container>
        }
        footer={
            <DrawerFooter>
                <Container size="medium">
                    <Box padding={['small', 0]}>
                        <Row justifyContent="flex-end">
                            <Col span="auto">
                                <Button onClick={onClose}>Done</Button>
                            </Col>
                        </Row>
                    </Box>
                </Container>
            </DrawerFooter>
        }
        isFooterSticky
    >
        <Section>
            <Container size="medium">
                <Box padding={[0, 0, 'large', 0]}>
                    {hasError ? (
                        <Alert theme="danger">
                            {' '}
                            An error occured while retreiving the Deal Delivery
                            Report. Please check back later or contact your 4D
                            account manager.
                        </Alert>
                    ) : (
                        <SummaryPanel
                            hasData={hasData}
                            dealReportMetrics={dealReportMetrics}
                        />
                    )}
                </Box>
            </Container>
        </Section>
        {hasData && !hasError && (
            <TroubleshootingSection dealReportMetrics={dealReportMetrics} />
        )}
    </Layout>
);

export default DrawerContent;
