import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './container.module.scss';

const cx = classNames.bind(styles);

function Container({ children, size, style }) {
    const containerClasses = cx({
        container: true,
        [`size--${size}`]: size,
    });

    return (
        <div style={style} className={containerClasses}>
            {children}
        </div>
    );
}

Container.defaultProps = {
    size: 'base',
    style: {},
};

Container.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'base', 'full-width']),
    style: PropTypes.object,
};

export default Container;
