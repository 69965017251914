import React, { useState } from 'react';
import sortTypes from 'js/enums/sort-types.enum';
import { sortOptions } from 'js/constants';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardAdvertiser from 'js/components/card/card-advertiser';
import CardCreateNew from 'js/components/card/card-create-new';
import CardLoading from 'js/components/card/card-loading';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import Select from 'js/components/select/select';
import Text from 'js/components/text/text';

const Wrapper = ({ children }) => (
    <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
        <Box padding={['base', 0, 0]}>{children}</Box>
    </Col>
);

const FavoriteAdvertisers = ({ advertisers, onToggleAdvertiserFavorite }) => (
    <Panel theme="secondary">
        <Box padding="base">
            <Text weight="bold" size="large">
                Favorites
            </Text>

            {advertisers.map((advertiser) => (
                <Box padding={['small', 0, 0]} key={advertiser.id}>
                    <CardAdvertiser
                        isSlim
                        advertiser={advertiser}
                        to={`/insights/${advertiser.id}/`}
                        onToggleFavorite={() =>
                            onToggleAdvertiserFavorite(advertiser)
                        }
                    />
                </Box>
            ))}
        </Box>
    </Panel>
);

const AdvertiserList = ({
    advertisers,
    templates,
    isLoading,
    hasError,
    onToggleAdvertiserFavorite,
    onRetry,
}) => {
    const [sorting, setSorting] = useState(sortTypes.UPDATED_DESC);

    const getReportsUpdateTime = (advertiser) =>
        templates
            .filter((template) => template.advertiser === advertiser.id)
            .reduce(
                (prev, curr) =>
                    curr.deep_updated > prev.deep_updated ? curr : prev,
                advertiser,
            ).deep_updated;

    const getReportsCount = (advertiser) =>
        templates.filter((template) => template.advertiser === advertiser.id)
            .length;

    const sortedAdvertisers = advertisers
        .map((advertiser) => ({
            ...advertiser,
            deep_updated: getReportsUpdateTime(advertiser),
            reports: getReportsCount(advertiser),
        }))
        .sort((a, b) => {
            const desc = sorting[0] === '-';
            const field = sorting
                .replace(sortTypes.UPDATED_ASC, sortTypes.DEEP_UPDATED_ASC)
                .substring(desc ? 1 : 0);
            return a[field].localeCompare(b[field]) * (desc ? -1 : 1);
        })
        .filter(({ reports }) => reports > 0);

    const favoriteAdvertisers = sortedAdvertisers.filter(
        (item) => item.is_favourite,
    );

    const hasReports = sortedAdvertisers.length > 0;

    return (
        <>
            <SectionTitle
                hasBar
                extension={[
                    <Button to="/insights/new/" theme="outline">
                        Create Report
                    </Button>,
                    <Select
                        prefix="Sort by"
                        theme="filter"
                        options={sortOptions}
                        selectedValues={sorting}
                        onChange={setSorting}
                    />,
                ]}
            >
                {isLoading || hasReports ? 'Advertisers' : 'Reports'}
            </SectionTitle>

            {favoriteAdvertisers.length > 0 && (
                <Box padding={['large', 0, 0]}>
                    <FavoriteAdvertisers
                        advertisers={favoriteAdvertisers}
                        onToggleAdvertiserFavorite={onToggleAdvertiserFavorite}
                    />
                </Box>
            )}

            <Box margin={['base', 0, 0]}>
                <Row gutter="base">
                    {hasError && (
                        <Wrapper>
                            <Alert
                                theme="danger"
                                actionItems={
                                    <Button onClick={() => onRetry()}>
                                        Retry
                                    </Button>
                                }
                                title="There was an error loading the advertisers"
                            >
                                <p>
                                    Hopefully it is only a temporary issue.
                                    Please try again in a few moments.
                                </p>
                            </Alert>
                        </Wrapper>
                    )}

                    {!hasError && isLoading && (
                        <Wrapper>
                            <CardLoading loadingText="Loading Advertisers" />
                        </Wrapper>
                    )}

                    {!hasError && !isLoading && (
                        <>
                            {sortedAdvertisers.map((advertiser) => (
                                <Wrapper key={advertiser.id}>
                                    <CardAdvertiser
                                        advertiser={advertiser}
                                        to={`/insights/${advertiser.id}/`}
                                    />
                                </Wrapper>
                            ))}

                            <Wrapper>
                                <CardCreateNew
                                    title="Create New Report"
                                    to="/insights/new/"
                                />
                            </Wrapper>
                        </>
                    )}
                </Row>
            </Box>
        </>
    );
};

export default AdvertiserList;
