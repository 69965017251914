import React, { useEffect, useRef, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { SEARCH_DEBOUNCE_TIMEOUT } from 'js/constants';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Spinner from 'js/components/spinner/spinner';
import Button from 'js/components/button/button';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import styles from './dropdown-menu.module.scss';

// TODO: move to select component

const DropdownSearch = ({
    placeholder,
    hasError,
    errorMessage,
    searching,
    onPreSearch,
    onSearch,
}) => {
    const formRef = useRef();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (onPreSearch) {
            onPreSearch(searchTerm);
        }
        const searchDebounce = setTimeout(
            async () => {
                await onSearch(searchTerm);
            },
            searchTerm ? SEARCH_DEBOUNCE_TIMEOUT : 0,
        );

        return () => {
            clearTimeout(searchDebounce);
        };
    }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

    const retry = async () => {
        if (onPreSearch) {
            await onPreSearch(searchTerm);
        }
        await onSearch(searchTerm);
    };

    return (
        <>
            <Form ref={formRef} className={styles['search-form']}>
                <Input
                    value={searchTerm}
                    placeholder={placeholder}
                    prefix={<FontAwesomeIcon icon={faSearch} />}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClear={() => setSearchTerm('')}
                    focusOnShow
                />
            </Form>

            {searching && (
                <div className={styles['container--searchable']}>
                    <Box margin="auto">
                        <Spinner
                            size="small"
                            message="Searching"
                            color={['gray', 'dark']}
                            isCentered
                        />
                    </Box>
                </div>
            )}
            {hasError && (
                <div className={styles.error}>
                    <Alert
                        actionItems={
                            <Row gutter="smaller">
                                <Col span="auto">
                                    <Button onClick={retry}>Retry</Button>
                                </Col>
                            </Row>
                        }
                        theme="danger"
                    >
                        {errorMessage}
                    </Alert>
                </div>
            )}
        </>
    );
};

DropdownSearch.defaultProps = {
    placeholder: '',
    hasError: false,
    errorMessage: (
        <p>
            There was an unexpected error while searching. Hopefully it is only
            a temporary issue. Please try again in a few moments.
        </p>
    ),
    searching: false,
    onPreSearch: undefined,
};

DropdownSearch.propTypes = {
    /**
     * The search input placeholder
     */
    placeholder: PropTypes.string,
    /**
     * If true, fetching the search results resulted in an error
     */
    hasError: PropTypes.bool,
    /**
     * The error message from the error defined by hasError
     */
    errorMessage: PropTypes.node,
    /**
     * If true, the searching is in progress
     */
    searching: PropTypes.bool,
    /**
     * a "pre-search" callback, to (for example) cancel ongoing search requests
     */
    onPreSearch: PropTypes.func,
    /**
     * A search callback. Called after the debounce timer is expired
     */
    onSearch: PropTypes.func.isRequired,
};

export default DropdownSearch;
