import React, { useState } from 'react';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import CopyButton from 'js/components/button/copy-button';
import Button from 'js/components/button/button';
import Text from 'js/components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import DropdownButton from 'js/components/dropdown-menu/button';
import DealDeliveryReportDrawer from './deal-delivery-report-drawer/deal-delivery-report-drawer';

const DealStateActionsButton = ({ deal }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);

    const toggleShowMenu = () => {
        setShowMenu((previous) => !previous);
    };

    return (
        <>
            <DropdownMenu
                onHide={() => setShowMenu(false)}
                showMenu={showMenu}
                content={
                    <ul>
                        <CopyButton
                            value={deal.deal_id}
                            message={
                                <Text color={['gray', 'darker']}>Copied</Text>
                            }
                        >
                            <DropdownButton>Copy Deal ID</DropdownButton>
                        </CopyButton>

                        <DropdownButton onClick={() => setShowDrawer(true)}>
                            View Deal Delivery Report
                        </DropdownButton>
                    </ul>
                }
            >
                <Button
                    theme="outline"
                    active={showMenu}
                    size="small"
                    square
                    onClick={toggleShowMenu}
                >
                    <FontAwesomeIcon icon={faEllipsisV} />
                </Button>
            </DropdownMenu>
            {showDrawer && (
                <DealDeliveryReportDrawer
                    onClose={() => setShowDrawer(false)}
                    deal={deal}
                />
            )}
        </>
    );
};

export default DealStateActionsButton;
