import { showToast } from 'js/components/toast/toast';
import {
    contextTemplate,
    dealTemplate,
    displayOptimizeTemplate,
    videoOptimizeTemplate,
} from 'js/constants';
import entityTypes from 'js/enums/entity-types.enum';
import languageTypes from 'js/enums/language-types.enum';
import permissionGroups from 'js/enums/permission-groups.enum';
import regionTypes from 'js/enums/region-types.enum';
import sectionTypes from 'js/enums/section-types.enum';
import serviceTypes from 'js/enums/service-types.enum';
import {
    AbortError,
    api,
    deepCopy,
    getEventTagSourceOrDefault,
    getJwtPayload,
    getPreset,
    isActiveOrigin,
    RuleSet,
    setDocumentTitle,
    topicsOrLogosHasChanged,
} from 'js/utils';
import React, { Component } from 'react';
import {
    autoDealProviderTypes,
    MATCHED_PAGES_REGION,
    MATCHED_VIDEOS_REGION,
} from './constants';
import ContextDetail from './context-detail';
import ContextDetailContext from './contexts/context-detail.context';

// get a nested page section
const getPageSection = (sections, sectionId) =>
    sections.find((item) => item.id === sectionId) || {
        isActive: true,
    };

// scroll to ref (if ref available in pageAnchorRefs)
const smoothScrollToAnchor = (e, sectionId, pageAnchorRefs) => {
    pageAnchorRefs[
        `${sectionId}Ref`
    ].current.childNodes[0].childNodes[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });
    e.preventDefault();
};

class ContextDetailPage extends Component {
    static isReadOnly() {
        const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
        return (
            !jwt.is_superuser && jwt.roles.includes(permissionGroups.VIEW_ONLY)
        );
    }

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            isContextPriceLoading: false,
            scaleScore: null,
            scaleErrors: {},
            scaleChanging: true,
            error: false,
            saving: false,
            amending: {},
            advertisers: [],
            campaigns: [],
            channels: [],
            topics: [],
            topicGroups: [],
            logoGroups: [],
            context: deepCopy(contextTemplate),
            savedContext: deepCopy(contextTemplate),
            contextErrors: {},
            deals: [],
            savedDeals: [],
            dealErrors: {},
            hasUnsavedChanges: false,
            priceStructure: {
                display_targeting_cpm_usd: 0,
            },
            optimize: deepCopy(displayOptimizeTemplate),
            eventHierarchy: null,
            matchedPages: {
                isError: false,
                isLoading: false,
                isLazyWaiting: true,
                needsReload: false,
                list: [],
                region:
                    localStorage.getItem(MATCHED_PAGES_REGION) ||
                    regionTypes.ANY,
            },
            matchedVideos: {
                isError: false,
                isLoading: false,
                isLazyWaiting: true,
                needsReload: false,
                list: [],
                region:
                    localStorage.getItem(MATCHED_VIDEOS_REGION) ||
                    regionTypes.ANY,
            },
            pageSections: [],
            pageAnchorRefs: {},
            navigationTarget: null,
            isFavoriteLoading: false,
            togglingFavoriteError: {},
        };

        this.state = deepCopy(this.initialState);
    }

    componentDidMount() {
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        const {
            match: {
                params: { contextId },
            },
            history,
        } = this.props;
        const { hasUnsavedChanges, navigationTarget } = this.state;
        if (!hasUnsavedChanges && navigationTarget) {
            this.setNavigationTarget(null);
            history.push(navigationTarget.pathname + navigationTarget.search);
        }
        if (contextId !== prevProps.match.params.contextId) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                {
                    ...deepCopy(this.initialState),
                    hasUnsavedChanges: contextId === 'new',
                },
                this.loadData,
            );
        }
    }

    componentWillUnmount() {
        api().abortAll();
    }

    get contextId() {
        const { match } = this.props;
        const { contextId } = match.params;

        return contextId === 'new' ? undefined : contextId;
    }

    updateDocumentTitle = (context) => {
        if (this.contextId && context.group_name) {
            setDocumentTitle([context.name, context.group_name, 'Contexts']);
        } else {
            setDocumentTitle(['Create Context', 'Contexts']);
        }
    };

    setPageSections = () => {
        const {
            context: { is_video: isVideo },
            pageAnchorRefs,
        } = this.state;
        const anchorRefs = {};
        const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
        const matchedPagesService = services.find(
            (service) => service.name === serviceTypes.MATCHED_PAGES,
        );
        const matchedVideosService = services.find(
            (service) => service.name === serviceTypes.MATCHED_VIDEOS,
        );
        const pageSections = [
            {
                id: sectionTypes.DETAILS,
                title: 'Details',
            },
            {
                id: sectionTypes.CHANNELS,
                title: 'Channels',
            },
            {
                id: sectionTypes.DIMENSIONS,
                title: 'Dimensions',
            },
            {
                id: sectionTypes.OPTIMIZE,
                title: 'Optimize',
            },
        ];

        if (!isVideo) {
            if (matchedPagesService) {
                pageSections.push({
                    id: sectionTypes.MATCHED_PAGES,
                    title: 'Matched Pages',
                });
            }
        } else if (isVideo && matchedVideosService) {
            pageSections.push({
                id: sectionTypes.MATCHED_VIDEOS,
                title: 'Matched Videos',
            });
        }

        pageSections.forEach((section) => {
            anchorRefs[`${section.id}Ref`] =
                pageAnchorRefs[`${section.id}Ref`] || React.createRef();
        });

        this.setState({ pageSections, pageAnchorRefs: anchorRefs });
    };

    getContextPrice = async () => {
        this.setState({ isContextPriceLoading: true });

        const {
            context,
            context: { rules },
        } = this.state;

        const nonEmptyRules = rules.filter(
            (rule) =>
                rule.keywords.length || rule.topics.length || rule.logos.length,
        );

        try {
            const resp = await api()
                .contexts.action('price')
                .create({
                    ...context,
                    rules: nonEmptyRules,
                    name: undefined,
                    group: undefined,
                });
            const { context: currentContext } = this.state; // race mitigation

            this.setState({
                context: { ...currentContext, ...resp },
                isContextPriceLoading: false,
            });
        } catch (errors) {
            if (errors instanceof AbortError) return;
            this.setState({ isContextPriceLoading: false }); // TODO: error reporting
        }
    };

    getScaleScore = async () => {
        const {
            context,
            context: { rules },
        } = this.state;
        const nonEmptyRules = rules.filter(
            (rule) => rule.keywords.length || rule.topics.length,
        );

        this.setState({ scaleChanging: true, scaleErrors: {} });

        try {
            const { score } = await api()
                .contexts.action('scale')
                .create({
                    ...context,
                    rules: nonEmptyRules,
                    name: undefined,
                    group: undefined,
                });

            if (!score || score === 0) {
                const errors = { score: 'Failed to calculate scale.' };

                throw errors;
            }

            this.setState({ scaleChanging: false, scaleScore: score });
        } catch (scaleErrors) {
            if (scaleErrors instanceof AbortError) return;
            this.setState({
                scaleChanging: false,
                scaleScore: null,
                scaleErrors,
            });
        }
    };

    getMatchedPages = async () => {
        const {
            context,
            context: { rules },
            matchedPages: { region },
        } = this.state;

        if (!rules.some((rule) => rule.keywords.length || rule.topics.length)) {
            this.setState({
                matchedPages: {
                    list: [],
                    isLoading: false,
                    isError: false,
                    needsReload: false,
                    isLazyWaiting: false,
                    region,
                },
            });
            return;
        }

        this.setState({
            matchedPages: {
                list: [],
                isLoading: true,
                isError: false,
                needsReload: false,
                isLazyWaiting: false,
                region,
            },
        });

        try {
            const { results: list } = await api().matchedPagesAnalytics.create(
                { ...context },
                {
                    limit: 40,
                    region,
                },
            );

            this.setState(() => ({
                matchedPages: {
                    list: list.map((item) => ({
                        ...item,
                    })),
                    isLoading: false,
                    isError: false,
                    needsReload: false,
                    isLazyWaiting: false,
                    region,
                },
            }));
        } catch (err) {
            if (err instanceof AbortError) return;
            this.setState(() => ({
                matchedPages: {
                    list: [],
                    isLoading: false,
                    isError: true,
                    isLazyWaiting: true,
                    needsReload: false,
                    region,
                },
            }));
        }
    };

    getMatchedVideos = async () => {
        const {
            context,
            context: { rules },
            matchedVideos: { region },
        } = this.state;

        if (!rules.some((rule) => rule.logos.length || rule.topics.length)) {
            this.setState({
                matchedVideos: {
                    list: [],
                    isLoading: false,
                    isError: false,
                    needsReload: false,
                    isLazyWaiting: false,
                    region,
                },
            });
            return;
        }

        this.setState({
            matchedVideos: {
                list: [],
                isLoading: true,
                isError: false,
                needsReload: false,
                isLazyWaiting: false,
                region,
            },
        });

        try {
            const { results: list } = await api().matchedVideosAnalytics.create(
                { ...context },
                {
                    limit: 40,
                    region,
                },
            );

            this.setState(() => ({
                matchedVideos: {
                    list,
                    isLoading: false,
                    isError: false,
                    needsReload: false,
                    isLazyWaiting: false,
                    region,
                },
            }));
        } catch (err) {
            if (err instanceof AbortError) return;
            this.setState(() => ({
                matchedVideos: {
                    list: [],
                    isLoading: false,
                    isError: true,
                    isLazyWaiting: true,
                    needsReload: false,
                    region,
                },
            }));
        }
    };

    validateContext = ({ attrs = [], throwErrors = false } = {}) => {
        const { context, contextErrors, amending } = this.state;
        const errors = { ...contextErrors };

        delete errors.non_field_errors;

        if (attrs.includes('name') || !attrs.length) {
            amending.name = true;

            delete errors.name;

            if (!context.name) {
                errors.name = 'A name is required.';
            }
        }

        if (attrs.includes('advertiser') || !attrs.length) {
            amending.advertiser = true;

            delete errors.advertiser;

            if (!context.advertiser) {
                errors.advertiser = 'An advertiser is required.';
            }
        }

        if (attrs.includes('group') || !attrs.length) {
            amending.group = true;

            delete errors.group;

            if (!context.group) {
                errors.group = 'A campaign is required.';
            }
        }

        this.setState({ contextErrors: errors, amending });

        if (Object.keys(errors).length) {
            if (throwErrors) throw errors;
            return false;
        }

        return true;
    };

    validateDeals = ({ throwErrors = false } = {}) => {
        const { context, deals, amending } = this.state;
        const errors = {};

        amending.deals = true;

        context.channels.forEach((channelId) => {
            const firstDeal = deals.find((deal) => deal.channel === channelId);
            if (firstDeal && !firstDeal.geotargets.length) {
                errors[channelId] = 'A country is required.';
            }
        });

        this.setState({ dealErrors: errors, amending });

        if (Object.keys(errors).length) {
            if (throwErrors) throw errors;
            return false;
        }

        return true;
    };

    saveContext = async () => {
        const toastUnknownError = 'There Was An Unexpected Error';
        this.setState({ saving: true });

        const { context, deals, savedDeals, optimize } = this.state;
        const { history } = this.props;
        const nonEmptyRules = context.rules.filter(
            (rule) =>
                rule.keywords.length || rule.topics.length || rule.logos.length,
        );

        let isValid = true;
        try {
            this.validateContext({ throwErrors: true });
        } catch (contextErrors) {
            this.setState({ saving: false, contextErrors });
            isValid = false;
        }
        try {
            this.validateDeals({ throwErrors: true });
        } catch (dealErrors) {
            this.setState({ saving: false, dealErrors });
            isValid = false;
        }
        if (!isValid) return;

        let updatedContext;
        try {
            if (this.contextId) {
                updatedContext = await api().contexts.update(this.contextId, {
                    ...context,
                    rules: nonEmptyRules,
                });
            } else {
                updatedContext = await api().contexts.create({
                    ...context,
                    rules: nonEmptyRules,
                });
            }
        } catch (err) {
            showToast(
                toastUnknownError,
                <p>
                    The context has not been saved. Please try again in a few
                    moments.
                </p>,
                null,
                'danger',
            );

            this.setState({ saving: false });
            return;
        }

        try {
            const enabledDeals = deals.filter(({ channel }) =>
                context.channels.includes(channel),
            );
            const addedDeals = enabledDeals.filter(({ id }) => !id);
            const updatedDeals = enabledDeals.filter(({ id }) => id);
            const removedDeals = savedDeals.filter(
                (deal) => !enabledDeals.some(({ id }) => id === deal.id),
            );

            const dealCallbacks = [
                ...addedDeals.map((deal) =>
                    api().deals.create(
                        {
                            ...deal,
                            rules: [
                                {
                                    aggregation: 'OR',
                                    contexts: [updatedContext.id],
                                },
                            ],
                        },
                        {},
                        { signal: null }, // avoids AbortErrors for POSTs
                    ),
                ),
                ...updatedDeals.map((deal) =>
                    api().deals.update(deal.id, deal),
                ),
                ...removedDeals.map(({ id }) => api().deals.destroy(id)),
            ];
            await Promise.all(dealCallbacks);
        } catch (err) {
            showToast(
                toastUnknownError,
                <p>
                    The context deals have not been saved. Please try again in a
                    few moments.
                </p>,
                null,
                'danger',
            );

            this.setState({ saving: false });
            return;
        }

        try {
            if (optimize.id) {
                await api().autoOptimizeReportTemplates.update(
                    optimize.id,
                    optimize.is_active ? optimize : { is_active: false },
                );
            } else if (optimize.is_active) {
                await api().autoOptimizeReportTemplates.create({
                    ...optimize,
                    context: updatedContext.id,
                });
            }
        } catch (err) {
            showToast(
                toastUnknownError,
                <p>
                    The context optimization settings have not been saved.
                    Please try again in a few moments.
                </p>,
                null,
                'danger',
            );

            this.setState({ saving: false });
            return;
        }

        this.setState({ hasUnsavedChanges: false });
        history.push(
            `/context/${updatedContext.advertiser}/${updatedContext.group}/`,
        );
    };

    deleteContext = async () => {
        // exceptions propagate to the calling DeleteWarningModal
        const { context } = this.state;
        const { history } = this.props;

        await api().contexts.destroy(context.id);
        history.push(`/context/${context.advertiser}/${context.group}/`);
    };

    loadData = async () => {
        this.setState({ loading: true, error: false });

        try {
            const callbacks = [
                api().advertisers.list({
                    ordering: 'name',
                }),

                api().contextGroups.list({
                    field: ['id', 'name', 'advertiser'],
                    ordering: 'name',
                    is_public: false,
                }),

                api().channels.list({
                    field: [
                        'description',
                        'id',
                        'integration',
                        'integration_key',
                        'name',
                        'logo',
                        'updated',
                        'deal_providers',
                        'is_video',
                    ],
                    verbose: true,
                    ordering: 'description',
                }),

                api().dealGeotargets.list(),

                api().logoGroups.list({ ordering: 'name', verbose: true }),

                this.contextId
                    ? api().deals.list({ contexts: [this.contextId] })
                    : { results: [] },

                api().priceStructures.retrieve('current'),

                this.contextId
                    ? api().contexts.retrieve(this.contextId, {
                          verbose: true,
                          is_public: false,
                          field: [
                              'id',
                              'created',
                              'updated',
                              'name',
                              'account',
                              'language',
                              'group',
                              'cpm_usd',
                              'is_video',
                              'scale',
                              'strategy',
                              'group_name',
                              'advertiser',
                              'advertiser_name',
                              'is_favourite',
                              'channels',
                              'rules',
                              // all except is_public
                          ],
                      })
                    : null,

                this.contextId
                    ? api().autoOptimizeReportTemplates.list({
                          context: this.contextId,
                          verbose: true,
                      })
                    : { results: [] },

                this.contextId
                    ? api().autoOptimizeRecommendations.list({
                          context: this.contextId,
                          ordering: '-created',
                      })
                    : { results: [] },
            ];

            const [
                { results: advertisers },
                { results: campaigns },
                { results: channels },
                { results: dealGeotargets },
                { results: logoGroups },
                { results: deals },
                priceStructure,
                contextData,
                { results: optimizeData },
                { results: optimizeRecommendations },
            ] = await Promise.all(callbacks);
            let context;
            let optimize;
            let hasUnsavedChanges = false;

            if (contextData) {
                context = contextData;
                optimize = optimizeData[0]
                    ? {
                          ...optimizeData[0],
                          recommendations: optimizeRecommendations,
                      }
                    : {
                          ...deepCopy(
                              context.is_video
                                  ? videoOptimizeTemplate
                                  : displayOptimizeTemplate,
                          ),
                          context: contextData.id,
                      };
            } else {
                const presetContext = getPreset();
                hasUnsavedChanges = Object.keys(presetContext).some(
                    (key) =>
                        ![
                            'advertiser',
                            'group',
                            'is_video',
                            'language',
                        ].includes(key),
                );

                context = {
                    ...deepCopy(contextTemplate),
                    ...presetContext,
                };
                optimize = deepCopy(
                    context.is_video
                        ? videoOptimizeTemplate
                        : displayOptimizeTemplate,
                );
            }

            const ruleSet = new RuleSet(context.rules);
            context.rules = ruleSet.rules;

            if (!context.advertiser) {
                const firstAdvertiser = advertisers[0];
                context.advertiser = firstAdvertiser?.id;
            }

            if (!context.group && context.advertiser) {
                const firstCampaign = campaigns.find(
                    (item) => item.advertiser === context.advertiser,
                );
                context.group = firstCampaign?.id;
            }

            dealGeotargets.sort((a, b) => a.country.localeCompare(b.country));
            const listFirstGeos = [
                'United States of America',
                'United Kingdom',
            ];
            let insertIndex = 0;
            listFirstGeos.forEach((geo) => {
                const target = dealGeotargets.find((g) => g.country === geo);
                if (target) {
                    dealGeotargets.splice(dealGeotargets.indexOf(target), 1);
                    dealGeotargets.splice(insertIndex, 0, target);
                    insertIndex += 1;
                }
            });

            // NOTE: geo-targets are a property of channels in the UI, of deals in the API.
            // We expect them to be the same for every deal of a channel and reset them if they are not.
            context.channels.forEach((channelId) => {
                const channelDeals = deals.filter(
                    (deal) => deal.channel === channelId,
                );
                const firstDeal = channelDeals[0];
                const hasIncongruentGeotargets = channelDeals.some(
                    ({ geotargets }) =>
                        geotargets.length !== firstDeal.geotargets.length ||
                        geotargets.some(
                            (geotargetId) =>
                                !firstDeal.geotargets.includes(geotargetId),
                        ),
                );
                if (hasIncongruentGeotargets) {
                    // should never happen for deals created via the UI
                    channelDeals.forEach((deal) =>
                        deal.geotargets.splice(0, deal.geotargets.length),
                    );
                }
            });

            const savedDeals = deepCopy(deals);
            const savedContext = deepCopy(context);
            this.updateDocumentTitle(savedContext);

            this.setState(
                {
                    loading: false,
                    context,
                    savedContext,
                    advertisers,
                    campaigns,
                    channels,
                    deals,
                    savedDeals,
                    dealGeotargets,
                    logoGroups,
                    priceStructure,
                    optimize,
                    hasUnsavedChanges,
                },
                () => {
                    this.setPageSections();
                    this.getScaleScore();

                    if (this.contextId) return;

                    this.getContextPrice();
                },
            );

            if (context.id) {
                this.validateContext();
                this.validateDeals();
            }

            this.loadTopics(
                context.is_video ? languageTypes.EN : context.language,
            );
        } catch (err) {
            this.setState({ loading: false, error: true });
        }
    };

    loadEventHierarchy = async () => {
        // NOTE: exceptions propagate to the caller
        const {
            context: { is_video: isVideo },
        } = this.state;

        const { results: eventHierarchyData } = await api().eventHierarchy.list(
            { is_video: isVideo, verbose: true },
        );

        eventHierarchyData.sort(
            (a, b) =>
                getEventTagSourceOrDefault(a.source).localeCompare(
                    getEventTagSourceOrDefault(b.source),
                ) ||
                a.campaign.localeCompare(b.campaign) ||
                a.line_item.localeCompare(b.line_item),
        );

        this.setState({
            eventHierarchy: eventHierarchyData,
        });
    };

    loadTopics = async (language) => {
        try {
            const { topics: prevTopics } = this.state;

            if (prevTopics[0]?.language === language) {
                return;
            }

            const { results: topics } = await api().topics.list({
                ordering: '-is_brand_safety,group__name,name',
                language,
                verbose: true,
            });

            const topicGroups = topics
                .filter((topic, i) => !i || topic.group !== topics[i - 1].group)
                .map((item) => ({
                    id: item.group,
                    name: item.group_name,
                    logo: item.group_logo,
                    topics: topics.filter(
                        (topic) => topic.group === item.group,
                    ),
                }));

            this.setState({
                topics,
                topicGroups,
            });
        } catch (err) {
            this.setState({
                topics: [],
                topicGroups: [],
            });
        }
    };

    toggleFavorite = async (entityType, favoriteId, isFavorite = true) => {
        if (entityType === entityTypes.CONTEXTS) {
            this.setState({
                isFavoriteLoading: true,
                togglingFavoriteError: {},
            });
        }
        try {
            await api()[entityType].favorite(favoriteId, isFavorite);

            if (entityType === entityTypes.CONTEXTS) {
                this.setState((prevState) => ({
                    context: {
                        ...prevState.context,
                        is_favourite: isFavorite,
                    },
                    isFavoriteLoading: false,
                }));
            }
        } catch (err) {
            const { context } = this.state;

            if (entityType === entityTypes.CONTEXTS) {
                this.setState({
                    isFavoriteLoading: false,
                    togglingFavoriteError: {
                        title: context.name,
                        isFavorite,
                    },
                });
            }
        }
    };

    appendAdvertiser = (advertiser) => {
        const { advertisers } = this.state;
        advertisers.push(advertiser);
        advertisers.sort((a, b) => a.name.localeCompare(b.name));

        this.setState({ advertisers });
    };

    appendCampaign = (campaign) => {
        const { campaigns } = this.state;
        campaigns.push(campaign);
        campaigns.sort((a, b) => a.name.localeCompare(b.name));

        this.setState({ campaigns });
    };

    getOnChangeAttr = (attr) => (e) => {
        const { context, amending } = this.state;
        context[attr] = e.target.value;

        if (amending[attr]) {
            this.validateContext({ attrs: [attr] });
        }

        this.setState({ context, hasUnsavedChanges: true });
    };

    setNavigationTarget = (navigationTarget) => {
        this.setState({ navigationTarget });
    };

    setHasUnsavedChanges = (hasUnsavedChanges) => {
        this.setState({ hasUnsavedChanges });
    };

    setRules = (rules) => {
        const { context } = this.state;
        const updatedContext = {
            ...deepCopy(context),
            rules,
        };
        this.setState(
            {
                context: updatedContext,
                hasUnsavedChanges: true,
            },
            () => {
                this.getScaleScore();
                this.getContextPrice();
                this.setMatchedPagesIsLazyWaiting(true);
                this.setPageSections();

                if (
                    updatedContext.is_video &&
                    topicsOrLogosHasChanged(context.rules, updatedContext.rules)
                ) {
                    this.setMatchedVideosIsLazyWaiting(true);
                }
            },
        );
    };

    setAdvertiser = (advertiserId) => {
        const { context, amending, campaigns } = this.state;
        const firstCampaign = campaigns.find(
            (item) => item.advertiser === advertiserId,
        );
        context.advertiser = advertiserId;
        context.group = firstCampaign ? firstCampaign.id : '';

        if (amending.advertiser || amending.group) {
            this.validateContext({ attrs: ['advertiser', 'group'] });
        }

        this.setState({ context, hasUnsavedChanges: true });
    };

    setCampaign = (campaignId) => {
        const { context, amending } = this.state;
        context.group = campaignId;

        if (amending.group) {
            this.validateContext({ attrs: ['group'] });
        }

        this.setState({ context, hasUnsavedChanges: true });
    };

    setStrategy = (newStrategy) => {
        this.setState(
            (prevState) => ({
                context: {
                    ...prevState.context,
                    strategy: newStrategy,
                },
                hasUnsavedChanges: newStrategy !== prevState.context.strategy,
            }),
            () => {
                this.getScaleScore();
            },
        );
    };

    setLanguage = (language) => {
        const { context } = this.state;
        const { is_video: isVideo } = context;
        context.language = language;

        this.setState({ context, hasUnsavedChanges: true }, () => {
            this.getScaleScore();
            this.setMatchedPagesIsLazyWaiting(true);
            this.setMatchedVideosIsLazyWaiting(true);
            this.loadTopics(isVideo ? languageTypes.EN : language);
        });
    };

    createDeal = (channel, provider) => {
        const { deals } = this.state;
        const geotargets = deepCopy(
            deals.find((item) => item.channel === channel.id)?.geotargets || [],
        );
        const newDeal = {
            ...deepCopy(dealTemplate),
            channel: channel.id,
            deal_provider: provider.id,
            deal_provider_type: provider.provider_type,
            geotargets,
        };
        this.setState({ deals: [...deals, newDeal], hasUnsavedChanges: true });
    };

    activateDeal = (deal) => {
        const { deals } = this.state;
        this.setState({
            deals: deals.map((item) =>
                item.id === deal.id ? { ...item, is_active: true } : item,
            ),
            hasUnsavedChanges: true,
        });
    };

    setChannelGeotargets = (channel, geotargetIds) => {
        const { deals, amending } = this.state;

        deals.forEach((deal) => {
            if (deal.channel === channel.id) {
                deal.geotargets.splice(0, Infinity, ...geotargetIds);
            }
        });

        if (amending.deals) {
            this.validateDeals();
        }

        this.setState({ deals, hasUnsavedChanges: true });
    };

    setChannels = (activeChannelIds) => {
        const { context, deals, channels, amending } = this.state;

        const newDeals = deepCopy(deals);
        activeChannelIds.forEach((channelId) => {
            const channel = channels.find((item) => item.id === channelId);
            const dealProviders = channel.deal_providers.filter(
                (item) =>
                    item.is_video === context.is_video ||
                    item.is_video === null,
            );
            const autoDealProviders = dealProviders.filter((provider) =>
                autoDealProviderTypes.includes(provider.provider_type),
            );
            const hasDeals = deals.some((deal) => deal.channel === channelId);

            if (!hasDeals) {
                autoDealProviders.forEach((provider) => {
                    newDeals.push({
                        ...deepCopy(dealTemplate),
                        channel: channelId,
                        deal_provider: provider.id,
                        deal_provider_type: provider.provider_type,
                    });
                });
            }
        });

        context.channels = activeChannelIds;
        if (amending.deals) {
            this.validateDeals();
        }
        this.getContextPrice();
        this.setState(
            { context, deals: newDeals, hasUnsavedChanges: true },
            this.setPageSections,
        );
    };

    setType = (isVideo) => {
        const { context, deals, optimize } = this.state;
        const { is_video: wasVideo } = context;

        let hasChanges = false;

        if (isVideo !== undefined && isVideo !== wasVideo) {
            context.is_video = isVideo;
            hasChanges = true;
        }

        if (hasChanges) {
            let newDeals = deals;
            const newContext = {
                ...context,
                rules: contextTemplate.rules,
            };
            if (isVideo !== undefined) {
                newContext.channels = [];
                newDeals = [];
            }

            const newOptimize = {
                ...deepCopy(
                    isVideo ? videoOptimizeTemplate : displayOptimizeTemplate,
                ),
                id: optimize.id,
                context: optimize.context,
            };

            this.setState(
                {
                    context: newContext,
                    deals: newDeals,
                    optimize: newOptimize,
                    hasUnsavedChanges: true,
                },
                () => {
                    this.setPageSections();
                    this.getContextPrice();
                    if (!isVideo) {
                        this.setMatchedPagesIsLazyWaiting(true);
                        this.getScaleScore();
                    } else {
                        this.setMatchedVideosIsLazyWaiting(true);
                    }
                },
            );
        }
        this.loadTopics(isVideo ? languageTypes.EN : context.language);
    };

    setOptimizeIsActive = (isActive) => {
        const { optimize } = this.state;
        optimize.is_active = isActive;

        this.setState({
            optimize,
            hasUnsavedChanges: true,
        });
    };

    setOptimizeTarget = (target) => {
        const { optimize } = this.state;
        optimize.target_metric = target;
        this.setState({
            optimize,
            hasUnsavedChanges: true,
        });
    };

    setOptimizeControls = (controls) => {
        const { optimize } = this.state;
        optimize.controls = controls;

        this.setState({
            optimize,
            hasUnsavedChanges: true,
        });
    };

    setOptimizeCampaigns = (campaigns) => {
        const { optimize } = this.state;
        optimize.campaigns = campaigns;

        this.setState({
            optimize,
            hasUnsavedChanges: true,
        });
    };

    setMatchedPagesNeedsReload = (needsReload) => {
        const { matchedPages } = this.state;
        this.setState({ matchedPages: { ...matchedPages, needsReload } });
    };

    setMatchedVideosNeedsReload = (needsReload) => {
        const { matchedVideos } = this.state;
        this.setState({ matchedVideos: { ...matchedVideos, needsReload } });
    };

    setMatchedPagesIsLazyWaiting = (isLazyWaiting) => {
        const { matchedPages } = this.state;
        const { needsReload } = matchedPages;
        const section = document.getElementById(sectionTypes.MATCHED_PAGES);
        const inView =
            section && section.getBoundingClientRect().top < window.innerHeight;
        if (inView && !needsReload) {
            this.setState(
                {
                    matchedPages: { ...matchedPages, isLazyWaiting: false },
                },
                this.getMatchedPages,
            );
        } else {
            this.setState({ matchedPages: { ...matchedPages, isLazyWaiting } });
        }
    };

    setMatchedVideosIsLazyWaiting = (isLazyWaiting) => {
        const { matchedVideos } = this.state;
        const { needsReload } = matchedVideos;
        const section = document.getElementById(sectionTypes.MATCHED_VIDEOS);
        const inView =
            section && section.getBoundingClientRect().top < window.innerHeight;
        if (inView && !needsReload) {
            this.setState(
                {
                    matchedVideos: { ...matchedVideos, isLazyWaiting: false },
                },
                this.getMatchedVideos,
            );
        } else {
            this.setState({
                matchedVideos: { ...matchedVideos, isLazyWaiting },
            });
        }
    };

    setMatchedPagesRegion = (region) => {
        const { matchedPages } = this.state;
        this.setState(
            {
                matchedPages: { ...matchedPages, region },
            },
            () => {
                this.setMatchedPagesIsLazyWaiting(true);
            },
        );
    };

    setMatchedVideosRegion = (region) => {
        const { matchedVideos } = this.state;
        this.setState(
            {
                matchedVideos: { ...matchedVideos, region },
            },
            () => {
                this.setMatchedVideosIsLazyWaiting(true);
            },
        );
    };

    render() {
        const {
            loading,
            isContextPriceLoading,
            scaleScore,
            scaleErrors,
            scaleChanging,
            error,
            saving,
            advertisers,
            campaigns,
            channels,
            deals,
            savedDeals,
            dealErrors,
            dealGeotargets,
            topics,
            topicGroups,
            logoGroups,
            context,
            savedContext,
            contextErrors,
            hasUnsavedChanges,
            priceStructure,
            optimize,
            eventHierarchy,
            matchedPages,
            matchedVideos,
            navigationTarget,
            pageSections,
            pageAnchorRefs,
            isFavoriteLoading,
            togglingFavoriteError,
        } = this.state;

        return (
            <ContextDetailContext.Provider
                // Fixing this the way the linter wants will require a react guru.
                // If there is one, please do it right.
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                value={{
                    page: {
                        isLoading: loading,
                        hasError: error,
                        isSaving: saving,
                        load: this.loadData,
                        isReadOnly: ContextDetailPage.isReadOnly(),
                    },
                    pageSections: {
                        list: pageSections,
                        exists: (sectionId) =>
                            getPageSection(pageSections, sectionId).id !==
                            undefined,
                        anchorRefs: pageAnchorRefs,
                        scrollTo: (e, sectionId) =>
                            smoothScrollToAnchor(e, sectionId, pageAnchorRefs),
                    },
                    context: {
                        id: this.contextId,
                        current: context,
                        saved: savedContext,
                        save: this.saveContext,
                        delete: this.deleteContext,
                        setAdvertiser: this.setAdvertiser,
                        setCampaign: this.setCampaign,
                        setStrategy: this.setStrategy,
                        setLanguage: this.setLanguage,
                        setIsVideo: this.setType,
                        setChannels: this.setChannels,
                        setRules: this.setRules,
                        updateAttr: this.getOnChangeAttr,
                        isPriceLoading: isContextPriceLoading,
                        hasUnsavedChanges,
                        setHasUnsavedChanges: this.setHasUnsavedChanges,
                    },
                    priceStructure,
                    optimize: {
                        current: optimize,
                        setTarget: this.setOptimizeTarget,
                        setIsActive: this.setOptimizeIsActive,
                        setControls: this.setOptimizeControls,
                        setCampaigns: this.setOptimizeCampaigns,
                    },
                    eventHierarchy: {
                        data: eventHierarchy,
                        load: this.loadEventHierarchy,
                    },
                    origins: {
                        isActiveOrigin: (o) => isActiveOrigin(o, context),
                    },
                    channels,
                    deals: {
                        current: deals,
                        saved: savedDeals,
                        errors: dealErrors,
                        create: this.createDeal,
                        activate: this.activateDeal,
                        geotargets: dealGeotargets,
                        setChannelGeotargets: this.setChannelGeotargets,
                    },
                    advertisers: {
                        list: advertisers,
                        append: this.appendAdvertiser,
                    },
                    campaigns: {
                        list: campaigns,
                        append: this.appendCampaign,
                    },
                    topics: {
                        list: topics,
                        groups: topicGroups,
                    },
                    logoGroups,
                    scale: {
                        score: scaleScore,
                        getScore: this.getScaleScore,
                        isLoading: scaleChanging,
                        errors: scaleErrors,
                    },
                    matchedPages: {
                        list: matchedPages.list,
                        load: this.getMatchedPages,
                        loadingPageMatches: matchedPages.isLoading,
                        pageMatchError: matchedPages.isError,
                        needsReload: matchedPages.needsReload,
                        setNeedsReload: this.setMatchedPagesNeedsReload,
                        isLazyWaiting: matchedPages.isLazyWaiting,
                        setLazyWaiting: this.setMatchedPagesIsLazyWaiting,
                        region: matchedPages.region,
                        setRegion: this.setMatchedPagesRegion,
                    },
                    matchedVideos: {
                        list: matchedVideos.list,
                        load: this.getMatchedVideos,
                        loadingVideoMatches: matchedVideos.isLoading,
                        videoMatchError: matchedVideos.isError,
                        needsReload: matchedVideos.needsReload,
                        setNeedsReload: this.setMatchedVideosNeedsReload,
                        isLazyWaiting: matchedVideos.isLazyWaiting,
                        setLazyWaiting: this.setMatchedVideosIsLazyWaiting,
                        region: matchedVideos.region,
                        setRegion: this.setMatchedVideosRegion,
                    },
                    errors: {
                        context: contextErrors,
                    },
                    confirmNavigation: {
                        navigationTarget,
                        setNavigationTarget: this.setNavigationTarget,
                    },
                    favorites: {
                        isLoading: isFavoriteLoading,
                        togglingError: togglingFavoriteError,
                        toggle: this.toggleFavorite,
                    },
                }}
            >
                <ContextDetail />
            </ContextDetailContext.Provider>
        );
    }
}

export default ContextDetailPage;
