const notificationCategories = {
    AUTO_OPTIMIZATION_APPLIED: 'AUTO_OPTIMIZATION.applied',
    AUTO_OPTIMIZATION_INCOMPLETE: 'AUTO_OPTIMIZATION.incomplete',
    AUTO_OPTIMIZATION_STOPPED: 'AUTO_OPTIMIZATION.stopped',
    DEAL_READY: 'DEAL.ready',
    DEAL_PAUSED: 'DEAL.paused',
    DEAL_UNPAUSED: 'DEAL.unpaused',
    DEAL_WILL_BE_PAUSED: 'DEAL.will_be_paused',
    EVENT_AUDIT_INACTIVE: 'EVENT_AUDIT.inactive',
    OPTIMISE_REPORT_READY: 'OPTIMISE_REPORT.ready',
    EVENT_LOG_REPORT_READY: 'EVENT_LOG_REPORT.ready',
    VIDEO_REPORT_READY: 'VIDEO_REPORT.ready',
    YOUTUBE_SDF_READY: 'YOUTUBE.sdf_ready',
    YOUTUBE_TARGETING_FILE_READY: 'YOUTUBE.targeting_file_ready',
};
export default notificationCategories;

export const notificationCategoryGroups = {
    ALL: 'All',

    DEAL_IDS: 'Deal IDs',
    INSIGHTS: 'Insights',
    CONTEXTS: 'Contexts',
};

export const notificationCategoryTypes = [
    // Deal IDs:
    {
        id: notificationCategories.DEAL_READY,
        group: notificationCategoryGroups.DEAL_IDS,
        settingId: 'notifications_deal_ready',
        label: 'New deal ID ready',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.DEAL_PAUSED,
        group: notificationCategoryGroups.DEAL_IDS,
        settingId: 'notifications_deal_paused',
        label: 'Deal paused due to inactivity',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.DEAL_UNPAUSED,
        group: notificationCategoryGroups.DEAL_IDS,
        settingId: 'notifications_deal_reactivated',
        label: 'Deal reactivated',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.DEAL_WILL_BE_PAUSED,
        group: notificationCategoryGroups.DEAL_IDS,
        settingId: 'notifications_deal_pause_warning',
        label: 'Deal about to be paused due to inactivity',
        supportsInApp: true,
        supportsEmail: true,
    },

    // Insights:
    {
        id: notificationCategories.OPTIMISE_REPORT_READY,
        group: notificationCategoryGroups.INSIGHTS,
        settingId: 'notifications_insights_display_ready',
        label: 'Insights display report ready',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.VIDEO_REPORT_READY,
        group: notificationCategoryGroups.INSIGHTS,
        settingId: 'notifications_insights_video_ready',
        label: 'Insights video report ready',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.EVENT_LOG_REPORT_READY,
        group: notificationCategoryGroups.INSIGHTS,
        settingId: 'notifications_event_log_ready',
        label: 'Event log report ready',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.EVENT_AUDIT_INACTIVE,
        group: notificationCategoryGroups.INSIGHTS,
        settingId: `notifications_insights_tag_anomaly`,
        label: '4D Tag needs reapplying',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.YOUTUBE_SDF_READY,
        group: notificationCategoryGroups.INSIGHTS,
        settingId: 'notifications_youtube_sdf',
        label: 'YouTube SDF targeting file ready',
        supportsInApp: false,
        supportsEmail: true,
    },
    {
        id: notificationCategories.YOUTUBE_TARGETING_FILE_READY,
        group: notificationCategoryGroups.INSIGHTS,
        settingId: 'notifications_youtube_targeting_file',
        label: 'YouTube targeting file ready',
        supportsInApp: false,
        supportsEmail: true,
    },

    // Contexts:
    {
        id: notificationCategories.AUTO_OPTIMIZATION_APPLIED,
        group: notificationCategoryGroups.CONTEXTS,
        settingId: 'notifications_auto_optimization_applied',
        label: 'Applied auto optimisation of a context',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.AUTO_OPTIMIZATION_INCOMPLETE,
        group: notificationCategoryGroups.CONTEXTS,
        settingId: 'notifications_auto_optimization_incomplete',
        label: 'Incomplete auto optimisation setup',
        supportsInApp: true,
        supportsEmail: true,
    },
    {
        id: notificationCategories.AUTO_OPTIMIZATION_STOPPED,
        group: notificationCategoryGroups.CONTEXTS,
        settingId: 'notifications_auto_optimization_stopped',
        label: 'Stopped auto optimisation of a context',
        supportsInApp: true,
        supportsEmail: true,
    },
];
