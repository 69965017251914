import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import CardGeneric from 'js/components/card/card-generic';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import { getJwtPayload, setDocumentTitle } from 'js/utils';
import React, { useEffect } from 'react';

export default function SettingsMenuPage() {
    const token = localStorage.getItem('AuthToken');
    const { is_internal: isInternalUser } = getJwtPayload(token);

    const cards = [
        {
            title: 'Channels',
            subtitle:
                'Activate and deactivate channels available to this account.',
            to: '/settings/channels/',
        },
        {
            title: 'Users',
            subtitle:
                'Manage users and their access permissions for this account.',
            to: '/settings/users/',
        },
        {
            title: 'Your Profile',
            subtitle: 'Manage your profile details and change your password.',
            to: '/settings/your-profile/',
        },
        {
            title: 'Rename Data Sources',
            subtitle:
                'Make it easier to identify the data sources that you want to create a report from by renaming the data sources belonging to this account.',
            to: '/settings/rename-data-sources/',
        },
    ];
    if (isInternalUser) {
        cards.push({
            title: 'Status Dashboard',
            subtitle:
                'Insights into what the platform is doing and how we might expect it to perform.',
            to: '/settings/status/',
        });
    }

    useEffect(() => {
        setDocumentTitle(['Settings']);
    }, []);

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb key="settings" label="Settings" />,
                    ]}
                />
            }
        >
            <Container>
                <Box padding={['base', 0, 'large']}>
                    <Row>
                        {cards.map((link) => (
                            <Col span={12} spanMd={6} spanLg={4} key={link.to}>
                                <Box padding={['base', 0, 0]}>
                                    <CardGeneric
                                        title={link.title}
                                        description={link.subtitle}
                                        to={link.to}
                                    />
                                </Box>
                            </Col>
                        ))}
                    </Row>
                </Box>
            </Container>
        </Layout>
    );
}
