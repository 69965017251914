import React from 'react';
import { createPresetUrl, reloadPage } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardCampaign from 'js/components/card/card-campaign';
import CardCreateNew from 'js/components/card/card-create-new';
import CardLoading from 'js/components/card/card-loading';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';

const Wrapper = ({ children }) => (
    <Section>
        <Container>
            <Box padding={['base', 0, 0]}>
                <Row>{children}</Row>
            </Box>
        </Container>
    </Section>
);

function AdvertiserCampaigns({
    campaigns,
    advertiser,
    readOnly,
    loading,
    hasLoadingError,
    onToggleFavorite,
    onDelete,
}) {
    const newCampaign = { advertiser: advertiser.id };
    const newCampaignUrl = createPresetUrl('/campaigns/new/', newCampaign);

    const createNewContent = (
        <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
            <Box padding={['base', 0, 0]}>
                <CardCreateNew
                    title="Create New Campaign"
                    to={newCampaignUrl}
                />
            </Box>
        </Col>
    );

    if (loading)
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <CardLoading loadingText="Loading Campaigns" />
                    </Box>
                </Col>
            </Wrapper>
        );

    if (hasLoadingError) {
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <Alert
                            theme="danger"
                            title="There was an error loading the campaigns"
                            actionItems={
                                <Button onClick={reloadPage}>Retry</Button>
                            }
                        >
                            <p>
                                Hopefully it is only a temporary issue. Please
                                try again in a few moments.
                            </p>
                        </Alert>
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    if (!campaigns.length) {
        if (readOnly) {
            return (
                <Wrapper>
                    <Box padding={['base', 0, 0]}>
                        <Alert theme="empty">You have no campaigns.</Alert>
                    </Box>
                </Wrapper>
            );
        }

        return <Wrapper>{createNewContent}</Wrapper>;
    }

    return (
        <Wrapper>
            {campaigns.map((campaign) => (
                <Col
                    key={campaign.id}
                    span={12}
                    spanSm={12}
                    spanMd={6}
                    spanLg={4}
                >
                    <Box padding={['base', 0, 0]}>
                        <CardCampaign
                            campaign={campaign}
                            onToggleFavorite={() =>
                                onToggleFavorite(campaign.id)
                            }
                            onDeleteCampaign={() => onDelete(campaign.id)}
                        />
                    </Box>
                </Col>
            ))}

            {createNewContent}
        </Wrapper>
    );
}

export default AdvertiserCampaigns;
