import React, { useRef, useState } from 'react';
import { useClickOutside } from 'js/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faPauseCircle,
} from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import ClickArea from 'js/components/click-area/click-area';
import Popper from 'js/components/popper/popper';
import Text from 'js/components/text/text';

const DealStateIcon = ({ deal }) => {
    const tooltipRef = useRef();
    const [showTooltip, setShowTooltip] = useState(false);
    useClickOutside([tooltipRef], () => setShowTooltip(false));

    const isPaused = !deal.is_active;
    const isReady = !!deal.deal_id;

    let icon;
    let color;
    if (isPaused) {
        icon = faPauseCircle;
        color = ['red', 'dark'];
    } else {
        icon = faCheckCircle;
        color = isReady ? ['purple', 'base'] : ['gray', 'light'];
    }

    return (
        <Box margin={['-smaller', 0, 0]}>
            <ClickArea
                isDisabled={!isReady}
                onClick={() => setShowTooltip(!showTooltip)}
            >
                <Popper
                    content={
                        <Box ref={tooltipRef} padding={['small', 'base']}>
                            This deal is {isPaused ? 'paused' : 'active'}
                        </Box>
                    }
                    showContent={showTooltip}
                    theme="tooltip"
                    placement="right"
                    offset={[0, 4]}
                >
                    <Text color={color} size="huge">
                        <FontAwesomeIcon icon={icon} size="xs" />
                    </Text>
                </Popper>
            </ClickArea>
        </Box>
    );
};

export default DealStateIcon;
