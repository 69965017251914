export const orientation = {
    none: null,
    portrait: 'portrait',
    landscape: 'landscape',
};

export const borderRadius = {
    none: 0,
    small: 2,
    base: 4,
    round: 8,
    rounder: 12,
    rounded: 999,
};

export const borderWidth = {
    base: 1,
    thick: 2,
    thicker: 4,
};

export const color = {
    purple: {
        darkest: '#0d134a',
        darker: '#242a6b',
        dark: '#3040e8',
        base: '#6673ff',
        light: '#99a2ff',
        lighter: '#ccd0ff',
        lightest: '#e5e8ff',
    },
    red: {
        darkest: '#450810',
        darker: '#730d1a',
        dark: '#ce172f',
        base: '#ea465b',
        light: '#ef7685',
        lighter: '#f5a3ae',
        lightest: '#fad1d6',
    },
    yellow: {
        darkest: '#4d3b00',
        darker: '#806200',
        dark: '#bf9300',
        base: '#ffc400',
        light: '#ffe380',
        lighter: '#fff0b3',
        lightest: '#fffae6',
    },
    aqua: {
        darkest: '#093f43',
        darker: '#137f86',
        dark: '#1cbec9',
        base: '#4cdce6',
        light: '#79e5ec',
        lighter: '#a6eef3',
        lightest: '#d2f6f9',
    },
    gray: {
        black: '#000',
        darkest: '#1a1a1a',
        darker: '#404040',
        dark: '#585858',
        base: '#808080',
        light: '#b3b3b3',
        lighter: '#d9d9d9',
        lightest: '#e8e8e8',
        background: '#f4f4f4',
        white: '#fff',
    },
    blue: {
        darkest: '#0747a6',
        darker: '#0052cc',
        dark: '#0065ff',
        base: '#2684ff',
        light: '#4c9aff',
        lighter: '#b3d4ff',
        lightest: '#deebff',
    },
};

export const fontSize = {
    smallest: 8,
    smaller: 10,
    small: 12,
    base: 14,
    large: 16,
    larger: 18,
    largest: 21,
    huge: 40,
};

export const fontWeight = {
    thin: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800,
};

export const spacing = {
    none: 0,
    smallest: 2,
    smaller: 4,
    small: 8,
    base: 16,
    large: 32,
    larger: 64,
};
