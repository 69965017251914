import { borderRadius, fontSize, fontWeight, spacing } from '../tokens.styles';

const styles = {
    titlePortrait: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.base,
    },

    titleLandscape: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.large,
    },

    icon: {
        width: 70,
        height: 70,
    },

    dot: {
        width: 3,
        height: 3,
        borderRadius: borderRadius.rounded,
    },

    itemTitlePortrait: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.smaller,
        textTransform: 'capitalize',
    },

    itemTitleLandscape: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.base,
        textTransform: 'capitalize',
    },

    parameterPortrait: {
        marginTop: spacing.smallest,
        fontSize: fontSize.smallest,
    },

    parameterLandscape: {
        marginTop: spacing.smallest,
        fontSize: fontSize.small,
    },

    mainParameter: {
        fontWeight: fontWeight.semibold,
    },
};

export default styles;
