import ruleTypes from 'js/enums/rule-types.enum';
import recommendationActions from './enums/actions.enum';

const getRecommendationAppliedCount = (
    { entities, attribute, action },
    { rules },
) => {
    const entityIds = entities.map((entity) => entity.name);
    const ruleEntities = rules
        .filter(({ aggregation }) => aggregation === ruleTypes.INCLUDED)
        .map((rule) => rule[attribute])
        .flat();
    return entityIds.filter(
        (i) =>
            ruleEntities.includes(i) === (action === recommendationActions.ADD),
    ).length;
};

export default getRecommendationAppliedCount;
