import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'js/components/button/button';
import templateFile from '../renameDspTemplate.xlsx';

const DownloadTemplateButton = () => {
    const downloadName = '4D_data_sources_template.xlsx';

    return (
        <Link to={templateFile} target="_blank" download={downloadName}>
            <Button theme="outline">Download Template</Button>
        </Link>
    );
};

export default DownloadTemplateButton;
