import React from 'react';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';

const videoReportSubtypeOptions = [
    {
        label: 'Programmatic Video and CTV',
        value: 1,
        subtypes: [videoReportSubtypes.DEFAULT],
        hint: (
            <p>
                Analyze a campaign that is using 4D targeting via a deal to
                discover how to boost performance and scale your context.
            </p>
        ),
    },
    {
        label: 'YouTube',
        value: 2,
        subtypes: [
            videoReportSubtypes.YOUTUBE_DV360,
            videoReportSubtypes.YOUTUBE_GADS,
            videoReportSubtypes.YOUTUBE_GADS_FILE,
        ],
        hint: (
            <p>
                Analyze a Google Ads or DV360 campaign that is running on
                YouTube inventory using 4D targeting to discover how to boost
                performance and scale your context.
            </p>
        ),
    },
];

export default videoReportSubtypeOptions;
