import React from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import TrendChart from 'js/components/trend-chart/trend-chart';
import Tooltip from 'js/components/trend-chart/tooltip';
import StatusFail from './images/page-status-fail.svg';
import StatusOk from './images/page-status-ok.svg';
import styles from '../status-list.module.scss';

const StatusTooltip = ({ series, date }) => (
    <div style={{ minWidth: 225 }}>
        <Tooltip series={series} date={date} />
    </div>
);

function StatusCard({ title, description, chart, statuses }) {
    return (
        <div className={styles['status-card']}>
            <div className={styles['status-card-content']}>
                <div>
                    <Box margin={['base', 0, 0]}>
                        <Row alignItems="center" gutter="small">
                            <Col span="auto">
                                <Text size="larger" weight="bolder">
                                    {title}
                                </Text>
                            </Col>

                            <Col span="auto">
                                <InfoButton>
                                    <Text color={['gray', 'darkest']}>
                                        {description}
                                    </Text>
                                </InfoButton>
                            </Col>
                        </Row>
                    </Box>
                </div>

                <div>
                    {chart && (
                        <Box margin={['base', 0]}>
                            <Text color={['gray', 'dark']}>Last 7 Days</Text>

                            {statuses.every((status) => !status.isOk) ? (
                                <Box margin={['smaller', 0]}>
                                    <Alert theme="empty">
                                        <Box margin={['base', 0]}>
                                            Not enough data available.
                                        </Box>
                                    </Alert>
                                </Box>
                            ) : (
                                <div style={{ height: 120 }}>
                                    <TrendChart
                                        series={chart}
                                        tooltip={<StatusTooltip />}
                                    />
                                </div>
                            )}
                        </Box>
                    )}

                    {statuses.map((status) => (
                        <Box margin={['base', 0]} key={status.title}>
                            <Text color={['gray', 'dark']}>{status.title}</Text>

                            <Box
                                margin={['smaller', 0, 0]}
                                padding="small"
                                borderRadius="round"
                                background={['gray', 'background']}
                            >
                                <Row alignItems="center" gutter="small">
                                    <Col span="auto">
                                        <img
                                            src={
                                                status.isOk
                                                    ? StatusOk
                                                    : StatusFail
                                            }
                                            alt={status.isOk ? 'OK' : 'Fail'}
                                        />
                                    </Col>
                                    <Col>{status.description}</Col>
                                </Row>
                            </Box>
                        </Box>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default StatusCard;
