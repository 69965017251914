import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { api, getJwtPayload, setDocumentTitle } from 'js/utils';
import permissionGroups from 'js/enums/permission-groups.enum';
import ChannelList from './channel-list';

const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';

const initialState = {
    channels: [],
    integration: {},
    loading: true,
    error: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        case FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            throw new Error();
    }
};
const ChannelListPage = () => {
    const { integrationId } = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { roles } = getJwtPayload(localStorage.getItem('AuthToken'));
    const isReadOnly =
        roles.includes(permissionGroups.MANAGER) ||
        roles.includes(permissionGroups.VIEW_ONLY);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [{ results: channels }, integration] = await Promise.all([
                    api().channels.list({
                        verbose: true,
                        field: [
                            'id',
                            'name',
                            'integration',
                            'logo',
                            'integration_key',
                            'description',
                        ],
                        integration: integrationId,
                        ordering: 'description',
                    }),
                    api().integrations.retrieve(integrationId),
                ]);

                setDocumentTitle([integration.name, 'Channels', 'Settings']);
                dispatch({
                    type: FETCH_SUCCESS,
                    payload: {
                        channels,
                        integration,
                    },
                });
            } catch (err) {
                dispatch({ type: FETCH_ERROR });
            }
        };
        loadData();
    }, [integrationId]);

    const { loading, error, channels, integration } = state;

    return (
        <ChannelList
            loading={loading}
            error={error}
            channels={channels}
            integration={integration}
            isReadOnly={isReadOnly}
        />
    );
};

export default ChannelListPage;
