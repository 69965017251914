import React from 'react';
import { accountMgmtEmailAddress } from 'js/constants';
import PropTypes from 'prop-types';
import Button from '../button/button';

const SupportEmailLink = ({ type, children, buttonTheme, emailSubject }) => {
    const emailLink = !emailSubject
        ? `mailto:${accountMgmtEmailAddress}`
        : `mailto:${accountMgmtEmailAddress}?subject=${encodeURI(
              emailSubject,
          )}`;
    const linkText = children || accountMgmtEmailAddress;

    if (type === 'button') {
        return (
            <Button to={emailLink} theme={buttonTheme || 'primary'}>
                {linkText}
            </Button>
        );
    }

    return (
        <a href={emailLink} target="_blank" rel="noreferrer">
            {linkText}
        </a>
    );
};

SupportEmailLink.defaultProps = {
    type: undefined,
    children: accountMgmtEmailAddress,
    buttonTheme: undefined,
    emailSubject: undefined,
};

SupportEmailLink.propTypes = {
    type: PropTypes.oneOf(['link', 'button']),
    children: PropTypes.string,
    buttonTheme: PropTypes.oneOf([
        'primary',
        'outline',
        'dark',
        'switch',
        'custom',
    ]),
    emailSubject: PropTypes.string,
};

SupportEmailLink.displayName = 'Support Email Link';

export default SupportEmailLink;
