import { api, setDocumentTitle, validEmail } from 'js/utils';
import React, {
    useState,
    useLayoutEffect,
    useEffect,
    useCallback,
} from 'react';
import PasswordReset from './password-reset';

function PasswordResetPage() {
    const [loading, setLoading] = useState(false);
    const [amending, setAmending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    useLayoutEffect(() => {
        setDocumentTitle(['Reset Password']);

        return () => {
            api().abortAll();
        };
    }, []);

    const validateForm = useCallback(
        ({ throwErrors = false } = {}) => {
            const e = {};
            setAmending(true);
            if (!email) {
                e.email = 'Your email address is required.';
            } else if (!validEmail(email)) {
                e.email = 'Enter a valid email address.';
            }
            if (e.email) {
                setErrors(e);
                if (throwErrors) throw e;
            }
        },
        [email],
    );

    const submitEmail = (e) => {
        const newErrors = { ...errors };
        delete newErrors.email;
        setErrors(newErrors);
        setEmail(e);
    };

    useEffect(() => {
        if (amending) validateForm();
    }, [email, amending, validateForm]);

    const resetPassword = async () => {
        setLoading(true);
        try {
            validateForm({ throwErrors: true });
            await api().applyPasswordReset(email);
            setSuccess(true);
            setLoading(false);
        } catch (e) {
            setErrors(e);
            setLoading(false);
        }
    };

    return (
        <PasswordReset
            email={email}
            loading={loading}
            errors={errors}
            success={success}
            setEmail={submitEmail}
            resetPassword={resetPassword}
        />
    );
}

export default PasswordResetPage;
