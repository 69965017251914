import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useClickOutside } from 'js/hooks';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import { cleanString } from 'js/utils';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Input from 'js/components/input/input';
import Popper from 'js/components/popper/popper';
import styles from '../../rename-data-sources.module.scss';

const EditNameButton = ({ name, onSave, onUpdateNameDisplayed }) => {
    const [nameInput, setNameInput] = useState(name);
    const [showPopper, setShowPopper] = useState(false);
    const tooltipRef = useRef();

    const handleTogglePopper = (ev) => {
        ev.stopPropagation();
        setShowPopper((prevState) => !prevState);
    };

    useClickOutside([tooltipRef], () => setShowPopper(false));

    const applyButtonDisabled =
        name === nameInput || cleanString(nameInput).length === 0;

    const saveNameHandler = () => {
        onSave(nameInput);
        onUpdateNameDisplayed(nameInput);
        setShowPopper(false);
    };

    return (
        <div className={styles['result-button-container']}>
            <Popper
                content={
                    <div
                        ref={tooltipRef}
                        onClick={(ev) => ev.stopPropagation()}
                        onKeyDown={(ev) => ev.stopPropagation()}
                        role="textbox"
                        tabIndex="0"
                    >
                        <Box padding="base">
                            <Row>
                                <Col span="auto">
                                    <Input
                                        value={nameInput}
                                        onChange={(ev) =>
                                            setNameInput(ev.target.value)
                                        }
                                        hint={<p>Edit</p>}
                                    />
                                </Col>
                                <Col span="auto" alignSelf="flex-end">
                                    <Button
                                        onClick={saveNameHandler}
                                        disabled={applyButtonDisabled}
                                    >
                                        Apply
                                    </Button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                }
                theme="tooltip"
                placement="top-start"
                showContent={showPopper}
            >
                <Button
                    size="small"
                    theme="outline"
                    square
                    onClick={handleTogglePopper}
                >
                    <FontAwesomeIcon icon={faEllipsisV} />
                </Button>
            </Popper>
        </div>
    );
};

export default EditNameButton;
