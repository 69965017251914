const sortTypes = {
    NONE: 'none',
    UPDATED_ASC: 'updated',
    UPDATED_DESC: '-updated',
    DEEP_UPDATED_ASC: 'deep_updated',
    DEEP_UPDATED_DESC: '-deep_updated',
    NAME_ASC: 'name',
    NAME_DESC: '-name',
    IMPRESSIONS_ASC: 'impressions',
    IMPRESSIONS_DESC: '-impressions',
    CLICKS_ASC: 'clicks',
    CLICKS_DESC: '-clicks',
    CTR_ASC: 'ctr',
    CTR_DESC: '-ctr',
    VIEWABLE_IMPRESSIONS_ASC: 'viewableImpressions',
    VIEWABLE_IMPRESSIONS_DESC: '-viewableImpressions',
    VIEW_RATE_ASC: 'viewRate',
    VIEW_RATE_DESC: '-viewRate',
    STARTS_ASC: 'starts',
    STARTS_DESC: '-starts',
    FIRST_QUARTILES_ASC: 'firstQuartiles',
    FIRST_QUARTILES_DESC: '-firstQuartiles',
    MIDPOINTS_ASC: 'midpoints',
    MIDPOINTS_DESC: '-midpoints',
    THIRD_QUARTILES_ASC: 'thirdQuartiles',
    THIRD_QUARTILES_DESC: '-thirdQuartiles',
    COMPLETES_ASC: 'completes',
    COMPLETES_DESC: '-completes',
    COMPLETION_RATE_ASC: 'completionRate',
    COMPLETION_RATE_DESC: '-completionRate',
};

export default sortTypes;
