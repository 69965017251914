import ruleTypes from 'js/enums/rule-types.enum';
import React from 'react';
import serviceTypes from 'js/enums/service-types.enum';
import { getJwtPayload, RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import SwitchButton from 'js/components/button/switch-button';
import InsightsTable from '../../../components/insights-table/insights-table';

export default function TopicsTableSection({
    report,
    context,
    tableColumns,
    onChangeContextRules,
    onInspectTopic,
}) {
    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const hasMatchedPagesService = services.some(
        (service) => service.name === serviceTypes.MATCHED_PAGES,
    );

    const tableData = {
        rows: report.topics.map((topic) => {
            const {
                topic: topicId,
                topic_name: topicName,
                topic_group_logo: topicGroupLogo,
                topic_group_name: topicGroupName,
                impressions,
                clicks,
                in_views: inViews,
            } = topic;

            return {
                topic,
                id: topicId,
                icon: topicGroupLogo,
                name: `${topicGroupName} – ${topicName}`,
                impressions,
                clicks,
                ctr: clicks / impressions,
                viewableImpressions: inViews,
                viewRate: inViews / impressions,
            };
        }),
        comparedValues: {
            impressions: report.impressions,
            clicks: report.clicks,
            ctr: report.clicks / report.impressions,
            viewableImpressions: report.in_views,
            viewRate: report.in_views / report.impressions,
        },
    };

    const allTargetedTopics = context.rules
        .filter(({ aggregation }) => aggregation === ruleTypes.INCLUDED)
        .map(({ topics }) => topics)
        .flat();

    const allBlockedTopics = context.rules
        .find(({ aggregation }) => aggregation === ruleTypes.EXCLUDED)
        .topics.flat();

    const firstRule = context.rules.find(
        ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
    );
    const notRule = context.rules.find(
        ({ aggregation }) => aggregation === ruleTypes.EXCLUDED,
    );
    const ruleSet = new RuleSet(context.rules);

    // add topics to first rule, remove from all rules
    const toggleTarget = (addTopics, removeTopics) => {
        ruleSet.changeRule(firstRule.key, { addTopics });
        ruleSet.changeAllRules({ removeTopics });

        onChangeContextRules(ruleSet.rules);
    };

    const toggleBlock = (addTopics, removeTopics) => {
        ruleSet.changeAllRules({ removeTopics });
        ruleSet.changeRule(notRule.key, { addTopics });

        onChangeContextRules(ruleSet.rules);
    };

    const getButtons = ({ topic, id: topicId }) => {
        const buttons = [];
        const isTargeted = allTargetedTopics.includes(topicId);
        const isBlocked = allBlockedTopics.includes(topicId);

        buttons.push(
            <SwitchButton
                size="small"
                active={isTargeted}
                onChange={() =>
                    toggleTarget(
                        isTargeted ? [] : [topicId],
                        isTargeted ? [topicId] : [],
                    )
                }
            >
                Target
            </SwitchButton>,
            <SwitchButton
                size="small"
                active={isBlocked}
                onChange={() =>
                    toggleBlock(
                        isBlocked ? [] : [topicId],
                        isBlocked ? [topicId] : [],
                    )
                }
            >
                Block
            </SwitchButton>,
        );
        if (hasMatchedPagesService) {
            buttons.push(
                <Button
                    size="small"
                    theme="outline"
                    onClick={() => onInspectTopic(topic)}
                >
                    Inspect
                </Button>,
            );
        }

        return buttons;
    };

    return (
        <Box margin={['base', 0]}>
            <InsightsTable
                tableData={tableData}
                visibleColumns={tableColumns.map((option) => option.value)}
                filteringOptions={tableColumns}
                hasLimit
                getButtons={getButtons}
            />
        </Box>
    );
}
