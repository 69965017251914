import React from 'react';
import PropTypes from 'prop-types';
import { reloadPage } from 'js/utils';
import Alert from '../alert/alert';
import Box from '../box/box';
import Button from '../button/button';
import Container from '../container/container';
import Col from '../grid/column';
import Row from '../grid/row';
import Section from '../section/section';

function ErrorBar({ title, action, onAction, size, children }) {
    const actionItems = (
        <Row gutter="smaller">
            <Col span="auto">
                <Button onClick={onAction}>{action}</Button>
            </Col>
        </Row>
    );

    return (
        <Section>
            <Container size={size}>
                <Box margin={['large', 0]}>
                    <Alert
                        actionItems={actionItems}
                        theme="danger"
                        title={title}
                    >
                        {children || (
                            <p>
                                Hopefully it is only a temporary issue. Please
                                try again in a few moments.
                            </p>
                        )}
                    </Alert>
                </Box>
            </Container>
        </Section>
    );
}

ErrorBar.defaultProps = {
    title: 'There was an error while loading this page',
    action: 'Retry',
    onAction: reloadPage,
    size: 'base',
    children: undefined,
};

ErrorBar.propTypes = {
    title: PropTypes.string,
    action: PropTypes.string,
    onAction: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'base', 'full-width']),
    children: PropTypes.node,
};

export default ErrorBar;
