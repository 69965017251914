import React, { useContext, useState } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import sectionTypes from 'js/enums/section-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Crumb from 'js/components/breadcrumbs/crumb';
import SummaryFooter from 'js/components/summary-footer/summary-footer';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Layout from 'js/components/layout/layout';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Navigation from 'js/components/navigation/navigation';
import Text from 'js/components/text/text';
import TopBar from 'js/components/top-bar/top-bar';
import AdvertiserModal from 'js/components/advertiser/modal';
import CampaignModal from 'js/components/campaign/modal';
import ContextDetailBody from './components/context-detail-body/context-detail-body';
import DeleteWarningModal from './components/modals/delete-warning-modal';
import DimensionWarningModal from './components/modals/dimension-warning-modal';
import ScaleInfoModal from './components/modals/scale-info-modal';
import VideoWarningModal from './components/modals/video-warning-modal';
import styles from './context-detail.module.scss';
import ContextDetailContext from './contexts/context-detail.context';

function HintContent({
    actionType,
    hasUnsavedChanges,
    hasTopicsOrKeywords,
    hasError,
    errorSectionId,
    isVideo,
    onScrollToIssue,
}) {
    if (!hasUnsavedChanges) {
        return (
            <Text color={['gray', 'dark']}>
                <p>There are no unsaved changes for this context.</p>
                <p>
                    This button will be enabled when there are changes that can
                    be saved.
                </p>
            </Text>
        );
    }

    if (hasError) {
        return (
            <>
                <Text color={['red', 'base']}>
                    <p>
                        You can&apos;t {actionType} the context at the moment
                        because there are problems that have been highlighted on
                        the page.
                    </p>
                </Text>

                <Box margin={['small', 0, 0]}>
                    <Button
                        onClick={(ev) => onScrollToIssue(ev, errorSectionId)}
                    >
                        Scroll To First Issue
                    </Button>
                </Box>
            </>
        );
    }

    if (!hasTopicsOrKeywords) {
        return (
            <Text color={['gray', 'dark']}>
                <p>
                    At least one Topic {!isVideo && 'or Keyword'} must be
                    selected for the Topics & Brands Dimension to be able to{' '}
                    {actionType} a Context.
                </p>
            </Text>
        );
    }

    return null;
}

function ActionItems() {
    const {
        context: {
            id: contextId,
            save: saveContext,
            hasUnsavedChanges,
            current: { is_video: isVideo, rules },
        },
        deals: { errors: dealErrors },
        pageSections: { scrollTo: smoothScrollToAnchor },
        errors: { context: contextErrors },
    } = useContext(ContextDetailContext);

    const [isHintDropdownVisible, setHintDropdownVisible] = useState(false);

    let hasError = false;
    let errorSectionId;
    if (Object.keys(contextErrors).length) {
        hasError = true;
        errorSectionId = sectionTypes.DETAILS;
    } else if (Object.keys(dealErrors).length) {
        hasError = true;
        errorSectionId = sectionTypes.CHANNELS;
    }

    const buttonLabel = contextId ? 'Save Changes' : 'Create Context';
    const actionType = contextId ? 'save' : 'create';

    const hasTopicsOrKeywords = rules.some(
        (rule) =>
            rule.topics.length > 0 || (rule.keywords.length > 0 && !isVideo),
    );

    const scrollToIssue = (ev, sectionId) => {
        setHintDropdownVisible(false);
        smoothScrollToAnchor(ev, sectionId);
    };

    const toggleHintDropdown = () => {
        if (!hasUnsavedChanges || hasError || !hasTopicsOrKeywords) {
            setHintDropdownVisible(true);
        }
    };

    const onSaveContext = async () => {
        await saveContext();
        toggleHintDropdown();
    };

    if (!hasUnsavedChanges || hasError || !hasTopicsOrKeywords) {
        return (
            <div
                className={styles['fake-button']}
                onMouseEnter={toggleHintDropdown}
                onMouseLeave={() => setHintDropdownVisible(false)}
            >
                <span>{buttonLabel}</span>

                <DropdownMenu
                    content={
                        <ul>
                            <li>
                                <div className={styles['hint-dropdown']}>
                                    <HintContent
                                        actionType={actionType}
                                        hasUnsavedChanges={hasUnsavedChanges}
                                        hasTopicsOrKeywords={
                                            hasTopicsOrKeywords
                                        }
                                        hasError={hasError}
                                        errorSectionId={errorSectionId}
                                        isVideo={isVideo}
                                        onScrollToIssue={scrollToIssue}
                                    />
                                </div>
                            </li>
                        </ul>
                    }
                    showMenu={isHintDropdownVisible}
                    placement="bottom-end"
                />
            </div>
        );
    }

    return (
        <Button
            onClick={onSaveContext}
            onMouseEnter={toggleHintDropdown}
            onMouseLeave={() => setHintDropdownVisible(false)}
            size="large"
        >
            {buttonLabel}
        </Button>
    );
}

function ContextDetail({ history }) {
    const {
        page: { isLoading: isPageLoading, hasError: hasPageError },
        context: {
            id: contextId,
            current: context,
            current: { advertiser: advertiserId, is_video: isVideo },
            saved: savedContext,
            delete: deleteContext,
            isPriceLoading: priceChanging,
            hasUnsavedChanges,
            setHasUnsavedChanges,
            setAdvertiser: setContextAdvertiser,
            setCampaign: setContextCampaign,
        },
        channels,
        advertisers: { list: advertisers, append: appendAdvertiser },
        campaigns: { append: appendCampaign },
        scale: {
            score: scaleScore,
            getScore: getScaleScore,
            isLoading: scaleChanging,
            errors: scaleErrors,
        },
        confirmNavigation: { setNavigationTarget },
    } = useContext(ContextDetailContext);
    const [advertiserModal, setAdvertiserModal] = useState(false);
    const [campaignModal, setCampaignModal] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [navigationModal, setNavigationModal] = useState(false);
    const [scaleInfo, setScaleInfo] = useState(false);
    const [dimensionWarning, setDimensionWarning] = useState(false);
    const [dimensionChanges, setDimensionChanges] = useState([]);
    const [dimensionWarningSuccess, setDimensionWarningSuccess] = useState(
        () => () => {},
    );
    const [videoWarning, setVideoWarning] = useState(false);
    const [videoWarningSuccess, setVideoWarningSuccess] = useState(
        () => () => {},
    );
    const [deleteErrors, setDeleteErrors] = useState({});

    const toggleAdvertiserModal = () => {
        setAdvertiserModal((state) => !state);
    };

    const toggleCampaignModal = () => {
        setCampaignModal((state) => !state);
    };

    const toggleDeleteWarningModal = () => {
        setDeleteWarning((state) => !state);
        setDeleteErrors({});
    };

    const toggleDimensionWarningModal = () => {
        setDimensionWarning((state) => !state);
    };

    const toggleVideoWarningModal = () => {
        setVideoWarning((state) => !state);
    };

    const onDeleteContext = async () => {
        setDeleteErrors({});

        try {
            await deleteContext(context);
            toggleDeleteWarningModal();
        } catch (err) {
            setDeleteErrors(err);
        }
    };

    const onCreateAdvertiser = (advertiser) => {
        appendAdvertiser(advertiser);
        setContextAdvertiser(advertiser.id);
        toggleAdvertiserModal();
    };

    const onCreateCampaign = (campaign) => {
        appendCampaign(campaign);
        setContextCampaign(campaign.id);
        toggleCampaignModal();
    };

    const getBreadcrumbs = () => {
        const breadcrumbs = [<Crumb key="home" to="/" label="4D" />];

        if (hasPageError) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (isPageLoading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (contextId) {
            breadcrumbs.push(
                <Crumb
                    key="advertisers"
                    to="/context/"
                    label="Advertisers"
                    isCollapsible
                />,
                <Crumb
                    key="advertiser"
                    to={`/context/${savedContext.advertiser}/`}
                    label={savedContext.advertiser_name}
                    isCollapsible
                />,
                <Crumb
                    key="group"
                    to={`/context/${savedContext.advertiser}/${savedContext.group}/`}
                    label={savedContext.group_name}
                    isCollapsible
                />,
                <Crumb key="context" label={savedContext.name} />,
            );
        } else {
            breadcrumbs.push(
                <Crumb
                    key="contexts"
                    label="Contexts"
                    isInactive
                    isCollapsible
                />,
                <Crumb key="create" label="Create New" />,
            );
        }

        return breadcrumbs;
    };

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
            footer={
                !isPageLoading && (
                    <SummaryFooter
                        context={context}
                        allChannels={channels}
                        priceChanging={priceChanging}
                        scaleScore={scaleScore}
                        scaleChanging={scaleChanging}
                        scaleErrors={scaleErrors}
                        scaleReload={getScaleScore}
                        scaleOnClick={() => setScaleInfo(true)}
                        scaleOnHelp={() => setScaleInfo(true)}
                        actionItems={<ActionItems />}
                    />
                )
            }
            isFooterSticky
        >
            <ContextDetailBody
                setVideoWarning={setVideoWarning}
                setVideoWarningSuccess={setVideoWarningSuccess}
                setDimensionWarning={setDimensionWarning}
                setDimensionChanges={setDimensionChanges}
                setDimensionWarningSuccess={setDimensionWarningSuccess}
                toggleAdvertiserModal={toggleAdvertiserModal}
                toggleCampaignModal={toggleCampaignModal}
                toggleDeleteWarningModal={toggleDeleteWarningModal}
            />

            {advertiserModal && (
                <AdvertiserModal
                    title="Create Advertiser"
                    onSave={onCreateAdvertiser}
                    onClose={toggleAdvertiserModal}
                />
            )}

            {campaignModal && (
                <CampaignModal
                    title="Create Campaign"
                    preset={{ advertiser: advertiserId }}
                    advertisers={advertisers}
                    onAdvertiserCreate={appendAdvertiser}
                    onSave={onCreateCampaign}
                    onClose={toggleCampaignModal}
                />
            )}

            {deleteWarning && (
                <DeleteWarningModal
                    deleteErrors={deleteErrors}
                    toggleDeleteWarningModal={toggleDeleteWarningModal}
                    onDeleteContext={onDeleteContext}
                />
            )}

            {dimensionWarning && (
                <DimensionWarningModal
                    toggleDimensionWarningModal={toggleDimensionWarningModal}
                    dimensionWarningSuccess={dimensionWarningSuccess}
                    dimensionChanges={dimensionChanges}
                />
            )}

            {videoWarning && (
                <VideoWarningModal
                    toggleVideoWarningModal={toggleVideoWarningModal}
                    videoWarningSuccess={videoWarningSuccess}
                    isVideo={isVideo}
                />
            )}

            {scaleInfo && (
                <ScaleInfoModal onClose={() => setScaleInfo(false)} />
            )}

            {navigationModal && (
                <ConfirmModal
                    confirm="Yes, Discard The Changes"
                    cancel="No, Stay On This Page"
                    onCancel={() => setNavigationModal(false)}
                    onConfirm={() => {
                        setNavigationModal(false);
                        setHasUnsavedChanges(false);
                    }}
                >
                    <p>
                        There are unsaved changes that will be lost if you
                        navigate away from this page.
                    </p>
                    <p>Are you sure you want to continue?</p>
                </ConfirmModal>
            )}

            <Prompt
                when={hasUnsavedChanges}
                message={(nextLocation) => {
                    if (
                        nextLocation.pathname.startsWith(
                            history.location.pathname,
                        )
                    ) {
                        return true;
                    }
                    if (nextLocation.pathname.startsWith('/login/')) {
                        return true;
                    }
                    if (nextLocation.pathname.startsWith('/reload/')) {
                        return true;
                    }
                    if (hasUnsavedChanges) {
                        setNavigationModal(true);
                        setNavigationTarget(nextLocation);
                        return false;
                    }
                    return true;
                }}
            />
        </Layout>
    );
}

export default withRouter(ContextDetail);
