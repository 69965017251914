import {
    faEllipsisH,
    faPlus,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pill from 'js/components/pill/pill';
import ruleTypes from 'js/enums/rule-types.enum';
import React, { useContext } from 'react';
import ContextDetailContext from '../../../contexts/context-detail.context';

// TODO: this is a terrible component that we should remove.

export default function Keyword({
    keyword,
    rule,
    onPrefixClick,
    onSuffixClick,
    prefixContent,
    suffixContent,
}) {
    const {
        context: {
            current: { rules },
        },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);

    const { aggregation } = rule;

    const targetedKeywords = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item.keywords)
        .flat();

    const blockedKeywords = rules
        .filter((item) => item.aggregation === ruleTypes.EXCLUDED)
        .map((item) => item.keywords)
        .flat();

    const keywordInRule = rule.keywords.includes(keyword);
    const keywordTargeted = targetedKeywords.includes(keyword);
    const keywordBlocked = blockedKeywords.includes(keyword);
    const keywordActive = keywordTargeted || keywordBlocked;

    let theme;
    // the keyword belongs to the rule and is not a related word
    if (keywordInRule && onSuffixClick) {
        theme = aggregation === ruleTypes.INCLUDED ? 'keyword' : 'danger';
    } else if (keywordTargeted) {
        theme = 'keyword';
    } else if (keywordBlocked) {
        theme = 'danger';
    } else {
        theme = 'muted';
    }

    return (
        <Pill
            suffixButton={
                isReadOnly || (!suffixContent && !onSuffixClick) ? null : (
                    <FontAwesomeIcon icon={keywordActive ? faTimes : faPlus} />
                )
            }
            prefixButton={
                isReadOnly || (!prefixContent && !onPrefixClick) ? null : (
                    <FontAwesomeIcon icon={faEllipsisH} />
                )
            }
            outline={theme !== 'keyword'}
            theme={theme}
            onPrefixClick={onPrefixClick}
            onSuffixClick={keywordActive ? onSuffixClick : null}
            prefixDropdownContent={prefixContent}
            suffixDropdownContent={!keywordActive && suffixContent}
        >
            {keyword}
        </Pill>
    );
}
