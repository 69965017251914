import React from 'react';
import * as d3 from 'd3';
import { parseDate } from '../helpers';

const LineSeries = ({
    series: { data, color, isSkinny },
    dateScale,
    yScale,
}) => {
    const style = {
        fill: 'none',
        stroke: color,
        strokeWidth: isSkinny ? 2 : 4,
    };

    const path = d3
        .line()
        .x((d) => dateScale(parseDate(d.date)))
        .y((d) => yScale(d.value))(data);

    if (data.length === 1) {
        return (
            <circle
                cx={path.split(',')[0].replace('M', '')}
                cy={path.split(',')[1].replace('Z', '')}
                style={style}
                r={isSkinny ? 1 : 2}
            />
        );
    }

    return <path style={style} d={path} />;
};

export default LineSeries;
