import React, { useState, useEffect } from 'react';
import Panel from 'js/components/panel/panel';
import Box from 'js/components/box/box';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Statistic from 'js/components/statistic/statistic';

const StatsPanel = ({ metrics, auditData }) => {
    const [summaryData, setSummaryData] = useState(metrics);

    useEffect(() => {
        const totals = {};
        const plainMetrics = metrics.filter(({ format }) => format !== '%');
        const derivedMetrics = metrics.filter(({ format }) => format === '%');

        // plain metrics totals need to be computed first, since the others are derived from them
        plainMetrics.forEach((metric) => {
            totals[metric.id] = auditData.reduce(
                (sum, record) => sum + metric.getValue(record),
                0,
            );
        });
        derivedMetrics.forEach((metric) => {
            totals[metric.id] = metric.getValue(totals);
        });

        setSummaryData(
            metrics.map((metric) => ({
                ...metric,
                value: totals[metric.id],
            })),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, auditData]);

    return (
        <Box margin={['base', 0]}>
            <Panel>
                <Box padding="base">
                    <Row gutter="smaller" gap="base">
                        {summaryData
                            .filter((metric) => !metric.hidden)
                            .map((metric) => (
                                <Statistic
                                    key={metric.id}
                                    name={metric.name}
                                    info={metric.info}
                                >
                                    <Number
                                        value={metric.value}
                                        isPercentage={metric.format === '%'}
                                        maximumFractionDigits={2}
                                    />
                                </Statistic>
                            ))}
                    </Row>
                </Box>
            </Panel>
        </Box>
    );
};

export default StatsPanel;
