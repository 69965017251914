import React from 'react';
import Box from 'js/components/box/box';
import Drawer from 'js/components/drawer/drawer';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Button from 'js/components/button/button';
import uploadGuideSteps from '../upload-guide-copy';

const HelpDrawer = ({
    selectedStep,
    onSetDrawerIsVisible,
    onSetSelectedStep,
}) => {
    const stepIndex = uploadGuideSteps.indexOf(selectedStep);
    return (
        <Drawer isSlimRight onClose={() => onSetDrawerIsVisible(false)}>
            <Box margin={['large', 0]}>
                <Container>
                    {selectedStep.pageCopy}
                    <Row justifyContent="space-between">
                        <Col span="auto">
                            {stepIndex > 0 && (
                                <Button
                                    onClick={() =>
                                        onSetSelectedStep(
                                            uploadGuideSteps[stepIndex - 1],
                                        )
                                    }
                                >
                                    Previous
                                </Button>
                            )}
                        </Col>
                        <Col span="auto">
                            {stepIndex < 2 && (
                                <Button
                                    onClick={() =>
                                        onSetSelectedStep(
                                            uploadGuideSteps[stepIndex + 1],
                                        )
                                    }
                                >
                                    Next
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Box>
        </Drawer>
    );
};

export default HelpDrawer;
