import React, { useLayoutEffect, useRef } from 'react';
import * as d3 from 'd3';
import Translate from './translate';
import styles from '../trend-chart.module.scss';

const Ticks = ({ axis, isGrid, isBottom, tickSize }) => {
    const ref = useRef(null);
    useLayoutEffect(() => {
        if (!axis) return;

        const host = d3.select(ref.current);
        host.select('g').remove();
        const group = host.append('g');

        if (isGrid) axis.tickFormat('').tickSize(-tickSize);

        group.call(axis);
    }, [axis, isGrid, isBottom, tickSize]);

    return <g ref={ref} className={isGrid ? styles.grid : styles.axis} />;
};

export const ChartGrid = ({
    axisBottom,
    axisLeft,
    XAxisWidth,
    YAxisHeight,
    width,
    height,
}) => (
    <>
        <Translate x={XAxisWidth} y={height - YAxisHeight}>
            <Ticks
                axis={axisBottom}
                tickSize={height - 2 * YAxisHeight + 10}
                isBottom
                isGrid
            />
        </Translate>
        {axisLeft && (
            <Translate x={XAxisWidth} y={YAxisHeight}>
                <Ticks
                    axis={axisLeft}
                    tickSize={width - 2 * XAxisWidth}
                    isGrid
                />
            </Translate>
        )}
    </>
);

export const ChartAxis = ({
    axisBottom,
    axisLeft,
    axisRight,
    XAxisWidth,
    YAxisHeight,
    width,
    height,
}) => (
    <>
        <Translate x={XAxisWidth} y={height - YAxisHeight}>
            <Ticks axis={axisBottom} isBottom />
        </Translate>
        {axisLeft && (
            <Translate x={XAxisWidth} y={YAxisHeight}>
                <Ticks axis={axisLeft} />
            </Translate>
        )}
        {axisRight && (
            <Translate x={width - XAxisWidth} y={YAxisHeight}>
                <Ticks axis={axisRight} />
            </Translate>
        )}
    </>
);
