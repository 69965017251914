import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SEARCH_DEBOUNCE_TIMEOUT, SEARCH_MIN_LENGTH } from 'js/constants';
import { api, cleanString, AbortError } from 'js/utils';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import RuleSelector from 'js/components/dropdown-menu/rule-selector';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import ContextDetailContext from '../../../contexts/context-detail.context';
import Keyword from './keyword';
import RuleRelatedKeywordsDropdown from './rule-related-keywords-dropdown';
import styles from './rules-section.module.scss';

const RELATED_KEYWORD_LIST_LIMIT = 10;
let searchDelay;

export default function RuleRelatedKeywords({ rule, keyword }) {
    const {
        context: { current: context, setRules },
        topics: { groups: topicGroups },
    } = useContext(ContextDetailContext);
    const { language, rules } = context;

    const [relatedKeyword, setRelatedKeyword] = useState('');
    const [isRelatedSearchLoading, setIsRelatedSearchLoading] = useState(false);
    const [relatedWords, setRelatedWords] = useState([]);
    const usedRelatedWords = relatedWords.filter((word) =>
        rules.some(({ keywords }) => keywords.includes(word)),
    );
    const visibleRelatedWords = relatedWords
        .filter((word) => !usedRelatedWords.includes(word))
        .slice(0, RELATED_KEYWORD_LIST_LIMIT);

    const setKeywordSearch = useCallback(
        (searchTerm) => {
            if (searchDelay) clearTimeout(searchDelay);

            if (searchTerm.length >= SEARCH_MIN_LENGTH) {
                setIsRelatedSearchLoading(true);
                setRelatedKeyword(searchTerm);

                searchDelay = setTimeout(async () => {
                    try {
                        const { signal } = api().controllers.setKeywordSearch;
                        const response = await api().relatedAnalytics.create(
                            {
                                keywords: searchTerm.split(' '),
                                language,
                            },
                            {},
                            { signal },
                        );

                        setRelatedWords(
                            response.results
                                .map((item) => item.entity)
                                .filter((item) => !item.includes("'")),
                        );
                    } catch (errors) {
                        if (errors instanceof AbortError) return;
                        setRelatedWords([]);
                    }

                    setIsRelatedSearchLoading(false);
                }, SEARCH_DEBOUNCE_TIMEOUT);
            } else {
                setRelatedKeyword('');
                setRelatedWords([]);
                setIsRelatedSearchLoading(false);
            }
        },
        [language],
    );

    useEffect(() => {
        setKeywordSearch(cleanString(keyword));
    }, [keyword]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isRelatedSearchLoading) {
        return (
            <Box
                margin={[0, '-base']}
                padding="base"
                borderTop={['base', 'solid', 'gray', 'lightest']}
                borderBottom={['base', 'solid', 'gray', 'lightest']}
                background={['gray', 'background']}
            >
                <Row>
                    <Col>
                        <Text color={['gray', 'dark']}>
                            Keywords related to “{relatedKeyword}“
                        </Text>

                        <Box margin={['small', 0, 0]}>
                            <Spinner size="small" color={['gray', 'dark']} />
                        </Box>
                    </Col>
                </Row>
            </Box>
        );
    }

    if (visibleRelatedWords.length) {
        return (
            <Box
                margin={[0, '-base']}
                padding="base"
                borderTop={['base', 'solid', 'gray', 'lightest']}
                borderBottom={['base', 'solid', 'gray', 'lightest']}
                background={['gray', 'background']}
            >
                <Text color={['gray', 'dark']}>
                    Keywords related to “{relatedKeyword}”
                </Text>

                <div className={styles['related-keywords']}>
                    {visibleRelatedWords.map((word) => (
                        <Box
                            key={word}
                            display="inline-block"
                            margin={['small', 'small', 0, 0]}
                        >
                            <Keyword
                                keyword={word}
                                rule={rule}
                                prefixContent={
                                    <RuleRelatedKeywordsDropdown
                                        keyword={word}
                                        rule={rule}
                                    />
                                }
                                suffixContent={
                                    <div className={styles.dropdown}>
                                        <RuleSelector
                                            keyword={word}
                                            targetRuleKey={rule.key}
                                            topicGroups={topicGroups}
                                            rules={rules}
                                            onChangeRules={setRules}
                                        />
                                    </div>
                                }
                            />
                        </Box>
                    ))}
                </div>
            </Box>
        );
    }

    if (!relatedKeyword.length) return null;

    return (
        <Box
            margin={[0, '-base']}
            padding="base"
            borderTop={['base', 'solid', 'gray', 'lightest']}
            borderBottom={['base', 'solid', 'gray', 'lightest']}
            background={['gray', 'background']}
        >
            <Text color={['gray', 'dark']}>
                Keywords related to “{relatedKeyword}”
            </Text>

            <Box
                margin={['small', 0, 0]}
                padding={['small', 'base']}
                border={['thick', 'dashed', 'gray', 'light']}
                borderRadius="round"
            >
                <Text color={['gray', 'dark']}>
                    This is where we would show you some related keywords, but
                    it looks like you’re targeting or blocking them all already,
                    or we weren’t able to identify any in the first place.
                </Text>
            </Box>
        </Box>
    );
}
