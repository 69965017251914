import React from 'react';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';

const dealReportMetricsInitial = [
    {
        name: 'Impressions Matched',
        key: 'avails',
        value: 0,
        helpText: 'Tells you if your deal is populating with matches.',
        troubleShooting: (
            <>
                <p>
                    If this number is 0, something has gone wrong and the deal
                    may be invalid.{' '}
                    <SupportEmailLink emailSubject="Deal Report Troubleshooting">
                        Click here
                    </SupportEmailLink>{' '}
                    to contact your Account Manager to assist you.
                </p>
                <p>
                    If this number is low (but not 0), and you are having
                    trouble scaling, explore broadening the make up of your
                    Context by adding more Topics.
                </p>
            </>
        ),
    },
    {
        name: 'Bid Requests',
        key: 'bid_requests',
        value: 0,
        helpText:
            'Tells you how many actual bid requests have been sent to the buyer.',
        troubleShooting: (
            <p>
                If this number is significantly lower than the Imps Matched,
                check the campaign set up to see if there are any (undesired)
                restrictions, e.g. geo-targeting, high Invalid Traffic levels,
                Brand safety, whitelists.
            </p>
        ),
    },
    {
        name: 'Bids',
        key: 'bids',
        value: 0,
        helpText: 'Tells you if the deal has been actively bidding.',
        troubleShooting: (
            <>
                <p>
                    No Bids means bidding is not taking place and therefore
                    Auctions Won will also be 0.
                </p>
                <p>
                    If this number is low, please check with your DSP or
                    Adserver. Common causes may be that the Deal is not
                    correctly implemented, active or is ineligible to bid in
                    some other way.
                </p>
            </>
        ),
    },
    {
        name: 'Auctions Won',
        key: 'wins',
        value: 0,
        helpText:
            'Tells you how successful the deal is at winning the auctions it bids on.',
        troubleShooting: (
            <p>
                If bidding is taking place but there are few (or zero) wins,
                check the CPM. Often issues in this area come down to pricing,
                and the bid price being too low.
            </p>
        ),
    },
];

export default dealReportMetricsInitial;
