import React from 'react';
import ConfirmModal from 'js/components/modal/confirm-modal';

export default function VideoWarningModal({
    toggleVideoWarningModal,
    videoWarningSuccess,
    isVideo,
}) {
    return (
        <ConfirmModal
            confirm="Yes, Continue"
            cancel="No, Cancel"
            onCancel={toggleVideoWarningModal}
            onConfirm={videoWarningSuccess}
        >
            <p>
                Changing the context type to {isVideo ? 'display' : 'video'}{' '}
                will clear all dimension, channel and optimization settings for
                this context.
            </p>
            <p>Are you sure you want to continue?</p>
        </ConfirmModal>
    );
}
