import { color, borderRadius } from '../tokens.styles';

const styles = {
    container: {
        position: 'relative',
        borderRadius: borderRadius.round,
    },

    'theme--primary': {
        backgroundColor: color.gray.lightest,
    },

    'theme--secondary': {
        backgroundColor: color.gray.background,
    },

    'theme--impressions': {
        color: color.purple.darker,
        backgroundColor: 'rgba(229, 232, 255, 0.5)', // color.purple.lightest
    },

    'theme--ctr': {
        color: color.aqua.darker,
        backgroundColor: 'rgba(210, 246, 249, 0.5)', // color.aqua.lightest
    },

    'theme--view-rate': {
        color: color.blue.darker,
        backgroundColor: 'rgba(222, 235, 255, 0.5)', // color.blue.lightest
    },
};

export default styles;
