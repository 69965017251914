import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import Crumb from 'js/components/breadcrumbs/crumb';
import { AbortError, setDocumentTitle, api } from 'js/utils';
import RenameDataSources from './rename-data-sources';

const RenameDataSourcesPage = () => {
    const { location } = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [hasErrors, setErrors] = useState(false);
    const [eventHierarchy, setEventHierarchy] = useState([]);
    const [campaignNames, setCampaignNames] = useState([]);
    const [lineItemNames, setLineItemNames] = useState([]);
    const [dataSourcesStructured, setDataSourcesStructured] = useState([]);
    const [unAssociatedLineItems, setUnassociatedLineItems] = useState([]);

    const createOrEditCampaignName = async (campaigns) => {
        try {
            setErrors(false);

            const groomedCampaigns = campaigns.map((campaign) => ({
                source: campaign.source,
                campaign_id: campaign.campaignId,
                campaign_name: campaign.campaignName,
            }));

            const updatedCampaignNames = await api().namedCampaign.create(
                groomedCampaigns,
            );
            setCampaignNames((curr) => [...curr, ...updatedCampaignNames]);
        } catch (errors) {
            if (errors instanceof AbortError) return;
            setErrors(true);
        }
    };

    const createOrEditLineItemName = async (lineItems) => {
        try {
            setErrors(false);

            const groomedLineItems = lineItems.map((lineItem) => ({
                source: lineItem.source,
                line_item_id: lineItem.lineItemId,
                line_item_name: lineItem.lineItemName,
            }));

            const updatedLineItems = await api().namedLineItem.create(
                groomedLineItems,
            );
            setLineItemNames((curr) => [...curr, ...updatedLineItems]);
        } catch (errors) {
            if (errors instanceof AbortError) return;
            setErrors(true);
        }
    };

    const loadData = async () => {
        try {
            setLoading(true);
            setErrors(false);

            const [
                { results: campaignNamesData },
                { results: lineItemNamesData },
                { results: eventHierarchyData },
            ] = await Promise.all([
                api().namedCampaign.list(),
                api().namedLineItem.list(),
                api().eventHierarchy.list({ verbose: true }),
            ]);

            setEventHierarchy(eventHierarchyData);
            setCampaignNames(campaignNamesData);
            setLineItemNames(lineItemNamesData);
        } catch (errors) {
            if (errors instanceof AbortError) return;

            setErrors(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        /*
            This monster of an effect does the following:
            - Deduplicates campaigns and line items in the event hierarchy
            - Creates nested structure of campaigns and their line items
            - Integrates campaign and line item names that aren't present in the event hierarchy
            - Applies name changes to the structured data in the case that the name no longer matches what was returned by the event hierarchy
            - Identifies which line item names don't have data and saves them in a separate list, as they cannot be nested in structured data

            I'll leave comments in for now, as they may help during review.
        */

        // get unique campaigns into a list
        const uniqueCampaigns = [];
        eventHierarchy.forEach((event) => {
            const newCampaign = {
                source: event.source,
                campaignId: event.campaign,
                campaignName: event.campaign_name || '',
                hasData: true,
            };

            if (
                !uniqueCampaigns.some(
                    (existingCampaign) =>
                        existingCampaign.source === newCampaign.source &&
                        existingCampaign.campaignId === newCampaign.campaignId,
                )
            ) {
                uniqueCampaigns.push(newCampaign);
            }
        });

        // Get unique line items placed into their the unique campaigns
        let campaignsWithLineItems = uniqueCampaigns.map((campaign) => {
            const lineItems = [
                ...new Set(
                    eventHierarchy
                        .filter(
                            (event) =>
                                event.source === campaign.source &&
                                event.campaign === campaign.campaignId,
                        )
                        .map((filteredItem) => filteredItem.line_item),
                ),
            ].map((lineItem) => ({
                source: campaign.source,
                lineItemId: lineItem,
                lineItemName: lineItem.line_item_name || '',
                hasData: true,
            }));
            return {
                ...campaign,
                lineItems,
            };
        });

        // add campaign names that aren't in heirarchy to structured data. Reassign name in case it no longer matches event hierachy
        campaignNames.forEach((x) => {
            const namedCampaignInHierarchy = campaignsWithLineItems.find(
                (existing) =>
                    existing.campaignId === x.campaign_id &&
                    existing.source === x.source,
            );

            if (namedCampaignInHierarchy) {
                const updated = {
                    ...namedCampaignInHierarchy,
                    campaignName: x.campaign_name,
                };

                const filteredCampaigns = campaignsWithLineItems.filter(
                    (campaign) => campaign !== namedCampaignInHierarchy,
                );

                campaignsWithLineItems = [...filteredCampaigns, updated];
            } else {
                const newCampaign = {
                    source: x.source,
                    campaignId: x.campaign_id,
                    campaignName: x.campaign_name,
                    hasData: false,
                    lineItems: [],
                };

                campaignsWithLineItems.push(newCampaign);
            }
        });

        // check to see if line item names have data, reassign name if it no longer matches event hierarchy
        const lineItemsMatchedToCampaigns = [];
        lineItemNames.forEach((item) => {
            const newCampaigns = campaignsWithLineItems.map((campaign) => {
                const lineItemInHierarchy = campaign.lineItems.find(
                    (existing) =>
                        existing.lineItemId === item.line_item_id &&
                        existing.source === item.source,
                );

                if (lineItemInHierarchy) {
                    const updatedLineItem = {
                        ...lineItemInHierarchy,
                        lineItemName: item.line_item_name,
                    };

                    const lineItemsFiltered = campaign.lineItems.filter(
                        (lineItem) =>
                            lineItem.lineItemId !==
                            lineItemInHierarchy.lineItemId,
                    );

                    lineItemsMatchedToCampaigns.push(item);

                    return {
                        ...campaign,
                        lineItems: [...lineItemsFiltered, updatedLineItem],
                    };
                }
                return campaign;
            });

            campaignsWithLineItems = newCampaigns;
        });

        // format and store the line items not matched to campaigns
        const lineItemsWithoutCampaign = lineItemNames
            .filter(
                (lineItem) => !lineItemsMatchedToCampaigns.includes(lineItem),
            )
            .map((item) => ({
                source: item.source,
                lineItemId: item.line_item_id,
                lineItemName: item.line_item_name,
                hasData: false,
            }));

        setDataSourcesStructured(campaignsWithLineItems);
        setUnassociatedLineItems(lineItemsWithoutCampaign);
    }, [eventHierarchy, campaignNames, lineItemNames]);

    useEffect(() => {
        loadData();
        setDocumentTitle(['Rename Data Sources']);
    }, []);

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        location.pathname.includes('insights') ? (
                            <Crumb
                                key="insights"
                                to="/insights/"
                                label="Insights"
                                isCollapsible
                            />
                        ) : (
                            <Crumb
                                key="settings"
                                to="/settings/"
                                label="Settings"
                                isCollapsible
                            />
                        ),
                        <Crumb
                            key="rename-data-sources"
                            label="Rename Data Sources"
                        />,
                    ]}
                />
            }
        >
            <RenameDataSources
                onUploadCampaignsFromTemplate={createOrEditCampaignName}
                onUploadLineItemsFromTemplate={createOrEditLineItemName}
                dataSources={dataSourcesStructured}
                unAssociatedLineItems={unAssociatedLineItems}
                onCampaignNameChange={createOrEditCampaignName}
                onLineItemNameChange={createOrEditLineItemName}
                isLoading={isLoading}
                hasErrors={hasErrors}
                onRetry={loadData}
            />
        </Layout>
    );
};

export default RenameDataSourcesPage;
