import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Emblem from 'js/components/emblem/emblem';
import Number from 'js/components/number/number';
import Row from 'js/components/grid/row';
import RadialChart from 'js/components/radial-chart/radial-chart';
import Sparkline from 'js/components/sparkline/sparkline';
import Text from 'js/components/text/text';
import Alert from 'js/components/alert/alert';
import { getImpressionThreshold } from 'js/utils';
import styles from './highlights-section.module.scss';

const Value = ({ value, isPercentage }) =>
    isPercentage ? (
        <Number value={value} maximumFractionDigits={2} isPercentage />
    ) : (
        <Number
            value={value}
            abbreviationFormats={[
                { target: 1e6, symbol: 'm', maxFractionDigits: 2 },
                {
                    target: 1e4,
                    appliedValue: 1e3,
                    symbol: 'k',
                    maxFractionDigits: 0,
                },
                {
                    target: 1e3,
                    appliedValue: 1e3,
                    symbol: 'k',
                    maxFractionDigits: 2,
                },
                {
                    target: 1,
                    appliedValue: 1,
                    symbol: '',
                    maxFractionDigits: 0,
                },
            ]}
        />
    );

const StatsContainer = ({ item, background, kpis }) => (
    <div className={styles['stats-container']}>
        <Emblem
            name={item.name}
            logo={item.logo}
            background={background}
            color={['gray', 'white']}
        />

        <div className={styles['stats-content']}>
            {item.logo && (
                <Text size="large" weight="bold">
                    {item.name}
                </Text>
            )}

            {Object.values(kpis).map((k, index) => {
                const value = k.getValue(item);

                if (!value) {
                    return null;
                }

                return (
                    <Box
                        key={k.name}
                        margin={[(index || item.logo) && 'small', 0, 0]}
                    >
                        <Row>
                            <Col>
                                <Text color={['gray', 'dark']}>{k.name}</Text>
                            </Col>

                            <Col span="auto">
                                <Text weight="bold">
                                    <Value
                                        value={value}
                                        isPercentage={k.isPercentage}
                                    />
                                </Text>
                            </Col>
                        </Row>
                    </Box>
                );
            })}
        </div>
    </div>
);

const Bar = ({ name, value, maxValue, color, isPercentage }) => (
    <>
        <Row>
            <Col>
                <Text weight="bold">{name}</Text>
            </Col>

            <Col span="auto">
                <Value value={value} isPercentage={isPercentage} />
            </Col>
        </Row>

        <Box padding={['small', 0, 'base']}>
            <Sparkline
                value={value}
                maxValue={maxValue}
                color={color}
                noLabel
            />
        </Box>
    </>
);

const HintPanel = ({ hint, icon, baseColor, iconColor }) => (
    <div className={styles['hint-panel']}>
        <Box
            display="flex"
            padding="base"
            borderRadius="rounder"
            background={[baseColor, 'lightest']}
        >
            <Row alignItems="center">
                <Col span="auto">
                    <Text size="huge" color={iconColor}>
                        <FontAwesomeIcon icon={icon} />
                    </Text>
                </Col>

                <Col>
                    <Text color={[baseColor, 'darker']}>{hint}</Text>
                </Col>
            </Row>
        </Box>
    </div>
);

const HighlightsPanel = ({ kpis, kpi, topItems, average, hint }) => {
    const firstTopItem = topItems[0];

    return (
        <div className={styles.container}>
            <Text size="large" color={['gray', 'dark']}>
                Top By {kpi.name}
            </Text>

            <Box margin={['base', 0]}>
                <StatsContainer
                    item={firstTopItem}
                    background={kpi.color}
                    kpis={kpis}
                />
            </Box>

            {topItems.map((item) => (
                <Bar
                    key={item.name}
                    name={item.name}
                    value={kpi.getValue(item)}
                    maxValue={kpi.getValue(firstTopItem)}
                    color={kpi.color}
                    isPercentage={kpi.isPercentage}
                />
            ))}

            {average !== undefined && (
                <Bar
                    name="Average"
                    value={average}
                    maxValue={kpi.getValue(firstTopItem)}
                    color={['gray', 'base']}
                    isPercentage={kpi.isPercentage}
                />
            )}

            <HintPanel
                hint={hint}
                icon={kpi.hintIcon}
                baseColor={kpi.color[0]}
                iconColor={kpi.color}
            />
        </div>
    );
};

const HighlightsSection = ({
    data,
    report,
    kpis,
    origin,
    impressionsHint,
    ctrHint,
    viewRateHint,
    startedViewsHint,
    compRateHint,
}) => {
    const impressionThreshold = getImpressionThreshold(report.impressions);
    const topItems = (items, kpi, limit = 4) =>
        [...items]
            .sort((a, b) => kpi.getValue(b) - kpi.getValue(a))
            .filter((item) => !!kpi.getValue(item))
            .splice(0, limit)
            .filter((item) => item.impressions >= impressionThreshold);

    const avgValue = (items, kpi) =>
        items.reduce((sum, item) => sum + kpi.getValue(item), 0) / items.length;

    const hasCTR = kpis.CTR && data.some((item) => !!item.clicks);
    const hasViewRate = kpis.VIEW_RATE && data.some((item) => !!item.in_views);
    const hasStartedViews =
        kpis.STARTED_VIEWS && data.some((item) => !!item.starts);
    const hasCompletionRate =
        kpis.COMPLETION_RATE &&
        data.some((item) => !!item.completes && !!item.impressions);

    const topByImps = topItems(data, kpis.IMPRESSIONS);
    const topByCTR = hasCTR ? topItems(data, kpis.CTR, 3) : [];
    const topByViewRate = hasViewRate ? topItems(data, kpis.VIEW_RATE, 3) : [];
    const topByStartedViews = hasStartedViews
        ? topItems(data, kpis.STARTED_VIEWS)
        : [];
    const topByCompRate = hasCompletionRate
        ? topItems(data, kpis.COMPLETION_RATE, 3)
        : [];

    const showImps = topByImps.length > 0;
    const showCTR = topByCTR.length > 0;
    const showViewRate = topByViewRate.length > 0;
    const showStartedViews = topByStartedViews.length > 0;
    const showCompRate = topByCompRate.length > 0;

    const numColumns = [
        showImps,
        showCTR,
        showViewRate,
        showStartedViews,
        showCompRate,
    ].reduce((acc, curr) => {
        if (curr) return acc + 1;
        return acc;
    }, 0);

    const lowRiskOrigin = data.filter((item) => item.name === 'Low Risk')[0];

    if (origin?.name === 'Piracy Control' && lowRiskOrigin) {
        if (!lowRiskOrigin.impressions >= impressionThreshold) {
            return (
                <Alert theme="empty">
                    <p>Not enough data.</p>
                </Alert>
            );
        }
        const piracyRisk =
            (report.impressions - lowRiskOrigin.impressions) /
            report.impressions;

        return (
            <Row gap="large" gutter="large" alignItems="stretch">
                <Col span={12} spanMd="equal">
                    <StatsContainer
                        item={lowRiskOrigin}
                        background={['purple', 'base']}
                        kpis={kpis}
                    />
                </Col>

                <Col span={12} spanMd="auto">
                    <RadialChart
                        metrics={[
                            {
                                id: 'imprs',
                                name: 'Impressions',
                                color: '#6673ff',
                            },
                        ]}
                        measures={[piracyRisk]}
                        maxMeasures={[1]}
                        iconSize={30}
                        chartSize={145}
                    >
                        <Text weight="bold">
                            <Number
                                value={piracyRisk}
                                maximumFractionDigits={2}
                                isPercentage
                            />
                        </Text>
                        <Box padding="smaller">
                            <Text color={['gray', 'dark']}>Piracy Risk</Text>
                        </Box>
                    </RadialChart>
                </Col>

                <Col span={12} spanMd="equal">
                    <HintPanel
                        hint="To avoid piracy risks, target the ‘Low Risk’ signal."
                        icon={faEyeSlash}
                        baseColor="purple"
                        iconColor={['purple', 'base']}
                    />
                </Col>
            </Row>
        );
    }

    if (numColumns === 0) {
        return (
            <Alert theme="empty">
                <p>Not enough data.</p>
            </Alert>
        );
    }

    return (
        <Row gap="large" gutter="large">
            {showImps && (
                <Col span={12} spanMd={6} spanLg={12 / numColumns}>
                    <HighlightsPanel
                        kpis={kpis}
                        kpi={kpis.IMPRESSIONS}
                        topItems={topByImps}
                        hint={impressionsHint}
                    />
                </Col>
            )}

            {showCTR && (
                <Col span={12} spanMd={6} spanLg={12 / numColumns}>
                    <HighlightsPanel
                        kpis={kpis}
                        kpi={kpis.CTR}
                        topItems={topByCTR}
                        average={avgValue(data, kpis.CTR)}
                        hint={ctrHint}
                    />
                </Col>
            )}

            {showViewRate && (
                <Col span={12} spanMd={6} spanLg={12 / numColumns}>
                    <HighlightsPanel
                        kpis={kpis}
                        kpi={kpis.VIEW_RATE}
                        topItems={topByViewRate}
                        average={avgValue(data, kpis.VIEW_RATE)}
                        hint={viewRateHint}
                    />
                </Col>
            )}

            {showStartedViews && (
                <Col span={12} spanMd={6} spanLg={12 / numColumns}>
                    <HighlightsPanel
                        kpis={kpis}
                        kpi={kpis.STARTED_VIEWS}
                        topItems={topByStartedViews}
                        hint={startedViewsHint}
                    />
                </Col>
            )}

            {showCompRate && (
                <Col span={12} spanMd={6} spanLg={12 / numColumns}>
                    <HighlightsPanel
                        kpis={kpis}
                        kpi={kpis.COMPLETION_RATE}
                        topItems={topByCompRate}
                        average={avgValue(data, kpis.COMPLETION_RATE)}
                        hint={compRateHint}
                    />
                </Col>
            )}
        </Row>
    );
};

export default HighlightsSection;
