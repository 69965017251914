import React from 'react';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import SwitchButton from 'js/components/button/switch-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import InspectDrawer, {
    InspectDrawerSummary,
    MatchedVideos,
} from './inspect-drawer/inspect-drawer';
import InspectDrawerBg from './images/inspect-drawer-bg.svg';
import targetingOptions from '../enums/targetingOptions';

const Summary = ({
    template,
    report,
    context,
    inspectedItem,
    onChangeContextRules,
}) => {
    const {
        id: itemId,
        name: itemName,
        targetingOption,
        impressions,
        clicks,
        completes,
    } = inspectedItem;

    const allTargetedTopics = context.rules
        .filter(({ aggregation }) => aggregation === ruleTypes.INCLUDED)
        .map(({ topics }) => topics)
        .flat();

    const allBlockedTopics = context.rules
        .find(({ aggregation }) => aggregation === ruleTypes.EXCLUDED)
        .topics.flat();

    const allTargetedLogos = context.rules
        .filter(({ aggregation }) => aggregation === ruleTypes.INCLUDED)
        .map(({ logos }) => logos)
        .flat();

    const allBlockedLogos = context.rules
        .find(({ aggregation }) => aggregation === ruleTypes.EXCLUDED)
        .logos.flat();

    const ruleSet = new RuleSet(context.rules);

    const toggleTarget = (addItems, removeItems) => {
        const firstRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
        );

        if (targetingOption === targetingOptions.TOPIC) {
            ruleSet.changeRule(firstRule.key, { addTopics: addItems });
            ruleSet.changeAllRules({ removeTopics: removeItems });
        } else if (targetingOption === targetingOptions.LOGO) {
            ruleSet.changeRule(firstRule.key, { addLogos: addItems });
            ruleSet.changeAllRules({ removeLogos: removeItems });
        }

        onChangeContextRules(ruleSet.rules);
    };

    const toggleBlock = (addItems, removeItems) => {
        const notRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.EXCLUDED,
        );

        if (targetingOption === targetingOptions.TOPIC) {
            ruleSet.changeAllRules({ removeTopics: removeItems });
            ruleSet.changeRule(notRule.key, { addTopics: addItems });
        } else if (targetingOption === targetingOptions.LOGO) {
            ruleSet.changeAllRules({ removeLogos: removeItems });
            ruleSet.changeRule(notRule.key, { addLogos: addItems });
        }

        onChangeContextRules(ruleSet.rules);
    };

    const isApplied =
        targetingOption === targetingOptions.TOPIC
            ? allTargetedTopics.includes(inspectedItem.id)
            : allTargetedLogos.includes(inspectedItem.id);
    const isBlocked =
        targetingOption === targetingOptions.TOPIC
            ? allBlockedTopics.includes(inspectedItem.id)
            : allBlockedLogos.includes(inspectedItem.id);

    return (
        <InspectDrawerSummary
            report={report}
            template={template}
            impressions={impressions}
            clicks={clicks}
            completes={completes}
            backgroundImg={InspectDrawerBg}
            header={
                <Row>
                    <Col>
                        <Text weight="bolder" color={['gray', 'dark']}>
                            {targetingOption === targetingOptions.TOPIC
                                ? 'Topic'
                                : 'Brand'}
                        </Text>
                        <Text size="huge">{itemName}</Text>
                    </Col>
                    <Col span="auto">
                        <SwitchButton
                            size="small"
                            active={isApplied}
                            onChange={() =>
                                toggleTarget(
                                    isApplied ? [] : [itemId],
                                    isApplied ? [itemId] : [],
                                )
                            }
                        >
                            Target
                        </SwitchButton>
                    </Col>
                    <Col span="auto">
                        <SwitchButton
                            size="small"
                            active={isBlocked}
                            onChange={() =>
                                toggleBlock(
                                    isBlocked ? [] : [itemId],
                                    isBlocked ? [itemId] : [],
                                )
                            }
                        >
                            Block
                        </SwitchButton>
                    </Col>
                </Row>
            }
        />
    );
};

const VideoInspectDrawer = ({
    template,
    report,
    context,
    inspectedItem,
    onChangeContextRules,
    onClose,
    matchedVideos,
    isLoading,
    hasError,
    onReload,
}) => {
    const label =
        inspectedItem.targetingOption === targetingOptions.TOPIC
            ? 'Topic'
            : 'Brand';
    return (
        <InspectDrawer
            summary={
                <Summary
                    template={template}
                    report={report}
                    context={context}
                    inspectedItem={inspectedItem}
                    onChangeContextRules={onChangeContextRules}
                />
            }
            content={
                <>
                    <Box margin={['large', 0]}>
                        <Text size="large" color={['gray', 'dark']}>
                            The following videos are examples of content that
                            the {label} ‘{inspectedItem.name}’ matches.
                        </Text>
                    </Box>

                    <MatchedVideos
                        videos={matchedVideos}
                        isLoading={isLoading}
                        hasError={hasError}
                        onReload={onReload}
                    />
                </>
            }
            onClose={onClose}
        />
    );
};

export default VideoInspectDrawer;
