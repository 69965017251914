import React, { useState } from 'react';
import Collapse from 'js/components/collapse/collapse';
import TitleBar from 'js/components/help-dropdown/title-bar';
import PropTypes from 'prop-types';
import styles from './help-dropdown.module.scss';

const HelpDropdown = ({ title, children }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className={styles.help}>
            <TitleBar isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
                <h2>{title}</h2>
            </TitleBar>
            <Collapse isOpen={isOpen}>
                <div className={styles.section}>{children}</div>
            </Collapse>
        </div>
    );
};

HelpDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default HelpDropdown;
