import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Checkbox from 'js/components/checkbox/checkbox';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Panel from 'js/components/panel/panel';
import Select from 'js/components/select/select';
import Text from 'js/components/text/text';

const CampaignLinesSelector = ({
    campaignId,
    eventHierarchy,
    template,
    campaignLabel,
    lineItemLabel,
    onChange,
    onRemove,
}) => {
    const [searchedCampaign, setSearchedCampaign] = useState('');
    const [searchedLineItem, setSearchedLineItem] = useState('');
    const { campaigns, source: sourceId } = template;

    const visibleCampaigns = [
        ...new Map(
            eventHierarchy
                .filter(
                    ({ source, campaign, campaign_name: campaignName }) =>
                        source === sourceId &&
                        (campaign === campaignId ||
                            !campaigns.some(
                                ({ campaign_id: id }) => id === campaign,
                            )) &&
                        (campaign
                            .toLowerCase()
                            .includes(searchedCampaign.toLowerCase()) ||
                            campaignName
                                ?.toLowerCase()
                                .includes(searchedCampaign.toLowerCase())),
                )
                .map(({ campaign, campaign_name: campaignName }) => ({
                    id: campaign,
                    name: campaignName,
                }))
                .map((campaign) => [campaign.id, campaign]),
        ).values(),
    ];

    const campaignOptions = visibleCampaigns
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(({ id, name }) => ({
            value: id,
            label: (
                <Text>
                    {name && (
                        <>
                            <Text inline weight="bold">
                                {name}
                            </Text>{' '}
                        </>
                    )}

                    <Text inline weight="base">
                        {id}
                    </Text>
                </Text>
            ),
        }));

    const lineItemIds =
        campaigns
            .find(({ campaign_id: id }) => id === campaignId)
            ?.line_items.map(({ line_item_id: id }) => id) || [];

    const visibleLineItems = [
        ...new Map(
            eventHierarchy
                .filter(
                    ({
                        source,
                        campaign,
                        line_item: lineItemId,
                        line_item_name: lineItemName,
                    }) =>
                        source === sourceId &&
                        campaign === campaignId &&
                        (lineItemId
                            .toLowerCase()
                            .includes(searchedLineItem.toLowerCase()) ||
                            lineItemName
                                ?.toLowerCase()
                                .includes(searchedLineItem.toLowerCase())),
                )
                .map(
                    ({
                        line_item: lineItemId,
                        line_item_name: lineItemName,
                    }) => ({
                        id: lineItemId,
                        name: lineItemName,
                    }),
                )
                .map((lineItem) => [lineItem.id, lineItem]),
        ).values(),
    ];

    const lineItemOptions = visibleLineItems
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(({ id, name }) => ({
            value: id,
            label: (
                <>
                    {name && (
                        <>
                            <Text inline>{name}</Text>{' '}
                        </>
                    )}

                    <Text inline weight="base">
                        {id}
                    </Text>
                </>
            ),
        }));

    return (
        <Panel bordered>
            <Box margin="base">
                <Select
                    label={campaignLabel}
                    width="same"
                    options={campaignOptions}
                    selectedValues={campaignId}
                    emptyMessage={`
                        There are no ${campaignLabel}s that match
                        your search.
                    `}
                    onChange={(value) => onChange(value, [])}
                    onSearch={setSearchedCampaign}
                />
            </Box>

            <Box margin="base">
                <Input
                    label={lineItemLabel}
                    placeholder="Search by name or by ID"
                    value={searchedLineItem}
                    prefix={<FontAwesomeIcon icon={faSearch} />}
                    onChange={(e) => setSearchedLineItem(e.target.value)}
                    onClear={() => setSearchedLineItem('')}
                    focusOnShow
                />
            </Box>

            <Box margin="base">
                {lineItemOptions.length > 0 ? (
                    <Checkbox
                        options={[
                            {
                                label: `All ${campaignLabel} ${lineItemLabel}s`,
                                value: '',
                            },
                            ...lineItemOptions,
                        ]}
                        selectedValues={lineItemIds}
                        onChange={(values) => onChange(campaignId, values)}
                    />
                ) : (
                    <Alert theme="empty">
                        <p>
                            There are no {lineItemLabel}s that match your
                            search.
                        </p>
                    </Alert>
                )}
            </Box>

            {onRemove && (
                <Box margin="base">
                    <Row justifyContent="flex-end">
                        <Col span="auto">
                            <Button
                                size="small"
                                theme="outline"
                                onClick={() => onRemove(campaignId)}
                            >
                                Remove {campaignLabel}
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}
        </Panel>
    );
};

export default CampaignLinesSelector;
