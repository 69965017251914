import React from 'react';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Switch from 'js/components/switch/switch';
import Text from 'js/components/text/text';
import Legend from 'js/components/treemap-chart/legend';

const CtrSwitchLegend = ({ colorScale, sizeScale, isChecked, onChange }) => (
    <Box margin={['-small', 0]}>
        <Row alignItems="center" gutter="small">
            <Col span="auto">
                <Text color={['gray', 'dark']}>CTR</Text>
            </Col>
            <Col span="auto">
                <Switch isChecked={isChecked} onChange={onChange} />
            </Col>
            <Col span="auto">
                {isChecked && (
                    <Box padding={[0, 'small']}>
                        <Legend
                            lowLabel="Lower CTR"
                            highLabel="Higher CTR"
                            colorScale={colorScale}
                            sizeScale={sizeScale}
                        />
                    </Box>
                )}
            </Col>
        </Row>
    </Box>
);

export default CtrSwitchLegend;
