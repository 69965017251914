import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import Crumb from 'js/components/breadcrumbs/crumb';
import { api, setDocumentTitle } from 'js/utils';
import YoutubeDownload from './task-detail';

const TIMEOUT_LIMIT = 600000; // ten minutes

const statusOptions = {
    LOADING: {
        title: 'Your DV360 SDF download is being prepared.',
        description:
            "We're calculating how long this will take. Please don't navigate away from this page.",
    },
    ERROR: {
        CONTEXT_CHANNEL: {
            title: 'Oops, something went wrong.',
            description:
                'We are not able to generate an SDF file for this context because it either does not exist or it does not have a YouTube channel enabled.',
        },
        GENERAL: {
            title: 'Oops, something went wrong.',
            description:
                'Please reload the page and try again. If the error persists contact your account manager.',
        },
    },
    SUCCESS: {
        title: 'Your DV360 SDF has been successfully created and downloaded!',
        description:
            'Click the button below to be redirected to DV360 where the file can be uploaded.',
    },
};

const YoutubeSdfDownloadPage = () => {
    const { contextId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [statusMsg, setStatusMsg] = useState(statusOptions.LOADING);
    const [redirectUrl, setRedirectUrl] = useState('');

    const breadcrumbs = [
        <Crumb key="home" to="/" label="4D" />,
        <Crumb key="sdfDownload" label="DV360 SDF Download" />,
    ];

    setDocumentTitle(['DV360 SDF Download', contextId]);

    const isValidContext = async () => {
        try {
            const callbacks = [
                api().contexts.retrieve(contextId, {
                    verbose: true,
                }),
                api().channels.list({
                    contexts: contextId,
                    verbose: true,
                }),
            ];

            const [context, { results: contextChannels }] = await Promise.all(
                callbacks,
            );

            const hasYoutubeChannel = contextChannels.some((channel) =>
                channel.name.toLowerCase().includes('youtube'),
            );

            if (!context || !context?.is_video || !hasYoutubeChannel) {
                setHasError(true);
                setIsLoading(false);
                setStatusMsg(statusOptions.ERROR.CONTEXT_CHANNEL);
            }

            return context && context?.is_video && hasYoutubeChannel;
        } catch (err) {
            setHasError(true);
            setIsLoading(false);
            setStatusMsg(statusOptions.ERROR.GENERAL);
            return false;
        }
    };

    const updateLoadingMsg = (state, waitTimeSeconds) => {
        let description =
            "This shouldn't take too much longer. Please don't navigate away from this page.";
        if (state === 'Todo' || state === 'Processing') {
            const waitTimeMinutes = Math.ceil(waitTimeSeconds / 60);
            if (waitTimeMinutes > 0) {
                description = `This should take about ${waitTimeMinutes} more ${
                    waitTimeMinutes > 1 ? 'minutes' : 'minute'
                }. Please don't navigate away from this page.`;
            }
        }

        setStatusMsg({
            ...statusOptions.LOADING,
            description,
        });
    };

    const createTask = async () => {
        try {
            const {
                id,
                state,
                wait_time: waitTimeSeconds,
            } = await api().tasks.create({
                job: 'youtube_sdf_download',
                inputs: [
                    {
                        variable: 'context_id',
                        value: contextId,
                    },
                ],
            });

            updateLoadingMsg(state, waitTimeSeconds);
            return id;
        } catch (err) {
            setHasError(true);
            setIsLoading(false);
            setStatusMsg(statusOptions.ERROR.GENERAL);
            return false;
        }
    };

    const processTaskOutput = (outputs) => {
        const sdfData = outputs.find((o) => o.variable === 'sdf_data').value;
        const partnerId = outputs.find(
            (o) => o.variable === 'partner_id',
        ).value;
        const advertiserId = outputs.find(
            (o) => o.variable === 'advertiser_id',
        ).value;
        const campaignId = outputs.find(
            (o) => o.variable === 'campaign_id',
        ).value;
        const insertionOrderId = outputs.find(
            (o) => o.variable === 'insertion_order_id',
        ).value;

        const today = new Date();
        const date = today.toISOString().split('.')[0];

        const a = document.createElement('a');
        a.href = `data:text/csv;base64,${sdfData}`;
        a.download = `4D-SDF-AdGroups-${contextId}-${date}.csv`;
        a.click();

        setRedirectUrl(
            `https://displayvideo.google.com/ng_nav/p/${partnerId}/a/${advertiserId}/c/${campaignId}/io/${insertionOrderId}/explorerlis`,
        );
    };

    const checkTaskStatus = async (taskId, statusInterval, startTime) => {
        try {
            const {
                state,
                outputs,
                wait_time: waitTimeSeconds,
            } = await api().tasks.retrieve(taskId);
            const hasTimedOut = Date.now() - startTime >= TIMEOUT_LIMIT;

            updateLoadingMsg(state, waitTimeSeconds);

            if (state === 'Done') {
                setIsLoading(false);
                setStatusMsg(statusOptions.SUCCESS);
                processTaskOutput(outputs);
                clearInterval(statusInterval);
            } else if (state === 'Error' || hasTimedOut) {
                throw new Error('There is a problem making this SDF file.');
            }
        } catch (err) {
            setIsLoading(false);
            setHasError(true);
            setStatusMsg(statusOptions.ERROR.GENERAL);
            clearInterval(statusInterval);
        }
    };

    useEffect(() => {
        let statusInterval;
        const beginTaskProcess = async () => {
            const contextIsValid = await isValidContext();

            if (contextIsValid) {
                const startTime = Date.now();
                const taskId = await createTask();
                statusInterval = setInterval(
                    () => checkTaskStatus(taskId, statusInterval, startTime),
                    20000,
                );
            }
        };

        beginTaskProcess();
        return () => clearInterval(statusInterval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={breadcrumbs} />}
        >
            <YoutubeDownload
                isLoading={isLoading}
                hasError={hasError}
                statusMsg={statusMsg}
                redirectUrl={redirectUrl}
            />
        </Layout>
    );
};

export default YoutubeSdfDownloadPage;
