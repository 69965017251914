import React from 'react';
import { Page } from '@react-pdf/renderer';
import { getNumber } from 'js/components/number/number';
import { getImpressionThreshold } from 'js/utils';
import { pageSizes } from '../constants';
import Aside from '../components/aside/aside';
import AnnotatedChart from '../components/annotated-chart/annotated-chart';
import Groups from '../components/groups/groups';
import Logos from '../components/logos/logos';
import NoDataAlert from '../components/no-data-alert/no-data-alert';
import PageContent from '../components/page-content/page-content';
import styles from '../report.styles';
import { getDisplayReportRecommendations, getCtr, getViewRate } from '../utils';

export default function KeywordThemesPage({
    orientation,
    logo4D,
    brandLogo,
    charts,
    report,
}) {
    const impressionThreshold = getImpressionThreshold(report.impressions);
    const isPortrait = orientation === 'portrait';
    const pageClasses = [
        styles.page,
        isPortrait ? styles.pagePortrait : styles.pageLandscape,
    ];

    if (logo4D || brandLogo) {
        pageClasses.push(isPortrait ? null : styles.pageLandscapeWithLogo);
    }

    const recommendations = getDisplayReportRecommendations(report).filter(
        (i) => i.title === 'Keyword',
    );

    const formatKeywords = (keywords) =>
        [...keywords].map((i) => ({
            icon: null,
            label: i.name,
            impressions: getNumber({
                value: i.impressions,
                maximumFractionDigits: 0,
            }),
            ctr: getNumber({
                value: getCtr(i),
                isPercentage: true,
                maximumFractionDigits: 2,
            }),
            viewRate:
                getViewRate(report) > 0 > 0 &&
                getNumber({
                    value: getViewRate(i),
                    isPercentage: true,
                    maximumFractionDigits: 2,
                }),
        }));

    const topKeywordsByCtr = [...report.keywords]
        .sort((a, b) => getCtr(b) - getCtr(a))
        .slice(0, 3)
        .filter((item) => item.impressions >= impressionThreshold);

    const topKeywordsByViewRate = [...report.keywords]
        .sort((a, b) => getViewRate(b) - getViewRate(a))
        .slice(0, 3)
        .filter((item) => item.impressions >= impressionThreshold);

    const showImpChart = report.keywords.some(
        (group) => group.impressions >= impressionThreshold,
    );
    const impChart = showImpChart && (
        <AnnotatedChart
            orientation={orientation}
            annotations={[{ title: 'Top Keywords by Impressions' }]}
            chart={charts.KEYWORDS}
        />
    );

    const ctrKeywords = topKeywordsByCtr.length > 0 && (
        <Groups
            orientation={orientation}
            title="Top Keywords by CTR"
            groups={formatKeywords(topKeywordsByCtr)}
            background="#1CBEC9"
            parameters={['impressions', 'ctr', 'viewRate']}
            mainParameter="ctr"
            hasBorderTop
        />
    );

    const viewRateKeywords = getViewRate(report) > 0 &&
        topKeywordsByViewRate.length > 0 && (
            <Groups
                orientation={orientation}
                title="Top Keywords by View Rate"
                groups={formatKeywords(topKeywordsByViewRate)}
                background="#4C9AFF"
                parameters={['impressions', 'ctr', 'viewRate']}
                mainParameter="viewRate"
                hasBorderTop
            />
        );

    return (
        <Page
            size={isPortrait ? pageSizes.portrait : pageSizes.landscape}
            orientation={orientation}
            style={pageClasses}
        >
            <Aside
                orientation={orientation}
                title="Keyword Themes"
                description="Keywords are the most granular building block of a context. Use these insights to understand how keywords overlap with your media, and can improve your targeting & performance."
                hasLogo={Boolean(logo4D || brandLogo)}
                recommendations={recommendations}
            />
            <PageContent orientation={orientation}>
                {impChart || ctrKeywords || viewRateKeywords ? (
                    <>
                        {impChart}
                        {ctrKeywords}
                        {viewRateKeywords}
                    </>
                ) : (
                    <NoDataAlert />
                )}
            </PageContent>
            <Logos
                orientation={orientation}
                logo4D={logo4D}
                brandLogo={brandLogo}
            />
        </Page>
    );
}
