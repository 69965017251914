import React from 'react';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import DrawerFooter from 'js/components/drawer/footer';
import Col from 'js/components/grid/column';
import Layout from 'js/components/layout/layout';
import Number from 'js/components/number/number';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import Statistic from 'js/components/statistic/statistic';
import Text from 'js/components/text/text';
import styles from './inspect-drawer.module.scss';

const MatchedItem = ({
    matchedPage: { url, domain, og_title: ogTitle, og_image: ogImage },
}) => (
    <div className={styles['matched-item']}>
        <Row>
            <Col>
                <Text size="small" color={['gray', 'dark']}>
                    {domain}
                </Text>

                <h3 className={styles['content-title']}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {ogTitle || url.split('?')[0].split('#')[0]}{' '}
                        <span className={styles['content-title-icon']}>
                            <FontAwesomeIcon icon={faExternalLink} />
                        </span>
                    </a>
                </h3>
            </Col>

            {ogImage && (
                <Col span="auto">
                    <div className={styles['content-image']}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img src={ogImage} alt={ogTitle} />
                        </a>
                    </div>
                </Col>
            )}
        </Row>
    </div>
);

export const MatchedPages = ({ pages, isLoading, hasError, onReload }) => {
    if (isLoading) {
        return (
            <Spinner
                size="large"
                message="Loading"
                color={['gray', 'dark']}
                isCentered
            />
        );
    }

    if (hasError) {
        return (
            <Alert
                theme="danger"
                title="There Was An Error Loading Data"
                actionItems={<Button onClick={onReload}>Retry</Button>}
            >
                <p>
                    Hopefully it is only a temporary issue. Please try again in
                    a few moments.
                </p>
            </Alert>
        );
    }

    if (!pages.length) {
        return <Alert theme="empty" title="There Are No Matched Pages" />;
    }

    return (
        <>
            {pages.map((item) => (
                <Box margin={[0, 0, 'base']} key={item.url}>
                    <MatchedItem matchedPage={item} />
                </Box>
            ))}
        </>
    );
};

export const InspectDrawerSummary = ({
    report,
    impressions,
    clicks,
    backgroundImg,
    header,
}) => {
    const { clicks: reportClicks, impressions: reportImpressions } = report;
    const reportCtr = reportClicks / reportImpressions;
    const ctr = clicks / impressions;
    const ratio = ctr / reportCtr - 1;

    return (
        <Section backgroundImg={backgroundImg}>
            <Container size="medium">
                <Box padding={['large', 0]}>
                    <div className={styles.summary}>
                        {header && <Box padding="base">{header}</Box>}

                        <Box
                            padding="base"
                            borderTop={['base', 'solid', 'gray', 'lightest']}
                        >
                            <Row gap="base">
                                <Statistic
                                    name="Impressions"
                                    comparison={
                                        <>
                                            <Number
                                                value={
                                                    impressions /
                                                    reportImpressions
                                                }
                                                isPercentage
                                                maximumFractionDigits={2}
                                            />{' '}
                                            Of Total
                                        </>
                                    }
                                >
                                    <Number value={impressions} />
                                </Statistic>

                                <Statistic
                                    name="Clicks"
                                    comparison={
                                        <>
                                            <Number
                                                value={clicks / report.clicks}
                                                isPercentage
                                                maximumFractionDigits={2}
                                            />{' '}
                                            Of Total
                                        </>
                                    }
                                >
                                    <Number value={clicks} />
                                </Statistic>

                                <Statistic
                                    name="CTR"
                                    comparison={
                                        <>
                                            <Text
                                                inline
                                                color={['purple', 'darker']}
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        ratio > 0
                                                            ? faArrowAltCircleUp
                                                            : faArrowAltCircleDown
                                                    }
                                                />
                                            </Text>{' '}
                                            <Number
                                                value={ratio}
                                                isPercentage
                                                maximumFractionDigits={2}
                                            />{' '}
                                            {ratio > 0 ? 'More' : 'Less'} Than
                                            Average
                                        </>
                                    }
                                >
                                    <Number
                                        value={ctr}
                                        isPercentage
                                        maximumFractionDigits={2}
                                    />
                                </Statistic>
                            </Row>
                        </Box>
                    </div>
                </Box>
            </Container>
        </Section>
    );
};

const InspectDrawer = ({ summary, content, onClose }) => (
    <Drawer onClose={onClose}>
        <Layout
            footer={
                <DrawerFooter>
                    <Container size="medium">
                        <Box padding={['small', 0]}>
                            <Row justifyContent="flex-end">
                                <Col span="auto">
                                    <Button onClick={onClose}>Done</Button>
                                </Col>
                            </Row>
                        </Box>
                    </Container>
                </DrawerFooter>
            }
            isFooterSticky
        >
            {summary}

            <Container size="medium">{content}</Container>
        </Layout>
    </Drawer>
);

export default InspectDrawer;
