import React from 'react';

/**
 *
 * Most important is the transform attribute, specifically its translate value.
 * We can build a new origin local to the group, offset
 * from the parent origin by the specified x(width) and y(height) values.
 * Therefore it is good to position a children component
 * since we can easily do so in the parent.
 * The children themselves will pretend they're placed at the origin,
 * so they only have concerned with their width and height and what to render
 * within that space.
 *
 */

const Translate = ({ x = 0, y = 0, children }) => {
    if (!x && !y) return children;
    return <g transform={`translate(${x},${y})`}>{children}</g>;
};

export default Translate;
