import React from 'react';
import { reloadPage } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CardUser from 'js/components/card/card-user';
import CardLoading from 'js/components/card/card-loading';
import CardCreateNew from 'js/components/card/card-create-new';
import Col from 'js/components/grid/column';
import Container from 'js/components/container/container';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';

const Wrapper = ({ children }) => (
    <Section>
        <Container>
            <Box padding={['base', 0, 'large']}>
                <Row>{children}</Row>
            </Box>
        </Container>
    </Section>
);

const UserListBody = ({ users, loading, hasErrors, isReadOnly }) => {
    if (loading) {
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <CardLoading loadingText="Loading Users" />
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    if (hasErrors) {
        return (
            <Wrapper>
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <Alert
                            theme="danger"
                            actionItems={
                                <Button onClick={reloadPage}>Retry</Button>
                            }
                            title="There was an error loading the users"
                        >
                            <p>
                                Hopefully it is only a temporary issue. Please
                                try again in a few moments.
                            </p>
                        </Alert>
                    </Box>
                </Col>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {users.map((user) => (
                <Col span={12} spanSm={12} spanMd={6} spanLg={4} key={user.id}>
                    <Box padding={['base', 0, 0]}>
                        <CardUser isReadOnly={isReadOnly} user={user} />
                    </Box>
                </Col>
            ))}

            {!isReadOnly && (
                <Col span={12} spanSm={12} spanMd={6} spanLg={4}>
                    <Box padding={['base', 0, 0]}>
                        <CardCreateNew
                            to="/settings/users/new/"
                            title="Create New User"
                        />
                    </Box>
                </Col>
            )}
        </Wrapper>
    );
};

export default UserListBody;
