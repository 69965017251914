import React from 'react';
import entityTypes from 'js/enums/entity-types.enum';
import Alert from 'js/components/alert/alert';
import CardContext from 'js/components/card/card-context';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';

export default function RecentContexts({
    contexts,
    onToggleFavorite,
    onDelete,
}) {
    if (!contexts.length) {
        return (
            <Alert theme="empty" padding="large">
                <p>You currently have no contexts.</p>
            </Alert>
        );
    }

    return (
        <Row gap="base">
            {contexts.map((context) => (
                <Col
                    key={context.id}
                    span={12}
                    spanSm={12}
                    spanMd={6}
                    spanLg={4}
                >
                    <CardContext
                        context={context}
                        onToggleFavorite={() =>
                            onToggleFavorite(
                                entityTypes.CONTEXTS,
                                context.id,
                                !context.is_favourite,
                            )
                        }
                        onDeleteContext={() =>
                            onDelete(entityTypes.CONTEXTS, context.id)
                        }
                    />
                </Col>
            ))}
        </Row>
    );
}
