import { fontSize, fontWeight } from '../tokens.styles';

const styles = {
    titlePortrait: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.base,
    },

    titleLandscape: {
        fontWeight: fontWeight.bold,
        fontSize: fontSize.large,
    },

    value: {
        fontWeight: fontWeight.heavy,
    },

    descriptionPortrait: {
        fontSize: fontSize.smallest,
    },

    descriptionLandscape: {
        fontSize: fontSize.small,
    },

    chart: {
        marginHorizontal: 'auto',
    },
};

export default styles;
