import React, { useContext, useState } from 'react';
import { INITIALLY_VISIBLE_KEYWORDS } from 'js/constants';
import { useLimitList } from 'js/hooks';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import Keyword from './keyword';
import RuleRelatedKeywordsDropdown from './rule-related-keywords-dropdown';
import styles from './rules-section.module.scss';
import ContextDetailContext from '../../../contexts/context-detail.context';

export default function RuleKeywords({ rule, onToggleEditorMode }) {
    const {
        context: {
            current: { rules },
            setRules,
        },
    } = useContext(ContextDetailContext);
    const { keywords } = rule;

    const [isChildDropdownOpen, setChildDropdownOpen] = useState(false);
    const [
        hasMoreKeywords,
        isKeywordsExpanded,
        visibleKeywords,
        showMoreKeywords,
        showLessKeywords,
    ] = useLimitList(keywords, INITIALLY_VISIBLE_KEYWORDS);

    const updateKeyword = (oldKeyword, newKeyword) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(rule.key, {
            addKeywords: [newKeyword],
            removeKeywords: [oldKeyword],
        });
        setRules(ruleSet.rules);
    };

    const removeKeyword = (keyword) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeRule(rule.key, {
            removeKeywords: [keyword],
        });
        setRules(ruleSet.rules);
    };

    return (
        <Box margin={['base', 0]}>
            <Text size="large">
                Keywords (
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span className={styles.link} onClick={onToggleEditorMode}>
                    Switch To Bulk Edit Mode
                </span>
                )
            </Text>

            <div className={styles.items}>
                {visibleKeywords.map((keyword) => (
                    <Box
                        key={keyword}
                        margin={['small', 'small', 0, 0]}
                        display="inline-block"
                    >
                        <Keyword
                            keyword={keyword}
                            rule={rule}
                            onSuffixClick={() => removeKeyword(keyword)}
                            prefixContent={
                                <RuleRelatedKeywordsDropdown
                                    keyword={keyword}
                                    rule={rule}
                                    onUpdateKeyword={(newKeyword) => {
                                        updateKeyword(keyword, newKeyword);
                                        setChildDropdownOpen(false);
                                    }}
                                    onSetShowSuffixMenu={setChildDropdownOpen}
                                />
                            }
                            isBlocked={isChildDropdownOpen}
                        />
                    </Box>
                ))}
            </div>

            {hasMoreKeywords && (
                <Box margin={['small', 'small', 0, 0]}>
                    <Row>
                        <Col span="auto">
                            <Button
                                theme="outline"
                                size="small"
                                onClick={showMoreKeywords}
                            >
                                Show All {keywords.length} Keywords
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}

            {isKeywordsExpanded && (
                <Box margin={['small', 'small', 0, 0]}>
                    <Row>
                        <Col span="auto">
                            <Button
                                theme="outline"
                                size="small"
                                onClick={showLessKeywords}
                            >
                                Show Fewer Keywords
                            </Button>
                        </Col>
                    </Row>
                </Box>
            )}
        </Box>
    );
}
