import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import Select from 'js/components/select/select';
import { showToast } from 'js/components/toast/toast';

function FilterDropdown({
    name,
    prefix,
    param,
    options,
    selectedValue,
    title,
    hasError,
    errorMessage,
    errorHint,
    onFilterChange,
}) {
    const [hadError, setHadError] = useState(false);
    const { label } = options.find((item) => item.value === selectedValue) || {
        label: `Unknown ${name}`,
    };

    if (hasError && !hadError) {
        showToast(errorMessage, errorHint, 10, 'danger');
        setHadError(true);
    }

    return (
        <Select
            theme="filter"
            width="fixed"
            prefix={prefix || name}
            header={title && <Box margin={[0, 0, 'small']}>{title}</Box>}
            options={options}
            selectedValues={selectedValue}
            onChange={(value) => {
                onFilterChange(param, value);
            }}
        >
            {label}
        </Select>
    );
}

FilterDropdown.defaultProps = {
    prefix: undefined,
    param: undefined,
    title: undefined,
    hasError: false,
    errorMessage: undefined,
    errorHint: undefined,
};

FilterDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    param: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            group: PropTypes.number,
        }),
    ).isRequired,
    selectedValue: PropTypes.string.isRequired,
    title: PropTypes.node,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    errorHint: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
};

export default FilterDropdown;
