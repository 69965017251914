import videoTagSides from 'js/enums/video-tag-sides.enum';

const videoTagTypes = [
    {
        id: 'commonObject',
        name: 'Common object',
        side: videoTagSides.LEFT,
        color: '#ef7685',
        order: 1,
    },
    {
        id: 'advancedObject',
        name: 'Advanced object',
        side: videoTagSides.LEFT,
        color: '#9004b3',
        order: 2,
    },
    {
        id: 'logo',
        name: 'Logo',
        side: videoTagSides.LEFT,
        color: '#ff9901',
        order: 4,
    },
    {
        id: 'scene',
        name: 'Scene',
        side: videoTagSides.LEFT,
        color: '#60c1a4',
        order: 5,
    },
    {
        id: 'nudity',
        name: 'Nudity',
        side: videoTagSides.LEFT,
        color: '#0065ff',
        order: 6,
    },
    {
        id: 'audio',
        name: 'Audio',
        color: '#ffc400',
        side: videoTagSides.BOTTOM,
        order: 1,
    },
];

export default videoTagTypes;
