import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Collapse from 'js/components/collapse/collapse';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Spinner from 'js/components/spinner/spinner';
import styles from './collapse-dropdown.module.scss';

const cx = classNames.bind(styles);

const CollapseContent = ({
    isLoading,
    loadingMessage,
    emptyMessage,
    hasError,
    errorMessage,
    onRetry,
    children,
}) => {
    if (isLoading) {
        return (
            <Box
                borderLeft={['thick', 'solid', 'gray', 'lightest']}
                margin={['smaller', 0, 0, 'base']}
                padding={[0, 0, 0, 'base']}
            >
                <Box padding={['base', 0]}>
                    <Spinner
                        size="base"
                        message={loadingMessage}
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            </Box>
        );
    }

    if (hasError) {
        return (
            <Box
                borderLeft={['thick', 'solid', 'gray', 'lightest']}
                margin={['smaller', 0, 0, 'base']}
                padding={[0, 0, 0, 'base']}
            >
                <Box padding={['base', 0]}>
                    <Alert
                        actionItems={
                            <Row gutter="smaller">
                                <Col span="auto">
                                    <Button onClick={() => onRetry()}>
                                        Retry
                                    </Button>
                                </Col>
                            </Row>
                        }
                        theme="danger"
                        title={errorMessage}
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            again in a few moments.
                        </p>
                    </Alert>
                </Box>
            </Box>
        );
    }

    if (emptyMessage) {
        return (
            <Box
                borderLeft={['thick', 'solid', 'gray', 'lightest']}
                margin={['smaller', 0, 0, 'base']}
                padding={[0, 0, 0, 'base']}
            >
                <Box padding={['base', 0]}>
                    <Alert theme="empty" title={emptyMessage} />
                </Box>
            </Box>
        );
    }

    return children;
};

function CollapseDropdown({
    label,
    prefix,
    suffix,
    icons: [defaultIcon, activeIcon],
    size,
    fluidHeight,
    isLoading,
    loadingMessage,
    emptyMessage,
    hasError,
    errorMessage,
    onRetry,
    onExpand,
    onCollapse,
    children,
    className,
}) {
    const hasContent = !!children;
    const [showCollapse, setShowCollapse] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const toggleCollapse = () => {
        setShowCollapse((prevState) => {
            if (prevState) {
                onCollapse();
            } else {
                onExpand();
            }

            return !prevState;
        });
        setButtonDisabled(true);

        setTimeout(() => {
            setButtonDisabled(false);
        }, 500);
    };

    const toggleClasses = cx({
        toggle: true,
        [`toggle--size-${size}`]: size,
        'toggle--has-content': hasContent,
        'toggle--active': showCollapse,
        'toggle--fluid-height': fluidHeight,
        'toggle--disabled': buttonDisabled,
    });

    return (
        <div className={className}>
            <button
                type="button"
                className={toggleClasses}
                onClick={hasContent ? toggleCollapse : null}
            >
                <span className={styles.inner}>
                    {prefix && <span className={styles.prefix}>{prefix}</span>}

                    <span className={styles['inner-top']}>
                        <span className={styles.content}>{label}</span>

                        {suffix && (
                            <span className={styles.suffix}>{suffix}</span>
                        )}

                        {hasContent && defaultIcon && activeIcon && (
                            <span className={styles.icon}>
                                <FontAwesomeIcon
                                    icon={
                                        showCollapse ? activeIcon : defaultIcon
                                    }
                                />
                            </span>
                        )}
                    </span>
                </span>
            </button>

            {hasContent && (
                <div className={styles.collapse}>
                    <Collapse isOpen={showCollapse}>
                        <CollapseContent
                            isLoading={isLoading}
                            loadingMessage={loadingMessage}
                            emptyMessage={emptyMessage}
                            hasError={hasError}
                            errorMessage={errorMessage}
                            onRetry={onRetry}
                        >
                            {children}
                        </CollapseContent>
                    </Collapse>
                </div>
            )}
        </div>
    );
}

CollapseDropdown.defaultProps = {
    label: undefined,
    prefix: undefined,
    suffix: undefined,
    icons: [undefined, undefined],
    size: 'base',
    fluidHeight: false,
    isLoading: false,
    emptyMessage: undefined,
    loadingMessage: 'Loading',
    hasError: false,
    errorMessage: 'There Was An Error While Loading',
    onRetry: undefined,
    onExpand: () => {},
    onCollapse: () => {},
    children: undefined,
    className: undefined,
};

CollapseDropdown.propTypes = {
    /**
     * The label of the component/field
     */
    label: PropTypes.node,
    /**
     * The prefix to show at the beginning of the collapse area
     */
    prefix: PropTypes.node,
    /**
     * The suffix to show at the end of the collapse area
     */
    suffix: PropTypes.node,
    /**
     * The list of 2 icons [default, active] to show at the end of the collapse area.
     */
    icons: PropTypes.arrayOf(PropTypes.object),
    /**
     * The size of the input and font
     */
    size: PropTypes.oneOf(['base', 'large']),
    /**
     * No idea what this is. TODO: remove this prop
     */
    fluidHeight: PropTypes.bool,
    /**
     * If true, the component is loading and it's content isn't available, yet
     */
    isLoading: PropTypes.bool,
    /**
     * The message to display to the user while isLoading is true
     */
    loadingMessage: PropTypes.node,
    /**
     * The empty state to show the user when no react children (content) exist
     */
    emptyMessage: PropTypes.node,
    /**
     * If true, the component is in an error state (typically because the content could not be loaded)
     */
    hasError: PropTypes.bool,
    /**
     * The message to display to the user while hasError is true
     */
    errorMessage: PropTypes.node,
    /**
     * A retry function to call in the event of an error
     */
    onRetry: PropTypes.func,
    /**
     * A callback executed when the dropdown is expanded/opened
     */
    onExpand: PropTypes.func,
    /**
     * A callback executed when the dropdown is collapsed/closed
     */
    onCollapse: PropTypes.func,
    /**
     * The child content to render
     * When the CollapseDropdown is collapsed, the children are hidden
     */
    children: PropTypes.node,
    /**
     * Unused passthrough to the outer div of the component
     * TODO: remove
     */
    className: PropTypes.string,
};

export default CollapseDropdown;
