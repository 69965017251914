import React, { useRef } from 'react';
import { getNumber } from 'js/components/number/number';
import Legend from 'js/components/treemap-chart/legend';
import TreeMapChart from 'js/components/treemap-chart/treemap-chart';
import TrendChart from 'js/components/trend-chart/trend-chart';

const trendChartMetrics = [
    {
        id: 'impressions',
        name: 'Impressions',
        format: 'k',
        getValue: (i) => i.impressions,
    },
    {
        id: 'clicks',
        name: 'Clicks',
        format: 'k',
        getValue: (i) => i.clicks,
    },
    {
        id: 'starts',
        name: 'Started Views',
        format: 'k',
        getValue: (i) => i.starts,
    },
    {
        id: 'completes',
        name: 'Completed Views',
        format: 'k',
        getValue: (i) => i.completes,
    },
    {
        id: 'completionRate',
        name: 'Completion Rate',
        format: '%',
        getValue: (i) => (i.impressions ? i.completes / i.impressions : 0),
    },
];

export const chartDimensionMap = {
    portrait: {
        trend: [450, 100],
        treemap: [535, 250],
        bubble: [535, 220],
    },

    landscape: {
        trend: [594, 154],
        treemap: [586, 250],
        bubble: [586, 250],
    },
};

const getTrendChartData = (report) =>
    trendChartMetrics.map((i) => ({
        ...i,
        data: report.overviews.map((record) => ({
            date: record.activity_date,
            value: i.getValue(record),
        })),
    }));

const ImpressionsChart = ({ report, orientation }) => (
    <div
        style={{
            width: chartDimensionMap[orientation].trend[0] * 2,
            height: chartDimensionMap[orientation].trend[1] * 2,
        }}
    >
        {report.overviews.some((i) => i.impressions > 0) && (
            <TrendChart
                series={getTrendChartData(report).map((i) => ({
                    ...i,
                    color: { impressions: '#6673FF', clicks: '#137F86' }[i.id],
                    axis: { impressions: 'left', clicks: 'right' }[i.id],
                }))}
            />
        )}
    </div>
);

const StartViewsChart = ({ report, orientation }) => (
    <div
        style={{
            width: chartDimensionMap[orientation].trend[0] * 2,
            height: chartDimensionMap[orientation].trend[1] * 2,
        }}
    >
        {report.overviews.some((i) => i.starts > 0) && (
            <TrendChart
                series={getTrendChartData(report).map((i) => ({
                    ...i,
                    color: { starts: '#1CBEC9' }[i.id],
                    axis: { starts: 'left' }[i.id],
                }))}
            />
        )}
    </div>
);

const CompleteViewsChart = ({ report, orientation }) => (
    <div
        style={{
            width: chartDimensionMap[orientation].trend[0] * 2,
            height: chartDimensionMap[orientation].trend[1] * 2,
        }}
    >
        {report.overviews.some((i) => i.completes > 0) && (
            <TrendChart
                series={getTrendChartData(report).map((i) => ({
                    ...i,
                    color: { completionRate: '#79E5EC', completes: '#2684FF' }[
                        i.id
                    ],
                    axis: { completionRate: 'left', completes: 'right' }[i.id],
                }))}
            />
        )}
    </div>
);

const TopicGroupsChart = ({ report, orientation }) => {
    const topGroupsByImpressions = [...report.topic_groups]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 4)
        .map((group) => ({
            label: group.topic_group_name,
            sublabel: `${getNumber({
                value: group.impressions,
                maximumFractionDigits: 0,
            })} (${getNumber({
                value: group.impressions / report.impressions,
                maximumFractionDigits: 2,
                isPercentage: true,
            })})`,
            value: group.impressions,
        }));

    const treeMapChartData = {
        children: topGroupsByImpressions.concat({
            label: 'Other',
            value:
                topGroupsByImpressions[topGroupsByImpressions.length - 1]
                    ?.value ?? 0,
        }),
    };

    return (
        <div
            style={{
                width: chartDimensionMap[orientation].treemap[0] * 2,
                height: chartDimensionMap[orientation].treemap[1] * 2,
            }}
        >
            {topGroupsByImpressions.some((i) => i.value > 0) && (
                <TreeMapChart
                    data={treeMapChartData}
                    rightLegend={
                        <Legend
                            lowLabel="Fewer Impressions"
                            highLabel="More Impressions"
                        />
                    }
                />
            )}
        </div>
    );
};

const LogoGroupsChart = ({ report, orientation }) => {
    const topGroupsByImpressions = [...report.logo_groups]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 4)
        .map((group) => ({
            label: group.logo_group_name,
            sublabel: `${getNumber({
                value: group.impressions,
                maximumFractionDigits: 0,
            })} (${getNumber({
                value: group.impressions / report.impressions,
                maximumFractionDigits: 2,
                isPercentage: true,
            })})`,
            value: group.impressions,
        }));

    const treeMapChartData = {
        children: topGroupsByImpressions.concat({
            label: 'Other',
            value:
                topGroupsByImpressions[topGroupsByImpressions.length - 1]
                    ?.value ?? 0,
        }),
    };

    return (
        <div
            style={{
                width: chartDimensionMap[orientation].treemap[0] * 2,
                height: chartDimensionMap[orientation].treemap[1] * 2,
            }}
        >
            {topGroupsByImpressions.some((i) => i.value > 0) && (
                <TreeMapChart
                    data={treeMapChartData}
                    rightLegend={
                        <Legend
                            lowLabel="Fewer Impressions"
                            highLabel="More Impressions"
                        />
                    }
                />
            )}
        </div>
    );
};

const VideoCharts = ({ report, orientation, chartRefs }) => {
    const refs = {
        IMPRESSIONS: useRef(),
        START_VIEWS: useRef(),
        COMPLETE_VIEWS: useRef(),
        TOPIC_GROUPS: useRef(),
        LOGO_GROUPS: useRef(),
    };
    Object.assign(chartRefs.current, refs);

    return (
        <>
            <div ref={refs.IMPRESSIONS}>
                <ImpressionsChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.START_VIEWS}>
                <StartViewsChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.COMPLETE_VIEWS}>
                <CompleteViewsChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.TOPIC_GROUPS}>
                <TopicGroupsChart report={report} orientation={orientation} />
            </div>
            <div ref={refs.LOGO_GROUPS}>
                <LogoGroupsChart report={report} orientation={orientation} />
            </div>
        </>
    );
};

export default VideoCharts;
