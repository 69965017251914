/**
 * returns search params from current URL
 * @returns {string}
 */
export function getSearch(history) {
    return (
        new URLSearchParams((history || window).location.search).get(
            'search',
        ) || ''
    );
}

/**
 * generates path to auto-redirect login
 * @returns {string}
 */
export function getLoginPath() {
    const redirectUri = window.location.pathname + window.location.search;
    if (redirectUri !== '/') {
        const redirectParams = new URLSearchParams({ redirectUri });
        return `/login/?${redirectParams}`;
    }
    return '/login/';
}

/**
 * generates path for not found page
 * @returns {string}
 */
export function get404Path() {
    const pathnames = window.location.pathname.split('/');
    const page = pathnames[1];
    const redirectUri =
        new URLSearchParams(window.location.search).get('redirectUri') || '';

    if (page && (page === 'login' || page === 'password-reset')) {
        if (redirectUri.startsWith('/errors/')) {
            return '/';
        }
        return redirectUri;
    }

    return '/errors/404/';
}
