import React from 'react';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import SVGImage from 'js/components/svg-image/svg-image';
import Text from 'js/components/text/text';
import { getImpressionThreshold } from 'js/utils';
import pageTabs from '../../enums/page-tabs.enum';
import styles from './insights-overview-panel.module.scss';

const abbreviationFormats = [
    {
        target: 1e6,
        symbol: 'm',
        maxFractionDigits: 2,
    },
    {
        target: 1e4,
        appliedValue: 1e3,
        symbol: 'k',
        maxFractionDigits: 0,
    },
    {
        target: 1e3,
        appliedValue: 1e3,
        symbol: 'k',
        maxFractionDigits: 2,
    },
    {
        target: 1,
        appliedValue: 1,
        symbol: '',
        maxFractionDigits: 0,
    },
];

const InsightBox = ({ metric, name, stat, helperStat, icon }) => (
    <Box margin={['small', 0, 0]}>
        <Panel>
            <Box padding="base">
                <div className={styles['insight-box-content-container']}>
                    <div className={styles['content-items-left']}>
                        <Text color={['gray', 'dark']}>{metric}</Text>
                        <div className={styles['primary-text']}>{name}</div>
                    </div>
                    <div className={styles['content-items-right']}>
                        <div className={styles['icon-and-stats']}>
                            <Text weight="bolder">{stat}</Text>
                            <Text color={['gray', 'dark']}>({helperStat})</Text>
                        </div>
                        {icon && (
                            <div className={styles['icon-and-stats']}>
                                <div className={styles['icon-container']}>
                                    <SVGImage src={icon} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Box>
        </Panel>
    </Box>
);

const InsightsCategory = ({ title, span, highlights }) => (
    <Col span={12} spanLg={span}>
        <Box padding={['base', 0]}>
            <Text size="large" spacing="large" color={['gray', 'dark']}>
                {title}
            </Text>
            {highlights.map((h) => (
                <InsightBox
                    key={h.value}
                    metric={h.metric}
                    name={h.name}
                    icon={h.logo}
                    stat={
                        <Number
                            value={h.value}
                            isPercentage={h.isPercentage}
                            abbreviationFormats={abbreviationFormats}
                            maximumFractionDigits={2}
                        />
                    }
                    helperStat={
                        <Number
                            isPercentage
                            signDisplay={h.comparedSignDisplay}
                            value={h.comparedValue}
                            maximumFractionDigits={2}
                        />
                    }
                />
            ))}
        </Box>
    </Col>
);

const InsightsPanelWrapper = ({ isBanner, children }) => {
    if (isBanner) {
        return (
            <div className={styles['panel-container']}>
                <Container>{children}</Container>
            </div>
        );
    }
    return (
        <Box margin={['large', 0]}>
            <Container>
                <Panel theme="secondary">
                    <Box padding="base">{children}</Box>
                </Panel>
            </Container>
        </Box>
    );
};

const InsightsOverviewPanel = ({ isBanner, report, onChangeTab }) => {
    const { topic_groups: topicGroups, keywords } = report;
    const impsThreshold = getImpressionThreshold(report.impressions);

    const topTopicByImps =
        topicGroups
            .sort((a, b) => b.impressions - a.impressions)
            .slice(0, 3)
            .filter((t) => t.impressions >= impsThreshold)[0] || null;
    const topTopicByCtr =
        topicGroups
            .sort((a, b) => b.clicks / b.impressions - a.clicks / a.impressions)
            .slice(0, 3)
            .filter((t) => t.impressions >= impsThreshold)[0] || null;

    const topTopicByViewRate =
        topicGroups
            .sort(
                (a, b) =>
                    b.in_views / b.impressions - a.in_views / a.impressions,
            )
            .slice(0, 3)
            .filter((t) => t.impressions >= impsThreshold)[0] || null;

    const topKeywordByImps =
        keywords
            .sort((a, b) => b.impressions - a.impressions)
            .slice(0, 3)
            .filter((t) => t.impressions >= impsThreshold)[0] || null;
    const topKeywordByCtr =
        keywords
            .sort((a, b) => b.clicks / b.impressions - a.clicks / a.impressions)
            .slice(0, 3)
            .filter((t) => t.impressions >= impsThreshold)[0] || null;

    const topKeywordByViewRate =
        keywords
            .sort(
                (a, b) =>
                    b.in_views / b.impressions - a.in_views / a.impressions,
            )
            .slice(0, 3)
            .filter((t) => t.impressions >= impsThreshold)[0] || null;

    const hasTopicHighlights =
        !!topTopicByImps || !!topTopicByCtr || !!topTopicByViewRate;

    const hasKeywordHighlights =
        !!topKeywordByImps || !!topKeywordByCtr || !!topKeywordByViewRate;

    const numColumns = [hasTopicHighlights, hasKeywordHighlights].reduce(
        (acc, current) => acc + current,
        0,
    );

    const comparedValues = {
        impressions: report.impressions,
        clicks: report.clicks,
        ctr: report.clicks / report.impressions,
        viewRate: report.in_views / report.impressions,
    };

    const calcPercentageDelta = (value, comparedValue) => {
        const ratio = value / comparedValue - 1;
        return ratio;
    };

    const constructHighlights = (
        topImps,
        topCtr,
        topViewRate,
        nameKey,
        logoKey = null,
    ) => {
        const highlights = [];
        if (topImps) {
            highlights.push({
                metric: 'Impressions',
                name: topImps[nameKey],
                logo: topImps[logoKey],
                value: topImps.impressions,
                isPercentage: false,
                comparedValue: calcPercentageDelta(
                    topImps.impressions,
                    comparedValues.impressions,
                ),
                comparedSignDisplay: 'never',
            });
        }

        if (topCtr) {
            highlights.push({
                metric: 'CTR',
                name: topCtr[nameKey],
                logo: topCtr[logoKey],
                value: topCtr.clicks / topCtr.impressions,
                isPercentage: true,
                comparedValue: calcPercentageDelta(
                    topCtr.clicks / topCtr.impressions,
                    comparedValues.ctr,
                ),
                comparedSignDisplay: 'always',
            });
        }

        if (topViewRate) {
            highlights.push({
                metric: 'View Rate',
                name: topViewRate[nameKey],
                logo: topViewRate[logoKey],
                value: topViewRate.in_views / topViewRate.impressions,
                isPercentage: true,
                comparedValue: calcPercentageDelta(
                    topViewRate.in_views / topViewRate.impressions,
                    comparedValues.viewRate,
                ),
                comparedSignDisplay: 'always',
            });
        }

        return highlights;
    };

    if (!hasTopicHighlights && !hasKeywordHighlights) return null;

    return (
        <InsightsPanelWrapper isBanner={isBanner}>
            <Text size="larger" weight="bold">
                Insights
            </Text>
            <Row gutter="large">
                {hasTopicHighlights && (
                    <InsightsCategory
                        span={12 / numColumns}
                        title="Top Topic Themes"
                        highlights={constructHighlights(
                            topTopicByImps,
                            topTopicByCtr,
                            topTopicByViewRate,
                            'topic_group_name',
                            'topic_group_logo',
                        )}
                    />
                )}
                {hasKeywordHighlights && (
                    <InsightsCategory
                        span={12 / numColumns}
                        title="Top Keywords"
                        highlights={constructHighlights(
                            topKeywordByImps,
                            topKeywordByCtr,
                            topKeywordByViewRate,
                            'name',
                        )}
                    />
                )}
            </Row>
            <Row>
                <Col span="auto">
                    <Box>
                        <Button onClick={() => onChangeTab(pageTabs.INSIGHTS)}>
                            View Insights
                        </Button>
                    </Box>
                </Col>
            </Row>
        </InsightsPanelWrapper>
    );
};

export default InsightsOverviewPanel;
