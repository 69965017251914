import React, { Fragment, useState } from 'react';
import columnLabels from 'js/enums/column-labels.enum';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Container from 'js/components/container/container';
import InfoButton from 'js/components/button/info-button';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import TopicButton from 'js/components/button/topic-button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import RuleSelector from 'js/components/dropdown-menu/rule-selector';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import { useLimitList } from 'js/hooks';
import Button from 'js/components/button/button';
import styles from './channels-panel.module.scss';

const numberFormats = [
    { target: 1e6, symbol: 'm', maxFractionDigits: 1 },
    { target: 1e3, appliedValue: 1e3, symbol: 'k', maxFractionDigits: 2 },
    { target: 1, appliedValue: 1, symbol: '', maxFractionDigits: 0 },
];

const textToParagraphs = (text) =>
    text.split(/\n\n+/).map((paragraph, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={i}>
            {paragraph.split(/\n/).map((sentence, j) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={j}>
                    {sentence}
                    <br />
                </Fragment>
            ))}
        </p>
    ));

const ActionableTopicButton = ({
    topic,
    topicGroups,
    isReadOnly,
    rules,
    onChangeContextRules,
}) => {
    const [showMenu, setShowMenu] = useState(false);

    const isTargeted = rules.some(
        (rule) =>
            rule.aggregation === ruleTypes.INCLUDED &&
            rule.topics.includes(topic.topic),
    );
    const isBlocked = rules.some(
        (rule) =>
            rule.aggregation === ruleTypes.EXCLUDED &&
            rule.topics.includes(topic.topic),
    );

    const removeTopic = () => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeAllRules({ removeTopics: [topic.topic] });
        onChangeContextRules(ruleSet.rules);
    };

    const handleChangeRules = (newRules) => {
        onChangeContextRules(newRules);
        setShowMenu(false);
    };

    const theme =
        (isTargeted && 'targeted') || (isBlocked && 'blocked') || 'default';

    const actionIcon = theme === 'default' ? 'add' : 'remove';
    return (
        <div>
            <DropdownMenu
                content={
                    <RuleSelector
                        ruleItemId={topic.topic}
                        topicGroups={topicGroups}
                        rules={rules}
                        onChangeRules={handleChangeRules}
                    />
                }
                showMenu={showMenu}
                onHide={() => setShowMenu(false)}
            >
                <TopicButton
                    theme={
                        (isTargeted && 'targeted') ||
                        (isBlocked && 'blocked') ||
                        'default'
                    }
                    action={actionIcon}
                    logo={topic.group_logo}
                    active={showMenu}
                    isReadOnly={isReadOnly}
                    onClick={
                        isTargeted || isBlocked
                            ? removeTopic
                            : () => setShowMenu(!showMenu)
                    }
                >
                    {topic.name}
                </TopicButton>
            </DropdownMenu>
        </div>
    );
};

const Channel = ({
    channel,
    metrics,
    rules,
    topicGroups,
    onChangeContextRules,
}) => {
    const {
        channel_id: channelId,
        title,
        description,
        thumbnail,
        follower_count: followerCount,
        topics,
    } = channel;

    const [
        hasMoreTopics,
        isTopicsExpanded,
        visibleTopics,
        showMoreTopics,
        showLessTopics,
    ] = useLimitList(topics, 5);

    const url = `https:///www.youtube.com/channel/${channelId}`;

    return (
        <Panel bordered>
            <Box padding="base">
                <div className={styles.channel}>
                    {thumbnail && (
                        <img
                            src={thumbnail}
                            className={styles.thumbnail}
                            alt=""
                        />
                    )}
                    <div>
                        <h3 className={styles.title}>
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {title}
                            </a>
                            <div className={styles.info}>
                                <InfoButton>
                                    {textToParagraphs(description)}
                                </InfoButton>
                            </div>
                        </h3>
                        <div className={styles.subscribers}>
                            <Number
                                abbreviationFormats={numberFormats}
                                value={followerCount}
                            />{' '}
                            Subscribers
                        </div>
                    </div>
                </div>

                {metrics.map(({ name, getValue, isPercentage }) => (
                    <div className={styles.metric} key={name}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.value}>
                            {isPercentage ? (
                                <Number
                                    value={getValue(channel)}
                                    maximumFractionDigits={2}
                                    isPercentage
                                />
                            ) : (
                                <Number
                                    value={getValue(channel)}
                                    abbreviationFormats={numberFormats}
                                />
                            )}
                        </div>
                    </div>
                ))}

                {topics.length > 0 && (
                    <>
                        <div className={styles['topics-title']}>
                            Matching Topics
                        </div>
                        <Row gutter="small" gap="small">
                            {visibleTopics.map((item) => (
                                <Col span="auto" key={item.topic}>
                                    <ActionableTopicButton
                                        topic={item}
                                        topicGroups={topicGroups}
                                        rules={rules}
                                        onChangeContextRules={
                                            onChangeContextRules
                                        }
                                    />
                                </Col>
                            ))}
                        </Row>

                        {hasMoreTopics && (
                            <Box margin={['small', 'small', 0, 0]}>
                                <Row>
                                    <Col span="auto">
                                        <Button
                                            theme="outline"
                                            size="small"
                                            onClick={showMoreTopics}
                                        >
                                            Show All {topics.length} Topics
                                        </Button>
                                    </Col>
                                </Row>
                            </Box>
                        )}

                        {isTopicsExpanded && (
                            <Box margin={['small', 'small', 0, 0]}>
                                <Row>
                                    <Col span="auto">
                                        <Button
                                            theme="outline"
                                            size="small"
                                            onClick={showLessTopics}
                                        >
                                            Show Fewer Topics
                                        </Button>
                                    </Col>
                                </Row>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Panel>
    );
};

const ChannelsPanel = ({
    report,
    rules,
    topicGroups,
    isDV360Report,
    onChangeContextRules,
}) => {
    const channels =
        report.channel_highlights?.filter((item) => item.title) || [];

    const topChannelByImpressions = channels.sort((prev, curr) =>
        curr.impressions > prev.impressions ? 1 : -1,
    )[0];
    const topChannelByCompletes = channels.sort((prev, curr) =>
        curr.completes > prev.completes ? 1 : -1,
    )[0];
    const topChannelByCompletionRate = channels.sort((prev, curr) =>
        curr.completes / curr.impressions > prev.completes / prev.impressions
            ? 1
            : -1,
    )[0];
    const kpis = [
        {
            name: columnLabels.IMPRESSIONS,
            getValue: (item) => item.impressions,
            isPercentage: false,
            topChannel: topChannelByImpressions,
        },
        {
            name: isDV360Report
                ? columnLabels.COMPLETES.DV360
                : columnLabels.COMPLETES.DEFAULT,
            getValue: (item) => item.completes,
            isPercentage: false,
            topChannel: topChannelByCompletes,
        },
        {
            name: isDV360Report
                ? columnLabels.COMPLETION_RATE.DV360
                : columnLabels.COMPLETION_RATE.DEFAULT,
            getValue: (item) =>
                item.completes && item.impressions
                    ? item.completes / item.impressions
                    : 0,
            isPercentage: true,
            topChannel: topChannelByCompletionRate,
        },
    ];

    return (
        <Container>
            {channels.length > 0 ? (
                <Box
                    background={['gray', 'background']}
                    border={['base', 'solid', 'gray', 'background']}
                    borderRadius="round"
                    padding="base"
                >
                    <Text size="larger" weight="bold">
                        Highlights
                    </Text>
                    <Row gap="base">
                        {kpis.map(({ name: topName, topChannel }) => (
                            <Col span={12} spanLg={6} spanXl={4} key={topName}>
                                <Box margin={['base', 0]}>
                                    <Text size="large" color={['gray', 'dark']}>
                                        Top By {topName}
                                    </Text>
                                </Box>
                                <Channel
                                    channel={topChannel}
                                    metrics={kpis}
                                    rules={rules}
                                    topicGroups={topicGroups}
                                    onChangeContextRules={onChangeContextRules}
                                />
                            </Col>
                        ))}
                    </Row>
                </Box>
            ) : (
                <Alert theme="empty" title="No Highlights Available" />
            )}
        </Container>
    );
};

export default ChannelsPanel;
