import React from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Form from 'js/components/form/form';
import Panel from 'js/components/panel/panel';
import Row from 'js/components/grid/row';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import DatesEditingStep from './dates-editing-step';
import NameStep from './name-step';

const EditingForm = ({ template, errors, onChange, onSave }) => {
    const changeName = (name) =>
        onChange((prevState) => ({ ...prevState, name }));

    const changeSchedule = (newSchedule) => {
        onChange((prevState) => ({
            ...prevState,
            schedule: newSchedule,
            start_date: null,
            end_date: null,
        }));
    };

    const hasScheduling =
        template.type !== entityTypes.EVENT_LOG_REPORTS &&
        template.subtype !== videoReportSubtypes.YOUTUBE_GADS_FILE;

    const hasFieldError = !!errors.name;
    const hasOtherError = Object.keys(errors).length > 0 && !hasFieldError;

    return (
        <Panel bordered>
            <Box margin="base">
                <Form>
                    <NameStep
                        template={template}
                        errors={errors}
                        onChangeName={changeName}
                    />

                    {hasScheduling && (
                        <Box margin={['base', 0, 0]}>
                            <DatesEditingStep
                                schedule={template.schedule}
                                errors={errors}
                                onChangeSchedule={changeSchedule}
                            />
                        </Box>
                    )}

                    {hasFieldError && (
                        <Box margin={['large', 0]}>
                            <Alert theme="danger">
                                <p>
                                    You can’t save because there are issues that
                                    have been highlighted in the form above.
                                </p>
                            </Alert>
                        </Box>
                    )}

                    {hasOtherError && (
                        <Box margin={['large', 0]}>
                            <Alert theme="danger">
                                <p>
                                    You were unable to save because there was an
                                    unexpected error. Please try again in a few
                                    moments.
                                </p>
                            </Alert>
                        </Box>
                    )}

                    <Box margin={['large', 0, 0]}>
                        <Row justifyContent="flex-end">
                            <Col span="auto">
                                <Button
                                    onClick={onSave}
                                    disabled={hasFieldError}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </Box>
        </Panel>
    );
};

export default EditingForm;
