import React from 'react';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import Panel from 'js/components/panel/panel';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import SupportEmailLink from 'js/components/support-email-link/support-email-link';

const ProcessingPanel = () => (
    <Box margin={['large', 0]}>
        <Container>
            <Panel theme="tertiary">
                <Box padding="base">
                    <Text
                        size="huge"
                        color={['purple', 'darker']}
                        lineHeight="large"
                    >
                        <Spinner
                            message={
                                <Text size="larger">
                                    This Insights Report Is Being Processed
                                </Text>
                            }
                            placement="right"
                        />
                    </Text>
                    <Box padding={['small', 0, 0]}>
                        <Text color={['purple', 'darker']} width="readable">
                            This Insights Report is Being Processed: Our team is
                            hard at work. Please check back for your insights
                            report within 24 hours. Questions or concerns please
                            contact: <SupportEmailLink />
                        </Text>
                    </Box>
                </Box>
            </Panel>
        </Container>
    </Box>
);

export default ProcessingPanel;
