/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { NotificationsContext } from 'js/contexts';
import { AbortError, api, getDisplayName, getJwtPayload } from 'js/utils';
import { showToast } from 'js/components/toast/toast';
import serviceTypes from 'js/enums/service-types.enum';

const ContextProvider = ({ children }) => {
    const [unseenNotifications, setUnseenNotifications] = useState([]);

    const refreshNotifications = async () => {
        const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
        const insightsService = services.find(
            (service) => service.name === serviceTypes.INSIGHTS,
        );
        // TODO: loading and error states?

        try {
            const { results } = await api({ scope: 'app' }).notifications.list({
                ordering: '-created',
                is_seen: false,
            });
            const filteredNotifications = results.filter(
                (notification) =>
                    notification.category !== 'Insights' || insightsService,
            );
            setUnseenNotifications((prevResults) => {
                // prevent unnecessary re-renders and onNotificationsChange() calls
                const hasChanged =
                    JSON.stringify(prevResults) !==
                    JSON.stringify(filteredNotifications);
                return hasChanged ? filteredNotifications : prevResults;
            });
        } catch (errors) {
            // ignore for now
        }
    };

    const seeNotification = useCallback(
        async (notification, upto = false) => {
            const { id, is_seen: isSeen, created } = notification;
            if (!upto && isSeen) return;

            try {
                await api({ scope: 'app' })
                    .notifications.action(id, 'mark-as-seen')
                    .call({ upto }, { method: 'PATCH' });

                setUnseenNotifications(
                    unseenNotifications.filter((item) =>
                        upto ? item.created > created : item.id !== id,
                    ),
                );
            } catch (err) {
                if (err instanceof AbortError) return;
                showToast(
                    'There Was An Unexpected Error',
                    <p>
                        It was not possible to mark some notification as seen.
                        Please try again in a few moments.
                    </p>,
                    10,
                    'danger',
                );
            }
        },
        [unseenNotifications],
    );

    const providerValue = useMemo(
        () => ({
            unseenNotifications,
            seeNotification: (notification) =>
                seeNotification(notification, false),
            seeAllNotifications: () => {
                const uptoNotification = unseenNotifications[0];
                if (uptoNotification) seeNotification(uptoNotification, true);
            },
            refreshNotifications,
        }),
        [seeNotification, unseenNotifications],
    );

    useEffect(() => {
        refreshNotifications();
        const interval = setInterval(refreshNotifications, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <NotificationsContext.Provider value={providerValue}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default function withNotifications(Wrapped) {
    function WithNotifications(props) {
        return (
            <ContextProvider>
                <Wrapped {...props} />
            </ContextProvider>
        );
    }

    WithNotifications.displayName = `withNotifications(${getDisplayName(
        Wrapped,
    )})`;

    return WithNotifications;
}
