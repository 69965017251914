import {
    color,
    fontSize,
    fontWeight,
    spacing,
} from './components/tokens.styles';

const styles = {
    page: {
        position: 'relative',
        display: 'flex',
        fontWeight: fontWeight.regular,
        lineHeight: 1.35,
        fontFamily: 'SF Pro Display',
        color: color.gray.darkest,
    },

    pagePortrait: {
        flexDirection: 'column',
    },

    pageLandscape: {
        flexDirection: 'row',
        paddingTop: spacing.large,
    },

    pageLandscapeWithLogo: {
        paddingTop: spacing.large + 35 + spacing.base, // <Aside> padding + logo height + extra padding
    },

    content: {
        fontSize: fontSize.largest,
    },

    radialChartIconContainer: {
        position: 'relative',
    },

    radialChartIconPosition: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translateY(-50%) translateX(-50%)',
    },
};

export default styles;
