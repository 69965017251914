import React from 'react';
import PropTypes from 'prop-types';
import { getFontSize, getTextDimensions, truncateText } from './helpers';

function Label({
    label,
    sublabel,
    hasChildren,
    containerWidth,
    containerHeight,
    style,
}) {
    const fontSize = getFontSize(containerWidth, containerHeight);
    style.fontSize = fontSize; // eslint-disable-line no-param-reassign
    const truncatedLabel = truncateText(label, style, containerWidth);
    const { width, height } = getTextDimensions(truncatedLabel, style);

    if (containerHeight < height) {
        return null;
    }

    const maxTextRows = Math.floor(containerHeight / height);
    const splitLabel =
        width >= containerWidth || !hasChildren
            ? label
                  .split(/(?=[A-Z/a-z0-9.][^A-Z/a-z0-9. -][¿])/g)
                  .slice(0, maxTextRows)
            : [label];

    let sublabelFontSize;
    let splitSublabel;
    let sublabelWidth;
    let sublabelHeight;

    if (sublabel) {
        sublabelFontSize = fontSize * 0.75;
        const sublabelTextDimensions = getTextDimensions(sublabel, {
            ...style,
            fontWeight: 400,
            fontSize: sublabelFontSize,
        });
        sublabelWidth = sublabelTextDimensions.width;
        sublabelHeight = sublabelTextDimensions.height;

        splitSublabel = sublabelWidth >= containerWidth ? [] : [sublabel];
    }

    return (
        <>
            {splitLabel.map((item, index) => (
                <tspan
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    fontWeight={style.fontWeight}
                    fontSize={fontSize}
                    fill="#fff"
                    x={0}
                    y={0}
                    dx={containerWidth / 2 - width / 2}
                    dy={
                        containerHeight / 2 -
                        height / 2 +
                        fontSize * (sublabel ? 0.4 : 1)
                    }
                >
                    {truncateText(item, style, containerWidth)}
                </tspan>
            ))}

            {sublabel &&
                splitSublabel.map((item, index) => (
                    <tspan
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        fontWeight="400"
                        fontSize={sublabelFontSize}
                        fill="#fff"
                        x={0}
                        y={0}
                        dx={containerWidth / 2 - sublabelWidth / 2}
                        dy={
                            containerHeight / 2 -
                            sublabelHeight / 2 +
                            sublabelFontSize * 1.6
                        }
                    >
                        {item}
                    </tspan>
                ))}
        </>
    );
}

Label.defaultProps = {
    sublabel: undefined,
    hasChildren: false,
};

Label.propTypes = {
    label: PropTypes.string.isRequired,
    sublabel: PropTypes.string,
    hasChildren: PropTypes.bool,
    containerWidth: PropTypes.number.isRequired,
    containerHeight: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
};

export default Label;
