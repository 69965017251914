import React, { useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import {
    faTimes,
    faPlus,
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RuleSet } from 'js/utils';
import ruleTypes from 'js/enums/rule-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Sparkline from 'js/components/sparkline/sparkline';
import InfoButton from 'js/components/button/info-button';
import ClickArea from 'js/components/click-area/click-area';
import Collapse from 'js/components/collapse/collapse';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Number from 'js/components/number/number';
import Panel from 'js/components/panel/panel';
import Pill from 'js/components/pill/pill';
import Text from 'js/components/text/text';
import styles from './recommendations.module.scss';

const cx = classNames.bind(styles);

const RecommendationKeywordsPanel = ({
    isReadOnly,
    isPositive,
    keywords,
    hasAppliedKeywords,
    onHandleSuffixClick,
}) => {
    const [isExpanded, setExpanded] = useState(false);
    const keywordsCount = keywords.length;
    const targetedCount = keywords.filter(({ applied }) => {
        const removeKeywords = hasAppliedKeywords ? !applied : applied;
        return isPositive ? applied : removeKeywords;
    }).length;

    return (
        <Panel theme={isExpanded ? 'primary' : 'secondary'} bordered>
            <Box padding="base">
                <ClickArea onClick={() => setExpanded(!isExpanded)}>
                    <Row alignItems="center">
                        <Col>
                            <Text weight="bold">
                                {`${targetedCount}/${keywordsCount}`} Keywords{' '}
                                {isPositive ? 'targeted' : 'untargeted'}
                            </Text>
                        </Col>

                        <Col span="auto">
                            <Row gutter="small" alignItems="center">
                                <Col span="auto">
                                    <Text size="small" color={['gray', 'dark']}>
                                        {isExpanded ? 'Hide' : 'View'} Keywords
                                    </Text>
                                </Col>

                                <Col span="auto">
                                    <Text color={['gray', 'light']}>
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={
                                                isExpanded
                                                    ? faChevronUp
                                                    : faChevronDown
                                            }
                                        />
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Box padding={['smaller', 'none', 'none']}>
                        <Sparkline
                            noLabel
                            value={targetedCount}
                            comparedValue={keywordsCount}
                            maxValue={keywordsCount}
                            color={['blue', 'light']}
                        />
                    </Box>
                </ClickArea>

                <Collapse isOpen={isExpanded}>
                    <Box padding={['base', 0, 0]}>
                        {keywords.map(({ keyword, applied }) => {
                            const addedTheme = applied ? 'blue-light' : 'muted';
                            const removedTheme = applied
                                ? 'muted'
                                : 'blue-light';
                            const appliedTheme = isPositive
                                ? addedTheme
                                : removedTheme;
                            const pillTheme = hasAppliedKeywords
                                ? appliedTheme
                                : 'muted-light';
                            const appliedOutline = !isPositive
                                ? true
                                : !applied;
                            const outline = hasAppliedKeywords
                                ? appliedOutline
                                : !isPositive;
                            return (
                                <Box
                                    key={keyword}
                                    margin={[0, 'small', 'smaller', 0]}
                                    display="inline-block"
                                >
                                    <Pill
                                        theme={pillTheme}
                                        outline={outline}
                                        onSuffixClick={() =>
                                            onHandleSuffixClick(
                                                keyword,
                                                applied,
                                            )
                                        }
                                        suffixButton={
                                            hasAppliedKeywords &&
                                            !isReadOnly ? (
                                                <FontAwesomeIcon
                                                    icon={
                                                        applied
                                                            ? faTimes
                                                            : faPlus
                                                    }
                                                />
                                            ) : null
                                        }
                                    >
                                        {keyword}
                                    </Pill>
                                </Box>
                            );
                        })}
                    </Box>
                </Collapse>
            </Box>
        </Panel>
    );
};

function KeywordRecommendation({
    isReadOnly,
    recommendation,
    rules,
    onToggleRecommendation,
    onChangeRules,
}) {
    const isPositive = recommendation.action === 'add';

    const targetMetricLabel = recommendation.target_metric.toUpperCase();

    const targetedKeywords = rules
        .filter((item) => item.aggregation === ruleTypes.INCLUDED)
        .map((item) => item.keywords)
        .flat();

    const keywords = recommendation.entities.map(({ name }) => name);

    const appliedKeywords = keywords.map((keyword) => {
        if (targetedKeywords.includes(keyword))
            return { keyword, applied: true };

        return { keyword, applied: false };
    });

    const hasAppliedKeywords =
        keywords.filter((keyword) =>
            isPositive
                ? targetedKeywords.includes(keyword)
                : !targetedKeywords.includes(keyword),
        ).length > 0;

    const visibleKeywords = hasAppliedKeywords
        ? appliedKeywords
        : keywords.map((keyword) => ({ keyword, applied: false }));

    const targetMetricRatio =
        recommendation.target_metric_value /
            recommendation.target_metric_baseline -
        1;

    const recommendationClasses = cx({
        recommendation: true,
        active: hasAppliedKeywords,
    });

    const addKeyword = (keyword) => {
        const ruleSet = new RuleSet(rules);
        const rule = rules.find(
            (ruleObject) => ruleObject.aggregation === ruleTypes.INCLUDED,
        );
        ruleSet.changeRule(rule.key, {
            addKeywords: [keyword],
        });
        onChangeRules(ruleSet.rules);
    };

    const removeKeyword = (keyword) => {
        const ruleSet = new RuleSet(rules);
        const rule = rules.find(
            (ruleObject) => ruleObject.aggregation === ruleTypes.INCLUDED,
        );
        ruleSet.changeRule(rule.key, { removeKeywords: [keyword] });
        onChangeRules(ruleSet.rules);
    };

    const handleSuffixClick = (keyword, applied) => {
        if (applied) removeKeyword(keyword);
        else {
            addKeyword(keyword);
        }
    };

    return (
        <div className={recommendationClasses}>
            <Panel bordered>
                <Box padding="base">
                    <Row alignItems="center">
                        <Col>
                            <Row gutter="small" alignItems="center">
                                <Col span="auto">
                                    <div className={styles['date-badge']}>
                                        {moment(recommendation.created).format(
                                            'D MMM YY',
                                        )}
                                    </div>
                                </Col>

                                <Col>
                                    {!hasAppliedKeywords && (
                                        <Text
                                            weight="bolder"
                                            size="large"
                                            inline
                                        >
                                            Optimization removed –{' '}
                                        </Text>
                                    )}
                                    <Text weight="bold" size="large" inline>
                                        {visibleKeywords.length}{' '}
                                        {visibleKeywords.length > 1
                                            ? 'Keywords'
                                            : 'Keyword'}{' '}
                                        {isPositive ? 'added' : 'removed'}
                                    </Text>{' '}
                                    <Box display="inline-block">
                                        <InfoButton>
                                            <Text weight="bolder">
                                                Improvement
                                            </Text>

                                            <Box margin={['small', 0, 0]}>
                                                <Text>
                                                    Pages featuring these
                                                    keywords had a{' '}
                                                    {targetMetricLabel} of{' '}
                                                    <Number
                                                        value={
                                                            recommendation.target_metric_value
                                                        }
                                                        isPercentage
                                                        maximumFractionDigits={
                                                            2
                                                        }
                                                    />
                                                    {', '}
                                                    <Number
                                                        value={
                                                            targetMetricRatio
                                                        }
                                                        isPercentage
                                                        maximumFractionDigits={
                                                            2
                                                        }
                                                        signDisplay="never"
                                                    />{' '}
                                                    {targetMetricRatio > 0
                                                        ? 'greater'
                                                        : 'less'}{' '}
                                                    than the report average.
                                                </Text>
                                            </Box>
                                        </InfoButton>
                                    </Box>
                                </Col>
                            </Row>
                        </Col>
                        {!isReadOnly && (
                            <Col span="auto">
                                <Button
                                    theme="outline"
                                    size="small"
                                    onClick={() => {
                                        onToggleRecommendation({
                                            attribute: 'keywords',
                                            hasAppliedEntities:
                                                hasAppliedKeywords,
                                            isPositive,
                                            entities: keywords,
                                        });
                                    }}
                                >
                                    {hasAppliedKeywords ? 'Undo' : 'Reapply'}
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <Box margin={['base', 0, 0]}>
                        <RecommendationKeywordsPanel
                            isReadOnly={isReadOnly}
                            keywords={visibleKeywords}
                            hasAppliedKeywords={hasAppliedKeywords}
                            isPositive={isPositive}
                            onHandleSuffixClick={handleSuffixClick}
                        />
                    </Box>
                </Box>
            </Panel>
        </div>
    );
}

export default KeywordRecommendation;
