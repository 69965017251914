import React, { useRef } from 'react';
import FileButton, {
    validateMimeTypes,
} from 'js/components/button/file-button';

const UploadTemplateButton = ({ onToggleLoading, onError, onSetData }) => {
    const fileInputRef = useRef();

    const errorStatusHandler = (msg) => {
        const errorDict = {
            msg,
            theme: 'danger',
        };

        onSetData();
        onError(errorDict);
    };

    const dataLoadHandler = (data) => {
        onSetData(data);
        onError({});
        onToggleLoading(false);
    };

    return (
        <FileButton
            ref={fileInputRef}
            theme="outline"
            onLoadStart={() => onToggleLoading(true)}
            onLoadEnd={(data) => dataLoadHandler(data)}
            onError={errorStatusHandler}
            sanitizers={[
                validateMimeTypes([
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]),
            ]}
        >
            Upload Template
        </FileButton>
    );
};

export default UploadTemplateButton;
