import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api, setDocumentTitle } from 'js/utils';
import { showToast } from 'js/components/toast/toast';
import UserSettingsNotifications from './user-settings-notifications';

export default function UserSettingsNotificationsPage() {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const [hasLoadingError, setLoadingError] = useState(false);
    const [userSettings, setUserSettings] = useState({});

    const loadUserSettings = useCallback(async () => {
        setLoading(true);
        setLoadingError(false);

        try {
            const { results: userSettingsData } =
                await api().userSettings.list();
            setUserSettings(userSettingsData[0]);
        } catch (err) {
            setLoadingError(true);
        }

        setLoading(false);
    }, []);

    const saveUserSettings = async (newUserSettings) => {
        setSaving(true);

        try {
            const updatedUserSettings = await api().userSettings.update(
                userSettings.id,
                newUserSettings,
            );
            setUserSettings(updatedUserSettings);
            history.push('/settings/your-profile/');
        } catch (err) {
            showToast(
                'There Was An Unexpected Error',
                <p>
                    It was not possible to save the settings. Please try again
                    in a few moments.
                </p>,
                10,
                'danger',
            );
        }

        setSaving(false);
    };

    useLayoutEffect(() => {
        setDocumentTitle(['Details', 'Your Profile', 'Settings']);
        loadUserSettings();

        return () => api().abortAll();
    }, [loadUserSettings]);

    return (
        <UserSettingsNotifications
            userSettings={userSettings}
            isLoading={isLoading}
            isSaving={isSaving}
            hasLoadingError={hasLoadingError}
            onSave={saveUserSettings}
        />
    );
}
