/* eslint-disable no-param-reassign */
import React from 'react';
import moment from 'moment/moment';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import StatusPanel, { statusMap } from './components/status-panel';

const getIntegrationStatuses = (data) => {
    const yesterdayData = data.filter((i) =>
        moment(i.activity_date).isSame(moment().subtract(1, 'days'), 'day'),
    );

    const usData = yesterdayData.find((i) => i.region === 'us-east-1');
    const euData = yesterdayData.find((i) => i.region === 'eu-central-1');

    const getRegionalStatus = (regionalData, title) => {
        if (!regionalData) {
            return {
                title,
                isOk: false,
                description: 'Not enough data.',
            };
        }

        if (regionalData.is_anomaly) {
            return {
                title,
                isOk: false,
                description: 'Not working correctly.',
            };
        }

        return {
            title,
            isOk: true,
            description: 'Working correctly.',
        };
    };

    return [
        getRegionalStatus(usData, 'Status - US'),
        getRegionalStatus(euData, 'Status - EU'),
    ];
};

const getEndpointStatuses = (data) => {
    const currentData = data[0];

    if (currentData.is_anomaly) {
        return [
            {
                title: 'Status',
                isOk: false,
                description: 'Not Passing Health Checks',
            },
        ];
    }

    return [
        {
            title: 'Status',
            isOk: true,
            description: 'Passing Health Checks',
        },
    ];
};

const getInternalStatuses = (data, id) => {
    const yesterdayData = data.filter((i) =>
        moment(i.activity_date).isSame(moment().subtract(1, 'days'), 'day'),
    );

    const [statusOk, statusFail] = statusMap[id].statuses;

    if (
        !yesterdayData.length ||
        yesterdayData.some((item) => item.is_anomaly)
    ) {
        return [
            {
                title: 'Status',
                isOk: false,
                description: statusFail,
            },
        ];
    }

    return [
        {
            title: 'Status',
            isOk: true,
            description: statusOk,
        },
    ];
};

const Wrapper = ({ children }) => (
    <Section>
        <Container>{children}</Container>
    </Section>
);

function StatusList({
    integrationStatuses,
    endpointStatuses,
    internalStatuses,
    isLoading,
    hasError,
    onRetry,
}) {
    if (isLoading) {
        return (
            <Wrapper>
                <Box padding={['large', 0]}>
                    <Spinner
                        size="large"
                        message="Loading"
                        color={['gray', 'dark']}
                        isCentered
                    />
                </Box>
            </Wrapper>
        );
    }

    if (hasError) {
        return (
            <Wrapper>
                <Box padding={['large', 0]}>
                    <Alert
                        theme="danger"
                        title="There Was An Error Loading The Statuses"
                        actionItems={<Button onClick={onRetry}>Retry</Button>}
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            again in a few moments.
                        </p>
                    </Alert>
                </Box>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <StatusPanel
                title="Integration Status"
                description="SSP/DSP Partners which users can activate their Contexts with."
                statuses={integrationStatuses}
                getChart={(data) => [
                    {
                        id: 'uploads',
                        name: 'File Uploads',
                        axis: 'left',
                        color: '#3040E8',
                        data: data.reduce((r, o) => {
                            if (r[r.length - 1]?.date === o.activity_date) {
                                r[r.length - 1].files += o.files;
                            } else {
                                r.push({
                                    date: o.activity_date,
                                    value: o.files,
                                });
                            }
                            return r;
                        }, []),
                    },
                    {
                        id: 'data',
                        name: 'Data (Bytes)',
                        axis: 'right',
                        format: 'M',
                        color: '#1CBEC9',
                        data: data.reduce((r, o) => {
                            if (r[r.length - 1]?.date === o.activity_date) {
                                r[r.length - 1].size += o.size;
                            } else {
                                r.push({
                                    date: o.activity_date,
                                    value: o.size,
                                });
                            }
                            return r;
                        }, []),
                        isSkinny: true,
                    },
                ]}
                getStatuses={getIntegrationStatuses}
            />

            <StatusPanel
                title="Endpoint Status"
                description="Servers which receive data to build Insights, Contexts etc."
                statuses={endpointStatuses}
                getChart={() => null}
                getStatuses={getEndpointStatuses}
            />

            <StatusPanel
                title="Internal Components / UI"
                description="Core functionality of the 4D experience."
                statuses={internalStatuses}
                getChart={() => null}
                getStatuses={getInternalStatuses}
            />
        </Wrapper>
    );
}

export default StatusList;
