import { orientationPrefix } from '../mixins.styles';
import {
    orientation,
    spacing,
    color,
    borderWidth,
    borderRadius,
} from '../tokens.styles';

const styles = {
    box: {},

    'position--relative': {
        position: 'relative',
    },

    'border-top--none': {
        borderTop: 'none',
    },

    'border-bottom--none': {
        borderBottom: 'none',
    },

    'border-left--none': {
        borderLeft: 'none',
    },

    'border-right--none': {
        borderRight: 'none',
    },
};

Object.entries(color).forEach(([colorKey]) => {
    Object.entries(color[colorKey]).forEach(([tone]) => {
        styles[`background--${colorKey}-${tone}`] = {
            backgroundColor: `${color[colorKey][tone]}`,
        };
    });
});

Object.entries(borderWidth).forEach(([thickness]) => {
    Object.entries(color).forEach(([colorKey]) => {
        Object.entries(color[colorKey]).forEach(([tone]) => {
            const solid = `${borderWidth[thickness]} solid ${color[colorKey][tone]}`;
            const dashed = `${borderWidth[thickness]} dashed ${color[colorKey][tone]}`;

            styles[`border--${thickness}-solid-${colorKey}-${tone}`] = {
                border: solid,
            };

            styles[`border-top--${thickness}-solid-${colorKey}-${tone}`] = {
                borderTop: `${solid} !important`,
            };

            styles[`border-bottom--${thickness}-solid-${colorKey}-${tone}`] = {
                borderBottom: `${solid} !important`,
            };

            styles[`border-left--${thickness}-solid-${colorKey}-${tone}`] = {
                borderLeft: `${solid} !important`,
            };

            styles[`border-right--${thickness}-solid-${colorKey}-${tone}`] = {
                borderRight: `${solid} !important`,
            };

            styles[`border--${thickness}-dashed-${colorKey}-${tone}`] = {
                border: `${dashed} !important`,
            };

            styles[`border-top--${thickness}-dashed-${colorKey}-${tone}`] = {
                borderTop: `${dashed} !important`,
            };

            styles[`border-bottom--${thickness}-dashed-${colorKey}-${tone}`] = {
                borderBottom: `${dashed} !important`,
            };

            styles[`border-left--${thickness}-dashed-${colorKey}-${tone}`] = {
                borderLeft: `${dashed} !important`,
            };

            styles[`border-right--${thickness}-dashed-${colorKey}-${tone}`] = {
                borderRight: `${dashed} !important`,
            };
        });
    });
});

Object.entries(borderRadius).forEach(([key, value]) => {
    styles[`border-radius--${key}`] = {
        borderRadius: value,
    };

    styles[`border-radius-top-left--${key}`] = {
        borderTopLeftRadius: value,
    };

    styles[`border-radius-top-right--${key}`] = {
        borderTopRightRadius: value,
    };

    styles[`border-radius-bottom-right--${key}`] = {
        borderBottomRightRadius: value,
    };

    styles[`border-radius-bottom-left--${key}`] = {
        borderBottomLeftRadius: value,
    };
});

Object.entries(orientation).forEach(([key]) => {
    const prefix = orientationPrefix(key);

    Object.entries(spacing).forEach(([size]) => {
        styles[`${prefix}padding--${size}`] = {
            padding: spacing[size],
        };

        styles[`${prefix}padding-horizontal--${size}`] = {
            paddingLeft: spacing[size],
            paddingRight: spacing[size],
        };

        styles[`${prefix}padding-vertical--${size}`] = {
            paddingBottom: spacing[size],
            paddingTop: spacing[size],
        };

        styles[`${prefix}padding-top--${size}`] = {
            paddingTop: spacing[size],
        };

        styles[`${prefix}padding-bottom--${size}`] = {
            paddingBottom: spacing[size],
        };

        styles[`${prefix}padding-left--${size}`] = {
            paddingLeft: spacing[size],
        };

        styles[`${prefix}padding-right--${size}`] = {
            paddingRight: spacing[size],
        };

        styles[`${prefix}margin--${size}`] = {
            margin: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin---${size}`] = {
                margin: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin-horizontal--${size}`] = {
            marginLeft: spacing[size],
            marginRight: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin-horizontal---${size}`] = {
                marginLeft: 0 - spacing[size],
                marginRight: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin-vertical--${size}`] = {
            marginTop: spacing[size],
            marginBottom: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin-vertical---${size}`] = {
                marginTop: 0 - spacing[size],
                marginBottom: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin-top--${size}`] = {
            marginTop: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin-top---${size}`] = {
                marginTop: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin-bottom--${size}`] = {
            marginBottom: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin-bottom---${size}`] = {
                marginBottom: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin-left--${size}`] = {
            marginLeft: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin-left---${size}`] = {
                marginLeft: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin-right--${size}`] = {
            marginRight: spacing[size],
        };

        if (size !== 'none') {
            styles[`${prefix}margin-right---${size}`] = {
                marginRight: 0 - spacing[size],
            };
        }

        styles[`${prefix}margin--auto`] = {
            margin: 'auto',
        };

        styles[`${prefix}margin-horizontal--auto`] = {
            marginLeft: 'auto',
            marginRight: 'auto',
        };

        styles[`${prefix}margin-vertical--auto`] = {
            marginTop: 'auto',
            marginBottom: 'auto',
        };

        styles[`${prefix}margin-top--auto`] = {
            marginTop: 'auto',
        };

        styles[`${prefix}margin-bottom--auto`] = {
            marginBottom: 'auto',
        };

        styles[`${prefix}margin-left--auto`] = {
            marginLeft: 'auto',
        };

        styles[`${prefix}margin-right--auto`] = {
            marginRight: 'auto',
        };

        styles[`${prefix}display--none`] = {
            display: 'none',
        };

        styles[`${prefix}display--block`] = {
            display: 'block',
        };

        styles[`${prefix}display--inline-block`] = {
            display: 'inline-block',
        };

        styles[`${prefix}display--flex`] = {
            display: 'flex',
        };
    });
});

export default styles;
