import React, { useRef } from 'react';
import Box from 'js/components/box/box';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Crumb from 'js/components/breadcrumbs/crumb';
import TopBar from 'js/components/top-bar/top-bar';
import FeatureReleaseBody from './components/discover-body/feature-release-body';
import PageExploreBody from './components/discover-body/page-explore-body';
import VideoExploreBody from './components/discover-body/video-explore-body';
import discoverTabs from './enums/discover-tabs.enum';
import styles from './discover.module.scss';

const getBreadcrumbs = ({ currentTab, hasError, loading }) => {
    const breadcrumbs = [
        <Crumb key="home" to="/" label="4D" />,
        <Crumb key="discover" isInactive label="Discover" isCollapsible />,
    ];
    if (hasError) {
        breadcrumbs.push(<Crumb key="error" hasError />);
    } else if (loading) {
        breadcrumbs.push(<Crumb key="loading" isLoading />);
    } else {
        breadcrumbs.push(
            <Crumb key={currentTab.id} label={currentTab.label} />,
        );
    }

    return breadcrumbs;
};

export default function Discover({
    tabs,
    loading,
    errors,
    setLoading,
    setErrors,
}) {
    const buttonsWrapper = ({ children }) => (
        <Row justifyContent="center">
            <Col span="auto">
                <Box padding={['large', 0, 'base']}>{children}</Box>
            </Col>
        </Row>
    );

    const scrollRef = useRef();

    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <>
                    <div ref={scrollRef} />
                    <TopBar
                        breadcrumbs={getBreadcrumbs({
                            currentTab: tabs.current,
                            hasError: Object.keys(errors).length > 0,
                            loading,
                        })}
                    />
                </>
            }
        >
            <div className={styles.container}>
                <div className={styles.content}>
                    <Tabs
                        tabs={tabs.list}
                        currentTab={tabs.current.id}
                        buttonsWrapper={buttonsWrapper}
                    >
                        <TabContent tabId={discoverTabs.FEATURE}>
                            <FeatureReleaseBody
                                scrollRef={scrollRef}
                                loading={loading}
                                setLoading={setLoading}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </TabContent>
                        <TabContent tabId={discoverTabs.VIDEO}>
                            <VideoExploreBody
                                loading={loading}
                                setLoading={setLoading}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </TabContent>
                        <TabContent tabId={discoverTabs.PAGE}>
                            <PageExploreBody
                                loading={loading}
                                setLoading={setLoading}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </TabContent>
                    </Tabs>
                </div>
            </div>
        </Layout>
    );
}
