import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import styles from './carousel.module.scss';

function Carousel({
    slides,
    infinite,
    showThumbnails,
    showFullscreenButton,
    showPlayButton,
    showBullets,
    autoPlay,
    renderLeftNav,
    renderRightNav,
    onSlide,
    onBeforeSlide,
    onMouseOver,
    onMouseLeave,
    controllerRef,
    slideInterval,
}) {
    return (
        <ImageGallery
            ref={controllerRef}
            items={slides}
            additionalClass={styles.container}
            infinite={infinite}
            showThumbnails={showThumbnails}
            showFullscreenButton={showFullscreenButton}
            showPlayButton={showPlayButton}
            showBullets={showBullets}
            autoPlay={autoPlay}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            onSlide={onSlide}
            onBeforeSlide={onBeforeSlide}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            slideInterval={slideInterval}
        />
    );
}

Carousel.defaultProps = {
    infinite: false,
    showThumbnails: false,
    showFullscreenButton: false,
    showPlayButton: false,
    showBullets: false,
    autoPlay: false,
    renderLeftNav: undefined,
    renderRightNav: undefined,
    onSlide: undefined,
    onBeforeSlide: undefined,
    slideInterval: 3000,
};

Carousel.propTypes = {
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            original: PropTypes.string,
            originalHeight: PropTypes.number,
            originalWidth: PropTypes.number,
            renderItem: PropTypes.func,
            originalAlt: PropTypes.string,
            description: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
        }),
    ).isRequired,
    infinite: PropTypes.bool,
    showThumbnails: PropTypes.bool,
    showFullscreenButton: PropTypes.bool,
    showPlayButton: PropTypes.bool,
    showBullets: PropTypes.bool,
    autoPlay: PropTypes.bool,
    renderLeftNav: PropTypes.func,
    renderRightNav: PropTypes.func,
    onSlide: PropTypes.func,
    onBeforeSlide: PropTypes.func,
    slideInterval: PropTypes.number,
};

export default Carousel;
