import React from 'react';
import { createPresetUrl } from 'js/utils';
import { sortOptions } from 'js/constants';
import filterTypes from 'js/enums/filter-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import FilterDropdown from 'js/components/filter-dropdown/filter-dropdown';
import SectionTitle from 'js/components/section-title/section-title';
import { showToast } from 'js/components/toast/toast';
import AdvertiserCampaigns from './advertiser-campaigns';
import FavoriteCampaigns from './favorite-campaigns';

function CampaignListBody({
    loading,
    errors,
    readOnly,
    campaigns,
    advertiser,
    ordering,
    togglingFavoriteError,
    onToggleFavoriteCampaign,
    onUpdateFilter,
    onDeleteCampaign,
}) {
    const checkIfErrorTypeExists = (errorType) =>
        Object.keys(errors).some((key) => key === errorType);
    const hasLoadingError = checkIfErrorTypeExists('loadingFailed');
    const hasFavoriteCampaigns = campaigns.some((item) => item.is_favourite);

    const newCampaign = { advertiser: advertiser.id };
    const newCampaignUrl = createPresetUrl('/campaigns/new/', newCampaign);

    const titleButtons = [];
    if (!readOnly) {
        titleButtons.push(
            <Col span="auto">
                <Button theme="outline" to={newCampaignUrl}>
                    Create Campaign
                </Button>
            </Col>,
        );
    }
    titleButtons.push(
        <Col span="auto">
            <FilterDropdown
                name="Sort Order"
                prefix="Sort by"
                param={filterTypes.ORDERING}
                options={sortOptions}
                selectedValue={ordering}
                onFilterChange={onUpdateFilter}
            />
        </Col>,
    );

    if (Object.keys(togglingFavoriteError).length) {
        showToast(
            'There Was An Unexpected Error',
            <p>
                It was not possible to{' '}
                {togglingFavoriteError.isFavorite ? 'favorite' : 'unfavorite'}{' '}
                {togglingFavoriteError.title}. Please try again in a few
                moments.
            </p>,
            5,
            'danger',
        );
    }

    return (
        <Box padding={['large', 0]}>
            <Container>
                <SectionTitle hasBar extension={titleButtons}>
                    Campaigns
                </SectionTitle>
            </Container>

            {hasFavoriteCampaigns && (
                <FavoriteCampaigns
                    campaigns={campaigns.filter((item) => item.is_favourite)}
                    onToggleFavorite={onToggleFavoriteCampaign}
                    onDelete={onDeleteCampaign}
                />
            )}

            <AdvertiserCampaigns
                campaigns={campaigns}
                advertiser={advertiser}
                readOnly={readOnly}
                loading={loading}
                hasLoadingError={hasLoadingError}
                onToggleFavorite={onToggleFavoriteCampaign}
                onDelete={onDeleteCampaign}
            />
        </Box>
    );
}

export default CampaignListBody;
