import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { useClickOutside, useScrollObserver } from 'js/hooks';
import Button from 'js/components/button/button';
import Popper from 'js/components/popper/popper';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

const InfoButton = ({
    active,
    placement,
    fluidTooltip,
    children,
    onToggle,
}) => {
    const tooltipRef = useRef();
    const [isActive, setActive] = useState(false);

    const tooltipClasses = cx({
        tooltip: true,
        'tooltip--fluid': fluidTooltip,
    });

    const onToggleActive = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setActive((prevState) => !prevState);
    }, []);

    useClickOutside([tooltipRef], () => setActive(false));
    useScrollObserver(useRef(window), () => setActive(false));

    useEffect(() => {
        if (onToggle) onToggle(isActive);
    }, [isActive, onToggle]);

    useEffect(() => {
        setActive(active);
    }, [active]);

    return (
        <Popper
            content={
                <div ref={tooltipRef} className={tooltipClasses}>
                    {children}
                </div>
            }
            theme="tooltip"
            placement={placement}
            offset={[0, 4]}
            showContent={isActive}
        >
            <Button
                size="smaller"
                theme="outline"
                square
                active={isActive}
                onClick={(e) => onToggleActive(e)}
            >
                <FontAwesomeIcon icon={faInfo} />
            </Button>
        </Popper>
    );
};

InfoButton.defaultProps = {
    active: false,
    placement: 'bottom-start',
    fluidTooltip: false,
};

InfoButton.propTypes = {
    active: PropTypes.bool,
    fluidTooltip: PropTypes.bool,
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf([
        'auto',
        'auto-start',
        'auto-end',
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
    ]),
};

export default InfoButton;
