import React from 'react';
import {
    faEye,
    faEyeSlash,
    faHandPointer,
} from '@fortawesome/free-regular-svg-icons';
import columnLabels from 'js/enums/column-labels.enum';
import columnTypes from 'js/enums/column-types.enum';
import TopicsPanel from './topics-panel/topics-panel';
import KeywordsPanel from './keywords-panel/keywords-panel';

const kpis = {
    IMPRESSIONS: {
        name: 'Impressions',
        getValue: (item) => item.impressions,
        color: ['purple', 'base'],
        isPercentage: false,
        hintIcon: faEyeSlash,
    },
    CTR: {
        name: 'CTR',
        getValue: (item) => item.clicks / (item.impressions || undefined),
        color: ['aqua', 'dark'],
        isPercentage: true,
        hintIcon: faHandPointer,
    },
    VIEW_RATE: {
        name: 'View Rate',
        getValue: (item) => item.in_views / (item.impressions || undefined),
        color: ['blue', 'light'],
        isPercentage: true,
        hintIcon: faEye,
    },
};

const ReportTab = ({
    report,
    context,
    onChangeContextRules,
    onInspectTopic,
}) => {
    let tableColumns = [
        {
            label: columnLabels.IMPRESSIONS,
            value: columnTypes.IMPRESSIONS,
        },
        {
            label: columnLabels.CLICKS,
            value: columnTypes.CLICKS,
        },
        {
            label: columnLabels.CTR,
            value: columnTypes.CTR,
        },
        {
            label: columnLabels.VIEWABLE_IMPRESSIONS,
            value: columnTypes.VIEWABLE_IMPRESSIONS,
        },
        {
            label: columnLabels.VIEW_RATE,
            value: columnTypes.VIEW_RATE,
        },
    ];

    if (!report.in_views) {
        tableColumns = tableColumns.slice(0, -2);
    }

    return (
        <>
            <TopicsPanel
                report={report}
                context={context}
                kpis={kpis}
                tableColumns={tableColumns}
                onChangeContextRules={onChangeContextRules}
                onInspectTopic={onInspectTopic}
            />
            <KeywordsPanel
                report={report}
                context={context}
                kpis={kpis}
                tableColumns={tableColumns}
                onChangeContextRules={onChangeContextRules}
            />
        </>
    );
};

export default ReportTab;
