import { color, fontSize, fontWeight, spacing } from '../tokens.styles';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexShrink: 0,
        padding: spacing.large,
        backgroundColor: color.gray.background,
    },

    containerPortrait: {
        paddingBottom: spacing.base,
    },

    containerLandscape: {
        width: 254,
        marginTop: -spacing.large,
    },

    titlePortrait: {
        fontSize: fontSize.largest,
    },

    titleLandscape: {
        fontSize: fontSize.huge,
    },

    descriptionPortrait: {
        display: 'none',
        opacity: 0,
    },

    descriptionLandscape: {
        marginTop: spacing.base,
        fontWeight: fontWeight.medium,
        fontSize: fontSize.large,
    },

    containerPortraitWithLogo: {
        paddingTop: spacing.large + 25 + spacing.small, // <Aside> padding + logo height + extra padding
    },

    containerLandscapeWithLogo: {
        marginTop: -(spacing.large + 35 + spacing.base), // <Aside> padding + logo height + extra padding
        paddingTop: spacing.large + 35 + spacing.base, // <Aside> padding + logo height + extra padding
    },
};

export default styles;
