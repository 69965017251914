import React from 'react';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import Section from 'js/components/section/section';
import SectionTitle from 'js/components/section-title/section-title';
import Text from 'js/components/text/text';
import styles from './page-section.module.scss';

export default function PageSection({
    title,
    description,
    hasDarkBackground,
    children,
    extension,
}) {
    return (
        <Section theme={hasDarkBackground ? 'secondary' : null}>
            <Container>
                <div className={styles.container}>
                    <Box padding={['large', 0]}>
                        {(title || description) && (
                            <Box margin={[0, 0, 'base']}>
                                <SectionTitle
                                    hasBar
                                    info={description}
                                    extension={extension || null}
                                >
                                    <Text
                                        color={['gray', 'darkest']}
                                        size="huge"
                                        weight="base"
                                    >
                                        {title}
                                    </Text>
                                </SectionTitle>
                            </Box>
                        )}
                        {children}
                    </Box>
                </div>
            </Container>
        </Section>
    );
}
