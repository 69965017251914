import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import InfoButton from 'js/components/button/info-button';
import styles from './label.module.scss';

const cx = classNames.bind(styles);

const Label = ({
    children,
    errorMessage,
    forId,
    hasError,
    hint,
    info,
    label,
    required,
}) => {
    const labelClasses = cx({
        label,
        'label--error': hasError,
        'label--cursor-pointer': forId,
    });

    return (
        <>
            {label && (
                <div className={styles['label-container']}>
                    <label htmlFor={forId} className={classNames(labelClasses)}>
                        {label}
                    </label>

                    {required && (
                        <span className={styles['required-label']}>
                            {typeof required === 'boolean'
                                ? 'Required'
                                : required}
                        </span>
                    )}

                    {info && (
                        <div className={styles['info-container']}>
                            <InfoButton>{info}</InfoButton>
                        </div>
                    )}
                </div>
            )}

            {hint && <div className={styles['hint-container']}>{hint}</div>}

            {children &&
                (label || hint ? (
                    <Box margin={['small', 0, 0]}>{children}</Box>
                ) : (
                    children
                ))}

            {errorMessage && hasError && (
                <div className={styles['error-message-container']}>
                    {errorMessage}
                </div>
            )}
        </>
    );
};

Label.defaultProps = {
    forId: undefined,
    label: undefined,
    hint: undefined,
    info: undefined,
    required: false,
    children: undefined,
    hasError: false,
    errorMessage: undefined,
};

Label.propTypes = {
    forId: PropTypes.string,
    label: PropTypes.node,
    hint: PropTypes.node,
    info: PropTypes.node,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    children: PropTypes.node,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.node,
};

export default Label;
