import React, { useEffect, useState } from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import columnLabels from 'js/enums/column-labels.enum';
import columnTypes from 'js/enums/column-types.enum';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Checkbox from 'js/components/checkbox/checkbox';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import InsightsTable from '../../components/insights-table/insights-table';
import PanelWrapper from '../../components/panel-wrapper';
import targetingOptions from '../enums/targetingOptions';

const getColumns = (isDV360Report, showClicks) => {
    const columns = [
        {
            label: columnLabels.IMPRESSIONS,
            value: columnTypes.IMPRESSIONS,
        },
        {
            label: columnLabels.STARTS,
            value: columnTypes.STARTS,
        },
        {
            label: isDV360Report
                ? columnLabels.COMPLETES.DV360
                : columnLabels.COMPLETES.DEFAULT,
            value: columnTypes.COMPLETES,
        },
        {
            label: isDV360Report
                ? columnLabels.COMPLETION_RATE.DV360
                : columnLabels.COMPLETION_RATE.DEFAULT,
            value: columnTypes.COMPLETION_RATE,
        },
        {
            label: columnLabels.FIRST_QUARTILES,
            value: columnTypes.FIRST_QUARTILES,
        },
        {
            label: columnLabels.MIDPOINTS,
            value: columnTypes.MIDPOINTS,
        },
        {
            label: columnLabels.THIRD_QUARTILES,
            value: columnTypes.THIRD_QUARTILES,
        },
    ];

    if (showClicks) {
        columns.splice(1, 0, {
            label: columnLabels.CLICKS,
            value: columnTypes.CLICKS,
        });
        columns.splice(5, 0, {
            label: columnLabels.CTR,
            value: columnTypes.CTR,
        });
    }

    return columns;
};

const getInitialColumns = (showClicks) => {
    const initialColumns = [
        columnTypes.IMPRESSIONS,
        columnTypes.STARTS,
        columnTypes.COMPLETES,
        columnTypes.COMPLETION_RATE,
    ];

    if (showClicks) initialColumns.splice(1, 0, columnTypes.CLICKS);

    return initialColumns;
};

export const ColumnDropdown = ({
    options,
    selectedValues,
    onApply,
    showClicks,
}) => {
    const [selectedOptions, setSelectedOptions] = useState(selectedValues);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        setSelectedOptions(selectedValues);
    }, [selectedValues]);

    const resetOptions = () => {
        onApply(getInitialColumns(showClicks));
        setShowMenu(false);
    };

    const applyFilter = () => {
        onApply(selectedOptions);
        setShowMenu(false);
    };

    const toggleMenu = () => {
        if (showMenu) {
            setSelectedOptions(selectedValues);
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }
    };

    return (
        <DropdownMenu
            content={
                <ul>
                    <Box margin={['-small', 'small', 0]}>
                        <Checkbox
                            options={options}
                            selectedValues={selectedOptions}
                            onChange={setSelectedOptions}
                        />
                    </Box>

                    <DropdownDivider />

                    <DropdownButton onClick={resetOptions}>
                        Reset To Default
                    </DropdownButton>

                    <DropdownButton
                        theme="primary"
                        disabled={!selectedOptions.length}
                        onClick={applyFilter}
                    >
                        Apply
                    </DropdownButton>
                </ul>
            }
            onHide={() => setShowMenu(false)}
            showMenu={showMenu}
        >
            <Button theme="outline" active={showMenu} onClick={toggleMenu}>
                Edit Columns
            </Button>
        </DropdownMenu>
    );
};

const TopicButton = ({
    itemId,
    isTargeting,
    rules,
    onChangeContextRules,
    targetingOption,
}) => {
    const targetRules = rules.filter(
        ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
    );
    const blockRule = rules.find(
        ({ aggregation }) => aggregation === ruleTypes.EXCLUDED,
    );

    const isTopicTargeted = targetRules
        .map(({ topics }) => topics)
        .flat()
        .includes(itemId);
    const isLogoTargeted = targetRules
        .map(({ logos }) => logos)
        .flat()
        .includes(itemId);

    const isItemTargeted =
        targetingOption === targetingOptions.TOPIC
            ? isTopicTargeted
            : isLogoTargeted;
    const isItemBlocked =
        blockRule[
            targetingOption === targetingOptions.TOPIC ? 'topics' : 'logos'
        ].includes(itemId);

    const addItem = (ruleKey, item) => {
        const ruleSet = new RuleSet(rules);
        if (ruleKey !== undefined) {
            ruleSet.changeRule(ruleKey, {
                [targetingOption === targetingOptions.TOPIC
                    ? 'addTopics'
                    : 'addLogos']: [item],
            });
        } else {
            ruleSet.addRule({
                aggregation: ruleTypes.INCLUDED,
                [targetingOption === targetingOptions.TOPIC
                    ? 'topics'
                    : 'logos']: [item],
            });
        }
        onChangeContextRules(ruleSet.rules);
    };

    const removeItem = (topic) => {
        const ruleSet = new RuleSet(rules);
        ruleSet.changeAllRules({
            [targetingOption === targetingOptions.TOPIC
                ? 'removeTopics'
                : 'removeLogos']: [topic],
        });
        onChangeContextRules(ruleSet.rules);
    };

    return isTargeting ? (
        <Button
            size="small"
            theme="switch"
            prefix={
                <FontAwesomeIcon
                    icon={isItemTargeted ? faCheckCircle : faCircle}
                />
            }
            active={isItemTargeted}
            onClick={() => {
                if (isItemTargeted) {
                    removeItem(itemId);
                } else {
                    addItem(targetRules[0].key, itemId);
                }
            }}
        >
            Target
        </Button>
    ) : (
        <Button
            size="small"
            theme="switch"
            prefix={
                <FontAwesomeIcon
                    icon={isItemBlocked ? faCheckCircle : faCircle}
                />
            }
            active={isItemBlocked}
            onClick={() => {
                if (isItemBlocked) {
                    removeItem(itemId);
                } else {
                    addItem(blockRule.key, itemId);
                }
            }}
        >
            Block
        </Button>
    );
};

export default function TableSection({
    report,
    context,
    targetData,
    dataGroups,
    targetingOption,
    onChangeContextRules,
    isDV360Report,
    showClicks,
    onInspectItem,
}) {
    const [visibleColumns, setVisibleColumns] = useState(
        getInitialColumns(showClicks),
    );

    const tableData = {
        rows: targetData.map(
            ({
                [targetingOption]: itemId,
                [`${targetingOption}_name`]: name,
                impressions,
                clicks,
                starts,
                first_quartiles: firstQuartiles,
                midpoints,
                third_quartiles: thirdQuartiles,
                completes,
            }) => {
                const group = dataGroups.find((g) =>
                    g[
                        targetingOption === targetingOptions.LOGO
                            ? 'logos'
                            : 'topics'
                    ].some(({ id }) => id === itemId),
                );

                const icon =
                    targetingOption === targetingOptions.TOPIC
                        ? group.logo
                        : group.logos.find(({ id }) => itemId === id).image;

                return {
                    id: itemId,
                    icon,
                    name: `${group.name} – ${name}`,
                    impressions,
                    clicks,
                    ctr: clicks / impressions,
                    starts,
                    firstQuartiles,
                    midpoints,
                    thirdQuartiles,
                    completes,
                    completionRate:
                        completes && impressions ? completes / impressions : 0,
                };
            },
        ),
        comparedValues: {
            impressions: report.impressions || 0,
            clicks: report.clicks || 0,
            ctr: report.clicks / report.impressions || 0,
            starts: report.starts || 0,
            firstQuartiles: report.first_quartiles || 0,
            midpoints: report.midpoints || 0,
            thirdQuartiles: report.third_quartiles || 0,
            completes: report.completes || 0,
            completionRate:
                report.completes && report.impressions
                    ? report.completes / report.impressions
                    : 0,
        },
    };

    const getButtons = (item) => [
        <TopicButton
            key="target"
            targetingOption={targetingOption}
            itemId={item.id}
            isTargeting
            rules={context.rules}
            onChangeContextRules={onChangeContextRules}
        />,
        <TopicButton
            key="block"
            targetingOption={targetingOption}
            itemId={item.id}
            rules={context.rules}
            onChangeContextRules={onChangeContextRules}
        />,
        <Button
            key="inspect"
            size="small"
            theme="outline"
            onClick={() => onInspectItem({ ...item, targetingOption })}
        >
            Inspect
        </Button>,
    ];

    return (
        <Box margin={['large', 0, 'base']}>
            <PanelWrapper>
                <Box margin={['base', 0]}>
                    <Box margin={[0, 0, 'base']}>
                        <Row justifyContent="space-between" alignItems="center">
                            <Col span="auto">
                                <Text weight="bold" size="larger">
                                    {targetingOption === targetingOptions.LOGO
                                        ? 'Brands'
                                        : 'Topics'}
                                </Text>
                            </Col>

                            <Col span="auto">
                                <Box padding={['small', 'base']}>
                                    <ColumnDropdown
                                        options={getColumns(
                                            isDV360Report,
                                            showClicks,
                                        )}
                                        selectedValues={visibleColumns}
                                        onApply={setVisibleColumns}
                                        showClicks={showClicks}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Box>

                    <InsightsTable
                        tableData={tableData}
                        visibleColumns={visibleColumns}
                        filteringOptions={getColumns(isDV360Report, showClicks)}
                        hasLimit
                        getButtons={getButtons}
                        isDV360Report={isDV360Report}
                    />
                </Box>
            </PanelWrapper>
        </Box>
    );
}
