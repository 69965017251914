import { useEffect, useState } from 'react';

export default function useScroller(scrollWrapperRef, deps = []) {
    const [isLeftScrollerVisible, setLeftScrollerVisible] = useState(false);
    const [isRightScrollerVisible, setRightScrollerVisible] = useState(false);

    const scrollToLeftEdge = () => {
        // eslint-disable-next-line no-param-reassign
        scrollWrapperRef.current.scrollLeft = 0;
    };

    const scrollToRightEdge = () => {
        // eslint-disable-next-line no-param-reassign
        scrollWrapperRef.current.scrollLeft =
            scrollWrapperRef.current.scrollWidth;
    };

    useEffect(() => {
        const scrollWrapper = scrollWrapperRef.current;
        let ticking = false;

        const handleResize = () => {
            const scrollPosition = scrollWrapper.scrollLeft;
            const totalWidth = scrollWrapper.offsetWidth;
            const visibleWidth = scrollWrapper.scrollWidth;

            if (!ticking) {
                window.requestAnimationFrame(() => {
                    setLeftScrollerVisible(scrollPosition > 0);
                    setRightScrollerVisible(
                        scrollPosition + totalWidth < visibleWidth,
                    );
                    ticking = false;
                });

                ticking = true;
            }
        };

        if (scrollWrapper) {
            handleResize();
            scrollWrapper.addEventListener('scroll', handleResize);
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (scrollWrapper) {
                scrollWrapper.removeEventListener('scroll', handleResize);
                window.removeEventListener('resize', handleResize);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollWrapperRef, scrollWrapperRef.current, deps]);

    return {
        isLeftScrollerVisible,
        isRightScrollerVisible,
        scrollToLeftEdge,
        scrollToRightEdge,
    };
}
