import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './switch.module.scss';

const cx = classNames.bind(styles);
let instanceId = 0;

function Switch({
    theme,
    labels: [leftLabel, rightLabel],
    isChecked,
    onChange,
    isDisabled,
}) {
    const [thisInstanceId, setThisInstanceId] = useState('');

    const switchClasses = cx({
        container: true,
        [theme]: theme,
        checked: isChecked,
    });

    useEffect(() => {
        instanceId += 1;
        setThisInstanceId(`switch-${instanceId}`);
    }, []);

    if (isDisabled) {
        return (
            <div className={switchClasses}>
                {!isChecked && (
                    <>
                        <input
                            id={`${thisInstanceId}-off`}
                            className={cx('handler', 'handler-left')}
                            name={thisInstanceId}
                            value="true"
                            type="radio"
                            checked={!isChecked}
                            disabled={isDisabled}
                        />
                        <label
                            htmlFor={`${thisInstanceId}-off`}
                            className={styles.label}
                        >
                            {leftLabel}
                        </label>
                    </>
                )}

                {isChecked && (
                    <>
                        <input
                            id={`${thisInstanceId}-on`}
                            className={cx('handler', 'handler-right')}
                            name={thisInstanceId}
                            value="false"
                            type="radio"
                            checked={isChecked}
                            disabled={isDisabled}
                        />
                        <label
                            htmlFor={`${thisInstanceId}-on`}
                            className={styles.label}
                        >
                            {rightLabel}
                        </label>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className={switchClasses}>
            <input
                id={`${thisInstanceId}-off`}
                className={cx('handler', 'handler-left')}
                name={thisInstanceId}
                value="true"
                type="radio"
                checked={!isChecked}
                onChange={onChange}
            />
            <label htmlFor={`${thisInstanceId}-off`} className={styles.label}>
                {leftLabel}
            </label>

            <input
                id={`${thisInstanceId}-on`}
                className={cx('handler', 'handler-right')}
                name={thisInstanceId}
                value="false"
                type="radio"
                checked={isChecked}
                onChange={onChange}
            />
            <label htmlFor={`${thisInstanceId}-on`} className={styles.label}>
                {rightLabel}
            </label>
        </div>
    );
}

Switch.defaultProps = {
    theme: 'regular',
    labels: ['Off', 'On'],
    isChecked: false,
    isDisabled: false,
};

Switch.propTypes = {
    theme: PropTypes.oneOf(['regular', 'equal']),
    labels: PropTypes.arrayOf(PropTypes.string),
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default Switch;
