import React, { useState } from 'react';
import moment from 'moment';
import entityTypes from 'js/enums/entity-types.enum';
import Button from 'js/components/button/button';
import DateRangePicker from 'js/components/date-picker/date-range-picker';
import ConfirmModal from 'js/components/modal/confirm-modal';
import Modal from 'js/components/modal/modal';
import Text from 'js/components/text/text';

const MIN_DAYS_RANGE = 7;
const EVENT_LOG_REPORT_MIN_DAYS_RANGE = 1;

const SnapshotModal = ({
    reportType,
    reports,
    isReady,
    hasError,
    onConfirm,
    onCancel,
}) => {
    const [startDate, setStartDate] = useState(
        moment().subtract(30, 'days').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());

    const minDaysRange =
        reportType === entityTypes.EVENT_LOG_REPORTS
            ? EVENT_LOG_REPORT_MIN_DAYS_RANGE
            : MIN_DAYS_RANGE;

    const isDaysRangeTooShort =
        moment(endDate).diff(moment(startDate), 'days') + 1 < minDaysRange;

    const isDaysRangeInUse = reports.some(
        (item) =>
            moment(item.start_date).isSame(startDate, 'day') &&
            moment(item.end_date).isSame(endDate, 'day'),
    );

    return isReady ? (
        <ConfirmModal
            title="Create New Snapshot"
            confirm="Create New Snapshot"
            cancel="Cancel"
            loadingMessage="Creating Snapshot"
            hasError={hasError || isDaysRangeTooShort || isDaysRangeInUse}
            errorMessage={
                hasError
                    ? 'You were unable to create a new snapshot because there was an unexpected error. Please try again in a few moments.'
                    : 'You can’t create a new snapshot because there are issues that have been highlighted in the form above.'
            }
            onConfirm={() =>
                onConfirm({
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                })
            }
            onCancel={onCancel}
        >
            <Text size="base" color={['gray', 'darkest']} lineHeight="base">
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={moment().toDate()}
                    hideUnavailableDates
                    hasError={isDaysRangeTooShort || isDaysRangeInUse}
                    errorMessage={
                        <p>
                            {isDaysRangeTooShort
                                ? `Your custom dates must span at least ${minDaysRange} days.`
                                : 'A snapshot with the same start and end dates already exists.'}
                        </p>
                    }
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                />
            </Text>
        </ConfirmModal>
    ) : (
        <Modal
            actionItems={<Button onClick={onCancel}>Close</Button>}
            preventClosing
        >
            <Text size="large" color={['gray', 'dark']} lineHeight="large">
                <p>
                    It is not possible to refresh the data for this report as a
                    new snapshot is already being processed.
                </p>
                <p>Please try again once the snapshot has been generated.</p>
            </Text>
        </Modal>
    );
};

export default SnapshotModal;
