import React from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import DrawerFooter from 'js/components/drawer/footer';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Layout from 'js/components/layout/layout';
import Section from 'js/components/section/section';
import Text from 'js/components/text/text';
import SummaryBg from './images/summary-bg.svg';
import styles from '../summary-footer.module.scss';
import Alert from '../../alert/alert';
import TopicsChanges from './topics-changes';
import LogosChanges from './logos-changes';
import KeywordsChanges from './keywords-changes';

function Summary() {
    return (
        <Section backgroundImg={SummaryBg}>
            <Container size="medium">
                <Box padding={['large', 0]}>
                    <div className={styles.summary}>
                        <Text size="huge">Context Settings & Changes</Text>

                        <Text
                            size="large"
                            color={['gray', 'dark']}
                            width="readable"
                        >
                            This panel outlines the changes that have been made
                            to the composition of your context.
                        </Text>
                    </div>
                </Box>
            </Container>
        </Section>
    );
}

function ContextChangesDrawer({
    context,
    contextChanges,
    topics,
    logos,
    onChangeContextRules,
    onClose,
}) {
    const { is_video: isVideo } = context;

    const hasChanges = contextChanges.rules.some((rule) => {
        if (isVideo)
            return (
                rule.addedTopics.length > 0 ||
                rule.removedTopics.length > 0 ||
                rule.addedLogos.length > 0 ||
                rule.removedLogos.length > 0
            );

        return (
            rule.addedKeywords.length > 0 ||
            rule.removedKeywords.length > 0 ||
            rule.addedTopics.length > 0 ||
            rule.removedTopics.length > 0
        );
    });

    return (
        <Drawer onClose={onClose}>
            <Layout
                footer={
                    <DrawerFooter>
                        <Container size="medium">
                            <Box padding={['small', 0]}>
                                <Row justifyContent="flex-end">
                                    <Col span="auto">
                                        <Button onClick={onClose}>Done</Button>
                                    </Col>
                                </Row>
                            </Box>
                        </Container>
                    </DrawerFooter>
                }
                isFooterSticky
            >
                <Summary />
                <Container size="medium">
                    {hasChanges ? (
                        <>
                            <TopicsChanges
                                context={context}
                                contextChanges={contextChanges}
                                topics={topics}
                                onChangeContextRules={onChangeContextRules}
                            />
                            {!isVideo ? (
                                <KeywordsChanges
                                    context={context}
                                    contextChanges={contextChanges}
                                    onChangeContextRules={onChangeContextRules}
                                />
                            ) : (
                                <LogosChanges
                                    context={context}
                                    contextChanges={contextChanges}
                                    logos={logos}
                                    onChangeContextRules={onChangeContextRules}
                                />
                            )}
                        </>
                    ) : (
                        <Box margin={['large', 0]}>
                            <Alert theme="empty">
                                <Box margin="small">
                                    <Text size="large">
                                        No changes have been applied to this
                                        context.
                                    </Text>
                                </Box>
                            </Alert>
                        </Box>
                    )}
                </Container>
            </Layout>
        </Drawer>
    );
}

ContextChangesDrawer.defaultProps = {
    contextChanges: {
        rules: [],
    },
};

ContextChangesDrawer.propTypes = {
    context: PropTypes.object.isRequired,
    contextChanges: PropTypes.shape({
        rules: PropTypes.array.isRequired,
    }),
    onChangeContextRules: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ContextChangesDrawer;
