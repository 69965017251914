import { useState } from 'react';

export default function useLimitList(
    list,
    initialCount,
    stepCount = list.length - initialCount,
) {
    const [visibleEntitiesCount, setVisibleEntitiesCount] =
        useState(initialCount);
    const [isListExpanded, setListExpanded] = useState(false);

    const hasMoreEntities = visibleEntitiesCount < list.length;
    const visibleEntities = list.slice(0, visibleEntitiesCount);

    const showMoreEntities = () => {
        setVisibleEntitiesCount((prevState) => prevState + stepCount);
        setListExpanded(true);
    };

    const showLessEntities = () => {
        setVisibleEntitiesCount(initialCount);
        setListExpanded(false);
    };

    return [
        hasMoreEntities,
        isListExpanded,
        visibleEntities,
        showMoreEntities,
        showLessEntities,
    ];
}
