import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './text.module.scss';

const cx = classNames.bind(styles);

function Text({
    color,
    lineHeight,
    spacing,
    size,
    weight,
    inline,
    width,
    children,
    align,
    textTransform,
}) {
    const classes = cx({
        container: true,
        [color && `color--${color[0]}-${color[1]}`]: color,
        [`line-height--${lineHeight}`]: lineHeight,
        [`size--${size}`]: size,
        [`spacing--${spacing}`]: spacing,
        [`weight--${weight}`]: weight,
        [`width--${width}`]: width,
        [`aligned--${align}`]: align,
        [`text-transform--${textTransform}`]: textTransform,
    });

    if (inline) {
        return <span className={classNames(classes)}>{children}</span>;
    }

    return <div className={classNames(classes)}>{children}</div>;
}

Text.defaultProps = {
    color: undefined,
    lineHeight: undefined,
    spacing: undefined,
    size: undefined,
    weight: undefined,
    inline: false,
    width: undefined,
    align: undefined,
    textTransform: undefined,
};

Text.propTypes = {
    color: PropTypes.arrayOf(
        PropTypes.oneOf([
            'purple',
            'red',
            'yellow',
            'aqua',
            'gray',
            'blue',
            'black',
            'darkest',
            'darker',
            'dark',
            'base',
            'light',
            'lighter',
            'lightest',
            'background',
            'white',
        ]),
    ),
    lineHeight: PropTypes.oneOf(['base', 'large']),
    spacing: PropTypes.oneOf([
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
    ]),
    size: PropTypes.oneOf([
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
        'largest',
        'huge',
    ]),
    weight: PropTypes.oneOf(['base', 'bold', 'bolder']),
    inline: PropTypes.bool,
    width: PropTypes.oneOf(['readable']),
    children: PropTypes.node.isRequired,
    align: PropTypes.oneOf(['center', 'right']),
    textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
};

export default Text;
