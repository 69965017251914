import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './panel.module.scss';

const cx = classNames.bind(styles);

function Panel({ children, theme, bordered }) {
    const classes = cx({
        container: true,
        [theme]: theme,
        bordered,
    });

    return <div className={classes}>{children}</div>;
}

Panel.defaultProps = {
    theme: 'primary',
    bordered: false,
};

Panel.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    bordered: PropTypes.bool,
};

export default Panel;
