import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faTimes,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import columnLabels from 'js/enums/column-labels.enum';
import columnTypes from 'js/enums/column-types.enum';
import sortTypes from 'js/enums/sort-types.enum';
import { useScroller } from 'js/hooks';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Checkbox from 'js/components/checkbox/checkbox';
import ClickArea from 'js/components/click-area/click-area';
import Col from 'js/components/grid/column';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import Form from 'js/components/form/form';
import InfoButton from 'js/components/button/info-button';
import Input from 'js/components/input/input';
import Number from 'js/components/number/number';
import Row from 'js/components/grid/row';
import SortingDropdown from 'js/components/sorting-dropdown/sorting-dropdown';
import Sparkline from 'js/components/sparkline/sparkline';
import Table from 'js/components/table/table';
import TableRow from 'js/components/table/table-row';
import Text from 'js/components/text/text';
import styles from './insights-table.module.scss';

const DropdownOption = ({ children, isSelected, onClick }) => {
    const classes = [styles['dropdown-option']];

    if (isSelected) {
        classes.push(styles.selected);
    }

    return (
        <li className={classNames(classes)}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={styles.content} onClick={onClick}>
                <span className={styles.icon}>
                    <FontAwesomeIcon
                        icon={isSelected ? faCheckCircle : faCircle}
                    />
                </span>

                <div className={styles.label}>{children}</div>
            </div>
        </li>
    );
};

const Filters = ({
    availableFilters,
    appliedFilters,
    onAddFilter,
    onRemoveFilter,
}) => {
    const filterRef = useRef();
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    return (
        <div className={styles.filters}>
            <Row gutter="none">
                <Col span="auto">
                    <Box padding={['small', 'smaller']}>
                        <span className={styles['filters-title']}>Filters</span>
                    </Box>
                </Col>

                <Col>
                    <div className={styles['filter-list']}>
                        {appliedFilters.length > 0 ? (
                            <Box padding={['smaller', 0]}>
                                {appliedFilters.map(({ type, label }) => (
                                    <FilterButton
                                        key={type}
                                        onRemoveFilter={() =>
                                            onRemoveFilter(type)
                                        }
                                    >
                                        {label}
                                    </FilterButton>
                                ))}
                            </Box>
                        ) : (
                            <Box padding={['small', 'smaller']}>
                                <div className={styles['no-filters']}>
                                    No Active Filters
                                </div>
                            </Box>
                        )}
                    </div>
                </Col>

                <Col span="auto">
                    <DropdownMenu
                        content={
                            <AddFilterMenu
                                availableFilters={availableFilters}
                                onCloseMenu={() => setShowFilterMenu(false)}
                                onFilterApply={onAddFilter}
                                ref={filterRef}
                            />
                        }
                        onHide={() => setShowFilterMenu(false)}
                        showMenu={showFilterMenu}
                        placement="bottom-start"
                        width="fixed"
                        insideRefs={[filterRef]}
                    >
                        <Box padding={['small', 'smaller']}>
                            <Button
                                theme="outline"
                                size="small"
                                active={showFilterMenu}
                                onClick={() =>
                                    setShowFilterMenu(!showFilterMenu)
                                }
                            >
                                Add Filter
                            </Button>
                        </Box>
                    </DropdownMenu>
                </Col>
            </Row>
        </div>
    );
};

const FilterButton = ({ children, onRemoveFilter }) => (
    <Box padding="smaller">
        <button
            type="button"
            className={styles.filter}
            onClick={onRemoveFilter}
        >
            <span>{children}</span>
            <span className={styles.suffix}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </button>
    </Box>
);

const AddFilterMenu = forwardRef(
    ({ availableFilters, onCloseMenu, onFilterApply }, ref) => {
        const [selectedFilter, setSelectedFilter] = useState('');
        const [selectedInequality, setSelectedInequality] = useState('>');
        const [value, setValue] = useState('');
        const [showMenu, setShowMenu] = useState(false);

        const applyFilter = () => {
            onFilterApply(selectedFilter, selectedInequality, value);
            onCloseMenu();
        };

        useEffect(() => {
            if (availableFilters.length) {
                setSelectedFilter(availableFilters[0].value);
            }
        }, [availableFilters]);

        if (!availableFilters.length) {
            return (
                <Box padding={['small', 'base']}>
                    <Text spacing="small">
                        <p>
                            No more filters can be added because all filterable
                            columns have an active filter.
                        </p>
                        <p>
                            To adjust a filter on a column you will need to
                            remove the active filter before adding a new one.
                        </p>
                    </Text>
                </Box>
            );
        }

        // It was originally supposed to use the Select component in this place,
        // but this provokes a bug associated with the parent menu closing prematurely.
        // I have not yet been able to find the cause and fix the bug,
        // so a workaround using DropdownMenu and DropdownOption will apply until this bug is fixed.
        return (
            <Box padding={[0, 'base']}>
                <Form onSubmit={applyFilter}>
                    <Box padding={['small', 0]}>
                        <DropdownMenu
                            ref={ref}
                            content={
                                <ul>
                                    {availableFilters
                                        .filter((o) => o.label)
                                        .map((option) => (
                                            <DropdownOption
                                                key={option.value}
                                                isSelected={
                                                    selectedFilter ===
                                                    option.value
                                                }
                                                onClick={() => {
                                                    setSelectedFilter(
                                                        option.value,
                                                    );
                                                    setShowMenu(false);
                                                }}
                                            >
                                                {option.label}
                                            </DropdownOption>
                                        ))}
                                </ul>
                            }
                            width="same"
                            offset={[0, 4]}
                            showMenu={showMenu}
                            onHide={() => setShowMenu(false)}
                        >
                            <Button
                                theme="select"
                                suffix={
                                    <FontAwesomeIcon icon={faChevronDown} />
                                }
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                {
                                    availableFilters.find(
                                        (option) =>
                                            option.value === selectedFilter,
                                    )?.label
                                }
                            </Button>
                        </DropdownMenu>
                    </Box>

                    <Box padding={['smaller', 0]}>
                        <Checkbox
                            radio
                            options={[
                                { label: 'Greater Than', value: '>' },
                                { label: 'Less Than', value: '<' },
                            ]}
                            selectedValues={selectedInequality}
                            onChange={setSelectedInequality}
                        />
                    </Box>

                    <Box padding={['small', 0]}>
                        <Input
                            label="Value"
                            hasError={
                                value.length > 0 &&
                                window.Number.isNaN(parseInt(value, 10))
                            }
                            errorMessage={<p>The value must be a number.</p>}
                            focusOnShow
                            type="number"
                            value={value}
                            onChange={(evt) => {
                                setValue(evt.target.value);
                            }}
                            step=".01"
                        />
                    </Box>

                    <Box padding={['small', 0]}>
                        <Row justifyContent="space-between">
                            <Col span="auto">
                                <Button
                                    theme="outline"
                                    type="button"
                                    onClick={onCloseMenu}
                                >
                                    Cancel
                                </Button>
                            </Col>

                            <Col span="auto">
                                <Button
                                    disabled={value.length === 0}
                                    onClick={applyFilter}
                                >
                                    Apply
                                </Button>
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </Box>
        );
    },
);

const ColumnTitle = ({
    title,
    subtitle,
    sortingOptions,
    selectedSorting,
    onSortingChange,
}) => (
    <>
        <Row gutter="none">
            <Col>
                <Row gutter="none" alignItems="center">
                    <Col span="auto">
                        <Text inline weight="bold" size="large">
                            {title}
                        </Text>
                    </Col>

                    {sortingOptions && (
                        <Col span="auto">
                            <Box padding={[0, 0, 0, 'smaller']}>
                                <SortingDropdown
                                    options={sortingOptions}
                                    selectedValue={selectedSorting}
                                    onSortingChange={onSortingChange}
                                />
                            </Box>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>

        {subtitle && (
            <Text color={['gray', 'dark']} size="small">
                {subtitle}
            </Text>
        )}
    </>
);

const ColumnCell = ({ value, comparedValue, maxValue, isDelta }) => (
    <div className={styles['fixed-width-cell']}>
        <Sparkline
            value={value}
            comparedValue={comparedValue}
            maxValue={maxValue}
            isDelta={isDelta}
        />
    </div>
);

const ActionButtons = ({ buttons }) => (
    <Box margin={['smaller', 0, 0]}>
        <Row gutter="small">
            {React.Children.map(buttons, (button) => (
                <Col span="auto">{button}</Col>
            ))}
        </Row>
    </Box>
);

const DescriptionTooltip = ({ children }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <InfoButton
            active={isActive}
            onClick={() => setIsActive(!isActive)}
            onClickOutside={() => setIsActive(false)}
        >
            {children}
        </InfoButton>
    );
};

const LimitButtons = ({
    currentLimit,
    minLimit,
    maxLimit,
    onViewMore,
    onViewLess,
}) => {
    if (currentLimit <= minLimit && currentLimit >= maxLimit) return null;

    return (
        <Box margin={['base', 0, 0]}>
            <Row justifyContent="center">
                {currentLimit > minLimit && (
                    <Col span="auto">
                        <Button theme="outline" onClick={onViewLess}>
                            View Less
                        </Button>
                    </Col>
                )}

                {currentLimit < maxLimit && (
                    <Col span="auto">
                        <Button theme="outline" onClick={onViewMore}>
                            View More
                        </Button>
                    </Col>
                )}
            </Row>
        </Box>
    );
};

const INITIAL_TABLE_LIMIT = 8;
const LIMIT_STEP = 8;

const InsightsTable = ({
    tableData,
    visibleColumns,
    filteringOptions,
    hasLimit,
    getButtons,
    isDV360Report,
}) => {
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [limit, setLimit] = useState(INITIAL_TABLE_LIMIT);
    const [currentSorting, setCurrentSorting] = useState(
        sortTypes.IMPRESSIONS_DESC,
    );

    const tableRef = useRef();
    const tableBottomRef = useRef();
    const { isRightScrollerVisible: isScrollerVisible, scrollToRightEdge } =
        useScroller(tableRef, [visibleColumns]);

    const { rows, comparedValues } = tableData;

    const availableFilters = filteringOptions.filter(
        (option) =>
            !appliedFilters.some((filter) => filter.type === option.value),
    );

    const sorter = (a, b) =>
        currentSorting.startsWith('-')
            ? b[currentSorting.slice(1)] - a[currentSorting.slice(1)]
            : a[currentSorting] - b[currentSorting];

    const filtering = (row) => {
        if (!appliedFilters.length) return true;

        return appliedFilters.every(({ inequality, type, value }) => {
            const multiplier =
                type === columnTypes.CTR ||
                type === columnTypes.VIEW_RATE ||
                type === columnTypes.COMPLETION_RATE
                    ? 100
                    : 1;

            return inequality === '>'
                ? row[type] * multiplier > value
                : row[type] * multiplier < value;
        });
    };

    const processedRows = rows.filter(filtering).sort(sorter);

    const hasButtons = (row) => getButtons && getButtons(row).length > 0;

    const hasImpressionsColumn = visibleColumns.includes(
        columnTypes.IMPRESSIONS,
    );
    const hasClicksColumn = visibleColumns.includes(columnTypes.CLICKS);
    const hasStartedViewsColumn = visibleColumns.includes(columnTypes.STARTS);
    const hasCompletedViewsColumn = visibleColumns.includes(
        columnTypes.COMPLETES,
    );
    const hasCompletionRateColumn = visibleColumns.includes(
        columnTypes.COMPLETION_RATE,
    );
    const hasCtrColumn = visibleColumns.includes(columnTypes.CTR);
    const hasViewableImpressionsColumn = visibleColumns.includes(
        columnTypes.VIEWABLE_IMPRESSIONS,
    );
    const hasViewRateColumn = visibleColumns.includes(columnTypes.VIEW_RATE);
    const hasFirstQuartileViewsColumn = visibleColumns.includes(
        columnTypes.FIRST_QUARTILES,
    );
    const hasMidpointViewsColumn = visibleColumns.includes(
        columnTypes.MIDPOINTS,
    );
    const hasThirdQuartileViewsColumn = visibleColumns.includes(
        columnTypes.THIRD_QUARTILES,
    );

    const getMaxColumnValue = (keyName) =>
        Math.max(...tableData.rows.map((item) => item[keyName]));

    const maxImpressionsValue = getMaxColumnValue(columnTypes.IMPRESSIONS);
    const maxClicksValue = getMaxColumnValue(columnTypes.CLICKS);
    const maxStartedViewsValue = getMaxColumnValue(columnTypes.STARTS);
    const maxCompletedViewsValue = getMaxColumnValue(columnTypes.COMPLETES);
    const maxCompletionRateValue = getMaxColumnValue(
        columnTypes.COMPLETION_RATE,
    );
    const maxCtrValue = getMaxColumnValue(columnTypes.CTR);
    const maxViewableImpressionsValue = getMaxColumnValue(
        columnTypes.VIEWABLE_IMPRESSIONS,
    );
    const maxViewRateValue = getMaxColumnValue(columnTypes.VIEW_RATE);
    const maxFirstQuartileViewsValue = getMaxColumnValue(
        columnTypes.FIRST_QUARTILES,
    );
    const maxMidpointViewsValue = getMaxColumnValue(columnTypes.MIDPOINTS);
    const maxThirdQuartileViewsValue = getMaxColumnValue(
        columnTypes.THIRD_QUARTILES,
    );

    const addFilter = (selectedFilter, selectedInequality, value) => {
        setAppliedFilters((prevState) => [
            ...prevState,
            {
                label: (
                    <>
                        {
                            filteringOptions.find(
                                (option) => option.value === selectedFilter,
                            ).label
                        }{' '}
                        {selectedInequality} <Number inline value={value} />
                    </>
                ),
                type: selectedFilter,
                inequality: selectedInequality,
                value,
            },
        ]);
    };

    const removeFilter = (filterType) => {
        setAppliedFilters((prevState) =>
            prevState.filter((filter) => filter.type !== filterType),
        );
    };

    const scrollIntoView = (currentRef) => {
        currentRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    useEffect(() => {
        setLimit(INITIAL_TABLE_LIMIT);
    }, [appliedFilters]);

    return (
        <Box padding="base" background={['gray', 'white']} borderRadius="round">
            <Box margin={[0, 0, 'base']}>
                <Row alignItems="center">
                    <Col>
                        <Filters
                            availableFilters={availableFilters}
                            appliedFilters={appliedFilters}
                            onAddFilter={addFilter}
                            onRemoveFilter={removeFilter}
                        />
                    </Col>

                    {isScrollerVisible && (
                        <Col span="auto">
                            <ClickArea onClick={scrollToRightEdge}>
                                <span className={styles.scroll}>
                                    Scroll Table Right To View More{' '}
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                            </ClickArea>
                        </Col>
                    )}
                </Row>
            </Box>

            {processedRows.length > 0 ? (
                <>
                    <div ref={tableRef} className={styles['table-wrapper']}>
                        <Table
                            firstColFixedWidth
                            firstColSticky
                            verticallyCentered
                        >
                            <TableRow isHeader>
                                <ColumnTitle title="Name" />

                                {hasImpressionsColumn && (
                                    <ColumnTitle
                                        title={columnLabels.IMPRESSIONS}
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending:
                                                sortTypes.IMPRESSIONS_ASC,
                                            descending:
                                                sortTypes.IMPRESSIONS_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasClicksColumn && (
                                    <ColumnTitle
                                        title={columnLabels.CLICKS}
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending: sortTypes.CLICKS_ASC,
                                            descending: sortTypes.CLICKS_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasStartedViewsColumn && (
                                    <ColumnTitle
                                        title={columnLabels.STARTS}
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending: sortTypes.STARTS_ASC,
                                            descending: sortTypes.STARTS_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasCompletedViewsColumn && (
                                    <ColumnTitle
                                        title={
                                            isDV360Report
                                                ? columnLabels.COMPLETES.DV360
                                                : columnLabels.COMPLETES.DEFAULT
                                        }
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending: sortTypes.COMPLETES_ASC,
                                            descending:
                                                sortTypes.COMPLETES_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasCompletionRateColumn && (
                                    <ColumnTitle
                                        title={
                                            isDV360Report
                                                ? columnLabels.COMPLETION_RATE
                                                      .DV360
                                                : columnLabels.COMPLETION_RATE
                                                      .DEFAULT
                                        }
                                        subtitle="(Compared To Avg.)"
                                        sortingOptions={{
                                            ascending:
                                                sortTypes.COMPLETION_RATE_ASC,
                                            descending:
                                                sortTypes.COMPLETION_RATE_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasCtrColumn && (
                                    <ColumnTitle
                                        title={columnLabels.CTR}
                                        subtitle="(Compared To Avg.)"
                                        sortingOptions={{
                                            ascending: sortTypes.CTR_ASC,
                                            descending: sortTypes.CTR_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasViewableImpressionsColumn && (
                                    <ColumnTitle
                                        title={
                                            columnLabels.VIEWABLE_IMPRESSIONS
                                        }
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending:
                                                sortTypes.VIEWABLE_IMPRESSIONS_ASC,
                                            descending:
                                                sortTypes.VIEWABLE_IMPRESSIONS_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasViewRateColumn && (
                                    <ColumnTitle
                                        title={columnLabels.VIEW_RATE}
                                        subtitle="(Compared To Avg.)"
                                        sortingOptions={{
                                            ascending: sortTypes.VIEW_RATE_ASC,
                                            descending:
                                                sortTypes.VIEW_RATE_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasFirstQuartileViewsColumn && (
                                    <ColumnTitle
                                        title={columnLabels.FIRST_QUARTILES}
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending:
                                                sortTypes.FIRST_QUARTILES_ASC,
                                            descending:
                                                sortTypes.FIRST_QUARTILES_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasMidpointViewsColumn && (
                                    <ColumnTitle
                                        title={columnLabels.MIDPOINTS}
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending: sortTypes.MIDPOINTS_ASC,
                                            descending:
                                                sortTypes.MIDPOINTS_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}

                                {hasThirdQuartileViewsColumn && (
                                    <ColumnTitle
                                        title={columnLabels.THIRD_QUARTILES}
                                        subtitle="(% Of Total)"
                                        sortingOptions={{
                                            ascending:
                                                sortTypes.THIRD_QUARTILES_ASC,
                                            descending:
                                                sortTypes.THIRD_QUARTILES_DESC,
                                        }}
                                        selectedSorting={currentSorting}
                                        onSortingChange={setCurrentSorting}
                                    />
                                )}
                            </TableRow>

                            {processedRows
                                .slice(0, hasLimit ? limit : rows.length)
                                .map((item) => (
                                    <TableRow
                                        key={item.name}
                                        hasBorder={
                                            item !==
                                            processedRows[
                                                processedRows.length - 1
                                            ]
                                        }
                                    >
                                        <Box
                                            margin={[
                                                !hasButtons(item) ? 'small' : 0,
                                                0,
                                            ]}
                                        >
                                            <Row
                                                gutter="small"
                                                alignItems="center"
                                            >
                                                {item.icon && (
                                                    <Col span="auto">
                                                        <img
                                                            className={
                                                                styles[
                                                                    'group-logo'
                                                                ]
                                                            }
                                                            src={item.icon}
                                                            alt={item.name}
                                                        />
                                                    </Col>
                                                )}
                                                <Col>
                                                    <Text weight="bold">
                                                        {item.name}
                                                    </Text>
                                                </Col>

                                                {item.description && (
                                                    <Col span="auto">
                                                        <DescriptionTooltip>
                                                            {item.description}
                                                        </DescriptionTooltip>
                                                    </Col>
                                                )}
                                            </Row>

                                            {hasButtons(item) && (
                                                <ActionButtons
                                                    buttons={getButtons(item)}
                                                />
                                            )}
                                        </Box>

                                        {hasImpressionsColumn && (
                                            <ColumnCell
                                                value={
                                                    item[
                                                        columnTypes.IMPRESSIONS
                                                    ]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.IMPRESSIONS
                                                    ]
                                                }
                                                maxValue={maxImpressionsValue}
                                            />
                                        )}

                                        {hasClicksColumn && (
                                            <ColumnCell
                                                value={item[columnTypes.CLICKS]}
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.CLICKS
                                                    ]
                                                }
                                                maxValue={maxClicksValue}
                                            />
                                        )}

                                        {hasStartedViewsColumn && (
                                            <ColumnCell
                                                value={item[columnTypes.STARTS]}
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.STARTS
                                                    ]
                                                }
                                                maxValue={maxStartedViewsValue}
                                            />
                                        )}

                                        {hasCompletedViewsColumn && (
                                            <ColumnCell
                                                value={
                                                    item[columnTypes.COMPLETES]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.COMPLETES
                                                    ]
                                                }
                                                maxValue={
                                                    maxCompletedViewsValue
                                                }
                                            />
                                        )}

                                        {hasCompletionRateColumn && (
                                            <ColumnCell
                                                value={
                                                    item[
                                                        columnTypes
                                                            .COMPLETION_RATE
                                                    ]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes
                                                            .COMPLETION_RATE
                                                    ]
                                                }
                                                maxValue={
                                                    maxCompletionRateValue
                                                }
                                                isDelta
                                            />
                                        )}

                                        {hasCtrColumn && (
                                            <ColumnCell
                                                value={item[columnTypes.CTR]}
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.CTR
                                                    ]
                                                }
                                                maxValue={maxCtrValue}
                                                isDelta
                                            />
                                        )}

                                        {hasViewableImpressionsColumn && (
                                            <ColumnCell
                                                value={
                                                    item[
                                                        columnTypes
                                                            .VIEWABLE_IMPRESSIONS
                                                    ]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes
                                                            .VIEWABLE_IMPRESSIONS
                                                    ]
                                                }
                                                maxValue={
                                                    maxViewableImpressionsValue
                                                }
                                            />
                                        )}

                                        {hasViewRateColumn && (
                                            <ColumnCell
                                                value={
                                                    item[columnTypes.VIEW_RATE]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.VIEW_RATE
                                                    ]
                                                }
                                                maxValue={maxViewRateValue}
                                                isDelta
                                            />
                                        )}

                                        {hasFirstQuartileViewsColumn && (
                                            <ColumnCell
                                                value={
                                                    item[
                                                        columnTypes
                                                            .FIRST_QUARTILES
                                                    ]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes
                                                            .FIRST_QUARTILES
                                                    ]
                                                }
                                                maxValue={
                                                    maxFirstQuartileViewsValue
                                                }
                                            />
                                        )}

                                        {hasMidpointViewsColumn && (
                                            <ColumnCell
                                                value={
                                                    item[columnTypes.MIDPOINTS]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes.MIDPOINTS
                                                    ]
                                                }
                                                maxValue={maxMidpointViewsValue}
                                            />
                                        )}

                                        {hasThirdQuartileViewsColumn && (
                                            <ColumnCell
                                                value={
                                                    item[
                                                        columnTypes
                                                            .THIRD_QUARTILES
                                                    ]
                                                }
                                                comparedValue={
                                                    comparedValues[
                                                        columnTypes
                                                            .THIRD_QUARTILES
                                                    ]
                                                }
                                                maxValue={
                                                    maxThirdQuartileViewsValue
                                                }
                                            />
                                        )}
                                    </TableRow>
                                ))}
                            <div ref={tableBottomRef} />
                        </Table>
                    </div>

                    {hasLimit && (
                        <LimitButtons
                            currentLimit={limit}
                            minLimit={INITIAL_TABLE_LIMIT}
                            maxLimit={processedRows.length}
                            onViewLess={() => {
                                scrollIntoView(tableRef);
                                setTimeout(
                                    () => setLimit(INITIAL_TABLE_LIMIT),
                                    800,
                                );
                            }}
                            onViewMore={() => {
                                scrollIntoView(tableBottomRef);
                                setLimit((prev) => prev + LIMIT_STEP);
                            }}
                        />
                    )}
                </>
            ) : (
                <Alert theme="empty">
                    <p>
                        No items match your current filters. Try clearing or
                        adjusting the filters above to view items.
                    </p>
                </Alert>
            )}
        </Box>
    );
};

export default InsightsTable;
