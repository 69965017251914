import React from 'react';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import AdvertiserList from './components/advertiser-list';
import TagPerformanceDashboard from './components/tag-performance-dashboard/tag-performance-dashboard';
import TemplateList from './components/template-list';

const ReportTemplateList = ({
    selectedRange,
    startDate,
    endDate,
    selectedAdvertiserId,
    advertisers,
    templates,
    eventAuditData,
    eventHierarchyData,
    isEventDataLoading,
    hasEventDataError,
    isLoading,
    hasError,
    onRetry,
    onCancelTemplateSchedule,
    onToggleTemplateFavorite,
    onToggleAdvertiserFavorite,
    onChangeRange,
    onChangeStart,
    onChangeEnd,
    onDelete,
}) => (
    <>
        {!selectedAdvertiserId && (
            <Box padding={['large', 0]}>
                <Container>
                    <TagPerformanceDashboard
                        selectedRange={selectedRange}
                        startDate={startDate}
                        endDate={endDate}
                        isLoading={isEventDataLoading}
                        hasError={hasEventDataError}
                        eventAuditData={eventAuditData}
                        eventHierarchyData={eventHierarchyData}
                        onChangeRange={onChangeRange}
                        onChangeStart={onChangeStart}
                        onChangeEnd={onChangeEnd}
                    />
                </Container>
            </Box>
        )}

        <Box padding={['large', 0]}>
            <Container>
                {!selectedAdvertiserId ? (
                    <AdvertiserList
                        advertisers={advertisers}
                        templates={templates}
                        isLoading={isLoading}
                        hasError={hasError}
                        onToggleAdvertiserFavorite={onToggleAdvertiserFavorite}
                        onRetry={onRetry}
                    />
                ) : (
                    <TemplateList
                        templates={templates.filter(
                            (item) => item.advertiser === selectedAdvertiserId,
                        )}
                        advertiserId={selectedAdvertiserId}
                        isLoading={isLoading}
                        hasError={hasError}
                        onCancelTemplateSchedule={onCancelTemplateSchedule}
                        onToggleTemplateFavorite={onToggleTemplateFavorite}
                        onRetry={onRetry}
                        onDelete={onDelete}
                    />
                )}
            </Container>
        </Box>
    </>
);

export default ReportTemplateList;
