import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { api, setDocumentTitle, validEmail } from 'js/utils';
import UserSettingsDetail from './user-settings-detail';

export default function UserSettingsDetailPage() {
    const history = useHistory();
    const [isAmending, setAmending] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [hasLoadingError, setLoadingError] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [userErrors, setUserErrors] = useState({});
    const [user, setUser] = useState({
        id: 'current',
        email: '',
        first_name: '',
        last_name: '',
    });

    const validateUser = useCallback(
        ({ userData, throwErrors = false } = {}) => {
            const errors = {};

            setAmending(true);

            if (!userData.email) {
                errors.email = 'Your email address is required.';
            } else if (!validEmail(userData.email)) {
                errors.email = 'Enter a valid email address.';
            }

            if (!userData.first_name) {
                errors.first_name = 'Your first name is required.';
            }

            if (!userData.last_name) {
                errors.last_name = 'Your surname is required.';
            }

            if (errors.email || errors.first_name || errors.last_name) {
                setUserErrors((prevState) => ({ ...prevState, ...errors }));

                if (throwErrors) throw errors;
            }
        },
        [],
    );

    const loadUser = useCallback(async () => {
        setLoading(true);
        setLoadingError(false);

        try {
            const userData = await api().users.retrieve('current');
            setUser(userData);
            validateUser({ userData });
        } catch (err) {
            setLoadingError(true);
        }

        setLoading(false);
    }, [validateUser]);

    const saveUser = async () => {
        setSaving(true);

        try {
            validateUser({ userData: user, throwErrors: true });
            const updatedUser = await api().users.update('current', user);
            setUser(updatedUser);
            history.push('/settings/your-profile/');
        } catch (err) {
            setUserErrors(err);
        }

        setSaving(false);
    };

    const setUserAttr = (attr, value) => {
        delete userErrors[attr];
        setUser((prevState) => ({ ...prevState, [attr]: value }));
    };

    useEffect(() => {
        if (isAmending && !isLoading) {
            validateUser({ userData: user });
        }
    }, [isAmending, isLoading, user, validateUser]);

    useLayoutEffect(() => {
        setDocumentTitle(['Details', 'Your Profile', 'Settings']);
        loadUser();

        return () => api().abortAll();
    }, [loadUser]);

    return (
        <UserSettingsDetail
            isLoading={isLoading}
            isSaving={isSaving}
            hasLoadingError={hasLoadingError}
            userErrors={userErrors}
            user={user}
            onChangeUserAttr={setUserAttr}
            onSaveUser={saveUser}
        />
    );
}
