import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { eventTagSources } from 'js/constants';
import { getEventTagSourceOrDefault } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import InfoButton from 'js/components/button/info-button';
import Checkbox from 'js/components/checkbox/checkbox';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Label from 'js/components/label/label';
import Panel from 'js/components/panel/panel';
import Statistic from 'js/components/statistic/statistic';
import Text from 'js/components/text/text';
import targetMetrics from '../enums/target-metrics.enum';
import OptimizeCampaignDrawer from '../../optimize-campaign-drawer/optimize-campaign-drawer';
import styles from '../optimize-section.module.scss';
import GuardrailsSelector from './guardrails-selector';

const TagHint = () => (
    <div className={styles['tag-hint']}>
        <Row alignItems="center" gutter="small" justifyContent="space-between">
            <Col span="auto">
                <FontAwesomeIcon
                    className={styles.exclamation}
                    icon={faExclamationCircle}
                />
            </Col>
            <Col>
                For 4D to get access to your campaign data, you will need to
                implement the 4D Tag in your DSP.
            </Col>
            <Col span={12} spanMd="auto">
                <Box padding={['small', 0, 0]} paddingMd="none">
                    <Link
                        to="/insights/tag/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button
                            suffix={<FontAwesomeIcon icon={faExternalLink} />}
                        >
                            Get Tags
                        </Button>
                    </Link>
                </Box>
            </Col>
        </Row>
    </div>
);

const NoDataSource = () => (
    <Box margin={['base', 0, 0]}>
        <Alert theme="empty">
            <p>No data sources have been connected to this context.</p>
        </Alert>
    </Box>
);

const Instruction = () => (
    <Box>
        <Text color={['gray', 'dark']}>
            For 4D to get access to your campaign data, you will need to
            implement the 4D Tag in your DSP.&nbsp;
            <Link to="/insights/tag/" target="_blank" rel="noopener noreferrer">
                <Text inline>Get Tags</Text>
                &nbsp;
                <Text inline>
                    <FontAwesomeIcon icon={faExternalLink} />
                </Text>
            </Link>
        </Text>
    </Box>
);

const Campaign = ({ isReadOnly, campaign, eventHierarchy = [], onRemove }) => {
    const {
        source: sourceId,
        campaign_id: campaignId,
        line_items: lineItems,
        campaign_name: campaignName,
    } = campaign;

    const { campaignLabel = 'Campaign', lineItemLabel = 'Line Item' } =
        eventTagSources.find(({ id }) => id === sourceId) || {};

    const eventHierarchyCampaign = eventHierarchy.find(
        (event) => event.campaign === campaignId,
    );

    const formattedCampaign = {
        id: eventHierarchyCampaign?.campaign || campaignId,
        name: eventHierarchyCampaign?.campaign_name || campaignName,
        lineItems: lineItems.map(
            ({ line_item_id: itemId, line_item_name: itemName }) => {
                const lineItem = eventHierarchy.find(
                    (event) => event.line_item === itemId,
                );

                return {
                    id: lineItem?.line_item || itemId,
                    name: lineItem?.line_item_name || itemName,
                };
            },
        ),
    };

    return (
        <Box margin={['base', 0, 0]}>
            <Panel bordered>
                <Box padding="base">
                    <Row gap="base">
                        <Statistic name="Platform">
                            {getEventTagSourceOrDefault(sourceId)}
                        </Statistic>

                        <Statistic name={`${campaignLabel}`}>
                            {formattedCampaign.name || formattedCampaign.id}
                        </Statistic>

                        <Statistic name={`${lineItemLabel}s`}>
                            {formattedCampaign.lineItems.length ? (
                                <>
                                    {`${
                                        formattedCampaign.lineItems.length
                                    } ${lineItemLabel}${
                                        formattedCampaign.lineItems.length > 1
                                            ? 's'
                                            : ''
                                    } `}
                                    <Box display="inline-block">
                                        <InfoButton>
                                            {formattedCampaign.lineItems.map(
                                                ({ id, name }) => (
                                                    <p key={id}>
                                                        {name && (
                                                            <strong>
                                                                {name}
                                                            </strong>
                                                        )}{' '}
                                                        {id}
                                                    </p>
                                                ),
                                            )}
                                        </InfoButton>
                                    </Box>
                                </>
                            ) : (
                                'All'
                            )}
                        </Statistic>
                        {!isReadOnly && (
                            <Col span="auto">
                                <Button
                                    size="small"
                                    theme="outline"
                                    onClick={onRemove}
                                >
                                    Remove
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Box>
            </Panel>
        </Box>
    );
};

const Settings = ({
    isReadOnly,
    isVideo,
    targetMetric,
    controls,
    campaigns,
    eventHierarchy,
    onChangeTarget,
    onChangeControls,
    onChangeCampaigns,
    onLoadEventHierarchy,
    topicGroups,
    logoGroups,
    language,
}) => {
    const [isCampaignDrawerVisible, setCampaignDrawerVisible] = useState(false);

    const targetMetricOptions = isVideo
        ? [
              {
                  label: 'Video Completion Rate',
                  value: targetMetrics.VCR,
              },
          ]
        : [
              {
                  label: 'CTR',
                  value: targetMetrics.CTR,
              },
              {
                  label: 'Viewability',
                  value: targetMetrics.VTR,
              },
          ];

    return (
        <>
            <Box margin={[0, 0, 'base']}>
                <Panel>
                    <Box padding="base">
                        <Checkbox
                            radio
                            label="Optimize"
                            options={targetMetricOptions}
                            selectedValues={targetMetric}
                            isReadOnly={isReadOnly}
                            onChange={onChangeTarget}
                        />
                    </Box>
                </Panel>
            </Box>

            <Box margin={[0, 0, 'base']}>
                <GuardrailsSelector
                    isVideo={isVideo}
                    isReadOnly={isReadOnly}
                    controls={controls}
                    topicGroups={topicGroups}
                    logoGroups={logoGroups}
                    language={language}
                    onChangeControls={onChangeControls}
                />
            </Box>

            <Panel>
                <Box padding="base">
                    <Label
                        label="Data Sources"
                        info="
                        Want to auto-optimize your contexts?
                        We’ll need access to your DSP advertising IDs to appropriately associate your context with the 4D Tag."
                    >
                        {campaigns.length > 0 ? (
                            <>
                                <Instruction />
                                {campaigns.map((campaign) => (
                                    <Campaign
                                        isReadOnly={isReadOnly}
                                        key={`${campaign.source}.${campaign.campaign_id}`}
                                        campaign={campaign}
                                        eventHierarchy={eventHierarchy || []}
                                        onRemove={() =>
                                            onChangeCampaigns(
                                                campaigns.filter(
                                                    ({
                                                        source,
                                                        campaign_id: id,
                                                    }) =>
                                                        source !==
                                                            campaign.source ||
                                                        id !==
                                                            campaign.campaign_id,
                                                ),
                                            )
                                        }
                                    />
                                ))}
                            </>
                        ) : (
                            <Box margin={['base', 0, 0]}>
                                <TagHint />
                                <NoDataSource />
                            </Box>
                        )}
                        {!isReadOnly && (
                            <Box display="inline-block" margin={['base', 0, 0]}>
                                <Button
                                    theme="outline"
                                    onClick={() =>
                                        setCampaignDrawerVisible(true)
                                    }
                                >
                                    Connect Data Source
                                </Button>
                            </Box>
                        )}
                    </Label>
                </Box>
            </Panel>

            {isCampaignDrawerVisible && (
                <OptimizeCampaignDrawer
                    isVideo={isVideo}
                    eventHierarchy={eventHierarchy?.filter(
                        (item) =>
                            !campaigns.some(
                                (campaign) =>
                                    campaign.source === item.source &&
                                    campaign.campaign_id === item.campaign,
                            ),
                    )}
                    onAddCampaign={(campaign) => {
                        onChangeCampaigns([...campaigns, campaign]);
                        setCampaignDrawerVisible(false);
                    }}
                    onClose={() => setCampaignDrawerVisible(false)}
                    onLoad={onLoadEventHierarchy}
                />
            )}
        </>
    );
};

export default Settings;
