import React from 'react';
import SectionTitle from 'js/components/section-title/section-title';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import NoResults from '../components/no-results';
import CampaignResult from '../components/campaign-result';

const CampaignResults = ({
    searchResults,
    onCampaignNameChange,
    onLineItemNameChange,
}) => {
    const sortedResults = searchResults.sort((a, b) => {
        if (b.campaignName.length === 0) {
            return -1;
        }
        if (a.campaignName.length === 0) {
            return 1;
        }
        return a.campaignName.localeCompare(b.campaignName);
    });
    return (
        <>
            <Box margin={['base', 0, 'small']}>
                <SectionTitle info="Campaign IDs are also known as Insertion Order IDs in Google Display & Video 360">
                    <Text size="large">Campaigns</Text>
                </SectionTitle>
            </Box>
            {searchResults.length === 0 ? (
                <NoResults />
            ) : (
                sortedResults.map((campaign) => (
                    <CampaignResult
                        key={campaign.campaignId}
                        campaign={campaign}
                        onCampaignNameChange={onCampaignNameChange}
                        onLineItemNameChange={onLineItemNameChange}
                    />
                ))
            )}
        </>
    );
};

export default CampaignResults;
