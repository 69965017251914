import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { createPopper } from '@popperjs/core';
import classNames from 'classnames/bind';
import Layer from 'js/components/layer/layer';
import styles from './popper.module.scss';

// TODO: Maybe completely rework this so that the popper does not need to be wrapped
//  around an element: instead we pass in an anchor DOM element.

const cx = classNames.bind(styles);

function Popper({
    children,
    content,
    placement,
    offset,
    flipVariations,
    showContent,
    theme,
    layerId,
    width,
}) {
    /*
        We use the `isInitialized` flag to render the layer with 0 opacity until
        it has been initialized/correctly placed by Popper.js so that we avoid a
        flash of unpositioned content.
    */
    const [isInitialized, setIsInitialized] = useState(false);
    const transitionNode = useRef();
    const anchor = useRef();
    const contentContainer = useRef();

    const wrapperClasses = cx({
        wrapper: true,
        empty: children.type === 'span' && !children.props.children,
    });

    const contentClasses = cx({
        content: true,
        invisible: !isInitialized,
        [`popper--theme-${theme}`]: theme,
        [`popper--width-${width}`]: typeof width === 'string',
    });

    useEffect(() => {
        if (showContent) {
            const modifiers = [
                {
                    name: 'offset',
                    options: {
                        offset,
                    },
                },
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: flipVariations ? null : [placement],
                        flipVariations,
                    },
                },
            ];

            if (width === 'same') {
                modifiers.push({
                    name: 'sameWidth',
                    enabled: true,
                    fn: ({ state }) => {
                        // eslint-disable-next-line no-param-reassign
                        state.styles.popper.width = `${state.rects.reference.width}px`;
                    },
                    phase: 'beforeWrite',
                    requires: ['computeStyles'],
                    effect: ({ state }) => {
                        // eslint-disable-next-line no-param-reassign
                        state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                    },
                });
            }

            createPopper(anchor.current, contentContainer.current, {
                onFirstUpdate: () => {
                    setIsInitialized(true);
                },
                placement,
                modifiers,
            });
        } else {
            setIsInitialized(false);
        }
    }, [showContent, placement, width, offset, flipVariations]);

    return (
        <>
            <span ref={anchor} className={wrapperClasses}>
                {children}
            </span>

            {showContent && (
                <CSSTransition
                    nodeRef={transitionNode}
                    classNames="fade"
                    timeout={300}
                    appear
                    in
                >
                    <Layer ref={transitionNode} layerId={layerId}>
                        <div
                            className={classNames(contentClasses)}
                            ref={contentContainer}
                            style={{
                                width: typeof width === 'number' ? width : null,
                            }}
                        >
                            {content}
                        </div>
                    </Layer>
                </CSSTransition>
            )}
        </>
    );
}

Popper.defaultProps = {
    content: undefined,
    placement: 'bottom',
    offset: [0, 0],
    flipVariations: true,
    showContent: false,
    theme: undefined,
    layerId: 'layer-portal',
    width: 'fluid',
};

Popper.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node,
    placement: PropTypes.oneOf([
        'auto',
        'auto-start',
        'auto-end',
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
    ]),
    offset: PropTypes.arrayOf(PropTypes.number),
    flipVariations: PropTypes.bool,
    showContent: PropTypes.bool,
    theme: PropTypes.oneOf(['tooltip']),
    layerId: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['fluid', 'fixed', 'same']),
    ]),
};

export default Popper;
