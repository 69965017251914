import React from 'react';
import PropTypes from 'prop-types';
import styles from './dropdown-menu.module.scss';

const DropdownScrollableList = ({ children }) => (
    <div className={styles.list}>
        <ul>{children}</ul>
    </div>
);

DropdownScrollableList.propTypes = {
    /**
     * The content of the DropdownScrollableList
     */
    children: PropTypes.node.isRequired,
};

export default DropdownScrollableList;
