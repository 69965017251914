const steps = {
    CONTEXT: 'Context',
    DATA_SOURCE: 'Data Source',
    DATES: 'Dates',
    NAME: 'Name',
    REPORT_TYPE: 'Report Type',
    SUMMARY: 'Summary',
};

export default steps;
