import React from 'react';
import { sortOptions } from 'js/constants';
import filterTypes from 'js/enums/filter-types.enum';
import { reloadPage } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import FilterDropdown from 'js/components/filter-dropdown/filter-dropdown';
import SectionTitle from 'js/components/section-title/section-title';
import { showToast } from 'js/components/toast/toast';
import AdvertiserList from './advertiser-list';
import FavoriteAdvertisers from './favorite-advertisers';

function AdvertiserListBody({
    errors,
    loading,
    readOnly,
    advertisers,
    ordering,
    togglingFavoriteError,
    onToggleFavoriteAdvertiser,
    onUpdateFilter,
    onDeleteAdvertiser,
}) {
    const advertiserList = advertisers;
    const checkIfErrorTypeExists = (errorType) =>
        Object.keys(errors).some((key) => key === errorType);
    const hasAdvertisersError = checkIfErrorTypeExists(
        'advertisersLoadingError',
    );
    const hasFavoriteAdvertisers = advertisers.some(
        (item) => item.is_favourite,
    );

    const titleButtons = [];
    if (!readOnly) {
        titleButtons.push(
            <Col span="auto">
                <Button theme="outline" to="/advertisers/new/">
                    Create Advertiser
                </Button>
            </Col>,
        );
    }
    titleButtons.push(
        <Col span="auto">
            <FilterDropdown
                name="Sort Order"
                prefix="Sort by"
                param={filterTypes.ORDERING}
                options={sortOptions}
                selectedValue={ordering}
                onFilterChange={onUpdateFilter}
            />
        </Col>,
    );

    if (Object.keys(togglingFavoriteError).length) {
        showToast(
            'There Was An Unexpected Error',
            <p>
                It was not possible to{' '}
                {togglingFavoriteError.isFavorite ? 'favorite' : 'unfavorite'}{' '}
                {togglingFavoriteError.title}. Please try again in a few
                moments.
            </p>,
            5,
            'danger',
        );
    }

    return (
        <Box padding={['base', 0]}>
            <Box padding={['base', 0]}>
                <Container>
                    <SectionTitle hasBar extension={titleButtons}>
                        Advertisers
                    </SectionTitle>
                </Container>

                {hasFavoriteAdvertisers && (
                    <FavoriteAdvertisers
                        advertisers={advertisers.filter(
                            (item) => item.is_favourite,
                        )}
                        onToggleFavorite={onToggleFavoriteAdvertiser}
                        onDelete={onDeleteAdvertiser}
                    />
                )}

                <AdvertiserList
                    advertisers={advertiserList}
                    readOnly={readOnly}
                    loading={loading}
                    hasAdvertisersError={hasAdvertisersError}
                    reloadPage={reloadPage}
                    onToggleFavorite={onToggleFavoriteAdvertiser}
                    onDelete={onDeleteAdvertiser}
                />
            </Box>
        </Box>
    );
}

export default AdvertiserListBody;
