import React, { useContext, useRef, useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import editorModes from 'js/enums/editor-modes.enum';
import { csvSplit, dedupe, RuleSet } from 'js/utils';
import Button from 'js/components/button/button';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import DropdownTitle from 'js/components/dropdown-menu/title';
import ContextDetailContext from '../../../contexts/context-detail.context';

const upload = (file, onError, onLoadStart, onLoadEnd, callback) => {
    const MAX_FILE_SIZE = 25 * 1024;
    const MAX_KEYWORD_COUNT = 250;
    const MAX_KEYWORD_LENGTH = 50;

    const isFileTypeValid =
        file.type === 'text/csv' ||
        (file.type === 'application/vnd.ms-excel' &&
            file.name.endsWith('.csv'));

    try {
        if (!isFileTypeValid) {
            throw Error('Unsupported file type');
        }
        if (file.size > MAX_FILE_SIZE) {
            throw Error('The file is too big');
        }
    } catch (error) {
        onError(error.message);
        return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadstart = (ev) => {
        if (onLoadStart) onLoadStart(ev);
    };
    reader.onloadend = (ev) => {
        const data = ev.target.result;
        let keywords = [];
        try {
            if (data === null) {
                throw Error('Unable to open the file');
            }
            keywords = dedupe(csvSplit(data));
            if (keywords.length <= 1) {
                throw Error('No keywords in the file');
            }
            if (keywords.length > MAX_KEYWORD_COUNT) {
                throw Error('Too many keywords in the file');
            }
            if (keywords.some((k) => k.length > MAX_KEYWORD_LENGTH)) {
                throw Error('Some keywords exceed the maximum length');
            }
        } catch (error) {
            onError(error.message);
            return;
        }
        if (onLoadEnd) onLoadEnd(ev);
        callback(keywords);
    };
};

function CsvUploadOptions({
    rule,
    setCallback,
    inputRef,
    onAddKeywords,
    onReplaceKeywords,
}) {
    return (
        <>
            <DropdownTitle>Upload CSV</DropdownTitle>

            <DropdownButton
                onClick={() => {
                    setCallback(() => onAddKeywords);
                    inputRef.current.click();
                }}
            >
                Add Keywords
            </DropdownButton>

            {rule.keywords.length > 0 && (
                <DropdownButton
                    onClick={() => {
                        setCallback(() => onReplaceKeywords);
                        inputRef.current.click();
                    }}
                >
                    Replace Keywords
                </DropdownButton>
            )}
        </>
    );
}

export default function RuleMenu({
    rule,
    editorMode,
    onToggleEditorMode,
    onLoadStart,
    onLoadEnd,
    onError,
}) {
    const {
        context: {
            current: { rules },
            setRules,
        },
    } = useContext(ContextDetailContext);
    const ruleSet = new RuleSet(rules);

    const [isOpen, setIsOpen] = useState(false);
    const [callback, setCallback] = useState(() => {});
    const inputRef = useRef(null);

    const isOnlyGroupOfAggregationType =
        rules.filter((r) => r.aggregation === rule.aggregation).length === 1;
    const canClear =
        isOnlyGroupOfAggregationType &&
        [...rule.keywords, ...rule.topics].length > 0;
    const canRemove = !isOnlyGroupOfAggregationType;

    const toggleEditorMode = () => {
        onToggleEditorMode();
        setIsOpen(false);
    };

    const addKeywords = (keywords) => {
        ruleSet.changeRule(rule.key, {
            addKeywords: keywords,
        });
        setRules(ruleSet.rules);
    };

    const replaceKeywords = async (keywords) => {
        ruleSet.changeRule(rule.key, {
            addKeywords: keywords,
            removeKeywords: rule.keywords,
        });
        setRules(ruleSet.rules);
    };

    const clearKeywordGroup = () => {
        ruleSet.changeRule(rule.key, {
            removeKeywords: rule.keywords,
            removeTopics: rule.topics,
        });
        setRules(ruleSet.rules);
    };

    const removeKeywordGroup = () => {
        ruleSet.removeRule(rule.key);
        setRules(ruleSet.rules);
    };

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                accept="text/csv"
                onChange={(ev) => {
                    upload(
                        ev.target.files[0],
                        onError,
                        onLoadStart,
                        onLoadEnd,
                        callback,
                    );
                    // eslint-disable-next-line no-param-reassign
                    ev.target.value = null;
                }}
                style={{ display: 'none' }}
            />

            <DropdownMenu
                width="fixed"
                placement="bottom-end"
                content={
                    <ul>
                        <DropdownButton onClick={toggleEditorMode}>
                            {editorMode === editorModes.RICH_UI
                                ? 'Bulk Edit'
                                : 'Rich UI'}{' '}
                            Keywords
                        </DropdownButton>

                        <DropdownDivider />

                        <CsvUploadOptions
                            rule={rule}
                            setCallback={setCallback}
                            inputRef={inputRef}
                            onAddKeywords={addKeywords}
                            onReplaceKeywords={replaceKeywords}
                        />

                        {canClear && (
                            <>
                                <DropdownDivider />

                                <DropdownButton onClick={clearKeywordGroup}>
                                    Clear Group
                                </DropdownButton>
                            </>
                        )}

                        {canRemove && (
                            <>
                                <DropdownDivider />

                                <DropdownButton onClick={removeKeywordGroup}>
                                    Remove Group
                                </DropdownButton>
                            </>
                        )}
                    </ul>
                }
                showMenu={isOpen}
                onHide={() => setIsOpen(false)}
            >
                <Button
                    size="small"
                    square
                    theme="outline"
                    onClick={() => setIsOpen(!isOpen)}
                    active={isOpen}
                >
                    <FontAwesomeIcon icon={faEllipsisV} />
                </Button>
            </DropdownMenu>
        </>
    );
}
