import React, { useContext, useEffect, useState } from 'react';
import { csvSplit, dedupe, RuleSet } from 'js/utils';
import Box from 'js/components/box/box';
import Form from 'js/components/form/form';
import TextArea from 'js/components/textarea/textarea';
import Text from 'js/components/text/text';
import ContextDetailContext from '../../../contexts/context-detail.context';
import styles from './rules-section.module.scss';

export default function RuleKeywordTextForm({
    rule,
    focus,
    onToggleEditorMode,
}) {
    const {
        context: {
            current: { rules },
            setRules,
        },
        page: { isReadOnly },
    } = useContext(ContextDetailContext);
    const { keywords } = rule;

    const [value, setValue] = useState(keywords.join('\n'));
    const [typingTimeout, setTypingTimeout] = useState(null);

    const commit = async (text) => {
        clearTimeout(typingTimeout);
        const formKeywords = dedupe(csvSplit(text));
        const addKeywords = formKeywords.filter((kw) => !keywords.includes(kw));
        const removeKeywords = keywords.filter(
            (kw) => !formKeywords.includes(kw),
        );
        if (addKeywords.length || removeKeywords.length) {
            const ruleSet = new RuleSet(rules);
            ruleSet.changeRule(rule.key, { addKeywords, removeKeywords });
            await setRules(ruleSet.rules);
        }
    };

    const handleChange = (event) => {
        const text = event.target.value;
        setValue(text);
        setTypingTimeout(() => setTimeout(() => commit(text), 1500));
    };

    useEffect(() => () => clearTimeout(typingTimeout), [typingTimeout]);

    return (
        <Box margin={['base', 0]}>
            <Text size="large">
                Keywords (
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span className={styles.link} onClick={onToggleEditorMode}>
                    Switch To Rich UI Mode
                </span>
                )
            </Text>

            <Form>
                <Box margin={['smaller', 0, 0]}>
                    <TextArea
                        value={value}
                        rows={8}
                        focusOnShow={focus}
                        onChange={handleChange}
                        onBlur={() => commit(value)}
                        readOnly={isReadOnly}
                    />

                    <Box margin={['small', 0, 0]}>
                        <Text size="base" color={['gray', 'dark']}>
                            Keywords in a group can be separated by a new line
                            or comma.
                        </Text>
                    </Box>
                </Box>
            </Form>
        </Box>
    );
}
