import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'js/components/button/button';

function SwitchButton({ size, active, disabled, children, onChange }) {
    return (
        <Button
            size={size}
            theme="switch"
            prefix={
                <FontAwesomeIcon icon={active ? faCheckCircle : faCircle} />
            }
            active={active}
            disabled={disabled}
            onClick={onChange}
        >
            {children}
        </Button>
    );
}

SwitchButton.defaultProps = {
    size: 'base',
    active: false,
};

SwitchButton.propTypes = {
    size: PropTypes.oneOf(['base', 'small', 'large', 'smaller']),
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SwitchButton;
