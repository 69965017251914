import React from 'react';
import Alert from 'js/components/alert/alert';
import CardReport from 'js/components/card/card-report';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import templateMethodMap from 'js/enums/templateMethodMap.enum';

export default function RecentReports({
    reports,
    onToggleFavorite,
    onDelete,
    onCancelScheduling,
}) {
    if (!reports.length) {
        return (
            <Alert theme="empty" padding="large">
                <p>You currently have no reports.</p>
            </Alert>
        );
    }

    return (
        <Row gap="base">
            {reports.map((template) => (
                <Col
                    key={template.id}
                    span={12}
                    spanSm={12}
                    spanMd={6}
                    spanLg={4}
                >
                    <CardReport
                        report={template}
                        onToggleFavorite={() =>
                            onToggleFavorite(
                                template.type,
                                template.id,
                                !template.is_favourite,
                            )
                        }
                        onDelete={() =>
                            onDelete(
                                templateMethodMap[template.type],
                                template.id,
                            )
                        }
                        onCancelSchedule={() => onCancelScheduling(template)}
                    />
                </Col>
            ))}
        </Row>
    );
}
