import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RuleSet, api, setDocumentTitle } from 'js/utils';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import { showToast } from 'js/components/toast/toast';
import TopBar from 'js/components/top-bar/top-bar';
import ReportEventLogDetail from './report-event-log-detail';

export default function ReportInsightsDetailPage() {
    const { templateId, reportId } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [hasDeletingError, setHasDeletingError] = useState(false);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [hasSnapshotError, setHasSnapshotError] = useState(false);
    const [reports, setReports] = useState([]);
    const [report, setReport] = useState(null);
    const [template, setTemplate] = useState({});
    const [context, setContext] = useState({});

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb
                key="insights"
                to="/insights/"
                label="Insights"
                isCollapsible
            />,
        ];
        if (hasLoadingError) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (loading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else {
            breadcrumbs.push(
                <Crumb
                    key="advertiser"
                    label={template.advertiser_name}
                    to={`/insights/${template.advertiser}/`}
                    isCollapsible
                />,
                <Crumb key="report" label={template.name} />,
            );
        }

        return breadcrumbs;
    };

    const switchReport = async (id) => {
        setLoading(true);
        setHasLoadingError(false);

        try {
            const reportData = await api().eventLogReports.retrieve(id, {
                verbose: true,
            });

            setReport(reportData);
        } catch (errors) {
            setHasLoadingError(true);
        } finally {
            setLoading(false);
        }
    };

    const loadData = async () => {
        setLoading(true);
        setHasLoadingError(false);

        try {
            const [{ results: reportsData }, templateData] = await Promise.all([
                api().eventLogReports.list({
                    template: templateId,
                    ordering: '-end_date',
                }),
                api().eventLogReportTemplates.retrieve(templateId, {
                    verbose: true,
                }),
            ]);

            const { context: contextId } = templateData;
            const [contextData, reportData] = await Promise.all([
                api().contexts.retrieve(contextId, { verbose: true }),
                reportsData.length > 0
                    ? api().eventLogReports.retrieve(
                          reportId || reportsData[0].id,
                          { verbose: true },
                      )
                    : null,
            ]);
            const { rules } = contextData;
            const ruleSet = new RuleSet(rules);
            contextData.rules = ruleSet.rules;

            setReports(reportsData);
            setTemplate(templateData);
            setContext(contextData);
            setReport(reportData);
        } catch (errors) {
            setHasLoadingError(true);
        } finally {
            setLoading(false);
        }
    };

    const createSnapshot = async (dateRange) => {
        setHasSnapshotError(false);
        try {
            const snapshotTemplate = await api()
                .eventLogReportTemplates.action('refresh')
                .create({ id: templateId, ...dateRange });

            setTemplate(snapshotTemplate);
        } catch (errors) {
            setHasSnapshotError(true);
            throw errors;
        }
    };

    const deleteTemplate = async () => {
        setHasDeletingError(false);
        try {
            await api().eventLogReportTemplates.destroy(template.id);
            await history.push(`/insights/${template.advertiser}/`);
        } catch (errors) {
            setHasDeletingError(true);
            throw errors;
        }
    };

    const toggleFavorite = async () => {
        const isFavorite = !template.is_favourite;
        try {
            await api().eventLogReportTemplates.favorite(
                template.id,
                isFavorite,
            );
            setTemplate({ ...template, is_favourite: isFavorite });
        } catch (err) {
            showToast(
                'There Was An Unexpected Error',
                <p>
                    It was not possible to{' '}
                    {isFavorite ? 'favorite' : 'unfavorite'} {template.name}.
                    Please try again in a few moments.
                </p>,
                10,
                'danger',
            );
        }
    };

    useLayoutEffect(() => {
        loadData();
        return () => api().abortAll();
    }, [templateId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (template.name) {
            setDocumentTitle([template.name, 'Insights']);
        } else {
            setDocumentTitle(['Insights']);
        }
    }, [template.name]);

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
        >
            <ReportEventLogDetail
                report={report}
                reports={reports}
                template={template}
                context={context}
                loading={loading}
                hasLoadingError={hasLoadingError}
                hasDeletingError={hasDeletingError}
                hasSnapshotError={hasSnapshotError}
                onDelete={deleteTemplate}
                onSnapshot={createSnapshot}
                onToggleFavorite={toggleFavorite}
                onReload={loadData}
                onSwitchReport={switchReport}
            />
        </Layout>
    );
}
