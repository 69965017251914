import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withCampaignList } from 'js/hocs';
import { setDocumentTitle } from 'js/utils';
import ActionBar from 'js/components/action-bar/action-bar';
import Button from 'js/components/button/button';
import Crumb from 'js/components/breadcrumbs/crumb';
import FavoritesButton from 'js/components/button/favorites-button';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import CampaignListBody from './components/campaign-list-body';

function CampaignListPage({
    loading,
    errors,
    readOnly,
    campaigns,
    advertiser,
    ordering,
    togglingFavoriteError,
    onToggleFavoriteAdvertiser,
    onToggleFavoriteCampaign,
    onUpdateFilter,
    onDeleteCampaign,
}) {
    const { advertiserId } = useParams();

    const getBreadcrumbs = () => {
        const breadcrumbs = [<Crumb key="home" to="/" label="4D" />];

        breadcrumbs.push(
            <Crumb
                key="advertisers"
                to="/context/"
                label="Advertisers"
                isCollapsible
            />,
            <Crumb
                key="advertiser"
                isLoading={loading && !advertiser.name}
                hasError={Object.keys(errors).length > 0}
                label={advertiser.name}
            />,
        );

        return breadcrumbs;
    };

    useEffect(() => {
        if (advertiser.name) {
            setDocumentTitle([advertiser.name, 'Contexts']);
        }
    }, [advertiser]);

    return (
        <Layout
            sidebar={<Navigation />}
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
        >
            <ActionBar>
                <FavoritesButton
                    key="favorite"
                    isFavorite={advertiser.is_favourite}
                    isLoading={loading || advertiser.isFavoriteLoading}
                    onToggleFavorite={onToggleFavoriteAdvertiser}
                />

                {!readOnly && (
                    <Button
                        key="edit"
                        theme="outline"
                        to={`/advertisers/${advertiserId}/`}
                    >
                        Edit
                    </Button>
                )}
            </ActionBar>

            <CampaignListBody
                loading={loading}
                errors={errors}
                readOnly={readOnly}
                campaigns={campaigns}
                advertiser={advertiser}
                ordering={ordering}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavoriteCampaign={onToggleFavoriteCampaign}
                onUpdateFilter={onUpdateFilter}
                onDeleteCampaign={onDeleteCampaign}
            />
        </Layout>
    );
}

export default withCampaignList(CampaignListPage);
