import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    faCheckCircle,
    faPauseCircle,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { SEARCH_DEBOUNCE_TIMEOUT, SEARCH_MIN_LENGTH } from 'js/constants';
import { useClickOutside } from 'js/hooks';
import { AbortError, api, dedupe, getSearch, getJwtPayload } from 'js/utils';
import searchTypes from 'js/enums/search-types.enum';
import serviceTypes from 'js/enums/service-types.enum';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Form from 'js/components/form/form';
import Input from 'js/components/input/input';
import Popper from 'js/components/popper/popper';
import SearchResults from './search-results';
import styles from './search-bar.module.scss';

const cx = classNames.bind(styles);

const resultsTemplate = [
    {
        id: searchTypes.ADVERTISERS,
        title: 'Advertisers',
        isExpanded: false,
        items: [],
    },
    {
        id: searchTypes.CAMPAIGNS,
        title: 'Campaigns',
        isExpanded: false,
        items: [],
    },
    {
        id: searchTypes.DISPLAY_CONTEXTS,
        title: 'Display Contexts',
        isExpanded: false,
        items: [],
    },
    {
        id: searchTypes.VIDEO_CONTEXTS,
        title: 'Video Contexts',
        isExpanded: false,
        items: [],
    },
    {
        id: searchTypes.DEALS,
        title: 'Deal IDs',
        isExpanded: false,
        items: [],
    },
    {
        id: searchTypes.DISPLAY_REPORTS,
        title: 'Display Reports',
        isExpanded: false,
        items: [],
    },
    {
        id: searchTypes.VIDEO_REPORTS,
        title: 'Video Reports',
        isExpanded: false,
        items: [],
    },
];

const buildDealName = (id, providerType) => {
    const providerNames = {
        xandr: 'Xandr',
        iristv: 'IrisTV',
        videobeet: 'Xandr (EMEA)',
    };
    const providerName = providerNames[providerType] || providerType;

    return providerName ? `${providerName} ${id}` : id;
};

const DealStatusIcon = ({ isActive }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const iconClasses = cx({
        icon: true,
        active: isActive,
    });

    return (
        <Popper
            content={
                <Box padding="base">
                    This deal is {isActive ? 'active' : 'paused'}
                </Box>
            }
            theme="tooltip"
            placement="right"
            // For some reason the tooltip doesn't take into account the icon width,
            // so the offset here is the icon width + padding
            offset={[0, 22]}
            showContent={showTooltip}
        >
            <div
                className={iconClasses}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <FontAwesomeIcon
                    icon={isActive ? faCheckCircle : faPauseCircle}
                />
            </div>
        </Popper>
    );
};

let searchDebounce;

function SearchBar({ align, placeholder, hasOverlap, history }) {
    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );
    const querySearch = getSearch();
    const [searchTerm, setSearchTerm] = useState(querySearch);
    const [isActive, setActive] = useState(querySearch.length > 0);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const containerClasses = cx({
        container: true,
        [`container--align-${align}`]: true,
    });
    const formClasses = cx({
        form: true,
        [`form--align-${align}`]: true,
    });
    const inputClasses = cx({
        input: true,
        'input--expanded': isActive,
        'input--with-overlap': hasOverlap,
    });
    const buttonClasses = cx({
        button: true,
        'button--hidden': isActive,
    });

    const containerRef = useRef();
    const formRef = useRef();

    const formatResults = (
        advertisers,
        campaigns,
        displayContexts,
        videoContexts,
        deals,
        displayReports,
        videoReports,
    ) => {
        const entitiesMap = {
            [searchTypes.ADVERTISERS]: advertisers,
            [searchTypes.CAMPAIGNS]: campaigns,
            [searchTypes.DISPLAY_CONTEXTS]: displayContexts,
            [searchTypes.VIDEO_CONTEXTS]: videoContexts,
            [searchTypes.DEALS]: deals,
            [searchTypes.DISPLAY_REPORTS]: displayReports,
            [searchTypes.VIDEO_REPORTS]: videoReports,
        };

        return resultsTemplate.map((entity) => ({
            ...entity,
            items: entitiesMap[entity.id],
        }));
    };

    const loadSearchData = async () => {
        const callbacks = [];
        const { signal } = api().controllers.loadSearchData;

        // accounts
        callbacks.push(api().accounts.list({ complete: true }, { signal }));

        // advertisers
        callbacks.push(
            api().advertisers.list(
                { search: searchTerm, complete: true },
                { signal },
            ),
        );

        // campaigns
        callbacks.push(
            api().contextGroups.list(
                {
                    search: searchTerm,
                    is_public: false,
                    verbose: true,
                    complete: true,
                },
                { signal },
            ),
        );

        // contexts
        callbacks.push(
            api().contexts.list(
                {
                    is_public: false,
                    search: searchTerm,
                    verbose: true,
                    complete: true,
                },
                { signal },
            ),
        );

        // deals
        callbacks.push(
            api().deals.list(
                { search: searchTerm, complete: true },
                { signal },
            ),
        );

        // display reports
        callbacks.push(
            insightsService
                ? api().optimizeReportTemplates.list(
                      { search: searchTerm, verbose: true, complete: true },
                      { signal },
                  )
                : { results: [] },
        );

        // video reports
        callbacks.push(
            insightsService
                ? api().videoReportTemplates.list(
                      { search: searchTerm, verbose: true, complete: true },
                      { signal },
                  )
                : { results: [] },
        );

        const [
            { results: accountsData },
            { results: advertisers },
            { results: campaigns },
            { results: contexts },
            { results: dealsData },
            { results: displayReports },
            { results: videoReports },
        ] = await Promise.all(callbacks);

        // TODO: Drop support for 'rules' in deals (API side), make the Deal model directly include 'context' and the
        // serializer the derived 'account', 'advertiser_name', 'group_name' and 'context_name' fields; then simplify
        // the logic below.
        // Otherwise (or in addition) make querying for the whole contexts dataset in verbose mode more efficient.
        const dealContextIds = dedupe(
            dealsData
                .map((deal) => deal.rules[0]?.contexts[0])
                .filter((contextId) => contextId),
        );
        const dealContextCallbacks = dealContextIds.map((contextId) =>
            api().contexts.retrieve(
                contextId,
                { verbose: true, complete: true },
                { signal },
            ),
        );
        const dealContexts = await Promise.all(dealContextCallbacks);
        const deals = dealsData
            .map((deal) => {
                const context = dealContexts.find(
                    ({ id: contextId }) =>
                        deal.rules[0]?.contexts[0] === contextId,
                );
                if (!context) return null;

                return {
                    ...deal,
                    name: buildDealName(deal.deal_id, deal.deal_provider_type),
                    account: context.account,
                    contextId: context.id,
                    context_name: context.name,
                    group_name: context.group_name,
                    advertiser_name: context.advertiser_name,
                    icon: <DealStatusIcon isActive={deal.is_active} />,
                };
            })
            .filter((deal) => deal);

        setAccounts(accountsData);
        setResults(
            formatResults(
                advertisers,
                campaigns.filter((i) => i.account),
                contexts.filter((i) => i.account && !i.is_video),
                contexts.filter((i) => i.account && i.is_video),
                deals,
                displayReports,
                videoReports,
            ),
        );
        setShowResults(true);
        setLoading(false);
    };

    const onPreSearch = async (term) => {
        if (term.length < SEARCH_MIN_LENGTH) {
            setErrors({
                ...errors,
                shortSearch:
                    'Your search is too short, please add more characters.',
            });
            setLoading(false);
        }
    };

    const onSearch = async (term) => {
        if (term.length >= SEARCH_MIN_LENGTH) {
            try {
                await loadSearchData();
            } catch (err) {
                if (err instanceof AbortError) return;

                setErrors({
                    ...errors,
                    searchError: 'Failed to load search results',
                });
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const expandGroup = (groupType) => {
        setResults((prevState) =>
            prevState.map((group) => {
                if (groupType === group.id) {
                    return {
                        ...group,
                        isExpanded: true,
                    };
                }

                return group;
            }),
        );
    };

    const clear = () => {
        setSearchTerm('');
        setErrors({});
    };

    const close = () => {
        setShowResults(false);
        setSearchTerm('');
        setErrors({});
        setActive(false);
    };

    useClickOutside([formRef, containerRef], () => {
        setSearchTerm('');
        setActive(false);
    });

    useEffect(() => {
        setErrors({});

        if (searchTerm) {
            setLoading(true);
        }

        searchDebounce = setTimeout(
            async () => {
                const params = new URLSearchParams(history.location.search);

                if (searchTerm) {
                    params.set('search', searchTerm);
                } else {
                    params.delete('search');
                }

                await history.replace({
                    ...history.location,
                    search: params.toString(),
                });

                if (searchTerm) {
                    await onPreSearch(searchTerm);
                    await onSearch(searchTerm);
                } else {
                    setShowResults(false);
                    setLoading(false);
                }
            },
            searchTerm ? SEARCH_DEBOUNCE_TIMEOUT : 0,
        );

        return () => {
            api().controllers.loadSearchData.abort();
            clearTimeout(searchDebounce);
        };
    }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={containerClasses}>
            <SearchResults
                ref={containerRef}
                results={results}
                accounts={accounts}
                isLoading={isLoading}
                errors={errors}
                showResults={results.length > 0 && showResults}
                onExpandGroup={expandGroup}
                onReloadResults={() => onSearch(searchTerm)}
                onClose={close}
            >
                <Form ref={formRef} className={formClasses}>
                    <Button
                        className={buttonClasses}
                        theme="outline"
                        square
                        onClick={() => setActive(true)}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>

                    <div className={inputClasses}>
                        <Input
                            focusOnShow={isActive}
                            value={searchTerm}
                            placeholder={placeholder}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClear={clear}
                        />
                    </div>
                </Form>
            </SearchResults>
        </div>
    );
}

SearchBar.defaultProps = {
    align: 'right',
    placeholder: 'Search',
    hasOverlap: true,
};

SearchBar.propTypes = {
    align: PropTypes.oneOf(['left', 'right']),
    placeholder: PropTypes.string,
    hasOverlap: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

export default withRouter(SearchBar);
