import React from 'react';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import Container from 'js/components/container/container';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Panel from 'js/components/panel/panel';
import SearchAndEdit from './search-and-edit/search-and-edit';
import BulkUploadSection from './bulk-upload-section/bulk-upload-section';

const Wrapper = ({ children }) => (
    <Section>
        <Container>
            <Box padding={['large', 0]}>{children}</Box>
        </Container>
    </Section>
);

const RenameDataSources = ({
    onUploadCampaignsFromTemplate,
    onUploadLineItemsFromTemplate,
    dataSources,
    unAssociatedLineItems,
    onCampaignNameChange,
    onLineItemNameChange,
    isLoading,
    hasErrors,
    onRetry,
}) => {
    if (isLoading) {
        return (
            <Wrapper>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Wrapper>
        );
    }

    if (hasErrors) {
        return (
            <Wrapper>
                <Alert
                    theme="danger"
                    title="There Was An Error Loading The Tag"
                    actionItems={<Button onClick={onRetry}>Retry</Button>}
                >
                    <p>
                        Hopefully it is only a temporary issue. Please try again
                        in a few moments.
                    </p>
                </Alert>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Container size="medium">
                <Panel bordered>
                    <Box padding="large">
                        <Box margin={[0, 0, 'larger', 0]}>
                            <BulkUploadSection
                                onUploadCampaignsFromTemplate={
                                    onUploadCampaignsFromTemplate
                                }
                                onUploadLineItemsFromTemplate={
                                    onUploadLineItemsFromTemplate
                                }
                            />
                        </Box>
                        <SearchAndEdit
                            dataSources={dataSources}
                            unAssociatedLineItems={unAssociatedLineItems}
                            onCampaignNameChange={onCampaignNameChange}
                            onLineItemNameChange={onLineItemNameChange}
                        />
                    </Box>
                </Panel>
            </Container>
        </Wrapper>
    );
};

export default RenameDataSources;
