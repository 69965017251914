import React from 'react';
import { Page } from '@react-pdf/renderer';
import { getNumber } from 'js/components/number/number';
import Aside from '../components/aside/aside';
import AnnotatedChart from '../components/annotated-chart/annotated-chart';
import Logos from '../components/logos/logos';
import NoDataAlert from '../components/no-data-alert/no-data-alert';
import PageContent from '../components/page-content/page-content';
import Section from '../components/section/section';
import { color } from '../components/tokens.styles';
import styles from '../report.styles';
import { pageSizes } from '../constants';
import { getCtr, getDisplayReportRecommendations, getViewRate } from '../utils';

export default function DisplaySummaryPage({
    orientation,
    logo4D,
    brandLogo,
    charts,
    report,
}) {
    const isPortrait = orientation === 'portrait';
    const pageClasses = [
        styles.page,
        isPortrait ? styles.pagePortrait : styles.pageLandscape,
    ];
    if (logo4D || brandLogo) {
        pageClasses.push(isPortrait ? null : styles.pageLandscapeWithLogo);
    }

    const hasImpressions = report.impressions > 0;
    const hasClicks = report.clicks > 0;
    const hasViewability = getViewRate(report) > 0;

    const recommendations = getDisplayReportRecommendations(report);

    const annotations = {
        IMPRESSIONS: {
            title: 'Impressions',
            color: color.purple.base,
            value: getNumber({
                value: report.impressions,
                maximumFractionDigits: 0,
            }),
            description:
                'The number of times an advert appeared on a consumer’s screen.',
        },
        CLICKS: {
            title: 'Clicks',
            color: color.aqua.darker,
            value: getNumber({
                value: report.clicks,
                maximumFractionDigits: 0,
            }),
            description:
                'The number of times an advert was clicked by a consumer.',
        },
        CTR: {
            title: 'CTR',
            color: color.aqua.dark,
            value: getNumber({
                value: getCtr(report),
                isPercentage: true,
                maximumFractionDigits: 2,
            }),
            description:
                'The ratio of consumers who click on an ad, to the number of total consumers who view an ad. CTR is calculated as (Clicks/Impressions).',
        },
        VIEW_RATE: {
            title: 'View Rate',
            color: color.blue.base,
            value: getNumber({
                value: getViewRate(report),
                isPercentage: true,
                maximumFractionDigits: 2,
            }),
            description:
                'Measures the number of impressions that were viewable compared with the total number of impressions.',
        },
        VIEWABLE_IMPRESSIONS: {
            title: 'Viewable Impressions',
            color: color.aqua.light,
            value: getNumber({
                value: report.in_views,
                maximumFractionDigits: 0,
            }),
            description:
                'A measure of whether a given advert was seen by an actual consumer.',
        },
    };

    return (
        <Page
            size={isPortrait ? pageSizes.portrait : pageSizes.landscape}
            orientation={orientation}
            style={pageClasses}
        >
            <Aside
                orientation={orientation}
                title="Summary"
                hasLogo={Boolean(logo4D || brandLogo)}
                recommendations={recommendations}
            />
            <PageContent orientation={orientation}>
                {hasImpressions ? (
                    <>
                        <Section hasBorderBottom={hasClicks || hasViewability}>
                            <AnnotatedChart
                                orientation={orientation}
                                annotations={
                                    hasClicks
                                        ? [
                                              annotations.IMPRESSIONS,
                                              annotations.CLICKS,
                                          ]
                                        : [annotations.IMPRESSIONS]
                                }
                                chart={charts.IMPRESSIONS}
                            />
                        </Section>
                        {hasClicks && (
                            <Section hasBorderBottom={hasViewability}>
                                <AnnotatedChart
                                    orientation={orientation}
                                    annotations={[annotations.CTR]}
                                    chart={charts.CTR}
                                />
                            </Section>
                        )}
                        {hasViewability && (
                            <Section>
                                <AnnotatedChart
                                    orientation={orientation}
                                    annotations={[
                                        annotations.VIEW_RATE,
                                        annotations.VIEWABLE_IMPRESSIONS,
                                    ]}
                                    chart={charts.VIEWABILITY}
                                />
                            </Section>
                        )}
                    </>
                ) : (
                    <NoDataAlert />
                )}
            </PageContent>
            <Logos
                orientation={orientation}
                logo4D={logo4D}
                brandLogo={brandLogo}
            />
        </Page>
    );
}
