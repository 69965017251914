import { percentage } from '../mixins.styles';
import { spacing } from '../tokens.styles';

const gridColumns = 12;

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    'row--reverse': {
        flexWrap: 'wrap-reverse',
    },

    'overflow--hidden': {
        overflow: 'hidden',
    },

    col: {
        position: 'relative',
        width: '100%',
        minHeight: 1,
    },
};

styles[`col--align-self-flex-start`] = {
    alignSelf: 'flex-start',
};

styles[`col--align-self-center`] = {
    alignSelf: 'center',
};

styles[`col--align-self-flex-end`] = {
    alignSelf: 'flex-end',
};

styles[`row--align-items-flex-start`] = {
    alignItems: 'flex-start',
};

styles[`row--align-items-center`] = {
    alignItems: 'center',
};

styles[`row--align-items-flex-end`] = {
    alignItems: 'flex-end',
};

styles[`row--align-items-stretch`] = {
    alignItems: 'stretch',
};

styles[`row--justify-content-flex-start`] = {
    justifyContent: 'flex-start',
};

styles[`row--justify-content-flex-end`] = {
    justifyContent: 'flex-end',
};

styles[`row--justify-content-center`] = {
    justifyContent: 'center',
};

styles[`row--justify-content-space-between`] = {
    justifyContent: 'space-between',
};

styles[`row--justify-content-space-around`] = {
    justifyContent: 'space-around',
};

styles[`row--justify-content-start`] = {
    justifyContent: 'space-evenly',
};

Object.entries(spacing).forEach(([gutterSize]) => {
    styles[`row--gutter-${gutterSize}`] = {
        marginRight: 0 - spacing[gutterSize] * 0.5,
        marginLeft: 0 - spacing[gutterSize] * 0.5,
    };

    styles[`col--gutter-${gutterSize}`] = {
        paddingRight: spacing[gutterSize] * 0.5,
        paddingLeft: spacing[gutterSize] * 0.5,
    };
});

styles[`col--fluid`] = {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '100%',
};

styles[`col--auto`] = {
    flexGrow: 0,
    width: 'auto',
};

styles[`col--equal`] = {
    flexGrow: 1,
    flexBasis: 0,
    width: 0,
    maxWidth: '100%',
};

for (let i = 1; i <= gridColumns; i += 1) {
    styles[`col--${i}`] = {
        flexShrink: 0,
        flexGrow: 1,
        flexBasis: `${percentage(i, gridColumns)}`,
        maxWidth: `${percentage(i, gridColumns)}`,
    };
}

for (let i = 0; i <= gridColumns - 1; i += 1) {
    if (i !== 0) {
        const num = i / gridColumns;

        styles[`col--offset-${i}`] = {
            marginLeft: num === 0 ? 0 : percentage(i, gridColumns),
        };
    }
}

styles[`col--order-first`] = {
    order: -1,
};

styles[`col--order-last`] = {
    order: gridColumns + 1,
};

for (let i = 0; i <= gridColumns; i += 1) {
    styles[`col--order-${i}`] = {
        order: i,
    };
}

export default styles;
