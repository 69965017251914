import React from 'react';
import styles from './trend-chart.module.scss';

const ColoredLine = ({ color, isSkinny }) => (
    <div
        className={styles.coloredLine}
        style={{
            height: isSkinny ? 2 : 4,
            backgroundColor: color,
        }}
    />
);

export default ColoredLine;
