import React from 'react';
import { reloadPage } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Panel from 'js/components/panel/panel';
import Row from 'js/components/grid/row';
import UserForm from './user-form';

const UserDetail = ({
    loading,
    user,
    isNew,
    onSaveUser,
    hasLoadingError,
    hasSavingError,
    userErrors,
    onSetEmail,
    onSetFirstName,
    onSetLastName,
    setPermissions,
    isReadOnly,
}) => {
    const getErrors = () => {
        const action = isNew ? 'create' : 'update';

        if (
            userErrors.email ||
            userErrors.first_name ||
            userErrors.last_name ||
            userErrors.is_admin ||
            userErrors.roles
        ) {
            return (
                <Box margin={['large', 0]}>
                    <Alert theme="danger">
                        <p>
                            You can&apos;t {action} the user at the moment
                            because there are problems that have been
                            highlighted in the form above.
                        </p>
                    </Alert>
                </Box>
            );
        }
        return (
            <Box margin={['large', 0]}>
                <Alert theme="danger">
                    <p>
                        You were unable to {action} the user because there was
                        an unexpected error. Please try again in a few moments.
                    </p>
                </Alert>
            </Box>
        );
    };

    return (
        <Box padding={['large', 0]}>
            <Container size="small">
                {hasLoadingError && (
                    <Alert
                        theme="danger"
                        title="There was an error while loading the user"
                        actionItems={
                            <Button onClick={reloadPage}>
                                Retry Loading User
                            </Button>
                        }
                    >
                        <p>
                            Hopefully it is only a temporary issue. Please try
                            loading the page again in a few moments.
                        </p>
                    </Alert>
                )}
                {!hasLoadingError && !loading && (
                    <Form>
                        <Panel bordered>
                            <Box padding="base">
                                <UserForm
                                    isReadOnly={isReadOnly}
                                    user={user}
                                    userErrors={userErrors}
                                    onSetEmail={onSetEmail}
                                    onSetFirstName={onSetFirstName}
                                    onSetLastName={onSetLastName}
                                    setPermissions={setPermissions}
                                />
                                {hasSavingError && getErrors()}
                                {!isReadOnly && (
                                    <Row justifyContent="flex-end">
                                        <Col span="auto">
                                            <Button
                                                type="submit"
                                                disabled={
                                                    loading ||
                                                    !!userErrors.email ||
                                                    !!userErrors.first_name ||
                                                    !!userErrors.last_name
                                                }
                                                onClick={onSaveUser}
                                            >
                                                {isNew
                                                    ? 'Create User'
                                                    : 'Save User'}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Box>
                        </Panel>
                    </Form>
                )}
            </Container>
        </Box>
    );
};

export default UserDetail;
