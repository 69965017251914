import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Box from 'js/components/box/box';
import Container from 'js/components/container/container';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Section from 'js/components/section/section';

export const Divider = () => null;
function ActionBar({ children }) {
    return (
        <Section hasBorderBottom>
            <Container>
                <Box padding={['small', 0]}>
                    <Row gutter="small" gap="small">
                        {Children.map(children, (child, index) => {
                            if (child) {
                                return (
                                    <Col
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        span={
                                            child?.type === Divider
                                                ? null
                                                : 'auto'
                                        }
                                    >
                                        {child}
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </Box>
            </Container>
        </Section>
    );
}

ActionBar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ActionBar;
