import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Button from 'js/components/button/button';
import styles from './dropdown-menu.module.scss';

const cx = classNames.bind(styles);

function DropdownButton({
    active,
    children,
    disabled,
    prefix,
    size,
    suffix,
    theme,
    to,
    onClick,
}) {
    return (
        <li className={cx(['item', 'button'])}>
            <Button
                active={active}
                disabled={disabled}
                prefix={prefix}
                size={size}
                suffix={suffix}
                theme={theme}
                to={to}
                onClick={onClick}
            >
                {children}
            </Button>
        </li>
    );
}

DropdownButton.defaultProps = {
    active: false,
    disabled: false,
    prefix: undefined,
    size: 'base',
    suffix: undefined,
    theme: 'outline',
    to: undefined,
    onClick: undefined,
};

DropdownButton.propTypes = {
    /**
     * if true, the button is styled as if in use/selected
     */
    active: PropTypes.bool,
    /**
     * the content of the button
     */
    children: PropTypes.node.isRequired,
    /**
     * if true, it's not possible to interact with the button
     */
    disabled: PropTypes.bool,
    /**
     * Prefix the content of the button with a react component
     */
    prefix: PropTypes.node,
    /**
     * The size of the button content
     */
    size: PropTypes.oneOf(['base', 'small', 'large', 'smaller']),
    /**
     * Suffix the content of the button with a react component
     */
    suffix: PropTypes.node,
    /**
     * The styled theme of the button
     */
    theme: PropTypes.oneOf([
        'primary',
        'outline',
        'dark',
        'select',
        'switch',
        'custom',
    ]),
    /**
     * A relative or remote url to navigate to when clicking the button
     */
    to: PropTypes.string,
    /**
     * A callback action triggered when the button is clicked
     */
    onClick: PropTypes.func,
};

export default DropdownButton;
