import React from 'react';

const commonMetrics = [
    {
        id: 'invalid_events',
        name: 'Invalid Events',
        format: 'k',
        hidden: true,
        getValue: (i) => i.invalid_events,
    },
    {
        id: 'valid_events',
        name: 'Valid Events',
        format: '%',
        info: (
            <>
                <p>
                    Shows the percentage of your advertising data (eg:
                    impressions) and URLs which were readable by our technology.
                </p>
                <p>
                    Common reasons for invalid data include malformed URLs,
                    unreachable URLs or unreadable URLs such as those from
                    mobile app traffic.
                </p>
            </>
        ),
        getValue: (i) => i.impressions / (i.impressions + i.invalid_events),
    },
    {
        id: 'impressions',
        name: 'Impressions',
        format: 'k',
        info: (
            <p>
                The number of times an advert appeared on a consumer’s screen.
            </p>
        ),
        getValue: (i) => i.impressions,
    },
    {
        id: 'clicks',
        name: 'Clicks',
        format: 'k',
        info: <p>The number of times an advert was clicked by a consumer.</p>,
        getValue: (i) => i.clicks,
    },
    {
        id: 'ctr',
        name: 'CTR',
        format: '%',
        info: (
            <p>
                The ratio of consumers who click on an ad, to the number of
                total consumers who view an ad. CTR is calculated by dividing
                the clicks by the impressions.
            </p>
        ),
        getValue: (i) => i.clicks / i.impressions,
    },
];

export const displayMetrics = [
    ...commonMetrics,
    {
        id: 'in_views',
        name: 'Viewable Impr.',
        format: 'k',
        info: (
            <p>
                A measure of whether a given advert was seen by an actual
                consumer. An impression is considered to be viewable if at least
                50% of the display ad is in view for 1 second or more (IAB
                standard).
            </p>
        ),
        getValue: (i) => i.in_views,
    },
    {
        id: 'view_rate',
        name: 'View Rate',
        format: '%',
        info: (
            <p>
                Measures the number of impressions that were viewable compared
                with the total number of impressions.
            </p>
        ),
        getValue: (i) => i.in_views / i.impressions,
    },
];

export const videoMetrics = [
    ...commonMetrics,
    {
        id: 'completes',
        name: 'Completed Views',
        format: 'k',
        info: (
            <p>
                Measures the number of video ads running through to completion.
            </p>
        ),
        getValue: (i) => i.completes,
    },
    {
        id: 'completion_rate',
        name: 'Completion Rate',
        format: '%',
        info: (
            <p>
                Measures the number of video ads run through to completion,
                compared to the total number of video ads served.
            </p>
        ),
        getValue: (i) => i.completes / i.impressions,
    },
];
