import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { orientations } from '../../constants';
import { getOrientationCssPrefix } from '../../utils';
import styles from './box.styles';

const backgroundClasses = (background) => {
    const classes = [];

    if (background) {
        classes.push(styles[`background--${background[0]}-${background[1]}`]);
    }
    return classes;
};

const borderClasses = ({
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
}) => {
    const classes = [];
    if (border) {
        classes.push(
            styles[
                `border--${border[0]}-${border[1]}-${border[2]}-${border[3]}`
            ],
        );
    }
    if (borderTop) {
        if (borderTop === 'none') {
            classes.push(styles['border-top--none']);
        } else {
            classes.push(
                styles[
                    `border-top--${borderTop[0]}-${borderTop[1]}-${borderTop[2]}-${borderTop[3]}`
                ],
            );
        }
    }
    if (borderBottom) {
        if (borderBottom === 'none') {
            classes.push(styles['border-bottom--none']);
        } else {
            classes.push(
                styles[
                    `border-bottom--${borderBottom[0]}-${borderBottom[1]}-${borderBottom[2]}-${borderBottom[3]}`
                ],
            );
        }
    }
    if (borderLeft) {
        if (borderLeft === 'none') {
            classes.push(styles['border-left--none']);
        } else {
            classes.push(
                styles[
                    `border-left--${borderLeft[0]}-${borderLeft[1]}-${borderLeft[2]}-${borderLeft[3]}`
                ],
            );
        }
    }
    if (borderRight) {
        if (borderRight === 'none') {
            classes.push(styles['border-right--none']);
        } else {
            classes.push(
                styles[
                    `border-right--${borderRight[0]}-${borderRight[1]}-${borderRight[2]}-${borderRight[3]}`
                ],
            );
        }
    }
    if (borderRadius) {
        if (Array.isArray(borderRadius)) {
            const [topLeft, topRight, bottomRight, bottomLeft] = borderRadius;

            classes.push(
                styles[`border-radius-top-left--${topLeft || 'none'}`],
                styles[`border-radius-top-right--${topRight || 'none'}`],
                styles[
                    `border-radius-bottom-right--${
                        bottomRight || topLeft || 'none'
                    }`
                ],
                styles[
                    `border-radius-bottom-left--${
                        bottomLeft || topRight || 'none'
                    }`
                ],
            );
        } else {
            classes.push(styles[`border-radius--${borderRadius}`]);
        }
    }
    return classes;
};

const shadowClasses = (shadow) => {
    const classes = [];
    if (shadow) {
        classes.push(styles[`shadow--${shadow}`]);
    }
    return classes;
};

const displayClasses = (props) => {
    const classes = [];
    orientations.forEach((orientation) => {
        const orientationCssPrefix = getOrientationCssPrefix(orientation);
        const { [`display${orientation}`]: display } = props;
        if (display) {
            classes.push(styles[`${orientationCssPrefix}display--${display}`]);
        }
    });
    return classes;
};

const marginClasses = (props) => {
    const classes = [];
    orientations.forEach((orientation) => {
        const orientationCssPrefix = getOrientationCssPrefix(orientation);
        const { [`margin${orientation}`]: margin } = props;
        if (margin) {
            if (Array.isArray(margin)) {
                if (margin.length === 1) {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}margin--${
                                margin[0] || 'none'
                            }`
                        ],
                    );
                } else if (margin.length === 2) {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}margin-vertical--${
                                margin[0] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}margin-horizontal--${
                                margin[1] || 'none'
                            }`
                        ],
                    );
                } else if (margin.length === 3) {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}margin-top--${
                                margin[0] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}margin-horizontal--${
                                margin[1] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}margin-bottom--${
                                margin[2] || 'none'
                            }`
                        ],
                    );
                } else {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}margin-top--${
                                margin[0] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}margin-right--${
                                margin[1] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}margin-bottom--${
                                margin[2] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}margin-left--${
                                margin[3] || 'none'
                            }`
                        ],
                    );
                }
            } else {
                classes.push(
                    styles[`${orientationCssPrefix}margin--${margin}`],
                );
            }
        }
    });
    return classes;
};

const paddingClasses = (props) => {
    const classes = [];
    orientations.forEach((orientation) => {
        const orientationCssPrefix = getOrientationCssPrefix(orientation);
        const { [`padding${orientation}`]: padding } = props;
        if (padding) {
            if (Array.isArray(padding)) {
                if (padding.length === 1) {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}padding--${
                                padding[0] || 'none'
                            }`
                        ],
                    );
                } else if (padding.length === 2) {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}padding-vertical--${
                                padding[0] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}padding-horizontal--${
                                padding[1] || 'none'
                            }`
                        ],
                    );
                } else if (padding.length === 3) {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}padding-top--${
                                padding[0] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}padding-horizontal--${
                                padding[1] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}padding-bottom--${
                                padding[2] || 'none'
                            }`
                        ],
                    );
                } else {
                    classes.push(
                        styles[
                            `${orientationCssPrefix}padding-top--${
                                padding[0] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}padding-right--${
                                padding[1] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}padding-bottom--${
                                padding[2] || 'none'
                            }`
                        ],
                        styles[
                            `${orientationCssPrefix}padding-left--${
                                padding[3] || 'none'
                            }`
                        ],
                    );
                }
            } else {
                classes.push(
                    styles[`${orientationCssPrefix}padding--${padding}`],
                );
            }
        }
    });
    return classes;
};

function Box({
    display,
    displayPortrait,
    displayLandscape,
    margin,
    marginPortrait,
    marginLandscape,
    padding,
    paddingPortrait,
    paddingLandscape,
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
    background,
    shadow,
    position,
    children,
}) {
    const classes = [
        styles.box,
        ...displayClasses({
            display,
            displayPortrait,
            displayLandscape,
        }),
        ...marginClasses({ margin, marginPortrait, marginLandscape }),
        ...paddingClasses({
            padding,
            paddingPortrait,
            paddingLandscape,
        }),
        ...borderClasses({
            border,
            borderTop,
            borderBottom,
            borderLeft,
            borderRight,
            borderRadius,
        }),
        ...backgroundClasses(background),
        ...shadowClasses(shadow),
    ];

    if (position) {
        classes.push(styles[`position--${position}`]);
    }

    return <View style={classes}>{children}</View>;
}

const displays = PropTypes.oneOf(['block', 'inline-block', 'none', 'flex']);

const spacings = PropTypes.oneOfType([
    PropTypes.oneOf([
        '-larger',
        '-large',
        '-base',
        '-small',
        '-smaller',
        '-smallest',
        'none',
        'smallest',
        'smaller',
        'small',
        'base',
        'large',
        'larger',
        'auto',
    ]),
    PropTypes.arrayOf(
        PropTypes.oneOf([
            '-larger',
            '-large',
            '-base',
            '-small',
            '-smaller',
            '-smallest',
            0,
            'none',
            'smallest',
            'smaller',
            'small',
            'base',
            'large',
            'larger',
            'auto',
        ]),
    ),
]);

const colors = [
    'purple',
    'red',
    'yellow',
    'aqua',
    'gray',
    'blue',
    'black',
    'darkest',
    'darker',
    'dark',
    'base',
    'light',
    'lighter',
    'lightest',
    'background',
    'white',
];

const borders = PropTypes.arrayOf(
    PropTypes.oneOf(['base', 'thick', 'thicker', 'solid', 'dashed', ...colors]),
);

Box.defaultProps = {
    display: undefined,
    displayPortrait: undefined,
    displayLandscape: undefined,
    margin: undefined,
    marginPortrait: undefined,
    marginLandscape: undefined,
    padding: undefined,
    paddingPortrait: undefined,
    paddingLandscape: undefined,
    border: undefined,
    borderTop: undefined,
    borderBottom: undefined,
    borderLeft: undefined,
    borderRight: undefined,
    borderRadius: undefined,
    background: undefined,
    shadow: undefined,
    position: undefined,
};

Box.propTypes = {
    display: displays,
    displayPortrait: displays,
    displayLandscape: displays,
    margin: spacings,
    marginPortrait: spacings,
    marginLandscape: spacings,
    padding: spacings,
    paddingPortrait: spacings,
    paddingLandscape: spacings,
    border: borders,
    borderTop: borders,
    borderBottom: borders,
    borderLeft: borders,
    borderRight: borders,
    borderRadius: PropTypes.oneOfType([
        PropTypes.oneOf([
            'none',
            'small',
            'base',
            'round',
            'rounder',
            'rounded',
        ]),
        PropTypes.arrayOf(
            PropTypes.oneOf([
                'none',
                'small',
                'base',
                'round',
                'rounder',
                'rounded',
            ]),
        ),
    ]),
    background: PropTypes.arrayOf(PropTypes.oneOf(colors)),
    shadow: PropTypes.oneOf(['shallow', 'base', 'deep']),
    position: PropTypes.oneOf(['relative', 'absolute']),
    children: PropTypes.node.isRequired,
};

export default Box;
