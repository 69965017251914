import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ruleItemTypes from 'js/enums/rule-item-types.enum';
import ruleTypes from 'js/enums/rule-types.enum';
import { capitalize, RuleSet } from 'js/utils';
import DropdownTitle from './title';
import DropdownButton from './button';
import DropdownDivider from './divider';

const ruleItemFromId = (id, groups) => {
    let ruleItem;
    Object.values(ruleItemTypes).forEach((type) => {
        groups.forEach((group) => {
            const foundItem = group[type]?.find((item) => item.id === id);
            if (foundItem) {
                ruleItem = foundItem;
                ruleItem.type = type;
            }
        });
    });
    return ruleItem;
};

const ruleItemFromKeyword = (keyword) => ({
    id: keyword,
    name: keyword,
    type: ruleItemTypes.KEYWORD,
});

const RuleSelector = ({
    keyword,
    ruleItemId,
    topicGroups,
    logoGroups,
    rules,
    targetRuleKey,
    onAdd,
    onRemove,
    onChangeRules,
}) => {
    const ruleItemGroups = [...topicGroups, ...logoGroups];

    const ruleItem = ruleItemId
        ? ruleItemFromId(ruleItemId, ruleItemGroups)
        : ruleItemFromKeyword(keyword);

    const isItemInRules = (inRules) =>
        inRules
            .map((rule) => rule[ruleItem.type])
            .flat()
            .includes(ruleItem.id);

    const addRuleItem = (ruleKey) => {
        if (onChangeRules) {
            const ruleSet = new RuleSet(rules);
            if (ruleKey !== undefined) {
                ruleSet.changeRule(ruleKey, {
                    [`add${capitalize(ruleItem.type)}`]: [ruleItem.id],
                });
            } else {
                ruleSet.addRule({
                    aggregation: ruleTypes.INCLUDED,
                    [ruleItem.type]: [ruleItem.id],
                });
            }
            onChangeRules(ruleSet.rules);
        }
        if (onAdd) onAdd(ruleKey);
    };

    const removeRuleItem = () => {
        if (onChangeRules) {
            const ruleSet = new RuleSet(rules);
            ruleSet.changeAllRules({
                [`remove${capitalize(ruleItem.type)}`]: [ruleItem.id],
            });
            onChangeRules(ruleSet.rules);
        }
        if (onRemove) onRemove();
    };

    const inclusionRules = rules.filter(
        (rule) => rule.aggregation === ruleTypes.INCLUDED,
    );
    const exclusionRules = rules.filter(
        (rule) => rule.aggregation === ruleTypes.EXCLUDED,
    );

    const showUninclude = isItemInRules(inclusionRules);
    const showUnexclude = isItemInRules(exclusionRules);

    return (
        <ul>
            <DropdownTitle>{ruleItem.name}</DropdownTitle>
            <DropdownDivider />

            {showUninclude && (
                <DropdownButton onClick={removeRuleItem}>
                    Do Not Target
                </DropdownButton>
            )}
            {showUnexclude && (
                <DropdownButton onClick={removeRuleItem}>
                    Do Not Block
                </DropdownButton>
            )}

            {!(showUninclude || showUnexclude) && (
                <>
                    {!ruleItem.is_brand_safety && (
                        <DropdownButton
                            onClick={() =>
                                addRuleItem(
                                    targetRuleKey || inclusionRules[0].key,
                                )
                            }
                        >
                            Target
                        </DropdownButton>
                    )}

                    <DropdownButton
                        onClick={() => addRuleItem(exclusionRules[0].key)}
                    >
                        Block
                    </DropdownButton>
                </>
            )}
        </ul>
    );
};

RuleSelector.defaultProps = {
    keyword: undefined,
    ruleItemId: undefined,
    targetRuleKey: undefined,
    topicGroups: [],
    logoGroups: [],
    onAdd: undefined,
    onRemove: undefined,
    onChangeRules: undefined,
};

RuleSelector.propTypes = {
    /**
     * A new keyword to target or block
     */
    keyword: PropTypes.string,
    /**
     * A new topic/logo ID to target or block
     */
    ruleItemId: PropTypes.string,
    /**
     * The key of the rule where a newly targeted item should be added (defaults to the first rule)
     */
    targetRuleKey: PropTypes.string,
    /**
     * The topic groups (for showing dynamic names)
     */
    topicGroups: PropTypes.arrayOf(
        PropTypes.shape({
            topics: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }),
    ),
    /**
     * The logo groups (for showing dynamic names)
     */
    logoGroups: PropTypes.arrayOf(
        PropTypes.shape({
            logos: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }),
    ),
    /**
     * The existing context rules
     */
    rules: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number.isRequired,
            aggregation: PropTypes.string.isRequired,
            keywords: PropTypes.arrayOf(PropTypes.string),
            topics: PropTypes.arrayOf(PropTypes.string),
            logos: PropTypes.arrayOf(PropTypes.string),
        }),
    ).isRequired,
    /**
     * Callback when adding a keyword
     */
    onAdd: PropTypes.func,
    /**
     * Callback when removing a keyword
     */
    onRemove: PropTypes.func,
    /**
     * Callback for changing the rules of the context
     */
    onChangeRules: PropTypes.func,
};

export default RuleSelector;
