import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from 'js/components/box/box';
import Pill from 'js/components/pill/pill';
import CardBase, { getRecentlyUpdated } from './card-base';
import styles from './card.module.scss';

function CardIntegration({ integration, isPreview }) {
    const {
        id: integrationId,
        name: integrationTitle,
        logo: integrationLogo,
        updated: integrationDate,
        channels: integrationChannels,
    } = integration;

    const integrationSeatCount = integrationChannels ?? 0;
    const updatedDate = getRecentlyUpdated(integrationDate);

    const classes = [styles.content];

    if (isPreview) {
        classes.push(styles[`content--is-preview`]);
    }

    return (
        <Link to={`/settings/channels/${integrationId}/`}>
            <CardBase type="full" isPreview={isPreview}>
                <div className={classNames(classes)}>
                    {integrationLogo ? (
                        <Box>
                            <img
                                className={styles.logo}
                                src={integrationLogo}
                                alt={`${integrationTitle} Logo`}
                            />
                        </Box>
                    ) : (
                        <div className={styles['logo-preview']}>Logo</div>
                    )}

                    <h3 className={styles.title}>{integrationTitle}</h3>

                    {updatedDate && (
                        <p className={styles.date}>{updatedDate}</p>
                    )}
                </div>

                <div className={styles.badge}>
                    <Pill outline theme="muted">
                        {integrationSeatCount}{' '}
                        {integrationSeatCount === 1 ? 'Seat' : 'Seats'}
                    </Pill>
                </div>
            </CardBase>
        </Link>
    );
}

CardIntegration.defaultProps = {
    isPreview: false,
};

CardIntegration.propTypes = {
    integration: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
        updated: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        channels: PropTypes.number,
    }).isRequired,
    isPreview: PropTypes.bool,
};

export default CardIntegration;
