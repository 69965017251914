import React, { useEffect, useRef, useState } from 'react';
import forceBoundary from 'd3-force-boundary';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Text from 'js/components/text/text';
import Bubble from './bubble';
import styles from './bubble-chart.module.scss';

const createD3Nodes = ({ width, height, data, minValue }) => {
    if (!width || !height) return [];
    return d3.pack().size([width, height]).padding(3)(
        d3
            .hierarchy({ children: data })
            .sum((d) => d.value - minValue * 0.95)
            .sort(() => (Math.random() > 0.5 ? 1 : -1)),
    ).children;
};

const positionNodes = ({ width, height, nodes, viewport }) => {
    const [viewXMin, viewYMin] = viewport;
    const treeMapLayout = d3
        .treemap()
        .tile(d3.treemapSquarify.ratio(1))
        .size([width, height])
        .round(true)
        .padding(1)(
        d3
            .hierarchy({ children: nodes.map((i) => i.data) })
            .sum((s) => s.value),
    );

    const newNodes = nodes.map((node, j) => ({
        ...node,
        x: width * viewXMin + treeMapLayout.children[j].x0 + node.r,
        y: height * viewYMin + treeMapLayout.children[j].y0 + node.r,
    }));

    return newNodes;
};

// eslint-disable-next-line no-unused-vars
const addD3ForceNodes = ({
    width,
    height,
    nodes,
    viewport,
    isAnimated,
    callback,
}) => {
    const [viewXMin, viewYMin, viewX, viewY] = viewport;
    const maxR = Math.max(...nodes.map((i) => i.r));
    const simulation = d3
        .forceSimulation(nodes)
        .force(
            'collision',
            d3
                .forceCollide()
                .radius((d) => d.r + 1)
                .strength(1.5),
        )
        .force(
            'boundary',
            forceBoundary(
                width * viewXMin + maxR,
                height * viewYMin + maxR,
                width * viewX - maxR,
                height * viewY - maxR,
            )
                .hardBoundary(true)
                .strength(0.05),
        )
        .velocityDecay(0.65)
        .alphaDecay(0.01);

    if (!isAnimated) {
        simulation.tick(300).stop();
    }

    simulation.on('tick', callback);

    return nodes;
};

const Legend = ({ legend: { title, lowLabel, highLabel }, colorScale }) => {
    const color = colorScale.domain([0, 1]);
    return (
        <Box padding="base">
            <Row alignItems="center" justifyContent="space-between">
                <Col span="auto">
                    {title && (
                        <Text size="large" color={['gray', 'dark']}>
                            {title}
                        </Text>
                    )}
                </Col>

                <Col span="auto">
                    <Row alignItems="center" gutter="smaller">
                        {lowLabel && (
                            <Col span="auto">
                                <Text color={['gray', 'dark']}>{lowLabel}</Text>
                            </Col>
                        )}

                        {[
                            { width: '12', color: color(0) },
                            { width: '17', color: color(0.5) },
                            { width: '22', color: color(1) },
                        ].map((row) => (
                            <Col span="auto" key={row.width}>
                                <svg width={row.width} viewBox="0 0 100 100">
                                    <circle
                                        fill={row.color}
                                        r="50"
                                        cx="50"
                                        cy="50"
                                    />
                                </svg>
                            </Col>
                        ))}

                        {highLabel && (
                            <Col span="auto">
                                <Text color={['gray', 'dark']}>
                                    {highLabel}
                                </Text>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Box>
    );
};

function BubbleChart({
    data,
    legend,
    viewport,
    isAnimated,
    colors: {
        low: lowColor,
        high: highColor,
        active: activeColor,
        func: colorFunc,
    },
    tooltip: Tooltip,
}) {
    const chartContainerRef = useRef();
    const svgRef = useRef();
    const tooltipRef = useRef();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [tooltipData, setTooltipData] = useState(null);
    const [tooltipCoords, setTooltipCoords] = useState({
        x: width,
        y: height,
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const setTickState = useState(false)[1];

    const maxValue = Math.max(...data.map((i) => i.value));
    const minValue = Math.min(...data.map((i) => i.value));

    const [viewXMin, viewYMin, viewX, viewY] = viewport;

    const colorScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateRgb(lowColor, highColor));

    const onClick = (event, eventData) => {
        setShowTooltip(!showTooltip);
        if (!showTooltip) {
            // eslint-disable-next-line no-param-reassign
            event.currentTarget.style.filter = '';
        }

        const svgElement = svgRef.current.getBoundingClientRect();
        const xAxis = event.clientX - svgElement.left;
        const yAxis = event.clientY - svgElement.top;

        setTooltipCoords({ x: xAxis, y: yAxis });
        setTooltipData(eventData);
    };

    const onMouseEnter = (event, eventData) => {
        setTooltipData(eventData);
        if (!showTooltip && !!Tooltip) {
            // eslint-disable-next-line no-param-reassign
            event.currentTarget.style.filter = 'brightness(0.8)';
        }
    };

    const onMouseLeave = (event) => {
        if (!showTooltip) {
            // eslint-disable-next-line no-param-reassign
            event.currentTarget.style.filter = '';
        }
        const newTarget = document.elementFromPoint(
            event.clientX,
            event.clientY,
        );
        if (
            !tooltipRef?.current?.contains(newTarget) &&
            newTarget !== event.target
        ) {
            setTooltipData(null);
            setShowTooltip(false);
        }
    };

    const getBubbleColor = (bubbleData) => {
        if (
            `${bubbleData.label}${bubbleData.value}` ===
                `${tooltipData?.label}${tooltipData?.value}` &&
            showTooltip
        ) {
            return activeColor;
        }
        const funcColor = colorFunc ? colorFunc(bubbleData) : '';
        return funcColor || colorScale(bubbleData.value / maxValue);
    };

    useEffect(() => {
        const handleResize = () => {
            if (chartContainerRef.current && !width && !height) {
                setWidth(
                    chartContainerRef.current.getBoundingClientRect().width,
                );
                setHeight(
                    chartContainerRef.current.getBoundingClientRect().height,
                );
            }
            if (chartData.length !== data.length) {
                const bubbleChart = createD3Nodes({
                    width,
                    height,
                    data,
                    minValue,
                });
                const chartRandom = positionNodes({
                    width,
                    height,
                    nodes: bubbleChart,
                    viewport,
                });
                const chartForce = addD3ForceNodes({
                    width,
                    height,
                    nodes: chartRandom,
                    callback: () => {
                        setTickState((curr) => !curr);
                    },
                    viewport,
                    isAnimated,
                });
                setChartData(chartForce);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [
        chartData.length,
        data,
        height,
        isAnimated,
        minValue,
        setTickState,
        viewport,
        width,
    ]);

    const legendComponent = (
        <div className={styles.legend}>
            <Legend legend={legend} colorScale={colorScale} />
        </div>
    );

    return (
        <div className={styles.wrapper}>
            {legend && legend.position === 'top' && legendComponent}
            <div className={styles.container} ref={chartContainerRef}>
                <svg
                    ref={svgRef}
                    viewBox={`${width * viewXMin} ${height * viewYMin} ${
                        width * (viewX - viewXMin)
                    } ${height * (viewY - viewYMin)}`}
                    height={height}
                >
                    <g textAnchor="middle">
                        {chartData.map(({ data: d, x, y, r }) => (
                            <Bubble
                                data={{ ...d, x, y, r }}
                                color={getBubbleColor(d)}
                                onClick={Tooltip ? onClick : undefined}
                                onMouseEnter={
                                    Tooltip ? onMouseEnter : undefined
                                }
                                onMouseLeave={
                                    Tooltip ? onMouseLeave : undefined
                                }
                                key={`${d.label}-${d.value}`}
                                x={x}
                                y={y}
                                r={r}
                            />
                        ))}
                    </g>
                </svg>
                {showTooltip && !!Tooltip && (
                    <div
                        style={{
                            position: 'absolute',
                            top: `${tooltipCoords.y}px`,
                            left: `${tooltipCoords.x}px`,
                        }}
                        ref={tooltipRef}
                        onMouseLeave={(e) => {
                            const circle = document.elementFromPoint(
                                e.clientX,
                                e.clientY,
                            );
                            if (
                                circle?.nextElementSibling?.firstChild
                                    ?.textContent !== tooltipData.label &&
                                circle?.getAttribute('r') === tooltipData.radius
                            ) {
                                setTooltipData(null);
                                setShowTooltip(false);
                            }
                        }}
                    >
                        <Tooltip
                            data={tooltipData}
                            onClose={() => setShowTooltip(false)}
                        />
                    </div>
                )}
            </div>
            {legend && legend.position === 'bottom' && legendComponent}
        </div>
    );
}

BubbleChart.defaultProps = {
    legend: undefined,
    viewport: [0, 0, 1, 1],
    isAnimated: false,
    colors: {
        low: '#6673FF',
        high: '#6673FF',
        active: '#242A6B',
    },
    tooltip: undefined,
};

BubbleChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
    ).isRequired,
    legend: PropTypes.shape({
        lowLabel: PropTypes.string,
        highLabel: PropTypes.string,
        title: PropTypes.string,
        position: PropTypes.oneOf(['top', 'bottom']),
    }),
    colors: PropTypes.shape({
        low: PropTypes.string,
        high: PropTypes.string,
        active: PropTypes.string,
        func: PropTypes.func,
    }),
    viewport: PropTypes.arrayOf(PropTypes.number),
    isAnimated: PropTypes.bool,
    tooltip: PropTypes.elementType,
};

export default BubbleChart;
