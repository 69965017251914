import history from 'js/history';
import {
    UnauthorizedError,
    ForbiddenError,
    NotFoundError,
    LockedOutError,
    AbortError,
} from './api-error';
import { getLoginPath } from './query';
import { clearPresets } from './ui';
import { validJwtAge } from './utils';

const globalControllers = new Proxy(
    {},
    {
        get(obj, name) {
            if (obj[name]) {
                obj[name].abort();
            }
            // eslint-disable-next-line no-param-reassign
            obj[name] = new AbortController();
            return obj[name];
        },
        deleteProperty(obj, name) {
            if (obj[name]) {
                obj[name].abort();
            }
            // eslint-disable-next-line no-param-reassign
            return delete obj[name];
        },
    },
);

class API {
    // TODO: implicit class construction from the /rest/ endpoint?

    constructor(path, scope) {
        if (!path || !scope) {
            throw new Error('missing API argument');
        }
        this.path = path;
        this.scope = scope;
        this.controllers = globalControllers;
        this.call = this.call.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);

        if (process.env.REACT_APP_CLUSTER === 'dev') {
            this.host = `http://${window.location.hostname}:8000`;
        } else if (process.env.REACT_APP_CLUSTER === 'avocado') {
            this.host = 'https://api.4d.silverbulletcloud.com';
        } else {
            this.host = `https://api-${process.env.REACT_APP_CLUSTER}.4d.silverbulletcloud.com`;
        }
    }

    get url() {
        return `${[this.host, 'rest'].concat(this.path).join('/')}/`;
    }

    get accounts() {
        return new API(this.path.concat(['account']), this.scope);
    }

    get relatedAnalytics() {
        return new API(
            this.path.concat(['analytics', 'related-words']),
            this.scope,
        );
    }

    get matchedPagesAnalytics() {
        return new API(
            this.path.concat(['analytics', 'matched-pages']),
            this.scope,
        );
    }

    get matchedVideosAnalytics() {
        return new API(
            this.path.concat(['analytics', 'matched-videos']),
            this.scope,
        );
    }

    get audiences() {
        return new API(this.path.concat(['audience']), this.scope);
    }

    get deals() {
        return new API(this.path.concat(['deal']), this.scope);
    }

    get dealGeotargets() {
        return new API(this.path.concat(['deal-geotarget']), this.scope);
    }

    login(username, password, params = {}) {
        this.path.push(...['authenticate', 'login']);
        return this.create({ username, password }, params);
    }

    refreshToken(token, params = {}) {
        this.path.push(...['authenticate', 'refresh']);
        return this.create({ token }, params);
    }

    masquerade(account, params = {}) {
        this.path.push(...['authenticate', 'masquerade']);
        return this.create({ account }, params);
    }

    // eslint-disable-next-line camelcase
    changePassword(old_password, new_password1, new_password2, params = {}) {
        this.path.push(...['authenticate', 'password-change']);
        return this.create(
            { old_password, new_password1, new_password2 }, // eslint-disable-line camelcase
            params,
        );
    }

    applyPasswordReset(email, params = {}) {
        this.path.push(...['authenticate', 'password-reset', 'apply']);
        return this.create({ email }, params);
    }

    confirmPasswordReset(
        token,
        username,
        // eslint-disable-next-line camelcase
        new_password1,
        // eslint-disable-next-line camelcase
        new_password2,
        params = {},
    ) {
        this.path.push(...['authenticate', 'password-reset', 'confirm']);
        return this.create(
            { token, username, new_password1, new_password2 }, // eslint-disable-line camelcase
            params,
        );
    }

    acceptTermsAndConditions(username, params = {}) {
        this.path.push(...['terms-and-conditions', 'accept']);
        return this.create({ username }, params);
    }

    get channels() {
        return new API(this.path.concat(['channel']), this.scope);
    }

    get contextGroups() {
        return new API(this.path.concat(['context-group']), this.scope);
    }

    get advertisers() {
        return new API(this.path.concat(['brand']), this.scope);
    }

    get contexts() {
        return new API(this.path.concat(['context']), this.scope);
    }

    get notifications() {
        return new API(this.path.concat(['notifications']), this.scope);
    }

    get topics() {
        return new API(this.path.concat(['topic']), this.scope);
    }

    get logos() {
        return new API(this.path.concat(['logo']), this.scope);
    }

    get logoGroups() {
        return new API(this.path.concat(['logo-group']), this.scope);
    }

    get autoOptimizeReportTemplates() {
        return new API(
            this.path.concat(['report-template', 'auto-optimise']),
            this.scope,
        );
    }

    get autoOptimizeRecommendations() {
        return new API(
            this.path.concat(['report', 'auto-optimise-recommendation']),
            this.scope,
        );
    }

    get optimizeReportTemplates() {
        return new API(
            this.path.concat(['report-template', 'optimise']),
            this.scope,
        );
    }

    get videoReportTemplates() {
        return new API(
            this.path.concat(['report-template', 'video']),
            this.scope,
        );
    }

    get eventLogReportTemplates() {
        return new API(
            this.path.concat(['report-template', 'event-log']),
            this.scope,
        );
    }

    get optimizeReports() {
        return new API(this.path.concat(['report', 'optimise']), this.scope);
    }

    get videoReports() {
        return new API(this.path.concat(['report', 'video']), this.scope);
    }

    get eventLogReports() {
        return new API(this.path.concat(['report', 'event-log']), this.scope);
    }

    get firstParty() {
        return new API(this.path.concat(['first-party']), this.scope);
    }

    get dataLog() {
        return new API(this.path.concat(['data-log']), this.scope);
    }

    get dataSource() {
        return new API(this.path.concat(['data-source']), this.scope);
    }

    get auditAdform() {
        return new API(this.path.concat(['audit', 'adform']), this.scope);
    }

    get auditEvent() {
        return new API(this.path.concat(['audit', 'event']), this.scope);
    }

    get auditIristv() {
        return new API(this.path.concat(['audit', 'iristv']), this.scope);
    }

    get auditPage() {
        return new API(this.path.concat(['audit', 'page']), this.scope);
    }

    get auditReport() {
        return new API(this.path.concat(['audit', 'report']), this.scope);
    }

    get auditTreasuredata() {
        return new API(this.path.concat(['audit', 'treasuredata']), this.scope);
    }

    get auditVideo() {
        return new API(this.path.concat(['audit', 'video']), this.scope);
    }

    get auditXandr() {
        return new API(this.path.concat(['audit', 'xandr']), this.scope);
    }

    get auditYoutube() {
        return new API(this.path.concat(['audit', 'youtube']), this.scope);
    }

    get eventHierarchy() {
        return new API(this.path.concat(['event', 'hierarchy']), this.scope);
    }

    get histories() {
        return new API(this.path.concat(['history']), this.scope);
    }

    get integrations() {
        return new API(this.path.concat(['integration']), this.scope);
    }

    get priceStructures() {
        return new API(this.path.concat(['price-structure']), this.scope);
    }

    get providers() {
        return new API(this.path.concat(['provider']), this.scope);
    }

    get features() {
        return new API(this.path.concat(['feature']), this.scope);
    }

    get loginPromos() {
        return new API(this.path.concat(['login-promo']), this.scope);
    }

    get homepageLayouts() {
        return new API(this.path.concat(['layout', 'homepage']), this.scope);
    }

    get termsAndConditions() {
        return new API(this.path.concat(['terms-and-conditions']), this.scope);
    }

    get users() {
        return new API(this.path.concat(['user']), this.scope);
    }

    get userSettings() {
        return new API(this.path.concat(['user-settings']), this.scope);
    }

    get namedCampaign() {
        return new API(
            this.path.concat(['event', 'named-campaign']),
            this.scope,
        );
    }

    get namedLineItem() {
        return new API(
            this.path.concat(['event', 'named-line-item']),
            this.scope,
        );
    }

    get tasks() {
        return new API(this.path.concat(['task']), this.scope);
    }

    async list(params = {}, options = {}) {
        const callParams = { ...params };
        const callOptions = { ...options, method: 'GET' };
        if (!params.limit || params.limit < 1) {
            callParams.limit = 1000;
            callParams.offset = 0;
        } else {
            callParams.limit = Math.min(1000, params.limit);
            callParams.offset = 0;
        }

        const resp = await this.call(callParams, callOptions);

        while (
            (!params.limit ||
                resp.results.length < (params.limit || callParams.limit)) &&
            resp.next !== null
        ) {
            callParams.offset += callParams.limit;
            // eslint-disable-next-line no-await-in-loop
            const nextResp = await this.call(callParams, callOptions);
            resp.results = resp.results.concat(nextResp.results);
            resp.next = nextResp.next;
            resp.previous = nextResp.previous;
        }
        return resp;
    }

    retrieve(objId, params = {}, options = {}) {
        this.path.push(objId);
        return this.call(params, { ...options, method: 'GET' });
    }

    create(json = {}, params = {}, options = {}) {
        return this.call(params, {
            ...options,
            method: 'POST',
            body: JSON.stringify(json),
        });
    }

    update(objId, json = {}, params = {}, options = {}) {
        this.path.push(objId);
        return this.call(params, {
            ...options,
            method: 'PATCH',
            body: JSON.stringify(json),
        });
    }

    destroy(objId, params = {}, options = {}) {
        this.path.push(objId);
        return this.call(params, {
            ...options,
            method: 'DELETE',
        });
    }

    favorite(objId, isFavorite, params = {}, options = {}) {
        this.path.push(objId, 'favourite');
        return this.call(params, {
            ...options,
            method: 'PUT',
            body: JSON.stringify({ is_favourite: isFavorite }),
        });
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async call(params = {}, options = {}) {
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        const token = localStorage.getItem('AuthToken');
        if (token) {
            if (!validJwtAge(token)) {
                localStorage.removeItem('AuthToken');
                await history.push(getLoginPath());
                return null;
            }
            headers.Authorization = `Bearer ${token}`;
        }

        const signalKey = btoa(
            escape([this.scope, this.url, JSON.stringify(params)].join()),
        );
        const { signal } = this.controllers[signalKey];
        const callOptions = { signal, headers, ...options };
        const urlParams = new URLSearchParams();

        Object.entries(params).forEach(([name, value]) => {
            if (Array.isArray(value)) {
                value.forEach((param) => urlParams.append(name, param));
            } else {
                urlParams.set(name, value);
            }
        });

        const url = `${this.url}?${urlParams.toString()}`;

        try {
            const response = await fetch(url, callOptions);
            const { status } = response;
            const { pathname, search } = window.location;
            const redirectParams = new URLSearchParams({
                redirectUri: pathname + search,
            });

            if (status === 401 || status === 451) {
                localStorage.removeItem('AuthToken');
                clearPresets();
                await history.push(`/errors/401/?${redirectParams}`);
                throw new UnauthorizedError();
            }

            if (status === 403) {
                await history.replace('/errors/403/');
                throw new ForbiddenError();
            }

            if (status === 404) {
                let accountId;
                try {
                    const completeCallOptions = {
                        ...callOptions,
                        method: 'GET',
                    };
                    urlParams.set('complete', true);
                    const completeResponse = await (
                        await fetch(
                            `${this.url}?${urlParams.toString()}`,
                            completeCallOptions,
                        )
                    ).json();
                    accountId = (
                        Array.isArray(completeResponse)
                            ? completeResponse[0]
                            : completeResponse
                    ).account;
                } catch (err) {
                    if (err.name === 'AbortError') {
                        throw new AbortError();
                    }
                    // continue regardless of error, we couldn't find the account id of the object
                }

                await history.replace(
                    accountId
                        ? `/errors/401/${accountId}/?${redirectParams}`
                        : '/errors/404/',
                );
                throw new NotFoundError();
            }

            if (status === 429) {
                await history.push('/errors/429/');
                throw new LockedOutError();
            }

            if (status < 200 || status >= 300) {
                throw await response.json();
            }

            if (callOptions.method === 'DELETE') {
                return null;
            }

            return response.json();
        } catch (err) {
            if (err.name === 'AbortError') {
                throw new AbortError();
            }

            throw err;
        }
    }

    abort(params = {}) {
        const signalKey = btoa(
            escape([this.scope, this.url, JSON.stringify(params)].join()),
        );
        delete this.controllers[signalKey];
    }

    // eslint-disable-next-line class-methods-use-this
    abortAll() {
        Object.keys(this.controllers).forEach((item) => {
            const scope = unescape(atob(item)).split(',')[0];
            if (scope === this.scope) {
                delete this.controllers[item];
            }
        });
    }

    action(...path) {
        return new API(this.path.concat([...path]), this.scope);
    }
}

export default function api({ scope = 'page' } = {}) {
    return new API([], scope);
}

window.api = api;
