import React, { useState, useEffect } from 'react';
import SectionTitle from 'js/components/section-title/section-title';
import Select from 'js/components/select/select';
import Box from 'js/components/box/box';
import Input from 'js/components/input/input';
import { SEARCH_DEBOUNCE_TIMEOUT } from 'js/constants';
import Spinner from 'js/components/spinner/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from '../rename-data-sources.module.scss';
import LineItemsResults from './line-item-results';
import CampaignResults from './campaign-results';

const searchTypes = {
    any: 'any',
    campaign: 'campaign',
    lineItems: 'lineItem',
};

const searchOptions = [
    {
        label: 'Any',
        value: searchTypes.any,
    },
    {
        label: 'Campaign',
        value: searchTypes.campaign,
    },
    {
        label: 'Line Item',
        value: searchTypes.lineItems,
    },
];

const SearchAndEdit = ({
    dataSources,
    unAssociatedLineItems,
    onCampaignNameChange,
    onLineItemNameChange,
}) => {
    const [selectedSearchType, setSelectedSearchType] = useState(
        searchOptions[0].value,
    );
    const [searchInput, setSearchInput] = useState('');
    const [isSearching, setIsSearching] = useState('');
    const [searchResults, setSearchResults] = useState({});

    const search = (input) => {
        const allLineItems = [...unAssociatedLineItems];
        dataSources.forEach((source) => {
            source.lineItems.forEach((item) => allLineItems.push(item));
        });
        const filteredLineItems = allLineItems.filter(
            (item) =>
                item.lineItemName.toLowerCase().includes(input.toLowerCase()) ||
                item.lineItemId.toLowerCase().includes(input.toLowerCase()),
        );
        const filteredCampaigns = dataSources.filter(
            (source) =>
                source.campaignName
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                source.campaignId.toLowerCase().includes(input.toLowerCase()),
        );

        setSearchResults({
            campaigns: filteredCampaigns,
            lineItems: filteredLineItems,
        });
        setIsSearching(false);
    };

    useEffect(() => {
        setIsSearching(true);
        const searchDebounce = setTimeout(
            async () => {
                await search(searchInput);
                // set search results
            },
            searchInput ? SEARCH_DEBOUNCE_TIMEOUT : 0,
        );

        return () => {
            clearTimeout(searchDebounce);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    return (
        <>
            <SectionTitle
                info={
                    <>
                        <p>
                            You can search using an ID or the Campaign/Line Item
                            that you have added. Filter to show just campaigns,
                            just line items or all values.
                        </p>
                        <p>
                            To add a name to the ID number or to edit an
                            existing name, select the button with the three dots
                            and enter the name of your choice.
                        </p>
                        <p>
                            IDs that we have registered campaign performance
                            data for will display with a tick. IDs without a
                            tick have not yet campaign performance data from the
                            4D Tag.
                        </p>
                    </>
                }
                hasBar
                extension={[
                    <Select
                        theme="filter"
                        prefix="Type"
                        options={searchOptions}
                        selectedValues={selectedSearchType}
                        onChange={setSelectedSearchType}
                    />,
                ]}
            >
                Search / Edit IDs
            </SectionTitle>

            <Box margin={['large', 0]}>
                <Input
                    value={searchInput}
                    placeholder="Search by name or by ID"
                    prefix={<FontAwesomeIcon icon={faSearch} />}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
            </Box>

            {isSearching && (
                <div className={styles['spinner-container']}>
                    <Box margin="auto">
                        <Spinner
                            size="small"
                            message="Searching"
                            color={['gray', 'dark']}
                        />
                    </Box>
                </div>
            )}

            {searchInput &&
                !isSearching &&
                [searchTypes.any, searchTypes.campaign].includes(
                    selectedSearchType,
                ) && (
                    <Box margin={[0, 0, 'large', 0]}>
                        <CampaignResults
                            searchResults={searchResults.campaigns}
                            onCampaignNameChange={onCampaignNameChange}
                            onLineItemNameChange={onLineItemNameChange}
                        />
                    </Box>
                )}

            {searchInput &&
                !isSearching &&
                [searchTypes.any, searchTypes.lineItems].includes(
                    selectedSearchType,
                ) && (
                    <LineItemsResults
                        searchResults={searchResults.lineItems}
                        onLineItemNameChange={onLineItemNameChange}
                    />
                )}
        </>
    );
};

export default SearchAndEdit;
