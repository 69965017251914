import React from 'react';
import { capitalize, getEventTagSourceOrDefault } from 'js/utils';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Label from 'js/components/label/label';
import Select from 'js/components/select/select';
import CampaignLinesSelector from './campaign-lines-selector';

const EmptyDataMessage = ({ template }) => {
    const { type, subtype } = template;

    const typeLabel =
        (type === entityTypes.DISPLAY_REPORTS && 'display') ||
        (type === entityTypes.VIDEO_REPORTS && 'video') ||
        '';

    const isYoutube =
        subtype === videoReportSubtypes.YOUTUBE_DV360 ||
        subtype === videoReportSubtypes.YOUTUBE_GADS;

    return (
        <Alert
            theme="empty"
            title={`You Have No ${
                isYoutube ? 'Google Ads or DV360' : capitalize(typeLabel)
            } Data To Generate An Insights Report With`}
        >
            <p>
                For you to be able to create a{' '}
                {isYoutube ? 'YouTube' : typeLabel} report of this type, 4D
                needs to have received data generated by{' '}
                {isYoutube ? 'Google Ads or DV360' : `the ${typeLabel} tag`} for
                this account.
            </p>
            <p>
                If you have recently{' '}
                {isYoutube
                    ? `enabled the Google Ads or DV360 integration`
                    : `implemented the ${typeLabel} tag`}{' '}
                for a campaign, it may take some time for the data to be
                recognized. If that is the case, please check back soon.
            </p>
        </Alert>
    );
};

const EventsSourceTab = ({
    template,
    errors,
    campaignLabel,
    lineItemLabel,
    eventHierarchy,
    nextCampaignId,
    onAddCampaign,
    onChangeCampaign,
    onRemoveCampaign,
    onSetNextSourceId,
    onChangeSource,
}) => {
    const sourceOptions = [
        ...new Set(eventHierarchy.map(({ source }) => source)),
    ]
        .map((source) => ({
            value: source,
            label: getEventTagSourceOrDefault(source),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    if (!sourceOptions.length) {
        return <EmptyDataMessage template={template} />;
    }

    return (
        <>
            <Select
                label="Platform"
                width="same"
                options={sourceOptions}
                selectedValues={template.source}
                hasError={!!errors.source}
                errorMessage={<p>{errors.source}</p>}
                onChange={
                    template.campaigns.length > 1
                        ? onSetNextSourceId
                        : onChangeSource
                }
            />

            <Box margin={['base', 0, 0]}>
                <Label
                    label={`${campaignLabel}s`}
                    hint={
                        <p>
                            To ensure your 4D reporting and recommendations are
                            as accurate as possible, select {campaignLabel} ID
                            and {lineItemLabel} IDs that are associated with the
                            activation of your advertising. IDs will only appear
                            in this screen once 4D has collected data for them.
                        </p>
                    }
                    hasError={!!errors.campaigns}
                    errorMessage={
                        <p>
                            Something is wrong in your {`${campaignLabel}s`}{' '}
                            selection.
                        </p>
                    }
                >
                    {template.campaigns.map(({ campaign_id: campaignId }) => (
                        <Box key={campaignId} margin={['small', 0]}>
                            <CampaignLinesSelector
                                campaignId={campaignId}
                                eventHierarchy={eventHierarchy}
                                template={template}
                                campaignLabel={campaignLabel}
                                lineItemLabel={lineItemLabel}
                                onChange={(newCampaignId, newLineItemIds) =>
                                    onChangeCampaign(
                                        newCampaignId,
                                        newLineItemIds,
                                        campaignId,
                                    )
                                }
                                onRemove={
                                    template.campaigns.length > 1
                                        ? onRemoveCampaign
                                        : null
                                }
                            />
                        </Box>
                    ))}
                </Label>
            </Box>

            {nextCampaignId && (
                <Box margin={['small', 0, 0]}>
                    <Button theme="outline" onClick={onAddCampaign}>
                        Add Additional {campaignLabel}
                    </Button>
                </Box>
            )}
        </>
    );
};

export default EventsSourceTab;
