import React from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Spinner from 'js/components/spinner/spinner';
import CreationForm from './components/creation-form';
import EditingForm from './components/editing-form';

const Wrapper = ({ template, isSaving, children }) => (
    <Container size="small">
        <Box margin={['large', 0]}>{children}</Box>

        {isSaving && (
            <LoadingLayer
                message={template.id ? 'Saving' : 'Creating Insights Report'}
            />
        )}
    </Container>
);

const ErrorMessage = ({ onRetry }) => (
    <Alert
        theme="danger"
        title="There Was An Error Loading Data"
        actionItems={[
            <Button onClick={onRetry} key="retry">
                Retry
            </Button>,
        ]}
    >
        Hopefully it is only a temporary issue. Please try again in a few
        moments.
    </Alert>
);

const ReportTemplateDetail = ({
    template,
    eventHierarchy,
    advertisers,
    contexts,
    isLoading,
    hasLoadingError,
    isSaving,
    savingErrors,
    onChange,
    onValidate,
    onSave,
    onReload,
    onFileUploadError,
}) => {
    if (isLoading) {
        return (
            <Wrapper template={template} isSaving={isSaving}>
                <Spinner
                    size="large"
                    message="Loading"
                    color={['gray', 'dark']}
                    isCentered
                />
            </Wrapper>
        );
    }

    if (hasLoadingError) {
        return (
            <Wrapper template={template} isSaving={isSaving}>
                <ErrorMessage onRetry={onReload} />
            </Wrapper>
        );
    }

    if (!template.id) {
        return (
            <Wrapper template={template} isSaving={isSaving}>
                <CreationForm
                    template={template}
                    errors={savingErrors}
                    advertisers={advertisers}
                    contexts={contexts}
                    eventHierarchy={eventHierarchy}
                    onChange={onChange}
                    onValidate={onValidate}
                    onSave={onSave}
                    onFileUploadError={onFileUploadError}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper template={template} isSaving={isSaving}>
            <EditingForm
                template={template}
                errors={savingErrors}
                onChange={onChange}
                onSave={onSave}
            />
        </Wrapper>
    );
};

export default ReportTemplateDetail;
