import React from 'react';
import { MIN_PASSWORD_LENGTH } from 'js/constants';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Crumb from 'js/components/breadcrumbs/crumb';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Layout from 'js/components/layout/layout';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Navigation from 'js/components/navigation/navigation';
import Panel from 'js/components/panel/panel';
import TopBar from 'js/components/top-bar/top-bar';

const FormErrors = ({ passwordErrors }) => {
    if (
        passwordErrors.oldPassword ||
        passwordErrors.newPassword ||
        passwordErrors.confirmPassword
    ) {
        return (
            <Box margin={[0, 0, 'large']}>
                <Alert theme="danger">
                    <p>
                        You can&apos;t change your password at the moment
                        because there are problems that have been highlighted in
                        the form above.
                    </p>
                </Alert>
            </Box>
        );
    }
    if (Object.keys(passwordErrors).length) {
        return (
            <Box margin={[0, 0, 'large']}>
                <Alert theme="danger">
                    <p>
                        You did not change your password because there was an
                        unexpected error. Please try again in a few moments.
                    </p>
                </Alert>
            </Box>
        );
    }
    return null;
};

const PasswordChangeForm = ({
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    passwordErrors,
    onSavePassword,
}) => (
    <>
        <Box margin={[0, 0, 'base']}>
            <Input
                type="password"
                focusOnShow
                maxLength={128}
                required
                label="Your Current Password"
                value={oldPassword}
                onChange={(evt) => setOldPassword(evt.target.value)}
                hasError={!!passwordErrors.oldPassword}
                errorMessage={<p>{passwordErrors.oldPassword}</p>}
            />
        </Box>
        <Box margin={[0, 0, 'base']}>
            <Input
                type="password"
                maxLength={128}
                required
                label="Your New Password"
                hint={
                    <p>
                        Your new password needs to be at least{' '}
                        {MIN_PASSWORD_LENGTH} characters long. It is currently{' '}
                        <strong>{newPassword.length}</strong>{' '}
                        {newPassword.length === 1 ? 'character' : 'characters'}{' '}
                        long{' '}
                        {newPassword.length < MIN_PASSWORD_LENGTH ? '👎' : '👍'}
                    </p>
                }
                value={newPassword}
                onChange={(evt) => setNewPassword(evt.target.value)}
                hasError={!!passwordErrors.newPassword}
                errorMessage={<p>{passwordErrors.newPassword}</p>}
            />
        </Box>
        <Box margin={[0, 0, 'large']}>
            <Input
                type="password"
                maxLength={128}
                required
                label="Confirm Your New Password"
                value={confirmPassword}
                onChange={(evt) => setConfirmPassword(evt.target.value)}
                hasError={!!passwordErrors.confirmPassword}
                errorMessage={<p>{passwordErrors.confirmPassword}</p>}
            />
        </Box>
        <FormErrors passwordErrors={passwordErrors} />
        <Row justifyContent="flex-end">
            <Col span="auto">
                <Button
                    type="submit"
                    disabled={Object.keys(passwordErrors).length > 0}
                    onClick={onSavePassword}
                >
                    Change Your Password
                </Button>
            </Col>
        </Row>
    </>
);

function UserSettingsPasswordChange({
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    passwordErrors,
    onSavePassword,
    saving,
}) {
    return (
        <Layout
            sidebar={<Navigation />}
            header={
                <TopBar
                    breadcrumbs={[
                        <Crumb key="home" to="/" label="4D" />,
                        <Crumb
                            key="settings"
                            to="/settings/"
                            label="Settings"
                            isCollapsible
                        />,
                        <Crumb
                            key="profile"
                            to="/settings/your-profile/"
                            label="Your Profile"
                            isCollapsible
                        />,
                        <Crumb key="password" label="Password" />,
                    ]}
                />
            }
        >
            {saving ? <LoadingLayer message="Changing Password" /> : null}

            <Container size="small">
                <Box padding={['large', 0]}>
                    <Form>
                        <Panel bordered>
                            <Box padding="base">
                                <PasswordChangeForm
                                    oldPassword={oldPassword}
                                    newPassword={newPassword}
                                    confirmPassword={confirmPassword}
                                    setOldPassword={setOldPassword}
                                    setNewPassword={setNewPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    passwordErrors={passwordErrors}
                                    onSavePassword={onSavePassword}
                                />
                            </Box>
                        </Panel>
                    </Form>
                </Box>
            </Container>
        </Layout>
    );
}

export default UserSettingsPasswordChange;
