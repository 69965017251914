import React from 'react';
import Box from 'js/components/box/box';
import ruleTypes from 'js/enums/rule-types.enum';
import { RuleSet } from 'js/utils';
import Alert from 'js/components/alert/alert';
import SwitchButton from 'js/components/button/switch-button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Panel from 'js/components/panel/panel';
import Sparkline from 'js/components/sparkline/sparkline';
import Text from 'js/components/text/text';
import Recommendation from './recommendation';
import recommendationAttributes from './enums/attributes.enum';
import recommendationActions from './enums/actions.enum';
import SectionWrapper from './section-wrapper';
import { getRecommendationAppliedCount, formatKpi } from './utils';

const sortRecommendations = (
    { attributeA, actionA },
    { attributeB, actionB },
) => {
    if (attributeA !== attributeB) {
        return attributeA === recommendationAttributes.TOPICS ? -1 : 1;
    }
    if (actionA !== actionB) {
        return actionA === recommendationActions.ADD ? -1 : 1;
    }
    return 0;
};

const AllOptimizationsSwitch = ({
    recommendations,
    context,
    onChangeContextRules,
}) => {
    const appliedCount = recommendations
        .map((r) => getRecommendationAppliedCount(r, context))
        .reduce((a, b) => a + b, 0);
    const totalCount = recommendations.map((i) => i.entities).flat().length;

    const isAllApplied = appliedCount === totalCount;

    const ruleSet = new RuleSet(context.rules);

    const onApplyAll = () => {
        const addTopics = [];
        const addLogos = [];
        const removeTopics = [];
        const removeLogos = [];
        recommendations.forEach(({ attribute, action, entities }) => {
            const changedEntities = entities.map((i) => i.name);
            if (attribute === recommendationAttributes.TOPICS) {
                (action === recommendationActions.ADD
                    ? addTopics
                    : removeTopics
                ).push(...changedEntities);
            } else {
                (action === recommendationActions.ADD
                    ? addLogos
                    : removeLogos
                ).push(...changedEntities);
            }
        });
        const firstRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
        );
        ruleSet.changeRule(firstRule.key, { addTopics, addLogos });
        ruleSet.changeAllRules({ removeTopics, removeLogos });
        onChangeContextRules(ruleSet.rules);
    };

    const onUnapplyAll = () => {
        const addTopics = [];
        const addLogos = [];
        const removeTopics = [];
        const removeLogos = [];
        recommendations.forEach(({ attribute, action, entities }) => {
            const changedEntities = entities.map((i) => i.name);
            if (attribute === recommendationAttributes.TOPICS) {
                (action === recommendationActions.ADD
                    ? removeTopics
                    : addTopics
                ).push(...changedEntities);
            } else {
                (action === recommendationActions.ADD
                    ? removeLogos
                    : addLogos
                ).push(...changedEntities);
            }
        });
        const firstRule = context.rules.find(
            ({ aggregation }) => aggregation === ruleTypes.INCLUDED,
        );
        ruleSet.changeRule(firstRule.key, { addTopics, addLogos });
        ruleSet.changeAllRules({ removeTopics, removeLogos });
        onChangeContextRules(ruleSet.rules);
    };

    return (
        <Row alignItems="center">
            <Col span={12} spanSm="equal">
                <Text weight="bold">
                    {appliedCount}/{totalCount} optimizations applied
                </Text>
                <Box
                    margin={['smaller', 0, 'base']}
                    marginSm={['smaller', 0, 0]}
                >
                    <Sparkline
                        value={appliedCount}
                        maxValue={totalCount}
                        noLabel
                        color={['purple', 'base']}
                    />
                </Box>
            </Col>
            <Col span="auto">
                <SwitchButton
                    onChange={isAllApplied ? onUnapplyAll : onApplyAll}
                    active={isAllApplied}
                >
                    Apply All Optimizations
                </SwitchButton>
            </Col>
        </Row>
    );
};

function Optimizations({
    report,
    context,
    onChangeContextRules,
    isDV360Report,
}) {
    const infoText = (kpi) =>
        `${kpi} Recommendations are generated using 4D's statistical power analysis with the aim of discovering changes you can make to your contexts which will improve the ${kpi} performance of your advertising.`;

    const recommendationsByKpi = report.recommendations.reduce((acc, value) => {
        if (!acc[value.target_metric]) {
            acc[value.target_metric] = [];
        }
        acc[value.target_metric].push(value);
        return acc;
    }, {});

    const kpis = Object.keys(recommendationsByKpi).sort();

    if (!kpis.length) {
        return (
            <SectionWrapper title="Optimizations" info={infoText('')}>
                <Box margin={['large', 0]}>
                    <Alert theme="empty" title="No Optimizations Identified" />
                </Box>
            </SectionWrapper>
        );
    }

    return (
        <>
            {kpis.map((kpi) => {
                const recommendations = recommendationsByKpi[kpi];
                return (
                    <SectionWrapper
                        key={kpi}
                        title={`${formatKpi(kpi, isDV360Report)} Optimizations`}
                        info={infoText(formatKpi(kpi, isDV360Report))}
                    >
                        <Box margin={['base', 0, 0]}>
                            <Panel theme="secondary">
                                <Box padding="base">
                                    <AllOptimizationsSwitch
                                        recommendations={recommendations}
                                        context={context}
                                        onChangeContextRules={
                                            onChangeContextRules
                                        }
                                    />
                                </Box>
                            </Panel>
                        </Box>
                        {recommendations.sort(sortRecommendations).map((r) => (
                            <Box
                                key={r.action + r.attribute}
                                margin={['large', 0, 0]}
                            >
                                <Recommendation
                                    recommendation={r}
                                    report={report}
                                    context={context}
                                    onChangeContextRules={onChangeContextRules}
                                    isDV360Report={isDV360Report}
                                />
                            </Box>
                        ))}
                    </SectionWrapper>
                );
            })}
        </>
    );
}

export default function RecommendationsTab({
    report,
    context,
    onChangeContextRules,
    isDV360Report,
}) {
    return (
        <Optimizations
            report={report}
            context={context}
            onChangeContextRules={onChangeContextRules}
            isDV360Report={isDV360Report}
        />
    );
}
