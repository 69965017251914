import { fontWeight, spacing } from '../components/tokens.styles';

const pagePadding = spacing.large;
const coverStripWidthPortrait = 165;
const coverStripWidthLandscape = 475;

const styles = {
    coverStrip: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100vh',
    },

    coverTitleLandscape: {
        position: 'absolute',
        top: pagePadding + 95,
        left: pagePadding,
        paddingRight: coverStripWidthLandscape + pagePadding,
    },

    coverTitlePortrait: {
        position: 'absolute',
        top: pagePadding + 95,
        left: pagePadding,
        paddingRight: coverStripWidthPortrait + pagePadding,
    },

    advertiserLandscape: {
        fontWeight: 600,
        fontSize: 50,
        lineHeight: 1.11,
        marginBottom: 4,
    },

    advertiserPortrait: {
        fontWeight: 600,
        fontSize: 40,
        lineHeight: 1,
        marginBottom: 2,
    },

    reportLandscape: {
        fontWeight: 200,
        fontSize: 50,
        lineHeight: 1.11,
        marginBottom: 34,
    },

    reportPortrait: {
        fontWeight: 200,
        fontSize: 40,
        lineHeight: 1,
        marginBottom: 30,
    },

    description: {
        width: 285,
        fontSize: 15,
    },

    coverContents: {
        position: 'absolute',
        bottom: pagePadding,
        left: pagePadding,
        fontSize: 15,
    },

    coverContentItem: {
        fontWeight: 700,
        marginTop: 15,
    },

    bold: {
        fontWeight: fontWeight.bold,
    },
};

export default styles;
