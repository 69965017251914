import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api, getJwtPayload, setDocumentTitle } from 'js/utils';
import TermsAndConditions from './terms-and-conditions';

export default function TermsAndConditionsPage() {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [savingError, setSavingError] = useState(false);
    const [text, setText] = useState('');

    const loadData = async () => {
        setLoading(true);
        setLoadingError(false);
        try {
            const { terms_and_conditions: currentText } =
                await api().termsAndConditions.retrieve('current');
            setText(currentText);
        } catch {
            setLoadingError(true);
        } finally {
            setLoading(false);
        }
    };

    const acceptTerms = async () => {
        const { username } = getJwtPayload(localStorage.getItem('AuthToken'));

        setSaving(true);
        setSavingError(false);
        try {
            const { token } = await api().acceptTermsAndConditions(username);
            localStorage.setItem('AuthToken', token);
            await history.push('/');
        } catch {
            setSavingError(true);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        setDocumentTitle(['Terms & Conditions']);

        loadData();
        return () => {
            // only abort requests if text was loaded
            if (!text) {
                api().abortAll();
            }
        };
    }, [text]);

    return (
        <TermsAndConditions
            loading={loading}
            loadingError={loadingError}
            saving={saving}
            savingError={savingError}
            onAccept={acceptTerms}
            onReload={loadData}
        >
            {text}
        </TermsAndConditions>
    );
}
