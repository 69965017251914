import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'media/images/logos/logo.svg';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Text from 'js/components/text/text';
import styles from '../login.module.scss';

const LoginHeader = () => (
    <Box padding={['base', 0]}>
        <Row>
            <Col>
                <Text weight="bolder" size="largest">
                    <h1>Login to 4D</h1>
                </Text>

                <Box margin={['smaller', 0, 0]}>
                    <Text color={['gray', 'dark']}>
                        <p>Contextual intelligence and brand suitability.</p>
                    </Text>
                </Box>
            </Col>

            <Col span="auto">
                <img className={styles.logo} src={logo} alt="4D" />
            </Col>
        </Row>
    </Box>
);

const FormErrors = ({ errors }) => {
    let content;

    if (!Object.keys(errors).length) return null;

    if (errors.non_field_errors) {
        // user credentials invalid
        content = `You were unable to login because the email and password you 
            provided were incorrect. Please check them before trying again.`;
    } else if (errors.email || errors.password) {
        content = `You can't login at the moment because there are problems that 
            have been highlighted in the form above.`;
    } else if (errors.detail) {
        content = errors.detail;
    } else {
        // some unknown problem
        content = `You were unable to login because there was an unexpected error. 
            Please try again in a few moments.`;
    }

    return (
        <Box padding={['base', 0]}>
            <Alert theme="danger">
                <p>{content}</p>
            </Alert>
        </Box>
    );
};

function LoginForm({ errors, onUpdateEmail, onUpdatePassword, onLoginUser }) {
    return (
        <div className={styles['login-form']}>
            <LoginHeader />

            <Form>
                <Box padding={['small', 0]}>
                    <Box padding={['small', 0]}>
                        <Input
                            focusOnShow
                            label="Email"
                            maxLength={150}
                            onChange={(evt) => onUpdateEmail(evt.target.value)}
                            hasError={errors.email}
                            errorMessage={
                                <p>An email address is required to login.</p>
                            }
                        />
                    </Box>

                    <Box padding={['small', 0]}>
                        <Input
                            label="Password"
                            type="password"
                            maxLength={128}
                            onChange={(evt) =>
                                onUpdatePassword(evt.target.value)
                            }
                            hasError={errors.password}
                            errorMessage={
                                <p>A password is required to login.</p>
                            }
                        />
                    </Box>
                </Box>

                <FormErrors errors={errors} />

                <Box padding={['small', 0]}>
                    <Box margin={['small', 0]}>
                        <Text inline color={['gray', 'dark']}>
                            Don’t have an account?
                        </Text>{' '}
                        <Text inline weight="bold">
                            <Link
                                to={{
                                    pathname:
                                        'https://wearesilverbullet.com/contact/',
                                }}
                                target="_blank"
                            >
                                Contact us
                            </Link>
                        </Text>
                    </Box>

                    <Box margin={['small', 0]}>
                        <Button theme="outline" to="/password-reset/">
                            Forgot Your Password?
                        </Button>
                    </Box>

                    <Box margin={['small', 0]}>
                        <Button
                            disabled={errors.email || errors.password}
                            type="submit"
                            onClick={onLoginUser}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Form>
        </div>
    );
}

export default LoginForm;
