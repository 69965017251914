import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { counter } from 'js/utils';
import Popper from 'js/components/popper/popper';
import styles from './pill.module.scss';

const LayerCounter = counter();

function Pill({
    children,
    light,
    onPrefixClick,
    onSuffixClick,
    outline,
    prefixButton,
    prefixDropdownContent,
    size,
    suffixButton,
    suffixDropdownContent,
    theme,
}) {
    const prefixAnchorNode = useRef();
    const prefixContentNode = useRef();
    const suffixAnchorNode = useRef();
    const suffixContentNode = useRef();
    const [showSuffixMenu, setShowSuffixMenu] = useState(false);
    const [showPrefixMenu, setShowPrefixMenu] = useState(false);
    const toggleShowSuffixMenu = () => {
        setShowSuffixMenu((previous) => !previous);
    };
    const layerId = useState(`layer-pill-${LayerCounter.next().value}`)[0];
    const toggleShowPrefixMenu = (evt) => {
        if (
            !showPrefixMenu ||
            (prefixContentNode &&
                prefixContentNode.current &&
                !prefixContentNode.current.contains(evt.target))
        ) {
            setShowPrefixMenu((previous) => !previous);
        }
    };
    useEffect(() => {
        const handleClick = (evt) => {
            if (!document.getElementById(layerId).contains(evt.target)) {
                setShowPrefixMenu(false);
            }
            if (!document.getElementById(layerId).contains(evt.target)) {
                setShowSuffixMenu(false);
            }
        };
        if (showPrefixMenu || showSuffixMenu) {
            document.addEventListener('mousedown', handleClick);
        } else {
            document.removeEventListener('mousedown', handleClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [showPrefixMenu, showSuffixMenu, layerId]);
    const classes = [styles.container];
    const wrapperClasses = [styles.wrapper];
    if (prefixButton) {
        classes.push(styles['container--has-prefix-button']);
    }
    if (suffixButton) {
        classes.push(styles['container--has-suffix-button']);
    }
    if (light) {
        wrapperClasses.push(styles.light);
    } else if (outline) {
        wrapperClasses.push(styles.outline);
    }
    if (size) {
        wrapperClasses.push(styles[`size--${size}`]);
    }
    if (theme) {
        wrapperClasses.push(styles[`theme--${theme}`]);
    }
    const renderSuffixButton = () => {
        const buttonClasses = [styles['suffix-button']];
        if (showSuffixMenu) {
            buttonClasses.push(styles.active);
        }
        if (suffixButton) {
            if (suffixDropdownContent) {
                return (
                    <span>
                        <button
                            type="button"
                            className={classNames(buttonClasses)}
                            onClick={toggleShowSuffixMenu}
                            ref={suffixAnchorNode}
                        >
                            <Popper
                                content={
                                    <div
                                        className={styles['dropdown-container']}
                                        ref={suffixContentNode}
                                    >
                                        {suffixDropdownContent}
                                    </div>
                                }
                                theme="tooltip"
                                placement="bottom-start"
                                showContent={showSuffixMenu}
                                layerId={layerId}
                            >
                                {suffixButton}
                            </Popper>
                        </button>
                    </span>
                );
            }
            return (
                <span>
                    <button
                        type="button"
                        className={styles['suffix-button']}
                        onClick={() => onSuffixClick()}
                    >
                        {suffixButton}
                    </button>
                </span>
            );
        }
        return null;
    };
    const renderPrefixButton = () => {
        const buttonClasses = [styles['prefix-button']];
        if (showPrefixMenu) {
            buttonClasses.push(styles.active);
        }
        if (prefixButton) {
            if (prefixDropdownContent) {
                return (
                    <span>
                        <button
                            type="button"
                            className={classNames(buttonClasses)}
                            onClick={toggleShowPrefixMenu}
                            ref={prefixAnchorNode}
                        >
                            <Popper
                                content={
                                    <div
                                        className={styles['dropdown-container']}
                                        ref={prefixContentNode}
                                    >
                                        {prefixDropdownContent}
                                    </div>
                                }
                                theme="tooltip"
                                placement="bottom-start"
                                showContent={showPrefixMenu}
                                layerId={layerId}
                            >
                                {prefixButton}
                            </Popper>
                        </button>
                    </span>
                );
            }
            return (
                <span>
                    <button
                        type="button"
                        className={styles['prefix-button']}
                        onClick={() => onPrefixClick()}
                    >
                        {prefixButton}
                    </button>
                </span>
            );
        }
        return null;
    };
    return (
        <div id={layerId} className={classNames(styles['layer-pill'])}>
            <div className={classNames(wrapperClasses)}>
                {renderPrefixButton()}
                <div className={classNames(classes)}>{children}</div>
                {renderSuffixButton()}
            </div>
        </div>
    );
}

Pill.defaultProps = {
    light: false,
    onPrefixClick: undefined,
    onSuffixClick: undefined,
    outline: false,
    prefixButton: undefined,
    prefixDropdownContent: undefined,
    size: 'base',
    suffixButton: undefined,
    suffixDropdownContent: undefined,
    theme: undefined,
};

Pill.propTypes = {
    children: PropTypes.node.isRequired,
    light: PropTypes.bool,
    onPrefixClick: PropTypes.func,
    onSuffixClick: PropTypes.func,
    outline: PropTypes.bool,
    prefixButton: PropTypes.node,
    prefixDropdownContent: PropTypes.node,
    size: PropTypes.oneOf(['small', 'base', 'large']),
    suffixButton: PropTypes.node,
    suffixDropdownContent: PropTypes.node,
    theme: PropTypes.oneOf([
        'danger',
        'muted',
        'muted-light',
        'keyword',
        'default-darker',
        'blue',
        'blue-light',
    ]),
};

export default Pill;
