import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import ClickArea from 'js/components/click-area/click-area';
import Collapse from 'js/components/collapse/collapse';
import Container from 'js/components/container/container';
import Section from 'js/components/section/section';
import SectionTitle from 'js/components/section-title/section-title';
import styles from './section-wrapper.module.scss';

export default function SectionWrapper({
    title,
    children,
    toggleCollapse,
    isOpen,
}) {
    return (
        <Section hasBorderBottom>
            <Box padding={['large', 0]}>
                <Container>
                    <ClickArea onClick={toggleCollapse}>
                        <header className={styles.header}>
                            <SectionTitle>{title}</SectionTitle>

                            <div className={styles.icon}>
                                <FontAwesomeIcon
                                    icon={isOpen ? faChevronUp : faChevronDown}
                                />
                            </div>
                        </header>
                    </ClickArea>
                    <Collapse isOpen={isOpen}>
                        <Box padding={['large', 0, 0]}>{children}</Box>
                    </Collapse>
                </Container>
            </Box>
        </Section>
    );
}
