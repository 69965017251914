import { useEffect } from 'react';
import { reactLayerPortal, reactRoot } from '../utils';

function useClickOutside(refs, onClickOutside) {
    useEffect(() => {
        const handleClick = (evt) => {
            const someRefContainTarget = [...refs]
                .filter((r) => r.current)
                .some((r) => r.current.contains(evt.target));

            const refExistsInDom = [reactRoot, reactLayerPortal].some((r) =>
                r.contains(evt.target),
            );

            if (!someRefContainTarget && refExistsInDom) {
                onClickOutside(evt);
            }
        };

        [reactRoot, reactLayerPortal].forEach((item) =>
            item.addEventListener('click', handleClick),
        );

        return () => {
            [reactRoot, reactLayerPortal].forEach((item) =>
                item.removeEventListener('click', handleClick),
            );
        };
    }, [refs, onClickOutside]);
}

export default useClickOutside;
