import React, { useState } from 'react';
import { getJwtPayload } from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Notification from 'js/components/notification/notification';
import Panel from 'js/components/panel/panel';
import TabContent from 'js/components/tabs/content';
import Tabs from 'js/components/tabs/tabs';
import notificationCategories, {
    notificationCategoryGroups,
} from 'js/enums/notification-categories.enum';
import serviceTypes from 'js/enums/service-types.enum';
import pageSections from '../enums/page-sections.enum';
import SectionWrapper from './section-wrapper/section-wrapper';

const Tab = ({ notifications, onAction }) => {
    if (!notifications.length) {
        return (
            <Box padding={[0, 0, 'base', 0]}>
                <Alert theme="empty" padding="large">
                    <p>You currently have no notifications.</p>
                </Alert>
            </Box>
        );
    }

    return (
        <>
            {notifications.map((notification) => (
                <Box
                    key={notification.id}
                    margin={['small', 0]}
                    marginLg={['base', 0]}
                >
                    <Notification
                        notification={notification}
                        onAction={() => onAction(notification)}
                    />
                </Box>
            ))}

            <Box margin={['base', 0]}>
                <Row>
                    <Col span="auto">
                        <Button theme="outline" to="/notifications/">
                            See All Notifications
                        </Button>
                    </Col>
                </Row>
            </Box>
        </>
    );
};

export default function Notifications({
    sectionTitle,
    notifications,
    pageLayout,
    onAction,
    onSavePageLayout,
}) {
    const { services } = getJwtPayload(localStorage.getItem('AuthToken'));
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );

    const pageTabs = [
        {
            id: notificationCategoryGroups.ALL,
            label: 'All',
        },
        {
            id: notificationCategoryGroups.DEAL_IDS,
            label: 'Deal IDs',
        },
        {
            id: notificationCategoryGroups.CONTEXTS,
            label: 'Contexts',
        },
    ];

    if (insightsService) {
        pageTabs.splice(2, 0, {
            id: notificationCategoryGroups.INSIGHTS,
            label: 'Insights Reports',
        });
    }

    const LIST_LIMIT = 4;
    const [currentTabId, setCurrentTabId] = useState(pageTabs[0].id);

    const allNotifications = notifications.slice(0, LIST_LIMIT);
    const dealIdNotifications = notifications.filter((notification) =>
        [
            notificationCategories.DEAL_PAUSED,
            notificationCategories.DEAL_UNPAUSED,
            notificationCategories.DEAL_READY,
            notificationCategories.DEAL_WILL_BE_PAUSED,
        ].includes(notification.category),
    );
    const insightsNotifications = notifications
        .filter((notification) =>
            [
                notificationCategories.OPTIMISE_REPORT_READY,
                notificationCategories.EVENT_LOG_REPORT_READY,
                notificationCategories.CAMPAIGN_INACTIVE,
                notificationCategories.VIDEO_REPORT_READY,
            ].includes(notification.category),
        )
        .slice(0, LIST_LIMIT);
    const contextNotifications = notifications
        .filter((notification) =>
            [
                notificationCategories.AUTO_OPTIMIZATION_APPLIED,
                notificationCategories.AUTO_OPTIMIZATION_INCOMPLETE,
                notificationCategories.AUTO_OPTIMIZATION_STOPPED,
            ].includes(notification.category),
        )
        .slice(0, LIST_LIMIT);

    const notificationsSection = pageLayout.layout_sections.find(
        (layout) => layout.section === pageSections.NOTIFICATIONS,
    );
    const notificationsIndex =
        pageLayout.layout_sections.indexOf(notificationsSection);

    const [showCollapse, setShowCollapse] = useState(
        !notificationsSection.is_collapsed,
    );

    const toggleCollapse = () => {
        setShowCollapse((prevState) => !prevState);
        notificationsSection.is_collapsed = !notificationsSection.is_collapsed;
        const layoutSections = [...pageLayout.layout_sections];
        layoutSections[notificationsIndex] = notificationsSection;
        onSavePageLayout(layoutSections);
    };

    const buttonsWrapper = ({ children }) => (
        <Box margin={['base', 0]} marginLg={['large', 0]}>
            <Row justifyContent="center">
                <Col span="auto">{children}</Col>
            </Row>
        </Box>
    );

    return (
        <SectionWrapper
            title={sectionTitle}
            isOpen={showCollapse}
            toggleCollapse={toggleCollapse}
        >
            <Panel bordered>
                <Box padding={[0, 'base']}>
                    <Tabs
                        tabs={pageTabs}
                        currentTab={currentTabId}
                        buttonsWrapper={buttonsWrapper}
                        onChangeTab={setCurrentTabId}
                    >
                        <TabContent tabId={notificationCategoryGroups.ALL}>
                            <Tab
                                notifications={allNotifications}
                                onAction={onAction}
                            />
                        </TabContent>

                        <TabContent tabId={notificationCategoryGroups.DEAL_IDS}>
                            <Tab
                                notifications={dealIdNotifications}
                                onAction={onAction}
                            />
                        </TabContent>

                        <TabContent tabId={notificationCategoryGroups.INSIGHTS}>
                            <Tab
                                notifications={insightsNotifications}
                                onAction={onAction}
                            />
                        </TabContent>

                        <TabContent tabId={notificationCategoryGroups.CONTEXTS}>
                            <Tab
                                notifications={contextNotifications}
                                onAction={onAction}
                            />
                        </TabContent>
                    </Tabs>
                </Box>
            </Panel>
        </SectionWrapper>
    );
}
