import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'js/components/button/button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';

function EllipsisButton({ size, children, onClick }) {
    const [showDropdown, setShowDropdown] = useState(false);
    return (
        <DropdownMenu
            content={
                <ul>
                    {Children.map(
                        (Array.isArray(children)
                            ? children
                            : [children]
                        ).filter(Boolean),
                        (c) => ({
                            ...c,
                            props: {
                                ...c.props,
                                onClick: !c.props.to
                                    ? (e) => {
                                          e.stopPropagation();
                                          if (c.props.onClick) {
                                              setShowDropdown(false);
                                              c.props.onClick(e);
                                          }
                                      }
                                    : undefined,
                            },
                        }),
                    )}
                </ul>
            }
            onHide={() => setShowDropdown(false)}
            showMenu={showDropdown}
            placement="bottom-end"
        >
            <Button
                square
                theme="outline"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (onClick) onClick(e);
                    setShowDropdown((curr) => !curr);
                }}
                active={showDropdown}
                size={size}
            >
                <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
        </DropdownMenu>
    );
}

EllipsisButton.defaultProps = {
    size: 'base',
};

EllipsisButton.propTypes = {
    size: PropTypes.oneOf(['base', 'small', 'large', 'smaller']),
    children: PropTypes.node.isRequired,
};

export default EllipsisButton;
