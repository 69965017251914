import { useEffect, useRef } from 'react';

/**
 * merge refs to use the single reference value
 * @param {...React.RefObject} refs
 * @returns {React.RefObject}
 * @see https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 */
function useCombinedRefs(...refs) {
    const targetRef = useRef();

    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current; // eslint-disable-line no-param-reassign
            }
        });
    }, [refs]);

    return targetRef;
}

export default useCombinedRefs;
