import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './layout.module.scss';

const cx = classNames.bind(styles);

function Layout({
    alignContent,
    children,
    sidebar,
    header,
    footer,
    isFooterSticky,
}) {
    const contentClasses = cx({
        content: true,
        [`content--align-${alignContent}`]: alignContent,
    });

    const footerClasses = cx({
        footer: true,
        'footer--sticky': isFooterSticky,
    });

    return (
        <div className={styles.wrapper}>
            {sidebar && <div className={styles.sidebar}>{sidebar}</div>}

            <div className={styles.container} id="layout-container">
                {header && <div className={styles.header}>{header}</div>}

                {children && (
                    <div className={classNames(contentClasses)}>{children}</div>
                )}

                {footer && <div className={footerClasses}>{footer}</div>}
            </div>
        </div>
    );
}

Layout.defaultProps = {
    alignContent: undefined,
    children: undefined,
    sidebar: undefined,
    header: undefined,
    footer: undefined,
    isFooterSticky: false,
};

Layout.propTypes = {
    alignContent: PropTypes.oneOf(['center', 'fixed']),
    children: PropTypes.node,
    sidebar: PropTypes.node,
    header: PropTypes.node,
    footer: PropTypes.node,
    isFooterSticky: PropTypes.bool,
};

export default Layout;
