/* eslint-disable react/jsx-props-no-spreading */

import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import filterTypes from 'js/enums/filter-types.enum';
import permissionGroups from 'js/enums/permission-groups.enum';
import sortTypes from 'js/enums/sort-types.enum';
import { sortOptions } from 'js/constants';
import { api, getDisplayName, getJwtPayload, getUpdatedList } from 'js/utils';

const filterOptionsMap = {
    [filterTypes.ORDERING]: sortOptions,
};

export default function withAdvertiserList(Wrapped) {
    function WithAdvertiserList(props) {
        const [loading, setLoading] = useState(true);
        const [errors, setErrors] = useState({});
        const [advertisers, setAdvertisers] = useState([]);
        const [ordering, setOrdering] = useState(sortTypes.UPDATED_DESC);
        const [togglingFavoriteError, setTogglingFavoriteError] = useState({});

        const history = useHistory();

        const jwt = getJwtPayload(localStorage.getItem('AuthToken'));
        const isViewOnlyUser =
            !jwt.is_superuser && jwt.roles.includes(permissionGroups.VIEW_ONLY);

        const initFilterParams = () => {
            const filterParams = Object.fromEntries(
                new URLSearchParams(history.location.search).entries(),
            );
            const selectedOrdering =
                filterParams[filterTypes.ORDERING] || sortTypes.UPDATED_DESC;

            if (selectedOrdering === sortTypes.UPDATED_ASC) {
                filterParams[filterTypes.ORDERING] = sortTypes.DEEP_UPDATED_ASC;
            } else if (selectedOrdering === sortTypes.UPDATED_DESC) {
                filterParams[filterTypes.ORDERING] =
                    sortTypes.DEEP_UPDATED_DESC;
            }

            setOrdering(selectedOrdering);

            return filterParams;
        };

        const loadData = async () => {
            const filterParams = initFilterParams();
            delete filterParams.search;

            setLoading(true);
            setErrors({});

            try {
                const { results } = await api().advertisers.list({
                    ordering: '-deep_updated',
                    verbose: true,
                    ...filterParams,
                });

                setAdvertisers(results);
            } catch (err) {
                setErrors({
                    ...errors,
                    advertisersLoadingError: 'Failed to load advertisers.',
                });
            } finally {
                setLoading(false);
            }
        };

        const toggleFavoriteAdvertiser = async (advertiserId) => {
            const advertiser = advertisers.find(
                (item) => item.id === advertiserId,
            );
            const isFavorite = !advertiser.is_favourite;

            setAdvertisers((prevState) =>
                getUpdatedList(prevState, advertiserId, {
                    isFavoriteLoading: true,
                }),
            );

            let success = false;
            setTogglingFavoriteError({});
            try {
                await api().advertisers.favorite(advertiserId, isFavorite);
                success = true;
            } catch (err) {
                setTogglingFavoriteError({
                    title: advertiser.name,
                    isFavorite,
                });
            } finally {
                setAdvertisers((prevState) =>
                    getUpdatedList(prevState, advertiserId, {
                        is_favourite: success ? isFavorite : !isFavorite,
                        isFavoriteLoading: false,
                    }),
                );
            }
        };

        const updateFilter = async (filterType, filterValue) => {
            const params = new URLSearchParams(history.location.search);
            const defaultValue = filterOptionsMap[filterType][0].value;

            if (filterValue === defaultValue) {
                params.delete(filterType);
            } else {
                params.set(filterType, filterValue);
            }

            await history.replace({
                ...history.location,
                search: params.toString(),
            });

            await loadData();
        };

        const deleteAdvertiser = async (advertiserId) => {
            await api().advertisers.destroy(advertiserId);
            await loadData();
        };

        useLayoutEffect(() => {
            loadData();

            return () => {
                api().abortAll();
            };
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <Wrapped
                {...props}
                loading={loading}
                errors={errors}
                readOnly={isViewOnlyUser}
                advertisers={advertisers}
                ordering={ordering}
                togglingFavoriteError={togglingFavoriteError}
                onToggleFavoriteAdvertiser={toggleFavoriteAdvertiser}
                onUpdateFilter={updateFilter}
                onDeleteAdvertiser={deleteAdvertiser}
            />
        );
    }

    WithAdvertiserList.displayName = `withAdvertiserList(${getDisplayName(
        Wrapped,
    )})`;

    return WithAdvertiserList;
}
