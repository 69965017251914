import React, { useLayoutEffect, useReducer } from 'react';
import { api, setDocumentTitle } from 'js/utils';
import IntegrationList from './integration-list';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';

const stateReducer = (state, action) => {
    switch (action.type) {
        case FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                integrations: action.payload,
            };
        case FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

export default function IntegrationListPage() {
    const [state, dispatch] = useReducer(stateReducer, {
        isLoading: true,
        isError: false,
        integrations: [],
    });

    const { isLoading, isError, integrations } = state;

    const loadData = async () => {
        dispatch({ type: FETCH_INIT });

        try {
            const { results: integrationsData } = await api().integrations.list(
                {
                    ordering: 'name',
                    verbose: true,
                },
            );

            dispatch({ type: FETCH_SUCCESS, payload: integrationsData });
        } catch (err) {
            dispatch({ type: FETCH_FAILURE });
        }
    };

    useLayoutEffect(() => {
        setDocumentTitle(['Channels', 'Settings']);
        loadData();

        return () => api().abortAll();
    }, []);

    return (
        <IntegrationList
            isLoading={isLoading}
            isError={isError}
            integrations={integrations}
        />
    );
}
