import React, { useEffect, useState } from 'react';
import Drawer from 'js/components/drawer/drawer';
import { api } from 'js/utils';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import DrawerContent from './drawer-content';
import dealReportMetricsInitial from './deal-report-metrics-initial';

const DealDeliveryReportDrawer = ({ deal, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [dealReportMetrics, setDealReportMetrics] = useState(
        dealReportMetricsInitial,
    );

    const getSumForKey = (key, data) =>
        data.reduce((prev, curr) => prev + curr[key], 0);

    const loadData = async () => {
        setIsLoading(true);
        setHasError(false);

        try {
            const { bid_metadata: dealData } = await api().deals.retrieve(
                deal.id,
                {
                    verbose: true,
                },
            );

            if (dealData.length > 0) setHasData(true);

            const updatedMetricsData = dealReportMetricsInitial.map(
                (metric) => ({
                    ...metric,
                    value: getSumForKey(metric.key, dealData),
                }),
            );

            setDealReportMetrics(updatedMetricsData);
        } catch (err) {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Drawer onClose={onClose}>
            {isLoading ? (
                <LoadingLayer position="absolute" />
            ) : (
                <DrawerContent
                    deal={deal}
                    hasError={hasError}
                    hasData={hasData}
                    dealReportMetrics={dealReportMetrics}
                    onClose={onClose}
                />
            )}
        </Drawer>
    );
};

export default DealDeliveryReportDrawer;
