import React from 'react';
import { Page } from '@react-pdf/renderer';
import { getNumber } from 'js/components/number/number';
import { getImpressionThreshold } from 'js/utils';
import { pageSizes } from '../constants';
import Aside from '../components/aside/aside';
import AnnotatedChart from '../components/annotated-chart/annotated-chart';
import Logos from '../components/logos/logos';
import PageContent from '../components/page-content/page-content';
import Groups from '../components/groups/groups';
import NoDataAlert from '../components/no-data-alert/no-data-alert';
import styles from '../report.styles';
import {
    getCompletionRate,
    getVideoReportTopicRecommendations,
} from '../utils';

export default function VideoTopicThemesPage({
    orientation,
    logo4D,
    brandLogo,
    charts,
    report,
    isDV360Report,
}) {
    const impressionThreshold = getImpressionThreshold(report.impressions);
    const isPortrait = orientation === 'portrait';
    const pageClasses = [
        styles.page,
        isPortrait ? styles.pagePortrait : styles.pageLandscape,
    ];

    if (logo4D || brandLogo) {
        pageClasses.push(isPortrait ? null : styles.pageLandscapeWithLogo);
    }

    const recommendations = getVideoReportTopicRecommendations(
        report,
        isDV360Report,
    );

    const formatGroups = (topicGroups) =>
        [...topicGroups].map((i) => ({
            icon: i.logobase64 || null,
            label: i.topic_group_name,
            impressions: getNumber({
                value: i.impressions,
                maximumFractionDigits: 0,
            }),
            starts: getNumber({
                value: i.starts,
                maximumFractionDigits: 0,
            }),
            completionRate:
                getCompletionRate(report) > 0 &&
                getNumber({
                    value: getCompletionRate(i),
                    isPercentage: true,
                    maximumFractionDigits: 2,
                }),
            trueViewRate:
                getCompletionRate(report) > 0 &&
                getNumber({
                    value: getCompletionRate(i),
                    isPercentage: true,
                    maximumFractionDigits: 2,
                }),
        }));

    const topGroupsByStartViews = [...report.topic_groups]
        .sort((a, b) => b.starts - a.starts)
        .slice(0, 3)
        .filter((item) => item.impressions >= impressionThreshold);

    const topGroupsByCompletionRate = [...report.topic_groups]
        .sort((a, b) => getCompletionRate(b) - getCompletionRate(a))
        .slice(0, 3)
        .filter((item) => item.impressions >= impressionThreshold);

    const showImpChart = report.topic_groups.some(
        (group) => group.impressions >= impressionThreshold,
    );
    const impChart = showImpChart && (
        <AnnotatedChart
            orientation={orientation}
            annotations={[{ title: 'Top Topic Groups by Impressions' }]}
            chart={charts.TOPIC_GROUPS}
        />
    );

    const startViewsGroups = topGroupsByStartViews.length > 0 && (
        <Groups
            orientation={orientation}
            title="Top Topic Groups by Started Views"
            groups={formatGroups(topGroupsByStartViews)}
            showGroupTitles
            background="#1CBEC9"
            parameters={[
                'impressions',
                'starts',
                isDV360Report ? 'trueViewRate' : 'completionRate',
            ]}
            mainParameter="starts"
            hasBorderTop
        />
    );

    const completionRateGroups = getCompletionRate(report) > 0 &&
        topGroupsByCompletionRate.length > 0 && (
            <Groups
                orientation={orientation}
                title={`Top Topic Groups by ${
                    isDV360Report ? 'True View' : 'Completion'
                } Rate`}
                groups={formatGroups(topGroupsByCompletionRate)}
                showGroupTitles
                background="#4C9AFF"
                parameters={[
                    'impressions',
                    'starts',
                    isDV360Report ? 'trueViewRate' : 'completionRate',
                ]}
                mainParameter={
                    isDV360Report ? 'trueViewRate' : 'completionRate'
                }
                hasBorderTop
            />
        );

    return (
        <Page
            size={isPortrait ? pageSizes.portrait : pageSizes.landscape}
            orientation={orientation}
            style={pageClasses}
        >
            <Aside
                orientation={orientation}
                title="Topic Themes"
                description="Topics are combinations of subjects that share the same overall theme. Use these insights to understand how contexts overlap with your media, and can improve your targeting & performance."
                hasLogo={Boolean(logo4D || brandLogo)}
                recommendations={recommendations}
            />
            <PageContent orientation={orientation}>
                {impChart || startViewsGroups || completionRateGroups ? (
                    <>
                        {impChart}
                        {startViewsGroups}
                        {completionRateGroups}
                    </>
                ) : (
                    <NoDataAlert />
                )}
            </PageContent>
            <Logos
                orientation={orientation}
                logo4D={logo4D}
                brandLogo={brandLogo}
            />
        </Page>
    );
}
