import React from 'react';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { pageSizes } from '../constants';
import Logos from '../components/logos/logos';
import coverStrip from './images/coverstrip.png';
import coverStripLandscape from './images/coverstrip-landscape.png';
import globalStyles from '../report.styles';
import styles from './title-page.styles';

export default function TitlePage({
    pages,
    reportName,
    advertiserName,
    campaignName,
    orientation,
    logo4D,
    brandLogo,
    report,
}) {
    const isPortrait = orientation === 'portrait';
    const startDate = moment(report.start_date).format('Do MMMM YYYY');
    const endDate = moment(report.start_date).format('Do MMMM YYYY');

    return (
        <Page
            size={isPortrait ? pageSizes.portrait : pageSizes.landscape}
            orientation={orientation}
            style={globalStyles.page}
        >
            <View
                style={
                    isPortrait
                        ? styles.coverTitlePortrait
                        : styles.coverTitleLandscape
                }
            >
                {!!advertiserName && (
                    <Text
                        style={
                            isPortrait
                                ? styles.advertiserPortrait
                                : styles.advertiserLandscape
                        }
                    >
                        {advertiserName}
                    </Text>
                )}

                <Text
                    style={
                        isPortrait
                            ? styles.reportPortrait
                            : styles.reportLandscape
                    }
                >
                    {reportName}
                </Text>

                <View style={styles.description}>
                    {campaignName ? (
                        <Text>
                            An Insights Report covering the{' '}
                            <Text style={styles.bold}>{campaignName}</Text>{' '}
                            Campaign between{' '}
                            <Text style={styles.bold}>{startDate}</Text> and{' '}
                            <Text style={styles.bold}>{endDate}</Text>
                        </Text>
                    ) : (
                        <Text>
                            An Insights Report which ran between{' '}
                            <Text style={styles.bold}>{startDate}</Text> and{' '}
                            <Text style={styles.bold}>{endDate}</Text>
                        </Text>
                    )}
                </View>
            </View>

            <View style={styles.coverContents}>
                {pages.map((listItem) => (
                    <Text key={listItem.id} style={styles.coverContentItem}>
                        {listItem.label}
                    </Text>
                ))}
            </View>

            <Logos
                orientation={orientation}
                isTitle
                logo4D={logo4D}
                brandLogo={brandLogo}
            />

            <Image
                src={isPortrait ? coverStrip : coverStripLandscape}
                style={styles.coverStrip}
            />
        </Page>
    );
}
