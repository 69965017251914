import ruleTypes from 'js/enums/rule-types.enum';
import recommendationActions from './enums/actions.enum';

export const getRecommendationAppliedCount = (
    { entities, attribute, action },
    { rules },
) => {
    const entityIds = entities.map((entity) => entity.name);
    const ruleEntities = rules
        .filter(({ aggregation }) => aggregation === ruleTypes.INCLUDED)
        .map((rule) => rule[attribute])
        .flat();
    return entityIds.filter(
        (i) =>
            ruleEntities.includes(i) === (action === recommendationActions.ADD),
    ).length;
};

export const formatKpi = (kpi, isDV360Report) =>
    ({
        ctr: 'CTR',
        cr: isDV360Report ? 'True View Rate' : 'Completion Rate',
    }[kpi]);
