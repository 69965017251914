import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './table.module.scss';

function TableRow({ children, hasBorder, isHeader }) {
    const classes = [styles['table-row']];

    if (hasBorder) {
        classes.push(styles['table-row--has-border']);
    }
    if (isHeader) {
        classes.push(styles['table-row--is-header']);
    }

    return (
        <div className={classNames(classes)}>
            {React.Children.map(
                children,
                (child) =>
                    child && (
                        <div className={styles['table-cell']}>{child}</div>
                    ),
            )}
        </div>
    );
}

TableRow.defaultProps = {
    hasBorder: false,
    isHeader: false,
};

TableRow.propTypes = {
    children: PropTypes.node.isRequired,
    hasBorder: PropTypes.bool,
    isHeader: PropTypes.bool,
};

export default TableRow;
