import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './collapse.module.scss';

const state = {
    SHOW: 'SHOW',
    SHOWN: 'SHOWN',
    HIDE: 'HIDE',
    HIDDEN: 'HIDDEN',
};

const TRANSITION_DELAY = 350;

function Collapse({ isOpen, onOpened, onClosed, children }) {
    const [collapse, setCollapse] = useState(
        isOpen ? state.SHOWN : state.HIDDEN,
    );
    const [height, setHeight] = useState(null);

    const element = useRef();

    const getHeight = () => element.current.scrollHeight;

    useEffect(() => {
        let transitionTag;

        const setTransitionTag = (collapseType, callback) => {
            transitionTag = setTimeout(async () => {
                await (async () => {
                    setCollapse(collapseType);
                    setHeight(null);
                })();

                callback();
            }, TRANSITION_DELAY);
        };

        const openCollapse = async () => {
            await setCollapse(state.SHOW);

            setHeight(getHeight());
            setTransitionTag(state.SHOWN, onOpened);
        };

        const closeCollapse = async () => {
            await setHeight(getHeight());

            await (async () => {
                setCollapse(state.HIDE);
            })();

            setHeight(0);
            setTransitionTag(state.HIDDEN, onClosed);
        };

        if (isOpen && collapse === state.HIDDEN) {
            openCollapse();
        } else if (!isOpen && collapse === state.SHOWN) {
            closeCollapse();
        }

        return () => {
            clearTimeout(transitionTag);
        };
    }, [collapse, height, isOpen, onClosed, onOpened]);

    useEffect(() => {
        const handleResize = () => {
            if (collapse) {
                setHeight(null);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [collapse]);

    let collapseClass;

    switch (collapse) {
        case state.SHOW:
            collapseClass = styles.collapsing;
            break;
        case state.SHOWN:
            collapseClass = [styles.collapse, styles.show];
            break;
        case state.HIDE:
            collapseClass = styles.collapsing;
            break;
        case state.HIDDEN:
            collapseClass = styles.collapse;
            break;
        default:
            collapseClass = styles.collapse;
    }

    const style = height === null ? null : { height };

    return (
        <div style={style} className={classNames(collapseClass)} ref={element}>
            {children}
        </div>
    );
}

Collapse.defaultProps = {
    isOpen: false,
    onOpened: () => {},
    onClosed: () => {},
};

Collapse.propTypes = {
    isOpen: PropTypes.bool,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default Collapse;
