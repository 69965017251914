import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faQuestion } from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Spinner from 'js/components/spinner/spinner';
import styles from './indicator-bar.module.scss';

const cx = classNames.bind(styles);

const Segments = ({ value, delta, range, isLoading, isValuableLevel }) =>
    Array(range)
        .fill()
        .map((item, i) => {
            const isNegative = i < value && i >= value + delta;
            const isPositive = i >= value && i < value + delta;
            const segmentClasses = cx({
                segment: true,
                active: isValuableLevel && i < value,
                negative: isNegative,
                positive: isPositive,
            });

            if (isLoading) {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col key={i}>
                        <div className={segmentClasses} />
                    </Col>
                );
            }

            if (isNegative) {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col key={i}>
                        <div className={segmentClasses}>
                            <FontAwesomeIcon icon={faMinus} />
                        </div>
                    </Col>
                );
            }

            if (isPositive) {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col key={i}>
                        <div className={segmentClasses}>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    </Col>
                );
            }

            return (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={i}>
                    <div className={segmentClasses} />
                </Col>
            );
        });

function IndicatorBar({
    theme,
    value,
    delta,
    range,
    lowMin,
    lowMessage,
    mediumMin,
    mediumMessage,
    highMin,
    highMessage,
    loading,
    loadingMessage,
    unknownMessage,
    hasError,
    errorMessage,
    onClick,
    onHelp,
    onReload,
}) {
    const levels = {
        LOADING: {
            name: 'loading',
            message: loadingMessage,
        },
        UNKNOWN: {
            name: 'unknown',
            message: unknownMessage,
        },
        LOW: {
            name: 'low',
            message: lowMessage,
            min: lowMin,
        },
        MEDIUM: {
            name: 'medium',
            message: mediumMessage,
            min: mediumMin,
        },
        HIGH: {
            name: 'high',
            message: highMessage,
            min: highMin,
        },
    };
    const level = loading
        ? levels.LOADING
        : Object.values(levels).reduce(
              (acc, item) =>
                  value !== null &&
                  value >= item.min &&
                  item.min > (acc.min || 0)
                      ? item
                      : acc,
              levels.UNKNOWN,
          );
    const indicatorBarClasses = cx({
        'indicator-bar': true,
        [`indicator-bar--theme-${theme}`]: theme,
        clickable: !hasError && onClick,
    });
    const errorClasses = cx({
        error: true,
        [`error--theme-${theme}`]: theme,
    });

    if (hasError) {
        return (
            <div className={errorClasses}>
                <h2>{errorMessage}</h2>

                {onReload && (
                    <Box margin={['smaller', 0, 0]}>
                        <Row>
                            <Col span="auto">
                                <Button
                                    theme="outline"
                                    size="small"
                                    onClick={onReload}
                                >
                                    Retry
                                </Button>
                            </Col>
                        </Row>
                    </Box>
                )}
            </div>
        );
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            className={indicatorBarClasses}
            data-level={level.name}
            onClick={!hasError && onClick}
        >
            {level.message && (
                <div className={styles.label}>{level.message}</div>
            )}

            <Box position="relative">
                <Row gutter="smaller" alignItems="center">
                    <Segments
                        value={value}
                        delta={delta}
                        range={range}
                        isLoading={loading}
                        isValuableLevel={
                            level !== levels.LOADING || level !== levels.UNKNOWN
                        }
                    />

                    {onHelp && (
                        <Col>
                            <Box padding={[0, 'smaller']}>
                                <Button
                                    square
                                    theme="outline"
                                    size="small"
                                    onClick={onHelp}
                                >
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Button>
                            </Box>
                        </Col>
                    )}
                </Row>

                {loading && (
                    <div className={styles['spinner-wrapper']}>
                        <Spinner />
                    </div>
                )}
            </Box>
        </div>
    );
}

const defaultRange = 10;

IndicatorBar.defaultProps = {
    theme: 'gradient',
    value: undefined,
    delta: 0,
    range: defaultRange,
    lowMin: 1,
    lowMessage: undefined,
    mediumMin: 0.4 * defaultRange,
    mediumMessage: undefined,
    highMin: 0.8 * defaultRange,
    highMessage: undefined,
    loading: false,
    loadingMessage: undefined,
    unknownMessage: undefined,
    hasError: false,
    errorMessage: 'Error',
    onClick: undefined,
    onHelp: undefined,
    onReload: undefined,
};

IndicatorBar.propTypes = {
    theme: PropTypes.oneOf(['gradient', 'flat']),
    value: PropTypes.number,
    delta: PropTypes.number,
    range: PropTypes.number,
    lowMin: PropTypes.number,
    lowMessage: PropTypes.string,
    mediumMin: PropTypes.number,
    mediumMessage: PropTypes.string,
    highMin: PropTypes.number,
    highMessage: PropTypes.string,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    unknownMessage: PropTypes.string,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    onClick: PropTypes.func,
    onHelp: PropTypes.func,
    onReload: PropTypes.func,
};

export default IndicatorBar;
