import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Popper from 'js/components/popper/popper';
import styles from '../../rename-data-sources.module.scss';

const HasDataButton = () => {
    const [showHasDataTooltip, setShowHasDataTooltip] = useState(false);
    const handleToggleDataTooltip = () => {
        setShowHasDataTooltip((prevState) => !prevState);
    };

    const popperText = 'We have performance data for this ID';

    return (
        <div
            className={styles['has-data-icon']}
            onMouseEnter={handleToggleDataTooltip}
            onMouseLeave={handleToggleDataTooltip}
        >
            <Popper
                content={
                    <div className={styles['has-data-tooltip']}>
                        {popperText}
                    </div>
                }
                theme="tooltip"
                placement="left"
                offset={[0, 25]}
                showContent={showHasDataTooltip}
            >
                <FontAwesomeIcon icon={faCheckCircle} />
            </Popper>
        </div>
    );
};

export default HasDataButton;
