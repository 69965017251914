import React, { useState } from 'react';
import * as d3 from 'd3';
import Box from 'js/components/box/box';
import Text from 'js/components/text/text';
import Legend from 'js/components/treemap-chart/legend';
import TreeMapChart from 'js/components/treemap-chart/treemap-chart';
import CtrSwitchLegend from '../../components/ctr-switch-legend';
import DropdownTooltip from '../../components/dropdown-tooltip/dropdown-tooltip';
import PanelWrapper from '../../components/panel-wrapper';
import styles from '../../report-detail.module.scss';

export default function TreemapSection({
    report,
    targetingOption,
    isDV360Report,
    showClicks,
}) {
    const [isCtrView, setCtrView] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [dropdownCoords, setDropdownCoords] = useState(true);
    const [dropdownData, setDropdownData] = useState({});

    const onClick = (event, svgRef, nodeData, setColor, color, isDropdown) => {
        if (!isDropdown) return;
        if (event.currentTarget === event.target) {
            setToggleDropdown(false);
        }

        const { label, value, clicks, starts, completes } = nodeData;
        const svgElement = svgRef.current.getBoundingClientRect();
        const x = event.clientX - svgElement.left;
        const y = event.clientY - svgElement.top;

        setColor(color);

        const constructedData = [
            {
                title: 'Impressions',
                value,
                isDelta: false,
                comparedValue: report.impressions,
            },
            {
                title: 'Started Views',
                value: starts,
                isDelta: false,
                comparedValue: report.starts,
            },
            {
                title: `${isDV360Report ? 'True' : 'Completed'} Views`,
                value: completes,
                isDelta: false,
                comparedValue: report.completes,
            },
            {
                title: `${isDV360Report ? 'True View' : 'Completion'} Rate`,
                value: completes && value ? completes / value : 0,
                isDelta: true,
                comparedValue: report.completes / report.impressions,
            },
        ];

        if (showClicks) {
            constructedData.splice(2, 0, {
                title: 'Clicks',
                value: clicks,
                isDelta: false,
                comparedValue: report.clicks,
            });
        }
        setDropdownData({
            label,
            data: constructedData,
        });
        setDropdownCoords({ x, y });
        setToggleDropdown(true);
    };

    const onMouseEnter = (setColor, color, isDropdown) =>
        isDropdown && setColor(color);

    const onMouseLeave = (setColor, color, isDropdown) => {
        if (!isDropdown) return;
        setColor(color);
        setToggleDropdown(false);
    };

    const branchData = {
        children: [...report[`${targetingOption}_groups`]]
            .sort((a, b) => b.impressions - a.impressions)
            .slice(0, 25)
            .map((group) => ({
                label: group[`${targetingOption}_group_name`],
                value: group.impressions,
                clicks: group.clicks,
                ctr: group.clicks / group.impressions,
                starts: group.starts,
                completes: group.completes,
            })),
    };

    const impressionsColorFlatScale = d3.scaleSequential(() => '#6673FF');
    const impressionsSizeScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateNumber(0.75, 1.25));
    const ctrColorScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateRgb('#4CDCE6', '#137F86'));
    const ctrColorFlatScale = d3.scaleSequential(() => '#4CDCE6');
    const ctrSizeFlatScale = d3.scaleSequential(() => 1.0);

    return (
        <Box margin={['large', 0]}>
            <PanelWrapper>
                <Box margin={['base', 0]}>
                    <Box margin={[0, 0, 'base']}>
                        <Text weight="bold" size="larger">
                            Impression Share By Category
                        </Text>
                    </Box>

                    <div className={styles['treemap-chart-container']}>
                        <TreeMapChart
                            data={branchData}
                            customD3ColorScale={
                                isCtrView
                                    ? ctrColorScale
                                    : impressionsColorFlatScale
                            }
                            colorableValue={isCtrView ? 'data.ctr' : 'value'}
                            leftLegend={
                                <CtrSwitchLegend
                                    colorScale={ctrColorScale}
                                    sizeScale={ctrSizeFlatScale}
                                    isChecked={isCtrView}
                                    onChange={() => setCtrView((prev) => !prev)}
                                />
                            }
                            rightLegend={
                                <Legend
                                    lowLabel="Fewer Impressions"
                                    highLabel="More Impressions"
                                    colorScale={
                                        isCtrView
                                            ? ctrColorFlatScale
                                            : impressionsColorFlatScale
                                    }
                                    sizeScale={impressionsSizeScale}
                                />
                            }
                            dropdownTooltip={
                                <DropdownTooltip
                                    dropdownCoords={dropdownCoords}
                                    setToggleDropdown={setToggleDropdown}
                                    toggleDropdown={toggleDropdown}
                                    dropdownData={dropdownData}
                                />
                            }
                            onClick={onClick}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            hoverColor={isCtrView ? '#0C5257' : '#3040E8'}
                            activeColor={isCtrView ? '#093F43' : '#242A6B'}
                        />
                    </div>
                </Box>
            </PanelWrapper>
        </Box>
    );
}
