import { spacing } from '../tokens.styles';

const styles = {
    container: {
        flexGrow: 1,
        paddingHorizontal: spacing.large,
    },
};

export default styles;
