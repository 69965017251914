import React from 'react';
import styles from './dropdown-menu.module.scss';

function DropdownDivider() {
    return <li className={styles.divider} />;
}

DropdownDivider.defaultProps = {};

DropdownDivider.propTypes = {};

export default DropdownDivider;
