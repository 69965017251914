import React, {
    Children,
    Fragment,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CrumbCollapsed from './crumb-collapsed';
import styles from './breadcrumbs.module.scss';

function Breadcrumbs({ children }) {
    const [helperCrumbs, setHelperCrumbs] = useState([]);
    const [crumbs, setCrumbs] = useState([]);

    const navRef = useRef();
    const listRef = useRef();
    const firstCollapsibleIndex = useRef();
    const lastCollapsibleIndex = useRef();
    const isAllItemsCollapsed = useRef(false);
    const isContainerWideEnough = useRef(true);

    useEffect(() => {
        setHelperCrumbs([...children]);
    }, [children]);

    useLayoutEffect(() => {
        const maxCollapsible = Children.toArray(children).reduce(
            (acc, curr) => {
                if (curr.props.isCollapsible) return acc + 1;
                return acc;
            },
            0,
        );

        if (navRef.current && listRef.current) {
            const navWidth = navRef.current.getBoundingClientRect().width;
            const listWidth = listRef.current.getBoundingClientRect().width;

            isContainerWideEnough.current = navWidth >= listWidth;
        }

        if (isContainerWideEnough.current || isAllItemsCollapsed.current) {
            setCrumbs([...helperCrumbs]);
        } else {
            const collapsibles = Children.map(children, (child, index) => {
                if (child.props.isCollapsible) {
                    if (!firstCollapsibleIndex.current) {
                        firstCollapsibleIndex.current = index;
                    }

                    if (!lastCollapsibleIndex.current) {
                        lastCollapsibleIndex.current = index;
                    }

                    if (lastCollapsibleIndex.current >= index) {
                        return child;
                    }
                }

                return null;
            });

            const collapsedCrumbs =
                firstCollapsibleIndex.current && lastCollapsibleIndex.current
                    ? [
                          ...children.slice(0, firstCollapsibleIndex.current),
                          <CrumbCollapsed>{collapsibles}</CrumbCollapsed>,
                          ...children.slice(lastCollapsibleIndex.current + 1),
                      ]
                    : children;

            isAllItemsCollapsed.current =
                collapsibles.length === maxCollapsible;

            if (
                JSON.stringify(helperCrumbs.map((i) => i.key)) ===
                JSON.stringify(collapsedCrumbs.map((i) => i.key))
            ) {
                lastCollapsibleIndex.current += 1;
            }

            setHelperCrumbs(collapsedCrumbs);
        }
    }, [helperCrumbs]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const refreshCrumbs = () => {
            firstCollapsibleIndex.current = null;
            lastCollapsibleIndex.current = null;
            isAllItemsCollapsed.current = false;
            isContainerWideEnough.current = true;

            setHelperCrumbs([...children]);
        };

        window.addEventListener('resize', refreshCrumbs);

        return () => window.removeEventListener('resize', refreshCrumbs);
    }, [children]);

    return (
        <>
            {/* This block duplicates the "real" breadcrumbs rendering exclusively for smoother redrawings on screen resize */}
            <nav
                ref={navRef}
                className={styles.container}
                style={{ height: 0 }}
            >
                <ol ref={listRef} className={styles.crumbs}>
                    {helperCrumbs.map((crumb, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                            {crumb}

                            {index + 1 < helperCrumbs.length && (
                                <span className={styles.separator}>
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faChevronRight}
                                    />
                                </span>
                            )}
                        </Fragment>
                    ))}
                </ol>
            </nav>

            <nav className={styles.container}>
                <ol className={styles.crumbs}>
                    {crumbs.map((crumb, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                            {crumb}

                            {index + 1 < crumbs.length && (
                                <span className={styles.separator}>
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faChevronRight}
                                    />
                                </span>
                            )}
                        </Fragment>
                    ))}
                </ol>
            </nav>
        </>
    );
}

Breadcrumbs.defaultProps = {};

Breadcrumbs.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Breadcrumbs;
