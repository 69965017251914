import React from 'react';
import { exportToTXT } from 'js/utils';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import CopyButton from 'js/components/button/copy-button';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Panel from 'js/components/panel/panel';
import SVGImage from 'js/components/svg-image/svg-image';
import Text from 'js/components/text/text';
import Lightbulb from '../images/lightbulb.svg';
import styles from '../report-tag.module.scss';
import NoticePanel from './notice-panel';

const TagWrapper = ({ children, label }) =>
    label ? (
        <Panel>
            <Box padding="base">
                <Box padding={[0, 0, 'small']}>
                    <Text color={['gray', 'black']} size="large" weight="bold">
                        {label}
                    </Text>
                </Box>

                {children}
            </Box>
        </Panel>
    ) : (
        <Box margin={['base', 0, 0]}>{children}</Box>
    );

const Tag = ({ tag, type, hasLabel }) => (
    <Box key={tag.label} margin={['base', 0, 0]}>
        <TagWrapper label={hasLabel ? tag.label : ''}>
            <Row gutter="small">
                <Col>
                    <Input value={tag.script} readOnly />
                </Col>

                <Col spanSm="auto">
                    <CopyButton
                        value={tag.script}
                        message={<Text>Tag Copied</Text>}
                    >
                        <Button theme="outline">Copy</Button>
                    </CopyButton>
                </Col>

                <Col spanSm="auto">
                    <Button
                        theme="outline"
                        onClick={() =>
                            exportToTXT(
                                tag.script,
                                `4D-${tag.source}-${type}-tag.txt`,
                            )
                        }
                    >
                        Download
                    </Button>
                </Col>
            </Row>
        </TagWrapper>
    </Box>
);

const TagsPanel = ({ tags }) => (
    <Box margin={['large', 0]}>
        <Panel theme="secondary">
            <Box padding="base">
                <Text color={['gray', 'black']} size="large" weight="bold">
                    Your 4D Display Tag
                </Text>
                <Form>
                    {tags.map((tag) => {
                        const isUnique = tags.length === 1;
                        const type =
                            (isUnique && 'display') ||
                            (tag.label === 'Click Pixel' && 'click') ||
                            'impression';
                        return (
                            <Tag
                                tag={tag}
                                type={type}
                                hasLabel={!isUnique}
                                key={tag.label}
                            />
                        );
                    })}
                </Form>
            </Box>
        </Panel>
    </Box>
);

const KnowledgeBasePanel = ({ dsp, link }) => (
    <Box margin={['auto', 0, 0]}>
        <Panel theme="tertiary">
            <Box padding="base">
                <Row alignItems="center">
                    <Col span="auto">
                        <div className={styles['lightbulb-icon-container']}>
                            <SVGImage src={Lightbulb} />
                        </div>
                    </Col>

                    <Col>
                        <Text color={['purple', 'darker']}>
                            Find more information in the 4D Knowledge Base about
                            how to activate 4D on {dsp}
                        </Text>
                    </Col>

                    <Col span="auto">
                        <Button
                            theme="outline"
                            to={link}
                            className={styles['custom-button-knowledge-base']}
                        >
                            Go To Knowledge Base
                        </Button>
                    </Col>
                </Row>
            </Box>
        </Panel>
    </Box>
);

const DisplayTab = ({ source, accountId }) => {
    const scriptTags = source.scriptTags.map((tag) => ({
        ...tag,
        script: tag.script.replace('<account_id>', accountId),
        source: source.id,
    }));

    return (
        <Box padding={[0, 'large', 'large', 'large']}>
            <TagsPanel tags={scriptTags} />

            {source.noticeMsg && <NoticePanel text={source.noticeMsg} />}

            <Box margin={['large', 0, 0]}>
                <div className={styles.description}>{source.description}</div>
            </Box>

            {source.knowledgeBaseLink && (
                <Box margin={['large', 0, 0]}>
                    <KnowledgeBasePanel
                        dsp={source.label}
                        link={source.knowledgeBaseLink}
                    />
                </Box>
            )}
        </Box>
    );
};

export default DisplayTab;
